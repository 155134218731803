import React, { useEffect, useRef, useState } from "react";
import styles from "./price-fees.module.css";
import SelectedSellCar from "./components/SelectedSellCar";
import Box_A from "./components/Box_A";
import Box_B from "./components/Box_B";
import Box_C from "./components/Box_C";
import Box_D from "./components/Box_D";
import Box_E from "./components/Box_E";
import Box_F from "./components/Box_F";
import { useDispatch, useSelector } from "react-redux";
import SellFromData from "./components/SellFromData";
import EmptySellCar from "./components/EmptySellCar";
import Box_G from "./components/Box_G";
import Box_H from "./components/Box_H";
import {
  addSellPriceLabels,
  setDynamicAPILabels,
  setRetailData,
} from "../../../../Redux/slice/taxes&FeesSlice";
import SelectedSellCar2 from "../../DealerSell/components/SelectedSellCar2";
import usaStates from "../DealerAddInventory/usaStates.json";
import Box_B_Non_Edit from "../../DealerSell/components/Box_B_Non_Edit";
import { Dropdown, Modal } from "react-bootstrap";
import Box_Ea1 from "./components/Box_Ea1";
import { setIsSomethingChanged } from "../../../../Redux/slice/sellSlice";
import axios from "axios";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { saveUser } from "../../../../Redux/slice/userSlice";

const PricesFees = ({ selectedVehicle, status }) => {
  const Userdata = useSelector((state) => state.user);
  const totalFeesAndothers = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.totalFeesAndothers)
  );
  const salesTaxes = useSelector((state) =>
      parseFloat(state.taxesAndFees.dealerRetail.salesTaxes)
    );
    const mInventoryTax = useSelector((state) =>
      parseFloat(state.taxesAndFees.dealerRetail.mInventoryTax)
    );
    const { dealerId } = useSelector((state) => state.user);
    const txtTitleType = useSelector(
      (state) => state.taxesAndFees.dealerRetail.txtTitleType
    );
  const [apiData, setApiData] = useState(null);
  const dispatch = useDispatch();
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  
  const [totalTaxesOthers, setTotalTaxesOthers] = useState(0);
  const [totalTaxesOthersB, setTotalTaxesOthersB] = useState(0);
  const [totalFeesOthers, setTotalFeesOthers] = useState(0);
  const [sum, setSum] = useState(0);
  const isCarSelected = useSelector((state) => state.sell.selectedVehicle);
  const handleApiDataUpdate = (data) => {
    setApiData(data);
    console.log("API Data from SelectedSellCar:", totalTaxesOthers); // You can now use this data as needed
  };
  const handleTotalFeesUpdate = (newTotal) => {
    setTotalTaxesOthers(newTotal);
  };
  const handleTotalFeesUpdateB = (newTotal) => {
    setTotalTaxesOthersB(newTotal);
  };

  const handleSumChange = (newSum) => {
    setSum(newSum);
    // Handle the sum as needed
    console.log("Sum received from Box_F:", newSum);
  };
  const totalFeesAndOthersPrice = totalTaxesOthers + totalTaxesOthersB;
  // useEffect(() => {
  //   dispatch(
  //     setRetailData({
  //       totalFeesAndothers: totalFeesAndOthersPrice,
  //     })
  //   );
  // }, [totalFeesAndOthersPrice, totalTaxesOthersB]);
  console.log("totalTaxesOthersB", totalTaxesOthersB);
  const [showLabelPopout, setShowLabelPopout] = useState(false);
 useEffect(() => {
    if (showLabelPopout) {
      dispatch(saveUser({ isSideBarOpen: false }));
    }
  }, [showLabelPopout]);
  const allInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs
  );
  const lenghtInputs = allInputs?.length;
  console.log("all inputs dc", allInputs);
  //state selecting for dynamic label
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);
  const [selectedState, setSelectedState] = useState("TX - Texas");

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };
  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };
  // -------------------------------------------------------------
  // for save the text-title type-----------------------------------
  //  for save the data of labels in api-----------------------------
  const priceFeesLabels =
    allInputs &&
    allInputs?.map((input) => ({
      // userId: input.id,
      itemName: input && input?.label,
      itemValue: input && input?.InputValue,
    }));
  const additionalItems = [
    { itemName: "Sales Tax", itemValue: salesTaxes },
    { itemName: "Inventory Tax", itemValue: mInventoryTax },
  ];
  const updatedPriceFeesLabels =
    priceFeesLabels && priceFeesLabels?.concat(additionalItems);
  const objForLabels = {
    category: txtTitleType,
    userId: dealerId,
    sellPriceFeesLabels: updatedPriceFeesLabels,
  };
  const handleSaveLables = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/save_label_values`,
        objForLabels,
        {}
      );
      console.log("Response:5656565", response.data);
      const data = await response.data;
      if (response.status === 200) {
        setShowLabelPopout(false);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  //  get the data from api after selecttion of dropdown-------------------
  const fetchDynamicLabels = async (typeSale) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_labels/${Userdata.id}/${typeSale}`
      );
      console.log("Line 31", res.data);
      const mappedResponse =
        res &&
        res.data.sellPriceFeesLabels &&
        res.data.sellPriceFeesLabels
          .filter(
            (item) =>
              item.itemName !== "Sales Tax" && item.itemName !== "Inventory Tax"
          )
          .map((item, index) => ({
            id: item.id,
            label: item.itemName,
            InputValue: Number(item.itemValue),
          }));
      // for tex and inventory tax---------------------------
      const allItems = res && res.data.sellPriceFeesLabels;
      // Extract and get the InputValues for Sales Tax and Inventory Tax separately
      const salesTaxItem = allItems.find(
        (item) => item.itemName === "Sales Tax"
      );
      const inventoryTaxItem = allItems.find(
        (item) => item.itemName === "Inventory Tax"
      );

      // Get the InputValues for both (if they exist)
      const salesTaxInputValue = salesTaxItem
        ? Number(salesTaxItem.itemValue)
        : null;
      const inventoryTaxInputValue = inventoryTaxItem
        ? Number(inventoryTaxItem.itemValue)
        : null;
      console.log("mappedResponse => ", inventoryTaxInputValue);
      if (res.status === 200) {
        console.log("inventoryTaxInputValue => ", inventoryTaxInputValue);
        dispatch(setDynamicAPILabels(mappedResponse));
        dispatch(
          setRetailData({
            salesTaxes: salesTaxInputValue,
            mInventoryTax: inventoryTaxInputValue,
          })
        );
      } else {
        // dispatch(setDynamicAPILabels(retailData));
      }
      // dispatch(setDynamicAPILabelsFast(mappedResponse));
    } catch (error) {
      console.log("Error while fetching labels => ", error);
    }
  };
  // -----------------------------------------------------
    const selectedSaleType = retailFromRedux.txtTitleType;
    const saleTypes = [
      { id: "Retail", label: "Retail/Cash", image: Image.dealer_retail },
      { id: "outOfSale", label: "Out of State", image: Image.dealer_oos },
      { id: "wholeSale", label: "Whole Sale", image: Image.sidebarHome },
    ];
    // -----------------------------------------------------
    function switchDynamicLabels(NewSaleType) {
      dispatch(setIsSomethingChanged(true));
      switch (NewSaleType) {
        case "Retail":
          fetchDynamicLabels("Retail");
          break;
        case "wholeSale":
          fetchDynamicLabels("wholeSale");
          break;
        case "outOfSale":
          fetchDynamicLabels("outOfSale");
          break;
        // default:
        //   dispatch(setDynamicAPILabels(retailData));
      }
    }
    // ---------------------------------------------------------
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
          <SelectedSellCar2
              onApiDataUpdate={handleApiDataUpdate}
              apiData={apiData}
              selectedVehicle={selectedVehicle}
              status = {status}
            />
        {/* {isCarSelected || status ? (
          status ? (
            <SelectedSellCar2
              onApiDataUpdate={handleApiDataUpdate}
              apiData={apiData}
              selectedVehicle={selectedVehicle}
              status = {status}
            />
          ) : (
            <SelectedSellCar
              onApiDataUpdate={handleApiDataUpdate}
              apiData={apiData}
              selectedVehicle={selectedVehicle}
            />
          )
        ) : (
          <EmptySellCar />
        )} */}
        {/* <SelectedSellCar/> */}
        <div className={styles.contentContainer}>
          <div className={styles.content_left}>
            <Box_A retailFromRedux={retailFromRedux} apiData={apiData} showLabelPopout={showLabelPopout}/>

            {/* <Box_B
              retailFromRedux={retailFromRedux}
              apiData={apiData}
              onTotalFeesUpdate={handleTotalFeesUpdate}
            /> */}
            <div
              style={{
                border: "none",
              }}
            >
              {" "}
              <Box_B_Non_Edit
                allInputs={allInputs}
                editStatus={false}
                retailFromRedux={retailFromRedux}
              />
            </div>
            <p
              style={{
                border: "none",
                padding: "0.2rem 0",
                marginBottom: "0",
                marginLeft: "0.7rem",
                display: "flex",
                justifyContent: "end",
                paddingRight: "3rem",
              }}
            >
              <button className="btn-orange"
                onClick={() => {
                  setShowLabelPopout(true);
                }}
                style={{
                  fontWeight: "600",
                  borderRadius: "5px",
                  padding: "0.5rem 0.7rem",
                }}
              >
                Edit{" "}
                <span
                 style={{
                  color: "white",
                  fontSize: "larger",
                  fontWeight: "700",
                  borderRadius: "5px",
                }}
                >
                  &#8595;
                </span>
              </button>
            </p>
            {/* <Box_C
              retailFromRedux={retailFromRedux}
              apiData={apiData}
              onTotalFeesUpdateB={handleTotalFeesUpdateB}
            /> */}
          </div>
          <div className={styles.content_right}>
            <Box_G
              retailFromRedux={retailFromRedux}
              onSumChange={handleSumChange}
              lenghtInputs={lenghtInputs}
            />
            <Box_F
              retailFromRedux={retailFromRedux}
              onSumChange={handleSumChange}
            />
            <Box_D retailFromRedux={retailFromRedux} sum={sum} />
            <Box_H
              retailFromRedux={retailFromRedux}
              onSumChange={handleSumChange}
            />
            <Box_Ea1 retailFromRedux={retailFromRedux} />
            <Box_E retailFromRedux={retailFromRedux} status={status}/>
            <SellFromData status={status}/>
          </div>
        </div>
      </div>
      <Modal
        // dialogClassName="modal-90w"
        show={showLabelPopout}
        onHide={() => setShowLabelPopout(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.labelPopoutHeaderContainer}>
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className={`${styles.labelPopoutHeaderDropDown}`}
            >
              <Dropdown.Toggle
                size="sm"
                className={`px-3 bg-orange border-0 fw-bold text-light ${styles.selectSaleType}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <div className={styles.salesInventoryTaxContainer}>
              <p
                className={styles.salesTaxContainer}
                style={{ color: "#d1823a" }}
              >
                Edit Taxes & Fees (TTL){" "}
                <IoIosInformationCircleOutline
                  title="Taxes & Fees"
                  style={{ cursor: "pointer", color: "grey" }}
                />
              </p>
            </div>
            <div className={styles.salesInventoryTaxContainer}>
              {/* <p className={styles.salesTaxContainer}>
                Edit Taxes & Fees (TTL)
              </p> */}
              <p className={styles.InventoryTaxContainer}>
                <select
                  value={selectedSaleType}
                  className={styles.selectSaleType}
                  onChange={(e) => {
                    dispatch(setRetailData({ txtTitleType: e.target.value }));
                    switchDynamicLabels(e.target.value);
                  }}
                >
                  {saleTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className={styles.salesInventoryTaxContainer}
            style={{
              display: "flex",
              justifyContent: "center",
              borderBottom: "1px solid #ddd1d1",
              paddingBottom: "10px",
            }}
          >
            <p
              className={styles.salesTaxContainer}
              style={{ position: "relative" }}
            >
              <span style={{ fontWeight: "bold" }}>1. Sales Tax</span>
              <span className={styles.percentSymbolSalesTax}>%</span>
              <input
                type="text"
                value={retailFromRedux.salesTaxes}
                style={{ paddingLeft: "10%" }}
                onChange={(e) =>
                  dispatch(setRetailData({ salesTaxes: e.target.value }))
                }
              />
            </p>
            <p
              className={styles.InventoryTaxContainer}
              style={{ position: "relative" }}
            >
              <span style={{ fontWeight: "bold" }}>2. Inventory Tax</span>
              <span
                className={styles.percentSymbolSalesTax}
                style={{ left: "41%" }}
              >
                %
              </span>
              <input
                type="text"
                value={retailFromRedux.mInventoryTax}
                style={{ paddingLeft: "10%" }}
                onChange={(e) =>
                  dispatch(setRetailData({ mInventoryTax: e.target.value }))
                }
              />
            </p>
          </div>
          <div className={styles.boxContainer}>
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className={styles.labelPopoutHeaderDropDown}
            >
              <Dropdown.Toggle
                size="sm"
                className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <div className={styles.salesInventoryTaxContainerMobile}>
              <p className={styles.salesTaxContainer}>
                <span>Sales Tax</span>
                <input
                  type="text"
                  value={retailFromRedux.salesTaxes}
                  onChange={(e) =>
                    dispatch(setRetailData({ salesTaxes: e.target.value }))
                  }
                />
              </p>
              <p className={styles.InventoryTaxContainer}>
                <span>Inventory Tax</span>
                <input
                  type="text"
                  value={retailFromRedux.mInventoryTax}
                  onChange={(e) =>
                    dispatch(setRetailData({ mInventoryTax: e.target.value }))
                  }
                />
              </p>
            </div>
            <Box_B_Non_Edit allInputs={allInputs} editStatus={true} />
            <div
              className={
                styles.box_b_none_edit_add_button_small_screen_container
              }
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "#e8a262",
                  color: "white",
                  borderRadius: "5px",
                  padding: "0.35rem 1rem",
                  marginRight: "2rem",
                  fontSize: "medium",
                  marginTop: "0.1rem",
                }}
                className={styles.box_b_none_edit_add_button_small_screen}
                onClick={() =>
                  dispatch(
                    addSellPriceLabels({
                      id: allInputs && allInputs.length + 1,
                      label: `Input ${allInputs && allInputs?.length + 1}`,
                    })
                  )
                }
                disabled={allInputs && allInputs.length === 20}
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {" "}
            <button
              style={{
                border: "none",
                backgroundColor: "#E8A262",
                color: "white",
                fontWeight: "500",
                borderRadius: "5px",
                padding: "0.5rem 0.7rem",
                marginLeft: "0.7rem",
              }}
              onClick={() => handleSaveLables()}
            >
              Save
            </button>
          </p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PricesFees;
