import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./EmailLeadsMobile.module.css";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { FaCircleArrowRight } from "react-icons/fa6";

const EmailLeadsMobile = () => {
  const { dealerId } = useSelector((state) => state.user);
  // const [loanCardDataArray, setLoanCardDataArray] = useState([]);
  // console.log("loanCardDataArray => ", loanCardDataArray);
  //  dummy data  for mobile show----------------------------------------
  const loanCardDataArray = [
    {
      leadType: "Call",
      callerId: "Mike Robert Gray",
      mobileNumber: "(XXX)XXX-XXXX",
      city: "manahattan",
      source: "Website",
      recording: "Process..........",
    },
    {
      leadType: "Phone",
      callerId: "Mike Robert Gray",
      mobileNumber: "(XXX)XXX-XXXX",
      city: "California",
      source: "Website",
      recording: "Process..........",
    },
    {
      leadType: "Call",
      callerId: "Mike Robert Gray",
      mobileNumber: "(XXX)XXX-XXXX",
      city: "Collin",
      source: "Website",
      recording: "Process..........",
    },
  ];
  // const fetchDealerData = async () => {
  //   const fd = new FormData();
  //   fd.append("user_id", dealerId);

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/loan/get`,
  //       fd
  //     );
  //     setLoanCardDataArray(response.data.data);
  //   } catch (error) {
  //     console.log("Error fetching dealer data:", error);
  //     setLoanCardDataArray([]);
  //   }
  // };

  // useEffect(() => {
  //   if (dealerId) {
  //     fetchDealerData();
  //   }
  // }, [dealerId]);
  // for mobile popup-----------------------------------------------------------------------
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const showMobileMessagePopUp = () => {
    setShowMessagePopUp(true);
  };
  // -----------------------------------------------------------------------------------------
  return (
    <>
      <div className={styles.crmMwrapper}>
        {loanCardDataArray &&
          loanCardDataArray?.map((item, index) => (
            <div className={styles.crmMcontainer}>
              <div className={styles.crmMHeaderMain}>
                <div className={styles.crmMHeader}>
                  <span>
                    Lead Type{" "}
                    <span style={{ color: "black" }}>{item.leadType}</span>
                  </span>
                  {/* <span></span> */}
                </div>
                <div
                  className={`${styles.crmMHeader} ${styles.crmMHeaderRight} `}
                >
                  <span>
                    <button>Mute</button>
                  </span>
                  {/* <span style={{ color: "blue" }}>$ {item.offered}</span> */}
                </div>
              </div>
              <div className={styles.crmMPersonalWrapper}>
                <div className={styles.crmMPersonalDetails}>
                  <p className={styles.crmMDetail}>
                    <span>Caller Id</span>
                    <span>{item.callerId}</span>
                    {/* <span>{item.assetName}</span> */}
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Phone Number</span>
                    <span>{item.mobileNumber}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>City</span>
                    <span>{item.city}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Source</span>
                    <span>{item.source}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Recording</span>
                    <span>{item.recording}</span>
                  </p>
                </div>
                <div className={styles.crmMBtnContainer}>
                  <button>Call</button>
                  <button onClick={showMobileMessagePopUp}>Message</button>
                </div>
              </div>
              <span className={styles.crmMNumbering}>{index + 1}</span>
            </div>
          ))}
      </div>
      {/*--------- mobile messgae------------- */}
      <Modal
        show={showMessagePopUp}
        onHide={() => setShowMessagePopUp(false)}
        size="xl"
      >
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#d1823a" }}
          >
            Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div className={styles.mibileMessageCobntainer}>
            {/* Messanger part container */}
            <div className={styles.MessangerPartWrapper}>
              <div className={styles.MessangerPartContainer}>
                <div className={styles.MessangerHeader}>
                  <span>Message</span>
                  <span>1</span>
                </div>
                <div className={styles.MessangerBody}>
                  <div className={styles.LeftBody}>
                    <div className={styles.message}>
                      <span>Ghada</span>
                      <span>This is Test Message</span>
                    </div>
                  </div>
                  <div className={styles.RightBody}>
                    <div className={styles.message}>
                      <span>United</span>
                      <span>
                        Ok , I will send you the file. Please wait for a few.
                      </span>
                    </div>
                  </div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                </div>
                <div className={styles.MessangerInput}>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Your Message"
                  />
                  <span>
                    <FaCircleArrowRight />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <div className={styles.footer_btn}>
                <button>Call</button>
                <button>End Call</button>
                <button>Hold</button>
            </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmailLeadsMobile;
