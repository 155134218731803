import React, { useEffect, useRef, useState } from "react";
import Styles from "../Styles/SelectedSellCar.module.css";
import Image from "../../../imgData";
import { useOutletContext } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "react-bootstrap";
import resetIcon from "../components/resetButton.png";
import {
  setDynamicAPILabels,
  setRetailData,
} from "../../../../Redux/slice/taxes&FeesSlice";
import TradeInComponent from "./TradeInComponent";
import {
  setActiveTab,
  setIsSomethingChanged,
} from "../../../../Redux/slice/sellSlice";
import { saveUser } from "../../../../Redux/slice/userSlice";
import AddlistingData from "../../DealerInventory/DealerBalanceInventory/AddlistingData";
import { GrPowerReset } from "react-icons/gr";
import retailData from "../../DealerFast/Quote/retailData";
import wholeSaleData from "../../DealerFast/Quote/wholeSaleData";
import outOfSaleData from "../../DealerFast/Quote/outOfSaleData";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const SelectedSellCar2 = ({ onApiDataUpdate, apiData, selectedVehicle }) => {
  const Userdata = useSelector((state) => state.user);
  const isExpanded = useOutletContext();
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  const TaxFeesPopData = useSelector(
    (state) => state.taxesAndFees.dealerRetail || 0
  );
  const dateRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail.date
  );
  // console.log("TaxFeesPopData*******24*******", TaxFeesPopData);
  // const imageUrl = useSelector(
  //   (state) => state.taxesAndFees.dealerRetail.imageUrl);
  // console.log("imageUrl", selectedVehicle);
  const resetRef = useRef(null);
  const { id } = useSelector((state) => state.user);
  const { dealerId: dealerIdNew } = useSelector((state) => state.user);
  const buyer = useSelector((state) => state.sell.buyer);
  const sellCoBuyer = useSelector((state) => state.sell.sellCoBuyer);
  // ----------------------------------------------------------------
  const [isClicked, setIsClicked] = useState(false);
  console.log("isClicked123 => ", isClicked, "setIsClicked => ", setIsClicked);
  const [isClickedLien, setIsClickedLien] = useState(false);
  const [isClickedServiceContract, setIsClickedServiceContract] =
    useState(false);
  const [isClickedGapInsorance, setIsClickedGapInsorance] = useState(false);
  console.log("selectedVehicle => ", selectedVehicle);

  // _------------------------------------------
  const selectedReduxCar = useSelector((state) => state.sell.selectedVehicle);
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  const lienHolderName = useSelector(
    (state) => state.taxesAndFees.dealerRetail.lienHolderName
  );
  const serviceContractName = useSelector(
    (state) => state.taxesAndFees.dealerRetail.serviceContractName
  );

  console.log("line 18", retailFromRedux);
  //const [txtBtn, setTxtBtn] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);
  console.log("Selected car in redux in selectedsell car", selectedReduxCar);
  // const handleChangeDate = (e) => {
  //   const selectedDate = e.target.value;
  //   setDate(selectedDate);

  //   const dateObj = new Date(selectedDate);
  //   const date = dateObj.getDate();
  //   const month = dateObj.getMonth() + 1;
  //   const year = dateObj.getFullYear();
  //   console.log("Selected Date:", selectedDate);
  //   console.log("Day:", date);
  //   console.log("Month:", month);
  //   console.log("Year:", year);
  // };
  // for trade in------------------------------
  const handleReset = (resetFunction) => {
    resetRef.current = resetFunction;
  };

  const triggerReset = () => {
    if (resetRef.current) {
      resetRef.current();
    }
  };
  // FOR IMAGE VEHICLE CARD-------------------------------------------
  const noImage = Image.noImage;

  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${dealerIdNew}/${image}`;
        }
      }
    }
    return null;
  };

  const imageUrl =
  (selectedVehicle?.addListing2?.multimedia?.all_images &&
    getFirstValidImage(selectedVehicle.addListing2.multimedia.all_images)) ||
  (selectedVehicle?.addListing2?.multimedia?.exterior_images &&
    getFirstValidImage(selectedVehicle.addListing2.multimedia.exterior_images)) ||
  (selectedVehicle?.addListing2?.multimedia?.misc_images &&
    getFirstValidImage(selectedVehicle.addListing2.multimedia.misc_images)) ||
  (selectedVehicle?.addListing2?.multimedia?.interior_images &&
    getFirstValidImage(selectedVehicle.addListing2.multimedia.interior_images)) ||
  (selectedVehicle?.addListing2?.multimedia?.three_sixty_images &&
    getFirstValidImage(selectedVehicle.addListing2.multimedia.three_sixty_images)) ||
  noImage;
  // -----------------------------------------------------------------------------------
  const handleButtonClick = () => {
    setIsClicked(true); // Update state when button is clicked
  };
  useEffect(() => {
    if (purchase_price <= 0) {
      setIsClicked(false);
    }
  }, [purchase_price]);

  const handleButtonClickLien = () => {
    setIsClickedLien(true); // Update state when button is clicked
  };
  const handleButtonClickServiceContract = () => {
    setIsClickedServiceContract(true); // Update state when button is clicked
  };
  const handleButtonClickGapInsorance = () => {
    setIsClickedGapInsorance(true); // Update state when button is clicked
  };
  // for get data-----

  const dealerId = id;
  console.log("16", retailFromRedux);
  const stateData = retailFromRedux.txtState;
  const txtBtn = retailFromRedux.txtTitleType;
  console.log("43", txtBtn);
  const [formData, setFormData] = useState({
    dealerId: id,
    taxState: stateData,
    taxType: txtBtn,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({ ...prevFormData, taxType: txtBtn }));
  }, [txtBtn]);
  // const handleBtnTxt = (txt) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     taxType: txt,
  //   }));
  //   handleClose(); // Close the modal after selection
  // };
  //const [dataApi, setDataApi] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      if (formData.dealerId && formData.taxState && formData.taxType) {
        try {
          const response = await axios.post(`${apiUrl}/taxes/get`, formData);
          console.log("Fetched data:", response.data);
          onApiDataUpdate(response.data);
          // setDataApi(response.data);
        } catch (err) {
          console.error("API call error:", err);
        }
      }
    };

    // Fetch data when `formData.taxType` is updated
    if (formData.taxType) {
      fetchData();
    }
  }, [formData]);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [date, setDate] = useState(getCurrentDate());
  console.log("line94TradeIndate", date);
  useEffect(() => {
    dispatch(
      setRetailData({
        date: date,
      })
    );
  }, [date]);

  // for tade in popup----
  const handleLinkClick = () => {
    setShowModal(true);
    setCounter((prevCounter) => prevCounter + 1);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  // Lien Holder

  const [apiNameSuggestion, setApinameSuggestion] = useState([]);
  const [showLienHolderModal, setShowLienHoderModal] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [lienHolderData, setLienHolderData] = useState({
    name: "",
    holderId: "",
    address: "",
    address2: "",
  });

  // console.log(lienHolderData)
  const dispatch = useDispatch();
  const handleLienHolderChange = (e, changeValue) => {
    dispatch(setIsSomethingChanged(true));
    const { name, value } = e.target;
    console.log("e.target.value => ", e.target.value);
    setLienHolderData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    dispatch(setRetailData({ [changeValue]: value }));
    if (value.trim().length > 0) {
      fetchlienHolderData(value);
    } else {
      setApinameSuggestion([]);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    console.log("172", suggestion);
    setApinameSuggestion([]);
    setShowSuggestions(false);

    setLienHolderData((prevData) => ({
      ...prevData,
      name: suggestion.leinHolderName,
      holderId: suggestion.leinHolderId,
      address:
        suggestion.address1 +
        " , " +
        suggestion.city +
        " , " +
        suggestion.state +
        " , " +
        suggestion.zipCode,
      address2: suggestion.address2,
    }));

    dispatch(
      setRetailData({
        lienHolderName: suggestion?.leinHolderName,
        lienHolderID: suggestion?.leinHolderId,
        lienHolderAddress:
          suggestion.address1 +
          " , " +
          suggestion.city +
          " , " +
          suggestion.state +
          " , " +
          suggestion.zipCode,
        lienHolderAddress2: suggestion?.address2,
        leanHolderId: suggestion?.id,
      })
    );
  };

  // useEffect(() => {
  const fetchlienHolderData = async (nameValue) => {
    if (!nameValue || nameValue.trim().length === 0) {
      setApinameSuggestion([]);
      return;
    }

    try {
      const fd = new FormData();
      fd.append("name", nameValue);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/data/get_lein_holder`,
        fd
      );

      setApinameSuggestion(response.data);
    } catch (error) {
      console.log(error);
      setApinameSuggestion([]);
    }
  };

  // }, [lienHolderData.name]);

  const [providerData, setProviderData] = useState({
    name: "",
    // licenseNo: "",
    months: "",
    miles: "",
    address: "",
    // activationStatus: "",
    // providerType: "",
    phoneNumber: "",
    // county: "",
    // expirationDate: "",
  });

  const [apiProviderSuggestion, setProviderSuggestion] = useState([]);
  const [showProviderModal, setShowProviderModal] = useState(false);
  const handleProviderChange = (e, changeValue) => {
    dispatch(setIsSomethingChanged(true));
    const { name, value } = e.target;
    setProviderData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    dispatch(setRetailData({ [changeValue]: value }));

    if (
      value.trim().length > 0 &&
      changeValue !== "serviceProviderMonths" &&
      changeValue !== "serviceContractMiles"
    ) {
      fetchProviderData(value);
    } else {
      setProviderSuggestion([]);
    }
  };
  const handleProviderClick = (suggestion) => {
    console.log("172", suggestion);
    setProviderSuggestion([]);
    setProviderData((prevData) => ({
      ...prevData,
      name: suggestion.providerName,
      address: suggestion.city + " , " + suggestion.zipCode,
      phoneNumber: suggestion.phoneNumber,
    }));

    dispatch(
      setRetailData({
        serviceContractName: suggestion?.providerName,
        serviceContractAddress: suggestion.city + " , " + suggestion.zipCode,
        providerPhoneNumber: suggestion?.phoneNumber,
        serviceContractId: suggestion?.id,
      })
    );
  };

  const fetchProviderData = async (nameValue) => {
    if (!nameValue || nameValue.trim().length === 0) {
      setProviderSuggestion([]);
      return;
    }
    try {
      // Using FormData if necessary, otherwise use plain JSON
      const fd = new FormData();
      // fd.append("name", providerData.name);
      fd.append("name", nameValue);

      // API call to fetch lien holder data
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/data/get_service_contract`,
        fd
      );

      // Logging the response
      //console.log(response.data);
      setProviderSuggestion(response.data);
    } catch (error) {
      // More descriptive error handling
      console.log(error);
      setProviderSuggestion([]);
    }
  };

  useEffect(() => {
    dispatch(
      setRetailData({
        PriceFeeDate: date,
      })
    );
  }, [date]);
  // ----------------------------
  const [isSpanVisibleTradeIn, setIsSpanVisibleTradeIn] = useState(false);
  const [isSpanVisibleService, setIsSpanVisibleService] = useState(false);
  const [isSpanVisibleLien, setIsSpanVisibleLien] = useState(false);
  const [isSpanVisibleGap, setIsSpanVisibleGap] = useState(false);
  const handleLienHolderBgModal = () => {
    console.log("lien Clicked");
    setShowLienHoderModal(false);
    handleButtonClickLien();
    setIsSpanVisibleLien(true);
  };
  const handleServiceContactBgModal = () => {
    setShowProviderModal(false);
    handleButtonClickServiceContract();
    setIsSpanVisibleService(true);
  };

  const handleGapInsoranceBhModal = () => {
    setShowGapModal(false);
    handleButtonClickGapInsorance();
    setIsSpanVisibleGap(true);
  };

  //Gap Insurance
  const [GapData, setGapData] = useState({
    name: "",
    // licenseNo: "",
    months: "",
    miles: "",
    address: "",
    // activationStatus: "",
    // providerType: "",
    phoneNumber: "",
    // county: "",
    expirationDate: "",
  });

  const [apiGapSuggestion, setAPIGapSuggestion] = useState([]);
  const [showGapModal, setShowGapModal] = useState(false);
  const handleGapChange = (e, changeValue) => {
    dispatch(setIsSomethingChanged(true));
    const { name, value } = e.target;
    setGapData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    dispatch(setRetailData({ [changeValue]: value }));

    if (
      value.trim().length > 0 &&
      changeValue !== "gapMonths" &&
      changeValue !== "gapMiles"
    ) {
      fetchGapData(value);
    } else {
      setAPIGapSuggestion([]);
    }
  };
  const handleGapClick = (suggestion) => {
    setAPIGapSuggestion([]);
    setGapData((prevData) => ({
      ...prevData,
      name: suggestion.providerName,
      address: suggestion.city + " , " + suggestion.zipCode,
      phoneNumber: suggestion.phoneNumber,
    }));

    dispatch(
      setRetailData({
        gapName: suggestion?.providerName,
        gapAddress: suggestion.city + " , " + suggestion.zipCode,
        gapPhoneNumber: suggestion?.phoneNumber,
        gapInsuranceId: suggestion?.id,
      })
    );
  };

  const fetchGapData = async (nameValue) => {
    if (!nameValue || nameValue.trim().length === 0) {
      setAPIGapSuggestion([]);
      return;
    }
    try {
      // Using FormData if necessary, otherwise use plain JSON
      const fd = new FormData();
      // fd.append("name", providerData.name);
      fd.append("name", nameValue);

      // API call to fetch lien holder data
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/data/get_service_contract`,
        fd
      );

      // Logging the response
      //console.log(response.data);
      setAPIGapSuggestion(response.data);
    } catch (error) {
      // More descriptive error handling
      console.log(error);
      setAPIGapSuggestion([]);
    }
  };
  // for clean redux----------------------------------
  const cleanRTradeIn = () => {
    setIsClicked(false);
    console.log("clicked");
    dispatch(
      setRetailData({
        id: 0,
        vin: "",
        year_make_model: "",
        trim: "",
        // maek year modal for check purpose only------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        purchase_price: "",
        // ----------------------------------------
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
      })
    );
    setIsSpanVisibleTradeIn(false);
    triggerReset();
  };
  const cleanRserice = () => {
    setIsClickedServiceContract(false);
    setProviderData((prevData) => ({
      ...prevData,
      name: "",
      months: "",
      miles: "",
      // licenseNo: "",
      address: "",
      // activationStatus: "",
      // providerType: "",
      phoneNumber: "",
      // county: "",
      // expirationDate: "",
    }));
    dispatch(
      setRetailData({
        serviceContractName: "",
        serviceContractAddress: "",
        serviceProviderMonths: "",
        serviceContractMiles: "",
        // serviceProviderActivationStatus: "",
        // serviceProviderType: "",
        // serviceContractLicense: "",
        providerPhoneNumber: "",
        // providerCounty: "",
        // providerListingExpiry: "",
        serviceContractId: "",
      })
    );
    setIsSpanVisibleService(false);
  };
  // for lien---------------------------
  const cleanRLien = () => {
    setIsClickedLien(false);
    setLienHolderData((prevData) => ({
      ...prevData,
      name: "",
      holderId: "",
      address: "",
      address2: "",
    }));

    dispatch(
      setRetailData({
        lienHolderName: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        leanHolderId: "",
      })
    );
    setIsSpanVisibleLien(false);
  };
  // for gap------------------------
  const cleanRGap = () => {
    setIsClickedGapInsorance(false);
    setGapData((prevData) => ({
      ...prevData,
      name: "",
      // licenseNo: "",
      months: "",
      miles: "",
      address: "",
      // activationStatus: "",
      // providerType: "",
      phoneNumber: "",
      // county: suggestion.county,
      // expirationDate: "",
    }));

    dispatch(
      setRetailData({
        gapName: "",
        gapAddress: "",
        gapMonths: "",
        gapMiles: "",
        // gapActivationStatus: "",
        // gapType: "",
        // gapLicense: "",
        gapPhoneNumber: "",
        // gapCounty: suggestion?.county,
        // gapListingExpiry: "",
        gapInsuranceId: "",
      })
    );
    setIsSpanVisibleGap(false);
  };

  //If any one of the model is open close the sidebar
  useEffect(() => {
    if (showProviderModal || showLienHolderModal || showGapModal || showModal) {
      // dispatch(saveUser({ isSideBarOpen: false }));
    }
  }, [showProviderModal, showLienHolderModal, showGapModal, showModal]);
  // for set the data for glow button--------------------------
  // for Lien Holder-------------------
  useEffect(() => {
    if (TaxFeesPopData) {
      // Set formData with specific fields
      setLienHolderData((prevState) => ({
        ...prevState,
        name: TaxFeesPopData.lienHolderName || "",
        holderId: TaxFeesPopData.lienHolderID || "",
        address: TaxFeesPopData.lienHolderAddress || "",
        address2: TaxFeesPopData.lienHolderAddress2 || "",
      }));
      if (TaxFeesPopData.lienHolderName) {
        setIsSpanVisibleLien(true);
        setIsClickedLien(true);
      } else if (TaxFeesPopData.lienHolderName === "") {
        setIsSpanVisibleLien(false);
        setIsClickedLien(false);
      }
    }
  }, [TaxFeesPopData]);
  // for Service Contract------------------------------
  useEffect(() => {
    if (TaxFeesPopData) {
      // Set formData with specific fields
      setProviderData((prevData) => ({
        ...prevData,
        name: TaxFeesPopData.serviceContractName || "",
        months: TaxFeesPopData.serviceProviderMonths || "",
        miles: TaxFeesPopData.serviceContractMiles || "",
        address: TaxFeesPopData.serviceContractAddress || "",
        phoneNumber: TaxFeesPopData.providerPhoneNumber || "",
      }));
      if (TaxFeesPopData.serviceContractName) {
        setIsSpanVisibleService(true);
        setIsClickedServiceContract(true);
      } else if (TaxFeesPopData.serviceContractName === "") {
        setIsSpanVisibleService(false);
        setIsClickedServiceContract(false);
      }
    }
  }, [TaxFeesPopData]);
  // for Gap Insurance------------------------------
  useEffect(() => {
    if (TaxFeesPopData) {
      // Set formData with specific fields
      setGapData((prevData) => ({
        ...prevData,
        name: TaxFeesPopData.gapName || "",
        months: TaxFeesPopData.gapMonths || "",
        miles: TaxFeesPopData.gapMiles || "",
        address: TaxFeesPopData.gapAddress || "",
        phoneNumber: TaxFeesPopData.gapPhoneNumber || "",
      }));
      if (TaxFeesPopData.gapName) {
        setIsSpanVisibleGap(true);
        setIsClickedGapInsorance(true);
      } else if (TaxFeesPopData.gapName === "") {
        setIsSpanVisibleGap(false);
        setIsClickedGapInsorance(false);
      }
    }
  }, [TaxFeesPopData]);
  // ---------------------------------------
  useEffect(() => {
    console.log("typeof setIsClicked:", typeof setIsClicked); // Check if it's a function
    if (TaxFeesPopData) {
      console.log("purchase_price value:", TaxFeesPopData?.purchase_price); // Log the value
      if (TaxFeesPopData?.vin?.length === 17) {
        setIsClicked(true);
        setIsSpanVisibleTradeIn(true);
      } else {
        setIsClicked(false);
        setIsSpanVisibleTradeIn(false);
      }
    }
  }, [TaxFeesPopData]);
  // --------------------------------------
  console.log("selectedVehicle => ", selectedVehicle);
  const selectedSaleType = retailFromRedux.txtTitleType;
  const saleTypes = [
    { id: "Retail", label: "Retail/Cash", image: Image.dealer_retail },
    { id: "Finance/BHPH", label: "Finance/BHPH", image: Image.dealer_finance },
    { id: "outOfSale", label: "Out of State", image: Image.dealer_oos },
    { id: "wholeSale", label: "Whole Sale", image: Image.sidebarHome },
    // {
    //   id: "Rent & Lease",
    //   label: "Rent & Lease",
    //   image: Image.dealer_rent_lease,
    // },
  ];
  useEffect(() => {
    dispatch(setRetailData({ txtTitleType: "Retail" }));
  }, []);
  //  get the data from api after selecttion of dropdown-------------------
  const fetchDynamicLabels = async (typeSale) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_labels/${Userdata.id}/${typeSale}`
      );
      console.log("Line 31", res.data);
      const mappedResponse =
        res &&
        res.data.sellPriceFeesLabels &&
        res.data.sellPriceFeesLabels
          .filter(
            (item) =>
              item.itemName !== "Sales Tax" && item.itemName !== "Inventory Tax"
          )
          .map((item, index) => ({
            id: item.id,
            label: item.itemName,
            InputValue: Number(item.itemValue),
          }));
      // for tex and inventory tax---------------------------
      const allItems = res && res.data.sellPriceFeesLabels;
      // Extract and get the InputValues for Sales Tax and Inventory Tax separately
      const salesTaxItem = allItems.find(
        (item) => item.itemName === "Sales Tax"
      );
      const inventoryTaxItem = allItems.find(
        (item) => item.itemName === "Inventory Tax"
      );

      // Get the InputValues for both (if they exist)
      const salesTaxInputValue = salesTaxItem
        ? Number(salesTaxItem.itemValue)
        : null;
      const inventoryTaxInputValue = inventoryTaxItem
        ? Number(inventoryTaxItem.itemValue)
        : null;
      console.log("mappedResponse => ", inventoryTaxInputValue);
      if (res.status === 200) {
        console.log("inventoryTaxInputValue => ", inventoryTaxInputValue);
        dispatch(setDynamicAPILabels(mappedResponse));
        dispatch(
          setRetailData({
            salesTaxes: salesTaxInputValue,
            mInventoryTax: inventoryTaxInputValue,
          })
        );
      } else {
        // dispatch(setDynamicAPILabels(retailData));
      }
      // dispatch(setDynamicAPILabelsFast(mappedResponse));
    } catch (error) {
      console.log("Error while fetching labels => ", error);
    }
  };
  // -----------------------------------------------------
  function switchDynamicLabels(NewSaleType) {
    dispatch(setIsSomethingChanged(true));
    switch (NewSaleType) {
      case "Retail":
        fetchDynamicLabels("Retail");
        break;
      case "wholeSale":
        fetchDynamicLabels("wholeSale");
        break;
      case "outOfSale":
        fetchDynamicLabels("outOfSale");
        break;
      // default:
      //   dispatch(setDynamicAPILabels(retailData));
    }
  }
  // for date-----------------------------------

  const handleChangeDate = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    dispatch(
      setRetailData({
        date: selectedDate,
      })
    );

    const dateObj = new Date(selectedDate);
    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    console.log("Selected Date:", selectedDate);
    dispatch(setIsSomethingChanged(true));
  };
  // -----------------------------------------------------
  return (
    <>
      <div className={Styles.wrapper}>
        <div className={Styles.txtbutton_saleprice}>
          <p
            onClick={() => dispatch(setActiveTab("Buyer"))}
            className={Styles.buyerNameContainer}
          >
            <span>Buyer Name</span>
            <span>
              {" "}
              {buyer?.businessName
                ? buyer?.businessName
                : `${buyer?.firstName} ${buyer?.middleName} ${buyer?.lastName}`}{" "}
              {sellCoBuyer?.businessName ? (
                <> / {sellCoBuyer?.businessName} </>
              ) : sellCoBuyer?.firstName ? (
                <>
                  {" "}
                  / {sellCoBuyer?.firstName} {sellCoBuyer?.middleName}{" "}
                  {sellCoBuyer?.lastName}{" "}
                </>
              ) : (
                ""
              )}
            </span>
          </p>
        </div>
        <div className={Styles.container}>
          <div className={Styles.imgDataDetailsContainer}>
            <div className={Styles.imgContainer}>
              <img src={imageUrl} alt="car" />
            </div>

            <div
              className={`${Styles.vin_name_container} ${
                isExpanded ? Styles.font_smaller : ""
              }`}
            >
              <span
                className={` ${Styles.yearMake}`}
                style={{ fontWeight: "100px", cursor: "pointer" }}
                // onClick={(e) =>
                //   handleLinkClickAddlisting(selectedVehicle, e, 0)
                // }
              >
                {selectedVehicle.year_Make_Model}
              </span>
              <span
                className={Styles.vin_Container}
                // onClick={(e) =>
                //   handleLinkClickAddlisting(selectedVehicle, e, 0)
                // }
                style={{ cursor: "pointer" }}
              >
                <span style={{ color: "#d1823a" }}>VIN : </span>
                <span style={{ marginLeft: "10px" }}>
                {selectedVehicle?.addListing2?.vin}
                </span>
              </span>
              <span className={Styles.vin_Container}>
                <span style={{ color: "#d1823a" }}>Mileage: </span>
                <span style={{ marginLeft: "10px" }}>
                  {selectedVehicle?.addListing2?.mileage_in}
                  {/* {new Intl.NumberFormat().format(selectedVehicle?.addListing2?.mileage_in)} */}
                </span>
              </span>
            </div>
          </div>
          <div className={Styles.dateSaleTypeContainer}>
            <label htmlFor="saleDate">Sale Date</label>
            <input
              type="date"
              name="date"
              id="saleDate"
              value={dateRedux}
              onChange={handleChangeDate}
            />
            <select
              value={selectedSaleType}
              className={Styles.selectSaleType}
              onChange={(e) => {
                dispatch(setRetailData({ txtTitleType: e.target.value }));
                switchDynamicLabels(e.target.value);
              }}
            >
              {saleTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
          <div
            className={`${Styles.price_type_container} ${
              isExpanded ? Styles.expanded_price_type_container : ""
            }`}
            style={{
              justifyContent:
                selectedSaleType === "Finance/BHPH" ? "center" : "end",
              paddingBottom:
                selectedSaleType === "Finance/BHPH" ? "0" : "0.9rem",
            }}
          >
            {/* <div className={`${Styles.extraSelectedCarOptionsContainer}`}>
                 
              </div> */}
            <div
              className={Styles.sscModalBtnContainer}
              style={{
                display: selectedSaleType === "Finance/BHPH" ? "grid" : "flex",
              }}
            >
              {/* Lien Holder */}
              <span
                className={`${Styles.extraSelectedCarOptionsRelative} ${
                  isClickedLien ? Styles.clickedSpan : Styles.clickedSpanb
                }`}
                onClick={() => setShowLienHoderModal(true)}
              >
                Lien Holder
                {isSpanVisibleLien && (
                  <span
                    className={`${Styles.extraSelectedCarOptionsAbsolute}`}
                    onClick={cleanRLien}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </span>
                )}
              </span>
              {/* Trade In  */}
              <span
                onClick={handleLinkClick}
                className={`${Styles.extraSelectedCarOptionsRelative}
                    ${isClicked ? Styles.clickedSpan : Styles.clickedSpanb}`}
              >
                Trade-In
                {isSpanVisibleTradeIn && (
                  <span
                    className={`    ${Styles.extraSelectedCarOptionsAbsolute}`}
                    onClick={cleanRTradeIn}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </span>
                )}
              </span>
              {/* service contract  */}
              <span
                className={` ${Styles.extraSelectedCarOptionsRelative}
                    ${
                      isClickedServiceContract
                        ? Styles.clickedSpan
                        : Styles.clickedSpanb
                    }`}
                onClick={() => setShowProviderModal(true)}
              >
                Service Contract
                {isSpanVisibleService && (
                  <span
                    onClick={cleanRserice}
                    title="Reset"
                    className={`    ${Styles.extraSelectedCarOptionsAbsolute}`}
                  >
                    <GrPowerReset />
                  </span>
                )}
              </span>
              {/* Insurance */}
              {selectedSaleType === "Finance/BHPH" && (
                <span
                  className={` ${Styles.extraSelectedCarOptionsRelative}
                    ${
                      isClickedServiceContract
                        ? Styles.clickedSpan
                        : Styles.clickedSpanb
                    }`}
                  onClick={() => setShowProviderModal(true)}
                >
                  Insurance
                  {isSpanVisibleService && (
                    <span
                      onClick={cleanRserice}
                      title="Reset"
                      className={`    ${Styles.extraSelectedCarOptionsAbsolute}`}
                    >
                      <GrPowerReset />
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        {/* Lien Holder Modal*/}
        <Modal
          show={showLienHolderModal}
          onHide={() => setShowLienHoderModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Lien Holder</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="h-50 "
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr",
              gap: "2rem",
              rowGap: "2rem",
            }}
          >
            <div style={{ position: "relative" }}>
              <p style={{ marginBottom: "0" }}>Lien Holder Name</p>
              <input
                type="text"
                value={lienHolderData.name}
                name="name"
                style={{
                  backgroundColor: "#FCE8CF",
                  border: "none",
                  padding: "0.3rem",
                  color: "black",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleLienHolderChange(e, "lienHolderName")}
              />
              {lienHolderData.name.trim().length > 0 &&
                apiNameSuggestion.length > 0 && (
                  <ul
                    className="dropdown-menu show"
                    style={{
                      position: "absolute",
                      top: "4rem",
                      zIndex: "1",
                      overflowY: "auto",
                      height: "300px",
                      width: "23rem",
                    }}
                  >
                    {apiNameSuggestion?.map((suggestion) => (
                      <li key={suggestion.id}>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.leinHolderName}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Lien Holder ID</p>
              <input
                type="text"
                value={lienHolderData.holderId}
                name="holderId"
                style={{
                  backgroundColor: "#FCE8CF",
                  border: "none",
                  padding: "0.3rem",
                  color: "black",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleLienHolderChange(e, "lienHolderID")}
              />
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Address : </p>
              <input
                type="text"
                value={lienHolderData.address}
                name="address"
                style={{
                  backgroundColor: "#FCE8CF",
                  border: "none",
                  padding: "0.3rem",
                  color: "black",
                  fontWeight: "500",
                }}
                onChange={(e) => handleLienHolderChange(e, "lienHolderAddress")}
              />
            </div>
            <div>
              <p style={{ marginBottom: "0" }}>Address 2 :</p>
              <input
                type="text"
                value={lienHolderData.address2}
                name="address2"
                style={{
                  backgroundColor: "#FCE8CF",
                  border: "none",
                  padding: "0.3rem",
                  color: "black",
                  fontWeight: "500",
                }}
                onChange={(e) =>
                  handleLienHolderChange(e, "lienHolderAddress2")
                }
              />
            </div>
          </Modal.Body>
          {/* <div style={{display:"flex",justifyContent:"flex-end",marginRight:"15px",marginBottom:"15px"}}><button type="button" style={{border:"none",backgroundColor:"#d1823a",color:"white",borderRadius:"5px",width:"80px",height:"30px"}}>Save</button></div> */}
          <Modal.Footer>
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: "large",
                width: "100%",
              }}
            >
              <span
                style={{
                  backgroundColor: "#FCE8CF",
                  color: "black",
                  padding: "0.3rem 0.5rem",
                  borderRadius: "5px",
                }}
                onClick={handleLienHolderBgModal}
              >
                Save
              </span>
            </p>
          </Modal.Footer>
        </Modal>
        {/* Service Contract Modal */}
        <Modal
          show={showProviderModal}
          onHide={() => setShowProviderModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Service Contract Provider</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-50">
            <div
              style={{
                // display: "grid",
                // gridTemplateColumns: "repeat(3,1fr",
                // gap: "2rem",
                // rowGap: "2rem",
                display: "flex",
                gap: "2rem",
              }}
            >
              <div style={{ position: "relative", width: "60%" }}>
                <p style={{ marginBottom: "0" }}>Provider Name</p>
                <input
                  type="text"
                  value={providerData.name}
                  name="name"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) =>
                    handleProviderChange(e, "serviceContractName")
                  }
                />
                {apiProviderSuggestion.length > 0 && providerData.name && (
                  <ul
                    className="dropdown-menu show"
                    style={{
                      position: "absolute",
                      top: "4rem",
                      zIndex: "1",
                      fontSize: "small",
                      overflowY: "auto",
                      height: "300px",
                      width: "23rem",
                    }}
                  >
                    {apiProviderSuggestion &&
                      apiProviderSuggestion?.map((suggestion) => (
                        <li key={suggestion.id}>
                          <button
                            className="dropdown-item"
                            type="button"
                            style={{ color: "black" }}
                            onClick={() => handleProviderClick(suggestion)}
                          >
                            {suggestion.providerName}
                          </button>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <div style={{ display: "flex", gap: "10px", width: "40%" }}>
                <div style={{ width: "40%" }}>
                  <p style={{ marginBottom: "0" }}>Months</p>
                  <input
                    type="text"
                    value={providerData.months}
                    name="months"
                    style={{
                      backgroundColor: "#FCE8CF",
                      border: "none",
                      padding: "0.3rem",
                      color: "black",
                      fontWeight: "500",
                      zIndex: "0",
                    }}
                    onChange={(e) =>
                      handleProviderChange(e, "serviceProviderMonths")
                    }
                  />
                </div>
                <div style={{ width: "60%" }}>
                  <p style={{ marginBottom: "0" }}>Miles</p>
                  <input
                    type="text"
                    value={providerData.miles}
                    name="miles"
                    style={{
                      backgroundColor: "#FCE8CF",
                      border: "none",
                      padding: "0.3rem",
                      color: "black",
                      fontWeight: "500",
                      zIndex: "0",
                    }}
                    onChange={(e) =>
                      handleProviderChange(e, "serviceContractMiles")
                    }
                  />
                </div>
              </div>
            </div>
            {/* <div>
                <p style={{ marginBottom: "0" }}>Provider Type</p>
                <input
                  type="text"
                  value={providerData.providerType}
                  name="providerType"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) =>
                    handleProviderChange(e, "serviceProviderType")
                  }
                />
              </div> */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr",
                gap: "2rem",
                rowGap: "2rem",
                marginTop: "2rem",
              }}
            >
              <div>
                <p style={{ marginBottom: "0" }}>Phone Number</p>
                <input
                  type="text"
                  value={providerData.phoneNumber}
                  name="phoneNumber"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) =>
                    handleProviderChange(e, "providerPhoneNumber")
                  }
                />
              </div>
              <div>
                <p style={{ marginBottom: "0" }}>Address : </p>
                <input
                  type="text"
                  value={providerData.address}
                  name="address"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) =>
                    handleProviderChange(e, "serviceContractAddress")
                  }
                />
              </div>
            </div>
            {/* <div>
              <p style={{ marginBottom: "0" }}>County : </p>
              <input
                type="text"
                value={providerData.county}
                name="county"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleProviderChange(e, "providerCounty")}
              />
            </div> */}

            {/* <div style={{ marginTop: "2rem", width: "50%", marginLeft: "25%" }}>
              <p style={{ marginBottom: "0" }}>Expiry Date </p>
              <input
                type="text"
                value={providerData.expirationDate}
                name="expirationDate"
                style={{
                  backgroundColor: "#FCE8CF",
                  border: "none",
                  padding: "0.3rem",
                  color: "black",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) =>
                  handleProviderChange(e, "providerListingExpiry")
                }
              />
            </div> */}
          </Modal.Body>
          {/* <div style={{display:"flex",justifyContent:"flex-end",marginRight:"15px",marginBottom:"15px"}}><button type="button" style={{border:"none",backgroundColor:"#d1823a",color:"white",borderRadius:"5px",width:"80px",height:"30px"}}>Save</button></div> */}
          <Modal.Footer>
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: "large",
                width: "100%",
              }}
            >
              <span
                style={{
                  backgroundColor: "#FCE8CF",
                  color: "black",
                  padding: "0.3rem 0.5rem",
                  borderRadius: "5px",
                }}
                onClick={handleServiceContactBgModal}
              >
                Save
              </span>
            </p>
          </Modal.Footer>
        </Modal>
        {/* Gap Insurance */}
        <Modal
          show={showGapModal}
          onHide={() => setShowGapModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Gap Insurance Provider</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-50 ">
            <div
              style={{
                // display: "grid",
                // gridTemplateColumns: "repeat(3,1fr",
                // gap: "2rem",
                // rowGap: "2rem",
                display: "flex",
                gap: "2rem",
              }}
            >
              <div style={{ position: "relative", width: "60%" }}>
                <p style={{ marginBottom: "0" }}>Insurance Provider Name</p>
                <input
                  type="text"
                  value={GapData.name}
                  name="name"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) => handleGapChange(e, "gapName")}
                />
                {apiGapSuggestion.length > 0 && GapData.name && (
                  <ul
                    className="dropdown-menu show"
                    style={{
                      position: "absolute",
                      top: "4rem",
                      zIndex: "1",
                      fontSize: "small",
                      overflowY: "auto",
                      height: "300px",
                      width: "23rem",
                    }}
                  >
                    {apiGapSuggestion &&
                      apiGapSuggestion?.map((suggestion) => (
                        <li key={suggestion.id}>
                          <button
                            className="dropdown-item"
                            type="button"
                            style={{ color: "black" }}
                            onClick={() => handleGapClick(suggestion)}
                          >
                            {suggestion.providerName}
                          </button>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <div style={{ display: "flex", width: "40%", gap: "10px" }}>
                <div style={{ width: "40%" }}>
                  <p style={{ marginBottom: "0" }}>Months</p>
                  <input
                    type="text"
                    value={GapData.months}
                    name="months"
                    style={{
                      backgroundColor: "#FCE8CF",
                      border: "none",
                      padding: "0.3rem",
                      color: "black",
                      fontWeight: "500",
                      zIndex: "0",
                    }}
                    onChange={(e) => handleGapChange(e, "gapMonths")}
                  />
                </div>
                <div style={{ width: "60%" }}>
                  <p style={{ marginBottom: "0" }}>Miles</p>
                  <input
                    type="text"
                    value={GapData.miles}
                    name="miles"
                    style={{
                      backgroundColor: "#FCE8CF",
                      border: "none",
                      padding: "0.3rem",
                      color: "black",
                      fontWeight: "500",
                      zIndex: "0",
                    }}
                    onChange={(e) => handleGapChange(e, "gapMiles")}
                  />
                </div>
              </div>
            </div>
            {/* <div>
                <p style={{ marginBottom: "0" }}>Provider Type</p>
                <input
                  type="text"
                  value={GapData.providerType}
                  name="providerType"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) => handleProviderChange(e, "gapType")}
                />
              </div> */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr",
                gap: "2rem",
                rowGap: "2rem",
                marginTop: "2rem",
              }}
            >
              <div>
                <p style={{ marginBottom: "0" }}>Provider Phone Number</p>
                <input
                  type="text"
                  value={GapData.phoneNumber}
                  name="phoneNumber"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) => handleGapChange(e, "gapPhoneNumber")}
                />
              </div>
              <div>
                <p style={{ marginBottom: "0" }}>Address : </p>
                <input
                  type="text"
                  value={GapData.address}
                  name="address"
                  style={{
                    backgroundColor: "#FCE8CF",
                    border: "none",
                    padding: "0.3rem",
                    color: "black",
                    fontWeight: "500",
                    zIndex: "0",
                  }}
                  onChange={(e) => handleGapChange(e, "gapAddress")}
                />
              </div>
            </div>
            {/* <div>
              <p style={{ marginBottom: "0" }}>County : </p>
              <input
                type="text"
                value={GapData.county}
                name="county"
                style={{
                  backgroundColor: "#E8A262",
                  border: "none",
                  padding: "0.3rem",
                  color: "white",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleProviderChange(e, "gapCounty")}
              />
            </div> */}

            {/* <div style={{ marginTop: "2rem", width: "50%", marginLeft: "25%" }}>
              <p style={{ marginBottom: "0" }}>Expiry Date </p>
              <input
                type="text"
                value={GapData.expirationDate}
                name="expirationDate"
                style={{
                  backgroundColor: "#FCE8CF",
                  border: "none",
                  padding: "0.3rem",
                  color: "black",
                  fontWeight: "500",
                  zIndex: "0",
                }}
                onChange={(e) => handleProviderChange(e, "gapListingExpiry")}
              />
            </div> */}
          </Modal.Body>
          {/* <div style={{display:"flex",justifyContent:"flex-end",marginRight:"15px",marginBottom:"15px"}}><button type="button" style={{border:"none",backgroundColor:"#d1823a",color:"white",borderRadius:"5px",width:"80px",height:"30px"}}>Save</button></div> */}
          <Modal.Footer>
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: "large",
                width: "100%",
              }}
            >
              <span
                style={{
                  backgroundColor: "#FCE8CF",
                  color: "black",
                  padding: "0.3rem 0.5rem",
                  borderRadius: "5px",
                }}
                onClick={handleGapInsoranceBhModal}
              >
                Save
              </span>
            </p>
          </Modal.Footer>
        </Modal>
      </div>
      <TradeInComponent
        show={showModal}
        handleClose={handleModalClose}
        onButtonClick={handleButtonClick}
        setIsSpanVisibleTradeIn={setIsSpanVisibleTradeIn}
        // setIsClicked = {setIsClicked}
        // isClicked = {isClicked}
        counter={counter}
        onReset={handleReset}
      />
    </>
  );
};

export default SelectedSellCar2;
