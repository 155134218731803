import React, { useMemo } from "react";
import "./productlisting.css";
import { useLocation } from "react-router-dom";
import PriceFilter from "./PriceFilter";
import DropDownList from "./DropDownListOne";
import DropDownListTwo from "./DropDownListTwo";
import { Link } from "react-router-dom";
import Navbar from "../navbars/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { FcFilledFilter } from "react-icons/fc";

import CryptoCard from "./CryptoCard";
import Image from "../imgData";
import GridLayout from "./GridLayout";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import ScrollToTopButton from "../footer/ScrollToTopButton";
import DealerSideBar from "../DealerSection/DealerSideBar/DealerSideBar";
import MobileDataCard from "./MobileDataCard";
import { Modal } from "react-bootstrap";
import mobilePopoutFilters from "./mobileFilters";
import MobileFiltersPopout from "./MobileFiltersPopout";
const apiUrl = process.env.REACT_APP_API_URL;

const ProductListing = () => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsArrayState, setProductsArrayState] = useState([]);
  const [loading, setLoading] = useState(false);

  // for view of card list or grid--------------------------
  const [viewType, setViewType] = useState("list");
  // ------------------------------------------------------------
  const location = useLocation();

  // const data = location.state?.data;
  const NavDataLoc = useMemo(() => location.state);
  const [NavData, setNavData] = useState(NavDataLoc);
  console.log("NavData of location in open 123", NavData);

  const { id, email, dealerId, roles } = useSelector((state) => state.user);
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: roles === "Dealer" ? [dealerId] : [],
  });

  const [responseFilter, setResponseFilter] = useState({});

  useEffect(() => {
    if (NavData && Object.values(NavData).some((arr) => arr.length > 0)) {
      // If NavData has any non-empty arrays, use it to fetch filtered products
      setFilters(NavData);
      // fetchUpdatdFilters(NavData)
      console.log("Setting NavData in filters to be sent in payload");
      // fetchFilteredProducts(NavData);
      console.log("NavData has some data and used in useEffect", NavData);
    }
    // else if(!NavData && ){

    // }
  }, [NavData]);
  // const fetchUpdatdFilters = async (locationData) => {
  //   try {
  //     const response = await axios.post(`${apiUrl}/listing/filters`, locationData);
  //     console.log("response filters from the API", response.data.data);
  //     setResponseFilter(response.data);
  //     console.log("responseFilter", responseFilter);
  //   } catch (error) {
  //     console.log("Server side while fetching data during getting product");
  //     console.log(error);
  //   }
  // };

  // Add this function to fetch filtered products
  const fetchFilteredProducts = async (filters) => {
    try {
      const response = await axios.post(
        `${apiUrl}/listing/filter_listing`,
        filters
      );
      // Sort the data before setting it in the state
      const sortedData = handleSort(response.data);
      setProductsArrayState(sortedData);
    } catch (error) {
      console.error("Error fetching filtered products:", error);
    }
  };

  useEffect(() => {
    if (Object.values(filters).length > 0) {
      setNavData(null);
    }
    // if (location.state) {
    //   console.log("Setting NavData in filters to be sent in payload");
    //   setFilters(location.state);
    // }
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        let url = `${apiUrl}/listing/filter_listing`;
        let dataToSend = {};

        if (NavData && Object.values(NavData).some((arr) => arr.length > 0)) {
          // If NavData has content, use it for filtering
          dataToSend = NavData;
          console.log("Sending NavData in payload");
        } else if (Object.keys(filters).length > 0) {
          // If NavData is empty but filters has content, use filters

          dataToSend = filters;

          console.log("Sending filters in payload");
        }
        const response = await axios.post(url, dataToSend);
        const sortedData = handleSort(response.data);
        setProductsArrayState(sortedData);
        console.log("dataToSend NavData =>", NavData, " filters => ", filters);
        // If both NavData and filters are empty, send an empty object to get all listings

        setLoading(false);
        // console.log("UseEffect for location plus getlisting")
      } catch (error) {
        console.log("Error fetching products:", error);
        setLoading(false);
      }
    };
    fetchProducts();
  }, [filters, NavData]);

  // Function to check and uncheck the checkbox
  const updateFilters = (filterType, value, isChecked, isSelect = false) => {
    console.log(
      "FilterType 786=> ",
      filterType,
      "value => ",
      value,
      "isChecked => ",
      isChecked
    );
    setFilters((prevFilters) => {
      let updatedFilters = { ...prevFilters };

      if (!Array.isArray(updatedFilters[filterType])) {
        updatedFilters[filterType] = [];
      }

      if (isSelect) {
        updatedFilters[filterType] = [value];
      } else if (isChecked) {
        if (!updatedFilters[filterType].includes(value)) {
          updatedFilters[filterType] = [...updatedFilters[filterType], value];
        }
      } else {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (item) => item !== value
        );
      }

      // if (filterType === "make" && !isChecked && NavData.make.includes(value)) {
      //   console.log(
      //     "Filter type is make and isChecked =>",
      //     isChecked,
      //     "also includes in NavData =>",
      //     NavData.make.includes(value)
      //   );
      //   setNavData(null);
      // }

      // Remove empty arrays from the filters
      Object.keys(updatedFilters).forEach((key) => {
        if (updatedFilters[key].length === 0) {
          delete updatedFilters[key];
        }
      });

      // Always fetch filtered products, even if filters object is empty
      fetchFilteredProducts(updatedFilters);

      return updatedFilters;
    });
  };

  // UseEffect to get all the filters
  const fetchFilters = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/listing/filters`,
        NavData ? NavData : filters
      );
      console.log("response filters from the API", response.data.data);
      setResponseFilter(response.data);
    } catch (error) {
      console.log("Server side while fetching data during getting product");
    }
  };
  useEffect(() => {
    fetchFilters();
  }, [filters]);

  const handleSort = (data, order = "new") => {
    // if (data.length > 1) {
    return [...data].sort((a, b) => {
      return order === "oldest" ? a.id - b.id : b.id - a.id;
      // const dateA = new Date(a.created_at);
      // const dateB = new Date(b.created_at);
      // return order === "oldest" ? dateA - dateB : dateB - dateA;
    });
    // }
    return data; // Return as is if there's nothing to sort
  };

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentPosts = productsArrayState.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const totalPages = Math.ceil(productsArrayState.length / itemsPerPage);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, pageNumber) => pageNumber + 1
  );

  const [setShowDialogueBox] = useState(false);

  const handleShowDialogueBox = (boolValue) => {
    setShowDialogueBox(boolValue);
  };

  const handleSelectedNoOfItems = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever itemsPerPage changes
  };

  const handleShowCurrPageItems = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page whenever itemsPerPage changes
  }, [itemsPerPage]);

  const sponsoredItems = [
    { heading: "Heading 1", subHeading: "Sub Heading 1", img: "randomImage1" },
    { heading: "Heading 2", subHeading: "Sub Heading 2", img: "randomImage2" },
    { heading: "Heading 3", subHeading: "Sub Heading 3", img: "randomImage3" },
    { heading: "Heading 4", subHeading: "Sub Heading 4", img: "randomImage4" },
    { heading: "Heading 5", subHeading: "Sub Heading 5", img: "randomImage5" },
    { heading: "Heading 6", subHeading: "Sub Heading 6", img: "randomImage6" },
    { heading: "Heading 7", subHeading: "Sub Heading 7", img: "randomImage7" },
    { heading: "Heading 8", subHeading: "Sub Heading 8", img: "randomImage8" },
    { heading: "Heading 9", subHeading: "Sub Heading 9", img: "randomImage9" },
    {
      heading: "Heading 10",
      subHeading: "Sub Heading 10",
      img: "randomImage10",
    },
  ];
  //Getting the phone number and email for receiving the directional link
  const [locationAccess, setlocationAccess] = useState({
    latitude: null,
    longitude: null,
  });
  useEffect(() => {
    if (navigator.geolocation) {
      console.log("navigator.geolocation => ", navigator.geolocation);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success callback
          const { latitude, longitude } = position.coords;
          setlocationAccess((prevData) => ({
            ...prevData,
            latitude: latitude,
            longitude: longitude,
          }));
        },
        (err) => {
          // Error callback
          setlocationAccess((prevData) => ({
            ...prevData,
            latitude: null,
            longitude: null,
          }));
          toast.error(
            "Please enable geolocation access in your browser to calculate distance."
          );
        }
      );
    }
  }, [viewType]);

  // ---------------------------------------------
  const [isPermanentlyExpanded, setIsPermanentlyExpanded] = useState(() => {
    // Initialize state from localStorage, default to false if not set
    return localStorage.getItem("sidebarExpanded") === "true";
  });

  useEffect(() => {
    // Update localStorage whenever isPermanentlyExpanded changes
    localStorage.setItem("sidebarExpanded", isPermanentlyExpanded);
  }, [isPermanentlyExpanded]);
  const toggleSidebar = () => {
    setIsPermanentlyExpanded(!isPermanentlyExpanded);
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleSidebarHover = (hovered) => {
    setIsHovered(hovered);
  };
  const isSideBarOpen = useSelector((state) => state.user.isSideBarOpen);
  const [showMobileFiltersPopout, setShowMobileFiltersPopout] = useState(false);
  return (
    <>
      <Navbar />
      {isSideBarOpen && id !== 0 && email && roles === "Dealer" && (
        <div
          className={`dealer_sidebar_home_Parent ${
            isSideBarOpen ? "ShowSidebar" : "HideSidebar"
          }`}
          style={{
            flex: isSideBarOpen ? "0.15" : "0.75",
          }}
        >
          <DealerSideBar
            isPermanentlyExpanded={isPermanentlyExpanded}
            onHoverChange={handleSidebarHover}
          />
        </div>
      )}

      <div className="row productListingWrapper">
        {/* <!---------------------------------------------------------------------sidebar Start -----------------------------------------------------------------------> */}
        <div className=" productListingFilterSidebarWrapper">
          {/* <!-- Collapsible wrapper --> */}
          <div
            className="accordion sidebar-accordians shadow-sm p-3  bg-body rounded "
            id="accordionExample"
          >
            <div className="accordion-item p-1 d-flex justify-content-between">
              <h4>Search Inventry</h4>
              {/* <div className="list-unstyled"> */}
              {/* <p><strong>Color</strong></p> */}
              {/* <DaynamicColor /> */}
              {/* <span>
                    <BiSolidRectangle
                      style={{ fill: "#423635", fontSize: "32px" }}
                    />
                  </span> */}
              {/* </div> */}
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <h2 style={{ fontSize: "1rem" }}>Saved Search</h2>
                </button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">Search</div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap mt-3">
              <div className="filters">
                <h2 style={{ fontSize: "1rem" }}>Search Result</h2>
              </div>
              <div className="filters mt-3">
                <ul className="list-unstyled d-flex justify-content-between">
                  <li>
                    <Link to="#" className="text-dark me-3">
                      {" "}
                      <strong>Saved</strong>{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-dark">
                      {" "}
                      <strong>Reset</strong>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <DropDownList
              responseFilter={responseFilter}
              updateFilters={updateFilters}
              locationState={NavData}
              filters={filters}
            />
            <PriceFilter
              setFilters={setFilters}
              filters={filters}
              NavData={NavData}
            />
            <DropDownListTwo
              responseFilter={responseFilter}
              updateFilters={updateFilters}
              filters={filters}
            />
          </div>
          {/* <!-- Collapsible wrapper --> */}
        </div>
        <div className="productListingContentWrapper">
          <div
            className=" productListingHeader"
            style={{ marginBottom: "1rem" }}
          >
            <p className="productListingSubHeader1">
              {" "}
              Showing {indexOfFirstPost + 1} -
              {Math.min(indexOfLastPost, productsArrayState.length)} of{" "}
              {productsArrayState.length} Listings{" "}
            </p>

            <div className="productListingSubHeader2">
              <img
                src={viewType === "list" ? Image.listViewS : Image.cardView}
                alt="list-view"
                width="30px"
                height="40px"
                className="list-view"
                onClick={() => setViewType("list")}
                style={{ cursor: "pointer" }}
              />
              <img
                src={viewType === "grid" ? Image.listViewS : Image.cardView}
                alt="list-view"
                width="30px"
                height="40px"
                className="list-view"
                onClick={() => setViewType("grid")}
                style={{ marginRight: "15px", cursor: "pointer" }}
              />
              <select
                className="form-select"
                value={itemsPerPage}
                onChange={handleSelectedNoOfItems}
              >
                <option value={5}>Show 5</option>
                <option value={10}>Show 10</option>
                <option value={15}>Show 15</option>
                <option value={20}>Show 20</option>
              </select>

              <span
                className="pLSubHeader2FilterBtn"
                onClick={() => setShowMobileFiltersPopout(true)}
              >
                <FcFilledFilter />
              </span>
            </div>
          </div>
          {/* <!----------------- Card Start --------------------> */}
          <div className="pLMobileContent">
            {currentPosts.map((product, index) => (
              <MobileDataCard
                key={index}
                ProductId={product?.id}
                handleShowDialogueBox={handleShowDialogueBox}
                product={product}
                locationAccess={locationAccess && locationAccess}
              />
            ))}
          </div>
          <div className="pLDesktopContent">
            {loading ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Image.balls_loader}
                  style={{ height: "20%", width: "20%" }}
                />
              </div>
            ) : productsArrayState.length ? (
              viewType === "list" ? (
                <>
                  {currentPosts.map((product, index) => (
                    <CryptoCard
                      product={product}
                      key={index}
                      handleShowDialogueBox={handleShowDialogueBox}
                      ProductId={product?.id}
                      locationAccess={locationAccess && locationAccess}
                    />
                  ))}
                </>
              ) : (
                <div>
                  <GridLayout
                    items={currentPosts}
                    sponsoredItems={sponsoredItems}
                    handleShowDialogueBox={handleShowDialogueBox}
                    locationAccess={locationAccess && locationAccess}
                    // ProductId={product?.id}
                  />
                </div>
              )
            ) : (
              <p>No products found.</p>
            )}
          </div>
          {/* <!----------------- Card End --------------------> */}
          {!loading && (
            <div className="paginationPagesContainer">
              <nav>
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleShowCurrPageItems(currentPage - 1)}
                    >
                      Previous
                    </button>
                  </li>
                  {pageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handleShowCurrPageItems(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleShowCurrPageItems(currentPage + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              
            </div>
          )}
        </div>
        {/* <!---------------------------------------------------------------------Products End -----------------------------------------------------------------------> */}
      </div>

      <ScrollToTopButton />
      <MobileFiltersPopout
        showMobileFiltersPopout={showMobileFiltersPopout}
        setShowMobileFiltersPopout={setShowMobileFiltersPopout}
        setFilters={setFilters}
        filters={filters}
        responseFilter={responseFilter}
        updateFilters={updateFilters}
      />
    </>
  );
};

export default ProductListing;
