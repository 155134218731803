import React, { useState } from "react";
import "./ActiveBalancecalculator.css";
import { evaluate } from "mathjs"; // Import mathjs for evaluation

function ActiveBalanceCalculator({
  setShowModalCalculator,
  setInputValueAmount,
  setDisplayValues,
  displayValues,
  setFormData,
  statusShowCal,
}) {
  const [value, setValue] = useState("");
  console.log("displayValues****7Cal", displayValues);
  console.log("statusShowCal****7Cal", statusShowCal);
  const setDatCalculator = () => {
    if (statusShowCal === 1) {
      setInputValueAmount(value);
    } else if (statusShowCal === 2) {
      const formattedValue = Number(value).toLocaleString();
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        listing_price: formattedValue,
      }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        listing_price: value,
      }));
    } else if (statusShowCal === 3) {
      const formattedValue = Number(value).toLocaleString();
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        purchase_price: formattedValue,
      }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        purchase_price: value,
      }));
    } else if (statusShowCal === 4) {
      const formattedValue = Number(value).toLocaleString();
      //   setDisplayValues((prevDisplayValues) => ({
      //     ...prevDisplayValues,
      //     mileage_in: formattedValue,
      //   }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        payment: value,
      }));
    } else if (statusShowCal === 5) {
      const formattedValue = Number(value).toLocaleString();
      //   setDisplayValues((prevDisplayValues) => ({
      //     ...prevDisplayValues,
      //     mileage_in: formattedValue,
      //   }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        latePaymentFees: value,
      }));
    } else if (statusShowCal === 6) {
      const formattedValue = Number(value).toLocaleString();
      //   setDisplayValues((prevDisplayValues) => ({
      //     ...prevDisplayValues,
      //     mileage_in: formattedValue,
      //   }));
      setFormData((prevDisplayValues) => ({
        ...prevDisplayValues,
        mscOthers: value,
      }));
    }
    setShowModalCalculator(false);
  };

  const handleButtonClick = (e) => {
    const { value: buttonValue } = e.target;
    setValue((prevValue) => (prevValue + buttonValue));
  };

  const handleEqualClick = () => {
    try {
      // Evaluate the expression while respecting BODMAS/PEMDAS rules
      const result = evaluate(value);
      setValue(result.toString());
    } catch (error) {
      setValue("Error");
    }
  };

  const handleClearClick = () => {
    setValue("");
  };

  const handleDeleteClick = () => {
    setValue((prevValue) => prevValue.slice(0, -1));
  };

  return (
    <div className="container">
      <div className="calculator">
        <form action="">
          <div className="display">
            <input type="text" value={value} readOnly />
            <span
              className="close-icon"
              onClick={() => setShowModalCalculator(false)}
            >
              &times;
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="btn-parent-container">
              <input
                className="input_calculator"
                type="button"
                value="C"
                onClick={handleDeleteClick}
                style={{ backgroundColor: "#444444", color: "white" }}
              />
              <input
                className="input_calculator"
                type="button"
                value="AC"
                onClick={handleClearClick}
                style={{ backgroundColor: "#444444", color: "white" }}
              />
              <input
                className="input_calculator"
                type="button"
                value="."
                onClick={handleButtonClick}
                style={{ backgroundColor: "#444444", color: "white" }}
              />
              <input
                className="input_calculator"
                type="button"
                value="/"
                onClick={handleButtonClick}
                style={{
                  background:
                    "linear-gradient(to bottom right, black, #d1823a)",
                  color: "white",
                }}
              />
            </div>
            <div className="btn-parent-container">
              <input
                className="input_calculator"
                type="button"
                value="7"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="8"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="9"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="*"
                onClick={handleButtonClick}
                style={{ backgroundColor: "#d1823a", color: "white" }}
              />
            </div>
            <div className="btn-parent-container">
              <input
                className="input_calculator"
                type="button"
                value="4"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="5"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="6"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="-"
                onClick={handleButtonClick}
                style={{ backgroundColor: "#d1823a", color: "white" }}
              />
            </div>
            <div className="btn-parent-container">
              <input
                className="input_calculator"
                type="button"
                value="1"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="2"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="3"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="+"
                onClick={handleButtonClick}
                style={{ backgroundColor: "#d1823a", color: "white" }}
              />
            </div>
            <div className="btn-parent-container">
              {/* <input className="input_calculator" type="button" value="00" onClick={handleButtonClick} /> */}
              <input
                className="input_calculator"
                type="button"
                value="0"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="("
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value=")"
                onClick={handleButtonClick}
              />
              <input
                className="input_calculator"
                type="button"
                value="="
                onClick={handleEqualClick}
                style={{ backgroundColor: "#d1823a", color: "white" }}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <input
                className="input_calculator equal"
                type="button"
                value="Submit"
                onClick={setDatCalculator}
                style={{
                  background:
                    "linear-gradient(to bottom right, black, #d1823a)",
                  color: "white",
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ActiveBalanceCalculator;
