import React from "react";

const DropDownListOne = ({ responseFilter, updateFilters, filters }) => {
  // console.log(locationState)

  const renderCheckbox = (filterType, value, label, count) => (
    <div className="form-check" key={value}>
      <input
        className="form-check-input filtercar"
        type="checkbox"
        value={value}
        name={value}
        id={value}
        checked={filters[filterType]?.includes(value) || false}
        onChange={(e) => updateFilters(filterType, value, e.target.checked)}
      />
      <label
        className="form-check-label"
        htmlFor={value}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <span>{label}</span>
        {count !== undefined && <span>{count}</span>}
      </label>
    </div>
  );
  return (
    <>
      {/*-----------------------------------drop down 1---------------------------------------------*/}
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            Vehicle Type
          </button>
        </h2>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* <!-- Checked checkbox --> */}
              {/* {responseFilter &&
                responseFilter?.vehicleType?.map((type, i) => (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input filtercar"
                      type="checkbox"
                      value={type}
                      name={type}
                      id={type}
                      onChange={(e) =>
                        updateFilters("vehicleType", type, e.target.checked)
                      }
                    />
                    <label className="form-check-label" htmlFor={type}>
                      {type}
                    </label>
                  </div>
                ))} */}

              {responseFilter?.vehicleType?.map((model) =>
                renderCheckbox("vehicleType", model, model)
              )}
              {/* <!-- Checked checkbox --> */}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <div className="accordion-header" id="headingThree">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Make
          </button>
        </div>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter?.makes?.map((makeItem) =>
                renderCheckbox(
                  "make",
                  makeItem.make,
                  makeItem.make,
                  makeItem.count
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFour">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            Model
          </button>
        </h2>
        <div
          id="collapseFour"
          className="accordion-collapse collapse"
          aria-labelledby="headingFour"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* {responseFilter &&
                responseFilter?.models?.map((model, i) => (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input filtercar"
                      type="checkbox"
                      value={model}
                      name={model}
                      id={model}
                      // checked={filters.models?.includes(model)}
                      checked={
                        // locationState && locationState?.models?.includes(model)
                        //  ||
                          filters?.models?.includes(model)
                        }
                      onChange={(e) =>
                        updateFilters("models", model, e.target.checked)
                      }
                       // Pre-checking the checkbox if it exists in locationState or filters
                      //  checked={locationState.models?.includes(model) || filters.models?.includes(model)}
                      //  onChange={(e) => {
                      //    // Uncheck model and show all items if the model was pre-checked
                      //    if (e.target.checked) {
                      //      updateFilters("models", model, true);
                      //    } else {
                      //      updateFilters("models", model, false);
                      //      if (locationState?.models?.includes(model)) {
                      //        updateFilters("models", model, false);
                      //      }
                      //    }
                      //  }}
                    />
                    <label className="form-check-label" htmlFor={model}>
                      {model}
                    </label>
                  </div>
                ))} */}
              {responseFilter?.models?.map((model) =>
                renderCheckbox("models", model, model)
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFive">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFive"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            DAC Check Score
          </button>
        </h2>
        <div
          id="collapseFive"
          className="accordion-collapse collapse"
          aria-labelledby="headingFive"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* <!-- Default checkbox --> */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked19"
                />
                <label
                  className="form-check-label filtercar"
                  htmlFor="flexCheckChecked1"
                >
                  525
                </label>
                {/* <span className="badge badge-secondary float-end">(2)</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingSix">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseSix"
            aria-expanded="false"
            aria-controls="collapseSix"
          >
            Door Count
          </button>
        </h2>
        <div
          id="collapseSix"
          className="accordion-collapse collapse"
          aria-labelledby="headingSix"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <form>
              <div className="form-group">
                <select className="filtercar" id="mindoor">
                  {responseFilter &&
                    responseFilter?.doorCounts?.map((door, i) => (
                      <option value="0" key={i}>
                        {door}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label>To</label>
              </div>

              <div className="form-group">
                <select
                  className="filtercar"
                  id="maxdoor"
                  onChange={(e) =>
                    updateFilters("doorCounts", e.target.value, true, true)
                  }
                >
                  {responseFilter &&
                    responseFilter?.doorCounts?.map((door, i) => (
                      <option value={door} key={i}>
                        {door}
                      </option>
                    ))}
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingSeven">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseSeven"
            aria-expanded="false"
            aria-controls="collapseSeven"
          >
            Drive Train
          </button>
        </h2>
        <div
          id="collapseSeven"
          className="accordion-collapse collapse"
          aria-labelledby="headingSeven"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {/* <!-- Default checkbox --> */}
              {responseFilter &&
                responseFilter?.driveTrain?.map((drive, i) => (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input filtercar"
                      type="checkbox"
                      value={drive}
                      name={drive}
                      id={drive}
                      onChange={(e) =>
                        updateFilters("driveTrain", drive, e.target.checked)
                      }
                    />
                    <label className="form-check-label" htmlFor={drive}>
                      {drive}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingEight">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseEight"
            aria-expanded="false"
            aria-controls="collapseEight"
          >
            Engine Type
          </button>
        </h2>
        <div
          id="collapseEight"
          className="accordion-collapse collapse"
          aria-labelledby="headingEight"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              {responseFilter &&
                responseFilter?.engineType?.map((engine, i) => (
                  <div className="form-check" key={i}>
                    <input
                      className="form-check-input filtercar"
                      type="checkbox"
                      value={engine}
                      name={engine}
                      id={engine}
                      onChange={(e) =>
                        updateFilters("engineType", engine, e.target.checked)
                      }
                    />
                    <label className="form-check-label" htmlFor={engine}>
                      {engine}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {/*-----------------------------------drop down 1 End ---------------------------------------------*/}
    </>
  );
};

export default DropDownListOne;
