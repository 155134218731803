import React from "react";
import { Modal } from "react-bootstrap";
import styles from "../styles/LoginComponent.module.css";

const SocialQueryAuthModel = ({
  showQueryModel,
  setShowQueryModel,
  handleSelectUserType,
}) => {
  const handleUserType = async (userSelected) => {
    await handleSelectUserType(userSelected);
  };
  return (
    <Modal show={showQueryModel} onHide={() => setShowQueryModel(false)}>
      <Modal.Header closeButton>
        <h4 className={styles.queryModelHeader}>Create Account as</h4>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.queryModelContainer}>
          <button onClick={() => handleUserType("User")}>
            Individual Account
          </button>
          <button onClick={() => handleUserType("Dealer")}>
            Dealer Account
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SocialQueryAuthModel;
