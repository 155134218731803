import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import AddlistingData from "../DealerBalanceInventory/AddlistingData.jsx";
import AddlistingDataSold from "../DealerBalanceInventory/AddlistingDataSold.jsx";
import { IoMdInformationCircleOutline } from "react-icons/io";
import SoldListPreview from "./SoldListPreview.jsx";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
// import Pagination from "../../../Pagination/Pagination";
// import * as XLSX from "xlsx";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import Image from "../../../imgData";
// import styles from './sold.module.css';
// import CardView from "../activeInventory/CradView";

const SoldList = ({
  tableData,
  currentPage,
  tableRef,
  selectedRows,
  handleRowClick,
  handleSortByColumn,
}) => {
  console.log("tableData => ", tableData);
  const Navigate = useNavigate();

  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};
  //  for preview---------------------------------------------
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const handleLinkClickPreview = (itemDataPreview) => {
    setShowPreviewModal(true);
    setPreviewData(itemDataPreview);
  };
  const handleModalClosePrview = () => {
    setShowPreviewModal(false);
  };
  //   for addlisting-------------------------------------------------********************-----------------------
  const [showModalAddlisting, setShowModalAddlisting] = useState(false);
  const [addlistingData, setAddlistingData] = useState([]);
  const handleLinkClickAddlisting = (itemDataAddlisting) => {
    setShowModalAddlisting(true);
    setAddlistingData(itemDataAddlisting);
  };
  const handleModalCloseAddlisting = () => {
    setShowModalAddlisting(false);
  };
  console.log("addlisting*********135", addlistingData);
  //   -----------------------------------------------------------**********************-------------------------
  const isFieldUpdated = (data, fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };

  const handlePublish = (id) => {
    // Handle publish event
    console.log(`Publishing item with id: ${id}`);
  };

  const handleLinkClick = (vin, data) => {
    // Navigate to AddList component programmatically with state
    Navigate("/myprofile/addlist", { state: { vin, data, active: true } });
    // Navigate('/myprofile/addlist', { state: vin, });
  };

  const totalItems = tableData ? tableData.length : 0;
  const itemsPerPage = totalItems > 20 ? totalItems : 20;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(12)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));

  const renderTableRow = (item, index) => {
    // console.log("item in the current list =>234", item);
    // const item = items.sort((a, b) => {
    //   const dateA = new Date(a.sellPriceFees.priceFeeDate);
    //   const dateB = new Date(b.sellPriceFees.priceFeeDate);
      
    //   return dateB - dateA;  // If dateB is later, it will come before dateA
    // });
    if (!item?.addListing2?.year_make_model) {
      return null;
    }
    const year_make_model = item.addListing2.year_make_model;
    const [year, make, model, series, bodyClass] = year_make_model.split(" ");

    // Calculate days difference for the inspection date
    const daysDifference = calculateDaysDifference(item.addListing2.inspection);

    return (
      <>
        <tr
          key={index}
          className={selectedRows.includes(index) ? "selected-rows" : ""}
          onClick={() => item && handleRowClick(index)}
        >
          {item ? (
            <>
              <td>{index + 1}</td>
              <td
                className="border-0 mb-0"
                style={{
                  textShadow: isFieldUpdated(item, "inspection")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                }}
              >
                {item?.sellPriceFees?.priceFeeDate}{" "}
                {/* <span className="border-0 px-1"> {daysDifference}</span> */}
              </td>
              {/* <td
                style={{
                style={{
                  textShadow: isFieldUpdated(item, "mileage_in")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                }}
              >
                {item.saleType}
               
              </td> */}

              <td
                style={{
                  textShadow: isFieldUpdated(item, "listing_price")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                }}
              >
                {item.accountNumber}
              </td>
              <td>
                {item.isBuyerEntity
                  ? item.businessName
                  : `${item.firstName} ${item.lastName}`}
              </td>
              <td
                className="ac-col fw-bold"
                onClick={() => handleLinkClickAddlisting(item)}
              >
                {item.addListing2.vin.slice(-8)}
              </td>
              <td
                className="ac-col fw-bold"
                onClick={() => handleLinkClickAddlisting(item)}
              >
                {item.addListing2.year} {item.addListing2.make}{" "}
                {item.addListing2.model} {item.addListing2.trim}
              </td>
              {/* <td></td>
              <td></td> */}
              <td
                style={{
                  textShadow: isFieldUpdated(item, "exteriorColor")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                }}
              >
                {item.addListing2.exteriorColor || "NA"}
              </td>
              <td>{item.addListing2.vehicleType || "NA"}</td>

              {/* <td
                className="vin-column fw-bold"
                onClick={() => handleLinkClickAddlisting(item)}
              >
                {item.addListing2.vin || "NA"}
              </td> */}
              <td
                style={{
                  textShadow: isFieldUpdated(item, "inspection")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                  fontWeight: "700",
                }}
              >
                                         {" "}{
                                           isNaN(item?.mileageIn) 
                                            ? item?.mileageIn 
                                            : new Intl.NumberFormat().format(item?.mileageIn)
                                          }{" "}
                
                {/* {new Intl.NumberFormat().format(item?.mileageIn)} */}
              </td>
              <td>$ {new Intl.NumberFormat().format(item?.sellPriceFees?.listingPrice)}</td>
              {/* <OverlayTrigger
                placement="bottom-start"
                overlay={
                  <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                    Purchased Date: <br />
                    10/01/2001
                  </Tooltip>
                }
              ></OverlayTrigger> */}
              <td>{item.saleType}</td>
              <td className="text-center">
                <Button
                  onClick={() => handleLinkClickPreview(item)}
                  className="publish-btn border-0 me-1 py-2"
                  style={{ color: "white", width: "70px" }}
                >
                  Privew
                </Button>
                <Button
                  onClick={() => handleLinkClickAddlisting(item)}
                  className="publish-btn border-0  py-2"
                  style={{ color: "white", width: "70px" }}
                >
                  Edit
                </Button>
              </td>
            </>
          ) : (
            Array.from({ length: 12 }).map((_, i) => <td key={i}>&nbsp;</td>)
          )}
        </tr>
        <AddlistingDataSold
          show={showModalAddlisting}
          handleClose={handleModalCloseAddlisting}
          addlistingData={addlistingData}
          addlistionIdShow={2}
        />
        <SoldListPreview
          show={showPreviewModal}
          handleClose={handleModalClosePrview}
          data={previewData}
        />
      </>
    );
  };

  // Calculate total mileage
  const listingPrice = tableData.reduce(
    (total, price) => total + price.listing_price,
    0
  );

  return (
    <div className="table-wrapper rounded px-0 table-scrollbar">
      <Table
        striped
        bordered
        hover
        ref={tableRef}
        className="shadow mb-0"
        id="table"
      >
        <thead>
          <tr>
            <th>No#</th>
            <th className="ac-col">Sale Date</th>
            {/* <th>Sale Type</th> */}
            <th>Acc. No.</th>
            <th>Buyer</th>

            <th>
              Stock No.
              <span className="filter-icons">
                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Descending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Desc", "Stock")}>
                    <TiArrowSortedUp />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Ascending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Asc", "Stock")}>
                    <TiArrowSortedDown />
                  </span>
                </OverlayTrigger>
              </span>
            </th>

            <th>
              Assets Info
              <span className="tooltip-container-active-inventory">
                <IoMdInformationCircleOutline className="info-icon-active-inventory" />
                <div className="tooltip-text-active-inventory">
                  Year Make Model Trim
                </div>
              </span>
              <span className="filter-icons">
                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Descending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Desc", "Vehicle")}>
                    <TiArrowSortedUp />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Ascending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Asc", "Vehicle")}>
                    <TiArrowSortedDown />
                  </span>
                </OverlayTrigger>
              </span>
            </th>
            {/* <th>Make</th>
            <th>Model</th> */}
            <th>
              Color
              <span className="filter-icons">
                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Descending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Desc", "Color")}>
                    <TiArrowSortedUp />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Ascending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Asc", "Color")}>
                    <TiArrowSortedDown />
                  </span>
                </OverlayTrigger>
              </span>
            </th>

            <th>Vehicle Type</th>
            {/* <th className="vin-column">VIN</th> */}
            <th>
              Mileage
              <span className="filter-icons">
                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Descending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Desc", "Mileage")}>
                    <TiArrowSortedUp />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  key="bottom-start"
                  placement="bottom-start"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom-start`}
                      className="table-tooltip"
                    >
                      Ascending
                    </Tooltip>
                  }
                >
                  <span onClick={() => handleSortByColumn("Asc", "Mileage")}>
                    <TiArrowSortedDown />
                  </span>
                </OverlayTrigger>
              </span>
            </th>
            <th>Sold Price</th>
            <th>Sale Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {currentItems
        .sort((a, b) => new Date(b.sellPriceFees.priceFeeDate) - new Date(a.sellPriceFees.priceFeeDate))
        .map(renderTableRow)
       }
          {emptyRows}
        </tbody>
        <tfoot>
          <tr style={{ backgroundColor: "orange" }}>
            <td colSpan={2}></td>
            <td>16</td>
            <td colSpan={5}></td>
            <td>$ {new Intl.NumberFormat().format(listingPrice)}</td>
            <td>34</td>
            <td>675</td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

const calculateDaysDifference = (inspectionDate) => {
  if (!inspectionDate) return 0;
  const currentDate = new Date();
  const inspectionDateObj = new Date(inspectionDate);
  const timeDifference = inspectionDateObj - currentDate;
  return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
};

export default SoldList;
