import React, { useEffect, useRef, useState } from "react";
import styles from "./FinanceFHBHContent.module.css";
import Finance_Taxes_Container from "./Finance_Taxes_Container";
import { Dropdown, Modal } from "react-bootstrap";
import financeStateObject from "./financeStateObject";
// import usaStates from "../DealerInventory/DealerAddInventory/usaStates.json";
import usaStates from "../../DealerInventory/DealerAddInventory/usaStates.json";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useSelector } from "react-redux";
import Image from "../../../imgData";
import {
  setFinanceData,
  setFinanceDynamicAPILabels,
} from "../../../../Redux/slice/financeSlice";
import { setIsSomethingChanged } from "../../../../Redux/slice/sellSlice";

const FinanceFHBHContent = () => {
  const saleTypes = [
    { id: "Retail", label: "Retail/Cash", image: Image.dealer_retail },
    { id: "outOfSale", label: "Out of State", image: Image.dealer_oos },
    { id: "wholeSale", label: "Whole Sale", image: Image.sidebarHome },
  ];

  const [showEditCenter, setShowEditCenter] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const Userdata = useSelector((state) => state.user);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const searchInputRef = useRef(null);
  const [selectedState, setSelectedState] = useState("TX - Texas");
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  const selectedSaleType = retailFromRedux.txtTitleType;
  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);
  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };
  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };
  function switchDynamicLabels(NewSaleType) {
    dispatch(setIsSomethingChanged(true));
    switch (NewSaleType) {
      case "Retail":
        fetchDynamicLabels("Retail");
        break;
      case "wholeSale":
        fetchDynamicLabels("wholeSale");
        break;
      case "outOfSale":
        fetchDynamicLabels("outOfSale");
        break;
      // default:
      //   dispatch(setDynamicAPILabels(retailData));
    }
  }
  //  get the data from api after selecttion of dropdown-------------------
  const fetchDynamicLabels = async (typeSale) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_labels/${Userdata.id}/${typeSale}`
      );
      console.log("Line 31", res.data);
      const mappedResponse =
        res &&
        res.data.sellPriceFeesLabels &&
        res.data.sellPriceFeesLabels
          .filter(
            (item) =>
              item.itemName !== "Sales Tax" && item.itemName !== "Inventory Tax"
          )
          .map((item, index) => ({
            id: item.id,
            label: item.itemName,
            InputValue: Number(item.itemValue),
          }));
      // for tex and inventory tax---------------------------
      const allItems = res && res.data.sellPriceFeesLabels;
      // Extract and get the InputValues for Sales Tax and Inventory Tax separately
      const salesTaxItem = allItems.find(
        (item) => item.itemName === "Sales Tax"
      );
      const inventoryTaxItem = allItems.find(
        (item) => item.itemName === "Inventory Tax"
      );

      // Get the InputValues for both (if they exist)
      const salesTaxInputValue = salesTaxItem
        ? Number(salesTaxItem.itemValue)
        : null;
      const inventoryTaxInputValue = inventoryTaxItem
        ? Number(inventoryTaxItem.itemValue)
        : null;
      console.log("mappedResponse => ", inventoryTaxInputValue);
      if (res.status === 200) {
        console.log("inventoryTaxInputValue => ", inventoryTaxInputValue);
        dispatch(setFinanceDynamicAPILabels(mappedResponse));
        dispatch(
          setFinanceData({
            salesTaxes: salesTaxInputValue,
            mInventoryTax: inventoryTaxInputValue,
          })
        );
      } else {
        // dispatch(setDynamicAPILabels(retailData));
      }
      // dispatch(setDynamicAPILabelsFast(mappedResponse));
    } catch (error) {
      console.log("Error while fetching labels => ", error);
    }
  };
  const [formData, setFormData] = useState(financeStateObject);
  const [paymentSchedule, setPaymentSchedule] = useState(
    formData.finPaymentSchedule
  );

  const handleSaleType = (e) => {};
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.leftContainer}>
            <div className={styles.leftContainerPartOne}>
              {/* Sale Price container */}
              <div className={styles.salePriceWrapper}>
                <div className={styles.salePriceContainer}>
                  <span className={styles.fieldContainerLabel}>Prices</span>
                  <p>1. Sale Price</p>
                  <input
                    type="text"
                    name="finSalePrice"
                    id=""
                    value={formData.finSalePrice}
                  />
                </div>
              </div>
              {/* Total Down container */}
              <div className={styles.totalDownWrapper}>
                <div className={styles.totalDownContainer}>
                  <span className={styles.fieldContainerLabel}>Total Down</span>
                  <div className={styles.downContainer}>
                    <p>2. Down Payment</p>
                    <input
                      type="text"
                      name="finDownPayment"
                      value={formData.finDownPayment}
                      id=""
                    />
                  </div>
                  <div className={styles.downContainer}>
                    <p>3. Deffered Down</p>
                    <input
                      type="text"
                      name="finDefferedPayment"
                      value={formData.finDefferedPayment}
                      id=""
                    />
                  </div>
                  <div className={styles.downContainer}>
                    <p>4. Due date of Deffered</p>
                    <input
                      type="text"
                      name="finDueDateDeffered"
                      value={formData.finDueDateDeffered}
                      id=""
                    />
                  </div>
                </div>
              </div>
              {/* trade In container */}
              <div className={styles.totalDownWrapper}>
                <div className={styles.totalDownContainer}>
                  <span className={styles.fieldContainerLabel}>Trade In</span>
                  <div className={styles.downContainer}>
                    <p>5. Net After Trade-In</p>
                    <input
                      type="text"
                      name="finNetAfterTradeIn"
                      value={formData.finNetAfterTradeIn}
                      id=""
                    />
                  </div>
                </div>
              </div>
              {/* Taxes */}
              <div className={styles.totalTaxesWrapper}>
                <div className={styles.totalTaxesContainer}>
                  <span className={styles.fieldContainerLabel}>Taxes</span>
                  <div className={styles.taxesContainer}>
                    <p>7. Sale Tax</p>
                    <div className={styles.taxesInputContainer}>
                      <input
                        type="text"
                        name="finSaleTaxPerc"
                        value={formData.finSaleTaxPerc}
                        id=""
                      />
                      <input
                        type="text"
                        name="finSaleTaxValue"
                        value={formData.finSaleTaxValue}
                        id=""
                      />
                    </div>
                  </div>
                  <div className={styles.taxesContainer}>
                    <p>8. Inventory Tax</p>
                    <div className={styles.taxesInputContainer}>
                      <input
                        type="text"
                        name="finInventorytaxPerc"
                        value={formData.finInventorytaxPerc}
                        id=""
                      />
                      <input
                        type="text"
                        name="finInventorytaxValue"
                        value={formData.finInventorytaxValue}
                        id=""
                      />
                    </div>
                  </div>
                  <div className={styles.taxesContainer}>
                    <p>9. Total Lines</p>
                    <span> $ 2522.45</span>
                  </div>
                </div>
              </div>
              {/*  Service contract + Gap Insurance */}
              <div className={styles.totalDownWrapper}>
                <div className={styles.ServiceContractContainer}>
                  <span className={styles.fieldContainerLabel}>
                    Service Contract
                  </span>
                  <div className={styles.monthMilesPriceContainer}>
                    <p>
                      <span>9. Months</span>
                      <input
                        type="text"
                        name="finMonths"
                        value={formData.finMonths}
                      />
                    </p>
                    <p>
                      <span>10. Miles</span>
                      <input
                        type="text"
                        name="finMiles"
                        value={formData.finMiles}
                      />
                    </p>
                    <p>
                      <span>11. Price</span>
                      <input
                        type="text"
                        name="finPrice"
                        value={formData.finPrice}
                      />
                    </p>
                  </div>
                </div>
                <div className={styles.totalDownContainer}>
                  <div className={styles.downContainer}>
                    <p
                      className={`${styles.gapFontStyle} ${styles.gapFontStyleDesktop}`}
                    >
                      12.Gap Insurance
                    </p>
                    <input
                      type="text"
                      name="finGapInsurance"
                      value={formData.finGapInsurance}
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.leftContainerPartTwo}>
              <div className={styles.allTaxesWrapper}>
                <div className={styles.allTaxesContainer}>
                  <span className={styles.fieldContainerLabel}>Fees</span>
                  <Finance_Taxes_Container editStatus={false} />
                  <p className={styles.editBtnContainer}>
                    <button onClick={() => setShowEditCenter(true)}>
                      Edit
                    </button>
                  </p>
                </div>
              </div>
              <div className={styles.SubTotalContainer}>
                <span>Subtotal</span>
                <span>$ 12,345.67</span>
              </div>
              <p className={styles.lineBtwSubTotalTaxes}></p>
              <div className={styles.totalTaxesAndFeeWrapper}>
                <div
                  className={`${styles.totalTaxesAndFeesContainer} ${styles.totalTaxesAndFeesContainerDesktop}`}
                >
                  <span>27. Total Fees & Others</span>
                  <span>$ 4,345.67</span>
                </div>
              </div>
              <p className={styles.lineBtwSubTotalTaxes}></p>
            </div>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.totalTaxesAndFeeWrapper}>
              <div className={styles.totalTaxesAndFeesContainer}>
                <span>28. Financed Amount</span>
                <span>$ 4,345.67</span>
              </div>
            </div>
            <div className={styles.finalBoxContainer}>
              <div className={styles.paymentScheduleContainer}>
                <span className={styles.fieldContainerLabel}>
                  Payment Schedule
                </span>
                <div>
                  <p
                    style={{
                      backgroundColor:
                        paymentSchedule === "Weekly" && "#d1823a",
                    }}
                    onClick={() => setPaymentSchedule("Weekly")}
                  ></p>
                  <p onClick={() => setPaymentSchedule("Weekly")}>Weekly</p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor:
                        paymentSchedule === "biWeekly" && "#d1823a",
                    }}
                    onClick={() => setPaymentSchedule("biWeekly")}
                  ></p>
                  <p onClick={() => setPaymentSchedule("biWeekly")}>
                    Bi-Weekly
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor:
                        paymentSchedule === "Monthly" && "#d1823a",
                    }}
                    onClick={() => setPaymentSchedule("Monthly")}
                  ></p>
                  <p onClick={() => setPaymentSchedule("Monthly")}>Monthly</p>
                </div>
              </div>
              <div className={styles.termAPRContainer}>
                <div className={styles.termContainer}>
                  <span>29. Term(monthly)</span>
                  <input
                    type="text"
                    name="finTermMonthly"
                    value={formData.finTermMonthly}
                  />{" "}
                </div>
                <div className={styles.APRContainer}>
                  <span>30. APR(info)</span>
                  <input
                    type="text"
                    name="finAprInfo"
                    value={formData.finAprInfo}
                  />{" "}
                </div>
              </div>
              <div className={styles.termAPRContainer}>
                <div className={styles.termContainer}>
                  <span>31. Monthly Payment</span>
                  <input
                    type="text"
                    name="finMonthlyPayment"
                    value={formData.finMonthlyPayment}
                  />{" "}
                </div>
                <div className={styles.termContainer}>
                  <span>32. Finance Charge</span>
                  <input
                    type="text"
                    name="financeCharge"
                    value={formData.financeCharge}
                  />{" "}
                </div>
                <div className={styles.termContainer}>
                  <span>33. Total Payments</span>
                  <input
                    type="text"
                    name="finTotalPayments"
                    value={formData.finTotalPayments}
                  />{" "}
                </div>
                <div className={styles.termContainer}>
                  <span>34. Total Sale</span>
                  <input
                    type="text"
                    name="finTotalSale"
                    value={formData.finTotalSale}
                  />{" "}
                </div>
              </div>
              <div className={styles.generateFormBtnDesktopContainer}>
                <button className={styles.generateFormBtnDesktop}>
                  Generate Form
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className={styles.generateFormBtnMobile}>Generate Form</button>
      <Modal
        size="xl"
        show={showEditCenter}
        onHide={() => setShowEditCenter(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.labelPopoutHeaderContainer}>
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className={`${styles.labelPopoutHeaderDropDown}`}
            >
              <Dropdown.Toggle
                size="sm"
                className={`px-3 bg-orange border-0 fw-bold text-light ${styles.selectSaleType}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <div className={styles.salesInventoryTaxContainer}>
              <p
                className={styles.salesTaxContainer}
                style={{ color: "#d1823a" }}
              >
                Edit Taxes & Fees (TTL){" "}
                <IoIosInformationCircleOutline
                  title="Taxes & Fees"
                  style={{ cursor: "pointer", color: "grey" }}
                />
              </p>
            </div>
            <div className={styles.salesInventoryTaxContainer}>
              {/* <p className={styles.salesTaxContainer}>
                Edit Taxes & Fees (TTL)
              </p> */}
              <p className={styles.InventoryTaxContainer}>
                <select
                  value={selectedSaleType}
                  className={styles.selectSaleType}
                  onChange={(e) => {
                    dispatch(
                      setFinanceData({ financeSaleType: e.target.value })
                    );
                    switchDynamicLabels(e.target.value);
                  }}
                >
                  {saleTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.salesInventoryTaxContainer}>
            <p
              className={styles.salesTaxContainer}
              style={{ position: "relative" }}
            >
              <span style={{ fontWeight: "bold" }}>Sales Tax</span>
              <span className={styles.percentSymbolSalesTax}>%</span>
              <input
                type="text"
                value={retailFromRedux.salesTaxes}
                style={{ paddingLeft: "10%" }}
                onChange={(e) =>
                  dispatch(setFinanceData({ salesTaxes: e.target.value }))
                }
              />
            </p>
            <p
              className={styles.InventoryTaxContainer}
              style={{ position: "relative" }}
            >
              <span style={{ fontWeight: "bold" }}>Inventory Tax</span>
              <span
                className={styles.percentSymbolSalesTax}
                style={{ left: "41%" }}
              >
                %
              </span>
              <input
                type="text"
                value={retailFromRedux.mInventoryTax}
                style={{ paddingLeft: "10%" }}
                onChange={(e) =>
                  dispatch(setFinanceData({ mInventoryTax: e.target.value }))
                }
              />
            </p>
          </div>
          <div className={styles.boxContainer}>
            <div className={styles.stateSaleTypeContainer}>
              {" "}
              <Dropdown
                show={isOpen}
                onSelect={handleSelectState}
                onToggle={(open) => setIsOpen(open)}
                className={styles.labelPopoutHeaderDropDown}
              >
                <Dropdown.Toggle
                  size="sm"
                  className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {selectedState}
                </Dropdown.Toggle>
                <Dropdown.Menu className="state-drp">
                  {/* Hidden input to manage search */}
                  <input
                    type="text"
                    value={search}
                    ref={searchInputRef}
                    autofocus
                    onChange={handleSearchChange}
                    onFocus={() => setIsOpen(true)}
                    style={{
                      position: "absolute",
                      top: "-9999px",
                      left: "-9999px",
                    }} // Hide the input field
                  />
                  {filteredStates.length > 0 ? (
                    filteredStates.map((state) => (
                      <Dropdown.Item
                        key={state.name}
                        eventKey={state.name}
                        onClick={() => handleSelect(state.name)}
                      >
                        {state.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item disabled>No states found</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <select onChange={handleSaleType}>
                {saleTypes.map((type) => (
                  <option key={type.id} value={type.label}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.salesInventoryTaxContainerMobile}>
              <p className={styles.salesTaxContainer}>
                <span>Sales Tax</span>
                <input
                  type="text"
                  value={retailFromRedux.salesTaxes}
                  onChange={(e) =>
                    dispatch(setFinanceData({ salesTaxes: e.target.value }))
                  }
                />
              </p>
              <p className={styles.InventoryTaxContainer}>
                <span>Inventory Tax</span>
                <input
                  type="text"
                  value={retailFromRedux.mInventoryTax}
                  onChange={(e) =>
                    dispatch(setFinanceData({ mInventoryTax: e.target.value }))
                  }
                />
              </p>
            </div>
          </div>
          <Finance_Taxes_Container editStatus={true} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default FinanceFHBHContent;
