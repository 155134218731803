const financeStateObject = {
  finSalePrice: 0,
  finDownPayment: 0,
  finDefferedPayment: 0,
  finDueDateDeffered: 0,
  finNetAfterTradeIn: 0,
  finSaleTaxPerc: 0,
  finSaleTaxValue: 0,
  finInventorytaxPerc: 0,
  finInventorytaxValue: 0,
  finMonths: 0,
  finMiles: 0,
  finPrice: 0,
  finGapInsurance: 0,
  financeExtraFees: [],
  finTotalFeesOther: 0,
  financedAmount: 0,
  finPaymentSchedule: "Monthly",
  finTermMonthly: 0,
  finAprInfo: "",
  finMonthlyPayment: 0,
  financeCharge: 0,
  finTotalPayments: 0,
  finTotalSale: 0,
};
export default financeStateObject;
