import React, { useEffect, useRef, useState } from "react";
import CardView from "./CradView";
import "./active.css";
import Image from "../../../imgData";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TiArrowUnsorted } from "react-icons/ti";
import { BiReset } from "react-icons/bi";
import Select from "react-select";
import axios from "axios";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Pagination from "../../../Pagination/Pagination";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  incrementCounter,
  saveView,
} from "../../../../Redux/slice/activeSlice";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";

import {
  resetBuyerSellData,
  resetCoBuyerSellData,
  setActiveTab,
  setSelectedVehicle,
  setVehicleData,
} from "../../../../Redux/slice/sellSlice";
import SellData from "./SellData";
import AddlistingData from "../DealerBalanceInventory/AddlistingData";
import AdvertisingProfile from "./AdvertisingProfile";
// import CustomPrompt from './CustomPrompt';
import { Tooltip as ToolTip1 } from "react-tooltip";
import { saveUser } from "../../../../Redux/slice/userSlice";
import ScrollToTopButton from "../../../footer/ScrollToTopButton";
import ReactFocusLock from "react-focus-lock";
import AddlistingDataAddCost from "../DealerBalanceInventory/AddlistingDataAddCost";
const apiUrl = process.env.REACT_APP_API_URL;

const DealerActiveInventory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || {};
  const dispatch = useDispatch();
  const [counterAddLisitng, setCounterAddListing] = useState(0);
  const [counterb, setCounterb] = useState(0);
  const [counterc, setCounterc] = useState(0);
  const view = useSelector((state) => state.active.view);
  const counter = useSelector((state) => state.active.counter);
  const state = useSelector((state) => state);
  console.log("counter", counter);
  //  for new filter-----------------------------------
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedBodyType, setSelectedBodyType] = useState(null);
  const [dropdownData, setDropdownData] = useState();
  // console.log("view", view);
  // console.log("Full State:", state);
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  // For all dropdown button in this page
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [showPrompt, setShowPrompt] = useState(false);
  // const [showRowCheck, setShowRowCheck] = useState(false)
  const { dataFromMarquee = null } = location.state || {};
  console.log("dataFromMarquee 84", dataFromMarquee);
  const [selectedDataInMarquee, setSelectedDataInMarquee] = useState(null);

  const [email, setEmail] = useState("");
  const handleViewToggle = (newView) => {
    dispatch(saveView(newView));
  };

  const [initialFilters, setInitialFilters] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);

  // Update useEffect to handle direct access
  // Separate effect for handling selected make
  // useEffect(() => {
  //   if (selectedDataInMarquee) {
  //     setSelectedMake(selectedDataInMarquee);
  //     handleFilterDDClick("make", selectedDataInMarquee);
  //   }
  // }, [selectedDataInMarquee]);

  // useEffect(() => {
  //   console.log("counter", counter);
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/listing/get_user_listing/${dealerId}`
  //       );
  //       const data = await response.json();

  //       if (response.ok) {
  //         console.log("before handle sort tableData", data, tableData);
  //         setTableData(data);
  //         setInitialFilters(data);
  //         console.log("after handle sort tableData", tableData);
  //       }
  //     } catch (error) {
  //       setError(error);
  //       throw new Error("Failed to fetch data");
  //     } finally {
  //       setIsLoading(false);
  //       console.log("after handle sort tableData", tableData);
  //     }
  //   };

  //   const data = location.state || {};
  //   if (Object.keys(data).length > 0) {
  //     setTableData(Array.isArray(data) ? data : []);
  //     setIsLoading(false);
  //   } else {
  //     fetchData();
  //   }

  //   // Add a cleanup function
  //   return () => {
  //     // Cancel any ongoing fetch if component unmounts
  //   };
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log(" initialFilters >>>>>>>>", initialFilters);
  //   }, 200);
  // }, [initialFilters]);

  // const handleSort = (order) => {
  //   // const sorted = [...tableData].sort((a, b) => {
  //   //   const dateA = new Date(a.created_at);
  //   //   const dateB = new Date(b.created_at);
  //   //   return order === "oldest" ? dateA - dateB : dateB - dateA;
  //   // });
  //   console.log("__________order", order);
  //   if (order) {
  //     const sorted = [...tableData].sort((a, b) => a.id - b.id);
  //     console.log("__________sorted newest", sorted);
  //     setTableData(sorted);
  //   } else {
  //     const sorted = [...tableData].sort((a, b) => b.id - a.id);
  //     console.log("__________sorted oldest", sorted);
  //     setTableData(sorted);
  //   }
  // };

  const dropdownClickedRef = useRef(false);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleEmailClick = () => {
    // Handle email button click
  };

  const handleDownloadClick = () => {
    // Handle download button click
  };

  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // For calculation of DaysIn collumn
  const [daysDifference, setDaysDifference] = useState(0);
  // Calculate the number of days difference
  const calculateDaysDifference = (inspectionDate) => {
    if (!inspectionDate) return 0;
    const currentDate = new Date();
    const inspectionDateObj = new Date(inspectionDate);
    const timeDifference = inspectionDateObj - currentDate;
    return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
  };

  // For pdf geration
  const handlePrintClick = async () => {
    // const selected = selectedRows.map(index => tableData[index].vin);

    try {
      // Generate the XLSX file data
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(tableData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Open the XLSX file in a new window/tab
      const printWindow = window.open(url);

      // Wait for a short delay to ensure the XLSX file is loaded
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Print the content in the new window/tab
      if (printWindow) {
        printWindow.print();
      } else {
        throw new Error("Failed to open print window.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to print. Please try again.");
    }
  };

  // For selection of cards
  const [selectedCards, setselectedCards] = useState([]);

  const handleSelected = (index) => {
    setselectedCards((prevselectedCards) => {
      if (prevselectedCards.includes(index)) {
        // If the card is already selected, remove it
        return prevselectedCards.filter((i) => i !== index);
      } else {
        // If the card is not selected, add it
        return [...prevselectedCards, index];
      }
    });
  };

  useEffect(() => {
    dispatch(
      setRetailData({
        showSellNav: selectedCards,
      })
    );
  }, [selectedCards]);
  console.log("selectedCards", selectedCards);
  const handleRowClick = (index) => {
    // If the clicked row is already selected, deselect it
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      // If the clicked row is not selected, select it
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    }
  };

  // Function to handle export button click
  const tableRef = useRef(null);

  const handleExportClick = () => {
    // Show popup/modal/dialog to input email address
    // console.log("tableData", tableData)

    // setShowRowCheck(true);

    const email = prompt("Please enter your email address:");
    // setShowPrompt(true);

    if (email) {
      // Get the data of selected rows
      const selectedVin = selectedRows.map((index) => tableData[index].vin);

      // If user selected rows and entered an email address
      if (selectedVin.length > 0) {
        fetch(`${apiUrl}/active_inventory/sendExcelEmail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, selectedVin: selectedVin }),
        })
          .then((response) => {
            if (response.ok) {
              // If request is successful, display success message
              alert("Email has been sent successfully!");
            } else {
              // If request fails, display error message
              alert("Failed to send email. Please try again.");
            }
          })
          .catch((error) => {
            // If fetch fails, display error message
            console.error("Error:", error);
            alert("An error occurred. Please try again later.");
          });
      } else {
        // If no rows are selected, prompt the user to select rows
        alert("Please select rows to export.");
      }

      console.log("selectedData", selectedVin);
    }
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const handleWebsite = (event) => {
    event.stopPropagation();
    alert("Added");
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    alert("Edited");
  };

  const handleDelete = async (event, cardId, index) => {
    event.stopPropagation();

    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this inventory item?"
    );

    if (!userConfirmed) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    try {
      const listing_id = "listing";
      const response = await fetch(
        `${apiUrl}/listing/permanent_delete/${dealerId}/${cardId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the card");
      }
      navigate("/dealer/recycle-bin");
      // Update the state to reflect the deletion
      const newTableData = tableData.filter((_, i) => i !== index);
      setTableData(newTableData);
    } catch (error) {
      console.error("Error deleting card:", error);
    } finally {
      // dispatch(incrementCounter());
      // setInventoryIndex(null);
      // setShowModalAddlisting(false);
      // setShowModalAdd(false);
    }
  };

  const handleUpdate = (id, updatedData) => {
    setTableData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, addvertising: updatedData } : item
      )
    );
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.id === id ? { ...item, addvertising: updatedData } : item))
    // );
  };

  // console.log("tableData", tableData);

  //Fetching the filters
  // const [openDropdown, setOpenDropdown] = useState(null);
  //   First fetch the filters
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    bodyClass: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: [dealerId && dealerId],
    zip_code: [],
  });
  // console.log("Filter selected ", filters);

  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "bodyClass",
  });

  const handleFilterDDClick = (PropToUpdate, ValueForUpdate) => {
    console.log("PropToUpdate, ValueForUpdate", PropToUpdate, ValueForUpdate);

    // // Close the dropdown after selection
    // setOpenDropdown(null);

    if (!ValueForUpdate) {
      setSelectedFilter((prevData) => {
        const updatedFilter = {
          ...prevData,
          [PropToUpdate]:
            PropToUpdate === "make"
              ? "Make"
              : PropToUpdate === "models"
              ? "Model"
              : PropToUpdate === "year"
              ? "Year"
              : "bodyClass",
        };

        // Update filters for null selection
        setFilters((prevData) => ({
          ...prevData,
          [PropToUpdate]: [],
        }));

        console.log("Updated selectedFilter (null case):", updatedFilter);
        return updatedFilter;
      });

      return;
    }

    // If ValueForUpdate is not null, update the view and API with the value
    setSelectedFilter((prevData) => {
      const updatedFilter = {
        ...prevData,
        [PropToUpdate]: ValueForUpdate,
      };

      // Set filters: if year, send as array of strings
      const valueToUpdate =
        PropToUpdate === "year"
          ? [String(ValueForUpdate), String(ValueForUpdate)]
          : [ValueForUpdate];

      setFilters((prevData) => ({
        ...prevData,
        [PropToUpdate]: valueToUpdate,
      }));

      console.log("Updated selectedFilter:", updatedFilter);
      return updatedFilter;
    });
  };

  const handleResetFilter = () => {
    setOpenDropdown(null);
    setFilters({
      make: [],
      models: [],
      year: [],
      bodyClass: [],
      engineType: [],
      fuelType: [],
      vehicleType: [],
      owner: [],
      state: [],
      interiorColor: [],
      exteriorColor: [],
      interiorType: [],
      doorCounts: [],
      driveTrain: [],
      odometer: [],
      price: [],
      exterior_damage: [],
      photos: [],
      seller: [dealerId && dealerId],
      zip_code: [],
    });

    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "bodyClass",
    });

    dispatch(incrementCounter());
    setSelectedYear("");
    setSelectedMake("");
    setSelectedModal("");
    setSelectedBodyType("");
    setSelectedSort("");
    setCounterb((prev) => prev + 1);
  };
  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  //Here after changing the filter fetch the products again
  // useEffect(() => {
  //   console.log("I am inside the useEffect and value of counter just changed => ", counter);
  //   const fetchProducts = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/listing/filter_listing`,
  //         filters
  //       );
  //       console.log("response filters from the API", response.data);

  //       if (response.status === 200) {
  //         console.log("response filters from the API", response.data);
  //         const data = response.data;
  //         const sortedData = data.sort((a, b) => a.id - b.id);
  //         setTableData(Array.isArray(sortedData) ? sortedData : []);
  //         console.log("response filters from the API", sortedData, tableData);
  //       }
  //     } catch (error) {
  //       console.log("Server side while fetching data during getting filters");
  //     }
  //   };
  //   fetchProducts();
  // }, [filters,counter]);
  const isFirstRender = useRef(true); // Flag to check if it's the first render

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Set the flag to false after first render
      return; // Skip the API call on first render
    }
    // This code will only run when filters change (after the initial render)
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filter_listing`,
          filters
        );
        console.log("response filters from the API", response.data);

        if (response.status === 200) {
          const data = response.data;
          const sortedData = data.sort((a, b) => a.id - b.id);
          setTableData(Array.isArray(sortedData) ? sortedData : []);
          console.log("Sorted data", sortedData);
        }
      } catch (error) {
        console.log("Error fetching data while applying filters", error);
      }
    };

    fetchProducts();
  }, [filters, counterAddLisitng]);
  // -------------------------
  useEffect(() => {
    if (dataFromMarquee) {
      return; // Skip the API call if dataFromMarquee is available
    }
    // This code will only run when filters change (after the initial render)
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filter_listing`,
          filters
        );
        console.log("response filters from the API", response.data);

        if (response.status === 200) {
          const data = response.data;
          const sortedData = data.sort((a, b) => a.id - b.id);
          setTableData(Array.isArray(sortedData) ? sortedData : []);
          console.log("Sorted data", sortedData);
        }
      } catch (error) {
        console.log("Error fetching data while applying filters", error);
      }
    };

    fetchProducts();
  }, [filters, counterAddLisitng]);

  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedValue, type = "") => {
    // Close the dropdown after selection
    console.log("selected val", selectedValue, type);
    setOpenDropdown(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_user_listing/${dealerId}?searchTerm=${selectedValue}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      // Update the selected filter based on the type
      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedValue, // Use computed property name here
        }));
      }
      // else if (type === "make") {
      //   setSelectedFilter((prev) => ({
      //     ...prev,
      //     make: selectedValue,
      //   }));
      // }

      setTableData(data);
      // setSelectedSort(selectedValue);
      console.log("selectedFilter", selectedFilter, type, selectedValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };

  const handleSortByColumn = async (sort, column) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_user_listing/${dealerId}?searchTerm=${sort}&extraParam=${column}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //--------------------------------------------------------------------
  //seraching on key down event

  const listRef = useRef(null);
  useEffect(() => {
    if (listRef.current) {
      console.log("listRef => ", listRef);
      listRef.current.focus();
    }
  }, [listRef]);
  // --------------------------------------------------------------New Year make Modal filter-----------------------------**********-------------
  // Function to transform the data into the desired format for each dropdown
  const transformOptions = (data, label) => {
    // Check if the data is an array before proceeding
    if (!Array.isArray(data)) {
      console.error(`${label} data is not an array`, data);
      return [];
    }

    return [
      { value: "", label: label }, // Default empty value for all dropdowns
      ...data
        .map((item) => ({
          value: item.toString(),
          label: item.toString(),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  };

  // Generating options
  const optionsYear = transformOptions(dropdownData?.year, "Year");
  const optionsMake = transformOptions(dropdownData?.make, "Make");
  const optionsModal = transformOptions(dropdownData?.model, "Model");
  const optionsBodyType = transformOptions(
    dropdownData?.bodyStyle,
    "bodyClass"
  );

  console.log("dropdownData", dropdownData);
  const handleSelectedYear = (event) => {
    const selectedOption = optionsYear.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedYear(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("year", selectedOption.value);
  };
  const handleSelectedMake = (event) => {
    const selectedOption = optionsMake.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected selectedOption => ", selectedOption);
    setSelectedMake(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("make", selectedOption.value);
  };
  const handleSelectedModal = (event) => {
    const selectedOption = optionsModal.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedModal(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("models", selectedOption.value);
  };
  const handleSelectedBodyType = (event) => {
    const selectedOption = optionsBodyType.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedBodyType(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("bodyClass", selectedOption.value);
  };
  console.log("selectedYear", selectedYear);
  const objFor = {
    year: selectedYear ? parseInt(selectedYear) : null,
    make: selectedMake && selectedMake !== "" ? selectedMake : null,
    model: selectedModal && selectedModal !== "" ? selectedModal : null,
    bodyStyle:
      selectedBodyType && selectedBodyType !== "" ? selectedBodyType : null,
    userId: dealerId,
  };
  console.log("selectedYear", selectedYear);
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/active_filters`,
          objFor,
          {}
        );
        console.log("Response:", response.data);
        setDropdownData(response.data);
        // setCounterc((prev)=>prev+1);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    // Trigger the POST request
    postData();
  }, [counterb]);
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (dataFromMarquee) {
      // setSelectedDataInMarquee(dataFromMarquee);
      setSelectedMake(dataFromMarquee);
      handleFilterDDClick("make", dataFromMarquee);
      setApiCalled(true);
      setCounterb((prev) => prev + 1);
      dispatch(incrementCounter());
    } else {
      setSelectedDataInMarquee(null);
      setSelectedMake(null);
      setInitialFilters([]);
    }

    // // Cleanup function to reset state on unmount
    // return () => {
    //   setSelectedDataInMarquee(null);
    //   setSelectedMake(null);
    //   setInitialFilters([]);
    // };
  }, [dataFromMarquee]);

  return (
    <>
      <Container fluid className="px-4" style={{ marginTop: "-0.49rem" }}>
        <Row className="px-3">
          <Col sm={8} className="px-0 d-flex justify-content-center">
            <div className="inventory-info">
              <span className="inventory">All Inventory:</span>
              <span className="inventory-count orange-color">
                {tableData?.length}
              </span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Published:</span>
              <span className="inventory-count orange-color">0</span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Not Published:</span>
              <span className="inventory-count orange-color">0</span>
            </div>

            <div className="inventory-info">
              <span className="inventory">Total Leads:</span>
              <span className="inventory-count orange-color">0</span>
            </div>
          </Col>
          <Col
            sm={4}
            className="  d-flex "
            style={{
              justifyContent: "end",
              padding: "0 ",
              paddingRight: "2rem",
            }}
          >
            {/* Icon in the first column */}
            {/* <div className="toggle-view">
              <img
                src={view == "c" ? Image.cardViewS : Image.cardView}
                alt="card-view"
                width="50%"
                height="100%"
                className="card-view"
                onClick={() => handleViewToggle("c")}
              />

              <img
                src={view == "l" ? Image.listViewS : Image.listView}
                alt="list-view"
                width="50%"
                height="100%"
                className="list-view"
                onClick={() => handleViewToggle("l")}
              />
            </div> */}
          </Col>

          <Col className="d-flex justify-content-end align-items-center px-0">
            {view === "l" ? <></> : null}
          </Col>
        </Row>

        {view == "l" ? (
          <>
            <Row style={{ paddingRight: "2rem" }}>
              <Col sm={12} className="px-0" style={{ position: "relative" }}>
                <Container fluid className="px-3">
                  <Row>
                    {/* input field start */}
                    <Col
                      sm={11}
                      className=" d-flex mt-3 mb-4 "
                      id="act-search"
                      style={{ width: "100%" }}
                    >
                      <Button
                        className={`btn border-0 dropdown-toggle remove-border-radi`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      >
                        All
                      </Button>
                      <ul className="dropdown-menu">
                        <li onClick={() => handleSelect("Price10k")}>
                          <Link className="dropdown-item" to="#">
                            Price &lt; 10k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage50k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 50k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage100k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 100k
                          </Link>
                        </li>
                      </ul>

                      <input
                        type="text"
                        className="form-control remove-border-radi"
                        placeholder="Search Inventory"
                        style={{
                          boxShadow: "inset 0 0 9px 2px rgb(209, 130, 58)",
                        }}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                        onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                      />

                      <Button
                        className="btn border-0 remove-border-radi"
                        type="button"
                        id="button-addon1"
                        style={{
                          backgroundColor: "#e8a262",
                          borderRadius: "none",
                        }}
                        onClick={handleButtonClick}
                      >
                        <i className="fas fa-search"></i>
                      </Button>
                      <div className="new-filter-active">
                        <select
                          value={selectedYear}
                          onChange={handleSelectedYear}
                          style={{ width: "auto" }}
                          placeholder="Year"
                        >
                          {optionsYear.length === 0 ? (
                            <option disabled>No year found</option>
                          ) : (
                            optionsYear.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                      <div className="new-filter-active">
                        <select
                          value={selectedMake}
                          onChange={handleSelectedMake}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsMake.length === 0 ? (
                            <option disabled>No make found</option>
                          ) : (
                            optionsMake.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="new-filter-active">
                        <select
                          value={selectedModal}
                          onChange={handleSelectedModal}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsModal.length === 0 ? (
                            <option disabled>No modal found</option>
                          ) : (
                            optionsModal.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="new-filter-active">
                        <select
                          value={selectedBodyType}
                          onChange={handleSelectedBodyType}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsBodyType.length === 0 ? (
                            <option disabled>No body-type found</option>
                          ) : (
                            optionsBodyType.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div>
                        <Button
                          className={`btn border-0 dropdown-toggle btn-eq-wd remove-border-radi ${
                            selectedSort ? "bg-secondary" : ""
                          }`}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded={openDropdown === "sort-by"}
                          id="sortBy" // Added ID for accessibility
                          style={{
                            backgroundColor: "#e8a262",
                            margin: "0 0.3rem",
                            height: "3.3rem",
                          }}
                        >
                          Sort by
                        </Button>
                        <ul
                          className={`dropdown-menu ${
                            openDropdown === "sort-by" ? "show" : ""
                          }`}
                          aria-labelledby="sortBy"
                          style={{ maxHeight: "300px" }}
                        >
                          <li onClick={() => handleSelect("Asc")}>
                            <Link
                              className={`dropdown-item ${
                                selectedSort === "Asc" ? "selected-item" : ""
                              }`}
                              to="#"
                            >
                              Oldest
                            </Link>
                          </li>
                          <li onClick={() => handleSelect("Desc")}>
                            <Link
                              className={`dropdown-item ${
                                selectedSort === "Desc" ? "selected-item" : ""
                              }`}
                              to="#"
                            >
                              Newest
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <Button
                        className="btn border-0 btn-eq-wd remove-border-radi"
                        type="button"
                        id="button-addon1"
                        style={{
                          backgroundColor: "#e8a262",
                          height: "3.3rem",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                        onClick={handleResetFilter}
                        data-tooltip-id="reset1"
                        data-tooltip-content="Reset"
                        data-event="click"
                      >
                        <BiReset className="icons-r-flt" />
                      </Button>
                      <ToolTip1 id="reset1" place="top" />
                      <div
                        className="toggle-view"
                        style={{
                          width: "13rem",
                          height: "2.9rem",
                          marginLeft: "5px",
                        }}
                      >
                        <img
                          src={view == "c" ? Image.cardViewS : Image.cardView}
                          alt="card-view"
                          width="50%"
                          height="100%"
                          className="card-view"
                          onClick={() => handleViewToggle("c")}
                        />

                        <img
                          src={view == "l" ? Image.listViewS : Image.listView}
                          alt="list-view"
                          width="50%"
                          height="100%"
                          className="list-view"
                          onClick={() => handleViewToggle("l")}
                        />
                      </div>
                    </Col>
                    {/* input field end */}
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row
              className="mx-0 rounded  bg-body border-2"
              style={{
                position: "relative",
                top: "",
                zIndex: "5 ",
                // minWidth: "1000px", overflowX: "scroll"
              }}
            >
              {/* <BasicDocument /> */}
              <ListView
                tableData={tableData}
                currentPage={currentPage}
                tableRef={tableRef}
                selectedRows={selectedRows}
                handleRowClick={handleRowClick}
                handleDelete={handleDelete}
                onUpdate={handleUpdate}
                handleSortByColumn={handleSortByColumn}
              />

              {/* <Col sm={12}>
                <Pagination
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Col> */}
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col sm={1} className="h-100">
                {/* <div className="select-btn"></div> */}
              </Col>
              <Col
                sm={11}
                className="px-0"
                style={{ position: "relative", width: "98%" }}
              >
                <Container fluid className="px-3">
                  <Row
                    style={{ paddingLeft: "3.4rem" }}
                    className="starting_of_filter_sorting"
                  >
                    {/* input field start */}
                    <Col
                      sm={12}
                      className="input-group mt-3 mb-4"
                      id="act-search"
                    >
                      <Button
                        className="btn border-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          backgroundColor: "#e8a262",
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      >
                        All
                      </Button>
                      <ul className="dropdown-menu">
                        <li onClick={() => handleSelect("Price10k")}>
                          <Link className="dropdown-item" to="#">
                            Price &lt; 10k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage50k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 50k
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Mileage100k")}>
                          <Link className="dropdown-item" to="#">
                            Miles &lt; 100k
                          </Link>
                        </li>
                      </ul>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Inventory"
                        style={{
                          boxShadow: "inset 0 0 9px 2px rgb(209, 130, 58)",
                        }}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                        onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                      />

                      <Button
                        className="btn border-0"
                        type="button"
                        id="button-addon1"
                        style={{ backgroundColor: "#e8a262" }}
                        onClick={handleButtonClick}
                      >
                        <i className="fas fa-search"></i>
                      </Button>
                      {/* start--------------------------------new filter---------------------------------------------------- */}
                      <div className="new-filter-active">
                        <select
                          value={selectedYear}
                          onChange={handleSelectedYear}
                          style={{ width: "auto" }}
                          placeholder="Year"
                        >
                          {optionsYear.length === 0 ? (
                            <option disabled>No year found</option>
                          ) : (
                            optionsYear.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                      <div className="new-filter-active">
                        <select
                          value={selectedMake}
                          onChange={handleSelectedMake}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsMake.length === 0 ? (
                            <option disabled>No make found</option>
                          ) : (
                            optionsMake.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="new-filter-active">
                        <select
                          value={selectedModal}
                          onChange={handleSelectedModal}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsModal.length === 0 ? (
                            <option disabled>No modal found</option>
                          ) : (
                            optionsModal.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="new-filter-active">
                        <select
                          value={selectedBodyType}
                          onChange={handleSelectedBodyType}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsBodyType.length === 0 ? (
                            <option disabled>No body-type found</option>
                          ) : (
                            optionsBodyType.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                      {/*----------------------- end new filter----------------------------------- */}

                      <div>
                        <Button
                          className={`btn border-0 dropdown-toggle btn-eq-wd  remove-border-radi ${
                            selectedSort ? "bg-secondary" : ""
                          }`}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded={openDropdown === "sort-by"}
                          id="sortBy" // Added ID for accessibility
                          style={{
                            backgroundColor: "#e8a262",
                            margin: "0 0.3rem",
                            height: "3.3rem",
                          }}
                        >
                          Sort by
                        </Button>
                        <ul
                          className={`dropdown-menu ${
                            openDropdown === "sort-by" ? "show" : ""
                          }`}
                          aria-labelledby="sortBy"
                          style={{ maxHeight: "300px" }}
                        >
                          <li onClick={() => handleSelect("Asc")}>
                            <Link
                              className={`dropdown-item ${
                                selectedSort === "Asc" ? "selected-item" : ""
                              }`}
                              to="#"
                            >
                              Oldest
                            </Link>
                          </li>
                          <li onClick={() => handleSelect("Desc")}>
                            <Link
                              className={`dropdown-item ${
                                selectedSort === "Desc" ? "selected-item" : ""
                              }`}
                              to="#"
                            >
                              Newest
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <Button
                        className="btn border-0 btn-eq-wd"
                        type="button"
                        id="button-addon1"
                        style={{
                          backgroundColor: "#e8a262",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                        onClick={handleResetFilter}
                        data-tooltip-id="reset1"
                        data-tooltip-content="Reset"
                        data-event="click"
                      >
                        <BiReset className="icons-r-flt" />
                      </Button>
                      <ToolTip1 id="reset1" place="top" />
                      <div
                        className="toggle-view"
                        style={{
                          width: "5rem",
                          height: "3rem",
                          marginLeft: "5px",
                        }}
                      >
                        <img
                          src={view == "c" ? Image.cardViewS : Image.cardView}
                          alt="card-view"
                          width="50%"
                          height="100%"
                          className="card-view"
                          onClick={() => handleViewToggle("c")}
                        />

                        <img
                          src={view == "l" ? Image.listViewS : Image.listView}
                          alt="list-view"
                          width="50%"
                          height="100%"
                          className="list-view"
                          onClick={() => handleViewToggle("l")}
                        />
                      </div>
                    </Col>
                    {/* input field end */}
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row className="mx-0 rounded bg-body border-2 bg-tr">
              {Array.isArray(tableData) ? (
                tableData
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((data, index) => {
                    return (
                      <CardView
                        data={{
                          ...data,
                          daysDifference: calculateDaysDifference(
                            data.inspection
                          ),
                        }}
                        index={index}
                        key={index}
                        selectedCards={selectedCards}
                        handleSelected={handleSelected}
                        handleWebsite={handleWebsite}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        onUpdate={handleUpdate}
                        setCounterAddListing={setCounterAddListing}
                      />
                    );
                  })
              ) : (
                <p>No data available</p>
              )}
            </Row>
          </>
        )}
      </Container>
      <ScrollToTopButton />
    </>
  );
};

// Define the calculateDaysDifference function outside of ListView
const calculateDaysDifference = (inspectionDate) => {
  if (!inspectionDate) return 0;
  const currentDate = new Date();
  const inspectionDateObj = new Date(inspectionDate);
  const timeDifference = inspectionDateObj - currentDate;
  return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
};

const ListView = ({
  tableData,
  currentPage,
  tableRef,
  selectedRows,
  handleRowClick,
  handleDelete,
  onUpdate,
  handleSortByColumn,
}) => {
  const navigate = useNavigate();

  const location = useLocation();
  // Extract data and originalData from location state
  const {
    originalData = {},
    updatedData = {},
    // dataFromMarquee = null,
  } = location.state || {};

  // const originalData = location.state?.originalData || {};
  // const updatedData = location.state?.updatedData || {};
  // console.log("dataFromMarquee => ", dataFromMarquee);
  const isFieldUpdated = (data, fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };

  const handlePublish = (id) => {
    // Handle publish event
    console.log(`Publishing item with id: ${id}`);
  };

  // const handleLinkClick = (vin, data) => {
  //   // Navigate to AddList component programmatically with state
  //   Navigate("/dealer/inventory/add", { state: { vin, data, active: true } });
  //   // Navigate('/myprofile/addlist', { state: vin, });
  // };
  // for addlisting popup---------------------------------------------------------
  // for addlisting popup---------------------------------------------------------
  const [showModalAddlisting, setShowModalAddlisting] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalAddlistingAddCost, setShowModalAddlistingAddCost] =
    useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [addlistingData, setAddlistingData] = useState([]);
  const [addCostStatus, setAddCostStatus] = useState(0);
  const [InventoryIndex, setInventoryIndex] = useState(null);
  const [IndexAddCost, setIndexAddCost] = useState(null);
  const [addCost, setAddCost] = useState("");

  const handleLinkClickAddlisting = (itemDataAddlisting, e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setInventoryIndex(index);
    console.log(
      "************itemDataAddlisting*******************",
      itemDataAddlisting
    );
    setShowModalAddlisting(true);
    setShowModalAdd(true);
    setAddlistingData(itemDataAddlisting);
  };
  const handleLinkClickAddlistingAddCost = (
    itemDataAddlisting,
    e,
    cost,
    index
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIndexAddCost(index);
    // console.log("************itemDataAddlisting*******************", itemDataAddlisting);
    setAddCostStatus(cost);
    // setIsImageM(false);
    setShowModalAddlistingAddCost(true);
    // setShowModalAdd(true);
    setAddlistingData(itemDataAddlisting);
  };
  const handleModalCloseAddlistingAddCost = () => {
    setShowModalAddlistingAddCost(false);
    // setShowModalImage(false);
  };
  const handleOpenImgModal = (itemDataAddlisting, e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(
      "************itemDataAddlisting in handleOpenImgModal*******************",
      itemDataAddlisting
    );
    setShowModalAdd(false);
    setShowModalAddlisting(true);
    setShowModalImage(true);
    setAddlistingData(itemDataAddlisting);
  };

  const handleModalCloseAddlisting = () => {
    setShowModalAddlisting(false);
    setShowModalImage(false);
    setAddCost("");
  };

  const handleCloseImgModal = () => {
    // setShowModalAddlisting(false);
    setShowModalImage(false);
  };

  const handleAddCost = () => {
    setAddCost("Mantainance");
  };
  // ------------------------------------------------------------------------------------

  const totalRows = 20;
  const currentItemsCount = tableData.length;
  const emptyRowsCount =
    currentItemsCount < totalRows ? totalRows - currentItemsCount : 0;

  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(11)].map((_, i) => (
        <td key={i} style={{ height: "45px" }}>
          &nbsp;
        </td>
      ))}
    </tr>
  ));

  const dispatch = useDispatch();
  const [carName, setCarName] = useState("");
  const [showModalSell, setShowModalSell] = useState(false);
  const handleModalCloseSell = () => {
    setShowModalSell(false);
  };
  const handleGoToSell = (data) => {
    // Make sure that 'data' is correctly defined and holds the necessary information
    console.log("data to be sent to sell after cliking on card", data);
    const yearTrim = `${data.year_Make_Model} ${data.trim}`;
    // Navigate(`/dealer/sell#${data.stock_no}#${data.id}`, { state: { data } });
    setShowModalSell(false);
    // dispatch(setActiveTab("Sale Type"));
    dispatch(setSelectedVehicle(data));
    dispatch(
      setRetailData({
        listingId: data.id,
      })
    );
    setCarName(yearTrim);
    console.log("data.year_make_model", data.year_make_model);
    dispatch(resetCoBuyerSellData());
    dispatch(resetBuyerSellData());
    // Compare with the previous listingId
    dispatch(
      setRetailData({
        salesTaxes: "6.25",
        mInventoryTax: "0.001488",
        titleApplicationFee: 13.0,
        terpTitlefee: 20,
        drivesOutTag: 5.0,
        windshieldTrackerSticker: 50.75,
        regFeeDps: 1,
        cntyRoadBridgeFee: 10,
        childSafetyFund: 1.5,
        InspectionFeeOBDNL: 25,
        StateInspectionFees: 8.25,
        processingHandlingFee: 4.75,
        documentationFee: 150.0,
        technologyFees: 0.0,
        buyerTagFees: 5,
        other1: 0.0,
        other2: 0.0,
        cardFees: 0,
        warranty: 0,
        gapInsurance: 0,
        mailDeliveryFees: 0,
        transportationFees: 0,
        totalFees: 0,
        // totalFeesAndothers: 294.5,
        txtState: "",
        listingPrice: "",
        userListingPrice: 0,
        txtTitleType: null,
        ownedTrade: "",
        cashDifference: "",
        paymentPriceFees: "",
        date: "",
        saleTaxValue: "",
        mInventoryValue: "",
        balanceDue: "",
        totalDue: 0,
        // -----------------------------------
        lienHolderName: "",
        leanHolderId: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        serviceContractId: "",
        serviceContractName: "",
        serviceContractLicense: "",
        serviceContractAddress: "",
        serviceProviderActivationStatus: "",
        serviceProviderType: "",
        providerPhoneNumber: "",
        providerCounty: "",
        providerListingExpiry: "",
        priceFeesPaymentMethod: "",
        //Gap Insurance Provider Data
        gapInsuranceId: "",
        gapName: "",
        gapLicense: "",
        gapAddress: "",
        gapActivationStatus: "",
        gapType: "",
        gapPhoneNumber: "",
        gapCounty: "",
        gapListingExpiry: "",
        // Sell Form ---------------------------
        sellForm: "",
        // tax maker forms-----------------------
        taxMakerForms: "",
        // trade in key--------
        dealerId: 0,
        vin: "",
        year_make_model: "",
        // make year modal only check purpose for backend------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        // -----------------------
        trim: "",
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        purchase_price: "",
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
        mileageIn: "",
        // trade in key end--------
        // for images of vehicle card--------
        imageUrl: "",
      })
    );
    navigate("/dealer/sell");
    dispatch(setActiveTab("Buyer"));
  };

  const [show, setShow] = useState(false);
  useEffect(() => {
    if (show) {
      dispatch(saveUser({ isSideBarOpen: false }));
    }
  }, [show]);
  const [socialIcons, setSocialIcons] = useState([]);
  const [inventoryId, setInventoryId] = useState(0);
  const [advertisingPro, setAdvertisingPro] = useState({
    listing_id: 0,
    dacmp_status: true,
    dacmp_interval: "",
    unitedmotors_status: true,
    unitedmotors_interval: "",
    google_status: false,
    google_interval: "",
    insta_status: false,
    insta_interval: "",
    facebook_status: false,
    facebook_interval: "",
    facebook_marketplace_status: false,
    facebook_groups_status: false,
    craigslist_status: false,
    craigslist_interval: "",
    twitter_status: false,
    twitter_interval: "",
  });

  const handleAddsPro = (e, data) => {
    e.stopPropagation();
    setInventoryId(data.id);
    setShow(true);
    console.log("seting Profils");
    if (data && data.addvertising) {
      setAdvertisingPro((prev) => ({
        ...prev,
        listing_id: data.id,
        dacmp_interval: data.addvertising.dacmp_interval ?? prev.dacmp_interval,
        unitedmotors_interval:
          data.addvertising.unitedmotors_interval ?? prev.unitedmotors_interval,
        google_status: data.addvertising.google_status,
        google_interval:
          data.addvertising.google_interval ?? prev.google_interval,
        insta_status: data.addvertising.insta_status,
        insta_interval: data.addvertising.insta_interval ?? prev.insta_interval,
        facebook_status: data.addvertising.facebook_status,
        facebook_interval:
          data.addvertising.facebook_interval ?? prev.facebook_interval,
        craigslist_status: data.addvertising.craigslist_status,
        craigslist_interval:
          data.addvertising.craigslist_interval ?? prev.craigslist_interval,
        twitter_status: data.addvertising.twitter_status,
        twitter_interval:
          data.addvertising.twitter_interval ?? prev.twitter_interval,
      }));
    }
  };

  const handleCheckboxChange = (e, intervalName) => {
    console.log("handleCheckboxChange", e);
    const { name, checked } = e.target;
    // setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));

    if (name === "facebook_status" && checked === false) {
      setAdvertisingPro((prev) => ({
        ...prev,
        facebook_marketplace_status: false,
        facebook_groups_status: false,
      }));
    }

    // Reset the interval if the checkbox is unchecked
    const resetInterval = !checked ? "" : advertisingPro[intervalName];

    setAdvertisingPro((prev) => ({
      ...prev,
      [name]: checked,
      [intervalName]: resetInterval,
    }));
  };

  const handleSelectChange = (e) => {
    console.log("handleSelectChange");
    e.stopPropagation();
    // e.preventDefault();
    const { name, value } = e.target;
    setAdvertisingPro((prev) => ({ ...prev, [name]: value }));
  };

  const resetCheckboxes = () => {
    setAdvertisingPro({
      listing_id: null,
      dacmp_status: true,
      // dacmp_interval: "",
      unitedmotors_status: true,
      // unitedmotors_interval: "",
      google_status: false,
      google_interval: "",
      insta_status: false,
      insta_interval: "",
      facebook_status: false,
      facebook_interval: "",
      craigslist_status: false,
      craigslist_interval: "",
      twitter_status: false,
      twitter_interval: "",
    });
  };

  // Handle image toggle state for all profiles
  const [connectionStatus, setConnectionStatus] = useState({
    dacmp_connected: false,
    unitedmotors_connected: false,
    google_connected: false,
    insta_connected: false,
    facebook_connected: false,
    craigslist_connected: false,
    twitter_connected: false,
  });

  const toggleConnectionStatus = (profileName) => {
    setConnectionStatus((prevStatus) => ({
      ...prevStatus,
      [profileName]: !prevStatus[profileName],
    }));
  };

  const setAllIntervals = (interval) => {
    console.log("interval in setAllIntervals", interval);
    setAdvertisingPro((prev) => ({
      ...prev,
      dacmp_interval: prev.dacmp_status ? interval : prev.dacmp_interval,
      unitedmotors_interval: prev.unitedmotors_status
        ? interval
        : prev.unitedmotors_interval,
      google_interval: prev.google_status ? interval : prev.google_interval,
      insta_interval: prev.insta_status ? interval : prev.insta_interval,
      facebook_interval: prev.facebook_status
        ? interval
        : prev.facebook_interval,
      craigslist_interval: prev.craigslist_status
        ? interval
        : prev.craigslist_interval,
      twitter_interval: prev.twitter_status ? interval : prev.twitter_interval,
    }));
  };

  // Function to post advertisingPro data
  const updateAdvertisement = async () => {
    try {
      console.log(advertisingPro);
      // console.log('Payload being sent:', JSON.stringify(advertisingPro, null, 2));

      const response = await fetch(`${apiUrl}/listing/update_advertisement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(advertisingPro),
      });

      if (response.ok) {
        const updatedData = await response.json();
        console.log("Advertisement updated successfully:", updatedData);

        // Notify parent component of the update
        // if (onUpdate) onUpdate(data.id, advertisingPro);
        if (onUpdate) onUpdate(inventoryId, advertisingPro);

        // Update local state with the new data
        // setAdvertisingPro((prev) => ({
        //     ...prev,
        //     ...updatedData, // Assume the API response contains the updated fields
        // }));
      } else {
        console.error("Failed to update advertisement:", response.statusText);
        // Handle the error response as needed
      }
    } catch (error) {
      console.error("Error updating advertisement:", error);
      // Handle any errors that occur during the fetch
    } finally {
      setShow(false);
    }
  };

  const [facebookMarketplaceStatus, setFacebookMarketplaceStatus] =
    useState(false);
  const [facebookGroupsStatus, setFacebookGroupsStatus] = useState(false);

  const handleFacebookCheckboxChange = (checked, name) => {
    console.log("______checked", name, checked, facebookMarketplaceStatus);
    setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));
    setFacebookMarketplaceStatus(checked);
    // console.log("advertisingPro", advertisingPro);
  };

  useEffect(() => {
    setTimeout(() => {
      console.log("advertisingPro_______________", advertisingPro);
    }, 200);
  }, [facebookMarketplaceStatus, advertisingPro]);

  const renderTableRow = (item, index) => {
    if (!item.year_make_model) {
      return null;
    }
    const year_make_model = item.year_make_model;
    const [year, make, model, series, bodyClass] = year_make_model.split(" ");

    // Calculate days difference for the inspection date
    const daysDifference = calculateDaysDifference(item.inspection);
    const daysDifferencePurchase = calculateDaysDifference(item.purchasedDate);

    return (
      <tr
        key={index}
        className={selectedRows.includes(index) ? "selected-rows" : "tr_table"}
        onClick={() => item && handleRowClick(index)}
        style={{ cursor: "pointer" }}
      >
        {item ? (
          <>
            <td style={{ textAlign: "left" }}>{index + 1}</td>

            <td
              className="ac-col fw-bold"
              onClick={(e) => handleLinkClickAddlisting(item, e)}
              style={{ textAlign: "left" }}
            >
              {/* {item.stock_no} */}
              {item.vin.slice(-8)}
            </td>

            <td
              className="ac-col fw-bold"
              style={{ textAlign: "left" }}
              onClick={(e) => handleLinkClickAddlisting(item, e)}
            >
              {item.year} {item.make} {item.model} {item.trim}
            </td>
            {/* <td></td> */}
            {/* <td style={{ textAlign: "left" }}>{item.trim}</td> */}
            <td style={{ textAlign: "left" }}>{item.bodyClass}</td>
            <td
              style={{
                textShadow: isFieldUpdated(item, "exteriorColor")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
                textAlign: "left",
              }}
            >
              {item.exteriorColor}
            </td>
            {/* <td
              className="vin-column fw-bold"
              onClick={() => handleLinkClickAddlisting(item)}
              style={{ textAlign: "left" }}
            >
              {item.vin}
            </td> */}
            <td
              style={{
                textShadow: isFieldUpdated(item, "mileage_in")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {new Intl.NumberFormat().format(item.mileage_in)}
              {/* {item.mileage_in} */}
            </td>
            <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                  Purchase Date: <br />
                  {item.purchasedDate}
                </Tooltip>
              }
            >
              <td
                style={{
                  textShadow: isFieldUpdated(item, "inspection")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                  textAlign: "center",
                }}
              >
                {daysDifferencePurchase}
              </td>
            </OverlayTrigger>
            {/* <td
              className="border-0 mb-0"
              style={{
                textShadow: isFieldUpdated(item, "inspection")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
                textAlign: "left",
              }}
            >
              {item.purchasedDate}{" "}
            </td> */}
            <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                  Inspection Date: <br />
                  {item.inspection}
                </Tooltip>
              }
            >
              <td
                style={{
                  textShadow: isFieldUpdated(item, "inspection")
                    ? "#ff8c21 1px 0 10px"
                    : "black",
                  textAlign: "center",
                }}
              >
                {daysDifference}
              </td>
            </OverlayTrigger>

            {/* <td>{daysDifference}</td>  */}

            <td style={{ textAlign: "left" }}>{item.allLeads}</td>
            <td
              style={{
                textShadow: isFieldUpdated(item, "listing_price")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
                textAlign: "center",
              }}
            >
              ${new Intl.NumberFormat().format(item.listing_price)}
              {/* {item.listing_price} */}
            </td>
            <td
              style={{ display: "flex", gap: "5px", justifyContent: "center" }}
            >
              <Button
                className="publish-btn border-0"
                onClick={() => handleGoToSell(item)}
                style={{ color: "white", width: "70px" }}
              >
                Sell
              </Button>
              <Button
                onClick={(e) => handleAddsPro(e, item)}
                className="publish-btn border-0"
                style={{ color: "white", width: "70px" }}
              >
                Live
              </Button>
              <Button
                className="publish-btn border-0 py-2"
                // onClick={(e) => {
                //   handleLinkClickAddlisting(item, e, index);
                //   handleAddCost();
                // }}
                onClick={(e) =>
                  handleLinkClickAddlistingAddCost(item, e, 1, index)
                }
                style={{ color: "white" }}
              >
                Add Cost
              </Button>
            </td>
            {/* <td style={{ cursor: "pointer", textAlign: "center" }}> */}
            {/* <Button
                className="publish-btn border-0 py-2 me-1"
                onClick={(e) => handleLinkClickAddlisting(item, e, index)}
                style={{ color: "white", width: "70px" }}
              >
                Edit
              </Button> */}
            {/* 
              <Button
                className="publish-btn border-0 py-2"
                onClick={(e) => {
                  handleLinkClickAddlisting(item, e, index);
                  handleAddCost();
                }}
                style={{ color: "white" }}
              >
                Add Cost
              </Button>
              <MdDelete /> */}
            {/* </td> */}
          </>
        ) : (
          Array.from({ length: 11 }).map((_, i) => <td key={i}>&nbsp;</td>)
        )}
      </tr>
    );
  };

  // Calculate total mileage
  const totalMileage = tableData.reduce(
    (total, item) => total + item.mileage_in,
    0
  );

  // Calculate total mileage
  const listingPrice = tableData.reduce(
    (total, price) => total + price.listing_price,
    0
  );

  // Calculate total days in
  const totalDaysIn = tableData.reduce(
    (total, item) => total + calculateDaysDifference(item.inspection),
    0
  );

  // Calculate average days in
  const averageDaysIn = totalDaysIn / (tableData.length || 1); // Prevent division by zero

  return (
    <>
      <div className="table-wrapper rounded px-0 table-scrollbar">
        <Table
          striped
          bordered
          hover
          ref={tableRef}
          className="shadow mb-0"
          id="table"
        >
          <thead>
            <tr>
              <th>No#</th>

              <th className="ac-col ">
                Stock. No#
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Desc", "Stock")}>
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Asc", "Stock")}>
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>

              <th className="th-flex">
                Vehicle Info
                <span className="tooltip-container-active-inventory">
                  <IoMdInformationCircleOutline className="info-icon-active-inventory" />
                  <div className="tooltip-text-active-inventory">
                    Year Make Model Trim
                  </div>
                </span>
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Desc", "Vehicle")}>
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Asc", "Vehicle")}>
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              {/* <th>Make</th> */}
              {/* <th>Trim</th> */}
              <th>
                Body Type
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() => handleSortByColumn("Desc", "Body Style")}
                    >
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() => handleSortByColumn("Asc", "Body Style")}
                    >
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>
                Color
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Desc", "Color")}>
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Asc", "Color")}>
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              {/* <th className="vin-column">VIN</th> */}
              <th>
                Miles
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Desc", "Mileage")}>
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span onClick={() => handleSortByColumn("Asc", "Mileage")}>
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>
                P Days
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() =>
                        handleSortByColumn("Desc", "Purchase Date")
                      }
                    >
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() => handleSortByColumn("Asc", "Purchase Date")}
                    >
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>
                Inspection
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() =>
                        handleSortByColumn("Desc", "Inspection Date")
                      }
                    >
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() =>
                        handleSortByColumn("Asc", "Inspection Date")
                      }
                    >
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>All Leads</th>

              <th>
                Listing Price
                <span className="filter-icons">
                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Descending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() =>
                        handleSortByColumn("Desc", "Listing Price")
                      }
                    >
                      <TiArrowSortedUp />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="bottom-start"
                    placement="bottom-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom-start`}
                        className="table-tooltip"
                      >
                        Ascending
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() => handleSortByColumn("Asc", "Listing Price")}
                    >
                      <TiArrowSortedDown />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>

              <th>{/* Add Data */}</th>
            </tr>
          </thead>
          <tbody>
            {tableData
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(renderTableRow)}
            {emptyRows}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: "orange" }}>
              <td colSpan={7}>All Inventory: {tableData.length}</td>
              <td>
                {new Intl.NumberFormat().format(
                  totalMileage / tableData.length || 1
                )}
                {/* {totalMileage / tableData.length || 1} */}
              </td>

              <td>
                {" "}
                {new Intl.NumberFormat().format(averageDaysIn)}
                {/* {averageDaysIn} */}
              </td>

              <td>6575</td>
              <td>
                ${" "}
                {new Intl.NumberFormat().format(
                  listingPrice / tableData.length || 1
                )}
                {/* {listingPrice / tableData.length || 1} */}
              </td>
              {/* <td></td> */}
              {/* <td></td> */}
            </tr>
          </tfoot>
        </Table>
      </div>

      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Advertising Manager
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div id="refresh-btn-seller">
              <button
                type="button"
                id="adlst-reset-btn-seller"
                onClick={resetCheckboxes}
                title="Reset"
              >
                <img src={Image.reset_button} alt="Reset Button" />
              </button>
            </div>
            <div className="tab-buttons">
              <section className="container">
                <div className="row">
                  {/* Card for Dealers Auto Center Market Place */}
                  <AdvertisingProfile
                    title="Dealers Auto Center Market Place"
                    logo={Image.darkLogo}
                    statusName="dacmp_status"
                    intervalName="dacmp_interval"
                    status={advertisingPro.dacmp_status}
                    interval={advertisingPro.dacmp_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("dacmp_connected")
                    }
                    isConnected={connectionStatus.dacmp_connected}
                    isAllInterval={true}
                    setAllIntervals={setAllIntervals}
                    readonly={true}
                  />

                  {/* Card for United Motors */}
                  <AdvertisingProfile
                    title="Website (United Motors)"
                    logo={Image.darkLogo}
                    statusName="unitedmotors_status"
                    intervalName="unitedmotors_interval"
                    status={advertisingPro.unitedmotors_status}
                    interval={advertisingPro.unitedmotors_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("unitedmotors_connected")
                    }
                    isConnected={connectionStatus.unitedmotors_connected}
                    readonly={true}
                  />

                  {/* Card for Google Market Place */}
                  <AdvertisingProfile
                    title="Google Market Place"
                    logo={Image.google}
                    statusName="google_status"
                    intervalName="google_interval"
                    status={advertisingPro.google_status}
                    interval={advertisingPro.google_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("google_connected")
                    }
                    isConnected={connectionStatus.google_connected}
                  />

                  {/* Card for Instagram */}
                  <AdvertisingProfile
                    title="Instagram"
                    logo="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
                    statusName="insta_status"
                    intervalName="insta_interval"
                    status={advertisingPro.insta_status}
                    interval={advertisingPro.insta_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("insta_connected")
                    }
                    isConnected={connectionStatus.insta_connected}
                  />

                  {/* Card for Facebook */}
                  <AdvertisingProfile
                    title="Facebook"
                    logo={Image.facebook}
                    statusName="facebook_status"
                    intervalName="facebook_interval"
                    status={advertisingPro.facebook_status}
                    interval={advertisingPro.facebook_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("facebook_connected")
                    }
                    isConnected={connectionStatus.facebook_connected}
                    isFacebook={true}
                    facebookMarketplaceStatus={
                      advertisingPro.facebook_marketplace_status
                    }
                    facebookGroupsStatus={advertisingPro.facebook_groups_status}
                    handleFacebookCheckboxChange={handleFacebookCheckboxChange}
                  />

                  {/* Card for Craigslist */}
                  <AdvertisingProfile
                    title="Craigslist"
                    logo={Image.craigslist}
                    statusName="craigslist_status"
                    intervalName="craigslist_interval"
                    status={advertisingPro.craigslist_status}
                    interval={advertisingPro.craigslist_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("craigslist_connected")
                    }
                    isConnected={connectionStatus.craigslist_connected}
                  />

                  {/* Card for Twitter */}
                  <AdvertisingProfile
                    title="Twitter"
                    logo={Image.twitter_x_logo}
                    statusName="twitter_status"
                    intervalName="twitter_interval"
                    status={advertisingPro.twitter_status}
                    interval={advertisingPro.twitter_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("twitter_connected")
                    }
                    isConnected={connectionStatus.twitter_connected}
                  />
                </div>
              </section>
            </div>
            <div className="d-flex justify-content-center ">
              <Button
                className="btn-orange border-0 py-2 px-3"
                onClick={updateAdvertisement}
              >
                Publish
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <SellData
        show={showModalSell}
        handleClose={handleModalCloseSell}
        carName={carName}
      /> */}

      <AddlistingData
        show={showModalAddlisting}
        showModalImage={showModalImage}
        handleClose={handleModalCloseAddlisting}
        showModalAdd={showModalAdd}
        setShowModalImage={setShowModalImage}
        handleCloseImgModal={handleCloseImgModal}
        addlistingData={addlistingData}
        addlistionIdShow={3}
        handleInventoryDelete={handleDelete}
        InventoryIndex={InventoryIndex}
        addCost={addCost}
      />
      <AddlistingDataAddCost
        show={showModalAddlistingAddCost}
        // showModalImage={showModalImage}
        handleClose={handleModalCloseAddlistingAddCost}
        // showModalAdd={showModalAdd}
        // isImageM={isImageM}
        // setShowModalImage={setShowModalImage}
        // handleCloseImgModal={handleCloseImgModal}
        addlistingData={addlistingData}
        handleInventoryDelete={handleDelete}
        InventoryIndex={IndexAddCost}
        addlistionIdShow={3}
        addCostStatus={addCostStatus}
      />
    </>
  );
};

export default DealerActiveInventory;
