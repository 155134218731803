import React, { useEffect, useRef, useState } from "react";
import Styles from "../Styles/InputFieldComp.module.css";
import { BsCurrencyDollar, BsInfoCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../../Redux/slice/taxes&FeesSlice";
import Image from "../../../../imgData";
import { MdOutlineEdit } from "react-icons/md";
import ediIcon from "./editIcon.png";
import deleteIcon from "./binIcon.png";
import addIcon from "./addIcon.png";
import { setIsSomethingChanged } from "../../../../../Redux/slice/sellSlice";
import { incrementCounter } from "../../../../../Redux/slice/activeSlice";
import { SlSpeedometer } from "react-icons/sl";
import { FaCalculator } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";

const InputFieldComp = ({
  header,
  InputValue,
  name,
  handleInputChange,
  setInputState,
  inputState,
  setShowCalculator = null,
  showCalculator = false,
}) => {
  // Function to format numbers with commas and preserve decimals
  console.log("name =>", name);
  const allInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs
  );
  const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  const lenghtInputs = allInputs?.length;
  console.log("isSelected", lenghtInputs);
  // Function to format numbers with commas and preserve decimals
  const formatNumberWithCommas = (number) => {
    number = number.toString().replace(/,/g, "");
    console.log("format Number String",number);
    if (number === undefined || number === null) return "";
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  //total due
  const totalDue = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.totalDue) || 0
  );
  const handleChange = (e) => {
    console.log("Name46546",e.target.value);
    if (e.target.name === "mileageIn") {
      // Allow "Old" or any non-numeric string
      const value = e.target.value;
      if (value === "Old" || (value.trim() !== "" && isNaN(value))) {
          handleInputChange(e.target.name, value);
          dispatch(setIsSomethingChanged(true));
          return; // Exit the function early
      }
  }
    // Remove existing commas
    let value = e.target.value.replace(/,/g, "");
    // Allow only numbers and a single decimal point
    value = value.replace(/[^0-9.]/g, "");
    // Ensure only one decimal point
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("");
    }
    // Limit to two decimal places
    if (parts[1] && parts[1].length > 2) {
      value = parseFloat(value).toFixed(2);
    }

    // Override leading zero if followed by another digit
    if (value.startsWith("0") && value.length > 1) {
      value = value.replace(/^0+/, ""); // Remove leading zeros
    }

    // Call the parent's handleInputChange with the numeric value
    handleInputChange(name, value);
    dispatch(setIsSomethingChanged(true));
  };

  const dispatch = useDispatch();
  const handlePaymentDue = () => {
    dispatch(setIsSomethingChanged(true));
    if (totalDue) {
      dispatch(
        setRetailData({
          paymentPriceFees: totalDue ? totalDue.toFixed(2) : 0,
        })
      );
    }
  };
  const handleDOut = () => {
    dispatch(incrementCounter());
  };

  console.log("updated input state => ", inputState);
  //here editing of the header label starts
  const [isEditing, setIsEditing] = useState(false);
  const [editingInput, setEditingInput] = useState(header);
  console.log(" editingInput ", editingInput);
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsEditing(false);
      if (editingInput !== "") {
        editLabelFunc(editingInput);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleheaderInputChange = (e) => {
    setEditingInput(e.target.value);
  };

  const editLabelFunc = (headerNewValue) => {
    setInputState((prevData) =>
      prevData.map((item) =>
        item.name === name ? { ...item, header: headerNewValue } : item
      )
    );
    let itemToUpdate;
    console.log("Name here is ", name);
    switch (name) {
      case "documentationFee":
        itemToUpdate = "documentFees";
        break;
      case "drivesOutTag":
        itemToUpdate = "driveOutTags";
        break;
      case "titleApplicationFee":
        itemToUpdate = "titleAppFees";
        break;
      case "terpTitlefee":
        itemToUpdate = "terpTitleFees";
        break;
      case "buyerTagFees":
        itemToUpdate = "buyerTag";
        break;
      case "windshieldTrackerSticker":
        itemToUpdate = "windShieldTracker";
        break;
      case "regFeeDps":
        itemToUpdate = "regFeeDpsl";
        break;
      case "cntyRoadBridgeFee":
        itemToUpdate = "cntyRoadBridge";
        break;
      case "childSafetyFund":
        itemToUpdate = "childSafetyFee";
        break;
      case "InspectionFeeOBDNL":
        itemToUpdate = "inspectionFee";
        break;
      case "StateInspectionFees":
        itemToUpdate = "stateInspectionFee";
        break;
      case "processingHandlingFee":
        itemToUpdate = "procHandlingFee";
        break;
      //
      case "cardFees":
        itemToUpdate = "cardFeesl";
        break;
      case "warranty":
        itemToUpdate = "serviceContractl";
        break;
      case "gapInsurance":
        itemToUpdate = "gapInsurancel";
        break;
      case "mailDeliveryFees":
        itemToUpdate = "mailDeliveryFeesl";
        break;
      case "transportationFees":
        itemToUpdate = "transportationFeesl";
        break;
    }

    dispatch(setRetailData({ [itemToUpdate]: headerNewValue }));
  };
  useEffect(() => {
    if (!isEditing && editingInput !== header) {
      // Assuming `editingInput` is the new value you want to set
      editLabelFunc(editingInput);
    }
  }, [isEditing, header]);

  const [showExtraIcons, setShowExtraIcon] = useState({
    add: false,
    delete: false,
  });
  useEffect(() => {
    if (header === "") {
      // Dispatch an action to update the Redux state
      dispatch(setRetailData({ [name]: "" }));
    }
  }, [header, dispatch, name]);
  //Checking whether to view or not the pencil edit icon
  const [showEditIcon, setShowEditIcon] = useState(false);
  useEffect(() => {
    if (
      name === "documentationFee" ||
      name === "drivesOutTag" ||
      name === "titleApplicationFee" ||
      name === "terpTitlefee" ||
      name === "titleApplicationFee" ||
      name === "buyerTagFees" ||
      name === "windshieldTrackerSticker" ||
      name === "regFeeDps" ||
      name === "cntyRoadBridgeFee" ||
      name === "childSafetyFund" ||
      name === "InspectionFeeOBDNL" ||
      name === "StateInspectionFees" ||
      name === "processingHandlingFee" ||
      name === "cardFees" ||
      name === "warranty" ||
      name === "gapInsurance" ||
      name === "mailDeliveryFees" ||
      name === "transportationFees"
    ) {
      setShowEditIcon(true);
    } else {
      setShowEditIcon(false);
    }
  }, []);
  //for showing the delete button when component loads
  useEffect(() => {
    if (showEditIcon)
      setShowExtraIcon((prevData) => ({
        ...prevData,
        delete: true,
      }));
  }, [showEditIcon]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };
  // ------------------------------------------------------------------
  function getLengthBasedOnHeader(header) {
    switch (header) {
      case "Mileage On Sale:":
        return lenghtInputs + 3;
      case "Sale Price:":
        return lenghtInputs + 4;
      case "Total Taxes":
        return purchase_price > 0 ? lenghtInputs + 8 : lenghtInputs + 5;
      case "Total Fees And Others":
        return purchase_price > 0 ? lenghtInputs + 9 : lenghtInputs + 6;
      case "Trade In Allowance":
        return lenghtInputs + 5;
      case "Trade-In Payoff":
        return lenghtInputs + 6;
      case "D. Out":
        return purchase_price > 0 ? lenghtInputs + 12 : lenghtInputs + 9;
      case "Payment":
        return purchase_price > 0 ? lenghtInputs + 14 : lenghtInputs + 11;
        break;
    }
  }
  // ------------------------------------------------------------------
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        {isEditing ? (
          <input
            type="text"
            className="HeaderInputField"
            ref={inputRef}
            value={editingInput}
            onChange={handleheaderInputChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <div className={Styles.subHeader}>
            <span style={{ marginRight: "4px" }}>
              {getLengthBasedOnHeader(header)}. {header}
            </span>{" "}
            {header === "D. Out" ? (
              <span
                title="It Will Effect On Sell Price"
                className={Styles.dOutHeaderInfoIcon}
              >
                <BsInfoCircle />
              </span>
            ) : null}
            {showEditIcon
              ? header.length > 0 && (
                  <img
                    src={ediIcon}
                    alt="edit"
                    style={{
                      height: "15px",
                      cursor: "pointer",
                      marginRight: "15px",
                    }}
                    onClick={() => setIsEditing(true)}
                  />
                )
              : null}
            {showExtraIcons.delete && header.length > 0 && (
              <img
                src={deleteIcon}
                alt="add"
                style={{ height: "15px", cursor: "pointer" }}
                onClick={() => {
                  const updatedInputState = inputState.map((item) =>
                    item.name === name ? { ...item, header: "" } : item
                  );
                  setInputState(updatedInputState);
                  setEditingInput(""); // Clear the editing input
                  editLabelFunc("");
                  // Also dispatch an action to clear the corresponding header in Redux
                }}
              />
            )}
            {header.length === 0 && (
              <img
                src={addIcon}
                alt="add"
                style={{ height: "15px", cursor: "pointer" }}
                onClick={() => setIsEditing(true)}
              />
            )}
          </div>
        )}
      </div>

      {header === "Mileage On Sale:" ? (
        <>
          <span className={`${Styles.mi_logo} ${Styles.absolute_mi_logo}`}>
            <SlSpeedometer />
          </span>
        </>
      ) : (
        <span
          style={{ marginLeft: header === "Payment" ? "1rem" : "" }}
          className={Styles.dollar_logo_container}
        >
          <BsCurrencyDollar />
        </span>
      )}
      {name === "userListingPrice" && (
        <>
          <span
            className={`${Styles.mi_logo} ${Styles.absolute_mi_logo}`}
            onClick={() => setShowCalculator(!showCalculator)}
          >
            <FaCalculator />
          </span>
        </>
      )}

      <input
        type="text"
        value={formatNumberWithCommas(InputValue)}
        className={`${Styles.inputField} ${
          (name === "dOut" || header === "Payment") && Styles.inputFieldDOut
        }`}
        // style={{ flex: header === "Payment" && "0.3" }}
        name={name}
        onChange={handleChange}
        placeholder="0"
        onClick={name === "paymentPriceFees" ? handlePaymentDue : undefined}
      />
      {header === "D. Out" && (
        <span className={Styles.dOutUploadIcon}>
          <FiUpload
            title="Apply"
            onClick={name === "dOut" ? handleDOut : undefined}
          />
        </span>
      )}
      {header === "Payment" && (
        <span className={Styles.dOutUploadIcon}>
          <img
            onClick={name === "paymentPriceFees" ? handlePaymentDue : undefined}
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            src="https://static.thenounproject.com/png/1694564-200.png"
            alt="Mileage Icon"
            title="Update"
          />
        </span>
      )}
    </div>
  );
};

export default InputFieldComp;
