import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalMainColor: "#d1823a",
  globalSecondaryColor: "rgb(243, 223, 196)",
  themeBgColor: "white",
  fontFamily: "Arial",
};
const globalStyleSlice = createSlice({
  name: "globalStyle",
  initialState,
  reducers: {
    updateGlobalStyle: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const { updateGlobalStyle } = globalStyleSlice.actions;
export default globalStyleSlice.reducer;
