import React from "react";
import styles from "./PriceFeesPreviewDesktop.module.css";

const PriceFeesPreviewDesktop = ({ data }) => {
  const dataAfterDynamicLabels =
    data.sellPriceFees?.sellPriceFeeItems.length + 3;
  return (
    <div className={styles.priceFeesTable}>
      <div className={styles.priceFeesRowOne}>
        <div className={styles.priceFeesRow}>
          <p className={styles.priceFeesKey}>1. Sale Price</p>
          <p className={styles.priceFeesValue}>
            $ {data.sellPriceFees?.listingPrice || 0}
          </p>
        </div>
        <div className={styles.priceFeesSecondBlock}>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>2. Trade In</p>
            <p className={styles.priceFeesValue}>
              $ {(data.addListing && data?.addListing?.purchase_price) || 0}
            </p>
          </div>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>3. Cash Difference</p>
            <p className={styles.priceFeesValue}>
              ${" "}
              {(data.sellPriceFees && data.sellPriceFees?.cashDifference) || 0}
            </p>
          </div>
        </div>
        <div className={styles.priceFeesDynamcRowContainer}>
          {data.sellPriceFees?.sellPriceFeeItems?.length > 0 &&
            data.sellPriceFees?.sellPriceFeeItems?.map((item, index) => (
              <div
                className={`${styles.priceFeesRow} ${
                  index === data.sellPriceFees?.sellPriceFeeItems?.length - 1 &&
                  styles.dynamicLabels
                }`}
              >
                <p className={styles.priceFeesKey}>
                  {index + 4}. {item.itemName}
                </p>
                <p className={styles.priceFeesValue}>$ {item.itemValue}</p>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.priceFeesRowTwo}>
        <div className={styles.priceFeesSecondBlock}>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 1}. Sales Tax
            </p>
            <p className={`${styles.priceFeesValue} ${styles.percValue}`}>
              {data.sellPriceFees?.salesTaxes || 0}%
            </p>
            <p className={styles.priceFeesValue}>
              $ {data.sellPriceFees?.saleTaxValue || 0}
            </p>
          </div>

          <div className={`${styles.priceFeesRow} ${styles.dynamicLabels}`}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 2}. Inventory Tax
            </p>
            <p className={`${styles.priceFeesValue} ${styles.percValue}`}>
              $ {data.sellPriceFees?.mInventoryTax || 0}%
            </p>
            <p className={styles.priceFeesValue}>
              {data.sellPriceFees?.mInventoryValue || 0}
            </p>
          </div>
        </div>
        <div className={styles.priceFeesSecondBlock}>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 3}. Total Taxes
            </p>
            <p className={styles.priceFeesValue}>
              $ {data.sellPriceFees?.totalFees || 0}
            </p>
          </div>
          <div className={`${styles.priceFeesRow} ${styles.percValue}`}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 4}. Total Fees
            </p>
            <p className={styles.priceFeesValue}>
              $ {(data.sellPriceFees?.totalFeesAndothers).toFixed(2) || 0}
            </p>
          </div>
        </div>
        <div className={styles.priceFeesSecondBlock}>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 5}. TOTAL DUES
            </p>
            <p className={styles.priceFeesValue}>
              $ {(data.sellPriceFees?.totalDue).toFixed(2) || 0}
            </p>
          </div>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 6}. Payment Method
            </p>
            <p className={styles.priceFeesValue}>
              {data.sellPriceFees?.priceFeesPaymentMethod || "NA"}
            </p>
          </div>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 7}. Payment
            </p>
            <p className={styles.priceFeesValue}>
              $ {data.sellPriceFees?.paymentPriceFees || 0}
            </p>
          </div>
          <div className={styles.priceFeesRow}>
            <p className={styles.priceFeesKey}>
              {dataAfterDynamicLabels + 8}. Balance Due
            </p>
            <p className={styles.priceFeesValue}>
              $ {data.sellPriceFees?.balanceDue || 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceFeesPreviewDesktop;
