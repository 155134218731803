import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import mobilePopoutFilters from "./mobileFilters";
import "./productlisting.css";

const MobileFiltersPopout = ({
  showMobileFiltersPopout,
  setShowMobileFiltersPopout,
  filters,
  setFilters,
  responseFilter,
  updateFilters,
}) => {
  console.log("filters 222=> ", filters);
  console.log("responseFilter 222=> ", responseFilter);
  const [filterDataToShow, setFilterDataToShow] = useState(null);
  const [filterTypeSelected, setFilterTypeSelected] = useState("");
  console.log(" filterTypeSelected 222  => ", filterTypeSelected);
  useEffect(() => {
    if (showMobileFiltersPopout && responseFilter && !filterTypeSelected) {
      setFilterTypeSelected("vehicle_type");
    }
  }, [showMobileFiltersPopout]);

  const handleSelectedFilterType = (filterType) => {
    setFilterTypeSelected(filterType);
    // const filterData = responseFilter[filterType];
    // setFilterDataToShow(filterData);
  };
  const isFilterSelectedSingle = (filterTypeSelected) => {
    if (filterTypeSelected) {
      switch (filterTypeSelected) {
        case "vehicle_type":
          return false;
        case "makes":
          return false;
        case "user":
          return false;
        default:
          return true; // Optional: return false if no case matches
      }
    }
    return false; // Optional: return false if filterTypeSelected is falsy
  };
  const handleSingleFilter = (e) => {
    const { value, name } = e.target;
    console.log(
      "Value is => ",
      value,
      " name is => ",
      name,
      " filterTypeSelected => ",
      filterTypeSelected
    );
  };
  return (
    <Modal
      show={showMobileFiltersPopout}
      onHide={() => setShowMobileFiltersPopout(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="filters_popout_wrapper ">
          <div className="filterswrapper">
            {mobilePopoutFilters.map((filter) => (
              <span
                key={filter.name}
                onClick={() => handleSelectedFilterType(filter.name)}
                style={{
                  color: filter.name === filterTypeSelected ? "#d1823a" : "",
                  fontWeight: filter.name === filterTypeSelected ? "bold" : "",
                  fontSize: filter.name === filterTypeSelected ? "medium" : "",
                }}
              >
                {filter.label}
              </span>
            ))}
          </div>
          <div className="filtersContentwrapper">
            {/* Single Filter name */}
            {filterTypeSelected &&
              responseFilter &&
              isFilterSelectedSingle(filterTypeSelected) &&
              responseFilter[filterTypeSelected] &&
              responseFilter[filterTypeSelected].map((filter, index) => (
                <div className="dataWithCheckbox" key={index}>
                  <label htmlFor={filter}>
                    <input
                      type="checkbox"
                      name={filter}
                      id={filter}
                      checked={
                        filters[filterTypeSelected]?.includes(filter) || false
                      }
                      onChange={(e) =>
                        updateFilters(
                          filterTypeSelected,
                          filter,
                          e.target.checked
                        )
                      }
                    />{" "}
                    {filter}
                  </label>
                </div>
              ))}
            {/* Makes with count */}
            {filterTypeSelected &&
              responseFilter &&
              !isFilterSelectedSingle(filterTypeSelected) &&
              filterTypeSelected === "makes" &&
              responseFilter[filterTypeSelected].map((filter, index) => (
                <p key={index} className="filtersObjectContainer">
                  <label htmlFor={filter.make}>
                    <input
                      type="checkbox"
                      name={filter.make}
                      id={filter.make}
                      checked={
                        filters[
                          filterTypeSelected === "makes" && "make"
                        ]?.includes(filter.make) || false
                      }
                    />
                    <span>
                      <span id={filter.make}>{filter.make} </span>
                      <span>{filter.count}</span>
                    </span>
                  </label>
                </p>
              ))}
            {/* Vehicle Type with count */}
            {filterTypeSelected &&
              responseFilter &&
              !isFilterSelectedSingle(filterTypeSelected) &&
              filterTypeSelected === "vehicle_type" &&
              responseFilter[filterTypeSelected].map((filter, index) => (
                <p key={index} className="filtersObjectContainer">
                  <label htmlFor={filter.vehicleType}>
                    <input
                      type="checkbox"
                      name={filter.vehicleType}
                      id={filter.vehicleType}
                      checked={
                        filters[
                          filterTypeSelected === "vehicle_type" && "vehicleType"
                        ]?.includes(filter.vehicleType) || false
                      }
                    />
                    <span>
                      <span>{filter.vehicleType}</span>
                      <span>{filter.count}</span>
                    </span>
                  </label>
                </p>
              ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default MobileFiltersPopout;
