import React, { useState } from "react";
import styles from "../styles/NewSocialBox.module.css";
import Image from "../../components/imgData";
import NewSocialCard from "../SmallAuthComponent/NewSocialCard";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FacebookSignIn from "../../components/UserAuthDialogue/components/fblogin";
import getIPAddress from "../SmallAuthComponent/IpFile";
import UserQueryAuthModel from "./UserQueryAuthModel";
import axios from "axios";
import { saveUser } from "../../Redux/slice/userSlice";
import SignInWithApple from "../../components/UserAuthDialogue/components/SignInwithApple";
import { useGoogleLogin } from "react-google-login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleConsoleLogin from "../../components/UserAuthDialogue/components/GoogleConsoleLogin";
import { signInWithPopup } from "firebase/auth";
import {
  AppleOAuthProvider,
  auth,
  FbOAuthProvider,
} from "../../components/UserAuthDialogue/components/firebaseConfig";
import SocialQueryAuthModel from "./SocialQueryAuthModel";

const NewSocialBox = ({
  setShowLoginDialogueBox,
  setUserType,
  setUserData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [saveUserData, setSaveUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    userType: "",
    phoneNumber: "",
  });
  const [showQueryModel, setShowQueryModel] = useState(false);
  console.log("Save User => ", saveUserData);
  const handleFacebookSignIn = async () => {
    try {
      const Result = await signInWithPopup(auth, FbOAuthProvider);
      const userData = {
        email:
          Result.user.providerData[0].email ||
          Result.user.providerData[0].uid ||
          "",
        firstName: Result.user.displayName
          ? Result.user.displayName.split(" ")[0]
          : "",
        lastName: Result.user.displayName
          ? Result.user.displayName.split(" ")[1]
          : "",
        phoneNumber: Result.user.phoneNumber || "",
      };
      await handleSocialCheckAccount(userData.email, userData);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      console.log("Facebook Sign In Process Completed");
    }
  };
  const handleAppleSign = async () => {
    try {
      let result = await signInWithPopup(auth, AppleOAuthProvider);
      let userData = {
        email: result.user.email || "",
        firstName: result.user.displayName
          ? result.user.displayName.split(" ")[0]
          : "",
        lastName: result.user.displayName
          ? result.user.displayName.split(" ")[1]
          : "",
        phoneNumber: result.user.phoneNumber || "",
      };
      await handleSocialCheckAccount(userData.email, userData);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleSocialCheckAccount = async (userToBeChecked, userData) => {
    try {
      if (!userToBeChecked) {
        console.log("Email is empty");
        toast.error("Email is required");
        return;
      }
      const formData = new FormData();
      formData.append("email", userToBeChecked);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/check_account`,
        formData
      );
      console.log("Response after checking account => ", response);
      if (response.status === 200) {
        if (!response.data) {
          setSaveUser({
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.phoneNumber,
          });
          setShowQueryModel(true);
        } else {
          await handleSocialLogin(null, userData);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };
  const handleSelectUserType = async (userTypeSelected) => {
    try {
      await handleSocialLogin(userTypeSelected, saveUserData);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };

  const handleSocialLogin = async (userTypeSelected, userData) => {
    const ip = await getIPAddress();
    try {
      const formData = new FormData();
      formData.append("email", userData.email);
      formData.append("firstName", userData.firstName);
      formData.append("ip", ip);
      formData.append("phoneNumber", userData.phoneNumber);
      if (!setUserData.isUserExists && userTypeSelected) {
        formData.append("user_type", userTypeSelected);
      }
      console.log("Data top send in API => ", saveUserData);

      // Send the form data to your API
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/save_user`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Result after Facebook sign in", res);
      if (res.status === 200) {
        dispatch(
          saveUser({
            email: res.data?.email,
            id: res.data.id,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            roles: res.data.roles,
            phoneNumber: res.data?.phoneNumber,
            token: res.data?.accessToken,
            dealerId: res.data.dealerId,
            showLoginDialogue: false,
          })
        );
        if (res.data.roles === "Dealer") {
          navigate("/dealer/dashboard");
        }
      }
    } catch (error) {
      console.error("Error in Social Login with ", saveUserData, " => ", error);
      toast.error(error.response.data);
    } finally {
      setShowLoginDialogueBox(false);
    }
  };
  return (
    <div className={styles.SocialWrapper}>
      <div className={styles.SocialContainer}>
        <GoogleOAuthProvider clientId="413776152809-kefhp5sb06c160jr9h3ndb9t0svtkpke.apps.googleusercontent.com">
          <GoogleConsoleLogin
            setShowQueryModel={setShowQueryModel}
            showQueryModel={showQueryModel}
            setSaveUser={setSaveUser}
            saveUserData={saveUserData}
            handleSocialLogin={handleSocialLogin}
            setUserType={setUserType}
            setUserData={setUserData}
            handleSelectUserType={handleSelectUserType}
          />
        </GoogleOAuthProvider>

        <div onClick={handleAppleSign} className={styles.socialChildBTN}>
          <NewSocialCard logoIcon={Image.apple_logo} loginProvider="Apple" />
        </div>
        {/* <NewSocialCard logoIcon={Image.yahooLogo} loginProvider="Yahoo" /> */}
        <div onClick={handleFacebookSignIn} className={styles.socialChildBTN}>
          <NewSocialCard logoIcon={Image.fb_logo} loginProvider="Facebook" />
        </div>
      </div>
      <SocialQueryAuthModel
        showQueryModel={showQueryModel}
        setShowQueryModel={setShowQueryModel}
        handleSelectUserType={handleSelectUserType}
      />
    </div>
  );
};

export default NewSocialBox;
