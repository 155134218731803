import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../../Redux/slice/taxes&FeesSlice";
// Function to format numbers with commas
const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
};
const Box_E = ({ retailFromRedux,status }) => {
  const cashDifference = useSelector(state => state.taxesAndFees.dealerRetail.cashDifference);
  const userListingPrice = useSelector(state => parseFloat(state.taxesAndFees.dealerRetail.userListingPrice));
  const totalFees = useSelector(state => parseFloat(state.taxesAndFees.dealerRetail.totalFees));
  const totalFeesAndothers = useSelector(state => parseFloat(state.taxesAndFees.dealerRetail.totalFeesAndothers));
  const totalDue = useSelector(state => parseFloat(state.taxesAndFees.dealerRetail.totalDue) || 0);
  const paymentPriceFees = useSelector(state => parseFloat(state.taxesAndFees.dealerRetail.paymentPriceFees) || 0);
  const priceFeesPaymentMethod = useSelector(state => state.taxesAndFees.dealerRetail.priceFeesPaymentMethod || "");
  console.log("priceFeesPaymentMethodBoxE",priceFeesPaymentMethod);
  const balanceDue = totalDue - paymentPriceFees;
  const totalDueSum = cashDifference + totalFees + totalFeesAndothers;
  const dispatch = useDispatch();
  const initialValues = [
    // { header: "Total Due", InputValue: cashDifference, name: "cashDifference" },
    { header: "Payment", InputValue: paymentPriceFees, name: "paymentPriceFees" },
  ];
const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  const allInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs
  );
  const lenghtInputs = allInputs?.length;
  useEffect(() => {
    setInputValues(prevInputValues =>
      prevInputValues.map(item =>
        item.name === "paymentPriceFees"
          ? { ...item, InputValue: paymentPriceFees }
          : item
      )
    );
  }, [paymentPriceFees]);
  useEffect(() => {
    setInputValues(prevInputValues =>
      prevInputValues.map(item =>
        item.name === "cashDifference"
          ? { ...item, InputValue: cashDifference }
          : item
      )
    );
  }, [cashDifference]);
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (name, value) => {
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  };
  const [paymentMethod, setPaymentMethod] = useState('');

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  console.log("line53",paymentMethod);
useEffect(()=>{
  dispatch(setRetailData({
    priceFeesPaymentMethod:paymentMethod,
  }));
},[paymentMethod]);
useEffect(()=>{
  dispatch(setRetailData({
    balanceDue:balanceDue ? balanceDue.toFixed(2) : 0,
  }));
},[balanceDue]);
useEffect(()=>{
  
  dispatch(setRetailData({
    totalDue:totalDueSum,
  }));
},[totalDueSum]);

useEffect(() => {
  if(status === true){
    setPaymentMethod(priceFeesPaymentMethod);
  }
}, [status]);
  return (
    <div className={Styles.wrapper} style={{borderRight:"0px",borderLeft:"0px"}}>
      <div className={Styles.container}>
      <div className={Styles.inputFieldContainer_payment}>
          <span className={Styles.paymentMethodHeader}>
            {purchase_price > 0 ? lenghtInputs + 13 : lenghtInputs + 10}.
            Payment Method :{" "}
          </span>
          <select
            className={Styles.payment_method}
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <option value="">Payment Method</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
            <option value="Wire">Wire</option>
            <option value="Card">Card</option>
            <option value="Trade">Trade</option>
          </select>
        </div>
        {inputValues.map((item) => (
          <InputFieldComp
            item={item}
            key={item.header}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
          />
        ))}
        <div
          className={Styles.inputFieldContainer}
          style={{ marginTop: "0px", height: "40px" }}
        >
          <span className={Styles.balanceDueHeader}>
            {purchase_price > 0 ? lenghtInputs + 15 : lenghtInputs + 12}.
            Balance Due:{" "}
          </span>
          <p className={Styles.balanceDueData} >
           <span>$</span> {formatNumberWithCommas(balanceDue.toFixed(2))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Box_E;
