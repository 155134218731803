import React, { useState } from "react";
import styles from "./GlobalAuthMain.module.css";
import LoginComponent from "../AuthComponent/LoginComponent";
import NewSocialBox from "../AuthComponent/NewSocialBox";
import { RxCross2 } from "react-icons/rx";
import NewOTPLogin from "../AuthComponent/NewOTPLogin";
import { Link } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { saveUser } from "../../Redux/slice/userSlice";

const GlobalAuthMain = ({ setShowLoginDialogueBox }) => {
  const [userType, setUserType] = useState("User");
  const [userData, setUserData] = useState({
    email: "",
    isUserExists: false,
    token: "",
  });
  const [showQueryModel, setShowQueryModel] = useState(false);
  console.log("Current User Selected is => ", userType);
  const dispatch = useDispatch();
  const handleCloseModel = () => {
    setShowLoginDialogueBox(false);
    dispatch(saveUser({ showLoginDialogue: false }));
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.crossContainer}>
        <span onClick={handleCloseModel} style={{ cursor: "pointer" }}>
          <RxCross2 />
        </span>
      </p>
      <span className={styles.lightColorBubble}></span>
      <div className={styles.container}>
        {!userData.email ? (
          <LoginComponent setUserData={setUserData} />
        ) : (
          <NewOTPLogin
            userType={userType}
            userData={userData}
            setUserData={setUserData}
            setShowLoginDialogueBox={setShowLoginDialogueBox}
            setShowQueryModel={setShowQueryModel}
            showQueryModel={showQueryModel}
            setUserType={setUserType}
          />
        )}
        <GoogleOAuthProvider clientId="413776152809-kefhp5sb06c160jr9h3ndb9t0svtkpke.apps.googleusercontent.com">
          <NewSocialBox
            setShowLoginDialogueBox={setShowLoginDialogueBox}
            setUserType={setUserType}
            setUserData={setUserData}
          />
        </GoogleOAuthProvider>

        <p className={styles.termsPrivacyContainer}>
          <span>By creating an account you agree to Dealers Auto Center's</span>
          <span>
            <Link to="/term-condition">Terms of Use</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </span>
        </p>
      </div>
    </div>
  );
};

export default GlobalAuthMain;
