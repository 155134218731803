import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { filteredImage } from "./FilteredImage";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SliderContainer = styled.div`
  width: 100vw;
  height: 50vh;
  position: relative;
  // background-color: #f0f0f0;
  overflow: hidden;
  padding: 1rem;
`;

const Logo = styled.div`
  width: ${(props) =>
    props.isMobile ? "50px" : "30px"}; // Larger logos for mobile
  height: ${(props) => (props.isMobile ? "50px" : "30px")};
  position: absolute;
  transition: transform 0.3s ease;
  cursor: pointer;

  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;

  &:hover {
    transform: scale(1.5);
    z-index: 10;
  }
`;

const LogoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  // border : 2px solid red;

  img {
    width: 100%;
    height: 100%;
    // object-fit: contain;
    pointer-events: none;
    @media (min-width: 1800px) {
      width: 100%;
      height: 90%;
    }
  }

  span {
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    // background-color: rgba(0, 0, 0, 0.5);
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }
`;

const MainMarquee = () => {
  const navigate = useNavigate();
  const [logos, setLogos] = useState([]);
  const [carCounts, setCarCounts] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect mobile screen
  console.log("IS Screen small", isMobile);

  const sliderRef = useRef(null);
  const dragItem = useRef(null);
  const dragStart = useRef(null);
  const [mouseDownTime, setMouseDownTime] = useState(0);
  const [mouseUpTime, setMouseUpTime] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("user_id", 0);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/listing/get_makes_count`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const counts = data.reduce((acc, item) => {
          const makeKey = item.make.toLowerCase().replace(/-/g, "");
          if (filteredImage.hasOwnProperty(makeKey)) {
            acc[makeKey] = item.count;
          }
          return acc;
        }, {});
        setCarCounts(counts);
      } catch (error) {
        console.log("Fail", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Update mobile state on resize
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updateLogos = () => {
      const containerWidth = sliderRef.current.clientWidth;
      const containerHeight = sliderRef.current.clientHeight;
      const logos = Object.keys(filteredImage);
      const totalLogos = logos.length;

      // Calculate logo dimensions based on container size for responsiveness
      const logoWidth = isMobile
        ? Math.max(50, Math.min(100, containerWidth / 15)) // More logos per row on mobile
        : Math.max(50, Math.min(100, containerWidth / 20));
      const logoHeight = logoWidth; // Keep logos square

      // Set the number of rows to 3
      const rows = isMobile ? 5 : 4;

      // Calculate the maximum number of columns that can fit in the container
      const maxColumns = Math.floor(containerWidth / (logoWidth * 1.3));
      const columns = Math.min(totalLogos, maxColumns);

      // Calculate the horizontal and vertical gaps
      const horizontalGap =
        (containerWidth - columns * logoWidth) / (columns + 1);
      const verticalGap = (containerHeight - rows * logoHeight) / (rows + 1);

      const newLogos = [];
      let currentIndex = 0;

      for (let row = 0; row < rows; row++) {
        const columnsInThisRow = row % 2 === 0 ? columns : columns - 1;
        const xOffset = row % 2 === 1 ? horizontalGap + logoWidth / 2 : 0;

        for (
          let col = 0;
          col < columnsInThisRow && currentIndex < totalLogos;
          col++
        ) {
          const x = xOffset + (col + 1) * horizontalGap + col * logoWidth;
          const y = (row + 1) * verticalGap + row * logoHeight;

          newLogos.push({
            id: currentIndex,
            url: filteredImage[logos[currentIndex]],
            x,
            y,
            count: carCounts[logos[currentIndex]] || 0,
          });

          currentIndex++;
        }
      }

      setLogos(newLogos);
    }; 

    updateLogos();
    window.addEventListener("resize", updateLogos);

    return () => window.removeEventListener("resize", updateLogos);
  // }, [carCounts]);
  }, [carCounts]);
  const onMouseDown = (e, id) => {
    if (e.button !== 0) return;
    setMouseDownTime(Date.now());
    dragItem.current = id;
    dragStart.current = { x: e.clientX, y: e.clientY };
    setIsDragging(true);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    if (dragItem.current !== null) {
      const dx = e.clientX - dragStart.current.x;
      const dy = e.clientY - dragStart.current.y;

      setLogos((prevLogos) =>
        prevLogos.map((logo) => {
          if (logo.id === dragItem.current) {
            let newX = logo.x + dx;
            let newY = logo.y + dy;

            newX = Math.max(
              0,
              Math.min(newX, sliderRef.current.clientWidth - 80)
            );
            newY = Math.max(
              0,
              Math.min(newY, sliderRef.current.clientHeight - 80)
            );

            return { ...logo, x: newX, y: newY };
          }
          return logo;
        })
      );

      dragStart.current = { x: e.clientX, y: e.clientY };
    }
  };

  const onMouseUp = () => {
    setMouseUpTime(Date.now());
    dragItem.current = null;
    setIsDragging(false);
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  };

  const handleClick = useCallback(
    async (make) => {
      let adjustedMake;

      switch (make) {
        case "MERCEDESBENZ":
          adjustedMake = "MERCEDES-BENZ";
          break;
        case "LANDROVER":
          adjustedMake = "LAND ROVER";
          break;
        default:
          adjustedMake = make;
      }

      const filters = {
        make: [adjustedMake],
        models: [],
        year: [],
        engineType: [],
        fuelType: [],
        vehicleType: [],
        owner: [],
        state: [],
        interiorColor: [],
        exteriorColor: [],
        interiorType: [],
        doorCounts: [],
        driveTrain: [],
        odometer: [],
        price: [],
        exterior_damage: [],
        photos: [],
        seller: [],
      };
      // try {
      //   const response = await axios.post(
      //     `${process.env.REACT_APP_API_URL}/listing/filters`,
      //     filters
      //   );
      //   console.log("response in mainMarquee => ", response);
      //   if (response.status === 200) {
      //     navigate("/product", { state: response.data });
      //   }
      // } catch (error) {
      //   console.log(error);
      // }

      navigate("/product", { state: filters });
    },
    [navigate]
  );

  const handleLogoClick = useCallback(
    (make, e) => {
      const clickDuration = mouseUpTime - mouseDownTime;
      if (clickDuration < 200 && !isDragging) {
        handleClick(make.toUpperCase());
      }
    },
    [handleClick, isDragging, mouseDownTime, mouseUpTime]
  );

  // const getUpdatedFiltersBeforeNavigation = (make) => {

  return (
    <SliderContainer ref={sliderRef}>
      {logos.map((logo) => (
        <Logo
          key={logo.id}
          x={logo.x}
          y={logo.y}
          onMouseDown={(e) => onMouseDown(e, logo.id)}
          className="mmLogoContainer"
        >
          <LogoContent
            onClick={(e) => {
              handleLogoClick(Object.keys(filteredImage)[logo.id], e);
            }}
          >
            <img src={logo.url} alt={`Car logo ${logo.id + 1}`} />
            <span style={{ marginTop: "0.4rem" }}>{logo.count}</span>
          </LogoContent>
        </Logo>
      ))}
    </SliderContainer>
  );
};

export default MainMarquee;
