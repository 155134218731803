import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../../imgData/icon1.png";
import image2 from "../../../imgData/icon2.png";
import image3 from "../../../imgData/icon3.png";
import sliderData from "../../ExtraFeaturesData";

const ExtraFeatures1 = () => {
  const imageArray = [image1, image2, image3, image1, image2];
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {sliderData.map((data, index) => (
          <div className="col-lg-4 col-md-12 my-2" key={index}>
            <div className="d-sm-flex text-md-start text-center flex-column justify-content-center align-items-center p-1  border  bg-white rounded-4 my-2 mh-100 h-100">
              <img className="w-25 mx-2" src={imageArray[index]} alt="con" />
              <div className="d-sm-flex text-md-start text-center flex-column justify-content-center align-items-center p-1 rounded my-2 mh-100 h-100">
                <h4>{data.title}</h4>
                <p className="text-center">{data.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default ExtraFeatures1;
