import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { PDFDocument } from "pdf-lib";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

const PDFViewer = forwardRef(
  (
    {
      pdfArray,
      isCallFunc,
      setisCallFunc,
      toolbar = 0,
      zoomSize = 110,
      setCurrTotalPage = null,
    },
    ref
  ) => {
    const [mergedPdfUrl, setMergedPdfUrl] = useState(null);

    console.log("Merged PDF URL zoomSize", zoomSize);

    const userID = useSelector((state) => state.user);
    const selectedCar = useSelector((state) => state.sell.selectedVehicle);
    const [currPDFname, setCurrPDFname] = useState(null);

    // console.log("selectedCar => ", selectedCar);
    //method to merge pdfs
    const mergePDFs = async (pdfArray) => {
      console.log("pdfArray => ", pdfArray.length);
      try {
        const mergedPdf = await PDFDocument.create();
        let totalPages = 0;
        for (const item of pdfArray) {
          setCurrPDFname(item.isShow.label);
          const existingPdfBytes = await fetch(
            `${process.env.REACT_APP_DOCS_URL}/${userID.dealerId}/${item.pdf}`
          ).then((res) => res.arrayBuffer());
          const pdf = await PDFDocument.load(existingPdfBytes);
          const form = pdf.getForm();
          form.flatten();
          const copiedPages = await mergedPdf.copyPages(
            pdf,
            pdf.getPageIndices()
          );
          copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
            console.log("totalPages => in  PDF ", totalPages);
            totalPages++;
            setCurrTotalPage(totalPages);
          });
        }

        const mergedPdfBytes = await mergedPdf.save();
        console.log(
          ` mergedPDF ${selectedCar.year_Make_Model} ${
            selectedCar.trim
          } ${selectedCar?.vin.slice(-8)}${userID.id}`
        );

        console.log("totalPages => in  PDF ", totalPages);

        return URL.createObjectURL(
          new Blob([mergedPdfBytes], { type: "application/pdf" })
        );
      } catch (error) {
        toast.error(error.message);
      }
    };

    // const mergePDFs = async (pdfArray) => {
    //   try {
    //     const mergedPdf = await PDFDocument.create();  // Create a new merged PDF document

    //     for (const item of pdfArray) {
    //       const existingPdfBytes = await fetch(
    //         `${process.env.REACT_APP_DOCS_URL}/${userID.dealerId}/${item.pdf}`
    //       ).then((res) => {
    //         if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
    //         return res.arrayBuffer();
    //       });

    //       const pdf = await PDFDocument.load(existingPdfBytes);  // Load the source PDF

    //       // Copy all pages from the current PDF into the merged PDF
    //       const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());

    //       // Add the copied pages to the merged PDF
    //       pages.forEach(page => mergedPdf.addPage(page));
    //     }

    //     // Save the merged PDF
    //     const mergedPdfBytes = await mergedPdf.save();

    //     console.log(
    //       `Merged PDF: ${selectedCar.year_Make_Model} ${selectedCar.trim} ${selectedCar?.vin.slice(-8)}${userID.id}`
    //     );

    //     // Return the merged PDF as a Blob URL
    //     return URL.createObjectURL(
    //       new Blob([mergedPdfBytes], { type: "application/pdf" })
    //     );
    //   } catch (error) {
    //     console.error('Merge PDFs Error:', error);
    //     toast.error(error.message);
    //     return null;
    //   }
    // };

    //     const mergedPdfBytes = await mergedPdf.save();
    //     return URL.createObjectURL(
    //       new Blob([mergedPdfBytes], { type: "application/pdf" })
    //     );
    //   } catch (error) {
    //     console.error("PDF Merge Error:", error);
    //     toast.error(error.message);
    //     return null;
    //   }
    // };
    useEffect(() => {
      const mergeAndSetPdf = async () => {
        const url = await mergePDFs(pdfArray);

        setMergedPdfUrl(url);
      };

      mergeAndSetPdf();

      return () => {
        setCurrTotalPage(0);
      };
    }, []);
    const downloadMergedPDF = (callback) => {
      if (mergedPdfUrl) {
        console.log("isCallFunc download state ", mergedPdfUrl);
        const link = document.createElement("a");
        console.log("selectedCar => ", selectedCar);
        link.href = mergedPdfUrl;
        link.download = `${selectedCar?.year_Make_Model} ${
          selectedCar?.trim
        } ${selectedCar?.vin.slice(-8)}${userID.id}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("PDF is not ready for download yet.");
      }
      // Delay of 2000 milliseconds (2 seconds)
      if (callback) callback();
      console.log("Download Successfully.........");
    };

    const navigate = useNavigate();

    useEffect(() => {
      if (isCallFunc && mergedPdfUrl) {
        downloadMergedPDF(() => {
          console.log("isCallFunc download state ", isCallFunc);
          navigate("/dealer/inventory/sold", { state: true });
          console.log("Navigating after downalod isCallFunc download state");
        });
        setisCallFunc(false);
      }
    }, [isCallFunc, navigate, mergedPdfUrl]);

    useImperativeHandle(ref, () => ({
      downloadMergedPDF,
    }));

    if (!mergedPdfUrl) {
      return (
        <div className="pdfLoaderWrapper">
          <div className="pdfLoaderContainer">
            <span>Fetching : {currPDFname}</span>
          </div>
        </div>
      );
    }
    {
      /* 
        Loading... {currPDFname} */
    }
    // for pdf zoom size----------------------------------------------
    return (
      <div
        style={{ height: "85vh", marginBottom: "1.5rem", position: "relative" }}
      >
        {/* <button onClick={downloadMergedPDF}>Download All PDFS</button> */}

        <iframe
          src={mergedPdfUrl + `#zoom=${zoomSize}&toolbar=${toolbar}`}
          // src={`${mergedPdfUrl}#view=fit&pagemode=none&zoom=150`}
          width="100%"
          height="100%"
          type="application/pdf"
          title="Merged PDF File"
        />
        <span
          style={{
            position: "absolute",
            top: "0",
            right: "2rem",
            height: "50px",
            width: "100px",
            backgroundColor: "inherit",
            textAlign: "center",
            color: "white",
            opacity: "0.9",
          }}
        >
          {/* <FaInfoCircle /> */}
        </span>
      </div>
    );
  }
);

export default PDFViewer;
