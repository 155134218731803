import React, { useEffect, useState } from "react";
import styles from "../styles/NewOTPLogin.module.css";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveUser } from "../../Redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import UserQueryAuthModel from "./UserQueryAuthModel";

const NewOTPLogin = ({
  userType,
  userData,
  setUserData,
  setShowLoginDialogueBox,
  setUserType,
  showQueryModel,
  setShowQueryModel,
}) => {
  console.log(
    "Current user type is ",
    userType,
    "  and user data is ",
    userData
  );

  const [InputOtp, setInputOtp] = useState("");
  const [ip, setIP] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);

        setCountryCode(res.data.country_calling_code);
      } catch (error) {
        console.log(error);
      }

      // setIP(res.data.ip)
    };
    getUserCountryCode();
  }, []);
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const res = await axios.get("https://geolocation-db.com/json");
        // const res = await axios.get("https://api.seeip.org/jsonip");
        console.log("res IP => ", res.data.IPv4);
        setIP(res.data.IPv4);
      } catch (error) {
        console.log(error);
      }
    };
    fetchIP();
  }, []);
  const handleInputChange = async (e) => {
    const { value } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setInputOtp(value);

      if (value.length === 6) {
        if (userData.isUserExists) {
          await handleContinueResultLogin(value);
        } else {
          await handleContinueResult(value);
        }
      }
    }
  };
  const handleContinueResult = async (value) => {
    try {
      console.log("Handling API continue Result");
      const formData = new FormData();
      console.log("formData", formData);
      formData.append("email", userData.email);
      formData.append("otp", value);
      formData.append("countryCode", countryCode);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/authentication/verifyotp`,
        formData
      );

      if (res.status !== 200) {
        console.log("User OTP Failure");
      }

      if (res.status === 200) {
        console.log("response from OTP Login", res.data);
        toast.success("OTP verification successful");
        setUserData((prevData) => ({
          ...prevData,
          token: res.data.accessCode,
        }));
        setShowQueryModel(true);
      }
      // dispatch(saveUser({ email: userData.email, roles: userType }));
      // setShowLoginDialogueBox(false);
      // if (userType === "Dealer") {
      //   navigate("/dealer/dashboard");
      // }
    } catch (error) {
      console.log("error in  catch part while using otp");
      toast.error(error.response.data);
    }
  };

  const handleContinueResultLogin = async (value) => {
    try {
      console.log("Handling API continue Result");
      const formData = new FormData();
      console.log("formData", formData);
      formData.append("email", userData.email);
      formData.append("otp", value);
      formData.append("ip", ip);
      formData.append("countryCode", countryCode);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/authentication/verifyOtp`,
        formData
      );

      if (res.status !== 200) {
        console.log("User OTP Failure");
      }
      console.log("response from OTP Login", res.data);
      if (res.status === 200) {
        toast.success("Login Successful");
        setShowLoginDialogueBox(false);
        setUserData((prevData) => ({
          ...prevData,
          email: "",
          isUserExists: "",
        }));
        // dispatch(saveUser({ email: userData.email, roles: userType }));
        dispatch(
          saveUser({
            token: res.data.accessToken,
            email: res.data.email,
            id: res.data.id,
            phoneNumber: res.data.phoneNumber,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            office_number: res.data.office_number,
            lattitude: res.data.lattitude,
            longitude: res.data.longitude,
            zip_code: res.data.zip_code,
            address: res.data.address,
            profile_picture: res.data.profile_picture,
            website: res.data.website,
            roles: res.data.roles,
            dealerId: res.data.dealerId,
            showLoginDialogue: false,
          })
        );
        if (userType === "Dealer") {
          navigate("/dealer/dashboard");
        }
      }

      console.log("response from OTP Login", res.data);

      // setShowLoginDialogueBox(false);
    } catch (error) {
      console.log("error in  catch part while using otp");
      toast.error(error.response.data);
    }
  };
  const handleUserTypeSignUp = async (userTypeSelected) => {
    try {
      const formData = new FormData();
      formData.append("email", userData.email);
      formData.append("userType", userTypeSelected);
      formData.append("countryCode", countryCode);
      formData.append("accessCode", userData.token);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/authentication/signup_save`,
        formData
      );
      console.log("response from user type signup", response.data);
      if (response.status === 200) {
        setShowLoginDialogueBox(false);
        dispatch(
          saveUser({
            token: response.data.accessToken,
            email: response.data.email,
            id: response.data.id,
            phoneNumber: response.data.phoneNumber,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            office_number: response.data.office_number,
            lattitude: response.data.lattitude,
            longitude: response.data.longitude,
            zip_code: response.data.zip_code,
            address: response.data.address,
            profile_picture: response.data.profile_picture,
            website: response.data.website,
            roles: response.data.roles,
            dealerId: response.data.dealerId,
            showLoginDialogue: false,
          })
        );
      }
    } catch (error) {
      console.log("error in  catch part while using otp");
      toast.error(error.response.data);
    }
  };

  return (
    <div className={styles.OTPContainer}>
      <p className={styles.header}>Please enter OTP</p>
      <input
        type="text"
        style={{ backgroundColor: "rgb(243, 223, 196)" }}
        className={styles.inputField}
        onChange={handleInputChange}
        value={InputOtp}
        placeholder="6 Digit OTP"
      />
      <UserQueryAuthModel
        showQueryModel={showQueryModel}
        setShowQueryModel={setShowQueryModel}
        setUserType={setUserType}
        setUserData={setUserData}
        inputEmail={userData.email}
        handleCreateResult={handleUserTypeSignUp}
      />
    </div>
  );
};

export default NewOTPLogin;
