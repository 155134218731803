import React from "react";
import styles from "../styles/NewSocialCard.module.css";

const NewSocialCard = ({ logoIcon, loginProvider = "Google" }) => {
  return (
    <div className={styles.container}>
      <span>
        <img src={logoIcon} alt={`${loginProvider} logo`} />
      </span>
      <span>Login By {loginProvider}</span>
    </div>
  );
};

export default NewSocialCard;
