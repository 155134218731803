import React, { useState } from "react";
// import "./ListMyVehicle.css";
import "./SellMyVehicle.css";
import Image from "../imgData";

const VerifiedDealerOffer = () => {
  const [activeTab, setActiveTab] = useState("vin");
  return (
    <div className="smvWrapper gradientDealerBackground">
      <div className="smvContainer">
        <p className="smvHeader">
          <img
            src={Image.getOfferFromVerifiedDealer}
            alt="dealer offer image"
          />
        </p>
        <p className="smvHeader">Get Offer From Verified Dealer</p>
        <p className="smvSubHeader">
          Get started now by entering the VIN below
        </p>
        <div className="smvTableContainer">
          <div className="smvTabsContainer">
            <button
              style={{
                backgroundColor: activeTab === "vin" && "#d1823a",
                color: activeTab === "vin" && "white",
                borderBottom: activeTab === "vin" && "1px solid white",
              }}
              onClick={() => setActiveTab("vin")}
            >
              VIN
            </button>
            <button
              style={{
                backgroundColor: activeTab === "plate" && "#d1823a",
                color: activeTab === "plate" && "white",
                borderBottom: activeTab === "plate" && "1px solid white",
              }}
              onClick={() => setActiveTab("plate")}
            >
              Plate
            </button>
            <button
              style={{
                backgroundColor: activeTab === "manuel" && "#d1823a",
                color: activeTab === "manuel" && "white",
                borderBottom: activeTab === "manuel" && "1px solid white",
              }}
              onClick={() => setActiveTab("manuel")}
            >
              Manuel
            </button>
          </div>
          <div className="smvFormWrapper">
            <div className="smvFormContainer">
              {activeTab === "vin" && (
                <>
                  <input type="text" placeholder="Enter your VIN" />
                  <button>Search</button>
                </>
              )}
              {activeTab === "plate" && (
                <div className="smvPlateWrapper">
                  <div className="smvPlateContainer">
                    <input type="text" placeholder="Plate Number" />
                    <select>
                      <option value="">State</option>
                      {/* {usaState} */}
                    </select>
                  </div>
                  <p>
                    <button>Continue</button>
                  </p>
                </div>
              )}
              {activeTab === "manuel" && (
                <div className="smvManuelContainer">
                  <div className="smvManuel1">
                    <select name="" id="">
                      <option value="">Year</option>
                    </select>
                    <input type="text" placeholder="Make" />
                    <input type="text" placeholder="Model" />
                  </div>
                  <div className="smvManuel2">
                    <input type="text" placeholder="Trim" />
                    <input type="text" placeholder="Transmission" />
                  </div>
                  <div className="smvManuel3">
                    <input type="text" placeholder="BodyClass" />
                    <input type="text" placeholder="Drive Train Type" />
                  </div>

                  <p className="smvManuelbtnContainer">
                    <button>Continue</button>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedDealerOffer;
