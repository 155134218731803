const mobilePopoutFilters = [
  { label: "Vehicle Type", name: "vehicle_type" },
  { label: "Make", name: "makes" },
  { label: "Model", name: "models" },
  { label: "DAC Check Score", name: "dacCheckScore" },
  { label: "Door Count", name: "doorCounts" },
  { label: "Drive Train", name: "driveTrain" },
  { label: "Engine Type", name: "engineType" },
  { label: "Price", name: "price" },
  { label: "Year", name: "year" },
  { label: "Odometer", name: "odo" },
  { label: "Owners", name: "user" },
  { label: "Interior Color", name: "interiorColor" },
  { label: "Exterior Color", name: "exteriorColor" },
  { label: "Frame Damage", name: "exterior_damage" },
  { label: "Fuel Type", name: "fuelType" },
  { label: "Interior Type", name: "interiorType" },
];

export default mobilePopoutFilters;
