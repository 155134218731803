import "../home/home.css";
// import Marquee from 'react-fast-marquee';
import { Link, useLocation, useNavigate } from "react-router-dom";
import SellYourCar from "./SellYourCar";
import MonthlyLoanCalculator from "./MonthlyLoanCalculator";
import MainMarquee from "./MainMarquee";
import VehicleSlider from "./VehicleSlider";
import Navbar from "../navbars/Navbar";
import { React, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CompleteUserDetailModal from "../UserAuthDialogue/Pages/CompleteUserDetailModal";
import HomeFilters from "./HomeFilters";
import NearByCars from "./NearByCars";
import ExtraFeatures1 from "./WebsitePreviews/components/ExtraFeatures1";
import VehicleReport1 from "./WebsitePreviews/components/VehicleReport";
import DealerLoginMarquee from "./WebsitePreviews/DealerLoginMarquee";
import { useDispatch } from "react-redux";
import { getRedirectResult } from "firebase/auth";
import { auth } from "../UserAuthDialogue/components/firebaseConfig";
import { getIPAddress } from "../UserAuthDialogue/components/SignInwithApple";
import { saveUser } from "../../Redux/slice/userSlice";
import axios from "axios";
import HandleAppleResullt from "./HandleAppleResullt";
import DealerSideBar from "../DealerSection/DealerSideBar/DealerSideBar";
import HomeFiltersLogin from "./HomeFilterLogin";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { Modal } from "react-bootstrap";
import GoogleOneTaplogin from "../UserAuthDialogue/components/GoogleOneTaplogin";
import SideBar from "../dashboard/SideBar";
import MobileHeroSection from "./MobileHeroSection";
import OurProducts from "./OurProducts";
import Image from "../imgData";
import Dealer_left_Section from "../DealerAuthSection/Pages/Dealer_left_Section";
import DesktopNewFilters from "./DesktopNewFilters";
import ExtraFeaturesDesktop from "./ExtraFeaturesDesktop";
import SellMyVehicle from "./ListMyVehicle";
import ListMyVehicle from "./ListMyVehicle";
import VerifiedDealerOffer from "./VerifiedDealerOffer";
import ExtraFeaturesMobile from "./ExtraFeatureMobile";
import GoogleOneTap from "../UserAuthDialogue/GoogleOT";

const Home = () => {
  const navigate = useNavigate();
  const calculatorRef = useRef(null);
  const userData = useSelector((state) => state.user);
  const { firstName, lastName, id, email, roles } = userData;
  console.log("Id of user in my profile from redux", userData.id);
  const [showDialogueBox, setShowDialogueBox] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id && email) {
      if (firstName && lastName && roles === "User") {
        setShowDialogueBox(false);
        console.log(
          "All user details are present like first name , lastname and profile picture"
        );
      } else if (roles === "Dealer") {
        setShowDialogueBox(false);
      } else {
        // console.log(
        //   "All user details are present like first name , lastname and profile picture"
        // );
        console.log(
          "Not present =>  first name , lastname and profile picture"
        );
        setShowDialogueBox(true);
        dispatch(saveUser({ showLoginDialogue: false }));
      }
    }
  }, [firstName, lastName, id, email, roles]);

  useEffect(() => {
    if (showDialogueBox) {
      document.body.classList.add("disable-scroll");
      // Disable other user interactions
      const disableUserEvents = (e) => e.stopImmediatePropagation();
      // window.addEventListener("click", disableUserEvents, true);
      window.addEventListener("keydown", disableUserEvents, true);

      return () => {
        window.removeEventListener("click", disableUserEvents, true);
        window.removeEventListener("keydown", disableUserEvents, true);
      };
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [showDialogueBox]);

  //
  const [isPermanentlyExpanded, setIsPermanentlyExpanded] = useState(() => {
    // Initialize state from localStorage, default to false if not set
    return localStorage.getItem("sidebarExpanded") === "true";
  });

  useEffect(() => {
    // Update localStorage whenever isPermanentlyExpanded changes
    localStorage.setItem("sidebarExpanded", isPermanentlyExpanded);
  }, [isPermanentlyExpanded]);

  const toggleSidebar = () => {
    setIsPermanentlyExpanded(!isPermanentlyExpanded);
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleSidebarHover = (hovered) => {
    setIsHovered(hovered);
  };

  const isExpanded = isPermanentlyExpanded || isHovered;
  const isSideBarOpen = useSelector((state) => state.user.isSideBarOpen);
  //customer sidebar hande
  const [navbarText, setNavbarText] = useState("");
  const handleNavbarTextChange = (text) => {
    setNavbarText(text);
  };
  const isOpen2 = true;
  const [isOpen, setIsOpen] = useState(false);
  const isPermanentlyExpanded2 = false;
  //  for scroll calculator---------------------------------------------------
  const scrollToCalculator = () => {
    if (calculatorRef.current) {
      calculatorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // ------------------------------------------------------------
  return (
    <>
    <div className="home_grand_parent_wrapper">
      {showDialogueBox ? (
        <div className="complete_user_details_modal">
          <CompleteUserDetailModal setShowDialogueBox={setShowDialogueBox} />
        </div>
      ) : null}
      <GoogleOneTaplogin />
      <Navbar position="absolute" shouldShow={true} />
      {isSideBarOpen && id !== 0 && email && roles === "Dealer" && (
        <div
          className={`dealer_sidebar_home_Parent ${
            isSideBarOpen ? "ShowSidebar" : "HideSidebar"
          }`}
          style={{
            flex: isSideBarOpen ? "0.15" : "0.75",
          }}
        >
          <DealerSideBar
            isPermanentlyExpanded={isPermanentlyExpanded}
            onHoverChange={handleSidebarHover}
          />
        </div>
      )}
      {isSideBarOpen && id !== 0 && email && roles === "User" && (
        <div
          className={`dealer_sidebar_home_Parent ${
            isSideBarOpen ? "ShowSidebar" : "HideSidebar"
          }`}
          style={{
            position: "relative",
            top: "0",
            flex: isSideBarOpen ? "0.15" : "0.75",
          }}
        >
          <SideBar
            onButtonClick={handleNavbarTextChange}
            isOpen={isOpen2}
            toggleSidebar={toggleSidebar}
            isPermanentlyExpanded={isPermanentlyExpanded2}
            setIsOpen={setIsOpen}
            setIsPermanentlyExpanded={setIsPermanentlyExpanded}
          />
        </div>
      )}
       {roles && roles === "Dealer" ? null  : <DesktopNewFilters />}

      <div className="marqueeContainer">
        {roles && roles === "Dealer" ? <DealerLoginMarquee /> : <MainMarquee />}
      </div>
      {roles && roles === "Dealer" && (
        <div className="homeFilterContainer">
          <HomeFilters scrollToCalculator={scrollToCalculator} roles={roles} />
        </div>
      )}

      {/* <div className="mobileHeroSection">
        <MobileHeroSection />
      </div> */}

      {/* {roles && roles === "Dealer" ? (
            <HomeFiltersLogin
              scrollToCalculator={() =>
                calculatorRef.current.scrollIntoView({ behavior: "smooth" })
              }
            />
          ) : (
            <HomeFilters
              scrollToCalculator={() =>
                calculatorRef.current.scrollIntoView({ behavior: "smooth" })
              }
            />
          )} */}

      <div className="extraFeatureHomeContainer">
        {/* <ExtraFeatures1 /> */}
      </div>
      <div className="extraFeatureHomeContainerMobile">
        <ExtraFeaturesMobile />
      </div>
      <div className="extraFeatureHomeContainerDesktop">
        <ExtraFeaturesDesktop />
      </div>
      <VehicleSlider />
      {/* <HandleAppleResullt /> */}
      <NearByCars />

      <ListMyVehicle />
      {/* <MonthlyLoanCalculator ref={calculatorRef} /> */}
      <MonthlyLoanCalculator ref={calculatorRef} />
      {id && email && roles !== "Dealer" && (
        <>
          <div className="sellYourCarContainer">
            <SellYourCar />
          </div>
          <div className="vehicleReportContainer">
            <VehicleReport1 />
          </div>
        </>
      )}

      <VerifiedDealerOffer />
      {/* <OurProducts /> */}
      <Dealer_left_Section />
      {/* ----------------------------------------------------------------CRM Featured End--------------------------------------------------------- */}

      {/* ----------------------------------------------------------------Trusted Financing Partners Start--------------------------------------------------------- */}
      {id && email && roles !== "Dealer" && (
        <section className="p-4">
          <div className="container-fluid text-center">
            <div className="row">
              <div className="col-md-12 p-4 px-0">
                <h2>Sources</h2>

                <div className="row pt-4">
                  {/* <div className="customer-logos slider p-0"> */}

                  <div className="auto_slider_containers">
                    <ul className="auto_sliders">
                      <li>
                        <img src="client-logo/logo1.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo2.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo3.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo4.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo5.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo1.webp" alt="" />
                      </li>
                    </ul>
                    <ul className="auto_sliders">
                      <li>
                        <img src="client-logo/logo2.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo3.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo4.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo5.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo1.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo2.webp" alt="" />
                      </li>
                    </ul>
                    <ul className="auto_sliders">
                      <li>
                        <img src="client-logo/logo3.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo4.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo5.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo1.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo2.webp" alt="" />
                      </li>
                      <li>
                        <img src="client-logo/logo3.webp" alt="" />
                      </li>
                    </ul>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className="laptopProtoTypeContainer">
        <img src={Image.laptopProtoType} alt="laptopProtoType" />
        <p>
          <span>Sell</span>
          <span style={{ color: "#e8a262" }}>Your </span>
          <span>Cars</span>
        </p>
      </div>
      {/* <section>
        <div className="bg-color"></div>
      </section> */}
      {/* ----------------------------- */}

      <section
        // className="pb-5"
        style={{
          // backgroundImage: "url('image/bg-img.webp')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container-fluid">
          <div className="row" style={{ backgroundColor: "#efeeee" }}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div
                className="p-4 d-flex  parentNextCar"
                style={{
                  // backgroundImage: "url('image/waiting.jpg')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="waiting">
                  <div className="pt-4 pb-1" style={{ paddingLeft: "1rem" }}>
                    <h1 className="nextCarHeader">
                      Your next car is waiting <br />
                      for you here...
                    </h1>
                  </div>
                  <div className="pt-4 pb-4">
                    <button className="btn-grad">More</button>
                  </div>
                </div>
                <div className="mobileProtoTypeContainer ">
                  <img src={Image.mobileProtoType} alt="mobileProtoType" />
                </div>
              </div>
              {/* <div style={{ backgroundColor: "#f5f7fb" }}>
                <div className="row p-4">
                  <div className="col-lg-12 text-center">
                    <p
                      style={{
                        backgroundColor: "#fff",
                        padding: "15px",
                        fontSize: "33px",
                        borderRadius: "50px",
                        margin: "auto",
                        color: "#c57a40",
                        width: "78px",
                        height: "78px",
                      }}
                    >
                      <i
                        className="fa-solid fa-envelope"
                        style={{ fontSize: "35px" }}
                      ></i>
                    </p>
                    <p>Contact Us</p>
                    <p>
                      <b>abc@email.co</b>
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
      {/* -------------------------------------------------USA's top-rated car buying app End--------------------------------------------------------------*/}
    </div>
    {/* <GoogleOneTap/> */}
    </>
  );
};

export default Home;
