// SignInWithApple.js
import { auth, AppleOAuthProvider } from "./firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";

const SignInWithApple = async (setSaveUser) => {
  try {
    let result = await signInWithPopup(auth, AppleOAuthProvider);
    console.log("Result from popup => ", result);

    setSaveUser({
      email: result.user.email || "",
      firstName: result.user.displayName
        ? result.user.displayName.split(" ")[0]
        : "",
      lastName: result.user.displayName
        ? result.user.displayName.split(" ")[1]
        : "",
      phoneNumber: result.user.phoneNumber || "",
    });
    return 
  } catch (error) {
    console.error("Sign in with Apple failed:", error);
    toast.error("Sign in failed. Please try again.");
  }
};

export default SignInWithApple;
