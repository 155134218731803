import React, { useEffect, useState } from "react";
import "./Styles/DealerDashBoard.css";
import DealerDashBoardModal from "../DealerDashBoardModal/DealerDashBoardModal";
import "./DealerDashBoard.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { dropdownData } from "./DashBoardData";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";

const DealerDashBoard = () => {
  const { dealerId } = useSelector((state) => state.user);
  const [showNavigationModal, setShowNavigationModal] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (showNavigationModal) {
      dispatch(saveUser({ isSideBarOpen: false }));
    }
  }, [showNavigationModal]);
  //   for drop down------------------------------------
  const [openDropdowns, setOpenDropdowns] = useState({});

  // Toggle function for individual dropdown buttons
  const handleToggle = (index) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific dropdown by index
    }));
  };
  const [showDashBoardDetails, setShowDashBoardDetails] = useState(false);
  const [dropDataToShow, setdropDataToShown] = useState([]);
  const [dataIndex, setDataIndex] = useState(null);
  console.log("dropDataToShow => ", dropDataToShow);
  const handleShowDropDown = (parent, index) => {
    const dataToShow = dropdownData.filter(
      (item) => item.buttonTitle === parent
    );
    // console.log("dropDataToShow => ", dataToShow);
    setShowDashBoardDetails(true);
    setdropDataToShown(dataToShow);
    setDataIndex(index);
  };
  // take value of the lable deropdown from api-------------------------------------------
  const [dropDownValue, setDropDownValue] = useState({});
  const [subDropdownState, setSubDropdownState] = useState({
    activeInventory: false,
    soldInventory: false,
  });

  const [inventoryValues, setInventoryValues] = useState({
    activeInventory: { present: 0, absent: 0 },
    soldInventory: { present: 0, absent: 0 },
  });
  const handleSubDropdownToggle = (menuTitle) => {
    console.log("call subdropdown", menuTitle);
    setSubDropdownState((prev) => ({
      ...prev,
      [menuTitle]: !prev[menuTitle],
    }));
  };

  // const dropDownValue = {
  //   "Purchase": {
  //       "Weekly Purchase": 200.00,
  //       "Monthly Purchase": 200.00,
  //       "Today Purchase": 200.00,
  //       "Yearly Purchase": 200.00
  //   },
  //   "Sales": {
  //       "Monthly Sale": 561.00,
  //       "Weekly Sale": 561.00,
  //       "Yearly Sale": 561.00,
  //       "Today Sale": 561.00
  //   },
  //   "Inspection": {
  //       "Invalid Inspection": 0,
  //       "Valid Inspection": 13
  //   },
  //   "Title": {
  //       "Title Recieved": 1,
  //       "Title Not Recieved": 12
  //   }
  // };
  const objFor = {
    id: dealerId,
    make: null,
    saleType: null,
  };
  // --------------------------------------------------------------
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/dashboard/sales-report`,
          objFor,
          {}
        );
        setDropDownValue(response.data);
        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    // Trigger the POST request
    postData();
  }, []);

  useEffect(() => {
    dropdownData.forEach((section) => {
      // Update for Sales Reports
      if (section.buttonTitle === "Sales Reports") {
        section.menuItems.forEach((item) => {
          if (
            item.title === "All Sold Today" &&
            dropDownValue["Sales"]?.["Today Sale"]
          ) {
            item.value = dropDownValue["Sales"]["Today Sale"];
          } else if (
            item.title === "All Sold This Week" &&
            dropDownValue["Sales"]?.["Weekly Sale"]
          ) {
            item.value = dropDownValue["Sales"]["Weekly Sale"];
          } else if (
            item.title === "All Sold This Months" &&
            dropDownValue["Sales"]?.["Monthly Sale"]
          ) {
            item.value = dropDownValue["Sales"]["Monthly Sale"];
          } else if (
            item.title === "All Sold year to Date" &&
            dropDownValue["Sales"]?.["Yearly Sale"]
          ) {
            item.value = dropDownValue["Sales"]["Yearly Sale"];
          } else if (
            item.title === "All Time Sale" &&
            dropDownValue["Sales"]?.["All Time Sale"]
          ) {
            item.value = dropDownValue["Sales"]["All Time Sale"];
          }
        });
      }

      // Update for Purchase Reports
      if (section.buttonTitle === "Purchase Reports") {
        section.menuItems.forEach((item) => {
          if (
            item.title === "All Purchase Today" &&
            dropDownValue["Purchase"]?.["Today Purchase"]
          ) {
            item.value = dropDownValue["Purchase"]["Today Purchase"];
          } else if (
            item.title === "All Purchase This Week" &&
            dropDownValue["Purchase"]?.["Weekly Purchase"]
          ) {
            item.value = dropDownValue["Purchase"]["Weekly Purchase"];
          } else if (
            item.title === "All Purchase This Months" &&
            dropDownValue["Purchase"]?.["Monthly Purchase"]
          ) {
            item.value = dropDownValue["Purchase"]["Monthly Purchase"];
          } else if (
            item.title === "All Purchase year to Date" &&
            dropDownValue["Purchase"]?.["Yearly Purchase"]
          ) {
            item.value = dropDownValue["Purchase"]["Yearly Purchase"];
          }else if (
            item.title === "All Time Purchase" &&
            dropDownValue["Purchase"]?.["All Time Purchase"]
          ) {
            item.value = dropDownValue["Purchase"]["All Time Purchase"];
          }
        });
      }

      // Update for Vehicles Inspection Report
      if (section.buttonTitle === "Vehicles Inspection Report") {
        section.menuItems.forEach((item) => {
          if (
            item.title === "Valid Inspections" &&
            dropDownValue["Inspection"]?.["Valid Inspection"]
          ) {
            item.value = dropDownValue["Inspection"]["Valid Inspection"];
          } else if (
            item.title === "Invalid Inspections" &&
            dropDownValue["Inspection"]?.["Invalid Inspection"]
          ) {
            item.value = dropDownValue["Inspection"]["Invalid Inspection"];
          }
        });
      }

      // Update for Titles Status (Available Out Of Total) Received Not Received
      // if (section.buttonTitle === "Titles Status") {
      //   section.menuItems.forEach(item => {
      //     if (item.title === "Present" && dropDownValue["Title"]?.["Title Recieved"]) {
      //       item.value = dropDownValue["Title"]["Title Recieved"];
      //     } else if (item.title === "Absent" && dropDownValue["Title"]?.["Title Not Recieved"]) {
      //       item.value = dropDownValue["Title"]["Title Not Recieved"];
      //     }
      //   });
      // }
      if (dropDownValue && dropDownValue["Listing Title"]) {
        const activeInventoryData = dropDownValue["Listing Title"];
        setInventoryValues(prevState => ({
          ...prevState,
          activeInventory: {
            present: activeInventoryData["Title Recieved"] || 0,
            absent: activeInventoryData["Title Not Recieved"] || 0,
          },
        }));
      }
    
      if (dropDownValue && dropDownValue["Sold Title"]) {
        const soldInventoryData = dropDownValue["Sold Title"];
        setInventoryValues(prevState => ({
          ...prevState,
          soldInventory: {
            present: soldInventoryData["Title Recieved"] || 0,
            absent: soldInventoryData["Title Not Recieved"] || 0,
          },
        }));
      }
    });
  }, [dropDownValue]);

  console.log("DropdownValue", dropDownValue);
  // ------------------------------------------------------------------------------
  return (
    <div className="dealerDashBoard_Wrapper">
      <div className="dealerDashBoard_Container" style={{ width: "100%" }}>
        {showNavigationModal ? (
          <DealerDashBoardModal
            setShowNavigationModal={setShowNavigationModal}
          />
        ) : null}

        {/* DeskTop  DropDown*/}
        <div className="dashBoardDropDownContainer">
          <h1>Dashboard</h1>
          <div className="DropDownWrapper">
            {dropdownData.map((dropdown, index) => (
              <div
                className="dropdown"
                key={index}
                style={{
                  position: "relative",
                  // marginBottom: "10px",
                  // width: "90%",
                }}
              >
                {/* Button with Dynamic Title from Array */}
                <button
                  className="btn btn-secondary  toggleButtonDesktop"
                  type="button"
                  id={`dropdownMenuButton${index}`}
                  onClick={() => handleToggle(index)}
                  aria-expanded={openDropdowns[index] ? "true" : "false"}
                  data-bs-toggle="dropdown"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    color: "black",
                    alignItems: "center",
                    height: "4rem",
                    fontFamily: "inherit",
                    fontSize: "25px",
                    paddingLeft: "40px", // Add padding to the left of the button to make space for the circle
                  }}
                >
                  <span className="classDropDownParent">
                    <span
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px", // Square shape
                        top: "20px",
                        backgroundColor: "#d1823a", // Background color
                        borderRadius: "5px", // Optional rounded corners
                      }}
                    />
                    {/* Custom Dropdown Title from Array */}
                    {dropdown.buttonTitle}
                    {/* Arrow Icon */}
                  </span>
                  <span>
                    {openDropdowns[index] ? (
                      <IoIosArrowUp style={{ fill: "#d1823a" }} />
                    ) : (
                      <IoIosArrowDown style={{ fill: "#d1823a" }} />
                    )}
                  </span>
                </button>

                {/* Dropdown Menu with Dynamic Items from Array */}
                <ul
                  className="dropdown-menu ShownDropDown"
                  aria-labelledby={`dropdownMenuButton${index}`}
                  style={{
                    width: "100%",
                    padding: "30px",
                    paddingTop: "0",
                    position: "static",
                    transform: "translate(0, 0)",
                  }}
                >
                  {dropdown.menuItems.map((item, itemIndex) => (
                    <li
                      key={itemIndex}
                      style={{
                        borderBottom: "1px solid grey",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "5px 0",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div style={{ display: "flex", width: "100%" }}>
                        <span
                          style={{
                            width: "30px",
                            height: "26px",
                            borderRadius: "50%",
                            backgroundColor: "#EFD4BB",
                            color: "black",
                            textAlign: "center",
                            lineHeight: "30px",
                            marginRight: "10px",
                            fontSize: "16px",
                            marginTop: "5px",
                            boxShadow:
                              "3px 3px 3px grey , inset 0px 1px 2px grey;",
                          }}
                        ></span>
                        <a
                          className="dropdown-item"
                          href="#"
                          style={{ fontFamily: "inherit", fontSize: "20px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            // Toggle sub-dropdown for active/sold inventory
                            if (item.title === "Active Inventory") {
                              handleSubDropdownToggle("Active Inventory");
                            } else if (item.title === "Sold Inventory") {
                              handleSubDropdownToggle("Sold Inventory");
                            }
                          }}
                        >
                          {item.title}
                        </a>
                        <span
                          style={{ paddingRight: "2%" }}
                          className="reportValues"
                        >
                          {item.title === "Active Inventory" ||
                          item.title === "Sold Inventory"
                            ? ""
                            : item.value}
                        </span>
                      </div>
                      {/* Conditionally render the sub-dropdown */}
                      {(item.title === "Active Inventory" ||
                        item.title === "Sold Inventory") && (
                        <div
                          style={{
                            display: subDropdownState[item.title]
                              ? "block"
                              : "none",
                            marginLeft: "5rem",
                            width: "100%",
                          }}
                        >
                          <ul>
                            <li style={{ padding: "5px 0" }}>
                              <a
                                href="#"
                                style={{
                                  fontSize: "18px",
                                  fontFamily: "inherit",
                                  color: "black",
                                }}
                              >
                                Present{" "}
                                <span
                                  style={{ fontSize: "16px", color: "grey" }}
                                >
                                  {/* <p>{item.title.replace(/\s+/g, "")}</p>{" "} */}
                                  {/* Title without spaces */}(
                                  {/* {console.log("Computed Key:", item.title.replace(/\s+/g, ""))} */}
                                  {inventoryValues[
                                    item.title
                                      .replace(/\s+/g, "")
                                      .charAt(0)
                                      .toLowerCase() +
                                      item.title.replace(/\s+/g, "").slice(1)
                                  ]?.present || 0}
                                  )
                                </span>
                              </a>
                            </li>
                            <li style={{ padding: "5px 0" }}>
                              <a
                                href="#"
                                style={{
                                  fontSize: "18px",
                                  fontFamily: "inherit",
                                  color: "black",
                                }}
                              >
                                Absent{" "}
                                <span
                                  style={{ fontSize: "16px", color: "grey" }}
                                >
                                  {/* <p>{item.title.replace(/\s+/g, "")}</p>{" "} */}
                                  {/* Title without spaces */}(
                                  {/* {console.log("Computed Key:", item.title.replace(/\s+/g, ""))} */}
                                  {inventoryValues[
                                    item.title
                                      .replace(/\s+/g, "")
                                      .charAt(0)
                                      .toLowerCase() +
                                      item.title.replace(/\s+/g, "").slice(1)
                                  ]?.absent || 0}
                                  )
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {showDashBoardDetails ? (
          <>
            <h5 className="mobileDropDataHeader">
              <span
                onClick={() => {
                  setShowDashBoardDetails(false);
                }}
              >
                <FaArrowLeft />
              </span>
              <span>Dashboard</span>
            </h5>
            <h5 className="mobileDropDataHeader">Sales Reports</h5>
            {dropDataToShow.map((item, index) => (
              <div key={index} className="mobileDropDataWrapper">
                {item.menuItems.map((data, index) => (
                  <div key={index} className="mobileDropDataContainer">
                    <p>{data}</p>
                    <p className="dataValue">$ 87987978</p>
                    <span>
                      {dataIndex} - {index + 1}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <div className="dashBoardDropDownContainerMobile">
            {dropdownData.map((item, index) => (
              <p
                key={index}
                className="mobileDropDownItem"
                style={{
                  boxShadow: "3px 4px 4px grey, inset 0px 0px 20px #d1823a",
                }}
                onClick={() => handleShowDropDown(item.buttonTitle, index)}
              >
                {item.buttonTitle}
                <span style={{ backgroundColor: "#d1823a", color: "white" }}>
                  {index + 1}
                </span>
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DealerDashBoard;
