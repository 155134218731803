import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Image from "../../imgData";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import getIPAddress from "../../../GlobalAuthSection/SmallAuthComponent/IpFile";
import styles from "../../../GlobalAuthSection/styles/NewSocialCard.module.css";
import UserQueryAuthModel from "../../../GlobalAuthSection/AuthComponent/UserQueryAuthModel";

function GoogleConsoleLogin({
  setShowQueryModel,
  showQueryModel,
  setSaveUser,
  saveUserData,
  setUserType,
  setUserData,
  handleSocialLogin,
  handleSelectUserType,
}) {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  console.log("User => ", user);
  console.log("Profile => ", profile);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && isBtnClicked) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Google User Profile 222=> ", res.data);
          setProfile(res.data);
          const dataToSend = {
            email: res.data.email,
            firstName: res.data.given_name,
            lastName: res.data.family_name,
          };
          const dataTosend = {
            email: dataToSend.email,
            firstName: dataToSend.given_name,
            lastName: dataToSend.family_name,
          };
          handleSocialCheckAccount(dataTosend.email, dataTosend);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);
  const handleSocialCheckAccount = async (userToBeChecked, dataTosend) => {
    try {
      const formData = new FormData();
      formData.append("email", userToBeChecked);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/check_account`,
        formData
      );
      console.log("Response after checking account => ", response);
      if (response.status === 200) {
        if (!response.data) {
          setSaveUser({
            email: dataTosend.email,
            firstName: dataTosend.firstName,
            lastName: dataTosend.lastName,
          });
          setShowQueryModel(true);
        } else {
          await handleSocialLogin(null, dataTosend);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          login();
          setIsBtnClicked(true);
        }}
        className={styles.socialChildBTN2}
      >
        <span>
          <img src={Image.google_logo} alt="google logo" height={25} />
        </span>{" "}
        <span>Login By Google</span>
      </button>
      <UserQueryAuthModel
        showQueryModel={showQueryModel} //
        setShowQueryModel={setShowQueryModel} //
        setUserType={setUserType} //
        setUserData={setUserData} //
        inputEmail={saveUserData.email} //
        handleCreateResult={handleSocialLogin}
      />
    </>
  );
}
export default GoogleConsoleLogin;
