import React, { useEffect, useRef, useState } from "react";
import styles from "./CRMsheet.module.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import tableBodyArray from "./CRMDummyData";
import { MdEmail } from "react-icons/md";
import { ImCross } from "react-icons/im";
import CrmEmailPopOut from "./CrmEmailPopOut.jsx";
import { useSelector } from "react-redux";
import axios from "axios";
import { Document, Page } from 'react-pdf';
import { Button, Container, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice.js";
import { useDispatch } from "react-redux";
const CRMsheet = () => {
  const { dealerId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showEmailPopOut, setShowEmailPopOut] = useState(false);
  const [showModalOtp, setShowModalOtp] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [otp, setOtp] = useState(['', '', '', '', '', '']); // Initial state for the 6 OTP digits
  const [rowData, setRowData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  //  get data of crn table---------------------------------------------------
  const fetchDealerData = async () => {
    const fd = new FormData();
    fd.append('user_id', dealerId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/loan/get`,
        fd
      );
      setTableData(response.data.data);
    } catch (error) {
      console.log('Error fetching dealer data:', error);
      setTableData([]);
    }
  };

  // Using useEffect to fetch data when the page loads
  useEffect(() => {
    if (dealerId) {
      fetchDealerData(); // Fetch dealer data when 'id' is available
    }
  }, [dealerId]);
  // -----------------------------------------------------------------------------------
  // fortable data-------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
// const itemsPerPage = 20; // Change this value according to your preference

// Ensure tableData is defined before performing operations
const totalItems = tableData ? tableData.length : 0; 
const itemsPerPage = totalItems > 20 ? totalItems : 20; 
const totalPages = Math.ceil(totalItems / itemsPerPage);

// Calculate current page items
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = tableData ? tableData.slice(indexOfFirstItem, indexOfLastItem) : [];

  // Add empty rows if necessary
const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
  <tr key={`empty-${index}`} className={`${styles.tableRowData}`}>
    {[...Array(7)].map((_, i) => (
      <td key={i}>&nbsp;</td>
    ))}
  </tr>
));
 //  for pdf download and view--------------------------------------------------------------
 const [showPdf, setShowPdf] = useState(false);
 const [showPdfData,setShowPdfData] = useState();
 const [showPdfStatus,setShowPdfStatus] = useState();
 const [idFinance,setIdFinance] = useState();
 const [userIdFinance,setUserIdFinance] = useState();
//  const [pdfData, setPdfData] = useState(null);
 const [pdfData, setPdfData] = useState(null);
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState(null);

//  const loadPdf = async () => {
//   const idpopshow = showPdfStatus;
//   setLoading(true);
//   setError(null);
//   const fd = new FormData();
//   fd.append('id', idFinance);
//   fd.append('userId', userIdFinance);
//   fd.append('countryCode', "+91");
//   fd.append('otp', otp.join(''));
  
//   try {
//     const response = await axios.post(`${process.env.REACT_APP_API_URL}/loan/getpdf`, fd);
//      console.log("response3545",response.status);
//     if (response.status == 200) {
//       console.log("56554665",response.data);
//       const data = await response.data.arrayBuffer();
//       console.log("43654675",data);
//       const base64Pdf = arrayBufferToBase64(data); 
//       console.log("response43654", base64Pdf); 

//       setPdfData(base64Pdf);
//       setLoading(false);

//       if (idpopshow === 1) {
//         setShowPdf(true);
//       } else if (idpopshow === 2) {
//         setShowPdf(false);
//       }
//     } else if (response.status == 204) {
//       toast.error('Please Enter Correct OTP.');
//     }
//   } catch (err) {
//     setLoading(false);
//     setError('Error loading PDF: ' + err.message);
//     console.error("Error loading PDF:", err);
//   }
// };
const loadPdf = async () => {
  const idpopshow = showPdfStatus;
  setLoading(true);
  setError(null);
  const fd = new FormData();
  fd.append('id', idFinance);
  fd.append('userId', userIdFinance);
  fd.append('countryCode', countryCode);
  fd.append('otp', otp.join(''));

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/loan/getpdf`, fd, {
      responseType: 'arraybuffer'  // Set response type to arraybuffer
    });

    console.log("response3545", response.status);

    if (response.status === 200) {
      console.log("56554665", response.data);  // Logs the ArrayBuffer or binary data
      const data = response.data;  // Data is already an ArrayBuffer if responseType is set to 'arraybuffer'
      console.log("43654675", data);  // Logs the ArrayBuffer

      const base64Pdf = arrayBufferToBase64(data);
      console.log("Base64 PDF:", base64Pdf);

      setPdfData(base64Pdf);
      setLoading(false);

      if (idpopshow === 1) {
        setShowPdf(true);
        setShowModalOtp(false);
      } else if (idpopshow === 2) {
        setShowPdf(false);
        setShowModalOtp(false);
      }

    } else if (response.status === 204) {
      toast.error('Please Enter Correct OTP.');
    }
  } catch (err) {
    setLoading(false);
    setError('Error loading PDF: ' + err.message);
    console.error("Error loading PDF:", err);
  }
};


// Helper function to convert ArrayBuffer to Base64
const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const length = bytes.byteLength;
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
//  for download the pdf-----------------------------
const downloadPdf = () => {
  loadPdf();
  
  // Introduce a delay after calling loadPdf() (e.g., 2 seconds)
  setTimeout(() => {
    if (pdfData) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${pdfData}`; // Set the data URL for the PDF
      link.download = 'loan_document.pdf'; // Set a default filename for the download
      link.click(); // Trigger the download by clicking the link programmatically
    } else {
      alert('No PDF data available to download.');
    }
  }, 2000); // 2000 milliseconds = 2 seconds delay
};
//  show otp-------------------------------------------------------

const handleModalCloseOtp = () => {
  setShowModalOtp(false);
};
//  for pop otp value---------------------------------------
const getOtpLoan = async (id, userId, showIdPdf) => {
  console.log("idAndUser",countryCode);
  setIdFinance(id);
  setUserIdFinance(userId);
  setShowPdfStatus(showIdPdf);
  //  validation-------------------------------------------------------
  // if (showCosignerTab === true) {
  //   // Check for required fields including loan co-signer and assets
  //   if (
  //       !formData.loanCoSigner.firstName || 
  //       !formData.loanCoSigner.mobilNumber || 
  //       !formData.loanCoSigner.email  ||
  //       !formData.listingId
  //     ) {
  //     toast.error("First name, Mobile Number, Email, Co-signer details, and Assets are required fields.");
  //     return;
  //   }
  // } else if(showCosignerTab === false){
  //   // Check for required fields for loan signer only
  //   if (!formData.loanSigner.firstName || 
  //       !formData.loanSigner.mobilNumber || 
  //       !formData.loanSigner.email  ||
  //       !formData.listingId
  //     ) {
  //     toast.error("First name, Mobile Number,Email and Asset are required fields.");
  //     return;
  //   }
  // }
  // -------------------------------------------------------------------
  const fd = new FormData();
  fd.append('userId', userId);
  fd.append('countryCode', countryCode);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/loan/sendotp`,
      fd
    );
    console.log(response.data.data);
    setShowModalOtp(true);
    toast.success('OTP Send To Your Mobile Successfully.');
  } catch (error) {
    console.log('Error fetching dealer data:', error);
    // toast.error('Please Add Mobile Number.');
  }
};
  // Handle OTP input change
  const handleChangeOtp = (e, index) => {
    const value = e.target.value;

    if (/^\d{0,1}$/.test(value)) { // Only allow numbers
      const newOtp = [...otp];
      newOtp[index] = value; // Update the corresponding OTP digit
      setOtp(newOtp);

      // Focus on the next input field if the current input is filled
      if (value.length === 1 && index < 5) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  // Handle focus on backspace
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };
  // Handle paste event (for copy-pasting OTP)
  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste behavior

    // Get the pasted content
    const paste = e.clipboardData.getData('text');

    // Ensure the pasted content is exactly 6 digits
    if (/^\d{6}$/.test(paste)) {
      const newOtp = paste.split(''); // Convert the pasted OTP into an array of digits
      setOtp(newOtp); // Update the state with the pasted OTP digits
    }
  };
  // -------------------------------------
   //  for country dialing code-------------------------
   useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("Error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, []);
  console.log("countryCode",countryCode);
  useEffect(() => {
      dispatch(
        setRetailData({
          crmLoanLenght:tableData?.length ?? 0,
        })
      );
    }, [tableData]);
// --------------------------------------------------------------------------------
  const renderTableRow = (item, index) => {
    console.log("item in the current list => ", item);
    // Calculate days difference for the inspection date

    return (
      <>
        <tr
          key={index}
          className={`${styles.tableRowData}`}
        >
          {item ? (
            <>
              <td>{index + 1}</td>
              <td
                className="border-0 mb-0"
              >
                {item?.createdAt}{" "}
              </td>
              <td   
              >
                {item?.name}
              </td>
              <td>
                {item?.mobileNumber}
              </td>
              <td className={styles.email}>
                    <span>{item?.email}</span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowEmailPopOut(true);
                        setRowData(item);
                      }}
                    >
                      <MdEmail />
                    </span>
                  </td>
              <td
                className="ac-col fw-bold"
              >
                {item?.assetName}{" "}
              </td>
              <td className="text-center">
                <Button
                  type="button"
                  className="border-0 me-1 py-2"
                  style={{ color: "white", width: "77px",backgroundColor:"#934805",fontSize:"0.8rem" }}
                  // onClick={() => downloadPdf(item?.id)}
                  onClick={()=>getOtpLoan(item?.id, item?.userId,2)}
                >
                  Download
                </Button>
                <Button
                  type="button"
                  className="border-0  py-2"
                  style={{ color: "white", width: "70px",backgroundColor:"#934805",fontSize:"0.8rem"}}
                  // onClick={() => loadPdf(item?.id, 1)}
                  onClick={()=>getOtpLoan(item?.id, item?.userId,1)}
                >
                  View
                </Button>
              </td>
            </>
          ) : (
            Array.from({ length: 7 }).map((_, i) => <td key={i}>&nbsp;</td>)
          )}
        </tr>
      </>
    );
  };
  //  table functionend-------------------------------------------------------------
 console.log("showPdfData",showPdfData)
  // -------------------------------------------------------------------------------
console.log("tableData",tableData);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container} style={{maxHeight:"70vh",overflowY:"auto",scrollbarWidth:"none"}}>
          <Table
        striped
        bordered
        hover
        ref={tableRef}
        className="shadow mb-0"
        id="table"
      >
        <thead>
          <tr>
            <th>No#</th>
            <th className="ac-col">Date</th>
            <th>Name</th>
            <th>Phone</th>
            <th>
              Email
            </th>
            <th>
              Asset Info
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map(renderTableRow)}
          {emptyRows}
        </tbody>
        <tfoot>
          <tr className={`${styles.tableFooterRow}`}>
            <td colSpan={2}></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
        </div>
      </div>
      <CrmEmailPopOut
        setShowEmailPopOut={setShowEmailPopOut}
        showEmailPopOut={showEmailPopOut}
        rowData={rowData}
      />
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title">
           Loan App Forms
          </Modal.Title>
          <Modal.Title style={{ display: "flex", width: "45%", gap: "5%" }}>
            {/* <Button className="w-100 pe-0 py-2 btn-orange border-0">
              Print
            </Button>
            <Button className="w-100 pe-0 py-2 btn-orange border-0">
              E-Mail
            </Button> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
          {pdfData ? (
        <iframe
          title="Loan Application PDF"
          src={`data:application/pdf;base64,${pdfData}`}
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
      ) : (
        !loading && <p>No PDF loaded yet.</p>
      )}


            {/* {quoteForm &&
              quoteForm.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })} */}
          </Container>
        </Modal.Body>
      </Modal>
      {/* ------------------------------------------modal verify otp-------------------------------------------- */}
    <Modal show={showModalOtp} onHide={handleModalCloseOtp} size="xl">
          <Modal.Header closeButton style={{ paddingLeft: "35%" }}>
            <Modal.Title>
              <div style={{ color: "red" }}>Enter one-Time Password(OTP)</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className={styles.tradein_popup}>
      <h5 style={{textAlign:"center"}}>We have sent a one-time password to your mobile or email.<br/><br/>
        Please enter it below to proceed.
      </h5>
      <div className={styles.otpInputContainer} onPaste={handlePaste}>
        {otp.map((digit, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            value={digit}
            maxLength="1"
            onChange={(e) => handleChangeOtp(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}  // Handle backspace event
            className={styles.otpInputBox}
          />
        ))}
      </div>
      <div className={styles.otpResult_resent}><u>Resend OTP.</u></div>
      <div className={styles.otpResult}>
      <Button type="button" onClick={showPdfStatus === 2 ? downloadPdf : loadPdf}>
       {loading ? <div className="spinner"></div> : "Verify"}
     </Button>
      </div>
      <h6 className={styles.otpResult_resent}>Don't received the OTP ? <u>Click here to resend</u></h6>
    </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
    </>
  );
};

export default CRMsheet;
