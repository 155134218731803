const sliderData = [
  {
    title: "Digital Contracting",
    description:
      "Ensuring simplicity, swiftly sealing deals, and offering eSign, eContract, Online Payment & Delivery",
  },
  {
    title: "Get Offer from Verified Dealer",
    description:
      "Dive into Cutting-Edge Technology, Where Excellence is Redefined and Delivered with Every Interaction.",
  },
  {
    title: "Verified Dealers",
    description:
      "Connecting you to the USA's most trusted car professionals and genuine private sellers. Safe, straightforward, and reliable every time.",
  },
  {
    title: "Vehicle Ownership cost",
    description:
      "Make data-driven decisions with comprehensive analytics and insights",
  },
  {
    title: "Vehicle Market Value",
    description: "Round-the-clock assistance to ensure seamless operations",
  },
  {
    title: "Vehicle History Report",
    description: "Round-the-clock assistance to ensure seamless operations",
  },
];
export default sliderData;
