import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import { filteredImage } from "../FilteredImage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Image from "../../imgData";

const SliderContainer = styled.div`
  width: 100%;
  // height: 50vh;
  height: ${(props) => props.containerHeight};
  position: relative;
  overflow: hidden;
  padding: 5rem;
`;

const Logo = styled.div`
  width: ${(props) =>
    props.isMobile ? "50px" : "30px"}; // Larger logos for mobile
  height: ${(props) => (props.isMobile ? "50px" : "30px")};
  position: absolute;
  transition: transform 0.3s ease;
  cursor: pointer;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;

  &:hover {
    transform: scale(1.5);
    z-index: 10;
  }
`;

const LogoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  img {
    width: 100%;
    height: 90%;
    pointer-events: none;
  }

  span:nth-child(1) {
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }
  span:nth-child(2) {
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }
`;

const DealerLoginMarquee = () => {
  const navigate = useNavigate();
  const [logos, setLogos] = useState([]);
  console.log("all the logos => ", logos);
  const [carCounts, setCarCounts] = useState({});
  const [carCountOriginalMake, setCarCountOriginalMake] = useState({});
  console.log("All the acr counts =>  ", carCounts);
  console.log("carCountOriginalMake =>  ", carCountOriginalMake);

  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);
  const dragItem = useRef(null);
  const dragStart = useRef(null);
  const [mouseDownTime, setMouseDownTime] = useState(0);
  const [mouseUpTime, setMouseUpTime] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const userData = useSelector((state) => state.user);
  const { id } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("user_id", userData.id);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/listing/get_makes_count`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        console.log("original count => ", data);
        const counts = data.reduce((acc, item) => {
          const makeKey = item.make.toLowerCase().replace(/-/g, "");
          // if (filteredImage.hasOwnProperty(makeKey)) {
          acc[makeKey] = item.count;
          // } else {
          // acc[makeKey] = item.count;
          // }
          return acc;
        }, {});
        setCarCounts(counts);
        const countOriginalMake = data.reduce((acc, item) => {
          const makeKey = item.make;
          acc[makeKey] = item.count;

          return acc;
        }, {});
        console.log("count is => ", counts);

        setCarCountOriginalMake(countOriginalMake);
      } catch (error) {
        console.log("Fail", error);
      }
    };

    fetchData();
  }, []);

  const [containerHeight, setContainerHeight] = useState("50vh");
  useLayoutEffect(() => {
    const containerWidth = sliderRef.current.clientWidth;
    const containerHeight = sliderRef.current.clientHeight;
    const logos = Object.keys(carCounts);
    const totalLogos = logos.length;

    const logoWidth = isMobile ? 50 : 80;
    const logoHeight = logoWidth;

    const newLogos = logos.map((logo, index) => ({
      id: index,
      url: filteredImage[logo],
      x: Math.random() * (containerWidth - logoWidth),
      y: Math.random() * (containerHeight - logoHeight),
      count: carCounts[logo] || 0,
      make: logo,
    }));

    const adjustPositions = (logos) => {
      const logoWidth = isMobile ? 50 : 80;
      const logoHeight = logoWidth;
      const bufferZone = 20; // Add a buffer zone around each logo
      const containerWidth = sliderRef.current && sliderRef.current.clientWidth;
      const containerHeight =
        sliderRef.current && sliderRef.current.clientHeight;
      const minDistance = (logoWidth + bufferZone) * 1.5; // Increase the minimum distance

      for (let i = 0; i < logos.length; i++) {
        let overlap = true;
        let attempts = 0;
        while (overlap && attempts < 100) {
          const x = Math.random() * (containerWidth - (logoWidth + bufferZone));
          const y =
            Math.random() * (containerHeight - (logoHeight + bufferZone));
          overlap = false;

          for (let j = 0; j < logos.length; j++) {
            if (i !== j) {
              const distance = Math.sqrt(
                Math.pow(x - logos[j].x, 2) + Math.pow(y - logos[j].y, 2)
              );
              if (distance < minDistance) {
                overlap = true;
                break;
              }
            }
          }

          if (!overlap) {
            logos[i].x = x;
            logos[i].y = y;
          }

          attempts++;
        }
      }

      return logos;
    };

    setLogos(adjustPositions(newLogos));
    window.addEventListener("resize", () => {
      setLogos(adjustPositions(newLogos));
    });

    return () =>
      window.removeEventListener("resize", () => {
        setLogos(adjustPositions(newLogos));
      });
  }, [carCounts]);

  const onMouseDown = (e, id) => {
    if (e.button !== 0) return;
    setMouseDownTime(Date.now());
    dragItem.current = id;
    dragStart.current = { x: e.clientX, y: e.clientY };
    setIsDragging(true);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    if (dragItem.current !== null) {
      const dx = e.clientX - dragStart.current.x;
      const dy = e.clientY - dragStart.current.y;

      setLogos((prevLogos) =>
        prevLogos.map((logo) => {
          if (logo.id === dragItem.current) {
            let newX = logo.x + dx;
            let newY = logo.y + dy;

            newX = Math.max(
              0,
              Math.min(newX, sliderRef.current.clientWidth - 80)
            );
            newY = Math.max(
              0,
              Math.min(newY, sliderRef.current.clientHeight - 80)
            );

            return { ...logo, x: newX, y: newY };
          }
          return logo;
        })
      );

      dragStart.current = { x: e.clientX, y: e.clientY };
    }
  };

  const onMouseUp = () => {
    setMouseUpTime(Date.now());
    dragItem.current = null;
    setIsDragging(false);
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  };

  const handleClick = useCallback(
    (make) => {
      let adjustedMake;

      switch (make) {
        case "MERCEDESBENZ":
          adjustedMake = "MERCEDES-BENZ";
          break;
        case "LANDROVER":
          adjustedMake = "LAND ROVER";
          break;
        default:
          adjustedMake = make;
      }

      navigate("/dealer/inventory/active", {
        state: { dataFromMarquee: adjustedMake },
      });
    },
    [navigate]
  );

  const handleLogoClick = useCallback(
    (logo, e) => {
      console.log("logog => ");
      const clickDuration = mouseUpTime - mouseDownTime;
      if (clickDuration < 200 && !isDragging) {
        handleClick(logo.make.toUpperCase());
      }
    },
    [handleClick, isDragging, mouseDownTime, mouseUpTime]
  );

  return (
    <SliderContainer ref={sliderRef} containerHeight={containerHeight}>
      {logos.map((logo) => (
        <Logo
          key={logo.id}
          x={logo.x}
          y={logo.y}
          onMouseDown={(e) => onMouseDown(e, logo.id)}
          className="mmLogoContainer"
        >
          <LogoContent
            onClick={(e) => {
              handleLogoClick(logo, e);
            }}
          >
            <img
              src={logo.url || Image.questionImage}
              alt={`Car logo ${logo.make}`}
            />
            <span style={{ marginTop: "0.4rem" }}>{logo.count}</span>
            {!logo.url && (
              <span style={{ marginTop: "-1rem", fontWeight: "bold" }}>
                {logo?.make?.charAt(0).toUpperCase() + logo?.make?.slice(1)}
              </span>
            )}
          </LogoContent>
        </Logo>
      ))}
    </SliderContainer>
  );
};

export default DealerLoginMarquee;
