import React, { useEffect, useRef, useState } from "react";
import styles from "../BalancePaymentHistory.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import PaymentHistoryCard from "./PaymentHistoryCard";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ReceiptPDFRender from "./ReceiptPDFRender";

const BalancePaymentHistory = () => {
  const location = useLocation();
  const carData = location.state;

  const [apiData, setApiData] = useState([]);
  const handleGetDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_paymenthistory/${carData.userId}/${carData.id}`
      );
      // console.log("Response from the data => ", response);
      if (response.status === 200) {
        console.log("Response from the data => ", response);
        setApiData(response.data);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  useEffect(() => {
    handleGetDetails();
  }, []);
  // handle delete
  const [arrayCheckIDToSend, setArrayCheckIDToSend] = useState([]);
  const handleDeleteRow = async () => {
    try {
      // delete_paymenthistory/{userId}/{id}
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/delete_paymenthistory/${carData.userId}`,
        arrayCheckIDToSend
      );
      if (response.status === 200) {
        await handleGetDetails();
        setArrayCheckIDToSend([]);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  // delete functionality  starts from here
  const handleCheckDelete = (id) => {
    if (arrayCheckIDToSend.includes(id)) {
      setArrayCheckIDToSend(arrayCheckIDToSend.filter((item) => item !== id));
    } else {
      setArrayCheckIDToSend([...arrayCheckIDToSend, id]);
    }
  };
  //Recript pdf
  const [pdfArray, setPdfArray] = useState([]);
  const [showPdfArray, setShowPdfArray] = useState(false);
  const handlePrintfetch = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/view_receipt/${carData.userId}`,
        arrayCheckIDToSend
      );
      if (response.status === 200) {
        console.log("Response from PDF API => ", response);
        setPdfArray(response.data);
        setShowPdfArray(true);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  const clickRef = useRef(null);
  const handleSendEnvelopeBtn = () => {
    clickRef.current.triggerClick();
    handlePrintfetch()
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Link to="/dealer/inventory/balance">
            <IoMdArrowRoundBack />
          </Link>
          <span>Payment History</span>
        </div>
        {/* Content Starts here */}
        <div className={styles.contentContainer}>
          <div className={styles.paymentReceipient}>Emran Rababah United</div>
          <div className={styles.paymentTransactionContainer}>
            <p className={styles.paymentContainer}>
              <span>Total Payment :</span>
              <span>
                ${" "}
                {(apiData &&
                  apiData?.reduce((acc, item) => acc + item.payment, 0)) ||
                  0}
              </span>
            </p>
            <p className={styles.TransactionContainer}>
              <span>Total Transaction :</span>
              <span>{(apiData && apiData?.length) || 0}</span>
            </p>
          </div>
          <div className={styles.deletePrintContainer}>
            <button onClick={handlePrintfetch}>Print</button>
            <span onClick={handleDeleteRow}>
              <MdDelete />
            </span>
          </div>
          <div className={styles.cardParentWrapper}>
            {apiData &&
              apiData?.map((item, index) => (
                <span
                  key={index}
                  onClick={
                    index === 0 ? null : () => handleCheckDelete(item.id)
                  }
                >
                  <PaymentHistoryCard
                    index={index}
                    data={item}
                    arrayCheckIDToSend={arrayCheckIDToSend}
                    handleGetDetails={handleGetDetails}
                    ref={clickRef}
                    setArrayCheckIDToSend={setArrayCheckIDToSend}
                  />
                </span>
              ))}
          </div>

          {/* <PaymentHistoryCard /> */}
          <div className={styles.historyTrackerContainer}>
            <label htmlFor="paymentTracker">
              <input type="checkbox" name="" id="paymentTracker" />
              Payment Tracker
            </label>
            <button>Save</button>
          </div>
        </div>
      </div>
      <Modal
        show={showPdfArray}
        onHide={() => setShowPdfArray(false)}
        size="xl"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReceiptPDFRender pdfArray={pdfArray && pdfArray} />
        </Modal.Body>
      </Modal>
      <div className={styles.printSendEnvelopeContainer}>
        <button
          disabled={arrayCheckIDToSend.length === 1 ? false : true}
          style={{
            backgroundColor: arrayCheckIDToSend.length !== 1 && "lightgrey",
            color: arrayCheckIDToSend.length !== 1 && "black",
            pointer: arrayCheckIDToSend.length !== 1 && "not-allowed",
          }}
          onClick={handleSendEnvelopeBtn}
        >
          Send Envelope
        </button>
        <button
          onClick={handlePrintfetch}
          disabled={arrayCheckIDToSend.length === 0 && true}
          style={{
            backgroundColor: arrayCheckIDToSend.length === 0 && "lightgrey",
            color: arrayCheckIDToSend.length === 0 && "black",
            pointer: arrayCheckIDToSend.length === 0 && "not-allowed",
          }}
        >
          Print
        </button>
      </div>
    </div>
  );
};

export default BalancePaymentHistory;
