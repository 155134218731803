import React, { useEffect, useState } from "react";

import "./MobileDataCard.css";
import { useNavigate } from "react-router-dom";
import Image from "../imgData";
import { useLocation } from "react-router-dom";
import { GrLocation } from "react-icons/gr";

import { useSelector } from "react-redux";
import axios from "axios";
import { haversineDistanceMiles } from "./calculateDistance";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveUser } from "../../Redux/slice/userSlice";
import { IoMdCall } from "react-icons/io";
import { RiGasStationLine } from "react-icons/ri";
import { ImMeter } from "react-icons/im";
import { Modal } from "react-bootstrap";
import { MdInfoOutline, MdOutlineWrongLocation } from "react-icons/md";
const imgUrl = process.env.REACT_APP_IMG_URL;

const MobileDataCard = ({
  handleShowDialogueBox,
  product,
  ProductId,
  locationAccess,
}) => {
  console.log("product is gold=> ", product);
  const navigate = useNavigate();
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [isFav, setIsFav] = useState(false);
  // console.log(product);
  const location = useLocation();
  const { id } = useSelector((state) => state.user);

  const handleLikeTheProduct = async () => {
    if (id === 0) {
      handleShowDialogueBox(true);
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/add_favourite/${id}/${ProductId}`
        );
        console.log(res);
        setIsFav(true);
      } catch (error) {
        console.log("Server side error while liking the product");
      }
    }
  };
  // for single product details---------------

  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = product && product.multimedia;
  const noImage = Image.image_not_available;
  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${product.userId}/${image.trim()}`;
        }
      }
    }
    return null;
  };
  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;
  // console.log("product7888*****",product);

  //getting the location and distance between coordinates
  const [totalDistance, setTotalDistance] = useState(0);
  useEffect(() => {
    if (navigator.geolocation) {
      const { latitude, longitude } = locationAccess && locationAccess;

      const coords1 = {
        latitude: latitude && latitude,
        longitude: longitude && longitude,
      };
      const coords2 = {
        latitude: product?.coordinates && product?.coordinates?.latitude,
        longitude: product?.coordinates && product?.coordinates?.longitude,
      };

      if (latitude && longitude && product?.coordinates) {
        const distance = haversineDistanceMiles(coords1, coords2);
        setTotalDistance(distance.toFixed(1));
        // console.log(
        //   "distance => ",
        //   distance.toFixed(2),
        //   " latitude => ",
        //   latitude,
        //   " longitude => ",
        //   longitude,
        //   "coordinates => ",
        //   product?.coordinates
        // );
      } else {
        setTotalDistance(0);
      }
    }
  }, [locationAccess]);

  //location sending handle
  const [showlocationSend, setShowlocationSend] = useState(false);

  useEffect(() => {
    if (showlocationSend) {
      // toast.error("The dealer has not provided the location of this inventory");
      if (product && !product?.coordinates) {
        toast.error(
          "The dealer has not provided the location of this inventory"
        );
      }
    }
  }, [showlocationSend]);
  const [receiveData, setReceiveData] = useState({
    email: "",
    phoneNumber: "",
  });
  const handleReceiveDataChange = (e) => {
    const { name, value } = e.target;
    setReceiveData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [error, setError] = useState(null);
  const handleReceiveDLink = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success callback
          const { latitude, longitude } = position.coords;
          handleSendCoordinates(latitude, longitude);
          setError(null);
        },
        (err) => {
          // Error callback
          setError(err.message);
          toast.error(
            "Geolocation access was denied by the user. To get a directional link, please enable geolocation access in your browser settings."
          );
        }
      );
      // ;
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };
  const [showLoginModal, setShowLoginModal] = useState(false);

  const dispatch = useDispatch();

  const handleCustomerLogin = () => {
    setShowLoginModal(false);

    dispatch(saveUser({ showLoginDialogue: true }));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //Handle the data to send and receive of coordinates
  const handleSendCoordinates = (latitude, longitude) => {
    //If we have the coordinates of the user
    if (latitude && longitude) {
      //If the user is not logged in
      if (!id) {
        setShowLoginModal(true);
      } else {
        //If the user is looged in

        const directinalURL = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${product?.coordinates?.latitude},${product?.coordinates?.longitude}
`;
        console.log("directinalURL => ", directinalURL);
        toast.success("Directional link sent to your Email and Phone number");
      }
    } else {
      toast(
        "Please enable location access in your browser to get directional link"
      );
    }
  };

  //modal for availability
  const [showAvailibility, setShowAvailibility] = useState(false);
  const estimatedPrice = product.estimated_cost;
  const handleClick = () => {
    navigate(
      `/cardetail/${product.id}_${product.userId}_${product.modelYear}_${product.modelMake}`,
      {
        state: { product },
      }
    );
  };
//check availabilty for the inventory
  const caSendData = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    userId: product && product?.userId,
    listingId: product && product?.id,
    testDrive: false,
    vehicleHistoryReport: false,
    financeOption: false,
    tradeIn: false,
    other: false,
    acceptance: false,
  };
  const [cAFormData, setCaFormData] = useState(caSendData);
  const handleCAFormInput = (e) => {
    const { name, value, type, checked } = e.target;
    setCaFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // availability/save
  console.log("caFormData => ", cAFormData);
  const handleSendCheckAvail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/availability/save`,
        cAFormData
      );
      console.log("Response from api", response);
      if (response.status === 200) {
        toast.success(response.data);
        setShowAvailibility(false);
        setCaFormData(caSendData);
      }
    } catch (error) {
      console.log("Server side error => ", error);
    }
  };
  
  return (
    <div className="pLMDCardWrapper">
      <div className="pLMDCardContainer">
        <div className="pLMDImageContainer">
          <img src={imageUrl} alt="car_image" />
          <p className="pLMDAbsoluteItem">
            <span>
              <IoMdCall />
              {"  "} Call Now
            </span>
            <span
              onClick={() => {
                // if (!id) {
                //   setShowLoginModal(true);
                //   return;
                // } else {
                setShowAvailibility(true);
                // }
              }}
            >
              Check Availibility
            </span>
          </p>
        </div>
        <div className="pLMDCardContent">
          <p className="pLMDCarName">
            <span onClick={handleClick}>
              {product?.modelYear} {product?.modelMake} {product?.makeModel}
            </span>
            <span>{`${product?.driveType} . ${
              product?.engine.split(" ")[1]
            } . ${product?.engine.split(" ")[0]} . ${
              product?.engine.split(" ")[3]
            }`}</span>
          </p>
          <p className="pLMDCarFeatures">
            <span>
              <span>
                <ImMeter />
              </span>
              <span>{product.mileage_in}</span>
            </span>
            <span>
              <span>
                <RiGasStationLine />
              </span>
              <span>{product.fuelType}</span>
            </span>
            <span>
              <span
                onClick={
                  product?.coordinates ? () => setShowlocationSend(true) : null
                }
              >
                {product?.coordinates ? (
                  <GrLocation />
                ) : (
                  <MdOutlineWrongLocation />
                )}
              </span>
              <span>{product.location || " NA"} </span>
            </span>
          </p>
          <p className="pLMDCarFinance">
            <span>
              ${new Intl.NumberFormat().format(product.listing_price)}{" "}
            </span>
            <span>
              Est $
              {new Intl.NumberFormat().format(
                estimatedPrice ? Math.ceil(estimatedPrice) : 0
              )}{" "}
              mo
            </span>
          </p>
        </div>
      </div>
      <Modal
        size="xl"
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ textAlign: "center", marginTop: "1rem" }}>
            Log in to unlock this feature! You need to be logged in to check
            availability of this inventory.
          </h4>
          <h5 style={{ margin: "2rem 0", textAlign: "center" }}>
            {" "}
            Click below to log in and continue.
          </h5>
          <p style={{ display: "flex", justifyContent: "center", gap: "3rem" }}>
            <button
              style={{
                border: "none",
                backgroundColor: "#d1823a",
                color: "white",
                fontSize: "larger",
                fontWeight: "600",
                padding: "0.4rem 0.7rem",
                borderRadius: "10px",
              }}
              onClick={() => handleCustomerLogin("Customer")}
            >
              Login
            </button>
          </p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showlocationSend && product && product?.coordinates}
        onHide={() => setShowlocationSend(false)}
        size="lg"
      >
        <Modal.Body>
          <div>
            <p
              style={{
                fontWeight: "600",
                fontSize: "large",
                textAlign: "center",
                margin: "1rem 0",
              }}
            >
              To receive the directional link, please provide your email address
              and phone number.
            </p>
            <p
              style={{ display: "flex", justifyContent: "center", gap: "10%" }}
            >
              <input
                type="text"
                placeholder="Phone Number"
                style={{ width: "30%", padding: "0.3rem" }}
                value={receiveData.phoneNumber}
                name="phoneNumber"
                onChange={handleReceiveDataChange}
              />
              <input
                type="text"
                placeholder="Email"
                style={{ width: "30%", padding: "0.3rem" }}
                value={receiveData.email}
                name="email"
                onChange={handleReceiveDataChange}
              />
            </p>
            <p style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <button
                onClick={handleReceiveDLink}
                style={{
                  border: "none",
                  backgroundColor:
                    receiveData.email.length > 6 &&
                    receiveData.phoneNumber.length > 9 &&
                    receiveData.email.includes("@") &&
                    receiveData.email.includes(".")
                      ? "rgb(209, 130, 58)"
                      : "lightgrey",
                  color: "white",
                  padding: "0.4rem 0.9rem",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
                disabled={
                  receiveData.email.length > 0 &&
                  receiveData.phoneNumber.length > 0
                    ? false
                    : true
                }
              >
                Receive
              </button>
            </p>
            <p
              style={{
                fontSize: "medium",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "smaller", marginLeft: "0.3rem" }}>
                <MdInfoOutline />
              </span>{" "}
              <span>Make sure to enable location access in your browser.</span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAvailibility}
        onHide={() => setShowAvailibility(false)}
        size="lg"
      >
        <Modal.Body>
          <div className="checkAvailailityContainer">
            <p className="checkAvailailityHeader">
              <span>Check Availability</span>
            </p>
            <p className="cAContactHeader">
              <span>
                <span style={{ borderBottom: "2px solid black" }}>
                  Call Now
                </span>{" "}
              </span>
              <span>(469) 469-4699</span>
            </p>
            <p className="cAorBorderContainer">
              <span>Or</span>
            </p>
            <p className="cATextContainer">
              <span style={{ marginRight: "1.3rem" }}>My name is </span>
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                value={cAFormData.firstName}
                onChange={handleCAFormInput}
              />
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={cAFormData.lastName}
                onChange={handleCAFormInput}
              />
              interested
            </p>
            <p className="cAYearMakeModelText">
              in {product.year_Make_Model} {product.trim}
            </p>
            <p className="cAYearMakeModelText">contact me at my</p>
            <p className="cATextContainer">
              <input
                type="text"
                placeholder="(XXX) XXX-XXXX"
                name="mobileNumber"
                value={cAFormData.mobileNumber}
                onChange={handleCAFormInput}
              />
              <span style={{ marginRight: "1.3rem" }}>&</span>
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={cAFormData.email}
                onChange={handleCAFormInput}
              />
            </p>
            <div className="cABoxWrapper">
              <div className="cAInputBoxWrapper">
                <p className="cAInputBoxHeader">
                  <span>Need more info about</span>
                </p>
                <div className="cAInputBoxContainer">
                  <p className="cAInputBoxColumn">
                    <label htmlFor="testDrive">
                      <input
                        type="checkbox"
                        id="testDrive"
                        name="testDrive"
                        value={cAFormData.testDrive}
                        checked={cAFormData.testDrive}
                        onChange={handleCAFormInput}
                      />
                      Test Drive
                    </label>
                    <label htmlFor="vehicleHistoryReport">
                      <input
                        type="checkbox"
                        id="vehicleHistoryReport"
                        name="vehicleHistoryReport"
                        value={cAFormData.vehicleHistoryReport}
                        onChange={handleCAFormInput}
                      />
                      Vehicle history report
                    </label>
                    <label htmlFor="financeOption">
                      <input
                        type="checkbox"
                        id="financeOption"
                        name="financeOption"
                        value={cAFormData.financeOption}
                        checked={cAFormData.financeOption}
                        onChange={handleCAFormInput}
                      />
                      Finance Options
                    </label>
                    <label htmlFor="tradeIn">
                      <input
                        type="checkbox"
                        id="tradeIn"
                        name="tradeIn"
                        value={cAFormData.tradeIn}
                        checked={cAFormData.tradeIn}
                        onChange={handleCAFormInput}
                      />
                      I have a trade in
                    </label>
                    <label htmlFor="other">
                      <input
                        type="checkbox"
                        id="other"
                        style={{ marginRight: "1rem" }}
                        name="other"
                        value={cAFormData.other}
                        checked={cAFormData.other}
                        onChange={handleCAFormInput}
                      />
                      Other
                    </label>
                  </p>
                  <p className="cASendBtnContainer">
                    {" "}
                    <button onClick={handleSendCheckAvail}>Send</button>
                  </p>
                </div>
                <label htmlFor="acceptance" className="acceptance">
                  <input
                    type="checkbox"
                    id="acceptance"
                    style={{ marginRight: "1rem" }}
                    name="acceptance"
                    value={cAFormData.acceptance}
                    checked={cAFormData.acceptance}
                    onChange={handleCAFormInput}
                  />
                  By clicking send , I accept to contacted by
                  dealersautocenter.com in the future.
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MobileDataCard;
