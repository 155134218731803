import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Image from "../../../imgData";
import styles from "../DealerSoldInventory/sold.module.css";

import { saveView } from "../../../../Redux/slice/activeSlice";
import SellDraftCard from "./SellDraftCard.jsx";
import { BiReset } from "react-icons/bi";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const DealerDraft = () => {
  const { id } = useSelector((state) => state.user);
  const view = useSelector((state) => state.active.view);
//  for new filter-----------------------------------
    const { dealerId } = useSelector((state) => state.user);
    const [counterb, setCounterb] = useState(0);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMake, setSelectedMake] = useState(null);
    const [selectedModal, setSelectedModal] = useState(null);
    const [selectedBodyType, setSelectedBodyType] = useState(null);
    const [dropdownData,setDropdownData] = useState();
  // -----------------------------------------------------------------
  const dispatch = useDispatch();

  const location = useLocation();
  const data = location.state || {};

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  // const [apiRespnse]

  //fetch all the drafts
  const activeTab = useSelector((state) => state.sell.activeTab);
  const [counter, setCounter] = useState(0);
  const [initialFilters, setInitialFilters] = useState([]);
  

  const handleIncrement = () => {
    setOpenDropdown(null);
    setCounter(prevCounter => prevCounter + 1);
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });
    setSelectedSort(null);
  };
  // --------------------------------------
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  // For Pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewToggle = (newView) => {
    dispatch(saveView(newView));
  };

  // Calculate the number of days difference
  const calculateDaysDifference = (inspectionDate) => {
    if (!inspectionDate) return 0;
    const currentDate = new Date();
    const inspectionDateObj = new Date(inspectionDate);
    const timeDifference = inspectionDateObj - currentDate;
    return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
  };

  // For selection of cards
  const [selectedCards, setselectedCards] = useState([]);

  const handleSelected = (index) => {
    setselectedCards((prevselectedCards) => {
      if (prevselectedCards.includes(index)) {
        // If the card is already selected, remove it
        return prevselectedCards.filter((i) => i !== index);
      } else {
        // If the card is not selected, add it
        return [...prevselectedCards, index];
      }
    });
  };

  const handleRowClick = (index) => {
    // If the clicked row is already selected, deselect it
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      // If the clicked row is not selected, select it
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    }
  };

  // Function to handle export button click
  const tableRef = useRef(null);

  const handleSort = (order) => {
    // const sorted = [...tableData].sort((a, b) => {
    //   const dateA = new Date(a.created_at);
    //   const dateB = new Date(b.created_at);
    //   return order === "oldest" ? dateA - dateB : dateB - dateA;
    // });
    console.log("__________order", order);
    if (order) {
      const sorted = [...tableData].sort((a, b) => a.id - b.id);
      console.log("__________sorted newest", sorted);
      setTableData(sorted);
    } else {
      const sorted = [...tableData].sort((a, b) => b.id - a.id);
      console.log("__________sorted oldest", sorted);
      setTableData(sorted);
    }
  };

  const handleWebsite = (event) => {
    event.stopPropagation();
    alert("Added");
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    alert("Edited");
  };

  const handleDelete = async (event, cardId, index) => {
    event.stopPropagation();

    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this inventory item?"
    );

    if (!userConfirmed) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    try {
      const listing_id = "listing";
      const response = await fetch(
        `${apiUrl}/listing/delete/${listing_id}/${id}/${cardId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the card");
      }

      // Update the state to reflect the deletion
      const newTableData = tableData.filter((_, i) => i !== index);
      setTableData(newTableData);
      // setSortedData(newTableData);
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleUpdate = (id, updatedData) => {
    console.log("updatedData>>>>>>>>>>>>>>>>>>>>>>>", updatedData);
    setTableData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, addvertising: updatedData } : item
      )
    );
    // setTableData((prevData) =>
    //   prevData.map((item) => (item.id === id ? { ...item, addvertising: updatedData } : item))
    // );
  };
  // for filter sold data---------------------------------------------------------------*********************-----------------------------


  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });
  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus, type = "") => {
    setOpenDropdown(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sell/get_draft/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedStatus, // Use computed property name here
        }));
      }

      setTableData(data);
      setSelectedSort(selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //handleSelect(inputValue); // Call the function with the input value
      setInputValue(inputValue); // Clear the input field if desired
      setCounterb((prev)=>prev+1);
    }
  };
  const handleButtonClick = () => {
   // handleSelect(inputValue); // Call the function with the input value
    setInputValue(inputValue); // Clear the input field if desired
    setCounterb((prev)=>prev+1);
  };
  // -------------------------------------------------------------------------------------------
  console.log("initialFilters32455", initialFilters);
  // const filterSoldData = initialFilters.addListing2;
  // console.log("filterSoldData32455******",filterSoldData);
  // const filterSoldData = 

  // console.log(newData);
  // ------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------New Filter Sold Inventory--------------------------
  
const transformOptions = (data, label) => {
  // Check if the data is an array before proceeding
  if (!Array.isArray(data)) {
      console.error(`${label} data is not an array`, data);
      return [];
  }

  return [
      { value: "", label: label },  // Default empty value for all dropdowns
      ...data.map(item => ({
          value: item.toString(),
          label: item.toString()
      }))
  ];
};

// Generating options
const optionsYear = transformOptions(dropdownData?.year, "Year");
const optionsMake = transformOptions(dropdownData?.make, "Make");
const optionsModal = transformOptions(dropdownData?.model, "Model");
const optionsBodyType = transformOptions(dropdownData?.bodyStyle, "Body Type");

console.log("dropdownData",dropdownData);
const handleSelectedYear = (event) => {
  const selectedOption = optionsYear.find(
    (option) => option.value === event.target.value
  );
  console.log("Selected year => ", selectedOption);
  setSelectedYear(selectedOption.value); // Set the selected option object
  setCounterb((prev)=>prev+1);
  //handleFilterDDClick("year",selectedOption.value);
};
const handleSelectedMake = (event) => {
  const selectedOption = optionsMake.find(
    (option) => option.value === event.target.value
  );
  console.log("Selected year => ", selectedOption);
  setSelectedMake(selectedOption.value); // Set the selected option object
  setCounterb((prev)=>prev+1);
  // handleFilterDDClick("make",selectedOption.value);
};
const handleSelectedModal = (event) => {
  const selectedOption = optionsModal.find(
    (option) => option.value === event.target.value
  );
  console.log("Selected year => ", selectedOption);
  setSelectedModal(selectedOption.value); // Set the selected option object
  setCounterb((prev)=>prev+1);
  // handleFilterDDClick("models",selectedOption.value);
};
const handleSelectedBodyType = (event) => {
  const selectedOption = optionsBodyType.find(
    (option) => option.value === event.target.value
  );
  console.log("Selected year => ", selectedOption);
  setSelectedBodyType(selectedOption.value); // Set the selected option object
  setCounterb((prev)=>prev+1);
  // handleFilterDDClick("Body Type",selectedOption.value);
};
console.log("selectedYear",selectedYear);
const objFor = {
  year:selectedYear ? parseInt(selectedYear) :null,
  make:selectedMake && selectedMake !== "" ? selectedMake : null,
  model:selectedModal && selectedModal !=="" ? selectedModal :null,
  bodyStyle:selectedBodyType && selectedBodyType !=="" ? selectedBodyType :null,
  userId:dealerId
};
console.log("selectedYear",selectedYear);
// --------------------------------------------------------------
// -------------------------------------------------------------------------------
useEffect(() => {
  const postData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/sell/draft_filters`, objFor, {
      });
      console.log('Response:', response.data);
      setDropdownData(response.data);
      // setCounterc((prev)=>prev+1);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  // Trigger the POST request
  postData();
}, [counterb]);
//  for get the data---------------------------------
const objForSold = {
  searchTerm:inputValue && inputValue !=="" ? inputValue :null,
  make:selectedMake && selectedMake !== "" ? selectedMake : null,
  model:selectedModal && selectedModal !=="" ? selectedModal :null,
  year:selectedYear ? parseInt(selectedYear) :null,
  bodyStyle:selectedBodyType && selectedBodyType !=="" ? selectedBodyType :null,
  userId:dealerId
};
useEffect(() => {
  const postData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/sell/get_draft`, objForSold, {
      });
      console.log('Response:5656565', response.data);
      const data = await response.data;
     if (response.status === 200 && data.length > 0) {
      console.log("all the data in sold inventory", data, response);
      const sortedData = data.sort((a, b) => a.id - b.id);
      console.log("all the sortedData in sold inventory", sortedData);
      setTableData(data);
      setInitialFilters(data.map((item) => item.addListing2));
      console.log("______________tableData", sortedData);
      setInputValue("");
    }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  // Trigger the POST request
  postData();
}, [counterb]);
//  for filter reset---------------------------------------------------------------------
const handleResetFilter = () => {
  setInputValue("");
  setSelectedMake("");
  setSelectedModal("");
  setSelectedYear("");
  setSelectedBodyType("");
  setCounterb((prev)=>prev+1);
}
// -----------------------------------------------------------
  return (
    <Container fluid className="px-4">
      <Row className="px-3">
        <Col sm={2} className="px-0">
          {/* Icon in the first column */}
          {/* <div className={styles.toggleView}>
            <img
              src={view === "c" ? Image.cardViewS : Image.cardView}
              alt="card-view"
              width="50%"
              height="100%"
              className={styles.cardView}
              onClick={() => handleViewToggle("c")}
            />
            <img
              src={view === "l" ? Image.listViewS : Image.listView}
              alt="list-view"
              width="50%"
              height="100%"
              className={styles.listView}
              onClick={() => handleViewToggle("l")}
            />
          </div> */}
        </Col>

        <Col className="d-flex justify-content-end align-items-center px-0">
          {/* First dropdown */}
          {/* <div>
            <button
              className={`btn dropdown-toggle me-2 ${styles.drpBtn} ${styles.pageCount}`}
              type="button"
              onClick={() => toggleDropdown("page-count")}
            >
              10
            </button>
            {openDropdown === "page-count" && (
              <ul
                className={`${styles.pageCountList} p-0`}
                ref={dropdownRef}
                style={{ display: openDropdown ? "block" : "none" }}
              >
                <li>
                  <Button className="dropdown-item">1</Button>
                </li>
                <li>
                  <Button className="dropdown-item">2</Button>
                </li>
              </ul>
            )}
          </div> */}

          {/* Second dropdown */}
          {/* {view === "c" && (
            <div>
              <button
                className={`btn dropdown-toggle ${styles.drpBtn} ${styles.sortBy}`}
                type="button"
                onClick={() => toggleDropdown("sort-by")}
              >
                Sort by
              </button>
              {openDropdown === "sort-by" && (
                <ul
                  className={`${styles.sortByList} p-0`}
                  ref={dropdownRef}
                  aria-labelledby="dropdownMenuButton2"
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li
                    className={`list-style-none dropdown-item ${styles.sortByItem}`}
                    onClick={() => handleSort("oldest")}
                  >
                    Oldest
                  </li>
                  <li
                    className={`list-style-none dropdown-item ${styles.sortByItem}`}
                    onClick={() => handleSort("")}
                  >
                    Newest
                  </li>
                </ul>
              )}
            </div>
          )} */}
        </Col>
      </Row>

      <Row>
        <Col sm={1} className="h-100">
          {/* <div className="select-btn"></div> */}
        </Col>
        <Col sm={11} className="px-0" style={{ position: "relative" }}>
          <Container fluid className="px-3">
            <Row>
              {/* input field start */}
              <Col
                sm={11}
                className="input-group mt-3 mb-4"
                id="act-search"
              >
                <Button
                  className="btn border-0 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ backgroundColor: "#e8a262", borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px" }}
                >
                  All
                </Button>
                <ul className="dropdown-menu">
                  <li onClick={() => handleSelect("Price10k")}>
                    <Link className="dropdown-item" to="#">
                      Price &lt; 10k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage50k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 50k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage100k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 100k
                    </Link>
                  </li>
                </ul>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Inventory"
                  style={{boxShadow: "inset 0 0 9px 2px rgb(209, 130, 58)"}}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                  onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                />
                <Button
                  className="btn border-0"
                  type="button"
                  id="button-addon1"
                  style={{ backgroundColor: "#e8a262" }}
                  onClick={handleButtonClick}
                >
                  <i className="fas fa-search"></i>
                </Button>
                {/*--------------------------------- nerw filter---------------------------------- */}
                <div className="new-filter-active">
                        <select
                          value={selectedYear}
                          onChange={handleSelectedYear}
                          style={{ width: "auto" }}
                          placeholder="Year"
                        >
                          {optionsYear.length === 0 ? (
                            <option disabled>No year found</option>
                          ) : (
                            optionsYear.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                      <div className="new-filter-active">
                      <select
                          value={selectedMake}
                          onChange={handleSelectedMake}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsMake.length === 0 ? (
                            <option disabled>No make found</option>
                          ) : (
                            optionsMake.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="new-filter-active">
                      <select
                          value={selectedModal}
                          onChange={handleSelectedModal}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsModal.length === 0 ? (
                            <option disabled>No modal found</option>
                          ) : (
                            optionsModal.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="new-filter-active">
                      <select
                          value={selectedBodyType}
                          onChange={handleSelectedBodyType}
                          style={{ width: "auto" }}
                          placeholder="Make"
                        >
                          {optionsBodyType.length === 0 ? (
                            <option disabled>No body-type found</option>
                          ) : (
                            optionsBodyType.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
{/* ------------------------------------------------------------------------------- */}

                <Button
                        className={`btn border-0 dropdown-toggle btn-eq-wd ${
                          selectedSort === "Desc" || selectedSort === "Asc"
                            ? "bg-secondary"
                            : ""
                        }`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="sortBy" // Added ID for accessibility
                        style={{
                          backgroundColor: "#e8a262",
                          margin: "0 0.3rem",
                        }}
                      >
                        Sort BY
                      </Button>
                <ul className="dropdown-menu" aria-labelledby="sortBy">
                        <li onClick={() => handleSelect("Desc")}>
                          <Link
                            className={`dropdown-item ${
                              selectedSort === "Desc" ? "selected-item" : ""
                            }`}
                            to="#"
                          >
                            Newest
                          </Link>
                        </li>
                        <li onClick={() => handleSelect("Asc")}>
                          <Link
                            className={`dropdown-item ${
                              selectedSort === "Asc" ? "selected-item" : ""
                            }`}
                            to="#"
                          >
                            Oldest
                          </Link>
                        </li>
                      </ul>

                <Button
                  className="btn border-0 btn-eq-wd"
                  type="button"
                  id="button-addon1"
                  style={{ backgroundColor: "#e8a262",borderTopRightRadius:"20px",borderBottomRightRadius:"20px" }}
                  onClick={handleResetFilter}
                >
                  <BiReset className="icons-r-flt" />
                </Button>
              </Col>
              {/* input field end */}
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className={`mx-0 rounded bg-body border-2 ${styles.bgTr}`} style={{ width: "100%" }}>
        {tableData &&
          tableData?.map((data, index) => {
            return (
              <SellDraftCard
                data={{
                  ...data,
                  daysDifference: calculateDaysDifference(data.inspection),
                }}
                index={index}
                key={index}
                selectedCards={selectedCards}
                handleSelected={handleSelected}
                handleWebsite={handleWebsite}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                onUpdate={handleUpdate}
                setCounterb={setCounterb}
                // fetchData={fetchData}
              />
            );
          })}
      </Row>
    </Container>
  );
};

export default DealerDraft;
