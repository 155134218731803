import React, { useEffect, useRef, useState } from "react";
import styles from "./Offers.module.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import tableBodyArray from "./CRMDummyData";
import { MdEmail } from "react-icons/md";
import { ImCross } from "react-icons/im";
import CrmEmailPopOut from "./CrmEmailPopOut.jsx";
import { useSelector } from "react-redux";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { Button, Container, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice.js";
import { useDispatch } from "react-redux";
import { FaCircleArrowRight } from "react-icons/fa6";
const Offers = () => {
  const dispatch = useDispatch();
  const { dealerId } = useSelector((state) => state.user);
  const [showEmailPopOut, setShowEmailPopOut] = useState(false);
  const [showModalOtp, setShowModalOtp] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Initial state for the 6 OTP digits
  const [rowData, setRowData] = useState(null);
  // const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
   // contact dummy table--------------------------------
   const tableData = [
    {
      date:"02/12/2024",
      assets:"214 Fusion SE",
      name: "John Doe",
      phoneNumber:"6547546757",
      email:"john@gmail.com",
      offeredAmount:"1232434",
      countered:"85657658"
    },
    {
      date:"04/08/2025",
      assets:"215 Fusion SE",
      name: "Rahman Khan",
      phoneNumber:"6547546757",
      email:"rahman@gmail.com",
      offeredAmount:"9086677",
      countered:"678979"
    },
    {
      date:"04/08/2025",
      assets:"215 Fusion SE",
      name: "Rahman Khan",
      phoneNumber:"6547546757",
      email:"rahman@gmail.com",
      offeredAmount:"9086677",
      countered:"678979"
    },

  ]
  //  get data of crn table---------------------------------------------------
//   const fetchDealerData = async () => {
//     // const fd = new FormData();
//     // fd.append('user_id', dealerId);

//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/fastQuote/getQuote/${dealerId}`
//         // fd
//       );
//     //   setTableData(response.data);
//       setTableData([]);
//     } catch (error) {
//       console.log("Error fetching dealer data:", error);
//       setTableData([]);
//     }
//   };

//   // Using useEffect to fetch data when the page loads
//   useEffect(() => {
//     if (dealerId) {
//       fetchDealerData(); // Fetch dealer data when 'id' is available
//     }
//   }, [dealerId]);
//   console.log("tableData34543", tableData);
  // -----------------------------------------------------------------------------------
  // fortable data-------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 20; // Change this value according to your preference

  // Ensure tableData is defined before performing operations
  const totalItems = tableData ? tableData.length : 0;
  const itemsPerPage = totalItems > 20 ? totalItems : 20;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData
    ? tableData.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`} className={`${styles.tableRowData}`}>
      {[...Array(9)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));
  //  for pdf download and view--------------------------------------------------------------
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfData, setShowPdfData] = useState();
  const [showPdfStatus, setShowPdfStatus] = useState();
  const [idFinance, setIdFinance] = useState();
  const [userIdFinance, setUserIdFinance] = useState();
  //  const [pdfData, setPdfData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPdf = async (QuotationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fastQuote/view/${QuotationId}`
      );
      console.log("response3545", response.status);

      if (response.status === 200) {
        setPdfData([response.data]);
        toast.success("Successfully show the PDF");
        setShowPdf(true);
      } else if (response.status === 204) {
        toast.error("Please Enter Correct OTP.");
      }
    } catch (err) {
      toast.error(err);
    }
  };

  // -------------------------------------
  //  for country dialing code-------------------------
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("Error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, [countryCode]);
  console.log("countryCode", countryCode);
//   useEffect(() => {
//         dispatch(
//           setRetailData({
//             crmQuoteLenght:tableData?.length ?? 0,
//           })
//         );
//       }, [tableData]);
  // --------------------------------------------------------------------------------
  //  for view pop-up---------------------------------------------------------------
  const [showViewOffers,setShowViewOffers] = useState(false);
  const viewPopupOffers = () => {
    setShowViewOffers(true);
  }
  // -------------------------------------------------------------------------------
  const renderTableRow = (item, index) => {
    console.log("item in the current list => ", item);
    // Calculate days difference for the inspection date

    return (
      <>
        <tr
          key={index}
          className={`${styles.tableRowData}`}
        >
          {item ? (
            <>
              <td>{index + 1}</td>
              <td className="border-0 mb-0">{item?.date} </td>
              <td>
                {item?.assets}
              </td>
              <td>{item?.name}</td>
              <td>{item?.phoneNumber}</td>
              <td className={styles.email}>
                <span>{item?.email}</span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowEmailPopOut(true);
                    setRowData(item);
                  }}
                >
                  <MdEmail />
                </span>
              </td>
              <td>{item?.offeredAmount}</td>
              <td>{item?.countered}</td>
              {/* <td>{item?.total}</td> */}
              <td className="text-center">
                {/* <Button
                  type="button"
                  className="publish-btn border-0 me-1 py-2"
                  style={{ color: "white", width: "70px" }}
                  // onClick={() => downloadPdf(item?.id)}
                  // onClick={()=>getOtpLoan(item?.id, item?.userId,2)}
                >
                  Download
                </Button> */}
                <Button
                  type="button"
                  className="border-0  py-2"
                  style={{ color: "white", width: "70px",backgroundColor:"#934805",fontSize:"0.8rem" }}
                  // onClick={() => loadPdf(item?.id, 1)}
                  // onClick={()=>getOtpLoan(item?.id, item?.userId,1)}
                  // onClick={() => loadPdf(item?.id)}
                  onClick={() => viewPopupOffers()}
                >
                  View
                </Button>
              </td>
            </>
          ) : (
            Array.from({ length: 10 }).map((_, i) => <td key={i}>&nbsp;</td>)
          )}
        </tr>
      </>
    );
  };
  //  table functionend-------------------------------------------------------------
  console.log("showPdfData", showPdfData);
  // -------------------------------------------------------------------------------
  console.log("tableData", tableData);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container} style={{maxHeight:"70vh",overflowY:"auto",scrollbarWidth:"none"}}>
          <Table
            striped
            bordered
            hover
            ref={tableRef}
            className="shadow mb-0"
            id="table"
          >
            <thead>
              <tr>
                <th>No#</th>
                <th className="ac-col">Date</th>
                <th>Asset Info</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Offered Amount</th>
                <th>Countered</th>
                {/* <th>Total</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(renderTableRow)}
              {emptyRows}
            </tbody>
            <tfoot>
              <tr className={`${styles.tableFooterRow}`}>
                <td colSpan={2}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {/* <td></td> */}
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
      <CrmEmailPopOut
        setShowEmailPopOut={setShowEmailPopOut}
        showEmailPopOut={showEmailPopOut}
        rowData={rowData}
      />
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title" style={{ color:"#d1823a"}}>
            Quotation Pdf :
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {pdfData &&
              pdfData.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
      <Modal show={showViewOffers} onHide={() => setShowViewOffers(false)} size="xl">
              <Modal.Header closeButton style={{ gap: "10%" }}>
                <Modal.Title id="example-custom-modal-styling-title" style={{ color:"#d1823a"}}>
                 Offers
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ height: "100%" }}>
              <div className={styles.contact_pop_container}>
                <div className={styles.left_container_contact}>
                  <div className={styles.border_box}>
                  <span className={styles.asset_text}>Asset Info</span>
                    <h3>2014 Mercededs Aprinter Passanger Extended 170 " WB</h3>
                    <div>
                      <span style={{color:"#d1823a"}}>Name</span>
                      <h3>Mike Report Gra</h3>
                    </div>
                    <div>
                      <span style={{color:"#d1823a"}}>Phone Number</span>
                      <h3>(XXX)XXX-XXXX</h3>
                    </div>
                    <div>
                      <span style={{color:"#d1823a"}}>Email</span>
                      <h3>example@gmail.com</h3>
                    </div>
                  </div>
                  {/* bottom section for message */}
                  <div className={styles.MessangerPartWrapperb}>
                    <div className={styles.MessangerPartContainerb}>
                      <div className={styles.MessangerBody}>
                        {/* <div className={styles.LeftBody}>
                          <div className={styles.message}>
                            <span>Ghada</span>
                            <span>I am interested to drive this car.</span>
                          </div>
                        </div> */}
                        <div className={styles.RightBody}>
                          <div className={styles.message}>
                            <span>United</span>
                            <span>
                             {" "} Test Message or Dummy Message
                            </span>
                          </div>
                        </div>
                        <div className={styles.RightBody}></div>
                        <div className={styles.LeftBody}></div>
                        <div className={styles.RightBody}></div>
                        <div className={styles.LeftBody}></div>
                        <div className={styles.RightBody}></div>
                        <div className={styles.LeftBody}></div>
                        <div className={styles.RightBody}></div>
                        <div className={styles.LeftBody}></div>
                        <div className={styles.RightBody}></div>
                        <div className={styles.LeftBody}></div>
                        <div className={styles.RightBody}></div>
                      </div>
                      <div className={styles.MessangerInput}>
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Write Your Text Here"
                        />
                        <span>
                          <button className={styles.message_btn}>Message</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                  {/*----------------------------------------- Messanger part container------------------------------- */}
                   <div className={styles.MessangerPartWrapper}>
                    <div className={styles.MessangerPartContainer}>
                      <div className={styles.MessangerHeader}>
                        <span>Listing Price</span>
                        <span>$35,262</span>
                      </div>
                      <div className={styles.MessangerBody}>
                        <div className={styles.LeftBody}>
                          <div className={styles.message}>
                            <span>Ghada</span>
                            <span> Dummy Message From User Side</span>
                          </div>
                        </div>
                        <div className={styles.RightBody}>
                          <div className={styles.message}>
                            <span>United</span>
                            <span>
                              Dummy Message From My Side
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.MessangerInput}>
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Write Your Offer Here"
                        />
                        <span>
                        <button className={styles.message_btn}>Send Offer</button>
                        </span>
                      </div>
                    </div>
                  </div>
              </div>
              </Modal.Body>
              </Modal>
    </>
  );
};

export default Offers;
