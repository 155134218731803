import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import { connectStorageEmulator } from "firebase/storage";
import AmountInputWithCalculator from "../../DealerInventory/DealerAddInventory/AmountInputWithCalculator";

const Box_F = ({ retailFromRedux, totalFeesAndOthers, onSumChange }) => {
  const counter = useSelector((state) => state.active.counter);
  const totalFees = useSelector(
    (state) => state.taxesAndFees.dealerRetail.totalFees
  );
  // const totalFeesn = totalFees.toFixed(2)
  const userListingPrice = useSelector(
    (state) => state.taxesAndFees.dealerRetail.userListingPrice
  );
  const mileageIn = useSelector(
    (state) => state.taxesAndFees.dealerRetail.mileageIn
  );
  const totalFeesAndOthersprice = useSelector(
    (state) => state.taxesAndFees.dealerRetail.totalFeesAndOthersprice
  );
  const isCarSelected = useSelector((state) => state.sell.selectedVehicle);
  const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  // -------------for reverce calculation--------------------------------------------------------------
  const dOut = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.dOut) || 0
  );
  const totalFeesAndothersLablesSum = useSelector(
    (state) =>
      parseFloat(state.taxesAndFees.dealerRetail.totalFeesAndothers) || 0
  );
  const saleTaxCal = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.salesTaxes) || 0
  );
  const mInventoryTaxCal = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.mInventoryTax) || 0
  );

  // const netPriceAfterFees = paymentPriceFees - totalFeesAndothersLablesSum;
  // const totalTaxAndInventoryTax = saleTaxCal + mInventoryTaxCal * 100;

  const updatedSalePrice =
    ((dOut - totalFeesAndothersLablesSum) /
      (saleTaxCal + mInventoryTaxCal * 100 + 100)) *
    100;
  // ----------------------------------------------------------------------------------------------------
  console.log("totalFeesAndothersLablesSum", updatedSalePrice);
  // Provide a fallback in case `isCarSelected` is undefined
  const listingPrice = isCarSelected?.listing_price || 0;
  const mileage_in = isCarSelected?.mileage_in || 0;
  //const totalFeesTax = totalFees.toFixed(0);
  // useEffect(()=>{
  //   if(purchase_price > 0){
  //     userListingPrice = userListingPrice - purchase_price;
  //     dispatch(setRetailData({
  //       userListingPrice:userListingPrice,
  //     }));
  //   }
  // },[purchase_price]);

  // Update state whenever `totalFees` changes
  console.log("PaymentAmount", dOut);
  const [inputValues, setInputValues] = useState([
    // { header: "Mileage On Sale:", InputValue: `${mileageIn}`, name: "mileageIn" },
    {
      header: "Sale Price:",
      InputValue: `${userListingPrice}`,
      name: "userListingPrice",
    },
    // { header: "Total Taxes", InputValue: totalFees, name: "totalFees" },
    // { header: "Total Fees And Others", InputValue: `${totalFeesAndOthersprice}`, name: "totalFeesAndOthersprice" },
  ]);
  const formatNumberWithCommas = (num) => {
    return Number(num).toLocaleString();
  };

  const removeCommas = (str) => {
    return str.replace(/,/g, "");
  };

  useEffect(() => {
    if (listingPrice) {
      dispatch(
        setRetailData({
          userListingPrice: listingPrice,
        })
      );
    }
  }, [listingPrice]);

  useEffect(() => {
    if (mileage_in) {
      dispatch(
        setRetailData({
          mileageIn: mileage_in,
        })
      );
    }
  }, [mileage_in]);

  useEffect(() => {
    if (totalFeesAndOthers) {
      dispatch(
        setRetailData({
          totalFeesAndOthersprice: totalFeesAndOthers,
        })
      );
    }
  }, [totalFeesAndOthers]);
  // --------------------------------------
  // const handleUpdateSalePrice = () => {
  //   dispatch(
  //     setRetailData({
  //       userListingPrice: Math.ceil(updatedSalePrice),
  //     })
  //   );
  // }
  useEffect(() => {
    if (updatedSalePrice) {
      dispatch(
        setRetailData({
          userListingPrice: updatedSalePrice.toFixed(2),
        })
      );
    }
  }, [counter]);
  // --------------------------------------

  const dispatch = useDispatch();
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "mileageIn" ? { ...item, InputValue: mileageIn } : item
      )
    );
  }, [mileageIn]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndOthersprice"
          ? { ...item, InputValue: totalFeesAndOthersprice }
          : item
      )
    );
  }, [totalFeesAndOthersprice]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFees" ? { ...item, InputValue: totalFees } : item
      )
    );
  }, [totalFees]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndOthersprice"
          ? { ...item, InputValue: totalFeesAndOthersprice }
          : item
      )
    );
  }, [totalFeesAndOthersprice]);
  // sum all three------------------
  useEffect(() => {
    const sum = inputValues.reduce(
      (acc, item) => acc + parseFloat(item.InputValue),
      0
    );
    onSumChange(sum);
  }, [inputValues, onSumChange]);
  // --------------------------
  const handleInputChange = (name, value) => {
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  };
  const [showCalculator, setShowCalculator] = useState(false);

  return (
    <div className={Styles.wrapper}>
      <div
        className={Styles.container}
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          // borderBottom: "2px solid rgb(220 150 86 / 41%);",
          paddingBottom: "10px",
        }}
      >
        {inputValues.map((item, index) => (
          <InputFieldComp
            key={index}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
            setShowCalculator={setShowCalculator}
            showCalculator={showCalculator}
          />
        ))}
        {showCalculator && (
          <div className={Styles.calContainer}>
            <AmountInputWithCalculator
              setShowModalCalculator={setShowCalculator}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Box_F;
