import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { RiFileSearchFill } from "react-icons/ri";
import { AiOutlineMail, AiFillPrinter } from "react-icons/ai";
import Image from "../../imgData";
import "./forms.css";
import { FaCircleInfo, FaHandshake } from "react-icons/fa6";
import { FaRegEye, FaSearch } from "react-icons/fa";
import { FaFileContract } from "react-icons/fa";
import usaStates from "../DealerInventory/DealerAddInventory/usaStates.json";
import { useSelector } from "react-redux";
import PDFViewer from "./components/PDFViewer";
import { IoDocumentsSharp } from "react-icons/io5";
import SellFromData from "./components/SellFromData";
import { useDispatch } from "react-redux";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ImCross } from "react-icons/im";
import {
  resetBuyerSellData,
  resetCoBuyerSellData,
  setActiveTab,
  setSelectedVehicle,
} from "../../../Redux/slice/sellSlice";
import { setRetailData } from "../../../Redux/slice/taxes&FeesSlice";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import PDFMerger from "pdf-merger-js";
import { PDFDocument } from "pdf-lib";
import CombinedPDFViewer from "./components/PDF";
import PDF from "./components/PDF";
import PDFViewer2 from "./components/PDF2";
import { saveUser } from "../../../Redux/slice/userSlice";

// import PDF from "./components/PDF";

function Forms() {
  const apiUrl = process.env.REACT_APP_API_URL;
  // State to manage Select All
  //for all forms
  const [selectAll, setSelectAll] = useState(false);
  //for forms
  const [selectAllForms, setSelectAllForms] = useState(false);
  const [selectedState, setSelectedState] = useState("TX - Texas");
  const reduxForms = useSelector(
    (state) => state.taxesAndFees.dealerRetail.sellForm
  );

  // console.log(reduxForms);

  // console.log("Checkboxes ", checkboxes);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
    setSearch("");
  }, [isOpen]);

  // console.log(checkboxes);

  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };

  // Handle Select All change
  //for all forms
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckboxes({
      billOfSale: newSelectAll,
      billOfSaleCustomer: newSelectAll,
      buyerGuide: newSelectAll,
      odometerDisclosure: newSelectAll,
      titleApp: newSelectAll,
      AgreementInsurance: newSelectAll,
      creditBureauDisclosure: newSelectAll,
      rebuildTitleDisclosure: newSelectAll,
      powerOfAttorney: newSelectAll,
      retailContractInstallment: newSelectAll,
      salesTaxExemptionCertificate: newSelectAll,
      statementOfFacts: newSelectAll,
      airbagDisclosure: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
      accuracyInformationAcknowledment: newSelectAll,
      rightToReposses: newSelectAll,
    });
  };
  //for forms
  const handleSelectForms = () => {
    const newSelectAll = !selectAllForms;
    setSelectAllForms(newSelectAll);
    setFormsCheckboxes({
      billOfSale: newSelectAll,
      billOfSaleCustomer: newSelectAll,
      buyerGuide: newSelectAll,
      odometerDisclosure: newSelectAll,
      titleApp: newSelectAll,
      AgreementInsurance: newSelectAll,
      creditBureauDisclosure: newSelectAll,
      rebuildTitleDisclosure: newSelectAll,
      powerOfAttorney: newSelectAll,
      retailContractInstallment: newSelectAll,
      salesTaxExemptionCertificate: newSelectAll,
      statementOfFacts: newSelectAll,
      airbagDisclosure: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
      accuracyInformationAcknowledment: newSelectAll,
      rightToReposses: newSelectAll,
    });
  };

  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const [showPdfs, setShowPdfs] = useState(false);
  const [showForms, setShowFormsPdfs] = useState(false);
  // console.log("Show forms => ", showForms);
  const handlePreviewSelect = () => {
    setShowPdfs(true);
    // dispatch(saveUser({ isSideBarOpen: false }));
  };
  const handleShowFormsSelect = () => {
    setShowFormsPdfs(true);
  };

  //finalise data
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.user);
  const listingId = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.listingId)
  );
  const retailFromRedux = useSelector(
    (state) => state.taxesAndFees.dealerRetail
  );
  // console.log("line 12", retailFromRedux);
  const buyer = useSelector((state) => state.sell.buyer);
  const sellCoBuyer = useSelector((state) => state.sell.sellCoBuyer);
  //console.log("listingId",userId);
  const [formData, setFormData] = useState({
    userId: dealerId,
    listingId: listingId,
    id: 0,
    firstName: "",
    saleType: "",
    mileageIn: "",
    lastName: "",
    middleName: "",
    city: "",
    zipCode: "",
    state: "",
    idType: "",
    idNumber: "",
    businessName: "",
    isBuyerEntity: false,
    idState: "",
    address: "",
    address2: "",
    county: "",
    phoneNumber: "",
    email: "",
    created_at: "",
    // sellPriceFeesLabels: {
    //   documentFees: retailFromRedux.documentFees,
    //   driveOutTags: retailFromRedux.driveOutTags,
    //   titleAppFees: retailFromRedux.titleAppFees,
    //   terpTitleFees: retailFromRedux.terpTitleFees,
    //   buyerTag: retailFromRedux.buyerTag,
    //   windShieldTracker: retailFromRedux.windShieldTracker,
    //   regFeeDpsl: retailFromRedux.regFeeDpsl, //Special case
    //   cntyRoadBridge: retailFromRedux.cntyRoadBridge,
    //   childSafetyFee: retailFromRedux.childSafetyFee,
    //   inspectionFee: retailFromRedux.inspectionFee,
    //   stateInspectionFee: retailFromRedux.stateInspectionFee,
    //   procHandlingFee: retailFromRedux.procHandlingFee,
    //   cardFeesl: retailFromRedux.cardFeesl,
    //   serviceContractl: retailFromRedux.serviceContractl,
    //   gapInsurancel: retailFromRedux.gapInsurancel,
    //   mailDeliveryFeesl: retailFromRedux.mailDeliveryFeesl,
    //   transportationFeesl: retailFromRedux.transportationFeesl,
    // },
    lienHolder: {
      leanHolderId: 0,
      lienHolderName: "",
      leinHolderID: "",
      leinHolderAddress: "",
      leinHolderAddress2: "",
    },
    serviceContract: {
      serviceContractId: 0,
      serviceContractName: "",
      serviceContractLicense: "",
      serviceContractAddress: "",
      serviceProviderActivationStatus: "",
      serviceProviderType: "",
      providerPhoneNumber: "",
      providerCounty: "",
      providerListingExpiry: "",
      serviceProviderMonths: "",
      serviceContractMiles: "",
    },
    gapInsurance: {
      gapInsuranceId: 0,
      gapName: "",
      gapLicense: "",
      gapAddress: "",
      gapActivationStatus: "",
      gapType: "",
      gapPhoneNumber: "",
      gapCounty: "",
      gapListingExpiry: "",
      gapMonths: "",
      gapMiles: "",
    },
    sellPriceFees: {
      salesTaxes: 0, //
      mInventoryTax: 0, //

      totalFees: 0, //
      totalFeesAndothers: 0, //

      listingPrice: 0, //
      // txtTitleType: 0,

      cashDifference: 0, //
      paymentPriceFees: 0, //
      priceFeeDate: "", //
      priceFeesPaymentMethod: "", //
      saleTaxValue: "", //
      mInventoryValue: "", //
      balanceDue: 0, //
      totalDue: 0,
      sellPriceFeeItems: [],
    },
    sellCoBuyer: {
      firstName: "",
      lastName: "",
      middleName: "",
      businessName: "",
      idType: "",
      idNumber: "",
      idState: "",
      address: "",
      address2: "",
      county: "",
      phoneNumber: "",
      email: "",
      isCoBuyerEntity: false,
    },
    addListing: {
      id: 0,
      userId: dealerId,
      listingStatus: "Available",
      vin: "",
      lattitude: 0,
      longitude: 0,
      ip: "",
      purchasedDate: "",
      inspection: "",
      purchased_from: "",
      buyer_name: "",
      // stock_no: "",
      condition: "Used",
      year_make_model: "",
      // make: "",
      // make year model only use for backend-------------
      make: "",
      year: "",
      model: "",
      bodyClass: "",
      wheelbase: "",
      driveType: "",
      doorCount: "",
      // -----------------------------
      testp: "",
      // model: "",
      vehicleType: "",
      engine: "",
      transmission: "",
      mileage_in: "",
      // mileage_read: "",
      // engine_type: "",
      horse_power: "",
      trim: "",
      // transmission_speed: "",
      exteriorColor: "",
      interiorColor: "",
      seat_count: "",
      door_count: "",
      drive_train: "",
      interiorType: "",
      frame_damage: "",
      keys_count: "",
      accident: "",
      battery_kwh: "",
      battery_type: "",
      city_mpg: "",
      highway_mpg: "",
      price_fee_date: "",
      // payment_method: "",
      purchase_price: "",
      auction_fees: "",
      market_value_price: "",
      listing_price: "",
      total_repair_cost: "",
      flooring_fee: "",
      commission: "",
      others: "",
      total_cost: "",
      profit_price: "",
      title: "Clean",
      odo: "",
      owner: "",
      exterior_damage: "",
      state: "",
      status: "",
      smoked_in: "",
      tyrecondition_fl: "",
      tyrecondition_fr: "",
      tyrecondition_bl: "",
      tyrecondition_br: "",
      seller_description: "",
      // Title details section-------------
      title_status: "",
      title_type: "",
      title_number: "",
      title_recieved: "",
      title_location: "",
      other_restraint: "",

      vehicleOptions: {
        safetyFeatures: {
          anti_lock_brake_system: false,
          front_airbag: false,
          side_airbags: false,
          curtain_airbag: false,
          back_up_camera: false,
          blind_spot_monitoring: false,
          parking_sensors: false,
          brake_assist: false,
          collision_mitigation_system: false,
          cross_traffic_alert: false,
          daytime_running_lights: false,
          electronic_stability_control: false,
          forward_collision_warning: false,
          hill_descent_control: false,
          hill_start_assist: false,
          lane_departure_warning: false,
          lane_keeping_assist: false,
          rear_cross_traffic_alert: false,
          tyre_pressure_monitoring: false,
          traction_control: false,
        },
        infotainment: {
          apple_carplay_android_auto: false,
          bluetooth_connectivity: false,
          incar_wifi: false,
          navigation_system: false,
          premium_sound_system: false,
        },
        comfortAndConvenience: {
          adaptive_cruise_control: false,
          auto_dimming_rearview_mirror: false,
          auto_climate_control: false,
          heated_ventilated_seats: false,
          key_less_entry: false,
          key_less_go: false,
          memory_seats: false,
          leather_seats: false,
          multizone_climate_control: false,
          panormic_sunroof: false,
          power_adjustable_seats: false,
          power_liftgate: false,
          power_window_mirrors: false,
          remote_starter: false,
          smart_trunk_opener: false,
          sunshades: false,
          tilt_telescopic_steering_wheel: false,
        },

        exteriorFeatures: {
          alloy_wheels: false,
          automatic_headlights: false,
          fog_lights: false,
          led_headlights: false,
          power_folding_mirrors: false,
        },
        performanceAndHandling: {
          adaptive_suspension: false,
          all_wheels_drive: false,
          drive_mode_selector: false,
          sport_tuned_suspension: false,
          limited_slip_differential: false,
        },
        interiorFeatures: {
          ambient_lighting: false,
          cargo_cover: false,
          cargo_net: false,
          center_console_armrest: false,
          heated_steering_wheels: false,
        },
        driveAssistance: {
          adaptive_headlights: false,
          automatic_high_beams: false,
          driver_drowsiness_monitoring: false,
          head_up_display: false,
          night_vision: false,
        },
        energyAndEquipment: {
          engine_start_stop_system: false,
          hybrid_powertrain: false,
          regenerative_breaking: false,
          solar_roof: false,
        },
        extraVehicleOptions: [],
      },
      // -----------------------------------
      floor_fee: "",
      repair_cost: "",
      addvertising: {
        dacmp_status: true,
        dacmp_interval: "",
        unitedmotors_status: true,
        unitedmotors_interval: "",
        google_status: false,
        google_interval: "",
        insta_status: false,
        insta_interval: "",
        facebook_status: false,
        facebook_interval: "",
        craigslist_status: false,
        craigslist_interval: "",
        twitter_status: false,
        twitter_interval: "",
      },
      // new fields for trade-in--------------------------------
      doors: "",
      engineCylinder: "",
      engineType: "",
      engineSize: "",
      engineShape: "",
      fuelType: "",
      // fuelType2: "",
    },
  });
  useEffect(() => {
    console.log("listingId", listingId);
    setFormData((prevState) => ({
      ...prevState,
      listingId: listingId,
    }));
  }, [listingId]);
  // ------------------------------------------send data of redux to api-------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        addListing: {
          id: 0,
          userId: dealerId,
          vin: retailFromRedux.vin,
          listingStatus: "Available",
          year_make_model: retailFromRedux.year_make_model,
          // make year model check purpose-------
          make: retailFromRedux.make,
          year: retailFromRedux.year,
          model: retailFromRedux.model,
          bodyClass: retailFromRedux.bodyClass,
          wheelbase: retailFromRedux.wheelbase,
          driveType: retailFromRedux.driveType,
          doorCount: retailFromRedux.doorCount,
          // -----------------------------
          trim: retailFromRedux.trim,
          engine: retailFromRedux.engine,
          transmission: retailFromRedux.transmission,
          vehicleType: retailFromRedux.vehicleType,
          seat_count: retailFromRedux.seat_count,
          city_mpg: retailFromRedux.city_mpg,
          highway_mpg: retailFromRedux.highway_mpg,
          inspection: retailFromRedux.inspection,
          exteriorColor: retailFromRedux.exteriorColor,
          interiorColor: retailFromRedux.interiorColor,
          interiorType: retailFromRedux.interiorType,
          mileage_in: retailFromRedux.mileage_in,
          purchase_price: retailFromRedux.purchase_price,
          //tradeInValue: formData.tradeInValue,
          listing_price: retailFromRedux.listing_price,
          // new fields for trade in ------------------
          doors: retailFromRedux.doors,
          engineCylinder: retailFromRedux.engineCylinder,
          engineType: retailFromRedux.engineType,
          engineSize: retailFromRedux.engineSize,
          engineShape: retailFromRedux.engineShape,
          fuelType: retailFromRedux.fuelType,
        },
      }));
    }
  }, [retailFromRedux]);
  // -------------------------------------------------liean holder-------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        lienHolder: {
          leanHolderId: retailFromRedux.leanHolderId || 0,
          lienHolderName: retailFromRedux.lienHolderName,
          leinHolderID: retailFromRedux.lienHolderID,
          leinHolderAddress: retailFromRedux.lienHolderAddress,
          leinHolderAddress2: retailFromRedux.lienHolderAddress2,
        },
      }));
    }
  }, [retailFromRedux]);

  // -------------------------------service contract------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        serviceContract: {
          serviceContractId: retailFromRedux.serviceContractId || 0,
          serviceContractName: retailFromRedux.serviceContractName,
          serviceContractLicense: retailFromRedux.serviceContractLicense,
          serviceContractAddress: retailFromRedux.serviceContractAddress,
          serviceProviderActivationStatus:
            retailFromRedux.serviceProviderActivationStatus,
          serviceProviderType: retailFromRedux.serviceProviderType,
          providerPhoneNumber: retailFromRedux.providerPhoneNumber,
          providerCounty: retailFromRedux.providerCounty,
          providerListingExpiry: retailFromRedux.providerListingExpiry,
          serviceProviderMonths: retailFromRedux.serviceProviderMonths,
          serviceContractMiles: retailFromRedux.serviceContractMiles,
        },
      }));
    }
  }, [retailFromRedux]);
  // -----------------------------------------------------------gap insurance----------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        gapInsurance: {
          gapInsuranceId: retailFromRedux.gapInsuranceId || 0,
          gapName: retailFromRedux.gapName,
          gapLicense: retailFromRedux.gapLicense,
          gapAddress: retailFromRedux.gapAddress,
          gapActivationStatus: retailFromRedux.gapActivationStatus,
          gapType: retailFromRedux.gapType,
          gapPhoneNumber: retailFromRedux.gapPhoneNumber,
          gapCounty: retailFromRedux.gapCounty,
          gapListingExpiry: retailFromRedux.gapListingExpiry,
          gapMonths: retailFromRedux.gapMonths,
          gapMiles: retailFromRedux.gapMiles,
        },
      }));
    }
  }, [retailFromRedux]);

  //------------------------------------------------------------------
  //for labels of the price and fees
  // useEffect(() => {
  //   if (retailFromRedux) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       sellPriceFeesLabels: {
  //         documentFees: retailFromRedux.documentFees,
  //         driveOutTags: retailFromRedux.driveOutTags,
  //         titleAppFees: retailFromRedux.titleAppFees,
  //         terpTitleFees: retailFromRedux.terpTitleFees,
  //         buyerTag: retailFromRedux.buyerTag,
  //         windShieldTracker: retailFromRedux.windShieldTracker,
  //         regFeeDpsl: retailFromRedux.regFeeDpsl, //Special case
  //         cntyRoadBridge: retailFromRedux.cntyRoadBridge,
  //         childSafetyFee: retailFromRedux.childSafetyFee,
  //         inspectionFee: retailFromRedux.inspectionFee,
  //         stateInspectionFee: retailFromRedux.stateInspectionFee,
  //         procHandlingFee: retailFromRedux.procHandlingFee,
  //         cardFeesl: retailFromRedux.cardFeesl,
  //         serviceContractl: retailFromRedux.serviceContractl,
  //         gapInsurancel: retailFromRedux.gapInsurancel,
  //         mailDeliveryFeesl: retailFromRedux.mailDeliveryFeesl,
  //         transportationFeesl: retailFromRedux.transportationFeesl,
  //       },
  //     }));
  //   }
  // }, [retailFromRedux]);
  // -------------------------------------------------------
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        saleType: retailFromRedux.txtTitleType || "",
        // mileageIn: retailFromRedux.mileageIn.replace(/,/g, "") || "",
        mileageIn: (typeof retailFromRedux.mileageIn === 'string' 
          ? retailFromRedux.mileageIn 
          : String(retailFromRedux.mileageIn) // Convert to string if it's not already a string
        ).replace(/,/g, "") || "",
        sellPriceFees: {
          salesTaxes: Number(retailFromRedux.salesTaxes) || 0,
          mInventoryTax: Number(retailFromRedux.mInventoryTax) || 0,
          totalFees: Number(retailFromRedux.totalFees) || 0,
          totalFeesAndothers: Number(retailFromRedux.totalFeesAndothers) || 0,
          listingPrice: Number(retailFromRedux.userListingPrice) || 0,
          // txtTitleType: retailFromRedux.txtTitleType || 0,
          ownedTrade: Number(retailFromRedux.ownedTrade) || 0,
          cashDifference: Number(retailFromRedux.cashDifference) || 0,
          paymentPriceFees: Number(retailFromRedux.paymentPriceFees) || 0,
          priceFeeDate: retailFromRedux.date || "",
          priceFeesPaymentMethod: retailFromRedux.priceFeesPaymentMethod || "",
          saleTaxValue: Number(retailFromRedux.saleTaxValue) || 0,
          mInventoryValue: Number(retailFromRedux.mInventoryValue) || 0,
          balanceDue: Number(retailFromRedux.balanceDue) || 0,
          totalDue: Number(retailFromRedux.totalDue).toFixed(2) || 0,
        },
      }));
    }
  }, [retailFromRedux]);
  useEffect(() => {
    if (buyer) {
      setFormData((prevState) => ({
        ...prevState,
        firstName: buyer.firstName,
        lastName: buyer.lastName,
        middleName: buyer.middleName,
        businessName: buyer.businessName,
        idType: buyer.idType,
        idNumber: buyer.idNumber,
        idState: buyer.idState,
        address: buyer.address,
        address2: buyer.address2,
        county: buyer.county,
        phoneNumber: buyer.phoneNumber,
        email: buyer.email,
        isBuyerEntity: buyer.isBuyerEntity,
        city: buyer.city,
        zipCode: buyer.zipCode,
        state: buyer.state,
      }));
    }
  }, [buyer]);
  useEffect(() => {
    if (sellCoBuyer) {
      setFormData((prevState) => ({
        ...prevState,
        sellCoBuyer: {
          firstName: sellCoBuyer.firstName,
          lastName: sellCoBuyer.lastName,
          middleName: sellCoBuyer.middleName,
          idType: sellCoBuyer.idType,
          idNumber: sellCoBuyer.idNumber,
          idState: sellCoBuyer.idState,
          address: sellCoBuyer.address,
          address2: sellCoBuyer.address2,
          county: sellCoBuyer.county,
          phoneNumber: sellCoBuyer.phoneNumber,
          email: sellCoBuyer.email,
          businessName: sellCoBuyer.businessName,
          isCoBuyerEntity: sellCoBuyer.isCoBuyerEntity,
          city: sellCoBuyer.city,
          zipCode: sellCoBuyer.zipCode,
          state: sellCoBuyer.state,
        },
      }));
    }
  }, [sellCoBuyer]);

  // for buyer and cobyer-----------------------------------
  const dispatch = useDispatch();
  const SellPriceInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs || []
  );
  const priceFeesLabels = SellPriceInputs.map((input) => ({
    // userId: input.id,
    itemName: input.label,
    itemValue: Number(input.InputValue) || 0,
  }));
  const additionalItems = [
    {
      itemName: "Sales Tax",
      itemValue: Number(retailFromRedux.salesTaxes) || 0,
    },
    {
      itemName: "Inventory Tax",
      itemValue: Number(retailFromRedux.mInventoryTax) || 0,
    },
  ];
  const updatedPriceFeesLabels = priceFeesLabels.concat(additionalItems);
  useEffect(() => {
    if (retailFromRedux) {
      setFormData((prevState) => ({
        ...prevState,
        sellPriceFees: {
          ...prevState.sellPriceFees,
          sellPriceFeeItems: updatedPriceFeesLabels,
        },
      }));
    }
  }, [retailFromRedux]);
  const [sellForm, setSellForm] = useState([]);
  // console.log("sellForm", sellForm);
  useEffect(() => {
    dispatch(
      setRetailData({
        sellForm: sellForm,
      })
    );
  }, [sellForm]);

  const [isDisable, setisDisable] = useState(true);
  const [isComplete, setIsComplete] = useState({
    isFinalised: false,
    isSaved: false,
  });

  const selectedcarChanged = useSelector((state) => state.sell.selectedVehicle);
  useEffect(() => {
    if (isComplete.isFinalised || isComplete.isSaved) {
      setIsComplete((prevData) => ({
        ...prevData,
        isFinalised: false,
        isSaved: false,
      }));
    }
  }, [selectedcarChanged]);
  const [loading, setLoading] = useState({
    finalise: false,
    save: false,
  });

  const navigate = useNavigate();
  // for form to save data to craete sell form---------------------------
  const [isCallFunc, setisCallFunc] = useState(false);
  console.log("isCallFunc download state", isCallFunc);
  const pdfViewerRef = useRef(null);

  const handleCallChildFunction = () => {
    if (pdfViewerRef.current) {
      pdfViewerRef.current.callLocalFunction();
    }
  };
  const handleSubmit = async (e, purpose) => {
    e.preventDefault();
    const key = purpose === "Sold" ? "finalise" : "save";
    setLoading((prevData) => ({
      ...prevData,
      [key]: true,
    }));

    const fnd = new FormData();
    const updatedFormData = {
      ...formData,
      sellStatus: purpose,
    };
    fnd.append("sell", JSON.stringify(updatedFormData));

    try {
      const response = await axios.post(`${apiUrl}/sell/save`, fnd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Data saved successfully:", response.data);
      setSellForm(response.data);

      if (response.status === 200) {
        setisDisable(false);
        if (purpose === "Sold") {
          //For Downloading the PDF
          if (!isCallFunc) {
            setisCallFunc(true);
          }
          toast.success("Inventory Finalized and Documents Printed");
          setIsComplete((prevData) => ({
            ...prevData,
            isFinalised: true,
          }));
          handleCallChildFunction();
          setShowPdfs(false);
          navigate("/dealer/inventory/sold", { state: true });
        } else {
          toast.success("This inventory is saved in Drafts");
          setShowPdfs(false);
          navigate("/dealer/inventory/draft");
          setIsComplete((prevData) => ({
            ...prevData,
            isSaved: true,
          }));
        }
        // dispatch(resetCoBuyerSellData());
        // dispatch(resetBuyerSellData());

        dispatch(
          setRetailData({
            salesTaxes: "6.25",
            mInventoryTax: "0.001488",
            titleApplicationFee: 13.0,
            terpTitlefee: 20,
            drivesOutTag: 5.0,
            windshieldTrackerSticker: 50.75,
            regFeeDps: 1,
            cntyRoadBridgeFee: 10,
            childSafetyFund: 1.5,
            InspectionFeeOBDNL: 25,
            StateInspectionFees: 8.25,
            processingHandlingFee: 4.75,
            documentationFee: 150.0,
            technologyFees: 0.0,
            buyerTagFees: 5,
            other1: 0.0,
            other2: 0.0,
            cardFees: 0,
            warranty: 0,
            gapInsurance: 0,
            mailDeliveryFees: 0,
            transportationFees: 0,
            totalFees: 0,
            totalFeesAndothers: 294.5,
            txtState: "",
            listingPrice: "",
            userListingPrice: 0,
            txtTitleType: null,
            ownedTrade: "",
            cashDifference: "",
            paymentPriceFees: "",
            date: "",
            saleTaxValue: "",
            mInventoryValue: "",
            balanceDue: "",
            totalDue: 0,
            // -----------------------------------
            lienHolderName: "",
            leanHolderId: "",
            lienHolderID: "",
            lienHolderAddress: "",
            lienHolderAddress2: "",
            serviceContractId: "",
            serviceContractName: "",
            serviceContractLicense: "",
            serviceContractAddress: "",
            serviceProviderActivationStatus: "",
            serviceProviderType: "",
            providerPhoneNumber: "",
            providerCounty: "",
            providerListingExpiry: "",
            priceFeesPaymentMethod: "",
            //Gap Insurance Provider Data
            gapInsuranceId: "",
            gapName: "",
            gapLicense: "",
            gapAddress: "",
            gapActivationStatus: "",
            gapType: "",
            gapPhoneNumber: "",
            gapCounty: "",
            gapListingExpiry: "",
            //Gap Insurance Provider Data
            serviceProviderMonths: "",
            serviceContractMiles: "",
            gapMonths: "",
            gapMiles: "",
            // ----------------
            // Sell Form ---------------------------
            sellForm: "",
            // tax maker forms-----------------------
            taxMakerForms: "",
            // trade in key--------
            dealerId: 0,
            vin: "",
            year_make_model: "",
            // make year modal only check purpose for backend------
            make: "",
            year: "",
            model: "",
            bodyClass: "",
            wheelbase: "",
            driveType: "",
            doorCount: "",
            // -----------------------
            trim: "",
            engine: "",
            transmission: "",
            vehicleType: "",
            seat_count: "",
            city_mpg: "",
            highway_mpg: "",
            inspection: "",
            exteriorColor: "",
            interiorColor: "",
            interiorType: "",
            mileage_in: "",
            purchase_price: "",
            //tradeInValue: formData.tradeInValue,
            listing_price: "",
            mileageIn: "",
            // trade in key end--------
            // for images of vehicle card--------
            imageUrl: "",
          })
        );
        // dispatch(setSelectedVehicle(null));
      }
    } catch (error) {
      console.error("Error saving form data:", error);
      if (error?.response?.status === 401) {
        console.log(error);
        toast.error(error.response.data);
      }
    } finally {
      const key = purpose === "Sold" ? "finalise" : "save";
      setLoading((prevData) => ({
        ...prevData,
        [key]: false,
      })); // End loading regardless of success or failure
      // dispatch(resetCoBuyerSellData());
      // dispatch(resetBuyerSellData());
      // dispatch(setSelectedVehicle(null));
      dispatch(
        setRetailData({
          salesTaxes: "6.25",
          mInventoryTax: "0.001488",
          titleApplicationFee: 13.0,
          terpTitlefee: 20,
          drivesOutTag: 5.0,
          windshieldTrackerSticker: 50.75,
          regFeeDps: 1,
          cntyRoadBridgeFee: 10,
          childSafetyFund: 1.5,
          InspectionFeeOBDNL: 25,
          StateInspectionFees: 8.25,
          processingHandlingFee: 4.75,
          documentationFee: 150.0,
          technologyFees: 0.0,
          buyerTagFees: 5,
          other1: 0.0,
          other2: 0.0,
          cardFees: 0,
          warranty: 0,
          gapInsurance: 0,
          mailDeliveryFees: 0,
          transportationFees: 0,
          totalFees: 0,
          totalFeesAndothers: 294.5,
          txtState: "",
          listingPrice: "",
          userListingPrice: 0,
          txtTitleType: null,
          ownedTrade: "",
          cashDifference: "",
          paymentPriceFees: "",
          date: "",
          saleTaxValue: "",
          mInventoryValue: "",
          balanceDue: "",
          totalDue: 0,
          // -----------------------------------
          lienHolderName: "",
          leanHolderId: "",
          lienHolderID: "",
          lienHolderAddress: "",
          lienHolderAddress2: "",
          serviceContractId: "",
          serviceContractName: "",
          serviceContractLicense: "",
          serviceContractAddress: "",
          serviceProviderActivationStatus: "",
          serviceProviderType: "",
          providerPhoneNumber: "",
          providerCounty: "",
          providerListingExpiry: "",
          priceFeesPaymentMethod: "",
          //Gap Insurance Provider Data
          gapInsuranceId: "",
          gapName: "",
          gapLicense: "",
          gapAddress: "",
          gapActivationStatus: "",
          gapType: "",
          gapPhoneNumber: "",
          gapCounty: "",
          gapListingExpiry: "",
          //Gap Insurance Provider Data
          serviceProviderMonths: "",
          serviceContractMiles: "",
          gapMonths: "",
          gapMiles: "",
          // ----------------
          // Sell Form ---------------------------
          sellForm: "",
          // tax maker forms-----------------------
          taxMakerForms: "",
          // trade in key--------
          dealerId: 0,
          vin: "",
          year_make_model: "",
          // make year modal only check purpose for backend------
          make: "",
          year: "",
          model: "",
          bodyClass: "",
          wheelbase: "",
          driveType: "",
          doorCount: "",
          // -----------------------
          trim: "",
          engine: "",
          transmission: "",
          vehicleType: "",
          seat_count: "",
          city_mpg: "",
          highway_mpg: "",
          inspection: "",
          exteriorColor: "",
          interiorColor: "",
          interiorType: "",
          mileage_in: "",
          purchase_price: "",
          //tradeInValue: formData.tradeInValue,
          listing_price: "",
          mileageIn: "",
          // trade in key end--------
          // for images of vehicle card--------
          imageUrl: "",
        })
      );
    }
  };

  const handleNavigate = () => {
    setShowPdfs(false);
    dispatch(setActiveTab("Prices and Fees"));
  };

  // const handleDownloadPrint = async (item) => {
  //   console.log("Printing the pdf ", item);
  //   try {
  //     const response = await fetch(
  //       `https://5starsmotors.com/documents/${id}/${item}`
  //     );
  //     console.log(response + " while downloading the pdf");
  //     const blob = await response.blob();
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     const date = new Date();
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const day = String(date.getDate()).padStart(2, "0");
  //     const hour = String(date.getHours()).padStart(2, "0");
  //     const minute = String(date.getMinutes()).padStart(2, "0");
  //     const second = String(date.getSeconds()).padStart(2, "0");
  //     const fileName = `${userData.firstName}_${userData.lastName}_${month}${day}${year}${hour}${minute}${second}_${item}`; // Set file name to standard format
  //     link.download = fileName;
  //     link.click();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //FOR ALL FORMS IN THE MODAL
  //Content for All check boxes modal

  const AllCheckBoxesFromAllForms = [
    {
      id: "AgreementInsurance",
      label: "Agreement To Provide Insurance",
    },
    { id: "billOfSale", label: "Bill of Sale /Buyer Order" },
    { id: "billOfSaleCustomer", label: "Bill of Sale - Customer" },
    { id: "buyerGuide", label: "Buyer Guide AS / IS" },
    {
      id: "creditBureauDisclosure",
      label: "Credit Bureau Disclosure",
    },
    { id: "odometerDisclosure", label: "Odometer Disclosure" },
    {
      id: "rebuildTitleDisclosure",
      label: "Rebuilt Title Disclosure",
    },
    { id: "titleApp", label: `${selectedState} -Title App I130u` },
    {
      id: "powerOfAttorney",
      label: `${selectedState} - Power Of Attorney`,
    },
    {
      id: "retailContractInstallment",
      label: `${selectedState} - Retail Contract Installment`,
    },
    {
      id: "salesTaxExemptionCertificate",
      label: `${selectedState} - Sales Tax Exemption Certificate`,
    },
    {
      id: "statementOfFacts",
      label: `Statement of Facts`,
    },
    {
      id: "airbagDisclosure",
      label: `Airbag disclosure`,
    },
    {
      id: "serviceContract",
      label: `Service Contract Decline Acknowledgment`,
    },
    {
      id: "countyOfTitleIssuance",
      label: `County of Title Issuance`,
    },
    {
      id: "accuracyInformationAcknowledment",
      label: `Accuracy of Information Acknowledgment`,
    },
    {
      id: "rightToReposses",
      label: `Right to Reposses`,
    },
  ];

  // State to manage checkboxes for all forms
  const [checkboxes, setCheckboxes] = useState({
    billOfSale: false,
    billOfSaleCustomer: false,
    buyerGuide: false,
    odometerDisclosure: false,
    titleApp: false,
    AgreementInsurance: false,
    creditBureauDisclosure: false,
    rebuildTitleDisclosure: false,
    powerOfAttorney: false,
    retailContractInstallment: false,
    salesTaxExemptionCertificate: false,
    statementOfFacts: false,
    airbagDisclosure: false,
    serviceContract: false,
    countyOfTitleIssuance: false,
    accuracyInformationAcknowledment: false,
    rightToReposses: false,
  });

  // Handle individual checkbox change in ALL FORMS
  //search in All forms
  const [searchInput, setSearchInput] = useState("");
  const [searchFormOutput, setSearchFormOnput] = useState([]);
  console.log("searchFormOutput => ", searchFormOutput);
  // const handleCheckboxChange = (e) => {
  //   console.log("Updating the checkbox in the ALL FORMS");
  //   const { id, checked } = e.target;

  //   setCheckboxes((prev) => ({
  //     ...prev,
  //     [id]: checked,
  //   }));

  //   // Update Select All state based on individual checkboxes
  //   setSelectAll(
  //     Object.values({ ...searchFormOutput, [id]: checked }).every(
  //       (val) => val
  //     )
  //   );
  // };
  const handleCheckboxChange = (e, manualId = null) => {
    const id = manualId || e.target.id;
    const checked = manualId ? !checkboxes[id] : e.target.checked;

    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));

    // setSelectAll(
    //   Object.values({ ...searchFormOutput, [id]: checked }).every((val) => val)
    // );
  };

  //checkboxes to show in outer forms, by-default we are showing some checkboxes
  const [checkBoxesToShow, setCheckboxesToShow] = useState([
    "billOfSale",
    "billOfSaleCustomer",
    "titleApp",
    "powerOfAttorney",
    "buyerGuide",
    "AgreementInsurance",
    "odometerDisclosure",
    "statementOfFacts",
    "airbagDisclosure",
    "rebuildTitleDisclosure",
    "serviceContract",
    "countyOfTitleIssuance",
    "accuracyInformationAcknowledment",
    "rightToReposses",
  ]);
  // console.log("checkBoxesToShow", checkBoxesToShow);

  //making sure the item we are showing by-default should be checked by-default
  // useEffect(() => {
  //   //first filtering out the items in the all the forms which should be checked
  //   //by-default then check the filtered
  //   if (searchFormOutput.length > 0) {
  //     const filteredItem =
  //       checkBoxesToShow &&
  //       checkBoxesToShow.map((item) =>
  //         searchFormOutput.find((obj) => obj.id === item)
  //       );
  //     console.log("filteredItem in useEffect1 => ", filteredItem);
  //     // Create a new object with the updated checkbox values
  //     const updatedCheckboxes = {};
  //     filteredItem.forEach((item) => {
  //       if (item) {
  //         updatedCheckboxes[item.id] = true;
  //       }
  //     });

  //     // Update the checkboxes state
  //     setCheckboxes((prevData) => ({ ...prevData, ...updatedCheckboxes }));
  //   }
  // }, [checkBoxesToShow]);

  const [browser, setBrowser] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let detectedBrowser;

    if (userAgent.includes("Chrome")) {
      detectedBrowser = "Chrome";
    } else if (userAgent.includes("Firefox")) {
      detectedBrowser = "Firefox";
    } else if (userAgent.includes("Safari")) {
      detectedBrowser = "Safari";
    } else if (userAgent.includes("Edge")) {
      detectedBrowser = "Edge";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
      detectedBrowser = "Internet Explorer";
    } else {
      detectedBrowser = "Unknown";
    }

    setBrowser(detectedBrowser);
  }, []);

  useEffect(() => {
    if (searchFormOutput.length > 0) {
      const filteredItem = checkBoxesToShow.map((item) =>
        searchFormOutput.find((obj) => obj.id === item)
      );

      console.log("filteredItem in useEffect => ", filteredItem);

      const updatedCheckboxes = {};
      filteredItem.forEach((item) => {
        if (item) {
          updatedCheckboxes[item.id] = true;
        }
      });

      setCheckboxes((prevData) => ({ ...prevData, ...updatedCheckboxes }));
    }
  }, [searchFormOutput, checkBoxesToShow]); // Ensure dependencies are correct

  //Method in the all forms modal to add the checked checkbox in the outer forms
  const handleAddToForms = () => {
    const checkedItems = Object.entries(checkboxes).filter(
      ([key, value]) => value === true
    );
    const trueObject = Object.fromEntries(checkedItems);

    setCheckboxesToShow(Object.keys(trueObject));
    setShowFormsPdfs(false);
    setSearchInput("");
  };

  //for searching the modal in the modal
  const handleSearchForms = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    setSearchFormOnput(
      AllCheckBoxesFromAllForms.filter((item) =>
        item.label.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchFormOnput(AllCheckBoxesFromAllForms);
    }
  }, [searchInput]);

  useEffect(() => {
    const boolValue = Object.values(checkboxes).every((item) => item);
    console.log("Are all the checkboxes  checked ", boolValue);
    if (boolValue) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkboxes]);

  //OUTER FORMS STARTS HERE
  //Label checkboxes are the actual checkboxes that will be shown in outer forms
  const [labelCheckBox, setLabelCheckBox] = useState([]);
  console.log("Label Checkbox => ", labelCheckBox);
  useEffect(() => {
    if (checkBoxesToShow.length > 0) {
      const newArray = checkBoxesToShow
        .map((item) => AllCheckBoxesFromAllForms.find((obj) => obj.id === item))
        .filter(Boolean); // Remove any undefined results
      console.log("New Array in useEffect2 => ", newArray);
      setLabelCheckBox(newArray);
    } else {
      setLabelCheckBox([]);
    }
  }, [checkBoxesToShow]);

  //for label check box in outer forms
  const [Formcheckboxes, setFormsCheckboxes] = useState({
    billOfSale: true,
    billOfSaleCustomer: true,
    buyerGuide: true,
    odometerDisclosure: true,
    titleApp: true,
    AgreementInsurance: true,
    creditBureauDisclosure: true,
    rebuildTitleDisclosure: false,
    powerOfAttorney: true,
    retailContractInstallment: true,
    salesTaxExemptionCertificate: true,
    statementOfFacts: true,
    airbagDisclosure: true,
    serviceContract: true,
    countyOfTitleIssuance: true,
    accuracyInformationAcknowledment: true,
    rightToReposses: true,
  });

  //pdf array for Viewing the pdf
  const pdfArray = [
    {
      pdf: reduxForms && reduxForms[6],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.billOfSale &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "billOfSale"),
    },
    {
      pdf: reduxForms && reduxForms[16],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.billOfSaleCustomer &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "billOfSaleCustomer"),
    },
    {
      pdf: reduxForms && reduxForms[5],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.titleApp &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "titleApp"),
    },

    {
      pdf: reduxForms && reduxForms[1],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.salesTaxExemptionCertificate &&
        labelCheckBox &&
        labelCheckBox.find(
          (check) => check.id === "salesTaxExemptionCertificate"
        ),
    },
    {
      pdf: reduxForms && reduxForms[2],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.powerOfAttorney &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "powerOfAttorney"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[3],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.buyerGuide &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "buyerGuide"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[4],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.rebuildTitleDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "rebuildTitleDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[12],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.AgreementInsurance &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "AgreementInsurance"),
    },

    {
      pdf: reduxForms && reduxForms[7],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.odometerDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "odometerDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[8],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.retailContractInstallment &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "retailContractInstallment"),
    },
    {
      pdf: reduxForms && reduxForms[9],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.statementOfFacts &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "statementOfFacts"),
    },
    {
      pdf: reduxForms && reduxForms[10],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.airbagDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "airbagDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[11],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.serviceContract &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "serviceContract"),
    },
    {
      pdf: reduxForms && reduxForms[0],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.countyOfTitleIssuance &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "countyOfTitleIssuance"),
    },
    {
      pdf: reduxForms && reduxForms[13],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.creditBureauDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "creditBureauDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[14],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.accuracyInformationAcknowledment &&
        labelCheckBox &&
        labelCheckBox.find(
          (check) => check.id === "accuracyInformationAcknowledment"
        ),
    },
    {
      pdf: reduxForms && reduxForms[15],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.rightToReposses &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "rightToReposses"),
    },
  ];
  //function to update the checkbox in outer forms
  const handleFormsCheckboxChange = (e) => {
    console.log("Updating the checkbox in the OUTER forms");
    const { id, checked } = e.target;
    console.log("ID => " + id + " checked => ", checked);

    setFormsCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  console.log("Formcheckboxes ", Formcheckboxes);
  //If all the checkboxes are checked in nouter form then check the "Select ALL"
  // useEffect(() => {
  //   const matchedItems = Object.keys(Formcheckboxes).filter((item) =>
  //     labelCheckBox.find((obj) => obj.id === item)
  //   );
  //   console.log(
  //     "Matched Items which are in label check box as well as in formcheckboxes",
  //     matchedItems
  //   );
  //   // matchedItems.some((checked) => )
  // }, [Formcheckboxes, labelCheckBox]);

  //For combining the PDFs

  // useEffect(() => {
  //   const combinePdfs = async () => {
  //     try {
  //       const mergedPdf = await PDFDocument.create();
  //       for (const url of pdfArray.filter((item) => item.isShow)) {
  //         const existingPdfBytes = await fetch(
  //           `https://5starsmotors.com/documents/${userData.id}/${url.pdf}`
  //         ).then((res) => res.arrayBuffer());
  //         const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //         const copiedPages = await mergedPdf.copyPages(
  //           pdfDoc,
  //           pdfDoc.getPageIndices()
  //         );

  //         copiedPages.forEach((page) => mergedPdf.addPage(page));
  //       }

  //       const mergedPdfBytes = await mergedPdf.save();
  //       const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  //       const url = URL.createObjectURL(blob);
  //       setCombinedPdfUrl(url);
  //     } catch (error) {
  //       console.error("Error combining PDFs:", error);
  //     } finally {
  //       setPDFLoading(false); // Ensure loading state is updated
  //     }
  //   };

  //   combinePdfs(); For better printing please select page size letter and scale - print to fit area
  // }, [userData]);
  // useEffect(() => {
  //   if (showPdfs) {
  //     toast((t) => (
  //       <span onClick={() => toast.dismiss(t.id)}>
  //         For better printing please select page size letter and scale - fit to
  //         print area.
  //         {/* <button >
  //         For better printing please select page size letter and scale - print to fit area
  //         </button> */}
  //       </span>
  //     ));
  //   }
  // }, [showPdfs]);

  const [showSuggestionSS, setShowSuggestionSS] = useState(false);
  // useEffect(() => {
  //   if (showPdfs) {
  //     setShowSuggestionSS(true);
  //     setTimeout(() => {
  //       setShowSuggestionSS(false);
  //     }, 1500);
  //   }
  // }, [showPdfs]);
  // useEffect(() => {
  //   if (showSuggestionSS) {
  //     setTimeout(() => {
  //       setShowSuggestionSS(false);
  //     }, 1500);
  //   }
  // }, [showSuggestionSS]);

  const { isSomethingChanged } = useSelector((state) => state.sell);

  const [currScreenType, setCurrScreenType] = useState("Desktop");
  useEffect(() => {
    const handlWindowResize = () => {
      if (window.innerWidth < 768) {
        setCurrScreenType("Mobile");
      } else if (window.innerWidth < 1024) {
        setCurrScreenType("Tablet");
      } else if (window.innerWidth < 1441) {
        setCurrScreenType("Laptop");
      } else {
        setCurrScreenType("Desktop");
      }
    };
    handlWindowResize();

    window.addEventListener("resize", handlWindowResize);
    return () => {
      window.removeEventListener("resize", () => {
        setCurrScreenType("Desktop");
      });
    };
  }, []);

  const handlePDFZoom = () => {
    switch (currScreenType) {
      case "Mobile":
        return 70;
      case "Tablet":
        return 70;
      case "Laptop":
        return 90;
      case "Desktop":
        return 110;
    }
  };

  console.log("isSomethingChanged", isSomethingChanged);
  const [currTotalPage, setCurrTotalPage] = useState(0);
  const handleDeleteCheckBox = (checkBoxToDelete) => {
    console.log("checkBoxToDelete ", checkBoxToDelete);
    // Removing the checkbox from the outer forms
    if (labelCheckBox.map((item) => item.id).includes(checkBoxToDelete)) {
      const filteredData = labelCheckBox.filter(
        (item) => item.id !== checkBoxToDelete
      );
      setLabelCheckBox(filteredData);
    }
    // Removing the check of checkbox from All forms
    if (
      searchFormOutput &&
      searchFormOutput.map((item) => item.id).includes(checkBoxToDelete)
    ) {
      setCheckboxes((prevData) => ({
        ...prevData,
        [checkBoxToDelete]: false,
      }));
    }
  };

  return (
    <Container className="forms-container rounded relative ">
      <div className="absolute">
        <Modal
          // show={showPdfs}
          // onHide={() => setShowPdfs(false)}
          size="xl"
          dialogClassName="modal-90w"
          backdrop="static"
          onClick={(e) => {
            if (e.target.classList.contains("modal-backdrop")) {
              // handleBackdropClick();
              console.log("Detecting the click ooutside the modal");
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "1.5rem",
                color: "#e8a262",
              }}
            >
              Forms
            </Modal.Title>
          </Modal.Header>
          {pdfArray.some((item) => item.isShow) ? (
            <div className="previewModalBtnContainer">
              {" "}
              <button
                onClick={(e) => handleSubmit(e, "Sold")}
                disabled={
                  isComplete.isFinalised || isComplete.isSaved ? true : false
                }
                style={{
                  cursor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "lightgrey"
                      : "pointer",
                }}
              >
                {" "}
                {loading.finalise
                  ? "Finalising..."
                  : isComplete.isFinalised
                  ? "Finalised"
                  : "Finalise & Print"}
              </button>
              {/* <button
                    // disabled={isDisable}
                    // style={{ cursor: isDisable && "not-allowed" }}
                    onClick={handlePrintAllPdfs}
                  >
                    Print
                  </button> */}
              <button
                disabled={
                  isComplete.isFinalised || isComplete.isSaved ? true : false
                }
                style={{
                  cursor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "lightgrey"
                      : "pointer",
                }}
              >
                e-Contract
              </button>
              <button
                // disabled={!isDisable}
                // style={{ cursor: !isDisable && "not-allowed" }}
                disabled={
                  isComplete.isFinalised || isComplete.isSaved ? true : false
                }
                style={{
                  cursor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "lightgrey"
                      : "pointer",
                }}
                onClick={handleNavigate}
              >
                Edit
              </button>
              <button
                // disabled={!isDisable}
                // style={{ cursor: !isDisable && "not-allowed" }}
                onClick={(e) => handleSubmit(e, "Sell-Drafted")}
                disabled={
                  isComplete.isFinalised || isComplete.isSaved ? true : false
                }
                style={{
                  cursor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor:
                    isComplete.isFinalised || isComplete.isSaved
                      ? "lightgrey"
                      : "pointer",
                }}
              >
                {loading.save
                  ? "Saving..."
                  : isComplete.isSaved
                  ? "Saved"
                  : "Save"}
                {/* Save */}
              </button>
              <p
                style={{
                  // border: "1px solid black",
                  height: "100%",
                  fontSize: "x-large",
                  position: "relative",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginBottom: "0",
                }}
                // title="Guide to print the  document"
                onMouseEnter={() => setShowSuggestionSS(true)}
                onMouseLeave={() => setShowSuggestionSS(false)}
              >
                {/* <FaCircleInfo /> */}
                {/* {showSuggestionSS && (
                  <span
                    style={{
                      position: "absolute",
                      top: "2rem",
                      left: "-12rem",
                      zIndex: "99999",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "small",
                      }}
                    >
                      Guide to print the document
                    </p>
                    <img
                      src={Image.pdfPrintSuggestion}
                      alt="PDF PRINT SUGGESTION"
                    />
                  </span>
                )} */}
              </p>
              {/* Notification dropdown always visible */}
              {/* {showNotification && (
        <div className="notificationDropdown">
          <p>Please select Letter size and scale - Fit to print Area</p>
        </div>
      )} */}
            </div>
          ) : (
            <div> Please select the forms to view them</div>
          )}
          {pdfArray.some((item) => item.isShow) && (
            <Modal.Body className="h-100 p-3">
              {/* <PDFViewer pdfArray={pdfArray.filter((item) => item.isShow)} /> */}
              {/* <PDF pdfArray={pdfArray.filter((item) => item.isShow)} /> */}
              {browser === "Safari" ? (
                <PDFViewer2
                  pdfArray={pdfArray.filter((item) => item.isShow)}
                  setisCallFunc={setisCallFunc}
                  isCallFunc={isCallFunc}
                />
              ) : (
                <PDF
                  pdfArray={pdfArray.filter((item) => item.isShow)}
                  setisCallFunc={setisCallFunc}
                  isCallFunc={isCallFunc}
                  toolbar={0}
                  zoomSize={handlePDFZoom()}
                  setCurrTotalPage={setCurrTotalPage}
                />
              )}
            </Modal.Body>
          )}
        </Modal>

        <Modal
          show={showForms}
          onHide={() => setShowFormsPdfs(false)}
          size="xl"
          // dialogClassName="boxShadowInset"
          dialogClassName="modal-90w"
          style={{ boxShadow: "inset 0 0 30px #dd8a3f" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ width: "100%" }}>
              <div className="allFormsModalHeader">All Forms</div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="allFormsPopupBody">
            <Col className="allFormsHeaderItemsContainer">
              <div className="state allFormsHeaderItem">
                <Dropdown
                  show={isOpen}
                  onSelect={handleSelectState}
                  onToggle={(open) => setIsOpen(open)}
                  className="m-0 "
                >
                  <Dropdown.Toggle
                    size="sm"
                    className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {selectedState}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="state-drp">
                    {/* Hidden input to manage search */}
                    <input
                      type="text"
                      value={search}
                      ref={searchInputRef}
                      autofocus
                      onChange={handleSearchChange}
                      onFocus={() => setIsOpen(true)}
                      style={{
                        position: "absolute",
                        top: "-9999px",
                        left: "-9999px",
                      }} // Hide the input field
                    />
                    {filteredStates.length > 0 ? (
                      filteredStates.map((state) => (
                        <Dropdown.Item
                          key={state.name}
                          eventKey={state.name}
                          onClick={() => handleSelect(state.name)}
                        >
                          {state.name}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No states found</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="allFormsHeaderItem">
                <input
                  type="text"
                  value={searchInput}
                  placeholder="Search the forms here"
                  onChange={handleSearchForms}
                />
                <span>
                  <FaSearch />
                </span>
              </div>
            </Col>
            {/* {reduxForms && reduxForms?.map((item, i) => ( */}
            <p className="allFormsPopoutAddBtnContainer">
              <button onClick={handleAddToForms} className="innerBtn">
                Add to forms
              </button>
            </p>
            <label
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: "underline",
                display: "flex",
                gap: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <input
                type="checkbox"
                checked={selectAll}
                // onChange={handleSelectAll}
                onChange={handleSelectAll}
              />
              <span>{selectAll ? "DeSelect All" : "Select All"}</span>
            </label>
            <Form className="outerCheckBoxElContainer ">
              {searchFormOutput.length > 0 ? (
                searchFormOutput.map(({ id, label }) => (
                  <Form.Group key={id} className="outerCheckBoxEl">
                    <Form.Check
                      type="checkbox"
                      id={id}
                      checked={checkboxes[id]}
                      onChange={handleCheckboxChange}
                      label={
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            handleCheckboxChange(null, id);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className={`custom-checkbox ${
                              checkboxes[id] ? "OrangeCheckBox" : ""
                            }`}
                          />
                          {label}
                        </span>
                      }
                      className="form-option border-bottom outerCheckBoxChild"
                    />
                  </Form.Group>
                ))
              ) : (
                <p>No form found for "{searchInput}"</p>
              )}
            </Form>
            <div className="outerBtnContainer">
              {/* <button
                className="innerBtn"
                // onClick={handlePreviewSelect}
              >
                <span>
                  <RiFileSearchFill />
                </span>
                <span>Preview</span>
              </button> */}
              <button onClick={handleAddToForms} className="innerBtn">
                Add to forms
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>

      {showPdfs ? (
        pdfArray.some((item) => item.isShow) && (
          <div className="h-100 p-3">
            {pdfArray.some((item) => item.isShow) ? (
              <div className="previewModalBtnContainer">
                {" "}
                <button onClick={() => setShowPdfs(false)}>
                  <IoMdArrowRoundBack />
                </button>
                <button
                  onClick={(e) => handleSubmit(e, "Sold")}
                  disabled={
                    isComplete.isFinalised || isComplete.isSaved ? true : false
                  }
                  style={{
                    cursor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "lightgrey"
                        : "pointer",
                  }}
                >
                  {" "}
                  {loading.finalise
                    ? "Finalising..."
                    : isComplete.isFinalised
                    ? "Finalised"
                    : "Finalise & Print"}
                </button>
                <button
                  disabled={
                    isComplete.isFinalised || isComplete.isSaved ? true : false
                  }
                  style={{
                    cursor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "lightgrey"
                        : "pointer",
                  }}
                >
                  e-Contract
                </button>
                <span title="Number of Pages">{currTotalPage}</span>
                <button
                  // disabled={!isDisable}
                  // style={{ cursor: !isDisable && "not-allowed" }}
                  disabled={
                    isComplete.isFinalised || isComplete.isSaved ? true : false
                  }
                  style={{
                    cursor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "lightgrey"
                        : "pointer",
                  }}
                  onClick={handleNavigate}
                >
                  Edit
                </button>
                <button
                  // disabled={!isDisable}
                  // style={{ cursor: !isDisable && "not-allowed" }}
                  onClick={(e) => handleSubmit(e, "Sell-Drafted")}
                  disabled={
                    isComplete.isFinalised || isComplete.isSaved ? true : false
                  }
                  style={{
                    cursor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      isComplete.isFinalised || isComplete.isSaved
                        ? "lightgrey"
                        : "pointer",
                  }}
                >
                  {loading.save
                    ? "Saving..."
                    : isComplete.isSaved
                    ? "Saved"
                    : "Save"}
                  {/* Save */}
                </button>
                <p
                  style={{
                    // border: "1px solid black",
                    height: "100%",
                    fontSize: "x-large",
                    position: "relative",
                    cursor: "pointer",
                    marginLeft: "0.4rem",
                    marginBottom: "0",
                  }}
                  // title="Guide to print the  document"
                  onMouseEnter={() => setShowSuggestionSS(true)}
                  onMouseLeave={() => setShowSuggestionSS(false)}
                >
                  {/* <FaCircleInfo /> */}
                  {/* {showSuggestionSS && (
                    <span
                      style={{
                        position: "absolute",
                        top: "2rem",
                        left: "-12rem",
                        zIndex: "99999",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "small",
                        }}
                      >
                        Guide to print the document
                      </p>
                      <img
                        src={Image.pdfPrintSuggestion}
                        alt="PDF PRINT SUGGESTION"
                      />
                    </span>
                  )} */}
                </p>
              </div>
            ) : (
              <div> Please select the forms to view them</div>
            )}
            {browser === "Safari" ? (
              <PDFViewer2
                pdfArray={pdfArray.filter((item) => item.isShow)}
                setisCallFunc={setisCallFunc}
                isCallFunc={isCallFunc}
              />
            ) : (
              <PDF
                pdfArray={pdfArray.filter((item) => item.isShow)}
                setisCallFunc={setisCallFunc}
                isCallFunc={isCallFunc}
                ref={pdfViewerRef}
                toolbar={1}
                zoomSize={handlePDFZoom()}
                setCurrTotalPage={setCurrTotalPage}
              />
            )}
          </div>
        )
      ) : (
        <>
          <Row className="  text-center  d-flex justify-content-between">
            <Col sm={2} className="p-1  w-100">
              <h3 className="formsHeader">
                {" "}
                <span>Forms</span>
              </h3>
            </Col>
          </Row>
          <div className="formsContainer">
            <p className="OuterAllformsBtnContainer">
              <Button
                variant="light"
                className="  button-width "
                onClick={handleShowFormsSelect}
              >
                <span>
                  <IoDocumentsSharp />
                </span>{" "}
                <span>All Forms</span>
              </Button>
            </p>
            <Col className="outerSelectAllContainer">
              <div className="mb-3 " style={{ marginRight: "-1%" }}>
                <label
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllForms}
                    // onChange={handleSelectAll}
                    onChange={handleSelectForms}
                  />
                  <span className="outerSelectAllLabel">
                    {selectAllForms ? "Unselect All" : "Select All"}
                  </span>
                </label>
              </div>

              <Form className="outerCheckBoxElContainer">
                {labelCheckBox.length > 0 ? (
                  labelCheckBox?.map(({ id, label }) => (
                    <Form.Group key={id} className="outerCheckBoxEl">
                      <Form.Check
                        type="checkbox"
                        id={id}
                        checked={Formcheckboxes && Formcheckboxes[id]}
                        onChange={handleFormsCheckboxChange}
                        // label={label}
                        label={
                          <span
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   handleFormsCheckboxChange(null, id);
                            // }}
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              className={`custom-checkbox ${
                                Formcheckboxes[id] ? "OrangeCheckBox" : ""
                              }`}
                            />
                            {label}
                          </span>
                        }
                        className="form-option border-bottom  outerCheckBoxChild"
                      />
                      <span onClick={() => handleDeleteCheckBox(id)}>
                        <ImCross />
                      </span>
                    </Form.Group>
                  ))
                ) : (
                  <p>Please select checkboxes to show here</p>
                )}
              </Form>
            </Col>

            <Col className="d-flex flex-column justify-content-center align-items-between gap-4">
              <div className="  outerBtnContainer">
                <Button
                  variant="light"
                  className="  button-width"
                  onClick={handlePreviewSelect}
                  disabled={
                    (reduxForms && reduxForms?.length === 0) ||
                    (!Formcheckboxes.titleApp &&
                      !Formcheckboxes.salesTaxExemptionCertificate &&
                      !Formcheckboxes.powerOfAttorney &&
                      !Formcheckboxes.buyerGuide &&
                      !Formcheckboxes.rebuildTitleDisclosure &&
                      !Formcheckboxes.AgreementInsurance &&
                      !Formcheckboxes.billOfSale &&
                      !Formcheckboxes.odometerDisclosure &&
                      !Formcheckboxes.statementOfFacts &&
                      !Formcheckboxes.airbagDisclosure &&
                      !Formcheckboxes.serviceContract &&
                      !Formcheckboxes.countyOfTitleIssuance &&
                      !Formcheckboxes.creditBureauDisclosure &&
                      !Formcheckboxes.retailContractInstallment &&
                      !Formcheckboxes.accuracyInformationAcknowledment &&
                      !Formcheckboxes.rightToReposses && 
                      !Formcheckboxes.billOfSaleCustomer 
                    ) ||
                    isSomethingChanged
                  }
                >
                  <span>
                    {/* <FaRegEye /> */}
                    <RiFileSearchFill />
                  </span>
                  <span>Preview</span>
                </Button>
                <Button
                  variant="light"
                  className="  button-width "
                  onClick={handleShowFormsSelect}
                >
                  <span>
                    <IoDocumentsSharp />
                  </span>{" "}
                  <span>All Forms</span>
                </Button>
              </div>
            </Col>
          </div>
        </>
      )}
    </Container>
  );
}

export default Forms;
