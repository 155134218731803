import React, { useEffect, useState } from "react";
import styles from "./DealerAddManagerMobile.module.css";
import { SlSpeedometer } from "react-icons/sl";
import { LuArrowRight } from "react-icons/lu";
import { CiFilter } from "react-icons/ci";
import { IoIosInformationCircleOutline, IoMdArrowDropleft } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
const DealerAddmanagerMobile = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [isRotated, setIsRotated] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isRotatedModel, setIsRotatedModel] = useState(false);
  const [isInputVisibleModel, setIsInputVisibleModel] = useState(false);
  const [isRotatedBodyType, setIsRotatedBodyType] = useState(false);
  const [isInputVisibleBodyType, setIsInputVisibleBodyType] = useState(false);
  const [isRotatedSortBy, setIsRotatedSortBy] = useState(false);
  const [isInputVisibleSortBy, setIsInputVisibleSortBy] = useState(false);
  // -------------------data-------------------------------------------------------------
  const makes = [
    "Aucura",
    "Ford",
    "Mercedes",
    "Audi",
    "Audi Q5",
    "Audi Q7",
    "Audi Q8",
    "Akara SUV",
    "Akara Sedan",
    "Akara Hatchback",
    "Akara Coupe",
    "Akara Electric",
    "Mercedes A-Class",
    "Mercedes C-Class",
    "Mercedes E-Class",
    "Mercedes GLC",
    "Mercedes GLE",
    "Mercedes G-Class",
    "Mercedes S-Class",
    "Mercedes EQC"
]
const sortBy = [
  "Increasing",
  "Decreasing",
]

// ------------------------------------------------------------------------------------------
  const [years, setYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const handleSideBarFilter = () => {
    setIsFilterActive(true);
    setIsFilterVisible(true);
  };

  const handleCloseFilter = () => {
    setIsFilterActive(false);
    setTimeout(() => setIsFilterVisible(false), 300); // Matches the transition duration
  };
  //  for year filter---------------------------
  useEffect(() => {
    const yearArray = [];
    for (let year = 1996; year <= currentYear; year++) {
      yearArray.push(year);
    }
    setYears(yearArray);
  }, [currentYear]);

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };
  // ------------------------------------------------for make filter----------------------
  const handleArrowClick = () => {
    setIsRotated(!isRotated);
    setIsInputVisible(!isInputVisible);
  };
  // -----------------------------------------------for model filter------------------------------
  const handleArrowClickModel = () => {
    setIsRotatedModel(!isRotatedModel);
    setIsInputVisibleModel(!isInputVisibleModel);
  };
  // ---------------------------------------------- body type filter---------------------------------
  const handleArrowClickBodyType = () => {
    setIsRotatedBodyType(!isRotatedBodyType);
    setIsInputVisibleBodyType(!isInputVisibleBodyType);
  };
// ----------------------------------------------- sort by filter------------------------------------
const handleArrowClickSortBy = () => {
  setIsRotatedSortBy(!isRotatedSortBy);
  setIsInputVisibleSortBy(!isInputVisibleSortBy);
};
// ----------------------------------------------------------------------------------------------------
//  for media icons ---------------------------------
const [showPublish,setShowPublish] = useState(false);
const handlePublishMedia = () =>{
setShowPublish(true);
}
// --------------------------------------------------
  return (
    <div>
      {showPublish ? 
        <div>
          <div className={styles.publishContainer}>
          <button onClick={() => setShowPublish(false)}><FaArrowLeft /></button>
          </div>
          <div className={styles.publishVehicleName}>
            <div>Assets Info <IoIosInformationCircleOutline /> : <span style={{color:"#d1823a"}}>Vin : </span> ABCD1234567898765</div>
            <div>2016 FORD Transit SRW Medium Roof Van</div>
          </div>
        </div> : 
        <div className={styles.container}>
        <div className={styles.heading}>
          <h1>Summery</h1>
        </div>
        {/* counting for data */}
        <div className={styles.countingContainer}>
          <div className={styles.counting}>
            <div className={styles.active}>
              <h2>34</h2>
              <p>All Inventory</p>
            </div>
            <div className={styles.active}>
              <h2>2</h2>
              <p>Not Published</p>
            </div>
            <div className={styles.active}>
              <h2>27</h2>
              <p>Published</p>
            </div>
            <div className={styles.active}>
              <h2>17</h2>
              <p>Total Leads</p>
            </div>
          </div>
        </div>
        {/* counting for data end*/}
        {/* for filter search */}
        <div className={styles.filterCard}>
          <div className={styles.searchContainer}>
            <input
              type="text"
              placeholder="Search..."
              className={styles.searchInput}
            />
            <span className={styles.searchIcon}>
              {/* You can use an icon from a library like Font Awesome or Material Icons */}
              <i className="fas fa-search"></i>
            </span>
          </div>
          <div className={styles.filterIcons} onClick={handleSideBarFilter}>
            <CiFilter />
          </div>
        </div>
        {/* for card container */}
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.cardImageContainer}>
              <div className={styles.cardImage}>
                <img
                  src="https://www.kbb.com/wp-content/uploads/2022/08/2022-mercedes-amg-eqs-front-left-3qtr.jpg?w=918"
                  alt="car image"
                />
              </div>
              <div className={styles.cardImageText}>
                <span>Exterior Color</span>
                <span>Interior Color</span>
              </div>
            </div>
            <div className={styles.cardText}>
              <p className={styles.dateCreated}>Added : 11/02/2024</p>
              <h2 className={styles.vehicleName}>
                2016 Ford Transit SRW Medium Roof Van
              </h2>
              <h2>
                <span style={{ color: "#d1823a" }}>VIN : </span>
                1FTYR678911234567
              </h2>
              <h2 className={styles.mileageCount}>
                <span
                  style={{
                    color: "#d1823a",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <SlSpeedometer />
                </span>
                132,145 mi
              </h2>
              <span className={styles.rightArrowClick} onClick={handlePublishMedia}>
                <LuArrowRight />
              </span>
            </div>
          </div>
        </div>
      </div>
      }
      {/* for filter from side bar */}
      {isFilterVisible && (
        <div
          className={`${styles.filterContainer} ${
            isFilterActive ? styles.active : ""
          }`}
        >
          <div className={styles.filterHeader}>
            <h4>Filter</h4>
            <button onClick={handleCloseFilter} className={styles.closeButton}>
              X
            </button>
          </div>
          {/* Your filter content goes here */}
          {/* Year filter */}
          <div className={styles.yeraFilter}>
            <h4>Year</h4>
            <div>
              <div
                style={{
                  maxHeight: "100px",
                  overflowY: "auto",
                }}
              >
                {years.map((year) => (
                  <div
                    key={year}
                    onClick={() => handleYearClick(year)}
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      fontWeight:selectedYear === year ? "600" : "",
                      fontFamily:selectedYear === year ? "Alata" : "",
                      textAlign:"center",
                      backgroundColor:
                        selectedYear === year ? "#f0f0f0" : "transparent",
                    }}
                  >
                    {year}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* make filter */}
          <div className={styles.makeFilter}>
            <h4>Make</h4>
            <span
            style={{ transition: 'opacity 0.5s ease',
              visibility:isInputVisible? 'visible' : 'hidden',
              textAlign:"center"
             }}
            ><input type="text" placeholder="Search" style={{width:"90%"}}/></span>
            <span
            onClick={handleArrowClick}
            style={{
              display: 'inline-block',
              transition: 'transform 0.5s ease',
              transform: isRotated ? 'rotate(-90deg)' : 'rotate(0deg)',
            }}
            ><IoMdArrowDropleft style={{fontSize:"37px"}}/></span>
          </div>
          {isInputVisible && (
                <div className={styles.container_make}>
                    {makes.map((make, index) => (
                        <div key={index} className={styles.item_make}>
                            {make}
                        </div>
                    ))}
                </div>
            )}
          {/* Model Filter */}
          <div className={styles.modelFilter}>
            <h4>Model</h4>
            <span
            style={{ transition: 'opacity 0.5s ease',
              visibility:isInputVisibleModel? 'visible' : 'hidden',
              textAlign:"center"
             }}
            ><input type="text" placeholder="Search" style={{width:"90%"}}/></span>
            <span
            onClick={handleArrowClickModel}
            style={{
              display: 'inline-block',
              transition: 'transform 0.5s ease',
              transform: isRotatedModel ? 'rotate(-90deg)' : 'rotate(0deg)',
            }}
            ><IoMdArrowDropleft style={{fontSize:"37px"}}/></span>
          </div>
          {isInputVisibleModel && (
                <div className={styles.container_make}>
                    {makes.map((make, index) => (
                        <div key={index} className={styles.item_make}>
                            {make}
                        </div>
                    ))}
                </div>
            )}
          {/* Body Type Filter*/}
          <div className={styles.bodyTypeFilter}>
            <h4>Body Type</h4>
            <span
            style={{ transition: 'opacity 0.5s ease',
              visibility:isInputVisibleBodyType? 'visible' : 'hidden',
              textAlign:"center"
             }}
            ><input type="text" placeholder="Search" style={{width:"90%"}}/></span>
            <span
            onClick={handleArrowClickBodyType}
            style={{
              display: 'inline-block',
              transition: 'transform 0.5s ease',
              transform: isRotatedBodyType ? 'rotate(-90deg)' : 'rotate(0deg)',
            }}
            ><IoMdArrowDropleft style={{fontSize:"37px"}}/></span>
          </div>
          {isInputVisibleBodyType && (
                <div className={styles.container_make}>
                    {makes.map((make, index) => (
                        <div key={index} className={styles.item_make}>
                            {make}
                        </div>
                    ))}
                </div>
            )}
          {/* Sort By Type Filter*/}
          <div className={styles.sortByFilter}>
            <h4>Sort By</h4>
            <span
            style={{ transition: 'opacity 0.5s ease',
              visibility:isInputVisibleSortBy? 'visible' : 'hidden',
              textAlign:"center"
             }}
            ><input type="text" placeholder="Search" style={{width:"90%"}}/></span>
            <span
            onClick={handleArrowClickSortBy}
            style={{
              display: 'inline-block',
              transition: 'transform 0.5s ease',
              transform: isRotatedSortBy ? 'rotate(-90deg)' : 'rotate(0deg)',
            }}
            ><IoMdArrowDropleft style={{fontSize:"37px"}}/></span>
          </div>
          {isInputVisibleSortBy && (
                <div className={styles.container_make}>
                    {sortBy.map((sort, index) => (
                        <div key={index} className={styles.item_make}>
                            {sort}
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.filterFooterContainer}>
              <div className={styles.footerContant}>
                <button className={styles.footerContant_btn1}>Clean</button>
                <button className={styles.footerContant_btn2}>Done</button>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};

export default DealerAddmanagerMobile;
