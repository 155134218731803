import React, { useEffect, useState } from "react";
import styles from "../PaymentStage1.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const PaymentStage1 = ({ setPaymentStages, dataFromLocation }) => {
  console.log("Data from location => ", dataFromLocation);
  const { isEditing } = dataFromLocation.state;
  const { item: paymentData } = dataFromLocation.state;
  console.log(
    "isEditing => ",
    isEditing,
    " data to be updated => ",
    paymentData
  );

  const currentBalance = isEditing
    ? paymentData.dueBalance.toFixed(2)
    : paymentData.sellPriceFees?.balanceDue.toFixed(2);

  const dateParts = isEditing && paymentData.nextPaymentDate?.split("/"); // Assuming the input is in yyyy/mm/dd format
  const formattedDate =
    isEditing && dateParts?.length === 3
      ? `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      : "";

  const [formData, setFormsData] = useState({
    dueBalance: currentBalance,
    latePaymentFees: isEditing ? paymentData.latePaymentFees : "",
    payment: isEditing ? paymentData.payment : "",
    paymentMethod: isEditing ? paymentData.paymentMethod : "",
    nextPaymentDate: isEditing ? formattedDate : "",
    balanceDue: isEditing ? paymentData.balanceDue : "",
    notes: isEditing ? paymentData.notes : "",
    mscOthers: isEditing ? paymentData.mscOthers : "",
    reminder: isEditing ? paymentData.reminder : false,
    sendReceiptEmail: isEditing ? paymentData.sendReceiptEmail : false,
    sellId: "",
    userId: "",
    id: isEditing ? paymentData.id : 0,
  });

  useEffect(() => {
    let newBalanceDue;
    if (formData.payment) {
      newBalanceDue = formData.dueBalance - formData.payment;
      console.log("New balance data => ", newBalanceDue);
    } else {
      newBalanceDue = formData.dueBalance - 0;
      console.log("New balance data => ", newBalanceDue);
    }
    setFormsData((prevData) => ({
      ...prevData,
      balanceDue: parseInt(newBalanceDue)?.toFixed(2) || 0,
    }));
  }, [formData.payment]);
  console.log("Formdata => ", formData);
  const handlePaymentValuesChange = (e) => {
    const { name, value } = e.target;

    setFormsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveData = async () => {
    const dateParts = formData.nextPaymentDate.split("-"); // Assuming the input is in yyyy-mm-dd format
    const formattedDate =
      dateParts.length === 3
        ? `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
        : "";

    const dataToSend = {
      ...formData,
      id: isEditing ? parseInt(paymentData?.id) : 0,
      sellId: isEditing
        ? parseInt(paymentData?.sellId)
        : parseInt(paymentData?.id),
      userId: parseInt(paymentData?.userId),
      balanceDue: parseFloat(formData.balanceDue) || 0,
      dueBalance: parseFloat(currentBalance) || 0,
      payment: parseFloat(formData.payment) || 0,
      latePaymentFees: parseFloat(formData.latePaymentFees) || 0,
      mscOthers: parseFloat(formData.mscOthers) || 0,
      nextPaymentDate: formattedDate,
    };
    console.log("Data to be sent  to server", dataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/update_payment`,
        dataToSend
      );

      if (response.status === 200) {
        console.log("response from server", response);
        setPaymentStages("stage2");
      }
    } catch (error) {
      console.error("Error while sending data to backend", error);
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Link to="/dealer/inventory/balance">
            <IoMdArrowRoundBack />
          </Link>
          <span>Payment</span>
        </div>
        <div className={styles.dataContainer}>
          <p className={styles.dataContent}>
            <span>Payment</span>
            <input
              type="text"
              name="payment"
              onChange={handlePaymentValuesChange}
              value={formData.payment}
            />
          </p>
          <p className={styles.dataContent}>
            <span>Current Balance</span>
            <input type="text" name="dueBalance" value={formData.dueBalance} />
          </p>
          <p className={styles.dataContent}>
            <span>Late Payment Fees</span>
            <input
              type="text"
              name="latePaymentFees"
              onChange={handlePaymentValuesChange}
              value={formData.latePaymentFees}
            />
          </p>
          <p className={styles.dataContent}>
            <span>MSC and Others</span>
            <input
              type="text"
              name="mscOthers"
              onChange={handlePaymentValuesChange}
              value={formData.mscOthers}
            />
          </p>
          <p className={styles.dataContent}>
            <span>Total Payment</span>
            <input
              type="text"
              value={parseInt(
                parseInt(formData.latePaymentFees || 0) +
                  parseInt(formData.mscOthers || 0) +
                  parseInt(formData.payment || 0)
              ).toFixed(2)}
            />
          </p>
          <p className={styles.dataContent}>
            <span>Payment Method</span>
            <select
              id="paymentMethod"
              name="paymentMethod"
              onChange={handlePaymentValuesChange}
              value={formData.paymentMethod}
            >
              <option value="">Select a Payment Method</option>
              <option value="cash">Cash</option>
              <option value="cheque">Cheque</option>
              <option value="wire">Wire</option>
              <option value="card">Card</option>
              <option value="trade">Traded</option>
              <option value="other">Other</option>
              <option value="cashier_check">Cashier Check</option>
              <option value="finance">Finance Source</option>
              {/* Add more payment options as needed */}
            </select>
          </p>
          <p className={styles.dataContent}>
            <span>Next Payment Date</span>
            <input
              type="date"
              name="nextPaymentDate"
              onChange={handlePaymentValuesChange}
              value={formData.nextPaymentDate}
            />
          </p>
          <p className={styles.dataContent}>
            <span>Next Payment Amount</span>
            <input
              type="text"
              name="balanceDue"
              onChange={handlePaymentValuesChange}
              value={formData.balanceDue}
            />
          </p>
          <p className={styles.dataContent2}>
            <span>Notes</span>
            <textarea
              name="notes"
              onChange={handlePaymentValuesChange}
              rows={5}
              value={formData.notes}
            />
          </p>
        </div>
        <div className={styles.checkBoxContainer}>
          <label htmlFor="sendRemainder">
            <input
              id="sendRemainder"
              type="checkbox"
              checked={formData.reminder}
              onChange={() =>
                setFormsData((prevData) => ({
                  ...prevData,
                  reminder: !prevData.reminder,
                }))
              }
            />
            Send Remainder for the payment by email
          </label>
          <label htmlFor="getReceipt">
            <input
              id="getReceipt"
              type="checkbox"
              checked={formData.sendReceiptEmail}
              onChange={() =>
                setFormsData((prevData) => ({
                  ...prevData,
                  sendReceiptEmail: !prevData.sendReceiptEmail,
                }))
              }
            />
            Send Receipt Via Email
          </label>
        </div>
      </div>
      <button className={styles.saveBtnContainer} onClick={handleSaveData}>
        {isEditing ? "Update" : "Save"}
      </button>
    </div>
  );
};

export default PaymentStage1;
