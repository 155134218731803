import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  financeExtraTaxes: [
    { id: 1, label: "Documentation Fees", InputValue: 150.0 },
    { id: 3, label: "Drive Out Tag", InputValue: 5.0 },
    { id: 4, label: "TERP Title Fee", InputValue: 20 },
    { id: 5, label: "Buyer Tag", InputValue: 5 },
    { id: 7, label: "Wind-Shield Sticker", InputValue: 50.75 },
    { id: 8, label: "REG. FEE-DPS", InputValue: 1 },
    { id: 9, label: "CNTY ROAD Bridge Addon Fee", InputValue: 10 },
    { id: 10, label: "Child Safety Fee", InputValue: 1.5 },
    { id: 11, label: "Inspection Fee", InputValue: 25 },
    { id: 12, label: "Proc. & Handling Fee", InputValue: 4.75 },
    { id: 13, label: "Card Fee", InputValue: 0 },
    { id: 14, label: "Service Contract", InputValue: 0 },
    { id: 16, label: "Mail Delivery Fees", InputValue: 0 },
    { id: 17, label: "Transportation Fees", InputValue: 0 },
  ],
  extraTaxesTotal: 0,
  financeSaleType: "",
};
const calculateExtraTaxesTotal = (state) => {
  const sum = state.financeExtraTaxes.reduce((acc, item) => {
    const itemValue = parseFloat(item.InputValue) || 0;
    return acc + itemValue;
  }, 0);
  state.extraTaxesTotal = sum;
};

const financeSlice = createSlice({
  name: "financeFHBH",
  initialState,

  reducers: {
    setFinanceData: (state, action) => {
      state = { ...state, ...action.payload };
    },
    //Add a new tax in extra taxes
    addFinanceNewTax: (state, action) => {
      if (!state.financeExtraTaxes) {
        state.financeExtraTaxes = [];
      }
      state?.financeExtraTaxes?.push({
        id: action.payload.id,
        label: action.payload.label,
        InputValue: "",
      });
    },
    //Update the input field in the Price and fees
    updateFinanceExtraTaxInput: (state, action) => {
      const { index, value } = action.payload;
      state.financeExtraTaxes[index].InputValue = value;
      console.log("Update the sum ", state);
    },
    //Update the label  in the Price and fees
    updateFinanceExtraTaxLabel: (state, action) => {
      const { index, value } = action.payload;
      state.financeExtraTaxes[index].label = value;
    },

    //Delete the label  in the Price and fees
    deleteFinanceExtraTax: (state, action) => {
      const { index } = action.payload;
      state.financeExtraTaxes.splice(index, 1); // Remove item at index
    },

    //fetching the labels and setting it back here in finance
    setFinanceDynamicAPILabels: (state, action) => {
      state.financeExtraTaxes = action.payload;
    },

    resetFinanceExtraTaxes: (state) => {
      state = {
        ...state,
        financeExtraTaxes: [
          { id: 1, label: "Documentation Fees", InputValue: "" },
          { id: 2, label: "Inventory Tax", InputValue: "" },
          { id: 3, label: "Drive Out Tag", InputValue: "" },
          { id: 4, label: "TERP Title Fee", InputValue: "" },
          { id: 5, label: "Buyer Tag", InputValue: "" },
          { id: 7, label: "Wind-Shield Sticker", InputValue: "" },
          { id: 8, label: "REG. FEE-DPS", InputValue: "" },
          { id: 9, label: "CNTY ROAD Bridge Addon Fee", InputValue: "" },
          { id: 10, label: "Child Safety Fee", InputValue: "" },
          { id: 11, label: "Inspection Fee", InputValue: "" },
          { id: 12, label: "Proc. & Handling Fee", InputValue: "" },
          { id: 13, label: "Card Fee", InputValue: "" },
          { id: 14, label: "Service Contract", InputValue: "" },
          { id: 16, label: "Mail Delivery Fees", InputValue: "" },
          { id: 17, label: "Transportation Fees", InputValue: "" },
        ],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("financeFHBH/addFinanceNewTax", (state) => {
        calculateExtraTaxesTotal(state); // Calculate sum after adding a new tax
      })
      .addCase("financeFHBH/updateFinanceExtraTaxInput", (state) => {
        calculateExtraTaxesTotal(state); // Calculate sum after updating input value
      })
      .addCase("financeFHBH/updateFinanceExtraTaxLabel", (state) => {
        calculateExtraTaxesTotal(state); // Calculate sum after updating label
      })
      .addCase("financeFHBH/deleteFinanceExtraTax", (state) => {
        calculateExtraTaxesTotal(state); // Calculate sum after deleting a tax
      });
  },
});

export const {
  addFinanceNewTax,
  updateFinanceExtraTaxInput,
  updateFinanceExtraTaxLabel,
  deleteFinanceExtraTax,
  setFinanceData,
  setFinanceDynamicAPILabels,
  resetFinanceExtraTaxes,
} = financeSlice.actions;

export default financeSlice.reducer;
