import { configureStore } from "@reduxjs/toolkit";

import todoReducer from "./slice/todo";
import dataReducer from "./slice/data";
import profileimgReducer from "./slice/profileimg";
import addlistingdataReducer from "./slice/addlistingdata";
import userReducer from "./slice/userSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import sellReducer from "./slice/sellSlice";
import activeReducer from "./slice/activeSlice";
import taxesFeesReducer from "./slice/taxes&FeesSlice";
import fastReducer from "./slice/fastSlice";
import globalStyleReducer from "./slice/globalStyleSlice";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import crmReducer from "./slice/crmSlice";
import financeReducer from "./slice/financeSlice";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist/es/constants";
import { createLogger } from "redux-logger";

const logger = createLogger({
  diff: true,
  collapsed: true,
  predicate: (getState, action) => true, // log all actions
  level: "log", // set the log level to 'log'
  logger: console, // use the console logger
  logErrors: true, // log errors
  collapsed: (getState, action, logEntry) => !logEntry.error, // collapse log entries without errors
  stateTransformer: (state) => state, // optional: transform state for logging
  actionTransformer: (action) => {
    if (!action) {
      console.warn("Action is undefined");
      return { type: "UNDEFINED_ACTION" }; // Fallback action
    }

    if (!action.type) {
      console.warn("Action is missing type:", action);
      return { ...action, type: "MISSING_TYPE_ACTION" }; // Fallback action
    }

    const error = new Error();
    const fileName = error.stack.split("\n")[1].trim().split(":")[0];
    // action.meta = { ...action.meta, fileName };
    return action;
  },
});

const rootReducer = combineReducers({
  todo: todoReducer,
  data: dataReducer,
  profileimg: profileimgReducer,
  addlistingdata: addlistingdataReducer,
  user: userReducer,
  sell: sellReducer,
  active: activeReducer,
  taxesAndFees: taxesFeesReducer,
  fast: fastReducer,
  globalStyle: globalStyleReducer,
  crm: crmReducer,
  finance: financeReducer,
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // .concat(logger),
});

const persistor = persistStore(store);

export { store, persistor };
