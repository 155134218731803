import Image from "../../imgData";
import { CiSettings } from "react-icons/ci";
export const DealerSideBarArray = [
  {
    id: 1,
    name: "Dashboard",
    route: "/dealer/dashboard",
    image: Image.sidebarHome,
  },
  // {
  //   id: 2,
  //   name: "Mail",
  //   route: "/dealer/mail",
  //   image: Image.dealer_email,
  // },

  {
    id: 11,
    name: "Add Inventory",
    route: "/dealer/inventory/add",
    image: Image.addList,
    // children: [
    //   {
    //     id: 112,
    //     name: "Vehicle Specification",
    //     route: "/dealer/inventory/add/vehicleSpecification",
    //     image: Image.billingAdd,
    //   },
    //   {
    //     id: 113,
    //     name: "Equipments",
    //     route: "/dealer/inventory/add/equipments",
    //     image: Image.dealer_forms,
    //   },
    //   {
    //     id: 114,
    //     name: "Seller Description",
    //     route: "/dealer/inventory/add/sellerDescription",
    //     image: Image.dealer_forms,
    //   },
    //   {
    //     id: 115,
    //     name: "Add Cost",
    //     route: "/dealer/inventory/add/addCost",
    //     image: Image.dealer_forms,
    //   },
    //   {
    //     id: 116,
    //     name: "Title Info",
    //     route: "/dealer/inventory/add/titleInfo",
    //     image: Image.dealer_forms,
    //   },
    //   {
    //     id: 117,
    //     name: "Finance",
    //     route: "/dealer/inventory/add/finance",
    //     image: Image.dealer_forms,
    //   },
    // ],
  },
  {
    id: 112,
    name: "Active Inventory",
    route: "/dealer/inventory/active",
    image: Image.activeListing,
  },
  {
    id: 1130,
    name: "Fast",
    // route: "/dealer/fast/quote/fast-quote",
    image: Image.billingAdd,
    children: [
      {
        id: 1131,
        name: "Fast Quote",
        route: "/dealer/fast/quote/fast-quote",
        image: Image.billingAdd,
      },
      {
        id: 1132,
        name: "Tax-Certificate",
        route: "/dealer/fast/quote/tax-certificate",
        image: Image.dealer_forms,
      },
      {
        id: 1133,
        name: "Draft",
        route: "/dealer/fast/quote/draft",
        image: Image.dealer_forms,
      },
      {
        id: 1134,
        name: "Forms",
        route: "/dealer/fast/quote/forms",
        image: Image.dealer_forms,
      },
    ],
  },
  {
    id: 12,
    name: "Sell Inventory",
    route: "/dealer/sell",
    image: Image.Sell,
    // children: [
    //   {
    //     id: 121,
    //     name: "Vehicle List",
    //     route: "/dealer/sell/vehicleList",
    //     image: Image.billingAdd,
    //   },

    //   {
    //     id: 122,
    //     name: "Sale Type",
    //     route: "/dealer/sell/saleType",
    //     image: Image.dealer_user,
    //   },
    //   {
    //     id: 123,
    //     name: "Buyer Info",
    //     route: "/dealer/sell/buyer",
    //     image: Image.dealer_information,
    //   },
    //   {
    //     id: 124,
    //     name: "Price and Fees",
    //     route: "/dealer/sell/priceFees",
    //     image: Image.dealer_information,
    //   },
    //   {
    //     id: 125,
    //     name: "Forms",
    //     route: "/dealer/sell/forms",
    //     image: Image.dealer_information,
    //   },
    // ],
  },

  {
    id: 113,
    name: "Sold Inventory",
    route: "/dealer/inventory/sold",
    image: Image.boughtSold,
  },
  {
    id: 6,
    name: "Active Balance",
    route: "/dealer/inventory/balance",
    image: Image.boughtSold,
  },
  {
    id: 122,
    name: "Media Manager",
    route: "/dealer/dealer_add_manager",
    image: Image.sidebarAddManager,
  },
  {
    id: 4,
    name: "Dealer Resources",
    route: "/dealer/dealer-resources",
    image: Image.dealer_master,
  },
  {
    id: 3,
    name: "Settings",
    route: "/dealer/dealer-master",
    image: `https://cdn.icon-icons.com/icons2/2761/PNG/512/settings_icon_176440.png`,
    children: [
      {
        id: 5,
        name: "Users",
        route: "/dealer/users",
        image: Image.dealer_user,
      },
      {
        id: 32,
        name: "Billing",
        route: "/dealer/dealer-master/taxes-fees/retail",
        image: Image.billingAdd,
      },
      {
        id: 31,
        name: "Dealer-Information",
        route: "/dealer/dealer-master/bussiness-information",
        image: Image.dealer_information,
      },
    ],
  },
  {
    id: 23,
    name: "Envelop",
    route: "/dealer/dealer-master",
    image: `https://cdn.icon-icons.com/icons2/2761/PNG/512/settings_icon_176440.png`,
    children: [
      {
        id: 231,
        name: "Create",
        route: "/dealer/dealer-master/taxes-fees/retail",
        image: Image.billingAdd,
      },

      {
        id: 232,
        name: "History",
        route: "/dealer/users",
        image: Image.dealer_user,
      },
    ],
  },
  {
    id: 114,
    name: "Draft",
    route: "/dealer/inventory/draft",
    image: Image.sidebarDraft,
  },
  // {
  //   id: 115,
  //   name: "Import & Export",
  //   route: "/dealer/inventory/import-export",
  //   image: Image.dealer_import_export,
  // },
  {
    id: 116,
    name: "Inventory Tax Maker",
    route: "/dealer/inventory/tax-maker",
    image: Image.dealer_inventory_tax_maker,
  },

  {
    id: 13,
    name: "Website",
    route: "/dealer/website",
    image: Image.sidebarHome,
  },
  // {
  //   id: 131,
  //   name: "HR Manager",
  //   route: "/dealer/website/hr-manager",
  //   image: Image.sidebarHome,
  // },
  {
    id: 132,
    name: "CRM/Leeds",
    // route: "/dealer/crmLeeds",
    image: Image.leads,
    children: [
      // {
      //   id: 1321,
      //   name: "All",
      //   route: "/dealer/crmLeeds/all",
      //   image: Image.billingAdd,
      // },
      // {
      //   id: 1322,
      //   name: "Search",
      //   route: "/dealer/crmLeeds/search",
      //   image: Image.dealer_forms,
      // },
      {
        id: 1323,
        name: "Loan App",
        route: "/dealer/crmLeeds/loanApp",
        image: Image.dealer_forms,
      },
      {
        id: 1330,
        name: "Quotation",
        route: "/dealer/crmLeeds/quotation",
        image: Image.sidebarOffers,
      },
      {
        id: 1324,
        name: "Leads Information",
        route: "/dealer/crmLeeds/emailLeeds",
        image: Image.dealer_forms,
      },
      // {
      //   id: 1325,
      //   name: "Messages",
      //   route: "/dealer/crmLeeds/messages",
      //   image: Image.sidebarOffers,
      // },
      // {
      //   id: 1326,
      //   name: "Calls",
      //   route: "/dealer/crmLeeds/calls",
      //   image: Image.sidebarOffers,
      // },
      {
        id: 1327,
        name: "Offers",
        route: "/dealer/crmLeeds/offers",
        image: Image.sidebarOffers,
      },
      {
        id: 1328,
        name: "Users",
        route: "/dealer/crmLeeds/usersReport",
        image: Image.sidebarOffers,
      },
      // {
      //   id: 1329,
      //   name: "Services",
      //   route: "/dealer/crmLeeds/services",
      //   image: Image.sidebarOffers,
      // },
      {
        id: 1331,
        name: "Contact",
        route: "/dealer/crmLeeds/contactInfo",
        image: Image.sidebarOffers,
      },
      
      
    ],
  },
  {
    id: 133,
    name: "Help & Support",
    route: "/dealer/help_desk",
    image: Image.support_icon,
  },
  // {
  //   id: 134,
  //   name: "Dealer Resources",
  //   route: "/dealer/inventory/active",
  //   image: Image.activeListing,
  // },
  {
    id: 14,
    name: "Recycle Bin",
    route: "/dealer/recycle-bin",
    image: Image.sidebarRecycleBin,
  },
  {
    id: 15,
    name: "Logout",
    route: "/",
    image: Image.logouta,
  },
];
