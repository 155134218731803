import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./UsersMobile.module.css";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { FaCircleArrowRight } from "react-icons/fa6";

const UsersMobile = () => {
  const { dealerId } = useSelector((state) => state.user);
  // const [loanCardDataArray, setLoanCardDataArray] = useState([]);
  // console.log("loanCardDataArray => ", loanCardDataArray);
  //  dummy data  for mobile show----------------------------------------
  const loanCardDataArray = [
    {
      asset: "2014 Mercedes Sprinter",
      offered: "5,500.00",
      name: "Niraj Kumar",
      mobileNumber: "9752467788",
      email: "niraj@gmail.com",
      address: "Taxes , USA, Collin",
    },
    {
      asset: "2014 BMW Sprinter",
      offered: "5,576.00",
      name: "Sumit Kumar Rana",
      mobileNumber: "3456654575",
      email: "sumit@gmail.com",
      address: "Taxes , USA, Collin",
    },
    {
      asset: "2014 ODI Sprinter",
      offered: "5,556.00",
      name: "Afjal Khan",
      mobileNumber: "345646324575",
      email: "afjal@gmail.com",
      address: "Taxes , USA, Collin",
    },
  ];

  return (
    <>
      <div className={styles.crmMwrapper}>
        {loanCardDataArray &&
          loanCardDataArray?.map((item, index) => (
            <div className={styles.crmMcontainer}>
              <div className={styles.crmMHeaderMain}>
                <div className={styles.crmMHeader}>
                  <span>User Id</span>
                  <span>Gadha</span>
                </div>
              </div>
              <div className={styles.crmMPersonalWrapper}>
                <div className={styles.crmMPersonalDetails}>
                  <p className={styles.crmMDetail}>
                    <span>Sent Message</span>
                    {/* <span>{item.name}</span> */}
                    <span>12</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Outgoing Call</span>
                    <span>34</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Incoming Call</span>
                    <span>56</span>
                  </p>
                </div>
                <div className={styles.crmMPersonalDetails} style={{gap:"2.3rem"}}>
                  <p className={styles.crmMDetail}>
                    <span>Loan App access</span>
                    {/* <span>{item.name}</span> */}
                    <span>34</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Offers</span>
                    <span>76</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Quotation Sent</span>
                    <span>21</span>
                  </p>
                </div>
                <div className={styles.crmMPersonalDetails} style={{gap:"2.3rem"}}>
                  <p className={styles.crmMDetail}>
                    <span>Appointment</span>
                    {/* <span>{item.name}</span> */}
                    <span>5</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Login Hours</span>
                    <span>43</span>
                  </p>
                </div>
                <div className={styles.crmMBtnContainer}>
                  <button>View</button>
                </div>
              </div>
              <span className={styles.crmMNumbering}>{index + 1}</span>
            </div>
          ))}
      </div>
    </>
  );
};

export default UsersMobile;
