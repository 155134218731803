import React, { useEffect, useState } from "react";
import Image from "../imgData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderData from "./ExtraFeaturesData";
import "./ExtraFeatureDesktop.css";

const ExtraFeaturesDesktop = () => {
  const imageArray = [
    Image.digitalContracting,
    Image.getOfferFromVerifiedDealer,
    Image.getOfferFromVerifiedDealer,
    Image.vehiclemarketValue,
    Image.vehiclemarketValue,
    Image.vehicleHistoryReport,
  ];
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const extendedSliderData = [...sliderData, ...sliderData.slice(0, 3)];
  const extendedImageArray = [...imageArray, ...imageArray.slice(0, 3)];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === sliderData.length - 3) {
          setIsTransitioning(true);
          setTimeout(() => {
            setIsTransitioning(false);
            setCurrentIndex(0);
          }, 500);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="efdWrapper">
      <div className="slider-container2" style={{ overflow: "visible" }}>
        <div
          className="slider-track2"
          style={{
            display: "flex",
            transition: isTransitioning ? "none" : "transform 0.5s ease-in",
            transform: `translateX(-${currentIndex * 28.33}%)`,
            // transform: `translateX(-${
            //   currentIndex * handleSliderPerc(window.innerWidth)
            // }%)`,
          }}
        >
          {extendedSliderData.map((data, index) => (
            <div
              className="efdContainerDesktop"
              key={index}
              style={{
                transform: `scale(${index - currentIndex === 1 ? 1.25 : 1})`,
                transition: "transform 0.3s ease",
              }}
            >
              <img
                src={extendedImageArray[index]}
                alt={data.title}
                className="efdImage"
              />
              <h4 className="efdTitle">{data.title}</h4>
              <p className="efdDesc">{data.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExtraFeaturesDesktop;
