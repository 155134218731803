import axios from "axios";

const getIPAddress = async () => {
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  } catch (error) {
    console.error("Error fetching IP information:", error);
    return "";
  }
};
export default getIPAddress;
