import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "../imgData";
import sliderData from "./ExtraFeaturesData";
import "./ExtraFeatureDesktop.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ExtraFeaturesMobile = () => {
  // Custom Previous Arrow Component
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        {/* Replace with your custom icon or SVG */}
        <span>
          <FaChevronLeft />
        </span>{" "}
        {/* Example: Left arrow symbol */}
      </div>
    );
  };

  // Custom Next Arrow Component
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        {/* Replace with your custom icon or SVG */}
        <span>
          <FaChevronRight />
        </span>{" "}
        {/* Example: Right arrow symbol */}
      </div>
    );
  };
  // Slider Settings with Comprehensive Configuration
  const settings = {
    // dots: true,              // Show navigation dots
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 4, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll
    // autoplay: true,          // Auto play slides
    autoplaySpeed: 3000, // Auto play interval
    arrows: true, // Show navigation arrows
    // centerMode: true, // Center the active slide
    // centerPadding: "60px",
    prevArrow: <CustomPrevArrow />, // Use custom previous arrow
    nextArrow: <CustomNextArrow />, // Use custom next arrow
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Image Array for Slider
  const imageArray = [
    Image.digitalContracting,
    Image.getOfferFromVerifiedDealer,
    Image.getOfferFromVerifiedDealer,
    Image.vehicleOwnerShipCost,
    Image.vehiclemarketValue,
    Image.vehicleHistoryReport,
  ];

  return (
    <div className="efdWrapper">
      <h1>Services</h1>

      {/* Comprehensive Slider Wrapper */}
      <div
        className="slider-container"
        style={{ width: "100%", maxWidth: "600px" }}
      >
        <Slider {...settings}>
          {sliderData.map((data, index) => (
            <div key={index} className="efdContainer">
              <img
                src={imageArray[index]}
                alt={data.title}
                className="efdImage"
              />
              <h4 className="efdTitle">{data.title}</h4>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ExtraFeaturesMobile;
