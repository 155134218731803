import React, { useEffect, useState } from "react";
import styles from "./DealerCRMLeeds.module.css";
import CRMsheet from "./CRMsheet/CRMsheet";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import QutationTable from "./CRMsheet/QutationTable";
import Contact from "./CRMsheet/Contact";
import { useSelector } from "react-redux";
import CRMLoanMobileData from "./CRMsheet/CRMLoanMobileData";
import EmailLeads from "./CRMsheet/EmailLeads";
import Offers from "./CRMsheet/Offers";
import Users from "./CRMsheet/Users";
import ContactMobile from "./CRMsheet/ContactMobile";
import OffersMobile from "./CRMsheet/OffersMobile";
import { FaCircleInfo } from "react-icons/fa6";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useDispatch } from "react-redux";
import EmailLeadsMobile from "./CRMsheet/EmailLeadsMobile";
import UsersMobile from "./CRMsheet/UsersMobile";

const DealerCRMLeeds = () => {
  const dispatch = useDispatch();
  const crmLoanLenght = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.crmLoanLenght) || 0
  );
  const crmQuoteLenght = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.crmQuoteLenght) || 0
  );
  const tabContentArray = [
    // { tabName: "All", tabId: "all" },
    // { tabName: "Search", tabId: "search" },
    { tabName: "Loan App", tabId: "loanApp", countNo: crmLoanLenght },
    { tabName: "Quotation", tabId: "quotation", countNo: crmQuoteLenght },
    { tabName: "Leads", tabId: "emailLeeds", countNo: "2" },
    // { tabName: "Messages", tabId: "messages",countNo : "0" },
    // { tabName: "Calls", tabId: "calls",countNo : "0" },
    { tabName: "Offers", tabId: "offers", countNo: "2" },
    { tabName: "Users", tabId: "usersReport", countNo: "2" },
    // { tabName: "Services", tabId: "services",countNo : "0" },
    { tabName: "Contact", tabId: "contactInfo", countNo: "2" },
  ];
  const [tabSelected, settabSelected] = useState("loanApp");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Add event listener for window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    handleResize();
    // Add event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [textNav,setTextNav] = useState("CRM/Leeds");
  const viewContent = (tabSelected) => {
    switch (tabSelected) {
      // case "all":
      //   return "";
      case "loanApp":
        return screenWidth > 600 ? <CRMsheet /> : <CRMLoanMobileData />;
      case "emailLeeds":
        return screenWidth > 600 ? <EmailLeads /> : <EmailLeadsMobile />;
        case "offers":
        return screenWidth > 600 ?  <Offers /> : <OffersMobile />;
        case "usersReport":
        return screenWidth > 600 ? <Users /> : <UsersMobile />;
      case "quotation":
        return <QutationTable />;
      case "contactInfo":
        return screenWidth > 600 ? <Contact /> : <ContactMobile />;
    }
    setTextNav("CRM/Leeds");
  };
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.replace("/dealer/crmLeeds/", "");
    const route = tabContentArray.find((tab) => tab.tabId === path);
    if (route) {
      settabSelected(path);
    } else {
      settabSelected("loanApp"); // Default tab if path doesn't match
    }
  }, [location.pathname]);
  const navigate = useNavigate();
  const handleTabChange = (tab) => {
    console.log("Navigating to => ", tab);
    settabSelected(tab.tabId);
    navigate(`/dealer/crmLeeds/${tab.tabId}`);
  };
useEffect(() => {
    dispatch(
      saveUser({
        sideBarItemName: textNav,
      })
    );
  }, [textNav]);
  return (
    <div className={styles.wrapper}>
      <div style={{ display: "flex", justifyContent: "flex-end" }} className={styles.parentSearch}>
        <div className={`input-group mb-3 ${styles.CRMsearchBar}`}>
          <button
            className="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ color: "white" }}
          >
            All
          </button>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Separated link
              </a>
            </li>
          </ul>
          <input
            type="text"
            className="form-control"
            aria-label="Text input with dropdown button"
            style={{ border: "1px solid #d1823a" }}
          />
        </div>
      </div>
      <div className={styles.container}>
        {screenWidth > 600 && (
          <div className={styles.tabPanelContainer}>
            {tabContentArray.map((tab, index) => (
              <div
                key={tab.tabId}
                className={styles.tabPanel}
                onClick={() => handleTabChange(tab)}
                style={{
                  backgroundColor: tabSelected === tab.tabId ? "#ba681d" : "",
                  color: tabSelected === tab.tabId ? "white" : "",
                  border: tabSelected === tab.tabId ? "none" : "",
                }}
              >
                <span>
                  {" "}
                  {tab.tabName} {tab.tabName === "Leads" ? <FaCircleInfo title="Leads Information"/> : ""} ({tab.countNo})
                </span>
                {/* {index === 1 ? (
                <span>
                  <input type="text" />
                </span>
              ) : (
                <span> {tab.tabName}</span>
              )} */}
              </div>
            ))}
          </div>
        )}

        <div className={styles.tabPanelContent}>{viewContent(tabSelected)}</div>
      </div>
    </div>
  );
};

export default DealerCRMLeeds;
