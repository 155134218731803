//------------------------------------------------------------------------------------------------------------------------

import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./dasbord.css";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setProfileimg } from "../../Redux/slice/profileimg";
import { CiCirclePlus } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import Image from "../imgData";
import { MdVerifiedUser } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { IoMdCloseCircle } from "react-icons/io";
import { fetchGeoMapData } from "./geoAPI";
import { MdMyLocation } from "react-icons/md";
import {
  logoutUser,
  resetAllSidebarCounts,
  saveUser,
  setShowUserCompleteProfileDialogueBox,
} from "../../Redux/slice/userSlice";
import { resetSellData } from "../../Redux/slice/sellSlice";
import { LoadScript, Autocomplete } from '@react-google-maps/api';
// import filePath from '../../../../../../'
// C:/Share-allUsers/temp/
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;
// import { MdMyLocation } from "react-icons/md";

const CustomerDetails = () => {
  // Session Time out for 15 minutes--------------/-----------------------
  const navigate = useNavigate();

  // --------------------------------------------

  const dispatch = useDispatch();

  useEffect(() => { }, []);

  const [file, setFile] = useState(null);
  const [filePreview, setfilePreview] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [loginDetails, setLoginDetails] = useState([]);
  console.log("login Details ", loginDetails);
  console.log(file);
  const { id } = useSelector((state) => state.user);
  console.log(
    "id of the user from the store in customer details component",
    id
  );

  const [formData, setFormData] = useState({
    id: id,
    firstName: "",
    lastName: "",
    email: "",
    office_number: "",
    phoneNumber: "",
    website: "",
    address: "",
    zip_code: "",
    lattitude: "",
    longitude: "",
    sms_notify: false,
    email_notify: false,
    two_factor_authentication: false,
    userLoginHistory: [],
    ms_auth: false,
    ip: "",
  });
  console.log(formData);
  console.log("formData.userLoginHistory", formData.userLoginHistory);
  const [saveClicked, setSaveClicked] = useState(false);

  const [number_email_verified, set_number_email_verified] = useState({
    emailverified: false,
    phoneVerified: false,
  });



  //   sending the user Id to the backend
  const sendUserId = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/get_profile`,
        {
          id: id,
        }
      );

      if (res.status !== 200) {
        console.log("User Id is not sent to the server");
      }
      const { data } = res;
      console.log("Response from API to get user data when loading", data);
      setFormData((prevData) => ({
        ...prevData,

        firstName: data.firstName || "",
        email: data.email || "",
        address: data.address || "",
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        lattitude: data.lattitude || "",
        longitude: data.longitude || "",
        office_number: data.office_number || "",
        phoneNumber: data.phoneNumber || "",
        website: data.website || "",
        sms_notify: data.sms_notify || false,
        two_factor_authentication: data.two_factor_authentication || false,
        zip_code: data.zip_code || "",
        email_notify: data.email_notify || false,
        userLoginHistory: res.data.userLoginHistories.reverse().slice(0, 5),
      }));

      setfilePreview(
        data.profile_picture
          ? `${imgUrl}/${id}/${data.profile_picture}`
          : null
      );
      // setfilePreview(data.profile_picture);
      setFile(data.profile_picture);
      if (data.loginType === 1) {
        set_number_email_verified((prevData) => ({
          ...prevData,
          emailverified: true,
          phoneVerified: false,
        }));
      } else if (data.loginType === 0) {
        set_number_email_verified((prevData) => ({
          ...prevData,
          emailverified: false,
          phoneVerified: true,
        }));
      }
      console.log("User Id is  sent to the server successfully", res);
      setLoginDetails(res.data.userLoginHistories);
    } catch (error) {
      console.log(
        "Error while sending the Id of the user to the server",
        error
      );
    }
  };

  useEffect(() => {
    sendUserId();
  }, []);
  const handleFileChange = (e) => {
    const selectedfile = e.target.files[0];
    if (selectedfile) {
      setFile(selectedfile);
      setIsImageSelected(true);

      // Validate file size (not more than 3 MB)
      if (selectedfile.size > 2 * 1024 * 1024) {
        console.log("File size should not exceed 3 MB");
        return;
      }

      // Validate file type (JPG or PNG)
      if (
        !["image/jpeg", "image/png", "image/webp", "image/gif"].includes(
          selectedfile.type
        )
      ) {
        console.log("File format should be JPG or PNG");
        return;
      }

      const previewurl = URL.createObjectURL(selectedfile);
      setfilePreview(previewurl);
    } else {
      setIsImageSelected(false);
    }
  };

  const handleRemoveSelectedImage = () => {
    setFile(null);
    setfilePreview(null);
    setIsImageSelected(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleYesNoSms = () => {
    setFormData((prevData) => ({
      ...prevData,
      sms_notify: !prevData.sms_notify,
    }));
  };
  const handleYesNoEmail = () => {
    setFormData((prevData) => ({
      ...prevData,
      email_notify: !prevData.email_notify,
    }));
  };
  const handleYesNo2FA = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      two_factor_authentication: e.target.value === "enabled",
    }));
  };
  const handleYesNomsauth = () => {
    setFormData((prevData) => ({
      ...prevData,
      ms_auth: !prevData.ms_auth,
    }));
  };

  const handleUpdateuserInfo = async () => {
    setSaveClicked(true);
    const fd = new FormData();

    fd.append("profile_picture", file);
    fd.append("data", JSON.stringify(formData));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/update_profile`,
        fd
      );

      console.log("response after updating the user details from backend", res);
      if (res.status === 200) {
        dispatch(
          saveUser({
            email: res.data.email,
            id: res.data.id,
            phoneNumber: res.data.phoneNumber,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            office_number: res.data.office_number,
            lattitude: res.data.lattitude,
            longitude: res.data.longitude,
            zip_code: res.data.zip_code,
            address: res.data.address,
            profile_picture: res.data.profile_picture,
            website: res.data.website,
            roles: res.data.roles,
          })
        );

        setTimeout(() => {
          setSaveClicked(false);
        }, 2000);
      }
    } catch (error) {
      console.log("Error 500 while updating the user information");
      setTimeout(() => {
        setSaveClicked(false);
      }, 2000);
    }
  };

  const handleGMapAPI = async () => {
    try {
      const res = await fetchGeoMapData();

      console.log("Response of GEO API", res);
      setFormData((prevData) => ({
        ...prevData,

        address: res.formattedAddress || "",
        lattitude: res.coordinates.lat || "",
        longitude: res.coordinates.lng || "",
        zip_code: res.zipCode || "",
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getUserIPaddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log("User IP Address", res);
    setFormData((prevData) => ({
      ...prevData,
      ip: res.data.ip,
    }));
  };
  useEffect(() => {
    getUserIPaddress();
  }, [formData.ip]);

  const handleDeactivateUser = async () => {
    const fd = new FormData();
    fd.append("id", id);
    fd.append("ip", formData.ip);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/deactivate_account`,
        fd
      );
      console.log(res);
      if (res.status === 200) {
        console.log("Deactivated the user with status 200");

        console.log("Dispatching resetAllSidebarCounts");
        dispatch(resetAllSidebarCounts());

        console.log("Dispatching resetSellData");
        dispatch(resetSellData());

        console.log("Removing user_sidebar_activity from localStorage");
        localStorage.removeItem("user_sidebar_activity");

        console.log("Dispatching logoutUser");
        dispatch(logoutUser());
      }
    } catch (error) {
      console.log(error);
    }
  };


  const libraries = ['places'];



  const [address, setAddress] = useState('');

  const autocompleteRef = useRef(null);
  const handlePlaceSelect = () => {

    const addressObject = autocompleteRef.current.getPlace();

    if (addressObject.formatted_address) {

      setAddress(addressObject.formatted_address);

    }

  };
  const detectLocation = () => {

    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(

        (position) => {

          const { latitude, longitude } = position.coords;

          const geocoder = new window.google.maps.Geocoder();

          const latlng = { lat: latitude, lng: longitude };



          geocoder.geocode({ location: latlng }, (results, status) => {

            if (status === 'OK') {

              if (results[0]) {

                setAddress(results[0].formatted_address);

              } else {

                alert('No results found');

              }

            } else {

              alert('Geocoder failed due to: ' + status);

            }

          });

        },

        () => {

          alert('Unable to retrieve your location');

        }

      );

    } else {

      alert('Geolocation is not supported by your browser');

    }

  };

  return (
    <>
      <div className="main-panel mt-md-0 ">
        <div className="content-wrapper mt-md-0 mt-5">
          <div className="border p-md-4 rounded mt-lg-0 mt-md-5 mt-5">
            <div className="container-fluid">
              <div className="  profile_verify_container">
                <div className=" profile_image_container">
                  <p>Profile Picture</p>
                  <img
                    src={filePreview !== null ? filePreview : Image.upload_icon}
                    //   src={`data:profilePicture/jpeg;base64,${img.profilePicture}`}
                    alt="profile picture"
                    className="profile_image"
                    onClick={() =>
                      document.getElementById("imagechoosebutton").click()
                    }
                  />
                  {isImageSelected ? (
                    <span
                      className="delete_button"
                      onClick={handleRemoveSelectedImage}
                    >
                      <MdDelete />
                    </span>
                  ) : null}

                  <div className="file-input-wrapper">
                    <input
                      type="file"
                      name="profilePicture"
                      onChange={handleFileChange}
                      id="imagechoosebutton"
                    />
                  </div>
                </div>

                <div className="verify_image">
                  <button
                    className='btn-change5'
                    id="bottom-save-btn"
                    style={{
                      backgroundColor: saveClicked ? "gray" : "#d1823a",
                      cursor: saveClicked ? "not-allowed" : "",
                      marginLeft: "2rem"
                    }}
                    disabled={saveClicked}
                    onClick={handleUpdateuserInfo}
                  >
                    SAVE
                  </button>
                </div>
              </div>
              <form name="profile" id="profile">
                <div className="row">
                  <div className="col-md-4 my-3">
                    <div className="input-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="input-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="input-fields">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email ID"
                        name="email"
                        value={formData.email}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="input-fields">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Phone"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleFormChange}
                        maxLength={10}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="input-fields">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Office Number (Optional)"
                        name="office_number"
                        value={formData.office_number}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="input-fields">
                      <input
                        type="url"
                        className="form-control"
                        placeholder="Website (Optional)"
                        name="website"
                        value={formData.website}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-8 my-3">
                    <div className="input-fields full_address_container">
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Full Address"
                        name="address"
                        value={formData.address}
                        onChange={handleFormChange}
                      /> */}
                      {/* <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        // googleMapsApiKey="AIzaSyAidNgKafDZvB6960WgN84sAV9hHqRYGYY"
                        libraries={libraries}
                      > */}
                        <div>
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (autocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={handlePlaceSelect}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your address"
                              value={formData.address}
                              onChange={handleFormChange}
                              name="address"
                            />
                          </Autocomplete>
                        </div>
                      {/* </LoadScript> */}

                      <span
                        className="location_button"
                        onClick={handleGMapAPI}
                        style={{ cursor: "pointer" }}
                      >
                        <MdMyLocation />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="input-fields">
                      <input
                        type=""
                        className="form-control"
                        placeholder="ZIP"
                        name="zip_code"
                        value={formData.zip_code}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 my-3">
                    <div className="input-fields">
                      <input
                        type="text"
                        id="lattitude"
                        className="form-control"
                        placeholder="Latitude"
                        name="lattitude"
                        value={formData.lattitude}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 my-3">
                    <div className="input-fields">
                      <input
                        type="text"
                        name="longitude"
                        id="longitude"
                        className="form-control"
                        placeholder="Longitude"
                        value={formData.longitude}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/*-----------------------------Detail form page End-------------------------------------*/}

          <div className="container-fluid mt-5">
            {/*------------------------------Contact Information Verification Dropdown list Start-------------------------- */}

            <div className="parent_wrappers" tabIndex="0">
              {/* <div className="contact_information_verification_wrapper">
                <div className="contact_information_verification_Container">
                  <p className="header">Contact Information Verification</p>
                  <div>
                    <div className="child_container">
                      <p className="contact_verification_child_left">
                        <span>
                          <FiPhoneCall />
                        </span>
                        <span>(XXX) XXX XX89</span>
                      </p>
                      {number_email_verified.phoneVerified ? (
                        <span className="verified_icon">
                          <MdVerifiedUser />
                        </span>
                      ) : (
                        <span className="close_icon">
                          <IoMdCloseCircle />
                        </span>
                      )}
                    </div>

                    <div className="child_container">
                      <p className="contact_verification_child_left">
                        <span>
                          <MdOutlineMail />
                        </span>
                        <span>xxxshj@gmail.com</span>
                      </p>
                      {number_email_verified.emailverified ? (
                        <span className="verified_icon">
                          <MdVerifiedUser />
                        </span>
                      ) : (
                        <span className="close_icon">
                          <IoMdCloseCircle />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="contact_information_verification_wrapper">
                <div className="contact_information_verification_Container">
                  <p className="header">Notification Setting</p>
                  <div>
                    <div className="child_container">
                      <p className="contact_verification_child_left">
                        <span>Yes notify me by Email</span>
                      </p>
                      <div>
                        {" "}
                        <div className="yes_no_button_container">
                          <p
                            onClick={handleYesNoEmail}
                            style={{
                              backgroundColor: formData.email_notify
                                ? "white"
                                : "#d1823a",
                              color: formData.email_notify ? "black" : "white",
                              border: formData.email_notify
                                ? "2px solid black"
                                : "2px solid #d1823a",
                            }}
                          >
                            No
                          </p>
                          <p
                            onClick={handleYesNoEmail}
                            style={{
                              backgroundColor: !formData.email_notify
                                ? "white"
                                : "#d1823a",
                              color: !formData.email_notify ? "black" : "white",
                              border: !formData.email_notify
                                ? "2px solid black"
                                : "2px solid #d1823a",
                            }}
                          >
                            Yes
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="child_container">
                      <p className="contact_verification_child_left">
                        <span>Yes notify me by SMS</span>
                      </p>
                      <div className="yes_no_button_container">
                        <p
                          onClick={handleYesNoSms}
                          style={{
                            backgroundColor: formData.sms_notify
                              ? "white"
                              : "#d1823a",
                            color: formData.sms_notify ? "black" : "white",
                            border: formData.sms_notify
                              ? "2px solid black"
                              : "2px solid #d1823a",
                          }}
                        >
                          No
                        </p>
                        <p
                          onClick={handleYesNoSms}
                          style={{
                            backgroundColor: !formData.sms_notify
                              ? "white"
                              : "#d1823a",
                            color: !formData.sms_notify ? "black" : "white",
                            border: !formData.sms_notify
                              ? "2px solid black"
                              : "2px solid #d1823a",
                          }}
                        >
                          Yes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact_information_verification_wrapper">
                <div className="contact_information_verification_Container">
                  <p className="header">2FA Verification</p>
                  <div>
                    <div className="child_container">
                      <p className="contact_verification_child_left">
                        <span>
                          <input
                            type="radio"
                            name="radioGroup1"
                            id="option_1"
                            value="enabled"
                            checked={formData.two_factor_authentication}
                            onChange={handleYesNo2FA}
                          />
                        </span>
                        <label htmlFor="option_1">Enabled</label>
                      </p>
                      <span className="verified_icon">
                        <MdVerifiedUser />
                      </span>
                    </div>

                    <div className="child_container">
                      <p className="contact_verification_child_left">
                        <span>
                          <input
                            type="radio"
                            name="radioGroup1"
                            id="option_2"
                            value="disabled"
                            checked={!formData.two_factor_authentication}
                            onChange={handleYesNo2FA}
                          />
                        </span>
                        <label htmlFor="option_2">Disabled</label>
                      </p>
                      <span className="close_icon">
                        <IoMdCloseCircle />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*---------------------------Contact Information Verification Dropdown list End-------------------------- */}

            {/* -------------------------Notification Setting Dropdown list Star------------------------------------------- */}

            {/* ---------------------------Notification Setting Dropdown list End------------------------------------------------- */}

            {/* ---------------------------Security Setting Dropdown list Start------------------------------------------------- */}

            <div className="row mt-md-4 mt-3 table_wrapper_ip">
              <div className="col-12 table_container_ip">
                <div className=" mt-5 ">
                  <h5 className="text-black fs-3">Account History</h5>
                  <table className="table table-bordered table-hover custtable">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" className="text-center text-black fs-4">
                          <p id="cust-tbl-hed">Date</p>
                        </th>
                        <th scope="col" className="text-center text-black fs-4">
                          <p id="cust-tbl-hed">Login IP</p>
                        </th>
                        <th scope="col" className="text-center text-black fs-4">
                          <p id="cust-tbl-hed">City</p>
                        </th>
                        <th scope="col" className="text-center text-black fs-4">
                          <p id="cust-tbl-hed">Status</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.userLoginHistory.map((entry, index) => (
                        <tr key={entry.id}>
                          <td className="text-center text-black fs-4">
                            {entry.created_at}
                          </td>
                          <td className="text-center text-black fs-4">
                            Logged in with {entry.ip_address}
                          </td>
                          <td className="text-center text-black fs-4">
                            {entry.city_name || "Unknown"}
                          </td>
                          <td
                            className={`text-center ${entry.status === "Success"
                              ? "text-success "
                              : "text-danger"
                              } fs-4`}
                            style={{ textDecoration: "underline" }}
                          >
                            {entry.status || "Unknown"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*---------------------Table End---------------------------*/}

              <div className="col-md-4 my-3  save_button_container">
                <button
                  id="bottom-save-btn"
                  style={{ backgroundColor: "maroon" }}
                  onClick={handleDeactivateUser}
                  className='btn-change5'
                >
                  Deactivate
                </button>
                {/* <button
                  className=""
                  id="bottom-save-btn"
                  style={{
                    backgroundColor: saveClicked ? "gray" : "#d1823a",
                    cursor: saveClicked ? "not-allowed" : "",
                    marginLeft : "2rem"
                  }}
                  disabled={saveClicked}
                  onClick={handleUpdateuserInfo}
                >
                  SAVE
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
