import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./CRMLoanMobileData.module.css";

const CRMLoanMobileData = () => {
  const { dealerId } = useSelector((state) => state.user);
  const [loanCardDataArray, setLoanCardDataArray] = useState([]);
  console.log("loanCardDataArray => ", loanCardDataArray);
  const fetchDealerData = async () => {
    const fd = new FormData();
    fd.append("user_id", dealerId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/loan/get`,
        fd
      );
      setLoanCardDataArray(response.data.data);
    } catch (error) {
      console.log("Error fetching dealer data:", error);
      setLoanCardDataArray([]);
    }
  };

  // Using useEffect to fetch data when the page loads
  useEffect(() => {
    if (dealerId) {
      fetchDealerData(); // Fetch dealer data when 'id' is available
    }
  }, [dealerId]);
  return (
    <div className={styles.crmMwrapper}>
      {loanCardDataArray &&
        loanCardDataArray?.map((item, index) => (
          <div className={styles.crmMcontainer}>
            <div className={styles.crmMHeader}>
              <span>Name</span>
              <span>{item.name}</span>
            </div>
            <div className={styles.crmMPersonalWrapper}>
              <div className={styles.crmMPersonalDetails}>
                <p className={styles.crmMDetail}>
                  <span>Phone Number</span>
                  <span>{item.mobileNumber}</span>
                </p>
                <p className={styles.crmMDetail}>
                  <span>Email</span>
                  <span>{item.email}</span>
                </p>
                <p className={styles.crmMDetail}>
                  <span>Asset Info</span>
                  <span>{item.assetName}</span>
                </p>
              </div>
              <div className={styles.crmMBtnContainer}>
                <button>Download</button>
                <button>Reply</button>
              </div>
            </div>
            <span className={styles.crmMNumbering}>{index + 1}</span>
          </div>
        ))}
    </div>
  );
};

export default CRMLoanMobileData;
