import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./DealerbalanceInventory.css";
import Buyerdata from "./Buyerdata";
import { useDispatch, useSelector } from "react-redux";
import AddlistingData from "./AddlistingData";
import { IoMdInformationCircleOutline } from "react-icons/io";
import AddlistingDataSold from "./AddlistingDataSold";
import { incrementCounter } from "../../../../Redux/slice/activeSlice";
import { BiReset } from "react-icons/bi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import PaymentDueData from "../PaymentDueData/PaymentDueData";
import BalanceCard from "./BalanceCard";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const DealerBalanceInventory = () => {
  const { id } = useSelector((state) => state.user);
  //  for new filter-----------------------------------
  const { dealerId } = useSelector((state) => state.user);
  const [counterb, setCounterb] = useState(0);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedBodyType, setSelectedBodyType] = useState(null);
  const [selectedBalanceStatus,setSelectedBalanceStatus] = useState(null);
  const [dropdownData, setDropdownData] = useState();
  // -----------------------------------------------------------------
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  //   const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.active.counter);
  const [showModal, setShowModal] = useState(false);
  const [showModalPaymentDue, setShowModalPaymentDue] = useState(false);
  const [showModalAddlisting, setShowModalAddlisting] = useState(false);
  const [dealerData, setDealerData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [cobuyerData, setCobuyerdata] = useState([]);
  const [dataPaymentDue, setDataPaymentDue] = useState([]);
  const [addlistingData, setAddlistingData] = useState([]);
  // Function to handle closing the modal
  const handleLinkClick = (itemData) => {
    setShowModal(true);
    setCobuyerdata(itemData);
  };
  // for paymentdue popup------------------------------------------------------
  const handleLinkClickPaymentDue = (itemData) => {
    setShowModalPaymentDue(true);
    setDataPaymentDue(itemData);
  };
  // ---------------------------------------------------------------------------
  const handleLinkClickAddlisting = (itemDataAddlisting) => {
    setShowModalAddlisting(true);
    setAddlistingData(itemDataAddlisting);
  };
  //console.log("cobuyerData", cobuyerData);
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalCloseAddlisting = () => {
    setShowModalAddlisting(false);
  };
  const handleModalClosePaytmentDue = () => {
    setShowModalPaymentDue(false);
  };
  //console.log("addlistingData", addlistingData);
  const [initialFilters, setInitialFilters] = useState([]);
  // get the data of sell section and co-buyer---------------------------------------
  const [refreshApiData, setRefreshApiData] = useState(false);
  // for fetch data----------------------------

  const handleIncrement = () => {
    dispatch(incrementCounter());
    setOpenDropdown(null);
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });
    setSelectedSort(null);
  };
  // --------------------------------------
  // --------------------------------------------------------------------------------------
  //console.log("tabledata", tableData);

  // table data for all users dealers --------------------------------------------------------
  // ---------------------------------------------------------------------------------
  //console.log("tableData", tableData);

  const totalItems = tableData ? tableData.length : 0;
  const itemsPerPage = totalItems > 20 ? totalItems : 20;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  //console.log("currentItems => ", currentItems);

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(12)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));

  const renderTableRow = (item, index) => {
    //console.log("item?????", item);

    return (
      <tr
        key={index}
        className={selectedRows.includes(index) ? "selected-rows" : ""}
        onClick={() => handleLinkClickPaymentDue(item)}
        // onDoubleClick={() => handleLinkClick(item.id)}
      >
        {item ? (
          <>
            <td>{index + 1}</td>
            <td>{item.accountNumber}</td>
            <td>{item.addListing2?.stock_no}</td>
            <td
              className="ac-col fw-bold"
              // onClick={() => handleLinkClickAddlisting(item)}
              // style={{ color: "blue" }}
            >
              {item.addListing2?.modelYear} {item.addListing2?.modelMake}{" "}
              {item.addListing2?.makeModel}
            </td>
            {/* <td>{item.make}</td>
            <td >{item.modal}</td> */}
            <td className="fw-bold">
              {new Intl.NumberFormat().format(item.mileageIn)}
            </td>
            {/* onClick={() => handleLinkClick(item)} style={{ color: "blue" }} */}
            <td>
              {item.firstName} {item.lastName}
            </td>
            {/* <td>{item.idType}</td>
            <td >{item.idNumber}</td>
            <td >{item.address}</td>
            <td >{item.county}</td> */}
            <td>{item.phoneNumber}</td>
            {/* <td >{item.email}</td> */}
            <td>
              {new Intl.NumberFormat().format(item.sellPriceFees.listingPrice)}
            </td>
            <td>{item.sellPriceFees.priceFeeDate}</td>
            <td>{item.sellPriceFees.priceFeesPaymentMethod}</td>
            <td style={{ color: "blue", fontWeight: "bold" }}>
              {new Intl.NumberFormat().format(
                item.sellPriceFees.paymentPriceFees
              )}
            </td>
            <td style={{ color: "blue", fontWeight: "bold" }}>
              {new Intl.NumberFormat().format(item.sellPriceFees.balanceDue)}
            </td>
            {/* <td>
              <Button className={`border-0 decline-btn`}>Pending</Button>
            </td> */}
          </>
        ) : (
          Array.from({ length: 12 }).map((_, i) => <td key={i}>&nbsp;</td>)
        )}
      </tr>
    );
  };

  // for filter sold data---------------------------------------------------------------*********************-----------------------------

  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus, type = "") => {
    setOpenDropdown(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      // Update the selected filter based on the type
      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedStatus, // Use computed property name here
        }));
      }

      setTableData(data);
      setSelectedSort(selectedStatus);
      //console.log(data);
      // Handle the response data as needed
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //handleSelect(inputValue); // Call the function with the input value
      setInputValue(inputValue); // Clear the input field if desired
      setCounterb((prev) => prev + 1);
    }
  };
  const handleButtonClick = () => {
    // handleSelect(inputValue); // Call the function with the input value
    setInputValue(inputValue); // Clear the input field if desired
    setCounterb((prev) => prev + 1);
  };

  const handleSortByColumn = async (sort, column) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${sort}&extraParam=${column}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      //console.log(data);
      // Handle the response data as needed
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  };
  // -------------------------------------------------------------------------------------------
  //console.log("initialFilters32455***********", initialFilters);
  //give me code for tracking size
  const [isScreenSmaller, setIsScreenSmaller] = useState(true);
  //console.log("Is  screen smaller", isScreenSmaller);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsScreenSmaller(true);
      } else {
        setIsScreenSmaller(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // ------------------------------------------------------------------------------------New Filter Sold Inventory--------------------------

  const transformOptions = (data, label) => {
    // Check if the data is an array before proceeding
    if (!Array.isArray(data)) {
      //console.error(`${label} data is not an array`, data);
      return [];
    }

    return [
      { value: "", label: label }, // Default empty value for all dropdowns
      ...data.map((item) => ({
        value: item.toString(),
        label: item.toString(),
      })),
    ];
  };

  // Generating options
  const optionsYear = transformOptions(dropdownData?.year, "Year");
  const optionsMake = transformOptions(dropdownData?.make, "Make");
  const optionsModal = transformOptions(dropdownData?.model, "Model");
  const optionsBodyType = transformOptions(
    dropdownData?.bodyStyle,
    "Body Type"
  );

  //console.log("dropdownData", dropdownData);
  const handleSelectedYear = (event) => {
    const selectedOption = optionsYear.find(
      (option) => option.value === event.target.value
    );
    //console.log("Selected year => ", selectedOption);
    setSelectedYear(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    //handleFilterDDClick("year",selectedOption.value);
  };
  const handleSelectedMake = (event) => {
    const selectedOption = optionsMake.find(
      (option) => option.value === event.target.value
    );
    //console.log("Selected year => ", selectedOption);
    setSelectedMake(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    // handleFilterDDClick("make",selectedOption.value);
  };
  const handleSelectedModal = (event) => {
    const selectedOption = optionsModal.find(
      (option) => option.value === event.target.value
    );
    //console.log("Selected year => ", selectedOption);
    setSelectedModal(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    // handleFilterDDClick("models",selectedOption.value);
  };
  const handleSelectedBodyType = (event) => {
    const selectedOption = optionsBodyType.find(
      (option) => option.value === event.target.value
    );
    //console.log("Selected year => ", selectedOption);
    setSelectedBodyType(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    // handleFilterDDClick("Body Type",selectedOption.value);
  };
  const handleSelectedBalanceStatus = (event) => {
    const value = event.target.value;
   console.log("selectedvalue",value);
    setSelectedBalanceStatus(value); // Set the selected option object
    setCounterb((prev) => prev + 1);
  }
  //console.log("selectedYear", selectedYear);
  const objFor = {
    year: selectedYear ? parseInt(selectedYear) : null,
    make: selectedMake && selectedMake !== "" ? selectedMake : null,
    model: selectedModal && selectedModal !== "" ? selectedModal : null,
    bodyStyle:
      selectedBodyType && selectedBodyType !== "" ? selectedBodyType : null,
    // balanceStatus:selectedBalanceStatus && selectedBalanceStatus !== "" ? selectedBalanceStatus : null,
    userId: dealerId,
  };
  //console.log("selectedYear", selectedYear);
  // --------------------------------------------------------------
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sell/active_filters`,
          objFor,
          {}
        );
        //console.log("Response:", response.data);
        setDropdownData(response.data);
        // setCounterc((prev)=>prev+1);
      } catch (error) {
        //console.error("Error occurred:", error);
      }
    };

    // Trigger the POST request
    postData();
  }, [counterb]);
  //  for get the data---------------------------------
  const objForSold = {
    searchTerm: inputValue && inputValue !== "" ? inputValue : null,
    make: selectedMake && selectedMake !== "" ? selectedMake : null,
    model: selectedModal && selectedModal !== "" ? selectedModal : null,
    year: selectedYear ? parseInt(selectedYear) : null,
    bodyStyle:
      selectedBodyType && selectedBodyType !== "" ? selectedBodyType : null,
    balanceStatus:selectedBalanceStatus && selectedBalanceStatus !== "" ? selectedBalanceStatus : null,
    userId: dealerId,
  };
  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sell/get/active_balance`,
          objForSold,
          {}
        );
        //console.log("Response:5656565", response.data);
        const data = await response.data;
        if (response.status === 200 && data.length > 0) {
          //console.log("all the data in sold inventory", data, response);
          const sortedData = data.sort((a, b) => a.id - b.id);
          //console.log("all the sortedData in sold inventory", sortedData);
          setTableData(data);
          setInitialFilters(data.map((item) => item.addListing2));
          //console.log("______________tableData", sortedData);
          setInputValue("");
        }
        else{
          setTableData([]);
        }

      } catch (error) {
        //console.error("Error occurred:", error);
      }
    };

    // Trigger the POST request
    postData();
  }, [counterb,counter]);
  //  for filter reset---------------------------------------------------------------------
  const handleResetFilter = () => {
    setInputValue("");
    setSelectedMake("");
    setSelectedModal("");
    setSelectedYear("");
    setSelectedBodyType("");
    setCounterb((prev) => prev + 1);
  };
  // -----------------------------------------------------------
  return (
    <>
      <Container fluid className="" style={{ minWidth: "82vw" }}>
        <Row>
          {/* input field start */}
          <Col
            sm={11}
            className="input-group mt-3 mb-4"
            // id="act-search"
          >
            {!isScreenSmaller && (
              <>
                <Button
                  className="btn border-0 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ backgroundColor: "#e8a262", borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"  }}
                >
                  All
                </Button>
                <ul className="dropdown-menu">
                  <li onClick={() => handleSelect("Price10k")}>
                    <Link className="dropdown-item" to="#">
                      Price &lt; 10k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage50k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 50k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage100k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 100k
                    </Link>
                  </li>
                </ul>
              </>
            )}

            <input
              type="text"
              className="form-control"
              placeholder="Search Inventory"
              style={{boxShadow: "inset 0 0 9px 2px rgb(209, 130, 58)"}}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)} // Update state on input change
              onKeyPress={handleKeyPress} // Call handleKeyPress on key press
            />
            <Button
              className="btn border-0"
              type="button"
              id="button-addon1"
              style={{ backgroundColor: "#e8a262" }}
              onClick={handleButtonClick}
            >
              <i className="fas fa-search"></i>
            </Button>
            {!isScreenSmaller && (
              <>
                {/*--------------------------------- nerw filter---------------------------------- */}
                <div className="new-filter-active">
                  <select
                    value={selectedYear}
                    onChange={handleSelectedYear}
                    style={{ width: "auto" }}
                    placeholder="Year"
                  >
                    {optionsYear.length === 0 ? (
                      <option disabled>No year found</option>
                    ) : (
                      optionsYear.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="new-filter-active">
                  <select
                    value={selectedMake}
                    onChange={handleSelectedMake}
                    style={{ width: "auto" }}
                    placeholder="Make"
                  >
                    {optionsMake.length === 0 ? (
                      <option disabled>No make found</option>
                    ) : (
                      optionsMake.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <div className="new-filter-active">
                  <select
                    value={selectedModal}
                    onChange={handleSelectedModal}
                    style={{ width: "auto" }}
                    placeholder="Make"
                  >
                    {optionsModal.length === 0 ? (
                      <option disabled>No modal found</option>
                    ) : (
                      optionsModal.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <div className="new-filter-active">
                  <select
                    value={selectedBodyType}
                    onChange={handleSelectedBodyType}
                    style={{ width: "auto" }}
                    placeholder="Make"
                  >
                    {optionsBodyType.length === 0 ? (
                      <option disabled>No body-type found</option>
                    ) : (
                      optionsBodyType.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="new-filter-active">
                  <select style={{ width: "auto" }} placeholder="All" onChange={handleSelectedBalanceStatus}>
                    <option value="all">All</option>
                    <option value="opened">Opened</option>
                    <option value="paidoff">Paid Off</option>
                  </select>
                </div>
                {/* ------------------------------------------------------------------------------- */}

                <Button
                  className={`btn border-0 dropdown-toggle btn-eq-wd ${
                    selectedSort === "Desc" || selectedSort === "Asc"
                      ? "bg-secondary"
                      : ""
                  }`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="sortBy" // Added ID for accessibility
                  style={{
                    backgroundColor: "#e8a262",
                    margin: "0 0.3rem",
                  }}
                >
                  Sort By
                </Button>
                <ul className="dropdown-menu" aria-labelledby="sortBy">
                  <li onClick={() => handleSelect("Desc")}>
                    <Link
                      className={`dropdown-item ${
                        selectedSort === "Desc" ? "selected-item" : ""
                      }`}
                      to="#"
                    >
                      Newest
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Asc")}>
                    <Link
                      className={`dropdown-item ${
                        selectedSort === "Asc" ? "selected-item" : ""
                      }`}
                      to="#"
                    >
                      Oldest
                    </Link>
                  </li>
                </ul>

                <Button
                  className="btn border-0 btn-eq-wd"
                  type="button"
                  id="button-addon1"
                  style={{ backgroundColor: "#e8a262",borderTopRightRadius:"20px",borderBottomRightRadius:"20px"   }}
                  onClick={handleResetFilter}
                >
                  <BiReset className="icons-r-flt" />
                </Button>
              </>
            )}
          </Col>
          {/* input field end */}
        </Row>
        {isScreenSmaller ? (
          <>
            {currentItems &&
              currentItems?.map((item) => (
                <div key={item.id}>
                  <BalanceCard item={item} />
                </div>
              ))}
          </>
        ) : (
          <Row
            className="mx-0 rounded  bg-body border-2"
            style={{
              position: "relative",
              top: "",
              zIndex: "5 ",
              // minWidth: "1000px", overflowX: "scroll"
            }}
          >
            <div className="table-wrapper rounded px-0 table-scrollbar">
              <Table
                striped
                bordered
                hover
                //   ref={tableRef}
                className="shadow mb-0"
                id="table"
              >
                <thead>
                  <tr>
                    <th>No#</th>
                    <th className="ac-col">Ac N.</th>

                    <th className="ac-col">
                      Stock N.
                      <span className="filter-icons">
                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Descending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() => handleSortByColumn("Desc", "Stock")}
                          >
                            <TiArrowSortedUp />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Ascending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() => handleSortByColumn("Asc", "Stock")}
                          >
                            <TiArrowSortedDown />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </th>

                    <th>
                      Assets Info
                      <span className="tooltip-container-active-inventory">
                        <IoMdInformationCircleOutline className="info-icon-active-inventory" />
                        <div className="tooltip-text-active-inventory">
                          Year Make Modal
                        </div>
                      </span>
                      <span className="filter-icons">
                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Descending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() =>
                              handleSortByColumn("Desc", "Vehicle")
                            }
                          >
                            <TiArrowSortedUp />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Ascending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() => handleSortByColumn("Asc", "Vehicle")}
                          >
                            <TiArrowSortedDown />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </th>
                    {/* <th>make</th>
                <th>Modal</th> */}
                    <th>
                      Mileage
                      <span className="filter-icons">
                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Descending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() =>
                              handleSortByColumn("Desc", "Mileage")
                            }
                          >
                            <TiArrowSortedUp />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Ascending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() => handleSortByColumn("Asc", "Mileage")}
                          >
                            <TiArrowSortedDown />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </th>

                    <th>Buyer</th>
                    {/* <th>ID-Type</th>
                <th>ID-Number</th>
                <th>Address</th>
                <th>County</th> */}
                    <th>Phone</th>
                    {/* <th>Email</th> */}
                    <th>Sell Price</th>
                    <th>Sell Date</th>
                    <th>Payment Method</th>
                    <th>Payment</th>
                    <th>Current Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems
                  .sort((a, b) => new Date(b.sellPriceFees.priceFeeDate) - new Date(a.sellPriceFees.priceFeeDate))
                  .map(renderTableRow)}
                  {emptyRows}
                </tbody>
                <tfoot>
                  <tr style={{ backgroundColor: "orange" }}>
                    <td colSpan={4}>Active Dealers: </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colSpan={4}>Pending Request</td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Row>
        )}

        {/* <DealerData
        show={showModal}
        handleClose={handleModalClose}
        dealerData={dealerData}
        dealerId={dealerId}
        addCost="addcost"
      /> */}
      </Container>
      <Buyerdata
        show={showModal}
        handleClose={handleModalClose}
        cobuyerData={cobuyerData}
      />
      <AddlistingDataSold
        show={showModalAddlisting}
        handleClose={handleModalCloseAddlisting}
        addlistingData={addlistingData}
        addlistionIdShow={2}
      />
      <PaymentDueData
        show={showModalPaymentDue}
        handleClose={handleModalClosePaytmentDue}
        PaymentData={dataPaymentDue}
        setRefreshApiData={setRefreshApiData}
      />
    </>
  );
};

export default DealerBalanceInventory;
