import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { MdModeEdit, MdDelete } from "react-icons/md";
import AdvertisingProfile from "./AdvertisingProfile";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Image from "../../../imgData";
import "./active.css";
import { FaRegCopyright } from "react-icons/fa";
import {
  resetBuyerSellData,
  resetCoBuyerSellData,
  setActiveTab,
  setSelectedVehicle,
} from "../../../../Redux/slice/sellSlice";
import {
  resetRetailData,
  setRetailData,
} from "../../../../Redux/slice/taxes&FeesSlice";
import SellData from "./SellData";
import AddlistingData from "../DealerBalanceInventory/AddlistingData";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaCopy } from "react-icons/fa6";
import { IoCopyOutline } from "react-icons/io5";
import { saveUser } from "../../../../Redux/slice/userSlice";
import AddlistingDataAddCost from "../DealerBalanceInventory/AddlistingDataAddCost";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const CardView = ({
  data,
  index,
  selectedCards,
  handleSelected,
  handleDelete,
  onUpdate,
  setCounterAddListing
}) => {
  // console.log("Data to be sent => ", data);
  const { id } = useSelector((state) => state.user);
  const { dealerId } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (show) {
  //     dispatch(saveUser({ isSideBarOpen: false }));
  //   }
  // }, [show]);
  const previousListingId = useSelector(
    (state) => state.taxesAndFees.dealerRetail.listingId
  );
  const [socialIcons, setSocialIcons] = useState([]);
  const [isProChecked, setIsProChecked] = useState(true);
  const [advertisingPro, setAdvertisingPro] = useState({
    listing_id: data.id,
    dacmp_status: true,
    dacmp_interval: "",
    unitedmotors_status: true,
    unitedmotors_interval: "",
    google_status: false,
    google_interval: "",
    insta_status: false,
    insta_interval: "",
    facebook_status: false,
    facebook_interval: "",
    facebook_marketplace_status: false,
    facebook_groups_status: false,
    craigslist_status: false,
    craigslist_interval: "",
    twitter_status: false,
    twitter_interval: "",
  });

  const handleAddsPro = (e) => {
    e.stopPropagation();
    setShow(true);
    console.log("seting Profils");
    if (data && data.addvertising) {
      setAdvertisingPro((prev) => ({
        ...prev,
        dacmp_interval: data.addvertising.dacmp_interval ?? prev.dacmp_interval,
        unitedmotors_interval:
          data.addvertising.unitedmotors_interval ?? prev.unitedmotors_interval,
        google_status: data.addvertising.google_status,
        google_interval:
          data.addvertising.google_interval ?? prev.google_interval,
        insta_status: data.addvertising.insta_status,
        insta_interval: data.addvertising.insta_interval ?? prev.insta_interval,
        facebook_status: data.addvertising.facebook_status,
        facebook_interval:
          data.addvertising.facebook_interval ?? prev.facebook_interval,
        craigslist_status: data.addvertising.craigslist_status,
        craigslist_interval:
          data.addvertising.craigslist_interval ?? prev.craigslist_interval,
        twitter_status: data.addvertising.twitter_status,
        twitter_interval:
          data.addvertising.twitter_interval ?? prev.twitter_interval,
      }));
    }
  };

  const handleCheckboxChange = (e, intervalName) => {
    console.log("handleCheckboxChange", e);
    const { name, checked } = e.target;
    // setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));

    if (name === "facebook_status" && checked === false) {
      setAdvertisingPro((prev) => ({
        ...prev,
        facebook_marketplace_status: false,
        facebook_groups_status: false,
      }));
    }

    // Reset the interval if the checkbox is unchecked
    const resetInterval = !checked ? "" : advertisingPro[intervalName];

    setAdvertisingPro((prev) => ({
      ...prev,
      [name]: checked,
      [intervalName]: resetInterval,
    }));
  };

  const handleSelectChange = (e) => {
    console.log("handleSelectChange");
    e.stopPropagation();
    // e.preventDefault();
    const { name, value } = e.target;
    setAdvertisingPro((prev) => ({ ...prev, [name]: value }));
  };

  const resetCheckboxes = () => {
    setAdvertisingPro({
      listing_id: data.id,
      dacmp_status: true,
      // dacmp_interval: "",
      unitedmotors_status: true,
      // unitedmotors_interval: "",
      google_status: false,
      google_interval: "",
      insta_status: false,
      insta_interval: "",
      facebook_status: false,
      facebook_interval: "",
      craigslist_status: false,
      craigslist_interval: "",
      twitter_status: false,
      twitter_interval: "",
    });
  };

  // Handle image toggle state for all profiles
  const [connectionStatus, setConnectionStatus] = useState({
    dacmp_connected: false,
    unitedmotors_connected: false,
    google_connected: false,
    insta_connected: false,
    facebook_connected: false,
    craigslist_connected: false,
    twitter_connected: false,
  });

  const toggleConnectionStatus = (profileName) => {
    setConnectionStatus((prevStatus) => ({
      ...prevStatus,
      [profileName]: !prevStatus[profileName],
    }));
  };

  const setAllIntervals = (interval) => {
    console.log("interval in setAllIntervals", interval);
    setAdvertisingPro((prev) => ({
      ...prev,
      dacmp_interval: prev.dacmp_status ? interval : prev.dacmp_interval,
      unitedmotors_interval: prev.unitedmotors_status
        ? interval
        : prev.unitedmotors_interval,
      google_interval: prev.google_status ? interval : prev.google_interval,
      insta_interval: prev.insta_status ? interval : prev.insta_interval,
      facebook_interval: prev.facebook_status
        ? interval
        : prev.facebook_interval,
      craigslist_interval: prev.craigslist_status
        ? interval
        : prev.craigslist_interval,
      twitter_interval: prev.twitter_status ? interval : prev.twitter_interval,
    }));
  };

  // Function to post advertisingPro data
  const updateAdvertisement = async () => {
    try {
      console.log(advertisingPro);
      // console.log('Payload being sent:', JSON.stringify(advertisingPro, null, 2));

      const response = await fetch(`${apiUrl}/listing/update_advertisement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(advertisingPro),
      });

      if (response.ok) {
        const updatedData = await response.json();
        console.log("Advertisement updated successfully:", updatedData);

        // Notify parent component of the update
        if (onUpdate) onUpdate(data.id, advertisingPro);

        // Update local state with the new data
        setAdvertisingPro((prev) => ({
          ...prev,
          ...updatedData, // Assume the API response contains the updated fields
        }));
      } else {
        console.error("Failed to update advertisement:", response.statusText);
        // Handle the error response as needed
      }
    } catch (error) {
      console.error("Error updating advertisement:", error);
      // Handle any errors that occur during the fetch
    } finally {
      setShow(false);
    }
  };

  const [facebookMarketplaceStatus, setFacebookMarketplaceStatus] =
    useState(false);
  const [facebookGroupsStatus, setFacebookGroupsStatus] = useState(false);

  const handleFacebookCheckboxChange = (checked, name) => {
    console.log("______checked", name, checked, facebookMarketplaceStatus);
    // const { checked } = e.target;
    // if (name === 'facebook_marketplace_status') {
    //   setFacebookMarketplaceStatus(checked);
    // } else if (name === 'facebook_groups_status') {
    //   setFacebookGroupsStatus(checked);
    // }

    // e.preventDefault();
    // e.stopPropagation();
    // const { checked } = e.target;
    setAdvertisingPro((prev) => ({ ...prev, [name]: checked }));
    setFacebookMarketplaceStatus(checked);
    // console.log("advertisingPro", advertisingPro);
  };

  useEffect(() => {
    setTimeout(() => {
      console.log("advertisingPro_______________", advertisingPro);
    }, 200);
  }, [facebookMarketplaceStatus, advertisingPro]);

  const navigate = useNavigate();
  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};

  // console.log("location", location);
  // console.log("updatedData", updatedData);
  // console.log("originalData", originalData);
  const [showModalSell, setShowModalSell] = useState(false);
  const handleModalCloseSell = () => {
    setShowModalSell(false);
  };
  // Function to check if a field has been updated
  const isFieldUpdated = (fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };
  // for addlisting popup---------------------------------------------------------
  const [showModalAddlisting, setShowModalAddlisting] = useState(false);
  const [showModalAddlistingAddCost, setShowModalAddlistingAddCost] =
    useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [isImageM, setIsImageM] = useState(false);
  const [addlistingData, setAddlistingData] = useState([]);
  const [addCostStatus, setAddCostStatus] = useState(0);
  const handleLinkClickAddlisting = (itemDataAddlisting, e, cost) => {
    // dispatch(saveUser({ isSideBarOpen: false }));
    console.log("addCostStatus", cost);
    e.preventDefault();
    e.stopPropagation();
    // console.log("************itemDataAddlisting*******************", itemDataAddlisting);
    setAddCostStatus(cost);
    setIsImageM(false);
    setShowModalAddlisting(true);
    setShowModalAdd(true);
    setAddlistingData(itemDataAddlisting);
  };
  // -------------------------------for add cost--------------------------------------------------
  const handleLinkClickAddlistingAddCost = (itemDataAddlisting, e, cost) => {
    // dispatch(saveUser({ isSideBarOpen: false }));
    console.log("addCostStatus", cost);
    e.preventDefault();
    e.stopPropagation();
    // console.log("************itemDataAddlisting*******************", itemDataAddlisting);
    setAddCostStatus(cost);
    // setIsImageM(false);
    setShowModalAddlistingAddCost(true);
    // setShowModalAdd(true);
    setAddlistingData(itemDataAddlisting);
  };
  // for check recall for check the status ----------------------------------
  const [recallNumber, setRecalNumber] = useState(0);
  const handleCheckReCall = (itemDataAddlisting) => {
    const make = itemDataAddlisting.modelMake;
    const model = itemDataAddlisting.makeModel;
    const modelYear = itemDataAddlisting.modelYear;

    console.log("Make", make);
    console.log("Model", model);

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
      make,
      model,
      modelYear: String(modelYear),
    });

    const url = `${
      process.env.REACT_APP_API_URL
    }/listing/get_recalls?${queryParams.toString()}`;

    fetch(url, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setRecalNumber(data);
        // toast.success(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // ---------------------------------------------------------------
  const handleOpenImgModal = (itemDataAddlisting, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsImageM(true);
    console.log("handleOpenImgModal isImageM", isImageM);

    // console.log("************itemDataAddlisting in handleOpenImgModal*******************", itemDataAddlisting);
    setIsImageM(true);
    setShowModalAdd(false);
    setShowModalAddlisting(true);
    setShowModalImage(true);
    setAddlistingData(itemDataAddlisting);
  };

  const handleModalCloseAddlisting = () => {
    console.log("handleModalCloseAddlisting isImageM", isImageM);
    setShowModalAddlisting(false);
    setShowModalImage(false);
  };

  const handleModalCloseAddlistingAddCost = () => {
    console.log("handleModalCloseAddlisting isImageM", isImageM);
    setShowModalAddlistingAddCost(false);
    // setShowModalImage(false);
  };

  const handleCloseImgModal = () => {
    console.log("handleCloseImgModal isImageM", isImageM);
    // setShowModalAddlisting(false);
    if (isImageM) {
      setShowModalAddlisting(false);
      setShowModalImage(false);
    } else {
      setShowModalImage(false);
    }
  };

  // console.log("addlistingData**********223********", addlistingData);
  // ------------------------------------------------------------------------------------
  const Navigate = useNavigate();

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleGoToSell = () => {
    // dispatch(saveUser({ isSideBarOpen: false }));
    // Make sure that 'data' is correctly defined and holds the necessary information
    console.log("data to be sent to sell after cliking on card", data);
    // Navigate(`/dealer/sell#${data.stock_no}#${data.id}`, { state: { data } });
    setShowModalSell(false);
    // dispatch(setActiveTab("Sale Type"));
    dispatch(setSelectedVehicle(data));
    dispatch(
      setRetailData({
        listingId: data.id,
      })
    );
    // Compare with the previous listingId
    dispatch(resetCoBuyerSellData());
    dispatch(resetBuyerSellData());
    dispatch(
      setRetailData({
        salesTaxes: "6.25",
        mInventoryTax: "0.001488",
        titleApplicationFee: 13.0,
        terpTitlefee: 20,
        drivesOutTag: 5.0,
        windshieldTrackerSticker: 50.75,
        regFeeDps: 1,
        cntyRoadBridgeFee: 10,
        childSafetyFund: 1.5,
        InspectionFeeOBDNL: 25,
        StateInspectionFees: 8.25,
        processingHandlingFee: 4.75,
        documentationFee: 150.0,
        technologyFees: 0.0,
        buyerTagFees: 5,
        other1: 0.0,
        other2: 0.0,
        cardFees: 0,
        warranty: 0,
        gapInsurance: 0,
        mailDeliveryFees: 0,
        transportationFees: 0,
        totalFees: 0,
        // totalFeesAndothers: 273,
        txtState: "",
        listingPrice: "",
        userListingPrice: 0,
        txtTitleType: null,
        ownedTrade: "",
        cashDifference: "",
        paymentPriceFees: "",
        date: getCurrentDate(),
        saleTaxValue: "",
        mInventoryValue: "",
        balanceDue: "",
        totalDue: 0,
        // -----------------------------------
        lienHolderName: "",
        leanHolderId: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        serviceContractId: "",
        serviceContractName: "",
        serviceContractLicense: "",
        serviceContractAddress: "",
        serviceProviderActivationStatus: "",
        serviceProviderType: "",
        providerPhoneNumber: "",
        providerCounty: "",
        providerListingExpiry: "",
        priceFeesPaymentMethod: "",
        //Gap Insurance Provider Data
        gapInsuranceId: "",
        gapName: "",
        gapLicense: "",
        gapAddress: "",
        gapActivationStatus: "",
        gapType: "",
        gapPhoneNumber: "",
        gapCounty: "",
        gapListingExpiry: "",
        // Sell Form ---------------------------
        sellForm: "",
        // tax maker forms-----------------------
        taxMakerForms: "",
        // trade in key--------
        dealerId: 0,
        vin: "",
        year_make_model: "",
        // make year modal only check purpose for backend------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        // -----------------------
        trim: "",
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        purchase_price: "",
        soldDataStatus:0,
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
        mileageIn: "",
        // trade in key end--------
        // for images of vehicle card--------
        imageUrl: "",
      })
    );
    navigate("/dealer/sell");
    dispatch(setActiveTab("Buyer"));
  };
  // For selection of cards----------------------------------********************************-------------------------------
  // const handleSelectedsell = (index) => {
  //   handleSelected(index);
  //   // Make sure that 'data' is correctly defined and holds the necessary information
  //   console.log("data to be sent to sell after cliking on card", data);
  //   dispatch(setActiveTab("Sale Type"));
  //   dispatch(setSelectedVehicle(data));
  //   dispatch(
  //     setRetailData({
  //       listingId: data.id,
  //     })
  //   );

  //   // Compare with the previous listingId
  //   dispatch(resetCoBuyerSellData());
  //   dispatch(resetBuyerSellData());
  //   dispatch(
  //     setRetailData({
  //       salesTaxes: "6.25",
  //       mInventoryTax: "0.001488",
  //       titleApplicationFee: 13.0,
  //       terpTitlefee: 20,
  //       drivesOutTag: 5.0,
  //       windshieldTrackerSticker: 50.75,
  //       regFeeDps: 1,
  //       cntyRoadBridgeFee: 10,
  //       childSafetyFund: 1.5,
  //       InspectionFeeOBDNL: 25,
  //       StateInspectionFees: 8.25,
  //       processingHandlingFee: 4.75,
  //       documentationFee: 150.0,
  //       technologyFees: 0.0,
  //       buyerTagFees: 5,
  //       other1: 0.0,
  //       other2: 0.0,
  //       cardFees: 0,
  //       warranty: 0,
  //       gapInsurance: 0,
  //       mailDeliveryFees: 0,
  //       transportationFees: 0,
  //       totalFees: 0,
  //       totalFeesAndothers: 294.5,
  //       txtState: "",
  //       listingPrice: "",
  //       userListingPrice: 0,
  //       txtTitleType: null,
  //       ownedTrade: "",
  //       cashDifference: "",
  //       paymentPriceFees: "",
  //       date: "",
  //       saleTaxValue: "",
  //       mInventoryValue: "",
  //       balanceDue: "",
  //       totalDue: 0,
  //       // -----------------------------------
  //       lienHolderName: "",
  //       leanHolderId: "",
  //       lienHolderID: "",
  //       lienHolderAddress: "",
  //       lienHolderAddress2: "",
  //       serviceContractId: "",
  //       serviceContractName: "",
  //       serviceContractLicense: "",
  //       serviceContractAddress: "",
  //       serviceProviderActivationStatus: "",
  //       serviceProviderType: "",
  //       providerPhoneNumber: "",
  //       providerCounty: "",
  //       providerListingExpiry: "",
  //       priceFeesPaymentMethod: "",
  //       //Gap Insurance Provider Data
  //       gapInsuranceId: "",
  //       gapName: "",
  //       gapLicense: "",
  //       gapAddress: "",
  //       gapActivationStatus: "",
  //       gapType: "",
  //       gapPhoneNumber: "",
  //       gapCounty: "",
  //       gapListingExpiry: "",
  //       // Sell Form ---------------------------
  //       sellForm: "",
  //       // tax maker forms-----------------------
  //       taxMakerForms: "",
  //       // trade in key--------
  //       dealerId: 0,
  //       vin: "",
  //       year_make_model: "",
  //       // make year modal only check purpose for backend------
  //       make: "",
  //       year: "",
  //       model: "",
  //       bodyClass: "",
  //       wheelbase: "",
  //       driveType: "",
  //       doorCount: "",
  //       // -----------------------
  //       trim: "",
  //       engine: "",
  //       transmission: "",
  //       vehicleType: "",
  //       seat_count: "",
  //       city_mpg: "",
  //       highway_mpg: "",
  //       inspection: "",
  //       exteriorColor: "",
  //       interiorColor: "",
  //       interiorType: "",
  //       mileage_in: "",
  //       purchase_price: "",
  //       //tradeInValue: formData.tradeInValue,
  //       listing_price: "",
  //       mileageIn: "",
  //       // trade in key end--------
  //       // for images of vehicle card--------
  //       imageUrl: "",
  //     })
  //   );
  // };

  // ----------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (data && data.addvertising) {
      const newSocialIcons = [];
      const advertisingData = data.addvertising;

      // Iterate over the keys of the advertisingData object
      for (const key in advertisingData) {
        // Check if the key ends with '_status' and its value is true
        if (key.endsWith("_status") && advertisingData[key]) {
          // Extract the social profile name from the key
          const profile = key.split("_status")[0];
          // Push the profile name to the newSocialIcons array
          newSocialIcons.push(profile);
        }
      }
      // Set the socialIcons state with the newSocialIcons array
      setSocialIcons(newSocialIcons);
    }
  }, [data]);

  const colorMapping = {
    Beige: "#F5F5DC",
    Black: "#151517",
    Blue: "#2c447b",
    Bronze: "#655e5f",
    Brown: "brown",
    "Dark Gray": "#a6b0b6",
    "Dark Red": "#802630",
    Grey: "#808080",
    "Light Gray": "#c6ccd4",
    Magenta: "#916075",
    Maroon: "#8e373e",
    "Off White": "#f4ebdd",
    Orange: "#f79057",
    Pink: "#d60f6f",
    Purple: "#6316bc",
    Red: "#de0a06",
    Silver: "#d4d4d4",
    Tan: "#c3793f",
    White: "#ffffff",
    Yellow: "#f5d512",
    Burgundy: "#800020",
    Charcoal: "#36454F",
    Gold: "#FFD700",
    Green: "#008000",
  };

  // Set the background color dynamically based on the received color names
  const exteriorBackgroundColor = colorMapping[data.exteriorColor];
  const interiorBackgroundColor = colorMapping[data.interiorColor];

  const calculateDaysDifferencepurchase = (purchasedDate) => {
    if (!purchasedDate) return 0; // Ensure formData.inspection is used for calculation
    const currentDate = new Date();
    const selectedDate = new Date(purchasedDate);
    const timeDifference = selectedDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
    );
    // console.log(daysDifference);
    return daysDifference;
  };

  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = data.multimedia;
  const noImage = Image.noImage;

  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${dealerId}/${image.trim()}`;
        }
      }
    }
    return null;
  };
  console.log("dealerId", dealerId);
  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;

  console.log("socialIcons", socialIcons);
  // console.log("data", data);
  // console.log("advertisingPro", advertisingPro);
  const [message, setMessage] = useState("");

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => toast.success("Copied Vin Number!"),
      (err) => toast.error("Failed to copy: " + err)
    );
    // Clear the message after a few seconds (optional)
    setTimeout(() => setMessage(""), 3000);
  };
  //  for redirect the car- details page------------------------------------------------------------------------
  // const handleRedirectCarDetails = (carDeataildata, e) =>{
  //  console.log("carDeataildata",carDeataildata);
  //  const product = carDeataildata;
  //   navigate(
  //     `/cardetail/${product.id}_${product.modelYear}_${product.modelMake}`,
  //     {
  //       state: { product },
  //     }
  //   );
  // }
  const handleRedirectCarDetails = (carDeataildata, e) => {
    console.log("carDeataildata", carDeataildata);
    const product = carDeataildata;
    // Construct the URL for the car details page
    const url = `/cardetail/${product.id}_${product.userId}_${product.modelYear}_${product.modelMake}`;
    // Open the URL in a new tab
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const [addToFeatured, setAddFeatured] = useState(false);
  console.log("addToFeatured => ", addToFeatured);
  const handleAddToFeatured = (e) => {
    const { checked } = e.target;
    setAddFeatured(checked);
    if (checked) {
      handleSendAddToFeaturedStatus();
    }
  };

  const handleSendAddToFeaturedStatus = async () => {
    try {
      const dataTosend = {
        userId: data.userId,
        listingId: data.id,
      };
      // /featured/save , key = userId, listingId
      const response = await axios.post(`${apiUrl}/featured/save`, dataTosend);
      if (response.status === 200) {
        toast.success("Inventory added to my featured");
      }
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  return (
    <>
      <Container className="px-0 bg-tr1">
        <Row
          className="mx-0 rounded bg-body border-2 bg-tr1"
          style={{ position: "relative", zIndex: "5 " }}
        >
          {/* <div className="" style={{position:"relative"}}> */}
          <Col sm={1} className="selection-box px-0" key={index}>
            <div className="card-count p-auto">{index + 1}</div>
            <div className="selection-btn">
              <img
                src={
                  selectedCards && selectedCards.includes(index + 1)
                    ? Image.selected
                    : Image.select
                }
                alt={
                  selectedCards && selectedCards.includes(index + 1)
                    ? "selected"
                    : "select"
                }
                className="select-img"
              />
              {/* {selectedCards.includes(index) ? "S" : "N"} */}
            </div>
          </Col>

          <Col sm={11} className="px-2 cards bg-tr1">
            <Container
              fluid
              className="d-flex flex-nowrap w-100 mb-4"
              onClick={() => {
                handleSelected(index + 1);
              }}
            >
              <Row
                className={`rounded border border-2 p-2 w-100 ${
                  selectedCards && selectedCards.includes(index + 1)
                    ? "bg-card"
                    : ""
                }`}
              >
                <Col md={12} className="">
                  <Container fluid className="px-0">
                    <Row>
                      <Col
                        lg={3}
                        className="shadow px-2 image-con "
                        // style={{ backgroundColor: "#dfbf561a" }}
                      >
                        <div className="product-actives">
                          <span
                            className="add-date"
                            style={{ color: "#D1823A", paddingLeft: "1.7rem" }}
                          >
                            Added: {data.created_at}
                          </span>
                          {/* <div className="img-con"> */}
                          <img
                            src={imageUrl}
                            className=""
                            alt="Inventory Car Image"
                            onClick={(e) => handleOpenImgModal(data, e)}
                          />
                          {/* </div> */}
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            {/* <span
                              className="add-date"
                              style={{ color: "#D1823A" }}
                            >
                              Added: {data.created_at}
                            </span>
                            <span
                              className="Exteriors rounded-pill"
                              style={{
                                backgroundColor: exteriorBackgroundColor,
                              }}
                            >
                              Exterior
                            </span>
                            <span
                              className="Interiors rounded-pill"
                              style={{
                                backgroundColor: interiorBackgroundColor,
                              }}
                            >
                              Interior
                            </span> */}
                            <div
                              className="interior_exterior_color_container"
                              style={{ gap: "4rem" }}
                            >
                              <span>Exterior</span>
                              <span
                                style={{
                                  backgroundColor: exteriorBackgroundColor,
                                  width: "20px",
                                  height: "20px",
                                  color: exteriorBackgroundColor ? "white" : "",
                                }}
                                title={
                                  data.exteriorColor ? data.exteriorColor : ""
                                }
                              ></span>
                              <span
                                style={{
                                  marginLeft: "0.3rem",
                                }}
                              >
                                Interior
                              </span>
                              <span
                                style={{
                                  backgroundColor: interiorBackgroundColor,
                                  width: "20px",
                                  height: "20px",
                                }}
                                title={
                                  data.interiorColor ? data.interiorColor : ""
                                }
                              ></span>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={7} className="px-0 ps-2">
                        <Container fluid className="px-0">
                          <Row>
                            <Col sm={12} className="px-0">
                              <Container>
                                <Row className="d-flex justify-content-between">
                                  <Col
                                    sm={10}
                                    className="card-head"
                                    onClick={(e) =>
                                      handleLinkClickAddlisting(data, e, 0)
                                    }
                                    style={{
                                      textShadow: isFieldUpdated("trim")
                                        ? "#ff8c21 1px 0 10px"
                                        : "black",
                                    }}
                                  >
                                    {data.year_Make_Model} {data.trim}
                                  </Col>
                                </Row>
                              </Container>
                            </Col>

                            <Col sm={12} className="px-0">
                              <Container>
                                <div className="sub-head data-font d-flex justify-content-between">
                                  <div className="text-nowrap mx-2 px-1 vin fw-bold">
                                    <span
                                      className="orange-color "
                                      onClick={(e) =>
                                        handleLinkClickAddlisting(data, e, 0)
                                      }
                                    >
                                      VIN:{" "}
                                      <span>
                                        XXXXXXXXX{data.vin.slice(-8)}{" "}
                                      </span>
                                    </span>

                                    <span
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        className="tooltip-container-active-inventory"
                                        onClick={() =>
                                          copyToClipboard(data.vin)
                                        }
                                      >
                                        <IoCopyOutline className="info-icon-active-inventory" />
                                      </span>
                                      {/* <span>
                                    {message && (
                                      <div
                                        style={{
                                          color: "green",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {message}
                                      </div>
                                    )}
                                    </span> */}
                                    </span>
                                  </div>

                                  <div className="engine">
                                    {data.mileage_in ? (
                                      <span
                                        className=" text-nowrap fw-bold gray-color border-2 px-2"
                                        style={{
                                          textShadow: isFieldUpdated(
                                            "mileage_in"
                                          )
                                            ? "#ff8c21 1px 0 10px"
                                            : "black",
                                          color: isFieldUpdated("mileage_in")
                                            ? "orange"
                                            : "black",
                                        }}
                                      >
                                        {new Intl.NumberFormat().format(
                                          data.mileage_in
                                        )}
                                        {/* {data.mileage_in} */}
                                      </span>
                                    ) : null}

                                    {data.engine ? (
                                      <span
                                        className=" border-2 px-0 "
                                        style={{
                                          textShadow: isFieldUpdated("engine")
                                            ? "#ff8c21 1px 0 10px"
                                            : "black",
                                        }}
                                      >
                                        <span>
                                          {" "}
                                          {data?.engine.split(" ")[1]}{" "}
                                        </span>{" "}
                                        &#x2022;
                                        <span>
                                          {" "}
                                          {data?.engine.split(" ")[0]}{" "}
                                        </span>{" "}
                                        &#x2022;
                                        <span>
                                          {" "}
                                          {data?.engine.split(" ")[3]}{" "}
                                        </span>{" "}
                                        &#x2022;
                                        <span>
                                          {" "}
                                          {data.transmission ||
                                            "Transmission NA"}{" "}
                                        </span>{" "}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </Container>
                            </Col>

                            <Col sm={12} className="px-0">
                              <Container fluid>
                                <Row className="vin-info">
                                  <Col
                                    className="border-end border-2 px-0 "
                                    style={{
                                      textShadow: isFieldUpdated(
                                        "purchasedDate"
                                      )
                                        ? "#ff8c21 1px 0 10px"
                                        : "black",
                                      // color: isFieldUpdated("trim") ? "orange" : "black",
                                    }}
                                  >
                                    <OverlayTrigger
                                      placement="bottom-end"
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-${index}`}
                                          className="custom-tooltip"
                                        >
                                          Days In:{" "}
                                          {calculateDaysDifferencepurchase(
                                            data.purchasedDate
                                          )}
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        P.Date
                                        <p className="orange-color">
                                          {data.purchasedDate}
                                        </p>
                                      </div>
                                    </OverlayTrigger>
                                  </Col>
                                  <Col
                                    className="border-end border-2 px-0"
                                    style={{
                                      textShadow: isFieldUpdated("inspection")
                                        ? "#ff8c21 1px 0 10px"
                                        : "black",
                                      // color: isFieldUpdated("trim") ? "orange" : "black",
                                    }}
                                  >
                                    <OverlayTrigger
                                      placement="bottom-end"
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-${index}`}
                                          className="custom-tooltip"
                                        >
                                          Days In: {data.daysDifference}
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        Inspection
                                        <p className="orange-color">
                                          {data.inspection}
                                        </p>
                                      </div>
                                    </OverlayTrigger>
                                  </Col>
                                  <Col className="border-end border-2 px-0">
                                    All leaads{" "}
                                    <p className="orange-color">23</p>
                                  </Col>
                                  <Col
                                    className="border-end border-2 px-0"
                                    style={{
                                      textShadow: isFieldUpdated(
                                        "listing_price"
                                      )
                                        ? "#ff8c21 1px 0 10px"
                                        : "black",
                                      // color: isFieldUpdated("trim") ? "orange" : "black",
                                    }}
                                  >
                                    Listing price
                                    <p className="orange-color">
                                      $
                                      {new Intl.NumberFormat().format(
                                        data.listing_price
                                      )}
                                      {/* ${data.listing_price} */}
                                    </p>
                                  </Col>
                                  <Col
                                    className=" px-0"
                                    style={{
                                      textShadow: isFieldUpdated(
                                        "market_value_price"
                                      )
                                        ? "#ff8c21 1px 0 10px"
                                        : "black",
                                      // color: isFieldUpdated("trim") ? "orange" : "black",
                                    }}
                                  >
                                    Market value{" "}
                                    <p className="orange-color">
                                      $
                                      {new Intl.NumberFormat().format(
                                        data.market_value_price
                                      )}
                                      {/* ${data.market_value_price} */}
                                    </p>
                                  </Col>
                                </Row>
                              </Container>
                            </Col>
                          </Row>

                          <Row className="mt-1" style={{ marginRight: "1%" }}>
                            <Col sm={12} className=" d-flex align-items-end">
                              {/* <Button
                                className=" border-0 p-1 bg-orange fw-bold"
                                onClick={(e) => handleAddsPro(e)}
                              >
                                Advertised on
                              </Button> */}
                              <div className=" w-100 d-flex justify-content-evenly flex-wrap">
                                {socialIcons.map((iconName, index) => (
                                  <img
                                    key={index}
                                    className="m-2 social-icon"
                                    src={Image[iconName]} // Corrected
                                    alt={iconName}
                                    width="25px"
                                  />
                                ))}
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Col>

                      <Col
                        lg={2}
                        className=" px-1 d-flex flex-column align-items-center"
                      >
                        <h6 className="data-font text-nowrap stock-no mb-1">
                          <span className="">
                            {" "}
                            <label
                              htmlFor="featuredInv"
                              style={{ marginRight: "1rem" }}
                            >
                              <input
                                type="checkbox"
                                name=""
                                id="featuredInv"
                                style={{ marginRight: "0.2rem" }}
                                title="Add to my featured inventory"
                                checked={addToFeatured}
                                onChange={handleAddToFeatured}
                              />{" "}
                              Add to featured
                            </label>
                            <span
                              onClick={(e) =>
                                handleLinkClickAddlisting(data, e, 0)
                              }
                            >
                              Stock. no #{" "}
                            </span>
                          </span>{" "}
                          {data.stock_no}
                        </h6>
                        <div
                          className="d-flex flex-column justify-content-around align-items-center mt-4"
                          // style={{ gap: "4.8rem" }}
                        >
                          {" "}
                          <Button
                            className="product-g-btns fw-bold p-1"
                            onClick={handleGoToSell}
                          >
                            {" "}
                            Sell{" "}
                          </Button>
                          <Button
                            className="product-g-btns border-0 fw-bold p-1"
                            variant="secondary"
                            onClick={(e) => handleAddsPro(e)}
                          >
                            Live
                          </Button>
                          <Button
                            className="product-g-btns border-0 fw-bold p-1"
                            variant="secondary"
                            onClick={(e) =>
                              handleLinkClickAddlistingAddCost(data, e, 1)
                            }
                          >
                            Add Cost
                          </Button>
                          <Button
                            className="product-g-btns border-0 fw-bold p-1"
                            variant="secondary"
                            onClick={(e) => handleRedirectCarDetails(data, e)}
                          >
                            Web-View
                          </Button>
                          {/* <Button
                            className="product-g-btns-recall border-0 fw-bold p-1"
                            variant="secondary"
                            style={{
                              backgroundColor: recallNumber
                                ? "green"
                                : "#e8a262", 
                              color: "white", 
                            }}
                            onClick={() => handleCheckReCall(data)}
                          >
                            {recallNumber ? recallNumber : "Check Re-call"}
                          </Button> */}
                          {/* <Button
                            className="btn-sec border-0 fw-bold p-1"
                            variant="secondary"
                          >
                            VHR
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              {/* <div
                className="row mb-3 h-100"
                style={{
                  display: "inline-block",
                  width: "5%",
                  position: "absolute",
                  right: "1.5%",
                }}
              >
                <Container fluid className="px-0 h-100">
                  <div className="row d-flex flex-nowrap h-100">
                    <div className="col-3 h-100 px-0 d-flex flex-column justify-content-between">
                      <div
                        className="d-flex align-items-center justify-content-around"
                        style={{ height: "100%" }}
                      >
                        <div className="vr pr-2 mx-1"></div>
                        <div className="d-flex flex-column align-items-center justify-content-around h-100">
                          <div
                            className=" ml-3 rounded-circle px-2 py-1 bg-light edit"
                            onClick={(e) => handleLinkClickAddlisting(data, e)}
                          >
                            <MdModeEdit />
                          </div> 
                          <div
                            className=" ml-3 rounded-circle px-2 py-1 bg-light edit"
                            onClick={(event) =>
                              handleDelete(event, data.id, index)
                            }
                          >
                            <MdDelete />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div> */}
            </Container>
          </Col>
        </Row>
      </Container>

      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Advertising Manager
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div id="refresh-btn-seller">
              <button
                type="button"
                id="adlst-reset-btn-seller"
                onClick={resetCheckboxes}
                title="Reset"
              >
                <img src={Image.reset_button} alt="Reset Button" />
              </button>
            </div>
            <div className="tab-buttons">
              <section className="container">
                <div className="row">
                  {/* Card for Dealers Auto Center Market Place */}
                  <AdvertisingProfile
                    title="Dealers Auto Center Market Place"
                    logo={Image.darkLogo}
                    statusName="dacmp_status"
                    intervalName="dacmp_interval"
                    status={advertisingPro.dacmp_status}
                    interval={advertisingPro.dacmp_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("dacmp_connected")
                    }
                    isConnected={connectionStatus.dacmp_connected}
                    isAllInterval={true}
                    setAllIntervals={setAllIntervals}
                    readonly={true}
                  />

                  {/* Card for United Motors */}
                  <AdvertisingProfile
                    title="Website (United Motors)"
                    logo={Image.darkLogo}
                    statusName="unitedmotors_status"
                    intervalName="unitedmotors_interval"
                    status={advertisingPro.unitedmotors_status}
                    interval={advertisingPro.unitedmotors_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("unitedmotors_connected")
                    }
                    isConnected={connectionStatus.unitedmotors_connected}
                    readonly={true}
                  />

                  {/* Card for Google Market Place */}
                  <AdvertisingProfile
                    title="Google Market Place"
                    logo={Image.google}
                    statusName="google_status"
                    intervalName="google_interval"
                    status={advertisingPro.google_status}
                    interval={advertisingPro.google_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("google_connected")
                    }
                    isConnected={connectionStatus.google_connected}
                  />

                  {/* Card for Instagram */}
                  <AdvertisingProfile
                    title="Instagram"
                    logo="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
                    statusName="insta_status"
                    intervalName="insta_interval"
                    status={advertisingPro.insta_status}
                    interval={advertisingPro.insta_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("insta_connected")
                    }
                    isConnected={connectionStatus.insta_connected}
                  />

                  {/* Card for Facebook */}
                  <AdvertisingProfile
                    title="Facebook"
                    logo={Image.facebook}
                    statusName="facebook_status"
                    intervalName="facebook_interval"
                    status={advertisingPro.facebook_status}
                    interval={advertisingPro.facebook_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("facebook_connected")
                    }
                    isConnected={connectionStatus.facebook_connected}
                    isFacebook={true}
                    facebookMarketplaceStatus={
                      advertisingPro.facebook_marketplace_status
                    }
                    facebookGroupsStatus={advertisingPro.facebook_groups_status}
                    handleFacebookCheckboxChange={handleFacebookCheckboxChange}
                  />

                  {/* Card for Craigslist */}
                  <AdvertisingProfile
                    title="Craigslist"
                    logo={Image.craigslist}
                    statusName="craigslist_status"
                    intervalName="craigslist_interval"
                    status={advertisingPro.craigslist_status}
                    interval={advertisingPro.craigslist_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("craigslist_connected")
                    }
                    isConnected={connectionStatus.craigslist_connected}
                  />

                  {/* Card for Twitter */}
                  <AdvertisingProfile
                    title="Twitter"
                    logo={Image.twitter_x_logo}
                    statusName="twitter_status"
                    intervalName="twitter_interval"
                    status={advertisingPro.twitter_status}
                    interval={advertisingPro.twitter_interval}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectChange={handleSelectChange}
                    toggleImage={() =>
                      toggleConnectionStatus("twitter_connected")
                    }
                    isConnected={connectionStatus.twitter_connected}
                  />
                </div>
              </section>
            </div>
            <div className="d-flex justify-content-center ">
              <Button
                className="btn-orange border-0 py-2 px-3"
                onClick={updateAdvertisement}
              >
                Publish
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SellData
        show={showModalSell}
        handleClose={handleModalCloseSell}
        VehicleName={data}
        status={false}
      />
      <AddlistingData
        show={showModalAddlisting}
        showModalImage={showModalImage}
        handleClose={handleModalCloseAddlisting}
        showModalAdd={showModalAdd}
        isImageM={isImageM}
        setShowModalImage={setShowModalImage}
        handleCloseImgModal={handleCloseImgModal}
        addlistingData={addlistingData}
        handleInventoryDelete={handleDelete}
        InventoryIndex={index + 1}
        addlistionIdShow={3}
        addCost={addCostStatus}
        setCounterAddListing = {setCounterAddListing}
      />
      <AddlistingDataAddCost
        show={showModalAddlistingAddCost}
        // showModalImage={showModalImage}
        handleClose={handleModalCloseAddlistingAddCost}
        // showModalAdd={showModalAdd}
        // isImageM={isImageM}
        // setShowModalImage={setShowModalImage}
        // handleCloseImgModal={handleCloseImgModal}
        addlistingData={addlistingData}
        handleInventoryDelete={handleDelete}
        InventoryIndex={index + 1}
        addlistionIdShow={3}
        addCostStatus={addCostStatus}
      />
    </>
  );
};

// Define the calculateDaysDifference function outside of ListView
const calculateDaysDifference = (inspectionDate) => {
  if (!inspectionDate) return 0;
  const currentDate = new Date();
  const inspectionDateObj = new Date(inspectionDate);
  const timeDifference = inspectionDateObj - currentDate;
  return Math.ceil(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));
};

export default CardView;
