import React, { forwardRef, useEffect, useState } from "react";
import "./Calculator.css";
import styles from "./calculator.module.css";
import { withExtraArgument } from "redux-thunk";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdInformationCircleOutline } from "react-icons/io";

const MonthlyLoanCalculator = forwardRef((props, ref) => {
  const [vehiclePrice, setVehiclePrice] = useState("15000");
  const [tradeInValue, setTradeInValue] = useState(0);
  const [ttlPrice, setTtlPrice] = useState(0);
  const [interestRate, setInterestRate] = useState("5.85");
  const [downPayment, setDownPayment] = useState(0);
  const [loanTermInMonth, setLoanTermInMonth] = useState("48");
  const [activeButton, setActiveButton] = useState("48");
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [loanAountData, setLoanAmountData] = useState(0);
  const [activeRate, setActiveRate] = useState(5.85); //for button bg color only----------
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (vehiclePrice) {
      // Calculate 10% of vehiclePrice
      const calculatedDownPayment = parseFloat(vehiclePrice) * 0.1;
      setDownPayment(calculatedDownPayment); // Update downPayment with two decimal places
    } else {
      setDownPayment(""); // Clear downPayment if vehiclePrice is empty
    }
  }, [vehiclePrice]);

  useEffect(() => {
    if (vehiclePrice) {
      // Calculate 65% of vehiclePrice
      const calculatedttlPrice = parseFloat(vehiclePrice) * 0.065;
      const calculatedTtlPrice = Math.ceil(calculatedttlPrice);
      setTtlPrice(calculatedTtlPrice); // Update downPayment with two decimal places
    } else {
      setTtlPrice(""); // Clear downPayment if vehiclePrice is empty
    }
  }, [vehiclePrice]);

  useEffect(() => {
    calculateMonthlyPayment();
  }, [
    vehiclePrice,
    tradeInValue,
    interestRate,
    downPayment,
    loanTermInMonth,
    ttlPrice,
  ]);

  const calculateMonthlyPayment = () => {
    const P = parseFloat(vehiclePrice) + parseFloat(ttlPrice);
    const TIV = parseFloat(tradeInValue);
    const r = parseFloat(interestRate) / 100 / 12;
    const DP = parseFloat(downPayment);
    const n = parseInt(loanTermInMonth, 10);

    if (isNaN(P) || isNaN(TIV) || isNaN(r) || isNaN(DP) || isNaN(n)) {
      return;
    }

    const loanAmount = P - DP - TIV;
    setLoanAmountData(loanAmount);
    const M =
      (loanAmount * (r * Math.pow(1 + r, n))) / (Math.pow(1 + r, n) - 1);
    //const roundedValue = M > 0 ? Math.round(M) : Math.ceil(M);
    setMonthlyPayment(Math.ceil(M));
  };
  // bg color-----------------------------------
  const handleButtonClickb = (rate) => {
    setInterestRate(rate);
    setActiveRate(rate); // Set the active button based on the rate
  };

  // Function to get button styles based on whether it's active
  const getButtonStyles = (rate) => ({
    backgroundColor: activeRate === rate ? "#d1823a" : "white", // Active button color
    color: activeRate === rate ? "white" : "black", // Active text color
  });
  // btn bg color---------------------------------------
  // console.log("vehiclePrice:", vehiclePrice);
  // console.log("tradeInValue:", tradeInValue);
  // console.log("interestRate:", interestRate);
  // console.log("downPayment:", downPayment);
  // console.log("loanTermInMonth:", loanTermInMonth);

  // const handleButtonClick = (value) => {
  //   if (activeButton === value) {
  //     setActiveButton(null);
  //     setLoanTermInMonth('');
  //   } else {
  //     setActiveButton(value);
  //     setLoanTermInMonth(value);
  //   }
  // };

  const handleButtonClick = (value) => {
    if (activeButton === value) {
      setActiveButton(null);
      setLoanTermInMonth("");
    } else {
      setActiveButton(value);
      setLoanTermInMonth(value);
    }
  };

  const getButtonStyle = (value) => {
    return activeButton === value
      ? {
          backgroundColor: "#d1823a",
          color: "white",
          borderRadius: "10px",
          border: "none",
        }
      : {
          backgroundColor: "white",
          color: "black",
          borderRadius: "10px",
          border: "none",
        };
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      handleButtonClick(inputValue);
      setShowInput(false); // Hide input field after pressing Enter
    }
  };

  const handleInputBlur = () => {
    // Hide input field if it loses focus
    setShowInput(false);
  };
  // ------------------------------------------------------------------------------------
  const handleMoreButtonClick = () => {
    setShowInput(true);
  };
  // ------------------------------------------------------------------------------------
  return (
    <>
      <section className={` ${styles.bgGradient}`} ref={ref}>
        {/* <h1>Auto Loan Calculator</h1> */}
        <div className={` ${styles.cylenderContainer}`}>
          <div className={` ${styles.cylender} ${styles.cylender1}`}></div>
          <div className={` ${styles.cylender} ${styles.cylender2}`}></div>
          <div className={` ${styles.cylender} ${styles.cylender3}`}></div>
        </div>
        <Container
          // fluid
          // className=""
          // style={{
          //   backgroundColor: "transparent",
          //   position: "relative",
          //   top: "60px",
          // }}
          className={styles.CalculatorParent}
        >
          <h2  className="mlcMobileHeader">Auto Loan Container</h2>
          <Row
            className="h-auto CalculatorSubParent"
            // style={{ border: "3px solid black" }}
          >
            <Col
              // sm={6}
              // style={{ paddingLeft: "11rem" }}
              className="CalculatorSubHalfPart"
            >
              <form action="" method="post">
                <p className="autoLoanContainerHeaderDesktop">
                  Auto Loan Container
                </p>
                <p className="mlcMobileResult">
                  <span> ${new Intl.NumberFormat().format(monthlyPayment)}</span>
                </p>
                <div className={styles.formRow1}>
                  <div
                    className={`mt-2 ${styles.formRow1Child}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "black",
                    }}
                  >
                    <span>
                      <p
                        className={styles.label}
                        style={{ marginBottom: "0px" }}
                      >
                        Vehicle Amount
                      </p>
                    </span>
                    <div className={styles.inputConPercent}>
                      <span
                        className={styles.percentSymbol}
                        style={{ fontWeight: "bolder", fontSize: "20px" }}
                      >
                        $
                      </span>
                      <input
                        type="text"
                        className={styles.formControl}
                        value={vehiclePrice}
                        onChange={(e) => setVehiclePrice(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    className={`mt-2 ${styles.Intl} ${styles.formRow1Child}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "black",
                    }}
                  >
                    <span>
                      <p
                        className={styles.label}
                        style={{ marginBottom: "0px" }}
                      >
                        <span>TTL</span> Est.
                      </p>
                    </span>
                    <div className={styles.inputConPercent}>
                      <span
                        className={styles.percentSymbol}
                        style={{ fontWeight: "bolder", fontSize: "20px" }}
                      >
                        $
                      </span>
                      <input
                        type="text"
                        className={styles.formControl}
                        value={ttlPrice}
                        onChange={(e) => setTtlPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.formRow1}>
                  <div
                    className={`mt-2 ${styles.formRow1Child}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "black",
                    }}
                  >
                    <span>
                      <p
                        className={styles.label}
                        style={{ marginBottom: "0px" }}
                      >
                        Down Payment
                      </p>
                    </span>
                    <div className={styles.inputConPercent}>
                      <span
                        className={styles.percentSymbol}
                        style={{ fontWeight: "bolder", fontSize: "20px" }}
                      >
                        $
                      </span>
                      <input
                        type="text"
                        className={styles.formControl}
                        value={downPayment}
                        onChange={(e) => setDownPayment(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    className={`mt-2 ${styles.formRow1Child}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "black",
                    }}
                  >
                    <span>
                      <p
                        className={styles.label}
                        style={{ marginBottom: "0px" }}
                      >
                        Interest Rate
                      </p>
                    </span>
                    <div className={styles.inputConPercent}>
                      <input
                        type="text"
                        className={styles.formControl}
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)}
                      />
                      <span
                        className={styles.percentSymbol}
                        style={{ fontWeight: "bolder", fontSize: "20px" }}
                      >
                        %
                      </span>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between", }}>
                    <span className={` ${styles.minMax}`}>Min. 1</span>
                    <span className={` ${styles.minMax}`}>Max. 72</span>
                  </div>
                  <input type="range" className={styles.inputRange} min="0" max="20000" /> */}
                  </div>
                </div>

                {/* <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Loan Terms (Monthly)
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    <span
                      className={styles.percentSymbol}
                      style={{ fontWeight: "bolder", fontSize: "20px" }}
                    >
                      $
                    </span>
                    <input
                      type="text"
                      className={styles.formControl}
                      value={loanTermInMonth}
                      onChange={(e) => setLoanTermInMonth(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className={styles.formRow1}>
                  <div
                    className={`mt-2 ${styles.formRow1Child}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "black",
                    }}
                  >
                    <span>
                      <p
                        className={styles.label}
                        style={{ marginBottom: "0px" }}
                      >
                        Trade-in-Value
                      </p>
                    </span>
                    <div className={styles.inputConPercent}>
                      <span
                        className={styles.percentSymbol}
                        style={{ fontWeight: "bolder", fontSize: "20px" }}
                      >
                        $
                      </span>
                      <input
                        type="text"
                        className={styles.formControl}
                        value={tradeInValue}
                        onChange={(e) => setTradeInValue(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className={`mt-2 ${styles.formRow1Child}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "black",
                    }}
                  >
                    <span>
                      <p
                        className={styles.label}
                        style={{ marginBottom: "0px" }}
                      >
                        Loan Terms
                        {/* (Monthly) */}
                      </p>
                    </span>
                    <div className={styles.inputConPercent}>
                      <div className={styles.loanTermsParent}>
                        <span className={` ${styles.txtBtnCarHome}`}>
                          <button
                            type="button"
                            onClick={() => handleButtonClick("36")}
                            style={getButtonStyle("36")}
                          >
                            36
                          </button>
                        </span>
                        <span className={` ${styles.txtBtnCarHome}`}>
                          <button
                            type="button"
                            onClick={() => handleButtonClick("48")}
                            style={getButtonStyle("48")}
                          >
                            48
                          </button>
                        </span>
                        <span className={` ${styles.txtBtnCarHome}`}>
                          <button
                            type="button"
                            onClick={() => handleButtonClick("60")}
                            style={getButtonStyle("60")}
                          >
                            60
                          </button>
                        </span>
                        {/* <span className={` ${styles.txtBtnCarHome}`}>
                      <button
                        type="button"
                        onClick={() => handleButtonClick("72")}
                        style={getButtonStyle("72")}
                      >
                        72
                      </button>
                    </span> */}
                        <span className={` ${styles.txtBtnCarHome}`}>
                          {showInput ? (
                            <input
                              type="text"
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyDown={handleInputKeyDown}
                              onBlur={handleInputBlur}
                              placeholder="Enter"
                              style={{
                                width: "5rem",
                                height: "100%",
                                borderRadius: "5px",
                              }}
                            />
                          ) : (
                            <button
                              type="button"
                              onClick={handleMoreButtonClick}
                              style={getButtonStyle(inputValue)}
                            >
                              more
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="mlcApproxCreditScoreContainer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                    // padding: "0 1.5rem",
                  }}
                >
                  <span>
                    <p className={styles.label} style={{ marginBottom: "0px" }}>
                      Approx. Credit Score
                    </p>
                  </span>
                  <div className={styles.inputConPercent}>
                    {/* <input
                    {/* <input
                      type="text"
                      className={styles.formControl}
                      value={vehiclePrice}
                      onChange={(e) => setVehiclePrice(e.target.value)}
                    />
                    <span className={styles.percentSymbol} style={{ fontWeight: "bolder", fontSize: "20px" }}>%</span> */}

                    <div className={styles.approxCreditScore}>
                      <span className={` ${styles.txtBtnCarHome2}`}>
                        <button
                          type="button"
                          style={getButtonStyles(11)}
                          onClick={() => handleButtonClickb(11)}
                        >
                          <p style={{ fontWeight: "bold" }}>Poor</p>
                          <p>630 or less</p>
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome2}`}>
                        <button
                          type="button"
                          style={getButtonStyles(6.85)}
                          onClick={() => handleButtonClickb(6.85)}
                        >
                          <p style={{ fontWeight: "bold" }}>Fair</p>
                          <p>640 or 699</p>
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome2}`}>
                        <button
                          type="button"
                          style={getButtonStyles(5.85)}
                          onClick={() => handleButtonClickb(5.85)}
                        >
                          <p style={{ fontWeight: "bold" }}>Good</p>
                          <p>700 or 749</p>
                        </button>
                      </span>
                      <span className={` ${styles.txtBtnCarHome2}`}>
                        <button
                          type="button"
                          style={getButtonStyles(4)}
                          onClick={() => handleButtonClickb(4)}
                        >
                          <p style={{ fontWeight: "bold" }}>Excellent</p>
                          <p>750 or 850</p>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <p className="mlcApprovedBtnContainerMobile">
                  <span>Get Pre-Approved</span>
                  <button>Apply</button>
                </p>
              </form>
            </Col>

            <Col
              // sm={6}
              className=" CalculatorSubHalfPart CalculatorSecondSubHalfPart"
            >
              <div className={` ${styles.loadValContainer}`}>
                <span>${new Intl.NumberFormat().format(monthlyPayment)}</span>
                <span>Month</span>
              </div>

              <div
                className=" d-flex justify-content-around align-items-center"
                // style={{ gap: "2.5rem" }}
              >
                <p className="mlcApprovedBtnContainer">
                  <span>Get Pre-Approved</span>
                  <button>Apply</button>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
});

export default MonthlyLoanCalculator;
