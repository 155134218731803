import { createSlice } from "@reduxjs/toolkit";
import { sideBarArray } from "../../components/dashboard/SideBarData";

const initialState = {
  email: "",
  id: 0,
  token: "",
  dealerId: 0,
  sideBarItemName: "Dashboard",
  firstName: "User",
  lastName: "",
  phoneNumber: "",
  office_number: "",
  lattitude: "",
  longitude: "",
  zip_code: "",
  address: "",
  profile_picture: null,
  website: "",
  roles: "",
  showUserCompletePofileDialogueBox: false,
  sideBarOrder: sideBarArray.map(({ id, count }) => ({ id, count: 0 })),
  showLoginDialogue: false,
  loginAccess: 0,
  dealerApproval: 0,
  dealerData: 0,
  isSideBarOpen: false,
  currentPage: "Dashboard",
};
console.log("Order in sidebar in slice", initialState);

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setShowUserCompleteProfileDialogueBox: (state, action) => {
      state.showUserCompletePofileDialogueBox = action.payload;
    },

    logoutUser: (state) => {
      state.email = "";
      state.id = 0;

      state.firstName = "User";
      state.lastName = "";
      state.office_number = "";
      state.phoneNumber = "";
      state.lattitude = "";
      state.longitude = "";
      state.zip_code = "";
      state.address = "";
      state.profile_picture = null;
      state.website = "";
      state.roles = "";
      state.showUserCompletePofileDialogueBox = false;
    },
    updateSidebarClickCount(state, action) {
      const { id } = action.payload;
      const itemIndex = state?.sideBarOrder?.findIndex(
        (item) => item.id === id
      );
      if (itemIndex >= 0) {
        state.sideBarOrder[itemIndex].count += 1;
      }
    },
    updateInitialDataFromAPI(state, action) {
      // state.sideBarOrder = action.payload;
      state.sideBarOrder = action.payload;
      // console.log("sideBarArrayFromAPI  => ",sideBarArrayFromAPI);
    },

    resetAllSidebarCounts(state) {
      state.sideBarOrder = state.sideBarOrder.map((item) => ({
        ...item,
        count: 0,
      }));
    },
  },
});

export const {
  saveUser,
  logoutUser,
  setShowUserCompleteProfileDialogueBox,
  updateSidebarClickCount,
  updateInitialDataFromAPI,
  resetAllSidebarCounts,
} = userSlice.actions;
export default userSlice.reducer;
