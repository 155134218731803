import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import { setIsSomethingChanged } from "../../../../Redux/slice/sellSlice";
// Function to format numbers with commas
const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
};
const Box_Ea1 = ({ retailFromRedux }) => {
  const cashDifference = useSelector(
    (state) => state.taxesAndFees.dealerRetail.cashDifference
  );
  const userListingPrice = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.userListingPrice)
  );
  const totalFees = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.totalFees)
  );
  const totalFeesAndothers = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.totalFeesAndothers)
  );
  const totalDue = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.totalDue) || 0
  );
  const dOut = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.dOut) || 0
  );
  const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  const allInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs
  );
  const lenghtInputs = allInputs?.length;
  const totalDueSum = parseFloat(
    cashDifference + totalFees + totalFeesAndothers
  ).toFixed(2);
  const dispatch = useDispatch();
  const initialValues = [
    // { header: "Total Due", InputValue: cashDifference, name: "cashDifference" },
    {
      header: "D. Out",
      InputValue: dOut,
      name: "dOut",
    },
  ];

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "dOut" ? { ...item, InputValue: dOut } : item
      )
    );
  }, [dOut]);
  const [inputValues, setInputValues] = useState(initialValues);
  const handleInputChange = (name, value) => {
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
    dispatch(setIsSomethingChanged(true));
  };
  const [paymentMethod, setPaymentMethod] = useState("");

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  console.log("line53", paymentMethod);
  useEffect(() => {
    dispatch(
      setRetailData({
        totalDue: totalDueSum,
      })
    );
    dispatch(setIsSomethingChanged(true));
  }, [totalDueSum]);
  return (
    <div
      className={Styles.wrapper}
      style={{ borderRight: "0px", borderLeft: "0px" }}
    >
      <div className={Styles.container}>
        <div
          className={`${Styles.inputFieldContainer} ${Styles.netTotalDUEContainer}`}
        >
          <span>
            {purchase_price > 0 ? lenghtInputs + 11 : lenghtInputs + 8}. TOTAL
            DUE :{" "}
          </span>
          <p className={Styles.dueData} style={{ marginBottom: "4px" }}>
            <span>$</span>{formatNumberWithCommas(totalDue)}
          </p>
        </div>
        {inputValues.map((item) => (
          <InputFieldComp
            item={item}
            key={item.header}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
          />
        ))}
      </div>
    </div>
  );
};

export default Box_Ea1;
