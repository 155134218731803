import React, { useEffect, useState } from "react";
import styles from "../styles/LoginComponent.module.css";
import axios from "axios";
import { UserCity } from "../../components/UserAuthDialogue/Pages/UserCity";
import toast from "react-hot-toast";
import UserQueryAuthModel from "./UserQueryAuthModel";

const LoginComponent = ({ setUserData }) => {
  const styleObj = {
    bgColor: "white",
    inputBg: "rgb(243, 223, 196)",
    buttonBg: "rgb(212 138 70 / 75%)",
    fontColor: "black",
    fontType:
      "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  };
  const [styleState, setstyleState] = useState(styleObj);
  //   Login Funcitonlity starts fom here
  const [inputEmail, setInputEmail] = useState("");
  console.log(inputEmail);
  const [isUserExists, setIsUserExists] = useState(true);
  console.log("Does user exist ?", isUserExists);
  const [loading, setLoading] = useState(false);

  const [city, setCity] = useState("");
  useEffect(() => {
    async function fetchCity() {
      const userCity = await UserCity();
      console.log(userCity);
      if (userCity) {
        setCity(userCity);
      }
    }
    fetchCity();
  }, []);
  const handleContinueResult = async () => {
    console.log("Handling API continue Result");
    setLoading(true);
    try {
      const unformattedPhoneNumber = inputEmail.replace(/\D/g, "");
      const formData = new FormData();
      if (/^\d{10}$/.test(unformattedPhoneNumber)) {
        console.log(unformattedPhoneNumber);
        console.log("Number detected");

        formData.append("email", unformattedPhoneNumber);
      } else {
        console.log("Number not detected");
        formData.append("email", inputEmail);
      }
      // formData.append("user_type", "User");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/authentication/signin`,
        formData
      );

      if (res.status === 200) {
        console.log(res.data);

        const formattedEmail = !isNaN(inputEmail.replace(/[()-\s]/g, ""))
          ? inputEmail.replace(/[()-\s]/g, "")
          : inputEmail;

        setUserData((prevData) => ({
          ...prevData,
          email: formattedEmail,
          isUserExists: true,
        }));

        setLoading(false);
      }
      console.log(res);
    } catch (error) {
      console.log(error, "Error while logging the user");
      setLoading(false);
      if (error.response.status === 400) {
        toast.error(error.response.data);
      } else if (error.response.status === 500) {
        toast.error(error.response.data);
      }
    }
  };
  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);

        setCountryCode(res.data.country_calling_code);
      } catch (error) {
        console.log(error);
      }

      // setIP(res.data.ip)
    };
    getUserCountryCode();
  }, [countryCode]);

  //   Function for handlig the User Registration
  const handleCreateResult = async () => {
    if (inputEmail)
      try {
        setLoading(true);
        const unformattedPhoneNumber = inputEmail.replace(/\D/g, "");
        const formData = new FormData();
        if (/^\d{10}$/.test(unformattedPhoneNumber)) {
          console.log(unformattedPhoneNumber);
          console.log("Number detected");

          formData.append("email", unformattedPhoneNumber);
        } else {
          console.log("Number not detected");
          formData.append("email", inputEmail);
        }
        formData.append("country_code", countryCode);
        // formData.append("user_type", userTypeSelected);
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/authentication/signup`,
          formData
        );

        if (res.status === 200) {
          console.log(res.data);
          setLoading(false);
          toast.success("OTP sent to your device.");

          // Format email/phone before setting userData
          const formattedEmail = !isNaN(inputEmail.replace(/[()-\s]/g, ""))
            ? inputEmail.replace(/[()-\s]/g, "")
            : inputEmail;

          setUserData((prevData) => ({
            ...prevData,
            email: formattedEmail,
            isUserExists: false,
          }));
        }
        console.log("resUser is for signup");
        console.log(res);
      } catch (error) {
        console.log(error, "Error while creating the user");
        setLoading(false);
        console.log("Error.response.status", error);
        if (error.response.status === 400) {
          toast.error(error.response.data);
          setIsUserExists(true);
          setInputEmail("");
        } else if (error.response.status === 500) {
          toast.error(error.response.data);
        }
      }
  };

  useEffect(() => {
    if (inputEmail.trim() === "") {
      setIsUserExists(true);
    }
  }, [inputEmail]);
  useEffect(() => {
    const handlekeypress = (e) => {
      if (e.key === "Enter") {
        if (isUserExists) {
          handleContinueResult();
        } else {
          // handleCreateResult();
          // setShowQueryModel(true);
        }
      }
    };
    window.addEventListener("keypress", handlekeypress);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keypress", handlekeypress);
    };
  }, [isUserExists, inputEmail]);

  const handleInputChange = async (e) => {
    const { value } = e.target;

    // Check if the input is a phone number
    if (/^\d{10}$/.test(value)) {
      // Format the phone number to USA format (assuming 10 digits)
      const formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6)}`;
      setInputEmail(formattedPhoneNumber);

      // Remove formatting for API submission
      const unformattedPhoneNumber = value.replace(/\D/g, "");
      if (unformattedPhoneNumber.length === 10) {
        await callAPI(unformattedPhoneNumber);
      }
    } else {
      setInputEmail(value);

      // Check if a dot is typed after "@"
      const indexOfAt = value.indexOf("@");
      const indexOfDot = value.indexOf(".", indexOfAt);
      if (indexOfDot !== -1) {
        // Call your API here
        await callAPI(value);
      }
    }
  };

  const callAPI = async (email) => {
    try {
      console.log("Calling the API");
      const formData = new FormData();
      formData.append("email", email);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/check_account`,
        formData
      );
      console.log(res.data);
      if (res.status == 200) {
        // navigate("/loginpassword", { state: res.data });
        console.log("Status 200");
        setIsUserExists(res.data);
      } else {
        console.log("Login Failed...");
      }
    } catch (error) {
      console.log("error in catch");
    }
  };
  // Show the Query Model after successful email sending that for who the user is

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundColor: styleState.bgColor,
        fontFamily: styleState.fontType,
      }}
    >
      <div className={styles.container}>
        <p className={styles.header}>Login or Create</p>
        <input
          type="text"
          className={styles.inputField}
          style={{
            backgroundColor: styleState.inputBg,
          }}
          onChange={handleInputChange}
          value={inputEmail}
          placeholder="Enter Phone or Email"
        />
        <button
          className={`${styles.btnSubmit} ${loading ? styles.blink_now : ""}`}
          style={{ backgroundColor: styleState.buttonBg }}
          disabled={inputEmail.trim() === "" ? true : false}
          onClick={isUserExists ? handleContinueResult : handleCreateResult}
        >
          {isUserExists ? "Login" : "Create"}
          {loading ? (
            <img
              src="https://dacbuckets3.s3.amazonaws.com/sport-car.png"
              alt="animated car icon"
            />
          ) : null}
        </button>
        <p className={styles.OrCrossLine}>
          <span>OR</span>
        </p>
      </div>
      {/* <UserQueryAuthModel
        showQueryModel={showQueryModel}
        setShowQueryModel={setShowQueryModel}
        setUserType={setUserType}
        setUserData={setUserData}
        inputEmail={inputEmail}
        handleCreateResult={handleCreateResult}
      /> */}
    </div>
  );
};

export default LoginComponent;
