import { createSlice } from "@reduxjs/toolkit";

const tabs = ["Fast Quote", "Tax Certificate 01-339", "Drafting", "Forms"];

const initialState = {
  tabs,
  activeTab: "Fast Quote",
  saleType: 'Retail',
  SellPriceInputs: [],
  totalLabelSum: 0, // Initialize as a number, not a string
  salesTax: 6.25,
  minventoryTax: 0.00148,
};

// Helper function to calculate total sum of input values
const calculateTotalLabelSum = (inputs) => {
  return inputs.reduce((acc, item) => {
    const itemValue = parseFloat(item.InputValue) || 0;
    return acc + itemValue;
  }, 0);
};

const fastSlice = createSlice({
  name: "fast",
  initialState,
  reducers: {
    setFastTab(state, action) {
      state.activeTab = action.payload;
    },
    addSellPriceLabelsFast: (state, action) => {
      state.SellPriceInputs.push({
        id: action.payload.id,
        label: action.payload.label,
        InputValue: "",
      });
      state.totalLabelSum = calculateTotalLabelSum(state.SellPriceInputs);
    },
    updateSellPriceInputsFast: (state, action) => {
      const { index, value } = action.payload;
      state.SellPriceInputs[index].InputValue = value;
      state.totalLabelSum = calculateTotalLabelSum(state.SellPriceInputs);
    },
    updateSellPriceLabelsFast: (state, action) => {
      const { index, value } = action.payload;
      state.SellPriceInputs[index].label = value;
      state.totalLabelSum = calculateTotalLabelSum(state.SellPriceInputs);
    },
    deleteSellPriceLabelsFast: (state, action) => {
      const { index } = action.payload;
      state.SellPriceInputs = state.SellPriceInputs.filter(
        (_, i) => i !== index
      );
      state.totalLabelSum = calculateTotalLabelSum(state.SellPriceInputs);
    },
    setDynamicAPILabelsFast: (state, action) => {
      state.SellPriceInputs = action.payload;
      state.totalLabelSum = calculateTotalLabelSum(state.SellPriceInputs);
    },
    // New reducer to update salesTax and inventoryTax
    updateTaxValues: (state, action) => {
      const { salesTax, minventoryTax } = action.payload;
    
      if (salesTax !== undefined) {
        state.salesTax = salesTax;
      }
    
      if (minventoryTax !== undefined) {
        state.minventoryTax = minventoryTax;
      }
    },
    // Update saleType
  updateSaleType: (state, action) => {
    const { saleType } = action.payload;
    state.saleType = saleType;
    // if (saleType !== undefined && typeof saleType === 'string') {
    //   state.saleType = saleType;
    // }
  },
  },
});

export const {
  setFastTab,
  setDynamicAPILabelsFast,
  addSellPriceLabelsFast,
  updateSellPriceInputsFast,
  updateSellPriceLabelsFast,
  deleteSellPriceLabelsFast,
  updateTaxValues,
  updateSaleType,
} = fastSlice.actions;
export default fastSlice.reducer;
