import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./HistoryMobile.module.css";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { FaCircleArrowRight } from "react-icons/fa6";
import toast from "react-hot-toast";

const HistoryMobile = () => {
  const { dealerId } = useSelector((state) => state.user);
  const [loanCardDataArray, setLoanCardDataArray] = useState([]);
  // console.log("loanCardDataArray => ", loanCardDataArray);
  //  dummy data  for mobile show----------------------------------------
  // const loanCardDataArray = [
  //   {
  //     name: "Niraj Kumar",
  //     mobileNumber: "9752467788",
  //     email: "niraj@gmail.com",
  //     address: "Taxes , USA, Collin",
  //   },
  //   {
  //     name: "Sumit Kumar Rana",
  //     mobileNumber: "3456654575",
  //     email: "sumit@gmail.com",
  //     address: "Taxes , USA, Collin",
  //   },
  //   {
  //     name: "Afjal Khan",
  //     mobileNumber: "345646324575",
  //     email: "afjal@gmail.com",
  //     address: "Taxes , USA, Collin",
  //   },
  // ];
  const fetchDealerData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/taxcertificate/get/${dealerId}`
        // fd
      );
      console.log("HistoryData", response.data);
      setLoanCardDataArray(response.data);
    } catch (error) {
      console.log("Error fetching dealer data:", error);
      setLoanCardDataArray([]);
    }
  };
  // Using useEffect to fetch data when the page loads
  useEffect(() => {
    if (dealerId) {
      fetchDealerData(); // Fetch dealer data when 'id' is available
    }
  }, [dealerId]);
  // for mobile popup-----------------------------------------------------------------------
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [showViewPopUp, setShowViewPopUp] = useState(false);
  const showMobileMessagePopUp = () => {
    setShowMessagePopUp(true);
  };
  const showMobileViewPopUp = () => {
    setShowViewPopUp(true);
  };
  // -----------------------------------------------------------------------------------------
  // for show pdf on Click of view button---------------------------------------------------------
  const [showPdf, setShowPdf] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const loadPdf = async (QuotationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/taxcertificate/view_pdf/${dealerId}/${QuotationId}`
      );
      console.log("response3545", response.status);

      if (response.status === 200) {
        setPdfData([response.data]);
        toast.success("Successfully show the PDF");
        setShowPdf(true);
      } else if (response.status === 204) {
        toast.error("Please Enter Correct OTP.");
      }
    } catch (err) {
      toast.error(err);
    }
  };
  return (
    <>
      <div className={styles.crmMwrapper}>
        {loanCardDataArray &&
          loanCardDataArray?.map((item, index) => (
            <div className={styles.crmMcontainer}>
              <div className={styles.crmMHeader}>
                <span>Name</span>
                <span>{item.sellerName}</span>
              </div>
              <div className={styles.crmMPersonalWrapper}>
                <div className={styles.crmMPersonalDetails}>
                  <p className={styles.crmMDetail}>
                    <span>Phone Number</span>
                    <span>{item.sellerPhone}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Email</span>
                    <span>{item.sellerEmail}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Address</span>
                    <span>{item.sellerAddressLine1}</span>

                    {/* <span>{item.assetName}</span> */}
                  </p>
                </div>
                <div className={styles.crmMBtnContainer}>
                  {/* <button onClick={showMobileMessagePopUp}>Message</button>
                  <button onClick={showMobileViewPopUp}>View</button> */}
                  <button type="button" onClick={() => loadPdf(item?.id)}>
                    View
                  </button>
                  <button type="button">Envelope</button>
                </div>
              </div>
              <span className={styles.crmMNumbering}>{index + 1}</span>
            </div>
          ))}
      </div>
      {/*--------- mobile View button pdf------------- */}
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#d1823a" }}
          >
            History Pdf :
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {pdfData &&
              pdfData.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HistoryMobile;
