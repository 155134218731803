import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../../Redux/slice/taxes&FeesSlice";
import { connectStorageEmulator } from "firebase/storage";

const Box_G = ({ retailFromRedux, totalFeesAndOthers, onSumChange ,lenghtInputs}) => {
  const totalFees = useSelector(
    (state) => state.taxesAndFees.dealerRetail.totalFees
  );
  // const totalFeesn = totalFees.toFixed(2)
  const userListingPrice = useSelector(
    (state) => state.taxesAndFees.dealerRetail.userListingPrice
  );
  const mileageIn = useSelector(
    (state) => state.taxesAndFees.dealerRetail.mileageIn
  );
  const totalFeesAndOthersprice = useSelector(
    (state) => state.taxesAndFees.dealerRetail.totalFeesAndOthersprice
  );
  const isCarSelected = useSelector((state) => state.sell.selectedVehicle);
  // Provide a fallback in case `isCarSelected` is undefined
  const listingPrice = isCarSelected?.listing_price || 0;
  const mileage_in = isCarSelected?.mileage_in || 0;
  //const totalFeesTax = totalFees.toFixed(0);
  // Update state whenever `totalFees` changes
  console.log("18etty", totalFeesAndOthers);
  const [inputValues, setInputValues] = useState([
    {
      header: "Mileage On Sale:",
      InputValue: `${mileageIn}`,
      name: "mileageIn",
    },
  ]);
  const formatNumberWithCommas = (num) => {
    return Number(num).toLocaleString();
  };
  // ----------------------for date--------------------------------
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [date, setDate] = useState(getCurrentDate());
  const handleChangeDate = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);

    const dateObj = new Date(selectedDate);
    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    console.log("Selected Date:", selectedDate);
  };
  useEffect(() => {
    dispatch(
      setRetailData({
        date: date,
      })
    );
  }, [date]);
  // -------------------------------------------------
  const removeCommas = (str) => {
    return str.replace(/,/g, "");
  };

  useEffect(() => {
    if (listingPrice) {
      dispatch(
        setRetailData({
          userListingPrice: listingPrice,
        })
      );
    }
  }, [listingPrice]);

  useEffect(() => {
    if (mileage_in) {
      dispatch(
        setRetailData({
          mileageIn: mileage_in,
        })
      );
    }
  }, [mileage_in]);

  useEffect(() => {
    if (totalFeesAndOthers) {
      dispatch(
        setRetailData({
          totalFeesAndOthersprice: totalFeesAndOthers,
        })
      );
    }
  }, [totalFeesAndOthers]);

  const dispatch = useDispatch();
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "mileageIn" ? { ...item, InputValue: mileageIn } : item
      )
    );
  }, [mileageIn]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndOthersprice"
          ? { ...item, InputValue: totalFeesAndOthersprice }
          : item
      )
    );
  }, [totalFeesAndOthersprice]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFees" ? { ...item, InputValue: totalFees } : item
      )
    );
  }, [totalFees]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndOthersprice"
          ? { ...item, InputValue: totalFeesAndOthersprice }
          : item
      )
    );
  }, [totalFeesAndOthersprice]);
  // sum all three------------------
  useEffect(() => {
    const sum = inputValues.reduce(
      (acc, item) => acc + parseFloat(item.InputValue),
      0
    );
    onSumChange(sum);
  }, [inputValues, onSumChange]);
  // --------------------------
  const handleInputChange = (name, value) => {
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  };

  return (
    <div
      className={Styles.wrapper}
    >
      <div
        className={Styles.container}
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          borderBottom: "2px solid rgb(220 150 86 / 41%);",
          paddingBottom: "10px",
        }}
      >
        {/* <div
          className={Styles.inputFieldContainer}
          style={{ marginTop: "0px" }}
        >
          <span style={{ fontWeight: "500", fontSize: "large" }}>Date : </span>
          <p className={Styles.dueDataDate} style={{ marginBottom: "4px" }}>
            <input
              type="date"
              value={date}
              onChange={handleChangeDate}
              className={Styles.inputField}
              style={{
                border: "none",
                padding: "0px",
                marginBottom: "5px",
                backgroundColor: "transparent",
                color: "black",
                boxShadow: "none",
                padding: "0px",
              }}
            />
          </p>
        </div> */}
        {inputValues.map((item, index) => (
          <InputFieldComp
            key={index}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
          />
        ))}
      </div>
    </div>
  );
};

export default Box_G;
