import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  phoneNumber: "1234567890",
};
const crmSlice = createSlice({
  name: "crmSlice",
  initialState,
  reducers: {
    saveCrmData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});
export const { saveCrmData } = crmSlice.actions;
export default crmSlice.reducer;
