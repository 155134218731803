import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../../Redux/slice/taxes&FeesSlice";
import { connectStorageEmulator } from "firebase/storage";

const Box_F = ({ retailFromRedux, totalFeesAndOthers, onSumChange }) => {
  const totalFees = useSelector(
    (state) => state.taxesAndFees.dealerRetail.totalFees
  );
  // const totalFeesn = totalFees.toFixed(2)
  const userListingPrice = useSelector(
    (state) => state.taxesAndFees.dealerRetail.userListingPrice
  );
  const mileageIn = useSelector(
    (state) => state.taxesAndFees.dealerRetail.mileageIn
  );
  const totalFeesAndOthersprice = useSelector(
    (state) => state.taxesAndFees.dealerRetail.totalFeesAndOthersprice
  );
  const isCarSelected = useSelector((state) => state.sell.selectedVehicle);
  const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  // Provide a fallback in case `isCarSelected` is undefined
  const listingPrice = isCarSelected?.listing_price || 0;
  const mileage_in = isCarSelected?.mileage_in || 0;
  //const totalFeesTax = totalFees.toFixed(0);
  // useEffect(()=>{
  //   if(purchase_price > 0){
  //     userListingPrice = userListingPrice - purchase_price;
  //     dispatch(setRetailData({
  //       userListingPrice:userListingPrice,
  //     }));
  //   }
  // },[purchase_price]);

  // Update state whenever `totalFees` changes
  console.log("18etty", totalFeesAndOthers);
  const [inputValues, setInputValues] = useState([
    // { header: "Mileage On Sale:", InputValue: `${mileageIn}`, name: "mileageIn" },
    {
      header: "Sale Price:",
      InputValue: `${userListingPrice}`,
      name: "userListingPrice",
    },
    // { header: "Total Taxes", InputValue: totalFees, name: "totalFees" },
    // { header: "Total Fees And Others", InputValue: `${totalFeesAndOthersprice}`, name: "totalFeesAndOthersprice" },
  ]);
  const formatNumberWithCommas = (num) => {
    return Number(num).toLocaleString();
  };

  const removeCommas = (str) => {
    return str.replace(/,/g, "");
  };

  useEffect(() => {
    if (listingPrice) {
      dispatch(
        setRetailData({
          userListingPrice: listingPrice,
        })
      );
    }
  }, [listingPrice]);

  useEffect(() => {
    if (mileage_in) {
      dispatch(
        setRetailData({
          mileageIn: mileage_in,
        })
      );
    }
  }, [mileage_in]);

  useEffect(() => {
    if (totalFeesAndOthers) {
      dispatch(
        setRetailData({
          totalFeesAndOthersprice: totalFeesAndOthers,
        })
      );
    }
  }, [totalFeesAndOthers]);

  const dispatch = useDispatch();
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "mileageIn" ? { ...item, InputValue: mileageIn } : item
      )
    );
  }, [mileageIn]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndOthersprice"
          ? { ...item, InputValue: totalFeesAndOthersprice }
          : item
      )
    );
  }, [totalFeesAndOthersprice]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFees" ? { ...item, InputValue: totalFees } : item
      )
    );
  }, [totalFees]);
  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "userListingPrice"
          ? { ...item, InputValue: userListingPrice }
          : item
      )
    );
  }, [userListingPrice]);

  useEffect(() => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "totalFeesAndOthersprice"
          ? { ...item, InputValue: totalFeesAndOthersprice }
          : item
      )
    );
  }, [totalFeesAndOthersprice]);
  // sum all three------------------
  useEffect(() => {
    const sum = inputValues.reduce(
      (acc, item) => acc + parseFloat(item.InputValue),
      0
    );
    onSumChange(sum);
  }, [inputValues, onSumChange]);
  // --------------------------
  const handleInputChange = (name, value) => {
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  };

  return (
    <div
      className={Styles.wrapper}
    >
      <div
        className={Styles.container}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        {inputValues.map((item, index) => (
          <InputFieldComp
            key={index}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
          />
        ))}
      </div>
    </div>
  );
};

export default Box_F;
