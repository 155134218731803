import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { RiFileSearchFill } from "react-icons/ri";

import "../../DealerSell/forms.css";
import { FaSearch } from "react-icons/fa";

import usaStates from "../DealerAddInventory/usaStates.json";
import { useSelector } from "react-redux";

import { IoDocumentsSharp } from "react-icons/io5";

import PDFViewer from "../../DealerSell/components/PDF";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ImCross } from "react-icons/im";

function FormsDesktop({ data }) {
  // State to manage Select All
  //for all forms
  const [selectAll, setSelectAll] = useState(false);
  //for forms
  const [selectAllForms, setSelectAllForms] = useState(false);
  const [selectedState, setSelectedState] = useState("TX - Texas");
  const userData = useSelector((state) => state.user);
  const reduxForms = [
    `title_issuance_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `tax_exemption_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `limited_attorney_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `buyers_guide_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `rebuilt_disclosure_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `title_registration_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `billOfSale_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `odometer_disclosure_${data?.addListing2?.vin?.slice(-8)}${
      userData.id
    }.pdf`,
    `salesContract_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `statement_of_facts_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `airbag_disclosure_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `service_contract_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `insurance_agreement_${data?.addListing2?.vin?.slice(-8)}${
      userData.id
    }.pdf`,
    `credit_bureau_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `accuracy_information_${data?.addListing2?.vin?.slice(-8)}${
      userData.id
    }.pdf`,
    `right_to_response_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
    `billOfSale_${data?.addListing2?.vin?.slice(-8)}${userData.id}.pdf`,
  ];

  // console.log(reduxForms);

  // console.log("Checkboxes ", checkboxes);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
    setSearch("");
  }, [isOpen]);

  // console.log(checkboxes);

  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };

  // Handle Select All change
  //for all forms
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckboxes({
      billOfSale: newSelectAll,
      billOfSaleCustomer: newSelectAll,
      buyerGuide: newSelectAll,
      odometerDisclosure: newSelectAll,
      titleApp: newSelectAll,
      AgreementInsurance: newSelectAll,
      creditBureauDisclosure: newSelectAll,
      rebuildTitleDisclosure: newSelectAll,
      powerOfAttorney: newSelectAll,
      retailContractInstallment: newSelectAll,
      salesTaxExemptionCertificate: newSelectAll,
      statementOfFacts: newSelectAll,
      airbagDisclosure: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
      accuracyInformationAcknowledment: newSelectAll,
      rightToReposses: newSelectAll,
    });
  };
  //for forms
  const handleSelectForms = () => {
    const newSelectAll = !selectAllForms;
    setSelectAllForms(newSelectAll);
    setFormsCheckboxes({
      billOfSale: newSelectAll,
      billOfSaleCustomer: newSelectAll,
      buyerGuide: newSelectAll,
      odometerDisclosure: newSelectAll,
      titleApp: newSelectAll,
      AgreementInsurance: newSelectAll,
      creditBureauDisclosure: newSelectAll,
      rebuildTitleDisclosure: newSelectAll,
      powerOfAttorney: newSelectAll,
      retailContractInstallment: newSelectAll,
      salesTaxExemptionCertificate: newSelectAll,
      statementOfFacts: newSelectAll,
      airbagDisclosure: newSelectAll,
      serviceContract: newSelectAll,
      countyOfTitleIssuance: newSelectAll,
      accuracyInformationAcknowledment: newSelectAll,
      rightToReposses: newSelectAll,
    });
  };

  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const [showPdfs, setShowPdfs] = useState(false);
  const [showForms, setShowFormsPdfs] = useState(false);
  // console.log("Show forms => ", showForms);
  const handlePreviewSelect = () => {
    setShowPdfs(true);
    // dispatch(saveUser({ isSideBarOpen: false }));
  };
  const handleShowFormsSelect = () => {
    setShowFormsPdfs(true);
  };

  // for form to save data to craete sell form---------------------------
  const [isCallFunc, setisCallFunc] = useState(false);
  console.log("isCallFunc download state", isCallFunc);

  const AllCheckBoxesFromAllForms = [
    {
      id: "AgreementInsurance",
      label: "Agreement To Provide Insurance",
    },
    { id: "billOfSale", label: "Bill of Sale /Buyer Order" },
    { id: "billOfSaleCustomer", label: "Bill of Sale - Customer" },
    { id: "buyerGuide", label: "Buyer Guide AS / IS" },
    {
      id: "creditBureauDisclosure",
      label: "Credit Bureau Disclosure",
    },
    { id: "odometerDisclosure", label: "Odometer Disclosure" },
    {
      id: "rebuildTitleDisclosure",
      label: "Rebuilt Title Disclosure",
    },
    { id: "titleApp", label: `${selectedState} -Title App I130u` },
    {
      id: "powerOfAttorney",
      label: `${selectedState} - Power Of Attorney`,
    },
    {
      id: "retailContractInstallment",
      label: `${selectedState} - Retail Contract Installment`,
    },
    {
      id: "salesTaxExemptionCertificate",
      label: `${selectedState} - Sales Tax Exemption Certificate`,
    },
    {
      id: "statementOfFacts",
      label: `Statement of Facts`,
    },
    {
      id: "airbagDisclosure",
      label: `Airbag disclosure`,
    },
    {
      id: "serviceContract",
      label: `Service Contract Decline Acknowledgment`,
    },
    {
      id: "countyOfTitleIssuance",
      label: `County of Title Issuance`,
    },
    {
      id: "accuracyInformationAcknowledment",
      label: `Accuracy of Information Acknowledgment`,
    },
    {
      id: "rightToReposses",
      label: `Right to Reposses`,
    },
  ];

  // State to manage checkboxes for all forms
  const [checkboxes, setCheckboxes] = useState({
    billOfSale: false,
    billOfSaleCustomer: false,
    buyerGuide: false,
    odometerDisclosure: false,
    titleApp: false,
    AgreementInsurance: false,
    creditBureauDisclosure: false,
    rebuildTitleDisclosure: false,
    powerOfAttorney: false,
    retailContractInstallment: false,
    salesTaxExemptionCertificate: false,
    statementOfFacts: false,
    airbagDisclosure: false,
    serviceContract: false,
    countyOfTitleIssuance: false,
    accuracyInformationAcknowledment: false,
    rightToReposses: false,
  });

  // Handle individual checkbox change in ALL FORMS
  //search in All forms
  const [searchInput, setSearchInput] = useState("");
  const [searchFormOutput, setSearchFormOnput] = useState([]);
  const handleCheckboxChange = (e, manualId = null) => {
    const id = manualId || e.target.id;
    const checked = manualId ? !checkboxes[id] : e.target.checked;

    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));

    // setSelectAll(
    //   Object.values({ ...searchFormOutput, [id]: checked }).every((val) => val)
    // );
  };

  //checkboxes to show in outer forms, by-default we are showing some checkboxes
  const [checkBoxesToShow, setCheckboxesToShow] = useState([
    "billOfSale",
    "billOfSaleCustomer",
    "titleApp",
    "powerOfAttorney",
    "buyerGuide",
    "AgreementInsurance",
    "odometerDisclosure",
    "statementOfFacts",
    "airbagDisclosure",
    "rebuildTitleDisclosure",
    "serviceContract",
    "countyOfTitleIssuance",
    "accuracyInformationAcknowledment",
    "rightToReposses",
  ]);
  // console.log("checkBoxesToShow", checkBoxesToShow);

  const [browser, setBrowser] = useState("");

  useEffect(() => {
    if (searchFormOutput.length > 0) {
      const filteredItem = checkBoxesToShow.map((item) =>
        searchFormOutput.find((obj) => obj.id === item)
      );

      console.log("filteredItem in useEffect => ", filteredItem);

      const updatedCheckboxes = {};
      filteredItem.forEach((item) => {
        if (item) {
          updatedCheckboxes[item.id] = true;
        }
      });

      setCheckboxes((prevData) => ({ ...prevData, ...updatedCheckboxes }));
    }
  }, [searchFormOutput, checkBoxesToShow]); // Ensure dependencies are correct

  //Method in the all forms modal to add the checked checkbox in the outer forms
  const handleAddToForms = () => {
    const checkedItems = Object.entries(checkboxes).filter(
      ([key, value]) => value === true
    );
    const trueObject = Object.fromEntries(checkedItems);

    setCheckboxesToShow(Object.keys(trueObject));
    setShowFormsPdfs(false);
    setSearchInput("");
  };

  //for searching the modal in the modal
  const handleSearchForms = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    setSearchFormOnput(
      AllCheckBoxesFromAllForms.filter((item) =>
        item.label.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchFormOnput(AllCheckBoxesFromAllForms);
    }
  }, [searchInput]);

  useEffect(() => {
    const boolValue = Object.values(checkboxes).every((item) => item);
    console.log("Are all the checkboxes  checked ", boolValue);
    if (boolValue) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkboxes]);

  //OUTER FORMS STARTS HERE
  //Label checkboxes are the actual checkboxes that will be shown in outer forms
  const [labelCheckBox, setLabelCheckBox] = useState([]);
  console.log("Label Checkbox => ", labelCheckBox);
  useEffect(() => {
    if (checkBoxesToShow.length > 0) {
      const newArray = checkBoxesToShow
        .map((item) => AllCheckBoxesFromAllForms.find((obj) => obj.id === item))
        .filter(Boolean); // Remove any undefined results
      console.log("New Array in useEffect2 => ", newArray);
      setLabelCheckBox(newArray);
    } else {
      setLabelCheckBox([]);
    }
  }, [checkBoxesToShow]);

  //for label check box in outer forms
  const [Formcheckboxes, setFormsCheckboxes] = useState({
    billOfSale: true,
    billOfSaleCustomer: true,
    buyerGuide: true,
    odometerDisclosure: true,
    titleApp: true,
    AgreementInsurance: true,
    creditBureauDisclosure: true,
    rebuildTitleDisclosure: false,
    powerOfAttorney: true,
    retailContractInstallment: true,
    salesTaxExemptionCertificate: true,
    statementOfFacts: true,
    airbagDisclosure: true,
    serviceContract: true,
    countyOfTitleIssuance: true,
    accuracyInformationAcknowledment: true,
    rightToReposses: true,
  });

  //pdf array for Viewing the pdf
  const pdfArray = [
    {
      pdf: reduxForms && reduxForms[6],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.billOfSale &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "billOfSale"),
    },
    {
      pdf: reduxForms && reduxForms[16],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.billOfSaleCustomer &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "billOfSaleCustomer"),
    },
    {
      pdf: reduxForms && reduxForms[5],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.titleApp &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "titleApp"),
    },

    {
      pdf: reduxForms && reduxForms[1],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.salesTaxExemptionCertificate &&
        labelCheckBox &&
        labelCheckBox.find(
          (check) => check.id === "salesTaxExemptionCertificate"
        ),
    },
    {
      pdf: reduxForms && reduxForms[2],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.powerOfAttorney &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "powerOfAttorney"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[3],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.buyerGuide &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "buyerGuide"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[4],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.rebuildTitleDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "rebuildTitleDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[12],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.AgreementInsurance &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "AgreementInsurance"),
    },
    ,
    {
      pdf: reduxForms && reduxForms[7],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.odometerDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "odometerDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[8],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.retailContractInstallment &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "retailContractInstallment"),
    },
    {
      pdf: reduxForms && reduxForms[9],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.statementOfFacts &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "statementOfFacts"),
    },
    {
      pdf: reduxForms && reduxForms[10],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.airbagDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "airbagDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[11],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.serviceContract &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "serviceContract"),
    },
    {
      pdf: reduxForms && reduxForms[0],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.countyOfTitleIssuance &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "countyOfTitleIssuance"),
    },
    {
      pdf: reduxForms && reduxForms[13],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.creditBureauDisclosure &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "creditBureauDisclosure"),
    },
    {
      pdf: reduxForms && reduxForms[14],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.accuracyInformationAcknowledment &&
        labelCheckBox &&
        labelCheckBox.find(
          (check) => check.id === "accuracyInformationAcknowledment"
        ),
    },
    {
      pdf: reduxForms && reduxForms[15],
      isShow:
        Formcheckboxes &&
        Formcheckboxes?.rightToReposses &&
        labelCheckBox &&
        labelCheckBox.find((check) => check.id === "rightToReposses"),
    },
  ];
  //function to update the checkbox in outer forms
  const handleFormsCheckboxChange = (e) => {
    console.log("Updating the checkbox in the OUTER forms");
    const { id, checked } = e.target;
    console.log("ID => " + id + " checked => ", checked);

    setFormsCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  console.log("Formcheckboxes ", Formcheckboxes);
  const [currTotalPage, setCurrTotalPage] = useState(0);
  const [currScreenType, setCurrScreenType] = useState("Desktop");
  useEffect(() => {
    const handlWindowResize = () => {
      if (window.innerWidth < 768) {
        setCurrScreenType("Mobile");
      } else if (window.innerWidth < 1024) {
        setCurrScreenType("Tablet");
      } else if (window.innerWidth < 1441) {
        setCurrScreenType("Laptop");
      } else {
        setCurrScreenType("Desktop");
      }
    };
    handlWindowResize();

    window.addEventListener("resize", handlWindowResize);
    return () => {
      window.removeEventListener("resize", () => {
        setCurrScreenType("Desktop");
      });
    };
  }, []);

  const handlePDFZoom = () => {
    switch (currScreenType) {
      case "Mobile":
        return 50;
      case "Tablet":
        return 60;
      case "Laptop":
        return 70;
      case "Desktop":
        return 90;
    }
  };
  const handleDeleteCheckBox = (checkBoxToDelete) => {
    console.log("checkBoxToDelete ", checkBoxToDelete);
    // Removing the checkbox from the outer forms
    if (labelCheckBox.map((item) => item.id).includes(checkBoxToDelete)) {
      const filteredData = labelCheckBox.filter(
        (item) => item.id !== checkBoxToDelete
      );
      setLabelCheckBox(filteredData);
    }
    // Removing the check of checkbox from All forms
    if (
      searchFormOutput &&
      searchFormOutput.map((item) => item.id).includes(checkBoxToDelete)
    ) {
      setCheckboxes((prevData) => ({
        ...prevData,
        [checkBoxToDelete]: false,
      }));
    }
  };

  return (
    <Container className="forms-container rounded relative ">
      <div className="absolute">
        <Modal
          show={showForms}
          onHide={() => setShowFormsPdfs(false)}
          size="xl"
          // dialogClassName="boxShadowInset"
          // dialogClassName="modal-90w"
          style={{ boxShadow: "inset 0 0 30px #dd8a3f" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ width: "100%" }}>
              <div className="allFormsModalHeader">All Forms</div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="allFormsPopupBody">
            <Col className="allFormsHeaderItemsContainer">
              <div className="state allFormsHeaderItem">
                <Dropdown
                  show={isOpen}
                  onSelect={handleSelectState}
                  onToggle={(open) => setIsOpen(open)}
                  className="m-0 "
                >
                  <Dropdown.Toggle
                    size="sm"
                    className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {selectedState}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="state-drp">
                    {/* Hidden input to manage search */}
                    <input
                      type="text"
                      value={search}
                      ref={searchInputRef}
                      autofocus
                      onChange={handleSearchChange}
                      onFocus={() => setIsOpen(true)}
                      style={{
                        position: "absolute",
                        top: "-9999px",
                        left: "-9999px",
                      }} // Hide the input field
                    />
                    {filteredStates.length > 0 ? (
                      filteredStates.map((state) => (
                        <Dropdown.Item
                          key={state.name}
                          eventKey={state.name}
                          onClick={() => handleSelect(state.name)}
                        >
                          {state.name}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No states found</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="allFormsHeaderItem">
                <input
                  type="text"
                  value={searchInput}
                  placeholder="Search the forms here"
                  onChange={handleSearchForms}
                />
                <span>
                  <FaSearch />
                </span>
              </div>
            </Col>
            {/* {reduxForms && reduxForms?.map((item, i) => ( */}
            <label
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: "underline",
                display: "flex",
                gap: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <input
                type="checkbox"
                checked={selectAll}
                // onChange={handleSelectAll}
                onChange={handleSelectAll}
              />
              <span>{selectAll ? "DeSelect All" : "Select All"}</span>
            </label>
            <Form className="outerCheckBoxElContainer ">
              {searchFormOutput.length > 0 ? (
                searchFormOutput.map(({ id, label }) => (
                  <Form.Group key={id} className="outerCheckBoxEl">
                    <Form.Check
                      type="checkbox"
                      id={id}
                      checked={checkboxes[id]}
                      onChange={handleCheckboxChange}
                      label={
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            handleCheckboxChange(null, id);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className={`custom-checkbox ${
                              checkboxes[id] ? "OrangeCheckBox" : ""
                            }`}
                          />
                          {label}
                        </span>
                      }
                      className="form-option border-bottom outerCheckBoxChild"
                    />
                    
                  </Form.Group>
                ))
              ) : (
                <p>No form found for "{searchInput}"</p>
              )}
            </Form>
            <div className="outerBtnContainer">
              {/* <button
                className="innerBtn"
                // onClick={handlePreviewSelect}
              >
                <span>
                  <RiFileSearchFill />
                </span>
                <span>Preview</span>
              </button> */}
              {/* <button onClick={handleAddToForms} className="innerBtn">
                Add to forms
              </button> */}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={handleAddToForms}
              className="innerBtn footer-btn-add-forms"
            >
              Add to forms
            </button>
          </Modal.Footer>
        </Modal>
      </div>

      {showPdfs ? (
        pdfArray.some((item) => item.isShow) && (
          <div className="h-100 p-3">
            <p className="FormsDesktopBackBtnContainer">
              {" "}
              <button onClick={() => setShowPdfs(false)}>
                <IoMdArrowRoundBack /> Back
              </button>
              <span>Forms generated while finalising the deal.</span>
            </p>

            <PDFViewer
              pdfArray={pdfArray.filter((item) => item.isShow)}
              setisCallFunc={setisCallFunc}
              isCallFunc={isCallFunc}
              ref={null}
              toolbar={1}
              zoomSize={handlePDFZoom()}
              setCurrTotalPage={setCurrTotalPage}
            />
          </div>
        )
      ) : (
        <>
          <Col className="outerSelectAllContainer">
            <div
              className="mb-3 "
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p>
                <label
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllForms}
                    // onChange={handleSelectAll}
                    onChange={handleSelectForms}
                  />
                  <span
                    className="outerSelectAllLabel"
                    style={{ width: "10rem" }}
                  >
                    {selectAllForms ? "Unselect All" : "Select All"}
                  </span>
                </label>
              </p>
              <p className="OuterAllformsBtnContainer">
                <Button
                  variant="light"
                  className="  button-width "
                  onClick={handleShowFormsSelect}
                >
                  <span>
                    <IoDocumentsSharp />
                  </span>{" "}
                  <span>All Forms</span>
                </Button>
              </p>
            </div>
            <Form className="outerCheckBoxElContainer">
              {labelCheckBox.length > 0 ? (
                labelCheckBox?.map(({ id, label }) => (
                  <Form.Group key={id} className="outerCheckBoxEl">
                    <Form.Check
                      type="checkbox"
                      id={id}
                      checked={Formcheckboxes && Formcheckboxes[id]}
                      onChange={handleFormsCheckboxChange}
                      // label={label}
                      label={
                        <span
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   handleFormsCheckboxChange(null, id);
                          // }}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className={`custom-checkbox ${
                              Formcheckboxes[id] ? "OrangeCheckBox" : ""
                            }`}
                          />
                          {label}
                        </span>
                      }
                      className="form-option border-bottom  outerCheckBoxChild"
                    />
                    <span onClick={() => handleDeleteCheckBox(id)}>
                      <ImCross />
                    </span>
                  </Form.Group>
                ))
              ) : (
                <p>Please select checkboxes to show here</p>
              )}
            </Form>
          </Col>

          <Col className="d-flex flex-column justify-content-center align-items-between gap-4">
            <div className="  outerBtnContainer">
              <Button
                variant="light"
                className="  button-width"
                onClick={handlePreviewSelect}
                disabled={
                  (reduxForms && reduxForms?.length === 0) ||
                  (!Formcheckboxes.titleApp &&
                    !Formcheckboxes.salesTaxExemptionCertificate &&
                    !Formcheckboxes.powerOfAttorney &&
                    !Formcheckboxes.buyerGuide &&
                    !Formcheckboxes.rebuildTitleDisclosure &&
                    !Formcheckboxes.AgreementInsurance &&
                    !Formcheckboxes.billOfSale &&
                    !Formcheckboxes.odometerDisclosure &&
                    !Formcheckboxes.statementOfFacts &&
                    !Formcheckboxes.airbagDisclosure &&
                    !Formcheckboxes.serviceContract &&
                    !Formcheckboxes.countyOfTitleIssuance &&
                    !Formcheckboxes.creditBureauDisclosure &&
                    !Formcheckboxes.retailContractInstallment &&
                    !Formcheckboxes.accuracyInformationAcknowledment &&
                    !Formcheckboxes.rightToReposses &&
                    !Formcheckboxes.billOfSaleCustomer)
                }
              >
                <span>
                  {/* <FaRegEye /> */}
                  <RiFileSearchFill />
                </span>
                <span>Preview</span>
              </Button>
              <Button
                variant="light"
                className="  button-width "
                onClick={handleShowFormsSelect}
              >
                <span>
                  <IoDocumentsSharp />
                </span>{" "}
                <span>All Forms</span>
              </Button>
            </div>
          </Col>
        </>
      )}
    </Container>
  );
}

export default FormsDesktop;
