import React, { useEffect, useState } from "react";
import styles from "./DealDetailDesktop.module.css";
import { FaCalculator } from "react-icons/fa";
import feesDetail from "./DealDetailFeeStructure";
import axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";

const DealDetailDesktop = ({ PaymentData }) => {
  console.log("Data from table => ", PaymentData);
  const [apiData, setApiData] = useState({});
  const [totalFees, setTotalFees] = useState(0);
  const [totalTaxes, setotalTaxes] = useState(0);
  console.log("apiData => ", apiData);
  const handleGetDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_dealdetails/${PaymentData.userId}/${PaymentData.id}`
      );
      console.log("Response from the data => ", response);
      if (response.status === 200) {
        setApiData(response.data);
        const taxes =
          response.data.saleTaxValue + response.data.mInventoryValue;
        setotalTaxes(taxes);
        const fees = response.data.sellPriceFeeItems.reduce(
          (acc, item) => acc + item.itemValue,
          0
        );
        console.log("Total fees => ", fees);
        setTotalFees(fees);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  useEffect(() => {
    handleGetDetails();
  }, []);

  const [showAbsolutePopout, setShowAbsolutePopout] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.bodyLeft}>
            {/* Sale Price */}
            <div className={styles.salePrice}>
              <span>1. Sales Price</span>
              <p>
                <input type="text" value={`$ ${apiData.listingPrice}`} />
                <span>
                  <FaCalculator style={{ cursor: "pointer" }} />
                </span>
              </p>
            </div>
            {/* box 2 */}
            {PaymentData?.addListing?.purchase_price > 0 && (
              <div
                className={`${styles.tradeInOwnedCashDifference}
                 ${styles.firstTradeInOwnedCashDifference}
                  ${styles.tradeRelativePopoutContainer}`}
              >
                <div className={styles.tradeInContainerOuter}>
                  <span>2. Trade In</span>
                  <input
                    type="text"
                    value={`$ ${PaymentData?.addListing?.purchase_price || 0}`}
                  />
                  <p onClick={() => setShowAbsolutePopout(!showAbsolutePopout)}>
                    <BsThreeDotsVertical />
                  </p>
                </div>
                {showAbsolutePopout && (
                  <div className={styles.tradeAbsolutePopoutContainer}>
                    <p className={styles.tradePopoutCloseBtnContainer}>
                      <span
                        className={styles.tradePopoutCloseBtn}
                        onClick={() => setShowAbsolutePopout(false)}
                      >
                        <IoCloseSharp />
                      </span>
                    </p>
                    <div className={styles.tradeInDataContainer}>
                      <div className={styles.OwnedContainerPopout}>  
                        <span>Trade In</span>
                        <input
                          type="text"
                          value={`$ ${
                            PaymentData?.addListing?.purchase_price || 0
                          }`}
                        />
                      </div>
                      <div className={styles.OwnedContainerPopout}>
                        <span>Owned on Trade</span>

                        <input type="text" value={`$ ${apiData?.ownedTrade}`} />
                      </div>
                      <div className={styles.OwnedContainerPopout}>
                        <span>Cash Difference</span>
                        <input
                          type="text"
                          value={`$ ${apiData.cashDifference}`}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className={styles.priceFeesContainer}>
              {apiData &&
                apiData?.sellPriceFeeItems?.map((fee, index) => (
                  <div key={fee.id} className={styles.priceFees}>
                    <span>
                      {index + 5}. {fee.itemName}
                    </span>
                    <input type="text" value={fee.itemValue} />
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.bodyRight}>
            <div className={styles.taxContainer}>
              <div className={styles.salesTaxContainer}>
                <span>15. Sales Tax</span>
                <div className={styles.taxInputContainer}>
                  <input type="text" value={`${apiData.salesTaxes}`} />
                  <input type="text" value={`$ ${apiData.saleTaxValue}`} />
                </div>
              </div>
              <div className={styles.taxInputContainer}>
                <span>16. Inventory Tax</span>

                <div className={styles.taxInputContainer}>
                  <input type="text" value={`${apiData.mInventoryTax}`} />
                  <input type="text" value={`$ ${apiData.mInventoryValue}`} />
                </div>
              </div>
            </div>
            <div className={styles.tradeInOwnedCashDifference}>
              <div className={styles.tradeInContainer}>
                <span>17. Total Taxes</span>
                <input
                  type="text"
                  value={`$ ${totalTaxes && totalTaxes.toFixed(2)}`}
                />
              </div>
              <div className={styles.OwnedContainer}>
                <span>18. Total Fees</span>
                <input type="text" value={`$ ${totalFees.toFixed(2)}`} />
              </div>
              <div className={styles.OwnedContainer}>
                <span>19. Total</span>
                <input
                  type="text"
                  value={`$ ${(totalTaxes + totalFees).toFixed(2)}`}
                />
              </div>
            </div>
            <div className={styles.tradeInOwnedCashDifference}>
              <div className={styles.tradeInContainer}>
                <span>20. Total Dues</span>
                <input
                  type="text"
                  value={`$ ${apiData?.totalDue?.toFixed(2)}`}
                />
              </div>
              <div className={styles.OwnedContainer}>
                <span>21. Payment Method</span>
                <input
                  type="text"
                  value={`${apiData.priceFeesPaymentMethod || "NA"}`}
                />
              </div>
              <div className={styles.OwnedContainer}>
                <span>22. Payment</span>
                <input
                  type="text"
                  value={`$ ${(
                    apiData.totalDue?.toFixed(2) -
                    apiData.balanceDue?.toFixed(2)
                  ).toFixed(2)}`}
                />
              </div>
              <div className={styles.OwnedContainer}>
                <span>23. Balance Due</span>
                <input
                  type="text"
                  value={`$ ${apiData?.balanceDue?.toFixed(2)}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealDetailDesktop;
