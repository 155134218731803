import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import { IoCopyOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import { MdOutlineShare } from "react-icons/md";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import Image from "../../../imgData";
import styles from "./sold.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setCoBuyerSellData,
  setSelectedVehiclePopOut,
  setSellData,
} from "../../../../Redux/slice/sellSlice";
import axios from "axios";
import SellData from "../activeInventory/SellData.jsx";
import AddlistingDataSold from "../DealerBalanceInventory/AddlistingDataSold.jsx";
import SellDatab from "../activeInventory/Selldatab.jsx";
import SoldPreviewModal from "./SoldPreviewModal.jsx";
import AddlistingDataSoldAddCost from "../DealerBalanceInventory/AddlistingDataSoldAddCost.jsx";
import SelldataSold from "../activeInventory/SelldataSold.jsx";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const SoldCard = ({
  data,
  index,
  // selectedCards,
  // handleSelected,
  handleDelete,
  onUpdate,
  setIsDeleted,
}) => {
  const { id } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [socialIcons, setSocialIcons] = useState([]);
  const [showModalAddlisting, setShowModalAddlisting] = useState(false);
  const [showModalAddlistingAddCost, setShowModalAddlistingAddCost] =
    useState(false);
  const [selectedCards, setselectedCards] = useState([1]);
 
  const [advertisingPro, setAdvertisingPro] = useState({
    listing_id: data.id,
    dacmp_status: true,
    dacmp_interval: "",
    unitedmotors_status: true,
    unitedmotors_interval: "",
    google_status: false,
    google_interval: "",
    insta_status: false,
    insta_interval: "",
    facebook_status: false,
    facebook_interval: "",
    craigslist_status: false,
    craigslist_interval: "",
    twitter_status: false,
    twitter_interval: "",
  });


  // console.log("data of the current product in sold inventory => ", data);

  const handleCheckboxChange = (e, manualId = null) => {
    const id = manualId || e.target.id;
    const checked = manualId ? !checkboxes[id] : e.target.checked;

    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setAdvertisingPro((prev) => ({ ...prev, [name]: value }));
  };


  const [showParentModels, setShowParentModels] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [isImageM, setIsImageM] = useState(false);

  const handleLinkClick = (tab) => {
    setShowParentModels(true);
  };
  const [addlistingData, setAddlistingData] = useState([]);

  const handleLinkClickAddlisting = (itemDataAddlisting) => {
    console.log("itemDataAddlisting", itemDataAddlisting);
    setShowModalAddlisting(true);
    setAddlistingData(itemDataAddlisting);
    setShowParentModels(false);
  };
  const handleModalCloseAddlisting = () => {
    setShowModalAddlisting(false);
    setShowParentModels(false);
  };
  const handleLinkClickAddlistingAddCost = (itemDataAddlisting) => {
    setShowModalAddlistingAddCost(true);
    setAddlistingData(itemDataAddlisting);
    setShowParentModels(false);
  };
  const handleModalCloseAddlistingAddCost = () => {
    setShowModalAddlistingAddCost(false);
    setShowParentModels(false);
  };

  const handleOpenImgModal = (itemDataAddlisting, e) => {
    console.log("handleOpenImgModal isImageM", isImageM);
    e.preventDefault();
    e.stopPropagation();
    // console.log("************itemDataAddlisting in handleOpenImgModal*******************", itemDataAddlisting);
    setIsImageM(true);
    setShowModalAdd(false);
    setShowModalAddlisting(true);
    setShowModalImage(true);
    setAddlistingData(itemDataAddlisting);
  };

  const handleCloseImgModal = () => {
    console.log("handleCloseImgModal isImageM", isImageM);
    // setShowModalAddlisting(false);
    if (isImageM) {
      setShowModalAddlisting(false);
      setShowModalImage(false);
    } else {
      setShowModalImage(false);
    }
  };

  // console.log("addlisting*********135", addlistingData);
  // Handle image toggle state for all profiles
  const [connectionStatus, setConnectionStatus] = useState({
    dacmp_connected: false,
    unitedmotors_connected: false,
    google_connected: false,
    insta_connected: false,
    facebook_connected: false,
    craigslist_connected: false,
    twitter_connected: false,
  });

  const toggleConnectionStatus = (profileName) => {
    setConnectionStatus((prevStatus) => ({
      ...prevStatus,
      [profileName]: !prevStatus[profileName],
    }));
  };

  // Function to post advertisingPro data
  const updateAdvertisement = async () => {
    try {
      // console.log(advertisingPro);
      // console.log('Payload being sent:', JSON.stringify(advertisingPro, null, 2));

      const response = await fetch(`${apiUrl}/listing/update_advertisement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(advertisingPro),
      });

      if (response.ok) {
        const updatedData = await response.json();
        // console.log("Advertisement updated successfully:", updatedData);

        // Notify parent component of the update
        if (onUpdate) onUpdate(data.id, advertisingPro);

        // Update local state with the new data
        // setAdvertisingPro((prev) => ({
        //     ...prev,
        //     ...updatedData, // Assume the API response contains the updated fields
        // }));
      } else {
        console.error("Failed to update advertisement:", response.statusText);
        // Handle the error response as needed
      }
    } catch (error) {
      console.error("Error updating advertisement:", error);
      // Handle any errors that occur during the fetch
    } finally {
      setShow(false);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};

  // console.log("location", location);
  console.log("updatedData", updatedData);
  // console.log("originalData", originalData);

  // Function to check if a field has been updated
  const isFieldUpdated = (fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };
  // navigate("/myprofile/addlist", { state: { vin, active: true } });
  // Navigate(`/dealer/sell#${data.stock_no}#${data.id}`, { state: { data } });
  const [showEditModal, setShowEditModal] = useState(false);
  // console.log("showEditModal ", showEditModal);
  const dispatch = useDispatch();

  const [showModalSell, setShowModalSell] = useState(false);
  const [showModalSellb, setShowModalSellb] = useState(false);
  const handleModalCloseSell = () => {
    setShowModalSell(false);
  };
  const handleModalCloseSellb = () => {
    setShowModalSellb(false);
  };
  const dataPopulateInSell = (saleactive) => {
    dispatch(setSelectedVehiclePopOut(data));
    if (saleactive === "Buyer") {
      dispatch(setActiveTab("Buyer"));
    } else {
      dispatch(setActiveTab("Buyer"));
    }
    // Navigate(`/dealer/sell`, { state: { data } });
    console.log("data*****8888", data);
    dispatch(
      setSellData({
        buyer: {
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          idType: data.idType,
          idNumber: data.idNumber,
          idState: data.idState,
          address: data.address,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          businessName: data.businessName,
          county: data.county,
          address2: data.address2,
          phoneNumber: data.phoneNumber,
          email: data.email,
        },
      })
    );
    dispatch(
      setCoBuyerSellData({
        sellCoBuyer: {
          businessName: data.sellCoBuyer.businessName,
          firstName: data.sellCoBuyer.firstName,
          middleName: data.sellCoBuyer.middleName,
          lastName: data.sellCoBuyer.lastName,
          idType: data.sellCoBuyer.idType,
          idNumber: data.sellCoBuyer.idNumber,
          idState: data.sellCoBuyer.idState,
          address: data.sellCoBuyer.address,
          county: data.sellCoBuyer.county,
          address2: data.sellCoBuyer.address2,
          phoneNumber: data.sellCoBuyer.phoneNumber,
          email: data.sellCoBuyer.email,
          city: data.sellCoBuyer.city,
          state: data.sellCoBuyer.state,
          zipCode: data.sellCoBuyer.zipCode,
          isCoBuyerEntity : data.sellCoBuyer.isCoBuyerEntity,
        },
      })
    );
    dispatch(
      setRetailData({
        // buyerTagFees: res.sellPriceFees.buyerTagFees || 0,
        soldDataStatus:1,
        // cardFees: res.sellPriceFees.cardFees || 0,
        mileageIn: data.mileageIn || 0,
        userListingPrice: data.sellPriceFees.listingPrice || 0,
        cashDifference: data.sellPriceFees.cashDifference || 0,
        // ----------------ServiceContarct----------------------------------------------
        serviceContractName: data.serviceContract.serviceContractName || "",
        providerPhoneNumber: data.serviceContract.providerPhoneNumber || "",
        serviceContractAddress:
          data.serviceContract.serviceContractAddress || "",
        serviceContractId: data.serviceContract.serviceContractId || "",
        serviceProviderMonths: data.serviceContract.serviceProviderMonths || "",
        serviceContractMiles: data.serviceContract.serviceContractMiles || "",
        // Gap Insurance Data------------------------------------------------------------------------------
        gapName: data.gapInsurance.gapName || "",
        gapPhoneNumber: data.gapInsurance.gapPhoneNumber || "",
        gapAddress: data.gapInsurance.gapAddress || "",
        gapInsuranceId: data.gapInsurance.gapInsuranceId || "",
        gapMonths: data.gapInsurance.gapMonths || "",
        gapMiles: data.gapInsurance.gapMiles || "",
        // Lien Holder Data-----------------------------------------------------------------------------
        lienHolderName: data.lienHolder.lienHolderName || "",
        lienHolderAddress: data.lienHolder.leinHolderAddress || "",
        leinHolderAddress2: data.lienHolder.leinHolderAddress2 || "",
        leanHolderId: data.lienHolder.leanHolderId || "",
        //  For TradeIn Data-------------------------------------------------------*************------------------------------
        listingId: data?.listingId,
        tradeInId: data?.tradeInId,
        vin: data?.addListing?.vin,
        year_make_model: data?.addListing?.year_make_model,
        trim: data?.addListing?.trim,
        // maek year modal for check purpose only------
        make: data?.addListing?.modelMake,
        year: data?.addListing?.modelYear,
        model: data?.addListing?.makeModel,
        bodyClass: data?.addListing?.bodyClass,
        wheelbase: data?.addListing?.wheelbase,
        driveType: data?.addListing?.driveType,
        doorCount: data?.addListing?.doorCount,
        purchase_price: data?.addListing?.purchase_price,
        // ----------------------------------------
        engine: data?.addListing?.engine,
        transmission: data?.addListing?.transmission,
        vehicleType: data?.addListing?.vehicleType,
        seat_count: data?.addListing?.seat_count,
        city_mpg: data?.addListing?.city_mpg,
        highway_mpg: data?.addListing?.highway_mpg,
        inspection: data?.addListing?.inspection,
        exteriorColor: data?.addListing?.exteriorColor,
        interiorColor: data?.addListing?.interiorColor,
        interiorType: data?.addListing?.interiorType,
        mileage_in: data?.addListing?.mileage_in,
        //tradeInValue: formData.tradeInValue,
        listing_price: data?.addListing?.listing_price,
        // childSafetyFund: data.sellPriceFees.childSafetyFund || 0,
        // cntyRoadBridgeFee: res.sellPriceFees.cntyRoadBridgeFee || 0,
        // documentationFee: res.sellPriceFees.documentationFee || 0,
        // documentationFee: res.sellPriceFees.documentationFee || 0,
        // drivesOutTag: res.sellPriceFees.drivesOutTag || 0,
        // inspectionFeeOBDNL: res.sellPriceFees.inspectionFeeOBDNL || 0,
        listingPrice: data.sellPriceFees.listingPrice || 0,
        mInventoryTax: data.sellPriceFees.mInventoryTax || 0,
        // mailDeliveryFees: res.sellPriceFees.mailDeliveryFees || 0,
        ownedTrade: data.sellPriceFees.ownedTrade || 0,
        paymentPriceFees: data.sellPriceFees.paymentPriceFees || 0,
        priceFeesPaymentMethod: data.sellPriceFees.priceFeesPaymentMethod || "",
        processingHandlingFee: data.sellPriceFees.processingHandlingFee || 0,
        // regFeeDps: res.sellPriceFees.regFeeDps || 0,
        salesTaxes: data.sellPriceFees.salesTaxes || 0,
        // technologyFees: res.sellPriceFees.technologyFees || 0,
        // terpTitlefee: res.sellPriceFees.terpTitlefee || 0,
        // titleApplicationFee: res.sellPriceFees.titleApplicationFee || 0,
        totalFees: data.sellPriceFees.totalFees || 0,
        totalFeesAndothers: data.sellPriceFees.totalFeesAndothers || 0,
        transportationFees: data.sellPriceFees.txtTitleType || 0,

        // warranty: res.sellPriceFees.warranty || 0,
        // windshieldTrackerSticker:
        //   res.sellPriceFees.windshieldTrackerSticker || 0,
        other1: data.sellPriceFees.other1 || 0,
        other2: data.sellPriceFees.other2 || 0,
        date: data.sellPriceFees.priceFeeDate || 0,
        txtTitleType: data.saleType,
      })
    );
    // setShowModalSell(true);
    setShowParentModels(false);
    navigate("/dealer/sell");
  };
  // only for buyer---------------------------------------------
  const dataPopulateInSellb = (saleactive) => {
    console.log("Only Buyer");
    dispatch(setSelectedVehiclePopOut(data));
    if (saleactive === "Buyer") {
      dispatch(setActiveTab("Buyer"));
    } else {
      dispatch(setActiveTab("Sale Type"));
    }
    // Navigate(`/dealer/sell`, { state: { data } });
    dispatch(
      setSellData({
        buyer: {
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          idType: data.idType,
          idNumber: data.idNumber,
          idState: data.idState,
          address: data.address,
          county: data.county,
          address2: data.address2,
          phoneNumber: data.phoneNumber,
          email: data.email,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
        },
      })
    );
    dispatch(
      setCoBuyerSellData({
        sellCoBuyer: {
          firstName: data.sellCoBuyer.firstName,
          middleName: data.sellCoBuyer.middleName,
          lastName: data.sellCoBuyer.lastName,
          idType: data.sellCoBuyer.idType,
          idNumber: data.sellCoBuyer.idNumber,
          idState: data.sellCoBuyer.idState,
          address: data.sellCoBuyer.address,
          county: data.sellCoBuyer.county,
          address2: data.sellCoBuyer.address2,
          phoneNumber: data.sellCoBuyer.phoneNumber,
          email: data.sellCoBuyer.email,
          city: data.sellCoBuyer.city,
          state: data.sellCoBuyer.state,
          zipCode: data.sellCoBuyer.zipCode,
        },
      })
    );
    dispatch(
      setRetailData({
        buyerTagFees: data?.sellPriceFees?.buyerTagFees || 0,
        cardFees: data?.sellPriceFees?.cardFees || 0,
        cashDifference: data?.sellPriceFees?.cashDifference || 0,
        childSafetyFund: data?.sellPriceFees?.childSafetyFund || 0,
        cntyRoadBridgeFee: data?.sellPriceFees?.cntyRoadBridgeFee || 0,
        documentationFee: data?.sellPriceFees?.documentationFee || 0,
        documentationFee: data?.sellPriceFees?.documentationFee || 0,
        drivesOutTag: data?.sellPriceFees?.drivesOutTag || 0,
        inspectionFeeOBDNL: data?.sellPriceFees?.inspectionFeeOBDNL || 0,
        listingPrice: data?.sellPriceFees?.listingPrice || 0,
        mInventoryTax: data?.sellPriceFees?.mInventoryTax || 0,
        mailDeliveryFees: data?.sellPriceFees?.mailDeliveryFees || 0,
        ownedTrade: data?.sellPriceFees?.ownedTrade || 0,
        paymentPriceFees: data?.sellPriceFees?.paymentPriceFees || 0,
        priceFeesPaymentMethod:
          data?.sellPriceFees?.priceFeesPaymentMethod || 0,
        processingHandlingFee: data?.sellPriceFees?.processingHandlingFee || 0,
        regFeeDps: data?.sellPriceFees?.regFeeDps || 0,
        salesTaxes: data?.sellPriceFees?.salesTaxes || 0,
        technologyFees: data?.sellPriceFees?.technologyFees || 0,
        terpTitlefee: data?.sellPriceFees?.terpTitlefee || 0,
        titleApplicationFee: data?.sellPriceFees?.titleApplicationFee || 0,
        totalFees: data?.sellPriceFees?.totalFees || 0,
        totalFeesAndothers: data?.sellPriceFees?.totalFeesAndothers || 0,
        transportationFees: data?.sellPriceFees?.txtTitleType || 0,

        warranty: data?.sellPriceFees?.warranty || 0,
        windshieldTrackerSticker:
          data?.sellPriceFees?.windshieldTrackerSticker || 0,
        other1: data?.sellPriceFees?.other1 || 0,
        other2: data?.sellPriceFees?.other2 || 0,
        date: data?.sellPriceFees?.priceFeeDate || 0,
        txtTitleType: data?.saleType,
      })
    );
    setShowModalSellb(true);
    setShowParentModels(false);
  };
  // ------------------------------------------------------------------
  const Navigate = useNavigate();

  const handleGoToSell = () => {
    // Make sure that 'data' is correctly defined and holds the necessary information
    Navigate(`/dealer/sell#${data.stock_no}#${data.id}`, { state: { data } });
  };

  useEffect(() => {
    if (data && data.addvertising) {
      const newSocialIcons = [];
      const advertisingData = data.addvertising;

      // Iterate over the keys of the advertisingData object
      for (const key in advertisingData) {
        // Check if the key ends with '_status' and its value is true
        if (key.endsWith("_status") && advertisingData[key]) {
          // Extract the social profile name from the key
          const profile = key.split("_status")[0];
          // Push the profile name to the newSocialIcons array
          newSocialIcons.push(profile);
        }
      }
      // Set the socialIcons state with the newSocialIcons array
      setSocialIcons(newSocialIcons);
    }
  }, [data]);

  const colorMapping = {
    Beige: "#F5F5DC",
    Black: "#151517",
    Blue: "#2c447b",
    Bronze: "#655e5f",
    Brown: "brown",
    "Dark Gray": "#a6b0b6",
    "Dark Red": "#802630",
    Grey: "#808080",
    "Light Gray": "#c6ccd4",
    Magenta: "#916075",
    Maroon: "#8e373e",
    "Off White": "#f4ebdd",
    Orange: "#f79057",
    Pink: "#d60f6f",
    Purple: "#6316bc",
    Red: "#de0a06",
    Silver: "#d4d4d4",
    Tan: "#c3793f",
    White: "#ffffff",
    Yellow: "#f5d512",
    Burgundy: "#800020",
    Charcoal: "#36454F",
    Gold: "#FFD700",
    Green: "#008000",
  };

  ///
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => toast.success("Copied Vin Number!"),
      (err) => toast.error("Failed to copy: " + err)
    );
  };
  const copyToClipboardBuyerName = (data) => {
    const text =
      // (data?.isBuyerEntity
      //   ? ` ${data?.businessName}`
      //   : `${data?.firstName} ${data?.lastName}`) +
      data?.sellCoBuyer?.isCoBuyerEntity
        ? ` ${data?.sellCoBuyer.businessName}`
        : data?.sellCoBuyer?.firstName
        ? `${data?.sellCoBuyer.firstName} ${data?.sellCoBuyer.lastName}`
        : "";
    navigator.clipboard.writeText(text).then(
      () => toast.success("Copied Co-Buyer Name!"),
      (err) => toast.error("Failed to copy: " + err)
    );
  };
  const copyToClipboardBuyerNamea = (data) => {
    const text = data?.isBuyerEntity
      ? ` ${data?.businessName}`
      : `${data?.firstName} ${data?.lastName}`;
    //    +
    // (data?.sellCoBuyer?.isCoBuyerEntity
    //   ? ` ${data?.sellCoBuyer.businessName}`
    //   : data?.sellCoBuyer?.firstName
    //   ? ` / ${data?.sellCoBuyer.firstName} ${data?.sellCoBuyer.lastName}`
    //   : "");
    navigator.clipboard.writeText(text).then(
      () => toast.success("Copied Buyer Name!"),
      (err) => toast.error("Failed to copy: " + err)
    );
  };
  // Set the background color dynamically based on the received color names
  const exteriorBackgroundColor =
    colorMapping[data?.addListing2?.exteriorColor];
  const interiorBackgroundColor =
    colorMapping[data?.addListing2?.interiorColor];
  // console.log(
  //   "multimedia data ",
  //   data.addListing2 && data.addListing2.multimedia
  // );
  const {
    all_images = [], // Default to an empty array if undefined or null
    exterior_images = [],
    misc_images = [],
    interior_images = [],
    three_sixty_images = [],
  } = (data.addListing2 && data.addListing2.multimedia) || {}; // If data.addListing2 or multimedia is null, default to empty object

  // You can also check if the object is null or undefined explicitly:
  if (!data.addListing2 || !data.addListing2.multimedia) {
    console.error("Error: multimedia data is missing");
  }

  const noImage = Image.noImage || "default_image_path";
  const getFirstValidImage = (images) => {
    if (images && typeof images === "string") {
      // Ensure images is a string
      const imageList = images.split(",").map((img) => img.trim()); // Split and trim each image
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${id}/${image.trim()}`;
        }
      }
    } else {
      console.error("Invalid images format:", images);
    }
    return null;
  };

  const imageUrl =
    (all_images && all_images && getFirstValidImage(all_images)) ||
    (exterior_images &&
      exterior_images &&
      getFirstValidImage(exterior_images)) ||
    (misc_images && misc_images && getFirstValidImage(misc_images)) ||
    (interior_images &&
      interior_images &&
      getFirstValidImage(interior_images)) ||
    (three_sixty_images &&
      three_sixty_images &&
      getFirstValidImage(three_sixty_images)) ||
    noImage;

  // console.log("socialIcons", socialIcons);
  console.log("data", data);
  // console.log("advertisingPro", advertisingPro);
  const [deleteModal, setShowDeleteModal] = useState(false);
  //Preview Modal
  const userData = useSelector((state) => state.user);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  //Checkboxes for preview pdfs
  const [checkboxes, setCheckboxes] = useState({
    billOfSale: true,
    buyerGuide: true,
    odometerDisclosure: true,
    titleApp: true,
    AgreementInsurance: true,
    creditBureauDisclosure: true,
    rebuildTitleDisclosure: true,
    powerOfAttorney: true,
    retailContractInstallment: true,
    salesTaxExemptionCertificate: true,
    statementOfFacts: true,
    airbagDisclosure: true,
    serviceContract: true,
    countyOfTitleIssuance: true,
    accuracyInformationAcknowledment: true,
    rightToReposses: true,
  });

  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [selectedDeleteOption, setSelectedDeleteOption] = useState("");
  const [selectedDeletePurpose, setSelectedDeletePurpose] = useState("");

  // console.log("selectedDeletePurpose  ", selectedDeletePurpose);

  const handleDeleteCheck = (e) => {
    const { value } = e.target;
    setSelectedDeleteOption(value);
    if (value === "deleteOption1") {
      setSelectedDeletePurpose("delete");
    } else {
      setSelectedDeletePurpose("active");
    }
  };
  //sell/toggle_status/delete/sellid/userid
  //sell/active/sellid/userid

  const deleteSoldItem = async () => {
    // console.log("Purpose of this Apply button", selectedDeletePurpose);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/toggle_status/${selectedDeletePurpose}/${data.id}/${userData.id}`
      );
      // console.log("Result after deleting the inventory", result);
      console.log("selectedDeletePurpose", selectedDeletePurpose);
      if (result.status === 200) {
        setIsDeleted(true);
        setShowDeleteOptions(false);
        setShowDeleteModal(false);
        setShowModalSell(false);
        if (selectedDeletePurpose === "delete") {
          Navigate("/dealer/inventory/active");
        }
      }
    } catch (error) {
      console.log("Error while deleting the product => ", error);
    }
  };
  // for select unselect---------------------------------
  const handleSelected = (index) => {
    setselectedCards((prevselectedCards) => {
      if (prevselectedCards.includes(index)) {
        // If the card is already selected, remove it
        return prevselectedCards.filter((i) => i !== index);
      } else {
        // If the card is not selected, add it
        return [...prevselectedCards, index];
      }
    });
  };
  console.log("selectedCards", selectedCards);
  return (
    <>
      <Container className={`px-0 bg-tr1`}>
        <Row
          className="mx-0 rounded bg-body border-2 bg-tr1 pb-4"
          style={{ position: "relative", zIndex: "5 " }}
        >
          {/* <div className="" style={{position:"relative"}}> */}
          <Col sm={1} className={`px-0 ${styles.selectionBox}`} key={index}>
            <div className={`${styles.cardCount} p-auto`}>{index + 1}</div>
            <div className={`${styles.selectionBtn}`}>
              <img
                src={
                  selectedCards && selectedCards.includes(index + 1)
                    ? Image.selected
                    : Image.select
                }
                alt={
                  selectedCards && selectedCards.includes(index + 1)
                    ? "selected"
                    : "select"
                }
                className="select-img"
              />
              {/* {selectedCards.includes(index) ? "S" : "N"} */}
            </div>
          </Col>

          <Col sm={11} className="px-3 cards bg-tr1">
            <Row
              onClick={() => {
                handleSelected(index + 1);
              }}
              className={`rounded border border-2 p-2 ${
                selectedCards && selectedCards.includes(index + 1)
                  ? "bg-card"
                  : ""
              }`}
            >
              <Col md={12} className="" style={{ paddingRight: "2%" }}>
                <Container fluid className="px-0">
                  <Row>
                    <Col
                      lg={3}
                      className="shadow px-2 image-con"
                      style={{ position: "relative" }}
                    >
                      <MdOutlineShare
                        fill="black"
                        className={` ${styles.share}`}
                      />
                      <div className={`${styles.productActives}`}>
                        <span
                          className={`${styles.addDate}`}
                          style={{ color: "#D1823A", paddingLeft: "2rem" }}
                        >
                          Sold On: {data?.sellPriceFees?.priceFeeDate}{" "}
                        </span>
                        <img
                          //src="http://localhost:9999/images/24/download%20(1).jpg"
                          src={imageUrl}
                          className=""
                          alt="Inventory Car Image"
                          onClick={(e) => handleOpenImgModal(data, e)}
                        />
                        <div className="d-flex flex-row justify-content-between  align-items-center ps-4">
                          {/* <span
                              className={`${styles.addDate}`}
                              style={{ color: "#D1823A" }}
                            >
                              Sold On: {data.created_at}
                            </span>
                            <span
                              className={` rounded-pill ${styles.Exteriors}`}
                              style={{
                                backgroundColor: exteriorBackgroundColor,
                              }}
                            >
                              Exterior
                            </span>
                            <span
                              className={` rounded-pill ${styles.Interiors}`}
                              style={{
                                backgroundColor: interiorBackgroundColor,
                              }}
                            >
                              Interior
                            </span> */}
                          <div
                            className="interior_exterior_color_container"
                            style={{ gap: "3rem" }}
                          >
                            <span>Exterior</span>
                            <span
                              style={{
                                backgroundColor: exteriorBackgroundColor,
                                width: "20px",
                                height: "20px",
                                color: exteriorBackgroundColor ? "white" : "",
                              }}
                              title={
                                data?.exteriorColor ? data?.exteriorColor : ""
                              }
                            ></span>
                            <span
                              style={{
                                marginLeft: "0.3rem",
                              }}
                            >
                              Interior
                            </span>
                            <span
                              style={{
                                backgroundColor: interiorBackgroundColor,
                                width: "20px",
                                height: "20px",
                              }}
                              title={
                                data?.interiorColor ? data?.interiorColor : ""
                              }
                            ></span>
                          </div>
                          {/* <div
                            className="interior_exterior_color_container"
                            style={{ paddingBottom: "2px" }}
                          >
                            <span
                              style={{ fontWeight: "400", fontSize: "small" }}
                            >
                              Exterior
                            </span>
                            <span
                              style={{
                                backgroundColor: exteriorBackgroundColor,
                              }}
                              title={
                                exteriorBackgroundColor
                                  ? exteriorBackgroundColor
                                  : ""
                              }
                            ></span>
                            <span
                              style={{
                                marginLeft: "0.3rem",
                                fontWeight: "400",
                                fontSize: "small",
                              }}
                            >
                              Interior
                            </span>
                            <span
                              style={{
                                backgroundColor: interiorBackgroundColor,
                              }}
                              title={
                                interiorBackgroundColor
                                  ? interiorBackgroundColor
                                  : ""
                              }
                            ></span>
                          </div> */}
                        </div>
                      </div>
                    </Col>

                    <Col lg={7}>
                      <Container fluid className="px-0 h-100">
                        <Row className="h-100 d-flex align-items-between">
                          <Col sm={12} className="px-0">
                            <Container>
                              <Row className="d-flex justify-content-between">
                                <Col
                                  sm={11}
                                  className={`${styles.cardHead}`}
                                  onClick={() =>
                                    handleLinkClickAddlisting(data)
                                  }
                                  // onClick={() =>
                                  //   handleLinkClick("Vehicle List")
                                  // }
                                  style={{
                                    textShadow: isFieldUpdated("trim")
                                      ? "#ff8c21 1px 0 10px"
                                      : "black",
                                  }}
                                >
                                  {data.addListing2?.year_Make_Model}{" "}
                                  {data?.addListing2?.trim}
                                </Col>

                                <Col>
                                  <div
                                    className={` ${styles.stockNo} text-nowrap cursor-pointer`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span
                                      onClick={() =>
                                        dataPopulateInSellb("Buyer")
                                      }
                                    >
                                      {" "}
                                      {data?.isBuyerEntity
                                        ? data?.businessName
                                        : `${data?.firstName} ${data?.lastName}`}{" "}
                                      <span
                                        className="tooltip-container-active-inventory"
                                        style={{ marginLeft: "0.rem" }}
                                        onClick={(event) => {
                                          event.preventDefault();
                                          event.stopPropagation();
                                          copyToClipboardBuyerNamea(data);
                                        }}
                                      >
                                        {" "}
                                        <IoCopyOutline className="info-icon-active-inventory" />
                                      </span>
                                      {data?.sellCoBuyer?.isCoBuyerEntity ? (
                                        <>
                                          {" "}
                                          / {
                                            data?.sellCoBuyer?.businessName
                                          }{" "}
                                          <span
                                            className="tooltip-container-active-inventory"
                                            style={{ marginLeft: "0.rem" }}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                              copyToClipboardBuyerName(data);
                                            }}
                                          >
                                            {" "}
                                            <IoCopyOutline className="info-icon-active-inventory" />
                                          </span>
                                        </>
                                      ) : data?.sellCoBuyer?.firstName ? (
                                        <>
                                          {" "}
                                          / {data?.sellCoBuyer?.firstName}{" "}
                                          {data?.sellCoBuyer?.lastName}{" "}
                                          <span
                                            className="tooltip-container-active-inventory"
                                            style={{ marginLeft: "0.rem" }}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                              copyToClipboardBuyerName(data);
                                            }}
                                          >
                                            {" "}
                                            <IoCopyOutline className="info-icon-active-inventory" />
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                          <Col sm={12} className="px-0">
                            <div
                              className={`${styles.subHead} ${styles.dataFont}`}
                            >
                              <div
                                className={`text-nowrap mx-2 px-1 fw-bold ${styles.vin}`}
                              >
                                <span
                                  className={`${styles.orangeColor}`}
                                  onClick={() =>
                                    handleLinkClickAddlisting(data)
                                  }
                                  // onClick={() =>
                                  //   handleLinkClick("Vehicle List")
                                  // }
                                >
                                  VIN:
                                </span>
                                <span
                                  onClick={() =>
                                    handleLinkClickAddlisting(data)
                                  }
                                  // onClick={() =>
                                  //   handleLinkClick("Vehicle List")
                                  // }
                                >
                                  {" "}
                                  XXXXXXXXX
                                  {data?.addListing2?.vin.slice(-8)}{" "}
                                </span>
                                <span
                                  className="tooltip-container-active-inventory"
                                  style={{ marginLeft: "0.rem" }}
                                  onClick={() =>
                                    copyToClipboard(data?.addListing2?.vin)
                                  }
                                >
                                  {" "}
                                  <IoCopyOutline className="info-icon-active-inventory" />
                                </span>
                              </div>
                              <div className="px-0 engine">
                                {/* {data?.addListing2?.mileage_in ? (
                                          <Col
                                            sm={3}
                                            className={` text-nowrap fw-bold  border-2 px-2 ${styles.grayColor}`}
                                            style={{
                                              textShadow: isFieldUpdated(
                                                "mileage_in"
                                              )
                                                ? "#ff8c21 1px 0 10px"
                                                : "black",
                                              color: isFieldUpdated(
                                                "mileage_in"
                                              )
                                                ? "orange"
                                                : "black",
                                            }}
                                          >
                                            {new Intl.NumberFormat().format(
                                              data?.addListing2?.mileage_in
                                            )}
                                          </Col>
                                        ) : null} */}

                                {data?.addListing2?.engine ? (
                                  <span
                                    className=" border-2 px-0"
                                    style={{
                                      textShadow: isFieldUpdated("engine")
                                        ? "#ff8c21 1px 0 10px"
                                        : "black",
                                    }}
                                  >
                                    {data?.addListing2?.engine}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} className="px-0">
                            <Container fluid>
                              <Row>
                                <Col>
                                  <Container fluid>
                                    <Row
                                      className={` ${styles.dataFont} text-center`}
                                    >
                                      <Col
                                        className={`px-0 ${styles.box} me-2 `}
                                        style={{
                                          textShadow: isFieldUpdated(
                                            "purchasedDate"
                                          )
                                            ? "#ff8c21 1px 0 10px"
                                            : "black",
                                          // color: isFieldUpdated("trim") ? "orange" : "black",
                                        }}
                                      >
                                        Vehicle Type{" "}
                                        <p
                                          className={`py-0 m-0 ${styles.orangeColor}`}
                                        >
                                          {data?.addListing2?.vehicleType}
                                        </p>
                                      </Col>
                                      <Col
                                        className={`px-0 ${styles.box} me-2 `}
                                      >
                                        Mileage{" "}
                                        <p
                                          className={`py-0 m-0 ${styles.orangeColor}`}
                                        >
                                          {
                                           isNaN(data?.mileageIn) 
                                            ? data?.mileageIn 
                                            : new Intl.NumberFormat().format(data?.mileageIn)
                                          }
                                        </p>
                                      </Col>
                                      <Col
                                        className={`px-0 ${styles.box} me-2 `}
                                        style={{
                                          textShadow: isFieldUpdated(
                                            "inspection"
                                          )
                                            ? "#ff8c21 1px 0 10px"
                                            : "black",
                                          // color: isFieldUpdated("trim") ? "orange" : "black",
                                        }}
                                      >
                                        Sale Type{" "}
                                        <p
                                          className={`py-0 m-0 ${styles.orangeColor}`}
                                        >
                                          {data.saleType}
                                        </p>
                                      </Col>
                                      <Col
                                        className={`px-0 ${styles.box} me-2 `}
                                        style={{
                                          textShadow: isFieldUpdated(
                                            "inspection"
                                          )
                                            ? "#ff8c21 1px 0 10px"
                                            : "black",
                                          // color: isFieldUpdated("trim") ? "orange" : "black",
                                        }}
                                      >
                                        Sale Price{" "}
                                        <p
                                          className={`py-0 m-0 ${styles.orangeColor}`}
                                        >
                                          ${" "}
                                          {new Intl.NumberFormat().format(
                                            data.sellPriceFees?.listingPrice
                                          )}{" "}
                                        </p>
                                      </Col>

                                      {/* <Col sm={2} className="px-0 me-3 d-flex align-items-end">
                                                                                    <h6 className="my-0">Acc. No. <span className={`${styles.blueColor}`}>7646</span></h6>
                                                                                </Col> */}
                                      {/* <Col
                                          sm={3}
                                          className="px-0 d-flex align-items-end"
                                        >
                                          <h6 className="my-0">
                                            Jessica Hernandez
                                          </h6>
                                        </Col> */}
                                    </Row>
                                  </Container>
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                    </Col>

                    <Col
                      lg={2}
                      className=" px-1 mt-5 d-flex align-items-center justify-content-around"
                    >
                      {/* <h4
                          className={` ${styles.dataFont} ${styles.stockNo} text-nowrap mb-4 cursor-pointer`}
                          onClick={() => dataPopulateInSell("Buyer")}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          {data.firstName} {data.lastName}
                        </h4> */}
                      <div className="d-flex flex-column justify-content-around">
                        <Button
                          className="product-g-btns border-0 fw-bold p-1"
                          onClick={() => handleLinkClickAddlistingAddCost(data)}
                        >
                          Add Cost
                        </Button>
                        <Button
                          className="product-g-btns border-0 fw-bold p-1"
                          onClick={() => setShowPreviewModal(true)}
                        >
                          Preview
                        </Button>
                        <Button
                          className={`product-g-btns border-0 fw-bold p-1`}
                          onClick={() => dataPopulateInSell()}
                        >
                          Edit
                        </Button>
                        {/* <Button
                          className="product-g-btns border-0 fw-bold p-1"
                          onClick={() => setShowPreviewModal1(true)}
                        >
                          Preview
                        </Button> */}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <div
              className="row mb-3 h-100"
              style={{
                display: "inline-block",
                width: "5%",
                position: "absolute",
                right: "1%",
              }}
            >
              {/* <Container fluid className="px-0 h-100">
                  <div className="row d-flex flex-nowrap h-100">
                    <div className="col-3 h-100 px-0 d-flex flex-column justify-content-between">
                      <div
                        className="d-flex align-items-center justify-content-around"
                        style={{ height: "100%" }}
                      >
                        <div className="vr pr-2 mx-1"></div>
                        <div className="d-flex flex-column align-items-center justify-content-around h-100">
                          <div
                            className={` ${styles.edit} ml-3 rounded-circle px-2 py-1 bg-light`}
                            onClick={() => dataPopulateInSell()}
                          >
                            <MdModeEdit />
                          </div>
                          {/* <div
                            className={` ${styles.edit} ml-3 rounded-circle px-2 py-1 bg-light`}
                            onClick={() => setShowDeleteModal(true)}
                          >
                            <MdDelete />
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </Container> */}

              <Modal
                show={deleteModal}
                size="xl"
                onHide={() => setShowDeleteModal(false)}
                dialogClassName=""
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Delete this Inventory ?
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {" "}
                    <p>Are you sure you want to delete this inventory?</p>
                    <Button
                      style={{
                        marginRight: "2rem",
                        backgroundColor: "red",
                        border: "none",
                      }}
                      onClick={() => setShowDeleteOptions(true)}
                    >
                      Yes
                    </Button>
                    {showDeleteOptions && (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          marginTop: "1rem",
                        }}
                      >
                        <label htmlFor="deleteOption1">
                          <input
                            type="radio"
                            name="deleteOptions"
                            id="deleteOption1"
                            value="deleteOption1"
                            checked={selectedDeleteOption === "deleteOption1"}
                            onChange={handleDeleteCheck}
                          />
                          <span style={{ marginLeft: "1.5rem" }}>
                            Cancel this transaction and return inventory into
                            active inventory ?
                          </span>
                        </label>

                        <label htmlFor="deleteOption2">
                          <input
                            type="radio"
                            name="deleteOptions"
                            id="deleteOption2"
                            value="deleteOption2"
                            checked={selectedDeleteOption === "deleteOption2"}
                            onChange={handleDeleteCheck}
                          />
                          <span style={{ marginLeft: "1.5rem" }}>
                            Cancel this transaction and return inventory into
                            active inventory and keep transaction into sold
                            inventory ?
                          </span>
                        </label>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "orange",
                              borderRadius: "5px",
                              padding: "0.3rem 0.7rem",
                              color: "white",
                              fontWeight: "500",
                            }}
                            onClick={deleteSoldItem}
                          >
                            Apply
                          </button>
                        </span>
                      </p>
                    )}
                  </div>
                </Modal.Body>
              </Modal>

              {/* Modal for edit */}
              <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                size="lg"
              >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                  <h3 style={{ textAlign: "center", marginBottom: "3rem" }}>
                    <span>Do you want to edit this deal?</span>
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "3rem",
                      fontSize: "large",
                      fontWeight: "500",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "green",
                        borderRadius: "5px",
                        color: "white",
                        width: "20%",
                        textAlign: "center",
                        height: "30px",
                        cursor: "pointer",
                        paddingTop: "0.1rem",
                      }}
                      onClick={() => {
                        setShowEditModal(false);
                        handleLinkClick();
                      }}
                    >
                      Yes to continue
                    </span>
                    <span
                      style={{
                        backgroundColor: "red",
                        borderRadius: "5px",
                        color: "white",
                        width: "20%",
                        textAlign: "center",
                        height: "30px",
                        cursor: "pointer",
                        paddingTop: "0.1rem",
                      }}
                      onClick={() => {
                        setShowEditModal(false);
                      }}
                    >
                      No to exit
                    </span>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>
          </Col>
        </Row>
        <SoldPreviewModal
          data={data}
          showPreviewModal={showPreviewModal}
          setShowPreviewModal={setShowPreviewModal}
          imageUrl={imageUrl}
        />
        <AddlistingDataSold
          show={showModalAddlisting}
          handleClose={handleModalCloseAddlisting}
          addlistingData={addlistingData}
          showModalImage={showModalImage}
          showModalAdd={showModalAdd}
          isImageM={isImageM}
          setShowModalImage={setShowModalImage}
          handleCloseImgModal={handleCloseImgModal}
          addlistionIdShow={2}
        />
        <AddlistingDataSoldAddCost
          show={showModalAddlistingAddCost}
          handleClose={handleModalCloseAddlistingAddCost}
          addlistingData={addlistingData}
          showModalImage={showModalImage}
          showModalAdd={showModalAdd}
          isImageM={isImageM}
          setShowModalImage={setShowModalImage}
          handleCloseImgModal={handleCloseImgModal}
          addlistionIdShow={2}
        />
        <SellDatab
          show={showModalSellb}
          handleClose={handleModalCloseSellb}
          VehicleName={data}
          status={true}
          setShowDeleteModal={setShowDeleteModal}
          statusDelete={1}
        />
      </Container>
      {/* Modal for asking which Data to edit , sold or sell */}
      <Modal
        show={showParentModels}
        onHide={() => setShowParentModels(false)}
        size="lg"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <h3 style={{ textAlign: "center", marginBottom: "3rem" }}>
            <span>Do you want to edit this deal?</span>
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "3rem",
              fontSize: "large",
              fontWeight: "500",
            }}
          >
            <span
              style={{
                backgroundColor: "green",
                borderRadius: "5px",
                color: "white",
                width: "40%",
                textAlign: "center",
                height: "40px",
                cursor: "pointer",
                paddingTop: "0.35rem",
              }}
              onClick={dataPopulateInSell}
            >
              Want to edit Sold Data ?
            </span>
            <span
              style={{
                backgroundColor: "red",
                borderRadius: "5px",
                color: "white",
                width: "40%",
                textAlign: "center",
                height: "40px",
                cursor: "pointer",
                paddingTop: "0.35rem",
              }}
              onClick={() => handleLinkClickAddlisting(data)}
            >
              Want to edit Maintainance Data ?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* <SelldataSold
          show={showModalSell}
          handleClose={handleModalCloseSell}
          VehicleName={data}
          status={true}
          setShowDeleteModal={setShowDeleteModal}
          statusDelete={1}
        /> */}
    </>
  );
};

export default SoldCard;
