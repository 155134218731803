// Retry Facebook sign-in
// SignInWithFacebook.js
import { auth, FbOAuthProvider } from "./firebaseConfig";
import { signInWithPopup } from "firebase/auth";

import "react-toastify/dist/ReactToastify.css";

const SignInWithFacebook = async (setSaveUser) => {
  try {
    let result = await signInWithPopup(auth, FbOAuthProvider);

    if (result) {
      const user = result.user;

      console.log("User:", user);
      console.log("User Name:", user.displayName);
      console.log("User Email:", user.email);
      console.log("User Phone Number:", user.phoneNumber);

      return result;
    }
  } catch (error) {
    console.error("Sign in with Facebook failed:", error);
  }
};

export default SignInWithFacebook;
