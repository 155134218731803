import React, { forwardRef, useImperativeHandle, useState } from "react";
import styles from "../PaymentHistoryCard.module.css";
import { PiDotsThreeCircleVertical } from "react-icons/pi";
import { MdDelete, MdEmail } from "react-icons/md";
import { RiFileEditLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const PaymentHistoryCard = forwardRef(
  (
    {
      index,
      data,
      arrayCheckIDToSend,
      handleGetDetails,
      setArrayCheckIDToSend,
    },
    ref
  ) => {
    console.log("UserData in card = > ", data);
    const [showEditPopout, setShowEditPopOut] = useState(false);
    const navigate = useNavigate();
    const handleEditNavigate = () => {
      navigate("/dealer/inventory/balance/payment", {
        state: {
          item: data,
          isEditing: true,
        },
      });
    };
    const [deletItemId, setDeleletItemId] = useState([]);
    const handleDeleteRow = async (dataId) => {
      try {
        // delete_paymenthistory/{userId}/{id}
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sell/delete_paymenthistory/${data.userId}`,
          [dataId]
        );
        if (response.status === 200) {
          handleGetDetails();
          setShowEditPopOut(false);
        }
      } catch (error) {
        console.log("Error while response from API => ", error);
      }
    };
    useImperativeHandle(ref, () => ({
      triggerClick: handleEmailReceipt,
    }));

    const handleEmailReceipt = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/sell/send_receipt/${data.userId}/${arrayCheckIDToSend[0]}`
        );
        if (response.status === 200) {
          console.log("Response from PDF API => ", response);
          toast.success("Payment Receipt sent via Email.");
          setShowEditPopOut(false);
        }
      } catch (error) {
        console.log("Error while response from API => ", error);
      }
    };

    const handleCheckAddId = (id) => {
      if (arrayCheckIDToSend.includes(id)) {
        setArrayCheckIDToSend(arrayCheckIDToSend.filter((item) => item !== id));
      } else {
        setArrayCheckIDToSend([...arrayCheckIDToSend, id]);
      }
    };
    return (
      <div className={styles.wrapper}>
        <span className={styles.absoluteKey}>{index + 1}</span>

        <div
          className={styles.container}
          style={{
            backgroundColor: arrayCheckIDToSend.includes(data.id)
              ? "#f8cfbe"
              : "",
          }}
        >
          <div className={styles.row1}>
            <div className={styles.row1TransactionDateContainer}>
              <span>Transaction Date</span>
              <span>{data.paymentDate || "NA"}</span>
            </div>
            <div className={styles.row1DueDateContainer}>
              <p>
                <span>Due Date</span>
                <span>{data.nextPaymentDate || "NA"}</span>
              </p>
              <p>
                <span
                  onClick={() => setShowEditPopOut(!showEditPopout)}
                  className={styles.paymentAbsolute}
                >
                  <PiDotsThreeCircleVertical />
                </span>
                {showEditPopout && (
                  <div className={styles.absoluteContainer}>
                    {/* <p onClick={() => handleCheckAddId(data.id)}>
                      <span>
                        <MdEmail />
                      </span>
                      <span>Email</span>
                    </p> */}
                    <p onClick={handleEditNavigate}>
                      <span>
                        <RiFileEditLine />
                      </span>
                      <span>Edit</span>
                    </p>
                    <p
                      onClick={
                        index === 0 ? null : () => handleDeleteRow(data.id)
                      }
                    >
                      <span>
                        <MdDelete />
                      </span>
                      <span>Delete</span>
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
          <div className={styles.row2}>
            <div className={styles.row2PaymentLateContainer}>
              <div className={styles.paymentContainer}>
                <p>Payment</p>
                <p>$ {data.payment?.toFixed(2) || "NA"}</p>
              </div>
              <div className={styles.latePaymentContainer}>
                <p>Late Payment Fees</p>
                <p>$ {data.latePaymentFees || "NA"}</p>
              </div>
            </div>
            <div className={styles.row2PaymentLateContainer}>
              <div className={styles.paymentContainer}>
                <p>Balance</p>
                <p>$ {data.dueBalance?.toFixed(2) || "NA"}</p>
              </div>
              <div className={styles.latePaymentContainer}>
                <p>MSC & Other</p>
                <p>$ {data.mscOthers || "NA"}</p>
              </div>
            </div>
            <div className={styles.row3PaymentNotesContainer}>
              <div className={styles.paymentContainer}>
                <p>Payments Method</p>
                <p>{data.paymentMethod || "NA"}</p>
              </div>
              <div className={styles.latePaymentContainer}>
                <p>Notes</p>
                <p>{data.notes || "NA"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PaymentHistoryCard;
