import React, { useEffect } from 'react';

function GoogleOneTap() {
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        // This code will run after the Google library is loaded
        window.google.accounts.id.initialize({
          client_id: "882863583650-d9lpkgf8dch0aiipej2e1biugdh7vlhe.apps.googleusercontent.com",
        
          callback: handleCredentialResponse,
        });
      };
      document.body.appendChild(script);
    }
  }, []);

  const handleCredentialResponse = (response) => {
    console.log("Encoded JWT ID token: " + response.credential);
    
  };

  return <div id="g_id_onload"></div>;
}

export default GoogleOneTap;