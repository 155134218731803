import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./OffersMobile.module.css";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { FaCircleArrowRight } from "react-icons/fa6";

const OffersMobile = () => {
  const { dealerId } = useSelector((state) => state.user);
  // const [loanCardDataArray, setLoanCardDataArray] = useState([]);
  // console.log("loanCardDataArray => ", loanCardDataArray);
  //  dummy data  for mobile show----------------------------------------
  const loanCardDataArray = [
    {
      asset: "2014 Mercedes Sprinter",
      offered: "5,500.00",
      name: "Niraj Kumar",
      mobileNumber: "9752467788",
      email: "niraj@gmail.com",
      address: "Taxes , USA, Collin",
    },
    {
      asset: "2014 BMW Sprinter",
      offered: "5,576.00",
      name: "Sumit Kumar Rana",
      mobileNumber: "3456654575",
      email: "sumit@gmail.com",
      address: "Taxes , USA, Collin",
    },
    {
      asset: "2014 ODI Sprinter",
      offered: "5,556.00",
      name: "Afjal Khan",
      mobileNumber: "345646324575",
      email: "afjal@gmail.com",
      address: "Taxes , USA, Collin",
    },
  ];
  // const fetchDealerData = async () => {
  //   const fd = new FormData();
  //   fd.append("user_id", dealerId);

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/loan/get`,
  //       fd
  //     );
  //     setLoanCardDataArray(response.data.data);
  //   } catch (error) {
  //     console.log("Error fetching dealer data:", error);
  //     setLoanCardDataArray([]);
  //   }
  // };

  // useEffect(() => {
  //   if (dealerId) {
  //     fetchDealerData();
  //   }
  // }, [dealerId]);
  // for mobile popup-----------------------------------------------------------------------
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const showMobileMessagePopUp = () => {
    setShowMessagePopUp(true);
  };
  // -----------------------------------------------------------------------------------------
  return (
    <>
      <div className={styles.crmMwrapper}>
        {loanCardDataArray &&
          loanCardDataArray?.map((item, index) => (
            <div className={styles.crmMcontainer}>
              <div className={styles.crmMHeaderMain}>
                <div className={styles.crmMHeader}>
                  <span>Asset Info</span>
                  <span>{item.asset}</span>
                </div>
                <div
                  className={`${styles.crmMHeader} ${styles.crmMHeaderRight} `}
                >
                  <span>Offered Amount</span>
                  <span style={{ color: "blue" }}>$ {item.offered}</span>
                </div>
              </div>
              <div className={styles.crmMPersonalWrapper}>
                <div className={styles.crmMPersonalDetails}>
                  <p className={styles.crmMDetail}>
                    <span>Name</span>
                    <span>{item.name}</span>
                    {/* <span>{item.assetName}</span> */}
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Phone Number</span>
                    <span>{item.mobileNumber}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Email</span>
                    <span>{item.email}</span>
                  </p>
                </div>
                <div className={styles.crmMBtnContainer}>
                  <button onClick={showMobileMessagePopUp}>View</button>
                  {/* <button onClick={showMobileViewPopUp}>View</button> */}
                </div>
              </div>
              <span className={styles.crmMNumbering}>{index + 1}</span>
            </div>
          ))}
      </div>
      {/*--------- mobile messgae------------- */}
      <Modal
        show={showMessagePopUp}
        onHide={() => setShowMessagePopUp(false)}
        size="xl"
      >
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#d1823a" }}
          >
            Offers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div className={styles.mibileMessageCobntainer}>
            {/*----------------------------------------- Messanger part container------------------------------- */}
            <div className={styles.MessangerPartWrapper}>
              <div className={styles.MessangerPartContainer}>
                <div className={styles.MessangerHeader}>
                  <span>Listing Price</span>
                  <span>$35,262</span>
                </div>
                <div className={styles.MessangerBody}>
                  <div className={styles.LeftBody}>
                    <div className={styles.message}>
                      <span>Ghada</span>
                      <span> Dummy Message From User Side</span>
                    </div>
                  </div>
                  <div className={styles.RightBody}>
                    <div className={styles.message}>
                      <span>United</span>
                      <span>Dummy Message From My Side</span>
                    </div>
                  </div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                </div>
                <div className={styles.MessangerInput}>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Write Your Offer Here"
                  />
                  <span>
                    <button className={styles.message_btn}>Send Offer</button>
                  </span>
                </div>
              </div>
            </div>
            {/* bottom section for message */}
            <div className={styles.MessangerPartWrapperb}>
              <div className={styles.MessangerPartContainerb}>
                <div className={styles.MessangerBody}>
                  {/* <div className={styles.LeftBody}>
                          <div className={styles.message}>
                            <span>Ghada</span>
                            <span>I am interested to drive this car.</span>
                          </div>
                        </div> */}
                  <div className={styles.RightBody}>
                    <div className={styles.message}>
                      <span>United</span>
                      <span> Test Message or Dummy Message</span>
                    </div>
                  </div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                  <div className={styles.LeftBody}></div>
                  <div className={styles.RightBody}></div>
                </div>
                <div className={styles.MessangerInput}>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Write Your Text Here"
                  />
                  <span>
                    <button className={styles.message_btn}>Message</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OffersMobile;
