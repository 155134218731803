export const dropdownData = [
  {
    buttonTitle: "Active Inventory",
    menuItems: [
      { title: "All Active Today", value: 0 },
      { title: "All Active This Week", value: 0 },
      { title: "All Active This Months", value: 0 },
      { title: "All Active year to Date", value: 0 },
      { title: "All Time Active", value: 0 },
    ],
  },
  {
    buttonTitle: "Sales Reports",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      { title: "All Time Sale", value: 0 },
    ],
  },
  {
    buttonTitle: "Purchase Reports",
    menuItems: [
      { title: "All Purchase Today", value: 0 },
      { title: "All Purchase This Week", value: 0 },
      { title: "All Purchase This Months", value: 0 },
      { title: "All Purchase year to Date", value: 0 },
      { title: "All Time Purchase", value: 0 },
    ],
  },
  {
    buttonTitle: "Revenue Reports",
    menuItems: [
      { title: "All", value: 0 },
      { title: "Paid Off", value: 0 },
      { title: "Opened", value: 0 },
    ],
  },
  {
    buttonTitle: "Titles Status",
    menuItems: [
      { title: "Active Inventory", value: 0 },
      { title: "Sold Inventory", value: 0 },
    ],
  },
  {
    buttonTitle: "Vehicles Inspection Report",
    menuItems: [
      { title: "Valid Inspections", value: 0 },
      { title: "Invalid Inspections", value: 0 },
    ],
  },
  {
    buttonTitle: "All Active Balance",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 28 },
      // { title: "Sales By Make", value: 11 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 200 },
    ],
  },
  {
    buttonTitle: "Collected Taxes And Fees",
    menuItems: [
      { title: "Sales Tax", value: 0 },
      { title: "Inventory Tax", value: 0 },
      { title: "TTL Fees", value: 0 },
      { title: "Documentation Fees", value: 0 },
      // { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 10 },
      // { title: "Sales By Make", value: 12 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 180 },
    ],
  },
  
  {
    buttonTitle: "User Report",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 15 },
      // { title: "Sales By Make", value: 8 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 130 },
    ],
  },
  {
    buttonTitle: "All Inventory",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 25 },
      // { title: "Sales By Make", value: 14 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 90 },
    ],
  },
  {
    buttonTitle: "All Leads Reports",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 20 },
      // { title: "Sales By Make", value: 13 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 110 },
    ],
  },
  {
    buttonTitle: "Advertised Reports",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 22 },
      // { title: "Sales By Make", value: 14 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 170 },
    ],
  },
  {
    buttonTitle: "Add Cost Reports",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 17 },
      // { title: "Sales By Make", value: 12 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 150 },
    ],
  },
  {
    buttonTitle: "Recall Reports",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 23 },
      // { title: "Sales By Make", value: 10 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 120 },
    ],
  },
  {
    buttonTitle: "Customer Reports",
    menuItems: [
      { title: "All Sold Today", value: 0 },
      { title: "All Sold This Week", value: 0 },
      { title: "All Sold This Months", value: 0 },
      { title: "All Sold year to Date", value: 0 },
      // { title: "Sales By Users", value: 19 },
      // { title: "Sales By Make", value: 14 },
      // { title: "Sales Summery By Type (Retail, Finance)", value: 160 },
    ],
  },
  {
    buttonTitle: "Sales By Users",
    menuItems: [
      { title: "", value: 0.00 },
    ],
  },
];
