import React, { useCallback, useEffect, useState } from "react";

import "./CryptoCard.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Image from "../imgData";
import { MdPhoneInTalk } from "react-icons/md";
import { FaRegMessage } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { VscTag } from "react-icons/vsc";
import { FaLocationDot } from "react-icons/fa6";
import { BsSpeedometer2 } from "react-icons/bs";
import { MdInfoOutline } from "react-icons/md";
import { MdWrongLocation } from "react-icons/md";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  saveUser,
  setShowUserCompleteProfileDialogueBox,
} from "../../Redux/slice/userSlice";
// import WebSocketService from "../DealerSection/DealerMessages/WebSocketService";
import WebSocketService from "../dashboard/Messages/WebSocketService";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import { haversineDistanceMiles } from "./calculateDistance";
const imgUrl = process.env.REACT_APP_IMG_URL;

const CryptoCard = ({
  handleShowDialogueBox,
  product,
  ProductId,
  locationAccess,
}) => {
  // //console.log("locationAccess List=> ", locationAccess);
  const navigate = useNavigate();
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [isFav, setIsFav] = useState(false);
  // //console.log("product ", product);
  const location = useLocation();
  const { id, roles } = useSelector((state) => state.user);
  // //console.log("User ID ", id);
  const handleShareLink = () => {
    //console.log("Sharing link with the id");
    setShowSocialMedia(!showSocialMedia);
  };
  const generateShareUrl = (itemId) => {
    return `${window.location.origin}${location.pathname}/${itemId}`;
  };

  const handleLikeTheProduct = async () => {
    if (id === 0) {
      handleShowDialogueBox(true);
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/add_favourite/${id}/${ProductId}`
        );
        //console.log(res);
        setIsFav(true);
      } catch (error) {
        //console.log("Server side error while liking the product");
      }
    }
  };
  // for single product details---------------
  const handleClick = () => {
    navigate(
      `/cardetail/${product.id}_${product.userId}_${product.modelYear}_${product.modelMake}`,
      {
        state: { product },
      }
    );
  };

  const {
    all_images,
    exterior_images,
    misc_images,
    interior_images,
    three_sixty_images,
  } = product && product.multimedia;
  const noImage = Image.image_not_available;
  const getFirstValidImage = (images) => {
    if (images) {
      const imageList = images.split(",").map((img) => img.trim());
      for (let image of imageList) {
        if (image) {
          return `${imgUrl}/${product.userId}/${image.trim()}`;
        }
      }
    }
    return null;
  };
  const imageUrl =
    (all_images && getFirstValidImage(all_images)) ||
    (exterior_images && getFirstValidImage(exterior_images)) ||
    (misc_images && getFirstValidImage(misc_images)) ||
    (interior_images && getFirstValidImage(interior_images)) ||
    (three_sixty_images && getFirstValidImage(three_sixty_images)) ||
    noImage;
  const [showLoginModal, setShowLoginModal] = useState(false);

  const dispatch = useDispatch();

  const handleCustomerLogin = (userType) => {
    setShowLoginModal(false);
    if (userType === "Customer") {
      dispatch(saveUser({ showLoginDialogue: true }));

      navigate("/");
    } else {
      navigate("/dealer/login");
    }
  };

  //Message input
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  //console.log("messages => ", messages);
  const [messageInput, setMessageInput] = useState("");
  const handleMessageInput = (e) => {
    setMessageInput(e.target.value);
  };
  useEffect(() => {
    if (id !== 0) {
      WebSocketService?.connect(onMessageReceived, id);
    }
    return () => WebSocketService?.disconnect();

    // Cleanup on component unmount
  }, [id]);

  const onMessageReceived = (message) => {
    const messageBody = JSON.parse(message.body);
    //console.log("Received message:", messageBody);
    if (messageBody.type === "CHAT") {
      setMessages((prevMessages) => [...prevMessages, messageBody]);
    } else if (messageBody.type === "HISTORY") {
      const historyMessages = JSON.parse(messageBody.body);
      setMessages(historyMessages); // Update state with chat history
    } else if (messageBody.type === "TYPING") {
      setIsTyping(messageBody.content !== "");
    }
  };

  const sendMessage = () => {
    const message = {
      sender: id,
      recipient: product.userId,
      content: messageInput,
      type: "CHAT",
    };

    //   // Add the sender's message to the state immediately

    //   // Send the message to the backend via WebSocket
    WebSocketService.sendMessage(message);

    //   // Clear the input field
    //   setMessageInput("");
    //   // if (roles && roles === "Dealer") {
    //   //   navigate("/dealer/messages", { state: product });
    //   // }
    //   // if (roles && roles === "User") {
    //   //   navigate("/myprofile/message", { state: product });
    //   // }
  };
  // for esttmated cost------------------------------
  const estimatedPrice = product.estimated_cost;

  //show the check availaibilty popout
  const [showAvailibility, setShowAvailibility] = useState(false);
  const [showlocationSend, setShowlocationSend] = useState(false);

  useEffect(() => {
    if (showlocationSend) {
      // toast.error("The dealer has not provided the location of this inventory");
      if (product && !product?.coordinates) {
        toast.error(
          "The dealer has not provided the location of this inventory"
        );
      }
    }
  }, [showlocationSend]);

  //Getting the phone number and email for receiving the directional link

  const [totalDistance, setTotalDistance] = useState(0);
  useEffect(() => {
    if (navigator.geolocation) {
      const { latitude, longitude } = locationAccess && locationAccess;

      const coords1 = {
        latitude: latitude && latitude,
        longitude: longitude && longitude,
      };
      const coords2 = {
        latitude: product?.coordinates && product?.coordinates?.latitude,
        longitude: product?.coordinates && product?.coordinates?.longitude,
      };

      if (latitude && longitude && product?.coordinates) {
        const distance = haversineDistanceMiles(coords1, coords2);
        setTotalDistance(distance.toFixed(1));
        // //console.log(
        //   "distance => ",
        //   distance.toFixed(2),
        //   " latitude => ",
        //   latitude,
        //   " longitude => ",
        //   longitude,
        //   "coordinates => ",
        //   product?.coordinates
        // );
      } else {
        setTotalDistance(0);
      }
    }
  }, [locationAccess]);

  const [countryCode, setCountryCode] = useState("");
  //console.log(countryCode);
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        //console.log("User all info", res.data);

        setCountryCode(res.data.country_calling_code);
      } catch (error) {
        //console.log(error);
      }

      // setIP(res.data.ip)
    };
    getUserCountryCode();
  }, []);
  const [receiveData, setReceiveData] = useState({
    email: "",
    phoneNumber: "",
    listingID: product.id,
    userID: product.userId,
  });
  const handleReceiveDataChange = (e) => {
    const { name, value } = e.target;
    setReceiveData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [error, setError] = useState(null);
  const handleReceiveDLink = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success callback
          const { latitude, longitude } = position.coords;
          handleSendCoordinates(latitude, longitude);
          setError(null);
        },
        (err) => {
          // Error callback
          setError(err.message);
          toast.error(
            "Geolocation access was denied by the user. To get a directional link, please enable geolocation access in your browser settings."
          );
        }
      );
      // ;
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  //Handle the data to send and receive of coordinates
  const handleSendCoordinates = async (latitude, longitude) => {
    //If we have the coordinates of the user
    if (latitude && longitude) {
      //If the user is not logged in
      // if (!id) {
      //   setShowLoginModal(true);
      // } else {
      //If the user is looged in

      const directinalURL = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${product?.coordinates?.latitude},${product?.coordinates?.longitude}
`;
      const dataToSend = {
        directionalURL: directinalURL,
        email: receiveData.email,
        phoneNumber: receiveData.phoneNumber,
        listingID: Number(receiveData.listingID),
        userID: Number(receiveData.userID),
        countryCode: countryCode,
      };
      //console.log("dataToSend => ", dataToSend);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/direction/send`,
          dataToSend
        );
        //console.log("direction response => ", res);
        if (res.status === 200) {
          toast.success("Directional link sent to your Email and Phone number");
        }
      } catch (error) {
        toast.error("Server side error while sending the  link");
      } finally {
        setShowlocationSend(false);
        setReceiveData((prevData) => ({
          ...prevData,
          email: "",
          phoneNumber: "",
        }));
      }
    } else {
      toast(
        "Please enable location access in your browser to get directional link"
      );
    }
  };

  const caSendData = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    userId: product && product?.userId,
    listingId: product && product?.id,
    testDrive: false,
    vehicleHistoryReport: false,
    financeOption: false,
    tradeIn: false,
    other: false,
    acceptance: false,
  };
  const [cAFormData, setCaFormData] = useState(caSendData);
  const handleCAFormInput = (e) => {
    const { name, value, type, checked } = e.target;
    setCaFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // availability/save
  console.log("caFormData => ", cAFormData);
  const handleSendCheckAvail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/availability/save`,
        cAFormData
      );
      console.log("Response from api", response);
      if (response.status === 200) {
        toast.success(response.data);
        setShowAvailibility(false);
        setCaFormData(caSendData);
      }
    } catch (error) {
      console.log("Server side error => ", error);
    }
  };
 
  return (
    <div
      className="card wrapper_card_product"
      style={{ border: "1px solid grey" }}
    >
      {/* Top Items */}
      <div className="row  pb-0 rounded card_container">
        <div className="col-lg-3 card_left product-actives">
          <div className="product-image-box py-2">
            <img
              // src={
              //   product.multimedia.all_images
              //     ? `${imgUrl}/${product.userId}/${
              //         product.multimedia.all_images?.split(",")[0]
              //       }`
              //     : Image.image_not_available
              // }
              src={imageUrl}
              alt="product_car_image"
              className="product_car_image"
            />
            {/* <i className = "fa-regular fa-heart"></i> */}
            <div className="product_share_like">
              <span onClick={handleShareLink}>
                <IoShareSocial />
              </span>
              {id !== 0 ? (
                <span onClick={handleLikeTheProduct}>
                  <FaHeart fill={`${isFav ? "red" : "white"}`} />
                </span>
              ) : null}
            </div>
            {/* Share buttons */}
            {showSocialMedia ? (
              <div className="social_media_container">
                <FacebookShareButton url={generateShareUrl(id)}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={generateShareUrl(id)}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={generateShareUrl(id)}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton url={generateShareUrl(id)}>
                  <FacebookMessengerIcon size={32} round />
                </TelegramShareButton>
              </div>
            ) : null}
          </div>
          <div className="interior_exterior_color_container">
            {" "}
            <span
              style={{
                backgroundColor: product.exteriorColor,
              }}
              title={product.exteriorColor ? product.exteriorColor : ""}
            ></span>
            <span
              style={{
                backgroundColor: product.exterior_color,
                color: product.exterior_color ? "white" : "",
              }}
            >
              Exterior Color
            </span>
            <span
              style={{
                backgroundColor: product.interiorColor,
                marginLeft: "0.3rem",
              }}
              title={product.interiorColor ? product.interiorColor : ""}
            ></span>
            <span
              style={{
                backgroundColor: product.interior_color,
                color: product.interior_color ? "white" : "",
              }}
            >
              Interior Color
            </span>
          </div>
        </div>
        <div className="ps-0 card_center">
          {/*----------------------content deatils Start---------------------*/}
          {/* <div className="row"> */}
          <div className=" center_primary">
            <h4
              style={{
                fontWeight: 800,
                cursor: "pointer",
                marginBottom: "1rem",
                // fontSize : "2rem"
                margin: "1rem 0",
              }}
              onClick={handleClick}
            >
              {product.year_Make_Model} {product.trim}
            </h4>

            <div className="vin_extras_container">
              {/* <h6 style={{ fontSize: "18px" }}> */}
              {/* <span
                  className="theme-color "
                  style={{
                    color: "#D1823A",
                    fontWeight: "bold",
                    margin: "10px 3px",
                  }}
                >
                  Mileage :
                </span> */}
              {/* <span style={{ cursor: "pointer" }} onClick={handleClick}> */}
              {/* {product.mileage_in} */}
              {/* {new Intl.NumberFormat().format(product.mileage_in)} */}
              {/* </span> */}
              {/* </h6> */}
              <h6
                style={{
                  fontSize: "18px",
                  display: "flex",
                  gap: "0.5rem",
                  paddingLeft: "0.4rem",
                  fontWeight: "600",
                  fontSize: "large",
                  // fontSize: isLargeScreen ? "20px" : "larger",
                  // fontSize: "medium",
                }}
              >
                <span> {product?.driveType || 0} </span> &#x2022;
                <span> {product?.engine.split(" ")[1]} </span> &#x2022;
                <span> {product?.engine.split(" ")[0]} </span> &#x2022;
                <span> {product?.engine.split(" ")[3]} </span> &#x2022;
                <span> {product.transmission || "Transmission NA"} </span>{" "}
                {/* &#x2022;
                <span>{product.id}</span> */}
              </h6>
            </div>

            <h6
              style={{
                fontSize: "18px",
                //  margin: "01.3rem 0"
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                fontSize: "large",
                // fontSize: isLargeScreen ? "20px" : "larger",
              }}
            >
              <span
                className="theme-color"
                style={{
                  color: "#D1823A",
                  fontWeight: "bold",
                  marginRight: "0.5rem",
                  marginTop: "-0.3rem",
                  fontSize: "larger",
                  cursor: "pointer",
                }}
                onClick={
                  product?.coordinates ? () => setShowlocationSend(true) : null
                }
              >
                {product?.coordinates ? <FaLocationDot /> : <MdWrongLocation />}
              </span>
              <span
                style={{
                  marginRight: "0.5rem",
                }}
              >
                {product.location || "Seller Location NA"}{" "}
              </span>

              <span>
                {totalDistance
                  ? `( ${totalDistance && totalDistance} mi away )`
                  : ""}
              </span>
            </h6>
            <div className="d-flex seller_section_product_listing">
              <h6
                style={{
                  fontSize: "large",
                  fontWeight: "600",
                  // fontSize: isLargeScreen ? "20px" : "larger",
                }}
              >
                <img
                  src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
                  height={25}
                  width={25}
                  style={{ borderRadius: "50%", marginRight: "0.3rem" }}
                />

                <span
                // className="theme-color"
                // style={{  fontWeight: "bold" }}
                >
                  Seller :{" "}
                </span>
                <span>{product.user_name || "Seller Name NA"}</span>
              </h6>
              {/* <label
                className="form-check-label ms-2"
                htmlFor="flexCheckDefault"
              >
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star " style={{ color: "#D1823A" }}></i>
                <i className="fas fa-star "></i>
              </label> */}
            </div>

            {/* <div className=" d-flex" style={{ marginTop: "1rem" }}>
                <h6
                  style={{
                    fontSize: "15px",
                    marginRight: "1rem",
                    marginTop: "0.2rem",
                  }}
                >
                  <b>Seller Report</b>{" "}
                </h6>
                <p>
                  {firstFourWords || "Seller Description NA"} {" "}
                  {product.seller_description ? (
                    <Link
                      to="#"
                      className="tooltip-bottom"
                      data-tooltips={product.seller_description}
                    // data-tooltips="Welcome to Dac, Sector 62,Noida D-Block"
                    >
                      More
                    </Link>
                  ) : null}
                </p>
              </div> */}
          </div>
          <div className="center_secondary">
            <h5
              style={{
                fontSize: "20px",
                marginBottom: "0rem",
              }}
            >
              {" "}
              {/* <b>Price : </b> */}
              <p
                className="theme-color"
                style={{
                  color: "#D1823A",
                  fontWeight: "700",
                  fontSize: "40px",
                  marginBottom: "0",

                  textAlign: "center",
                }}
              >
                {" "}
                ${new Intl.NumberFormat().format(product.listing_price)}{" "}
              </p>{" "}
            </h5>
            <p
              style={{
                // color: "#D1823A",
                fontWeight: "600",
                fontSize: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>
                Est $
                {new Intl.NumberFormat().format(
                  estimatedPrice ? Math.ceil(estimatedPrice) : 0
                )}{" "}
                mo
              </span>
              <span style={{ fontSize: "smaller", marginLeft: "0.3rem" }}>
                <MdInfoOutline />
              </span>
            </p>
            <p
              style={{
                fontSize: "xx-large",
                textAlign: "center",
                fontWeight: "700",
                paddingLeft: "0.5rem",
              }}
            >
              <span style={{ color: "#D1823A" }}>
                <BsSpeedometer2 />
              </span>
              <span style={{ marginLeft: "0.7rem" }}>
                {new Intl.NumberFormat().format(product.mileage_in)} mi
              </span>
            </p>
            {/* <div className="call_seller_button_container">
              <div className="product-g-btn">
                <span>
                  <MdPhoneInTalk />
                </span>{" "}
                +1<span style={{ fontSize: "16px" }}>(123) 423 7635</span>
              </div>
              <div className="product-S-btn">
                <span style={{ transform: "rotateY(180deg)" }}>
                  <VscTag />
                </span>{" "}
                Send Offers
              </div>
            </div> */}

            <div
              className="contact_message_section"
              style={{ margin: "2rem 0 1.5rem 0" }}
            >
              <button
                style={{
                  // cursor:
                  //   messageInput.trim() === "" ? "not-allowed" : "pointer",
                  // color: messageInput.trim() === "" ? "lightgrey" : "white",
                  padding: "0.5rem 0.9rem",
                  backgroundColor: "#D1823A",
                  color: "white",
                  border: "none",
                  borderRadius: "25px",
                  fontWeight: "600",
                }}
                onClick={() => {
                  // if (!id) {
                  //   setShowLoginModal(true);
                  //   return;
                  // } else {
                  setShowAvailibility(true);
                  // }
                }}
              >
                Check Availability
              </button>
              {/* <p
                style={{
                  fontSize: "18px",
                  // border: "2px solid blue",
                  textAlign: "center",
                  fontWeight: "600",
                  marginBottom: "2px",
                }}
              >
                Check Availibity
              </p> */}
              {/* <div className="input_contact_message_section"> */}
              {/* <img
                  src={Image.messanger}
                  alt="messenger icon"
                  height={30}
                  width={30}
                /> */}
              {/* <input
                  type="text"
                  placeholder="Hi Kumar, Is this still available ?"
                  value={messageInput}
                  onChange={handleMessageInput}
                  // style={{ marginLeft: "31%" }}
                /> */}

              {/* </div> */}
            </div>
          </div>
          {/* </div> */}
        </div>
        <Modal
          size="xl"
          show={showLoginModal}
          onHide={() => setShowLoginModal(false)}
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{ textAlign: "center", marginTop: "1rem" }}>
              Log in to unlock this feature! You need to be logged in to check
              availability of this inventory.
            </h4>
            <h5 style={{ margin: "2rem 0", textAlign: "center" }}>
              {" "}
              Click below to log in and continue.
            </h5>
            <p
              style={{ display: "flex", justifyContent: "center", gap: "3rem" }}
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "#d1823a",
                  color: "white",
                  fontSize: "larger",
                  fontWeight: "600",
                  padding: "0.4rem 0.7rem",
                  borderRadius: "10px",
                }}
                onClick={() => handleCustomerLogin("Customer")}
              >
                Customer Login
              </button>
              <button
                style={{
                  border: "none",
                  backgroundColor: "#d1823a",
                  color: "white",
                  fontSize: "larger",
                  fontWeight: "600",
                  padding: "0.4rem 0.7rem",
                  borderRadius: "10px",
                }}
                onClick={() => handleCustomerLogin("Dealer")}
              >
                Dealer Login
              </button>
            </p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
      <Modal
        show={showAvailibility}
        onHide={() => setShowAvailibility(false)}
        size="lg"
      >
        <Modal.Body>
          <div style={{ fontSize: "x-large", backgroundColor: "lightgrey" }}>
            <p
              style={{
                textAlign: "center",
                color: "#D1823A",
                fontWeight: "700",
              }}
            >
              <span>Check Availability</span>
            </p>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1.5rem",
                fontWeight: "600",
                marginBottom: "1.5em",
              }}
            >
              <span>
                <span style={{ borderBottom: "2px solid black" }}>
                  Call Now
                </span>{" "}
              </span>
              <span
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "20px",
                  padding: "0.1rem 2.2rem",
                }}
              >
                (469) 469-4699
              </span>
            </p>
            <p style={{ borderBottom: "1px solid grey", position: "relative" }}>
              <span
                style={{
                  // border: "1px solid black",
                  position: "absolute",
                  right: "50%",
                  top: "-1.8rem",
                  padding: "0.4rem",
                  color: "#D1823A",

                  fontWeight: "600",
                  backgroundColor: "lightgrey",
                }}
              >
                Or
              </span>
            </p>
            <p
              style={{
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <span style={{ marginRight: "1.3rem" }}>My name is </span>
              <input
                type="text"
                placeholder="First Name"
                style={{
                  width: "25%",
                  border: "none",
                  marginRight: "1.3rem",
                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
                name="firstName"
                value={cAFormData.firstName}
                onChange={handleCAFormInput}
              />
              <input
                type="text"
                placeholder="Last Name"
                style={{
                  width: "25%",
                  border: "none",
                  marginRight: "1.3rem",
                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
                name="lastName"
                value={cAFormData.lastName}
                onChange={handleCAFormInput}
              />
              interested
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              in {product.year_Make_Model} {product.trim}
            </p>
            <p
              style={{
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              contact me at my
            </p>
            <p
              style={{
                fontSize: "large",
                marginTop: "1.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <input
                type="text"
                placeholder="(XXX) XXX-XXXX"
                style={{
                  width: "25%",
                  border: "none",
                  marginRight: "1.3rem",
                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
                name="mobileNumber"
                value={cAFormData.mobileNumber}
                onChange={handleCAFormInput}
              />
              <span style={{ marginRight: "1.3rem" }}>&</span>
              <input
                type="text"
                placeholder="Email"
                style={{
                  width: "45%",
                  border: "none",

                  borderRadius: "10px",
                  padding: "0.4rem",
                }}
                name="email"
                value={cAFormData.email}
                onChange={handleCAFormInput}
              />
            </p>
            <div
              style={{
                marginTop: "1rem",

                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                marginBottom: "0.4rem",
              }}
            >
              <div
                style={{
                  width: "95%",
                  height: "80%",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <p
                  style={{
                    fontSize: "medium",
                    marginTop: "1.5rem",
                    fontWeight: "600",
                  }}
                >
                  <span>Need more info about</span>
                </p>
                <div
                  style={{
                    display: "flex",
                    //  border: "1px solid red"
                    fontSize: "large",
                    fontWeight: "600",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "0.5",
                    }}
                  >
                    <label htmlFor="testDrive">
                      <input
                        type="checkbox"
                        id="testDrive"
                        name="testDrive"
                        value={cAFormData.testDrive}
                        checked={cAFormData.testDrive}
                        onChange={handleCAFormInput}
                        style={{ marginRight: "1rem" }}
                      />
                      Test Drive
                    </label>
                    <label htmlFor="vehicleHistoryReport">
                      <input
                        type="checkbox"
                        id="vehicleHistoryReport"
                        name="vehicleHistoryReport"
                        value={cAFormData.vehicleHistoryReport}
                        onChange={handleCAFormInput}
                        style={{ marginRight: "1rem" }}
                      />
                      Vehicle history report
                    </label>
                    <label htmlFor="financeOption">
                      <input
                        type="checkbox"
                        id="financeOption"
                        name="financeOption"
                        value={cAFormData.financeOption}
                        checked={cAFormData.financeOption}
                        onChange={handleCAFormInput}
                        style={{ marginRight: "1rem" }}
                      />
                      Finance Options
                    </label>
                    <label htmlFor="tradeIn">
                      <input
                        type="checkbox"
                        id="tradeIn"
                        name="tradeIn"
                        value={cAFormData.tradeIn}
                        checked={cAFormData.tradeIn}
                        onChange={handleCAFormInput}
                        style={{ marginRight: "1rem" }}
                      />
                      I have a trade in
                    </label>
                    <label htmlFor="other">
                      <input
                        type="checkbox"
                        id="other"
                        style={{ marginRight: "1rem" }}
                        name="other"
                        value={cAFormData.other}
                        checked={cAFormData.other}
                        onChange={handleCAFormInput}
                      />
                      Other
                    </label>
                  </p>
                  <p
                    style={{
                      flex: "0.5",
                      // border: "1px solid blue",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      paddingRight: "2rem",
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        backgroundColor: "#D1823A",
                        border: "none",
                        padding: "1rem",
                        borderRadius: "10px",
                      }}
                      onClick={handleSendCheckAvail}
                    >
                      Send
                    </button>
                  </p>
                </div>
                <label
                  htmlFor="acceptance"
                  style={{ fontSize: "medium", fontWeight: "600" }}
                >
                  <input
                    type="checkbox"
                    id="acceptance"
                    style={{ marginRight: "1rem" }}
                    name="acceptance"
                    value={cAFormData.acceptance}
                    checked={cAFormData.acceptance}
                    onChange={handleCAFormInput}
                  />
                  By clicking send , I accept to contacted by
                  dealersautocenter.com in the future.
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showlocationSend && product && product?.coordinates}
        onHide={() => setShowlocationSend(false)}
        size="lg"
      >
        <Modal.Body>
          <div>
            <p
              style={{
                fontWeight: "600",
                fontSize: "large",
                textAlign: "center",
                margin: "1rem 0",
              }}
            >
              To receive the directional link, please provide your email address
              and phone number.
            </p>
            <p
              style={{ display: "flex", justifyContent: "center", gap: "10%" }}
            >
              <input
                type="text"
                placeholder="Phone Number"
                style={{ width: "30%", padding: "0.3rem" }}
                value={receiveData.phoneNumber}
                name="phoneNumber"
                onChange={handleReceiveDataChange}
              />
              <input
                type="text"
                placeholder="Email"
                style={{ width: "30%", padding: "0.3rem" }}
                value={receiveData.email}
                name="email"
                onChange={handleReceiveDataChange}
              />
            </p>
            <p style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <button
                onClick={handleReceiveDLink}
                style={{
                  border: "none",
                  backgroundColor:
                    receiveData.email.length > 6 &&
                    receiveData.phoneNumber.length > 9 &&
                    receiveData.email.includes("@") &&
                    receiveData.email.includes(".")
                      ? "rgb(209, 130, 58)"
                      : "lightgrey",
                  color: "white",
                  padding: "0.4rem 0.9rem",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
                disabled={
                  receiveData.email.length > 0 &&
                  receiveData.phoneNumber.length > 0
                    ? false
                    : true
                }
              >
                Receive
              </button>
            </p>
            <p
              style={{
                fontSize: "medium",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "smaller", marginLeft: "0.3rem" }}>
                <MdInfoOutline />
              </span>{" "}
              <span>Make sure to enable location access in your browser.</span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CryptoCard;
