import { IoIosArrowDown, IoIosInformationCircleOutline } from "react-icons/io";
import styles from "./Quote.module.css";
import Styles from "./Labels.module.css";
import { useEffect, useRef, useState } from "react";
import QuoteData from "./QuoteData.js";
import { useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import Labels from "./Labels.jsx";
import { BiReset, BiSave } from "react-icons/bi";
import { HiDotsVertical } from "react-icons/hi";
import { IoCalculator } from "react-icons/io5";
// for data labels-------------------------------
import retailData from "./retailData";
import wholeSaleData from "./wholeSaleData";
import outOfSaleData from "./outOfSaleData";
import { useDispatch } from "react-redux";
import usaStates from "../../DealerInventory/DealerAddInventory/usaStates.json";
import {
  addSellPriceLabelsFast,
  setDynamicAPILabelsFast,
  updateTaxValues,
  updateSaleType,
} from "../../../../Redux/slice/fastSlice.js";
import { DefaultDynamicLabels } from "../../DealerSell/DefaultDynamicLabels.js";
import QuoteCalculator from "./QuoteCalculator.jsx";

const apiUrl = process.env.REACT_APP_API_URL;
const Quote = () => {
  const { dealerId } = useSelector((state) => state.user);
  const Userdata = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("+1");
  const [isAssetOpened, setIsAssetOpened] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState(null);
  const allInputs = useSelector((state) => state.fast.SellPriceInputs || []);
  const retailFromRedux = useSelector((state) => state.fast);
  const tottalSumInputs = useSelector((state) => state.fast.totalLabelSum);
  const salesTaxRedux = useSelector((state) => state.fast.salesTax);
  const minventoryTaxRedux = useSelector((state) => state.fast.minventoryTax);
  const saleType = useSelector((state) => state.fast.saleType);
  console.log("salesTaxRedux",minventoryTaxRedux,salesTaxRedux);
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const [dropDownOptionSelect, setdropDownOptionSelect] =
    useState("Retail/Cash");
  const [quoteData, setQuoteData] = useState(QuoteData); // Assuming QuoteData is your initial state
  // console.log("retailData",retailData);
  const [sumFeesTaxValue, setSumFeesTaxValue] = useState(0);
  //   form data -------------------------------------------------------************************************---------------------------------------------
  const [formData, setFormData] = useState({
    dealerId: dealerId,
    countryCode: countryCode,
    checkQuote: {
      cash: false,
      card: false,
      wire: false,
      cashier: false,
      cheque: false,
      finance: false,
      trade: false,
      others: false,
    },
    listingId: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    salesPrice: 0,
    tradeIn: 0,
    ownedTradeIn: 0,
    cashDifference: 0,
    fastQuoteLabels: [],
    // calculation-------------------------------
    saleType: saleType,
    salesTaxPerc: 6.25,
    inventoryTaxes: 0.00148,
    salesTaxes: 0,
    inventoryTaxValue: 0,
    totalTaxes: 0,
    totalFees: 0,
    total: 0,
    totalFeesTax:0,
  });
  const [submitCheckBox, setSubmitCheckBox] = useState({
    preview: false, // false means not selected
    final: true, // false means not selected
  });

  const handleChangeCheck = (e) => {
    const { name, value } = e.target;

    // Update the state with the clicked radio button, set it to true
    setSubmitCheckBox((prev) => ({
      ...prev,
      [name]: value === "true", // Set to true if "true", otherwise false
      ...(name === "preview" && { final: false }), // Ensure 'final' is false if 'preview' is true
      ...(name === "final" && { preview: false }), // Ensure 'preview' is false if 'final' is true
    }));
  };
  console.log("subnitCheckBox", submitCheckBox);
  // ---------------calculation----------------------------------------------
  const [saletaxValue, setSaleTaxValue] = useState(0);
  const [inventorytaxValue, setInventoryTaxValue] = useState(0);
  const [totalTaxesValue, setTotalTaxesValue] = useState(0);
  const [sumTotalFees, setSumTotalFees] = useState(0);
  const [totalAllvalue, setTotalAllValue] = useState(0);
  useEffect(() => {
    const sumFeesTaxvalueb = sumTotalFees + totalTaxesValue;
    setSumFeesTaxValue(sumFeesTaxvalueb.toFixed(2));
    setFormData((prevData) => ({
      ...prevData,
      totalFeesTax: sumFeesTaxvalueb.toFixed(2),
    }));
  }, [sumTotalFees, totalTaxesValue]);
  console.log("totalTaxesValue", totalTaxesValue);
  useEffect(() => {
    // Convert salesPrice to a number before calculation
    const price = parseFloat(formData.salesPrice - formData.tradeIn);
    const cashDiffer =
      price + parseFloat(formData.ownedTradeIn ? formData.ownedTradeIn : 0);
    console.log("cashDiffer", cashDiffer);
    if (!isNaN(price) && price > 0) {
      // If price is valid, calculate the sales tax
      const calculatedTax = (price * formData.salesTaxPerc) / 100;
      const calculatedInTax = price * formData.inventoryTaxes;
      const calculatedTotalTax = calculatedTax + calculatedInTax;
      setSaleTaxValue(calculatedTax.toFixed(2));
      setInventoryTaxValue(calculatedInTax);
      setTotalTaxesValue(calculatedTotalTax);
      setFormData((prevData) => ({
        ...prevData,
        salesTaxes: parseFloat(calculatedTax.toFixed(2)),
        inventoryTaxValue: parseFloat(calculatedInTax.toFixed(2)),
        totalTaxes: parseFloat(calculatedTotalTax.toFixed(2)),
        cashDifference: parseFloat(
          `${formData.tradeIn === 0 ? 0 : cashDiffer.toFixed(2)}`
        ),
      }));
    } else {
      setSaleTaxValue(0);
      setInventoryTaxValue(0);
    }
  }, [
    formData.salesPrice,
    formData.tradeIn,
    formData.ownedTradeIn,
    formData.totalFees,
    formData.totalTaxes,
    formData.salesTaxPerc,
    formData.inventoryTaxes,
  ]);

  // --------------------------------for sum of all form data for total fees--------------------------
  // State to store the sum of numeric values
  useEffect(() => {
    console.log("dsgfdhsum", tottalSumInputs);
    setSumTotalFees(tottalSumInputs); // Update the sum state
    const totalSumAndTotalTax = tottalSumInputs;

    const totalFinal = parseFloat(
      totalSumAndTotalTax +
        totalTaxesValue +
        (formData.cashDifference === 0
          ? formData.salesPrice
          : formData.cashDifference)
    );
    console.log("dsgfdh", totalFinal);
    setTotalAllValue(totalFinal ? totalFinal.toFixed(2) : 0);
    setFormData((prevData) => ({
      ...prevData,
      totalFees: totalSumAndTotalTax,
      total: Number(totalFinal ? totalFinal.toFixed(2) : 0),
    }));
  }, [
    tottalSumInputs,
    formData.totalFees,
    formData.totalTaxes,
    formData.salesPrice,
    formData.cashDifference,
    totalTaxesValue,
  ]); // Recalculate sum whenever formData changes
  // ------------------------------------------------------------------------
  // for first time get labels from api-------------------------------------
  const fetchDynamicLabelsFirst = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/fastQuote/get/labels/${Userdata.id}/Retail`
      );
      console.log("LablesData18554765", res.data);
      const mappedResponse =
        res &&
        res.data.fastQuoteLabel &&
        res.data.fastQuoteLabel
          .filter(
            (item) =>
              item.itemName !== "Sales Tax" && item.itemName !== "Inventory Tax"
          )
          .map((item, index) => ({
            id: item.id,
            label: item.itemName,
            InputValue: Number(item.itemValue),
          }));
      // for tex and inventory tax---------------------------
      const allItems = res && res.data.fastQuoteLabel;
      // Extract and get the InputValues for Sales Tax and Inventory Tax separately
      const salesTaxItem = allItems.find(
        (item) => item.itemName === "Sales Tax"
      );
      const inventoryTaxItem = allItems.find(
        (item) => item.itemName === "Inventory Tax"
      );

      // Get the InputValues for both (if they exist)
      const salesTaxInputValue = salesTaxItem
        ? Number(salesTaxItem.itemValue)
        : null;
      const inventoryTaxInputValue = inventoryTaxItem
        ? Number(inventoryTaxItem.itemValue)
        : null;
       console.log("mappedResponse => ", mappedResponse);
      if (res.status === 200) {
        dispatch(setDynamicAPILabelsFast(mappedResponse));
        dispatch(
          updateTaxValues({ 
            salesTaxes: salesTaxInputValue,
            mInventoryTax: inventoryTaxInputValue,
          }));
      } else {
        // dispatch(setDynamicAPILabels(retailData));
      }
      // dispatch(setDynamicAPILabelsFast(mappedResponse));
    } catch (error) {
      console.log("Error while fetching labels => ", error);
    }
  };
  useEffect(() => {
    fetchDynamicLabelsFirst();
    }, []);
    useEffect(() => {
      setFormData((prevState) => ({
        ...prevState,
        salesTaxPerc: Number(salesTaxRedux),
        inventoryTaxes: Number(minventoryTaxRedux),
        }));
    }, [salesTaxRedux, minventoryTaxRedux]);
  //  labels api ---------------------------------------------------------------------
  const fetchDynamicLabels = async (saleTy) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/fastQuote/get/labels/${Userdata.id}/${saleTy}`
      );
      console.log("LablesData185546757f", res.data);
      const mappedResponse =
        res &&
        res.data.fastQuoteLabel &&
        res.data.fastQuoteLabel
          .filter(
            (item) =>
              item.itemName !== "Sales Tax" && item.itemName !== "Inventory Tax"
          )
          .map((item, index) => ({
            id: item.id,
            label: item.itemName,
            InputValue: Number(item.itemValue),
          }));
      // for tex and inventory tax---------------------------
      const allItems = res && res.data.fastQuoteLabel;
      // Extract and get the InputValues for Sales Tax and Inventory Tax separately
      const salesTaxItem = allItems.find(
        (item) => item.itemName === "Sales Tax"
      );
      const inventoryTaxItem = allItems.find(
        (item) => item.itemName === "Inventory Tax"
      );

      // Get the InputValues for both (if they exist)
      const salesTaxInputValue = salesTaxItem
        ? Number(salesTaxItem.itemValue)
        : null;
      const inventoryTaxInputValue = inventoryTaxItem
        ? Number(inventoryTaxItem.itemValue)
        : null;
      console.log("mappedResponse =>43645 ", inventoryTaxInputValue,salesTaxInputValue,salesTaxRedux,minventoryTaxRedux);
      if (res.status === 200) {
        dispatch(setDynamicAPILabelsFast(mappedResponse));
        dispatch(
          updateTaxValues({ 
            salesTax: salesTaxInputValue,
            minventoryTax: inventoryTaxInputValue,
          }));
      } else {
        // dispatch(setDynamicAPILabels(retailData));
      }
      // dispatch(setDynamicAPILabelsFast(mappedResponse));
    } catch (error) {
      console.log("Error while fetching labels => ", error);
    }
  };
  
  //  for set the data to fomrdata------------------------------
  useEffect(() => {
    if (allInputs) {
      setFormData((prevState) => ({
        ...prevState,
        fastQuoteLabels:
          allInputs &&
          allInputs?.map((item) => ({
            itemName: item.label,
            itemValue: Number(item.InputValue),
          })),
      }));
    }
  }, [allInputs]);
  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Find the selected asset based on listingId
    if (name === "listingId") {
      const selectedAsset = assets.find(
        (asset) => asset.listingId === Number(value)
      );
      const listingPrice = selectedAsset ? selectedAsset.listingPrice : null;

      setFormData((prevData) => ({
        ...prevData,
        [name]: Number(value), // Update listingId
        salesPrice: Number(listingPrice), // Set listingPrice separately
      }));
    } else {
      const newValue = name === "listingId" ? Number(value) : value;
      setFormData({ ...formData, [name]: newValue });
    }
  };

  const handleChangeSaleType = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("value24355", value);
    switch (value) {
      case "Retail/Cash":
        fetchDynamicLabels("Retail");
        dispatch(updateSaleType({ saleType: "Retail" }))
        break;
      case "Whole Sale":
        fetchDynamicLabels("wholeSale");
        dispatch(updateSaleType({ saleType: "wholeSale" }))
        break;
      case "Out of state":
        fetchDynamicLabels("outOfSale");
        dispatch(updateSaleType({ saleType: "outOfSale" }))
        break;
      default:
        fetchDynamicLabels("Retail");
        dispatch(updateSaleType({ saleType: "Retail" }))
    }

    setFormData({ ...formData, [name]: value });
  };
  // -------------------------------********************-----Important handle Input Change------------------------------------------------
  // Event handler for input changes
  const formatPhoneNumber = (number) => {
    // Remove non-digit characters
    const digits = number.replace(/\D/g, "");

    // Format the digits into the US phone number format (XXX) XXX-XXXX
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
        6,
        10
      )}`;
    }
  };

  const handleInputChange = (key, event, isPersonalDetail) => {
    const newValue = event.target.value;

    // Handle phone number formatting
    if (key === "phoneNumber") {
      const formattedPhoneNumber = formatPhoneNumber(newValue);
      setFormData((prevData) => ({
        ...prevData,
        [key]: newValue.replace(/\D/g, ""),
      }));
      event.target.value = formattedPhoneNumber;
    }
    // Handle personal details (non-numeric fields)
    else if (
      isPersonalDetail ||
      key === "firstName" ||
      key === "lastName" ||
      key === "email"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [key]: newValue,
      }));
    }
    // Handle numeric fields (including decimals)
    else {
      const regex = /^-?\d*\.?\d*$/;

      if (newValue === "" || regex.test(newValue)) {
        // First update with string value to allow typing
        setFormData((prevData) => ({
          ...prevData,
          [key]: newValue,
        }));

        // Then update with number value after a small delay
        if (newValue !== "") {
          setTimeout(() => {
            setFormData((prevData) => ({
              ...prevData,
              [key]: Number(prevData[key]),
            }));
          }, 1000);
        }
      }
    }
  };

  //  for check box----------------------------------------------------
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      checkQuote: {
        ...prevState.checkQuote,
        [name]: checked, // Dynamically update the key based on checkbox name
      },
    }));
  };

  // ------------------------------------------------end of input changes functionality---------------------------------------------
  const [isTradeInChecked, setIsTradeInChecked] = useState(false);
  const [assets, setAssets] = useState([]);
  const saleTypeOptions = [
    "Retail/Cash",
    "Finance/BHPH",
    "Out of State",
    "Whole Sale",
    "Rent & Lease",
  ];
  // for at the time check for trade-in----------------------------
  const handelCheckBox = () => {
    console.log("handelCheckBox");
    setIsTradeInChecked(!isTradeInChecked);
    console.log("isTradeInChecked", isTradeInChecked);
  };
  // ---------------------------------------------------------
  // for index defiend for numbering---------------------
  const currentIndex = isTradeInChecked
    ? allInputs.length + 5
    : allInputs.length + 2;
  console.log("currentIndex", currentIndex);
  // ----------------------------------
  // useEffect(() => {
  //   if (isTradeInChecked === false) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       tradeIn: 0,
  //       ownedTradeIn: 0,
  //       cashDifference: 0,
  //     }));
  //   }
  // }, [isTradeInChecked]);
  // ------------------for check save this to backend------------------
  const handleSaveCheckBox = async () => {
    // Assuming formData.checkQuote is the object you want to send
    const checkQuoteValue = formData.checkQuote;

    console.log("Call Check Box...", checkQuoteValue);

    // Define the API endpoint
    const apiUrlCheck = `${apiUrl}/fastQuote/save/payment_options`;
    const payload = {
      userId: dealerId, // Add the userId
      ...checkQuoteValue, // Spread the existing checkQuoteValue object
    };
    try {
      // Make the POST request, sending only the checkQuote object
      const response = await fetch(apiUrlCheck, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload), // Send only the object itself
      });

      // Check if the request was successful
      if (response.ok) {
        toast.success("Check-Box Save Successfully...");
      } else {
        console.error("API Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while calling API:", error);
    }
  };
  //  for get check-box status for show on check box-------------------------------------------
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fastQuote/get_options/${dealerId}`
        );
        console.log("response =>checkbox ", response.data.data);
        setCheckBoxData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAssets();
  }, []);
  console.log("formdataCheck12345", checkBoxData);
  //  for show the check box on ui------------------------------------------------
  useEffect(() => {
    if (checkBoxData) {
      setFormData((prevState) => ({
        ...prevState,
        checkQuote: {
          ...prevState.checkQuote,
          card: checkBoxData?.card || false,
          cash: checkBoxData?.cash || false,
          cashier: checkBoxData?.cashier || false,
          cheque: checkBoxData?.cheque || false,
          finance: checkBoxData?.finance || false,
          others: checkBoxData?.others || false,
          trade: checkBoxData?.trade || false,
          wire: checkBoxData?.wire || false,
        },
      }));
    }
  }, [checkBoxData]);
  // -----------------------------------------------------------------
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/listing/get_assets/${dealerId}`
        );
        console.log("response => ", response);
        setAssets(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAssets();
  }, []);
  // for send the data for create pdf for fast quote---------------------------------------
  // for show the pdf______________
  const [quoteForm, setQuoteForm] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState(0);

  useEffect(() => {
    console.log("messageStatus2345", messageStatus);
  }, [messageStatus]);
  // _________________________
  //  for send the email--------------------------------------------------------------------
  const sendRequestEmail = () => {
    const apiUrlEmail = `${apiUrl}/fastQuote/send_Quote/${messageStatus}`;

    // Send GET request using Axios
    axios
      .get(apiUrlEmail)
      .then((response) => {
        console.log("Response:", response.data); // Handle the response here
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors
      });
  };
  const sendRequestEmailb = (messageId) => {
    const apiUrlEmail = `${apiUrl}/fastQuote/send_Quote/${messageId}`;

    // Send GET request using Axios
    axios
      .get(apiUrlEmail)
      .then((response) => {
        console.log("Response:", response.data); // Handle the response here
        // toast.success(response.data.message);
        toast.success("Envelope Sent on Email / Phone Number");
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors
      });
  };
  // ---------------------
  useEffect(() => {
    console.log("submitCheckBox.final", submitCheckBox.final);
  }, [submitCheckBox]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleGenerate = async (e) => {
    console.log("handleGenerate");
    e.preventDefault();
    if (submitCheckBox.final) {
    if (formData.listingId === 0) {
      toast.error("Please Select Assets");
      return;
    }
    if (
      !(formData.firstName || formData.lastName) ||
      !(formData.phoneNumber || formData.email)
    ) {
      let missingFields = [];

      // Check if first name or last name is missing
      if (!(formData.firstName || formData.lastName)) {
        missingFields.push("First Name or Last Name");
      }

      // Check if phone number or email is missing
      if (!(formData.phoneNumber || formData.email)) {
        missingFields.push("Phone Number or Email");
      }

      // Construct and show the error message
      const errorMessage = `The following fields are required: ${missingFields.join(
        ", "
      )}`;
      toast.error(errorMessage);
      return;
    }
  } else {
    // if (formData.listingId === 0) {
    //   toast.error("Please Select Assets");
    //   return;
    // }
  }
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/fastQuote/get_quote`,
        formData
      );
      setQuoteForm([response.data.file]);
      if (response.status === 200) {
        setMessageStatus(response.data.message);
      }
      if (submitCheckBox.final) {
        console.log("submitCheckBoxfinal", submitCheckBox.final);
        await sendRequestEmailb(response.data.message);
      } else {
        console.log("preview.............");
      }
      await delay(4000);
      toast.success("Quote Successfully Save...");
      // Show the modal
      setShowPdf(true);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("There was an error!", error);
      toast.error(error);
      // setError("Detail already exist");
    } finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };

  // for print the pdf---------------------------------------------------------------
  const pdfShowUrl = () => {
    return quoteForm.map((pdf, i) => {
      const pdfUrl = `${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`;
      return pdfUrl; // Returning the URL for each PDF
    });
  };

  const printThePdf = () => {
    const pdfUrls = pdfShowUrl(); // Call pdfShowUrl() to get an array of URLs

    // // Let's assume you want to print the first PDF in the list
    const pdfToPrint = pdfUrls[0];

    // Create an iframe element to load the PDF
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";
    iframe.src = pdfUrls; // Set the src to the selected PDF URL0

    // Append the iframe to the body
    document.body.appendChild(iframe);

    // Wait for the PDF to load, then trigger the print dialog
    iframe.onload = () => {
      iframe.contentWindow.print(); // Print the PDF
    };
  };
  //---------------------------------------------------------------------------------------
  console.log("Assets1234", assets);
  console.log("formDat123", formData.totalFees);
  console.log("quoteData", quoteData);
  const [selectedAsset, setSelectedAsset] = useState(null);

  // This function will be called when an option is selected from the dropdown
  const handleSelectedAsset = (asset) => {
    console.log("Selected asset => ", asset);

    // Update form data with listingId and listingPrice of the selected asset
    setFormData((prevData) => ({
      ...prevData,
      listingId: asset?.value, // listingId
      salesPrice: Number(asset?.listingPrice), // listingPrice
    }));

    // Set the selected asset (full object)
    setSelectedAsset(asset);
  };

  console.log("Selected Asset: ", selectedAsset);

  // Format the assets for the dropdown options
  const formattedAssets = assets.map((asset) => ({
    label: asset.assetName, // Display asset name in the dropdown
    value: asset.listingId, // Use listingId as the unique value
    listingPrice: asset.listingPrice, // Attach listingPrice to each option
  }));
  // ----------------------------------------------------------------
  const [showLabelPopout, setShowLabelPopout] = useState(false);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const searchInputRef = useRef(null);
  const [selectedState, setSelectedState] = useState("TX - Texas");

  useEffect(() => {
    if (search.length > 0) {
      // Filter states based on the search input
      setFilteredStates(
        usaStates.filter((state) =>
          state.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredStates(usaStates);
    }
  }, [search, usaStates, isOpen]);

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  // Handle state selection
  const handleSelectState = (state) => {
    setSelectedState(state);
  };
  const handleSelect = (eventKey) => {
    setSearch(""); // Clear search input when an item is selected
    setIsOpen(false); // Close the dropdown
  };
  //  for country code------------------------
  //  for country dialing code-------------------------
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("Error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, [countryCode]);
  console.log("fomrdata213", formData);
  // --------------------------------------------------------------------------------
  //  for taxes editable------------------------------------
  const handleInputChangeTax = (key, event) => {
    let value = event.target.value;

    // Remove leading zeros (if any)
    if (value.startsWith("0") && value.length > 1) {
      value = value.replace(/^0+/, ""); // This removes all leading zeros
    }

    // Update form data with the new value
    setFormData((prevData) => ({
      ...prevData,
      [key]: Number(value),
    }));
  };
  const submitTrddeIn = () => {
    setIsTradeInChecked(false);
  };
  // for calculator-------------------------------------
  // ------------------------------------------------------------------
  const [showCalculatorDropdownAllowance, setShowCalculatorDropdownAllowance] =
    useState(false);
  const handleClickAllowance = () => {
    setShowCalculatorDropdownAllowance(!showCalculatorDropdownAllowance); // Toggle the visibility of the dropdown
  };
  const [showCalculatorDropdownPayOff, setShowCalculatorDropdownPayOff] =
    useState(false);
  const handleClickPayOff = () => {
    setShowCalculatorDropdownPayOff(!showCalculatorDropdownPayOff); // Toggle the visibility of the dropdown
  };
  const [showCalculatorDropdownTradeIn, setShowCalculatorDropdownTradeIn] =
    useState(false);
  const handleClickTradeIn = () => {
    setShowCalculatorDropdownTradeIn(!showCalculatorDropdownTradeIn); // Toggle the visibility of the dropdown
  };
  const [showCalculatorDropdownSalePrice, setShowCalculatorDropdownSalePrice] =
    useState(false);
  const handleClickSalePrice = () => {
    setShowCalculatorDropdownSalePrice(!showCalculatorDropdownSalePrice); // Toggle the visibility of the dropdown
  };
  const [showCalculatorDropdownSaleTax, setShowCalculatorDropdownSaleTax] =
    useState(false);
  const handleClickSaleTax = () => {
    setShowCalculatorDropdownSaleTax(!showCalculatorDropdownSaleTax); // Toggle the visibility of the dropdown
  };
const [showCalculatorDropdownInvTax, setShowCalculatorDropdownInvTax] =
  useState(false);
const handleClickInvTax = () => {
  setShowCalculatorDropdownInvTax(!showCalculatorDropdownInvTax); // Toggle the visibility of the dropdown
};
  function calculatorHead(header) {
    console.log("header4567757", header);
    switch (header) {
      case "Trade-In allowance*":
        return handleClickAllowance();
      case "Trade-In Payoff":
        return handleClickPayOff();
      case "Net after Trade-In":
        return handleClickTradeIn();
      case "Sales Price":
        return handleClickSalePrice();
      case "salesTaxPerc":
        return handleClickSaleTax();
      case "Inventory Tax":
        return handleClickInvTax();
        break;
    }
  }
  // ------------------------------------------------------------------
  // ---------------------for save the lables for fast quote-------------------
  const priceFeesLabels = allInputs.map(input => ({
    // userId: input.id,
    itemName: input.label,
    itemValue: input.InputValue
  }));
const additionalItems = [
  { itemName: "Sales Tax", itemValue: parseFloat(salesTaxRedux)},
  { itemName: "Inventory Tax", itemValue: parseFloat(minventoryTaxRedux)},
];
  const updatedPriceFeesLabels = priceFeesLabels.concat(additionalItems);
  const objForLabels = {
    saleType:saleType,
    userId:dealerId,
    fastQuoteLabel:updatedPriceFeesLabels,
  };
  const handleSaveLables = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/fastQuote/save/labels`, objForLabels, {
      });
      console.log('Response:5656565', response.data);
      const data = await response.data;
     if (response.status === 200) {
      setShowLabelPopout(false);
    }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }
  // -----------------------------------------------------------------------------------------------
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.assetSelectContainer1}>
          {/* <span className={styles.selectItem}>Select Asset</span> */}
          <div className={styles.assetSelector}>
            {/* <div className={styles.assetListContainer}> */}
            <Select
              options={formattedAssets}
              className={styles.assetSelector_field}
              value={selectedAsset} // Pass the whole selected asset object
              onChange={handleSelectedAsset} // Handle selection
              placeholder="Select Assets"
              getOptionLabel={(e) => e.label} // Customize the label shown in the dropdown
              getOptionValue={(e) => e.value} // Use listingId as the value
              isSearchable
              noOptionsMessage={() => "No assets found"} // Custom no options message
              styles={{
                textAlign: "left", // Align text inside the input box
                control: (base) => ({
                  ...base,
                  textAlign: "left", // Align text in control (the box itself)
                  backgroundColor: "#d1823a",
                  color: "white",
                }),
                singleValue: (base) => ({
                  ...base,
                  textAlign: "left", // Align text for selected value
                  color: "white",
                }),
                placeholder: (base) => ({
                  ...base,
                  textAlign: "left", // Align placeholder text to the left
                  color: "white",
                }),
                menu: (base) => ({
                  ...base,
                  textAlign: "left", // Align text in the dropdown menu
                }),
                option: (base) => ({
                  ...base,
                  textAlign: "left", // Align text inside each dropdown option
                  fontSize: "12px",
                  // backgroundColor:"#d1823a",
                  // color:"white"
                }),
              }}
            />
            {/* </div> */}
          </div>
          <div className="col-md-4">
            {/* <label>Submit status</label> */}
            <div className="col-md-12">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="preview"
                  id="preview"
                  value="true" // Represents 'true' status for preview
                  checked={submitCheckBox.preview === true} // Checked if preview is true
                  onChange={handleChangeCheck}
                />
                <label className="form-check-label" htmlFor="preview">
                  Preview
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="final"
                  id="final"
                  value="true" // Represents 'true' status for final
                  checked={submitCheckBox.final === true} // Checked if final is true
                  onChange={handleChangeCheck}
                />
                <label className="form-check-label" htmlFor="final">
                  Generate
                </label>
              </div>
            </div>
          </div>
          <div className={styles.buyerSaleTypeContainer}>
            {/* <span>Sale Type</span> */}
            <p
              className={`${styles.saletypeDropDownContinaer} ${styles.ptag_remove_mar}`}
            >
              <select
                id="saleType"
                name="saleType"
                value={formData.saleType}
                // className={Styles.input_text_field}
                style={{
                  fontWeight: "bolder",
                  backgroundColor: "#d1823a",
                  color: "white",
                  padding: "7px",
                }}
                onChange={handleChangeSaleType}
              >
                {/* <option value="">Sale Type</option> */}
                <option value="Retail/Cash">Retail/Cash</option>
                {/* <option value="Finance/BHPS">Finance/BHPS</option> */}
                <option value="Out of state">Out of state</option>
                <option value="Whole Sale">Whole Sale</option>
                {/* <option value="Rent & Lease">Rent & Lease</option> */}
              </select>
            </p>
          </div>
        </div>
        <div className={styles.buyerQuoteInfoParent}>
          <div className={styles.buyerQuoteInfo}>
            {QuoteData.slice(0, 4).map((item) => (
              <div key={item.id} className={styles.buyerPersonalInfo}>
                <p
                  className={`${styles.buyerInfoText} ${styles.ptag_remove_mar}`}
                >
                  {item.inputLabel}
                </p>
                <input
                  type="text"
                  value={
                    item.key === "phoneNumber"
                      ? formatPhoneNumber(formData[item.key])
                      : formData[item.key]
                  } // Format phone number for display
                  onChange={(e) =>
                    handleInputChange(item.key, e, item.key !== "phoneNumber")
                  } // Pass the key and handle input change
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.priceFeesWrapper}>
        <div className={styles.priceFeesRightContainer}>
          <div className={styles.percCalWrapper}>
            {QuoteData.slice(4, 6).map((item, index) => (
              <div key={item.id}>
                {/* Render input fields for all except index 1 and 2 (initially) */}
                {index !== 1 && index !== 2 && (
                  <div className={`${styles.priceFeesContainer}`}>
                    <p className={styles.priceFeesText}>
                      {item.id}. {item.inputLabel}
                    </p>
                    <div className={styles.inputWithPercentb}>
                      <span className={styles.percentSymbolb}>$</span>
                      <input
                        type="text"
                        value={formData[item.key]} // Bind to formData
                        onChange={(e) => handleInputChange(item.key, e, false)} // Pass key
                      />
                      <span
                        className={styles.percentSymbolCalouter}
                        onClick={() => calculatorHead(item.inputLabel)}
                      >
                        <IoCalculator style={{ cursor: "pointer" }} />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {/*---------------------- for calculator sale-price--------------------------- */}
            {showCalculatorDropdownSalePrice && (
              <div
                className="calculator-dropdown"
                style={{
                  width: "14%",
                  position: "absolute",
                  marginTop: "0.5%",
                  // top: "90%", // Position it just below the input field
                  left: "42%",
                  border: "1px solid #ccc",
                  backgroundColor: "#949494",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                }}
              >
                <QuoteCalculator
                  setShowModalCalculator={setShowCalculatorDropdownSalePrice}
                  setFormData={setFormData} // Assuming setFormData is provided
                  statusShowCal={4}
                />
              </div>
            )}
          </div>
          {/* for trade in */}
          <div style={{ position: "relative" }}>
            <div className={styles.percCalWrapper}>
              {QuoteData.slice(5, 6).map((item, index) => (
                <div key={item.id}>
                  {/* Render input fields for all except index 1 and 2 (initially) */}
                  {index !== 1 && index !== 2 && (
                    <>
                      <div className={`${styles.priceFeesContainer}`}>
                        <p className={styles.priceFeesText}>
                          {item.id}. {item.inputLabel}
                        </p>
                        <div className={styles.inputWithPercentb}>
                          <span className={styles.percentSymbolb}>$</span>
                          <input
                            type="text"
                            value={formData[item.key]} // Bind to formData
                            onClick={item.inputLabel === "Net after Trade-In" && handelCheckBox}
                            // onChange={(e) =>
                            //   handleInputChange(item.key, e, false)
                            // } // Pass key
                          />
                          <span
                            onClick={handelCheckBox}
                            className={styles.percentSymbole}
                          >
                            <HiDotsVertical style={{ cursor: "pointer" }} />
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
            {/* for trade in end */}
            <div
              className={`${styles.percCalWrapper} ${styles.pop_tradeIn}`}
              style={{ display: isTradeInChecked ? "" : "none" }}
            >
              {QuoteData.slice(4, 8).map((item, index) => (
                <div key={item.id}>
                  {/* Render input fields for index 1 and 2 only if trade-in is checked */}
                  {(index === 1 || index === 2 || index === 3) &&
                    isTradeInChecked && (
                      <div
                        className={`${styles.priceFeesContainer}  ${styles.for_width}`}
                        style={{ marginTop: "1rem" }}
                      >
                        <p
                          className={`${styles.priceFeesText} ${styles.for_text_width}`}
                        >
                          {item.inputLabel}
                        </p>
                        <div className={styles.inputWithPercentd}>
                          <span className={styles.percentSymbold}>$</span>
                          <input
                            type="text"
                            value={formData[item.key]} // Bind to formData
                            onChange={(e) =>
                              handleInputChange(item.key, e, false)
                            } // Pass key
                          />
                          <span
                            className={styles.percentSymbolCal}
                            onClick={() => calculatorHead(item.inputLabel)}
                          >
                            <IoCalculator />
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              ))}
              {/*---------------------- for calculator allowance--------------------------- */}
              {showCalculatorDropdownAllowance && (
                <div
                  className="calculator-dropdown"
                  style={{
                    width: "37%",
                    position: "absolute",
                    marginTop: "15%",
                    // top: "90%", // Position it just below the input field
                    left: "0",
                    border: "1px solid #ccc",
                    backgroundColor: "#949494",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 10,
                  }}
                >
                  <QuoteCalculator
                    setShowModalCalculator={setShowCalculatorDropdownAllowance}
                    setFormData={setFormData} // Assuming setFormData is provided
                    statusShowCal={1}
                  />
                </div>
              )}
              {/*---------------------- for calculator pay-off--------------------------- */}
              {showCalculatorDropdownPayOff && (
                <div
                  className="calculator-dropdown"
                  style={{
                    width: "37%",
                    position: "absolute",
                    marginTop: "15%",
                    // top: "90%", // Position it just below the input field
                    left: "27%",
                    border: "1px solid #ccc",
                    backgroundColor: "#949494",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 10,
                  }}
                >
                  <QuoteCalculator
                    setShowModalCalculator={setShowCalculatorDropdownPayOff}
                    setFormData={setFormData} // Assuming setFormData is provided
                    statusShowCal={2}
                  />
                </div>
              )}
              {/*---------------------- for calculator trade-In--------------------------- */}
              {showCalculatorDropdownTradeIn && (
                <div
                  className="calculator-dropdown"
                  style={{
                    width: "37%",
                    position: "absolute",
                    marginTop: "15%",
                    // top: "90%", // Position it just below the input field
                    right: "4%",
                    border: "1px solid #ccc",
                    backgroundColor: "#949494",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 10,
                  }}
                >
                  <QuoteCalculator
                    setShowModalCalculator={setShowCalculatorDropdownTradeIn}
                    setFormData={setFormData} // Assuming setFormData is provided
                    statusShowCal={3}
                  />
                </div>
              )}
              {/* -------------------------------------------------------------- */}
              <div className={styles.submit_btn}>
                <button type="button" onClick={submitTrddeIn}>
                  submit
                </button>
              </div>
              <div className={styles.cross_btn}>
                <button type="button" onClick={submitTrddeIn}>
                  X
                </button>
              </div>
            </div>
          </div>
          <div className={styles.priceFeesleftContainer}>
            <div>
              <Labels
                allInputs={allInputs && allInputs}
                editStatus={false}
                isTradeInChecked={isTradeInChecked}
              />
            </div>
            <p
              style={{
                border: "none",
                marginBottom: "0",
                display: "flex",
                justifyContent: "end",
                paddingRight: "2rem",
                marginBottom: "0.5rem",
              }}
            >
              <button
                // className="btn-orange"
                onClick={() => {
                  dispatch(setDynamicAPILabelsFast(DefaultDynamicLabels));
                }}
                style={{
                  border: "none",
                  // backgroundColor: "#E8A262",
                  color: "#E8A262",
                  fontWeight: "500",
                  borderRadius: "5px",
                  padding: "0.5rem 0.7rem",
                  fontSize: "17px",
                  marginRight: "1rem",
                }}
              >
                <span>
                  <BiReset />
                </span>
              </button>
              <button
                className="btn-orange"
                onClick={() => {
                  setShowLabelPopout(true);
                }}
                style={{
                  border: "none",
                  // backgroundColor: "#E8A262",
                  color: "white",
                  fontWeight: "500",
                  borderRadius: "5px",
                  padding: "0.5rem 0.7rem",
                  width: "auto",
                }}
              >
                Edit{" "}
                <span
                  style={{
                    color: "white",
                    fontSize: "17px",
                    fontWeight: "700",
                    borderRadius: "5px",
                  }}
                >
                  &#8595;
                </span>
              </button>
            </p>
          </div>
        </div>
        <div className={styles.priceFeesRightContainer}>
          <div className={styles.percCalWrapper}>
            <p className={styles.percCalContainer}>
              <span>{currentIndex}. Sales Tax</span>
              <div className={styles.perCalInput}>
                <div className={styles.inputWithPercent}>
                  <span className={styles.percentSymbol}>%</span>
                  <input
                    type="number"
                    name="salesTaxPerc"
                    value={formData.salesTaxPerc}
                    className={styles.numberInput}
                    onChange={(e) => handleInputChangeTax("salesTaxPerc", e)}
                    style={{boxShadow:"0px 5px 3px lightgrey"}}
                  />
                  <span
                    className={styles.percentSymboltexCal}
                    onClick={() => calculatorHead("salesTaxPerc")}
                  >
                    <IoCalculator style={{ cursor: "pointer" }} />
                  </span>
                </div>
                 {/*---------------------- for calculator sale-tax--------------------------- */}
            {showCalculatorDropdownSaleTax && (
              <div
                className="calculator-dropdown"
                style={{
                  width: "14%",
                  position: "absolute",
                  marginTop: "3%",
                  // top: "90%", // Position it just below the input field
                  // left: "39%",
                  border: "1px solid #ccc",
                  backgroundColor: "#949494",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                }}
              >
                <QuoteCalculator
                  setShowModalCalculator={setShowCalculatorDropdownSaleTax}
                  setFormData={setFormData} // Assuming setFormData is provided
                  statusShowCal={5}
                />
              </div>
            )}
            {/* -------------------------------------------------- */}
                {/* <input type="text" value={taxesAndFees.salesTaxPercent} /> */}
                <input type="text" value={saletaxValue} style={{boxShadow:"0px 5px 3px lightgrey"}}/>
              </div>
            </p>
            <p className={styles.percCalContainer}>
              <span>{currentIndex + 1}. Inventory Tax</span>
              <div className={styles.perCalInput}>
                <div className={styles.inputWithPercent}>
                  <span className={styles.percentSymbol}>%</span>
                  <input
                    type="number"
                    name="inventoryTaxes"
                    value={formData.inventoryTaxes}
                    className={styles.numberInput}
                    style={{boxShadow:"0px 5px 3px lightgrey"}}
                    onChange={(e) => handleInputChangeTax("inventoryTaxes", e)}
                  />
                  <span
                    className={styles.percentSymboltexCal}
                    onClick={() => calculatorHead("Inventory Tax")}
                  >
                    <IoCalculator style={{ cursor: "pointer" }} />
                  </span>
                </div>
                {/*---------------------- for calculator sale-tax--------------------------- */}
            {showCalculatorDropdownInvTax && (
              <div
                className="calculator-dropdown"
                style={{
                  width: "14%",
                  position: "absolute",
                  marginTop: "3%",
                  // top: "90%", // Position it just below the input field
                  // left: "39%",
                  border: "1px solid #ccc",
                  backgroundColor: "#949494",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                }}
              >
                <QuoteCalculator
                  setShowModalCalculator={setShowCalculatorDropdownInvTax}
                  setFormData={setFormData} // Assuming setFormData is provided
                  statusShowCal={6}
                />
              </div>
            )}
            {/* -------------------------------------------------- */}
                <input type="text" value={inventorytaxValue.toFixed(2)}  style={{boxShadow:"0px 5px 3px lightgrey"}}/>
              </div>
            </p>
          </div>
          <div className={styles.percCalWrapper}>
            <p className={styles.percCalContainer2}>
              <span>{currentIndex + 2}. Total Taxes </span>
              <div className={styles.inputWithPercentc}>
                <span className={styles.percentSymbolc}>$</span>
                <input type="text" value={formData.totalTaxes} />
              </div>
            </p>
            <p className={styles.percCalContainer2}>
              <span>{currentIndex + 3}. Total Fees </span>
              <div className={styles.inputWithPercentc}>
                <span className={styles.percentSymbolc}>$</span>
                <input type="text" value={sumTotalFees} />
              </div>
            </p>
            <p className={styles.percCalContainer2}>
              <span>{currentIndex + 4}. Total Tax & Fees </span>
              <div className={styles.inputWithPercentc}>
                <span className={styles.percentSymbolc}>$</span>
                <input type="text" value={sumFeesTaxValue} />
              </div>
            </p>
          </div>
          <div className={styles.percCalWrapperPayment}>
            <p className={styles.check_payment_head}>Accepts payments</p>
            <div className={styles.check_payment}>
              <span>
                <input
                  type="checkbox"
                  id="cash"
                  name="cash"
                  checked={formData.checkQuote.cash}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="cash">Cash</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="card"
                  name="card"
                  checked={formData.checkQuote.card}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="card">Card (Fees Apply)</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="wire"
                  name="wire"
                  checked={formData.checkQuote.wire}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="wire">Wire</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="cashier"
                  name="cashier"
                  checked={formData.checkQuote.cashier}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="cashier">Cashier Cash</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="cheque"
                  name="cheque"
                  checked={formData.checkQuote.cheque}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="cheque">Cheque</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="finance"
                  name="finance"
                  checked={formData.checkQuote.finance}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="finance">Finance Source</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="trade"
                  name="trade"
                  checked={formData.checkQuote.trade}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="trade">Trade-In</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="others"
                  name="others"
                  checked={formData.checkQuote.others}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="others">Others</label>
              </span>
              <p
                className={styles.check_paymentSave}
                onClick={handleSaveCheckBox}
              >
                <BiSave title="Save Payment Method" />
              </p>
            </div>
          </div>
          <div className={styles.percCalWrapper3}>
            <p className={styles.percCalContainer3}>
              <span>{currentIndex + 5}. Total</span>
              <span>$ {formData?.total}</span>
            </p>
          </div>

          <p className={styles.generateBtnContainer}>
            <button type="button" onClick={handleGenerate}>
              {loading ? <div className="spinner"></div> : "Generate"}
            </button>
          </p>
        </div>
      </div>
      {/* <div className={styles.exportContainer}>
        <span className={styles.exportIndicator}>Export</span>
        <button>Print</button>
        <button>Email</button>
      </div> */}
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title">
            Quote Forms
          </Modal.Title>
          <Modal.Title
            style={{
              display: "flex",
              width: "16%",
              gap: "5%",
              marginLeft: "19%",
            }}
          >
            <Button
              className="w-100 pe-0 py-2 btn-orange border-0"
              onClick={() => sendRequestEmail()}
            >
              E-Mail
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {quoteForm &&
              quoteForm.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
      {/*----------------------------------- madel for labels--------------------------------------- */}
      <Modal
        // dialogClassName="modal-90w"
        show={showLabelPopout}
        onHide={() => setShowLabelPopout(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.labelPopoutHeaderContainer}>
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className={styles.labelPopoutHeaderDropDown}
            >
              <Dropdown.Toggle
                size="sm"
                className={`px-3 bg-orange border-0 fw-bold text-light ${styles.selectSaleType}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <div className={styles.salesInventoryTaxContainer}>
              <p className={styles.salesTaxContainer} style={{color:"#d1823a"}} >
                Edit Taxes & Fees (TTL) <IoIosInformationCircleOutline title="Taxes & Fees" style={{cursor:"pointer",color:"grey"}}/>
              </p>
              </div>
            <div className={styles.salesInventoryTaxContainer}>
              {/* <p className={styles.salesTaxContainer}>
                <span>Sales Tax</span>
                <input
                  type="text"
                  value={retailFromRedux.salesTax}
                  onChange={(e) =>
                    dispatch(updateTaxValues({ salesTax: e.target.value }))
                  }
                />
              </p>
              <p className={styles.InventoryTaxContainer}>
                <span>Inventory Tax</span>
                <input
                  type="text"
                  value={retailFromRedux.minventoryTax}
                  onChange={(e) =>
                    dispatch(updateTaxValues({ minventoryTax: e.target.value }))
                  }
                />
              </p> */}
              <p className={styles.InventoryTaxContainer}>
              <select
                id="saleType"
                name="saleType"
                value={formData.saleType}
                className={styles.selectSaleType}
                // className={Styles.input_text_field}
                style={{
                  fontWeight: "bolder",
                  backgroundColor: "#d1823a",
                  color: "white",
                  padding: "7px",
                }}
                onChange={handleChangeSaleType}
              >
                {/* <option value="">Sale Type</option> */}
                <option value="Retail/Cash">Retail/Cash</option>
                {/* <option value="Finance/BHPS">Finance/BHPS</option> */}
                <option value="Out of state">Out of state</option>
                <option value="Whole Sale">Whole Sale</option>
                {/* <option value="Rent & Lease">Rent & Lease</option> */}
              </select>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className={styles.salesInventoryTaxContainer} style={{display:"flex", justifyContent:"center",borderBottom:"1px solid #ddd1d1",paddingBottom:"10px"}} >
            <p className={styles.salesTaxContainer} style={{position:"relative"}}>
                <span style={{fontWeight:"bold"}}>Sales Tax</span>
                <span className={styles.percentSymbolSalesTax}>%</span>
                <input
                  type="text"
                  value={retailFromRedux.salesTax}
                  style={{paddingLeft:"10%"}}
                  onChange={(e) =>
                    dispatch(updateTaxValues({ salesTax: e.target.value }))
                  }
                />
              </p>
              <p className={styles.InventoryTaxContainer} style={{position:"relative"}}>
                <span style={{fontWeight:"bold"}}>Inventory Tax</span>
                <span className={styles.percentSymbolSalesTax} style={{left:"41%"}}>%</span>
                <input
                  type="text"
                  value={retailFromRedux.minventoryTax}
                  style={{paddingLeft:"10%"}}
                  onChange={(e) =>
                    dispatch(updateTaxValues({ minventoryTax: e.target.value }))
                  }
                />
              </p>
            </div>
          <div className={Styles.boxContainer}>
            <Dropdown
              show={isOpen}
              onSelect={handleSelectState}
              onToggle={(open) => setIsOpen(open)}
              className={Styles.labelPopoutHeaderDropDown}
            >
              <Dropdown.Toggle
                size="sm"
                className="px-3 py-2 bg-orange border-0 fw-bold text-light"
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedState}
              </Dropdown.Toggle>
              <Dropdown.Menu className="state-drp">
                {/* Hidden input to manage search */}
                <input
                  type="text"
                  value={search}
                  ref={searchInputRef}
                  autofocus
                  onChange={handleSearchChange}
                  onFocus={() => setIsOpen(true)}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                  }} // Hide the input field
                />
                {filteredStates.length > 0 ? (
                  filteredStates.map((state) => (
                    <Dropdown.Item
                      key={state.name}
                      eventKey={state.name}
                      onClick={() => handleSelect(state.name)}
                    >
                      {state.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No states found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Labels allInputs={allInputs} editStatus={true} />
            <div
              className={
                Styles.box_b_none_edit_add_button_small_screen_container
              }
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "#e8a262",
                  color: "white",
                  borderRadius: "5px",
                  padding: "0.35rem 1rem",
                  marginRight: "2rem",
                  fontSize: "medium",
                  marginTop: "0.1rem",
                }}
                className={Styles.box_b_none_edit_add_button_small_screen}
                onClick={() =>
                  dispatch(
                    addSellPriceLabelsFast({
                      id: allInputs && allInputs.length + 1,
                      label: `Input ${allInputs && allInputs?.length + 1}`,
                    })
                  )
                }
                disabled={allInputs && allInputs.length === 20}
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {" "}
            <button
              style={{
                border: "none",
                backgroundColor: "#E8A262",
                color: "white",
                fontWeight: "500",
                borderRadius: "5px",
                padding: "0.5rem 0.7rem",
                marginLeft: "0.7rem",
              }}
              onClick={() => handleSaveLables()}
            >
              Save
            </button>
          </p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Quote;
