import React, { useEffect, useRef, useState } from "react";
import styles from "./QutationTable.module.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import tableBodyArray from "./CRMDummyData";
import { MdEmail } from "react-icons/md";
import { ImCross } from "react-icons/im";
import CrmEmailPopOut from "./CrmEmailPopOut.jsx";
import { useSelector } from "react-redux";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { Button, Container, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice.js";
import { useDispatch } from "react-redux";
const QutationTable = () => {
  const dispatch = useDispatch();
  const { dealerId } = useSelector((state) => state.user);
  const [showEmailPopOut, setShowEmailPopOut] = useState(false);
  const [showModalOtp, setShowModalOtp] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Initial state for the 6 OTP digits
  const [rowData, setRowData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  //  get data of crn table---------------------------------------------------
  const fetchDealerData = async () => {
    // const fd = new FormData();
    // fd.append('user_id', dealerId);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fastQuote/getQuote/${dealerId}`
        // fd
      );
      setTableData(response.data);
    } catch (error) {
      console.log("Error fetching dealer data:", error);
      setTableData([]);
    }
  };

  // Using useEffect to fetch data when the page loads
  useEffect(() => {
    if (dealerId) {
      fetchDealerData(); // Fetch dealer data when 'id' is available
    }
  }, [dealerId]);
  console.log("tableData34543", tableData);
  // -----------------------------------------------------------------------------------
  // fortable data-------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 20; // Change this value according to your preference

  // Ensure tableData is defined before performing operations
  const totalItems = tableData ? tableData.length : 0;
  const itemsPerPage = totalItems > 20 ? totalItems : 20; 
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData
    ? tableData.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`} className={`${styles.tableRowData}`}>
      {[...Array(10)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));
  //  for pdf download and view--------------------------------------------------------------
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfData, setShowPdfData] = useState();
  const [showPdfStatus, setShowPdfStatus] = useState();
  const [idFinance, setIdFinance] = useState();
  const [userIdFinance, setUserIdFinance] = useState();
  //  const [pdfData, setPdfData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPdf = async (QuotationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fastQuote/view/${QuotationId}`
      );
      console.log("response3545", response.status);

      if (response.status === 200) {
        setPdfData([response.data]);
        toast.success("Successfully show the PDF");
        setShowPdf(true);
      } else if (response.status === 204) {
        toast.error("Please Enter Correct OTP.");
      }
    } catch (err) {
      toast.error(err);
    }
  };

  // -------------------------------------
  //  for country dialing code-------------------------
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("Error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, [countryCode]);
  console.log("countryCode", countryCode);
  useEffect(() => {
        dispatch(
          setRetailData({
            crmQuoteLenght:tableData?.length ?? 0,
          })
        );
      }, [tableData]);
  // --------------------------------------------------------------------------------
  const renderTableRow = (item, index) => {
    console.log("item in the current list => ", item);
    // Calculate days difference for the inspection date

    return (
      <>
        <tr
          key={index}
          className={`${styles.tableRowData}`}
        >
          {item ? (
            <>
              <td>{index + 1}</td>
              <td className="border-0 mb-0">{item?.createdAt} </td>
              <td>
                {item?.firstName} {item?.lastName}
              </td>
              <td>{item?.phoneNumber}</td>
              <td className={styles.email}>
                <span>{item?.email}</span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowEmailPopOut(true);
                    setRowData(item);
                  }}
                >
                  <MdEmail />
                </span>
              </td>
              <td className="ac-col fw-bold">{item?.asset} </td>
              <td>{item?.saleType}</td>
              <td style={{color:item?.status === 'Active' ? 'green' : 'red'}}>{item?.status}</td>
              <td>{item?.total}</td>
              <td className="text-center">
                {/* <Button
                  type="button"
                  className="publish-btn border-0 me-1 py-2"
                  style={{ color: "white", width: "70px" }}
                  // onClick={() => downloadPdf(item?.id)}
                  // onClick={()=>getOtpLoan(item?.id, item?.userId,2)}
                >
                  Download
                </Button> */}
                <Button
                  type="button"
                  className="border-0  py-2"
                  style={{ color: "white", width: "70px",backgroundColor:"#934805",fontSize:"0.8rem" }}
                  // onClick={() => loadPdf(item?.id, 1)}
                  // onClick={()=>getOtpLoan(item?.id, item?.userId,1)}
                  onClick={() => loadPdf(item?.id)}
                >
                  View
                </Button>
              </td>
            </>
          ) : (
            Array.from({ length: 10 }).map((_, i) => <td key={i}>&nbsp;</td>)
          )}
        </tr>
      </>
    );
  };
  //  table functionend-------------------------------------------------------------
  console.log("showPdfData", showPdfData);
  // -------------------------------------------------------------------------------
  console.log("tableData", tableData);
  // Conditionally apply styles for the table body
  // const tableBodyStyle = totalItems > itemsPerPage ? { height: '70vh', overflowY: 'auto',scrollbarWidth:"none" } : {};
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container} style={{maxHeight:"70vh",overflowY:"auto",scrollbarWidth:"none"}}>
          <Table
            striped
            bordered
            hover
            ref={tableRef}
            className="shadow mb-0"
            id="table"
          >
            <thead>
              <tr>
                <th>No#</th>
                <th className="ac-col">Date</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Asset Info</th>
                <th>Sale Type</th>
                <th>Status</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(renderTableRow)}
              {emptyRows}
            </tbody>
            <tfoot>
              <tr className={`${styles.tableFooterRow}`}>
                <td colSpan={2}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
      <CrmEmailPopOut
        setShowEmailPopOut={setShowEmailPopOut}
        showEmailPopOut={showEmailPopOut}
        rowData={rowData}
      />
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title" style={{ color:"#d1823a"}}>
            Quotation Pdf :
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {pdfData &&
              pdfData.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
      
    </>
  );
};

export default QutationTable;
