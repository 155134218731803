import React, { useEffect, useState } from "react";
import styles from "./DesktopNewFilters.module.css";
import { MdSearch } from "react-icons/md";
import axios from "axios";
import { IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const DesktopNewFilters = () => {
  const distanceArray = [
    { value: 20, label: "20 miles" },
    { value: 50, label: "50 miles" },
    { value: 100, label: "100 miles" },
    { value: 150, label: "150 miles" },
    { value: 230, label: "230 miles" },
    { value: 500, label: "500 miles" },
    { value: 1000, label: "1000 miles" },
  ];
  const [searchInput, setSearchInput] = useState("");
  console.log("searchInput => ", searchInput);
  const [showSellVehicle, seShowSellVehicle] = useState(false);
  const [showVehicleBtn, setShowVehcileBtn] = useState("vin");
  const [responseFilter, setResponseFilter] = useState({});
  const [showYear, setShowYear] = useState(false);
  const [showMake, setShowMake] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showDistance, setShowDistance] = useState(false);
  const [distanceInput, setDistanceInput] = useState("");
  const [distanceOutput, setDistanceOutput] = useState(distanceArray);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: [],
    zip_code: [],
  });
  console.log("filters in desktop new filters", filters);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filters`,
          filters
        );
        // console.log("response filters from the API", response.data.data);
        setResponseFilter(response.data);
      } catch (error) {
        // console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className={styles.dnfWrapper}>
      <div className={styles.dnfContainer}>
        <div className={styles.dnfbtnContainer}>
          <button
            onClick={() => seShowSellVehicle(false)}
            style={{ borderBottom: !showSellVehicle && "2px solid #d1823a" }}
          >
            Search Vehicle
          </button>
          <button
            onClick={() => seShowSellVehicle(true)}
            style={{ borderBottom: showSellVehicle && "2px solid #d1823a" }}
          >
            Sell a Vehicle
          </button>
        </div>
        <div className={styles.dnfFilterWraper}>
          {!showSellVehicle && (
            <>
              <div className={styles.dnfFilterContainer}>
                <div className={styles.dnfInputContainer}>
                  <span>
                    <MdSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search Inventory"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
                {/* for year make modal */}
                <div className={styles.dnfYearMakeContainer}>
                {/* Year */}
                <div className={`${styles.dnfRelativeContainer} ${styles.forMibileRightBorder}`}>
                  <p onClick={() => setShowYear(!showYear)}>
                    <span>
                      {filters.year.length > 0 ? filters.year[0] : "Year"}
                    </span>
                    <span>
                      <IoIosArrowUp
                        style={{
                          transform: !showYear && "rotate(180deg)",
                          transition: "all 0.5s ease",
                        }}
                      />
                    </span>
                  </p>
                  {showYear && (
                    <div className={styles.dnfSelectAbsoluteContainer}>
                      {responseFilter &&
                        responseFilter?.year?.map((year, i) => (
                          <p
                            value={year}
                            key={i}
                            onClick={() => {
                              setFilters({ ...filters, year: [year] });
                              setShowYear(false);
                            }}
                          >
                            {year}
                          </p>
                        ))}
                    </div>
                  )}
                </div>
                {/* Make */}
                <div className={`${styles.dnfRelativeContainer} ${styles.forMibileRightBorder}`}>
                  <p onClick={() => setShowMake(!showMake)}>
                    <span>
                      {filters.make.length > 0 ? filters.make[0] : "Make"}
                    </span>
                    <span>
                      <IoIosArrowUp
                        style={{
                          transform: !showMake && "rotate(180deg)",
                          transition: "all 0.5s ease",
                        }}
                      />
                    </span>
                  </p>
                  {showMake && (
                    <div className={styles.dnfSelectAbsoluteContainer}>
                      {responseFilter &&
                        responseFilter?.makes?.map((makeItem, i) => (
                          <p
                            value={makeItem.make}
                            key={i}
                            onClick={() => {
                              setFilters({ ...filters, make: [makeItem.make] });
                              setShowMake(false);
                            }}
                          >
                            {makeItem.make}
                          </p>
                        ))}
                    </div>
                  )}
                </div>
                {/* Model */}
                <div className={styles.dnfRelativeContainer}>
                  <p onClick={() => setShowModel(!showModel)}>
                    <span>
                      {filters.models.length > 0 ? filters.models[0] : "Model"}
                    </span>
                    <span>
                      <IoIosArrowUp
                        style={{
                          transform: !showModel && "rotate(180deg)",
                          transition: "all 0.5s ease",
                        }}
                      />
                    </span>
                  </p>
                  {showModel && (
                    <div className={styles.dnfSelectAbsoluteContainer}>
                      {responseFilter &&
                        responseFilter?.models?.map((model, i) => (
                          <p
                            key={i}
                            onClick={() => {
                              setFilters({ ...filters, models: [model] });
                              setShowModel(false);
                            }}
                          >
                            {model}
                          </p>
                        ))}
                    </div>
                  )}
                </div>
                </div>
                {/* year make modal end */}
                <div className={styles.dnfgeoZipContainer}>
                  <input type="text" name="" id="" placeholder="ZipCode" />
                  {/* Distance */}
                  <div className={styles.dnfRelativeContainer}>
                    <p>
                      <input
                        type="text"
                        placeholder="Distance"
                        value={distanceInput}
                        onClick={() => setShowDistance(!showDistance)}
                        onChange={(e) => {
                          const InputValue = e.target.value;
                          setDistanceInput(InputValue);
                          const filteredDistance = distanceArray.filter(
                            (mile) => {
                              return mile.label
                                .toLowerCase()
                                .includes(InputValue.toLowerCase());
                            }
                          );

                          if (filteredDistance.length > 0) {
                            setShowDistance(true);
                          }
                          setDistanceOutput(filteredDistance);
                        }}
                      />

                      <span>
                        <IoIosArrowUp
                          style={{
                            transform: !showDistance && "rotate(180deg)",
                            transition: "all 0.5s ease",
                          }}
                        />
                      </span>
                    </p>
                    {showDistance && (
                      <div className={styles.dnfSelectAbsoluteContainer}>
                        {distanceOutput &&
                          distanceOutput?.map((mile, i) => (
                            <p
                              key={i}
                              onClick={() => {
                                setDistanceInput(mile.label);
                                setShowDistance(false);
                              }}
                            >
                              {mile.label}
                            </p>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <p className={styles.dnfContinueBtn}>
                <button
                  onClick={() => navigate("/product", { state: filters })}
                >
                  {" "}
                  Continue
                </button>
              </p>
            </>
          )}
          {showSellVehicle && (
            <div className={styles.dnfSellVehicleContainer}>
              <div className={styles.dnfSellVehiclebtnContainer}>
                <button
                  style={{
                    backgroundColor: showVehicleBtn === "vin" && "#d1823a",
                  }}
                  onClick={() => setShowVehcileBtn("vin")}
                >
                  VIN
                </button>
                <button
                  style={{
                    backgroundColor: showVehicleBtn === "plate" && "#d1823a",
                  }}
                  onClick={() => setShowVehcileBtn("plate")}
                >
                  Plate
                </button>
                <button
                  style={{
                    backgroundColor:
                      showVehicleBtn === "manuelEntry" && "#d1823a",
                  }}
                  onClick={() => setShowVehcileBtn("manuelEntry")}
                >
                  Manuel Entry
                </button>
              </div>
              {showVehicleBtn === "vin" && (
                <div className={styles.dnfSellVehicleContent1}>
                  <input type="text" placeholder="Enter VIN number" />
                </div>
              )}
              {showVehicleBtn === "plate" && (
                <div className={styles.dnfSellVehicleContent2}>
                  <input type="text" placeholder="Plate Number" />
                  <select>
                    <option value="" hidden>
                      State
                    </option>
                  </select>
                </div>
              )}
              {showVehicleBtn === "manuelEntry" && (
                <div className={styles.dnfSellVehicleContent3}>
                  <p  className={styles.dnfSellVehicleContent3Line1}>
                    <select>
                      <option value="" hidden>
                        Year
                      </option>
                    </select>
                    <input type="text" placeholder="Make" />
                    <input type="text" placeholder="Model" />
                  </p>
                  <p className={styles.dnfSellVehicleContent3Line1}>
                    {" "}
                    <input type="text" placeholder="Trim" />
                    <input type="text" placeholder="Transmission" />
                  </p>
                  <p className={styles.dnfSellVehicleContent3Line1}>
                    <input type="text" placeholder="BodyClass" />
                    <input type="text" placeholder="Drive Train Type" />
                  </p>
                </div>
              )}
              <p className={styles.dnfSellContinuebtnContainer}>
                <button>Continue</button>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopNewFilters;
