import React, { useEffect, useRef, useState } from "react";
import "../../DealerInventory/DealerAddInventory/addlist.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PiSpeedometerThin } from "react-icons/pi";

import Image from "../../../imgData";
import { fetchGeoMapData } from "../../../dashboard/geoAPI";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import { setIsSomethingChanged } from "../../../../Redux/slice/sellSlice";
import "./TradeInComponent.css";
import TradeInOptions from "./TradeInOptions";
import { TbArrowRight } from "react-icons/tb";
// For Tabsection end----------------------
const apiUrl = process.env.REACT_APP_API_URL;

const TradeInComponent = ({
  show,
  handleClose,
  onButtonClick,
  setIsSpanVisibleTradeIn,
  onReset,
  setIsClicked,
  counter,
  isClicked,
  status,
}) => {
  const { id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { dealerId } = useSelector((state) => state.user);
  const location = useLocation();
  // Retrieve original data passed via navigation
  const originalData = location.state?.data || {};
  const TaxFeesPopData = useSelector(
    (state) => state.taxesAndFees.dealerRetail || 0
  );
  console.log("TaxFeesPopData*******24*******TradeIn", TaxFeesPopData);
  console.log("location", location);

  const [inputVin, setInputVin] = useState("");
  const dealerRetailDtata = useSelector(
    (state) => state.taxesAndFees.dealerRetail || ""
  );
  const selectedVehicle = useSelector((state) => state.sell.selectedVehicle);
  console.log("selectedVehicle => ", selectedVehicle);
  console.log("dealerRetailDtataTradeIn", dealerRetailDtata);
  console.log("statusTradeIn", status);
  // for trade-in calculation-------------------------------------------------
   const sellingPriceVehicle = useSelector(
      (state) => parseFloat(state.taxesAndFees.dealerRetail.userListingPrice) || 0
    );
  // Loading stats
  const [loading, setLoading] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [uploadSpeedDisplay, setUploadSpeedDisplay] = useState(0);

  useEffect(() => {
    setUploadSpeedDisplay(uploadSpeed);
  }, [uploadSpeed]);

  const progressPercentage = (uploadedCount / totalCount) * 100;

  const [activeTab, setActiveTab] = useState("tab1");
  const [apiData, setApiData] = useState(null);
  const [apiDataEdit, setApiDataEdit] = useState(null);
  const [apiDatab, setApiDatab] = useState(null);
  console.log("apiDatab => 2", apiDatab);
  const [apiDatac, setApiDatac] = useState(null);
  const scrollContainerRef = useRef(null);
  const [emptyFields, setEmptyFields] = useState({});
  //const [decode_error, setErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSeatsPresent, setIsSeatsPresent] = useState(false);
  const [isTransmissionPresent, setIsTransmissionPresent] = useState(false);
  const [year, setYear] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);

  // purchased form dropdown end---------------------------------------------------------
  const [exteriorColorShow, setExteriorColorShow] = useState("");
  console.log("exteriorColorShow => ", exteriorColorShow);
  const [interiorColorShow, setInteriorColorShow] = useState("");
  console.log("interiorColorShow => ", interiorColorShow);
  //  Buyer name dropdown end---------------------------------------------------
  const [btnTxt, setBtnTxt] = useState("");
  // popup selection option--------------------------------------****************************************************--------------------------

  const [showModalImg, setShowModalImg] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedOptionn, setSelectedOptionn] = useState("");
  const [showModalc, setShowModalc] = useState(false);
  const [selectedOptionc, setSelectedOptionc] = useState("");
  const [showModald, setShowModald] = useState(false);
  const [selectedOptiond, setSelectedOptiond] = useState("");
  const [showModale, setShowModale] = useState(false);
  const [selectedOptione, setSelectedOptione] = useState("");
  const [showModalh, setShowModalh] = useState(false);
  const [selectedOptionh, setSelectedOptionh] = useState("");
  const [showModali, setShowModali] = useState(false);
  const [selectedOptioni, setSelectedOptioni] = useState("");

  const [selectedOptionv, setSelectedOptionv] = useState("");
  const [showModalw, setShowModalw] = useState(false);
  const [selectedOptionw, setSelectedOptionw] = useState("");
  const [selectedOptionx, setSelectedOptionx] = useState("");

  const [showModalzb, setShowModalzb] = useState(false);
  const [selectedOptionzb, setSelectedOptionzb] = useState("");

  // ------------------------
  const [objectsFloor, setObjectsFloor] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  // repare cost------------------------------------------------------------
  const [objects, setObjects] = useState([]);
  const [inputValueDate, setInputValueDate] = useState("");
  const [inputValueAmount, setInputValueAmount] = useState("");
  const [inputValueDescription, setInputValueDescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  // Footer advertising manager---------------------------
  const [isCheckedfooter, setIsCheckedfooter] = useState(true);
  const [isCheckedfooterb, setIsCheckedfooterb] = useState(true);
  const [isCheckedfooterc, setIsCheckedfooterc] = useState(false);
  const [isCheckedfooterd, setIsCheckedfooterd] = useState(false);
  const [isCheckedfootere, setIsCheckedfootere] = useState(false);
  const [isCheckedfooterf, setIsCheckedfooterf] = useState(false);
  const [isCheckedfooterg, setIsCheckedfooterg] = useState(false);
  // Footer advertisi9ng manager end--------------------------
  // for inspection date-----------------------------------------
  // ---------------------new option arrow choose---------
  // Array of radio button options
  console.log("line120", apiDatab);

  let radioOptions;

  if (apiDatab && apiDatab.length > 0) {
    radioOptions = apiDatab.map((model) => ({
      value: model,
      label: model,
    }));
  } else {
    const defaultValue =
      apiDataEdit && apiDataEdit.trim ? apiDataEdit.trim : "";
    radioOptions = [{ value: defaultValue, label: defaultValue }];
  }

  console.log("line122", radioOptions);

  let radioOptionsc =
    apiDatac && apiDatac.length > 0
      ? apiDatac.map((model) => ({
          value: model,
          label: model,
        }))
      : [
          { value: "Manual", label: "Manual" },
          // { value: "Semi Automatic", label: "Semi Automatic" },
          { value: "Automatic", label: "Automatic" },
        ];

  const [radioOptionsd, setRadioOptionsd] = useState([
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ]);

  const radioOptionse = [
    { value: "Clothes", label: "Clothes" },
    { value: "Leather", label: "Leather" },
    { value: "Alcantara", label: "Alcantara" },
    { value: "Wood Trim", label: "Wood Trim" },
    { value: "Carbon Fiber", label: "Carbon Fiber" },
  ];
  const colorArray = [
    { value: "Beige", label: "Beige", boxColor: "#F5F5DC" },
    { value: "Black", label: "Black", boxColor: "black" },
    { value: "Blue", label: "Blue", boxColor: "blue" },
    { value: "Brown", label: "Brown", boxColor: "brown" },
    { value: "Burgundy", label: "Burgundy", boxColor: "#800020" },
    { value: "Charcoal", label: "Charcoal", boxColor: "#36454F" },
    { value: "Gold", label: "Gold", boxColor: "#FFD700" },
    { value: "Grey", label: "Grey", boxColor: "#808080" },
    { value: "Green", label: "Green", boxColor: "#008000" },
    { value: "Maroon", label: "Maroon", boxColor: "#800000" },
    { value: "Orange", label: "Orange", boxColor: "orange" },
    { value: "Pink", label: "Pink", boxColor: "pink" },
    { value: "Red", label: "Red", boxColor: "red" },
    { value: "Silver", label: "Silver", boxColor: "#C0C0C0" },
    { value: "Tan", label: "Tan", boxColor: "#D2B48C" },
    { value: "White", label: "White", boxColor: "white" },
    { value: "Yellow", label: "Yellow", boxColor: "yellow" },
  ];

  const [radioOptionsh, setRadioOptionsh] = useState(colorArray);
  const [radioOptionsi, setRadioOptionsi] = useState(colorArray);
  // const state = usaStates.map(state => {
  //     return { optionDropdown: state.name };
  // });

  const radioOptionszb = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];

  const [liveSeaching, setLiveSearching] = useState(false);
  console.log(liveSeaching);

  // ----------------------------------------------------------------------
  const [inspection, setInspection] = useState("");

  //formdata start ------------------------------------------------------------------

  const [formData, setFormData] = useState({
    id: 0,
    userId: dealerId,
    vin: "",
    year_make_model: "",
    trim: "",
    engine: "",
    transmission: "",
    vehicleType: "",
    // make year modal only check purpose----
    make: "",
    year: "",
    model: "",
    // --------------------------------
    seat_count: "",
    city_mpg: "",
    highway_mpg: "",
    inspection: "",
    exteriorColor: "",
    interiorColor: "",
    interiorType: "",
    mileage_in: "",
    //tradeInValue: formData.tradeInValue,
    purchase_price: 0,
    listing_price: "",
    netTradeIn:0,
    driveType: "",
    fuelType: "",
    fuelType2: "",
    engineCylinder: "",
    engineType: "",
    engineSize: "",
    engineShape: "",
    doors: "",
    tradeInAllowance: "",
    tradeInPay: 0,
    // actualValue: "",
  });

  const [displayValues, setDisplayValues] = useState({
    mileage_in: "",
    purchase_price: "", // Add other numeric fields as needed
    listing_price: "",
    others: "",
    market_value_price: "",
  });
  // Initialize formData and objects from apiDataEdit
  useEffect(() => {
    if (apiDataEdit) {
      console.log("apiDataEdit", apiDataEdit);
      const formatDate = (date) => {
        if (!date) return "";
        const [month, day, year] = date.split("/");
        return `${year}-${month}-${day}`;
      };
      // Set formData with specific fields
      setFormData((prevState) => ({
        ...prevState,
        id: apiDataEdit.id || "",
        // only use for backend-----------
        make: apiDataEdit?.Make || "",
        year: apiDataEdit?.ModelYear || "",
        model: apiDataEdit?.Model || "",
        bodyClass: apiDataEdit?.BodyClass || "",
        wheelbase: apiDataEdit?.WheelBaseShort || "",
        driveType: apiDataEdit?.DriveType || "",
        doorCount: apiDataEdit?.Doors || "",
        // --------------------------
        engine: apiDataEdit.engine || "",
        status: apiDataEdit.status || "",
        inspection: formatDate(apiDataEdit.inspection) || "",
        price_fee_date: apiDataEdit.price_fee_date || "",
        city_mpg: apiDataEdit.city_mpg || "",
        highway_mpg: apiDataEdit.highway_mpg || "",
        year_make_model: apiDataEdit.year_Make_Model || "",
        trim: apiDataEdit.trim || "",
        vehicleType: apiDataEdit.vehicleType || "",
        transmission: apiDataEdit.transmission || "",
        seat_count: apiDataEdit.seat_count || "",
        interiorType: apiDataEdit.interiorType || "",
        keys_count: apiDataEdit.keys_count || "",
        battery_kwh: apiDataEdit.battery_kwh || "",
        battery_type: apiDataEdit.battery_type || "",
        // mileage_in: apiDataEdit.mileage_in || "",
        // mileage_read: apiDataEdit.mileage_read || "",
        mileage_in: parseFloat(apiDataEdit.mileage_in || 0.0),
        purchase_price: parseFloat(apiDataEdit.purchase_price || 0.0),
        listing_price: parseFloat(apiDataEdit.listing_price || 0.0),
        others: parseFloat(apiDataEdit.others || 0.0),
        market_value_price: parseFloat(apiDataEdit.market_value_price || 0.0),
        purchasedDate: apiDataEdit.purchasedDate || "",
        exteriorColor: apiDataEdit.exteriorColor || "",
        interiorColor: apiDataEdit.interiorColor || "",
        other_restraint: apiDataEdit.other_restraint || "",
        // inspectionDate: apiDataEdit.inspection || "",
        //purchase_price: apiDataEdit.purchase_price || '',
        //listing_price: apiDataEdit.listing_price || "",
        //Equipment and Option----------
        adaptive_cruise_control: apiDataEdit.adaptive_cruise_control || "",
        key_less_entry: apiDataEdit.key_less_entry || "",
        side_airbags: apiDataEdit.side_airbags || "",
        air_conditioning: apiDataEdit.air_conditioning || "",
        key_less_go: apiDataEdit.key_less_go || "",
        side_steps_running_boards: apiDataEdit.side_steps_running_boards || "",
        all_wheels_drive: apiDataEdit.all_wheels_drive || "",
        leather_interior_surface: apiDataEdit.leather_interior_surface || "",
        s_line: apiDataEdit.s_line || "",
        amg_pack: apiDataEdit.amg_pack || "",
        leather_seats: apiDataEdit.leather_seats || "",
        soft_close_doors: apiDataEdit.soft_close_doors || "",
        anti_lock_brake_system: apiDataEdit.anti_lock_brake_system || "",
        leather_steering_wheel: apiDataEdit.leather_steering_wheel || "",
        sport_pack: apiDataEdit.sport_pack || "",
        auto_climate_control: apiDataEdit.auto_climate_control || "",

        // Seller Discription---------------------------------
        state: apiDataEdit.state || "",
        //status:apiDataEdit.status || '',
        odo: apiDataEdit.odo || "",
        owner: apiDataEdit.owner || "",
        exterior_damage: apiDataEdit.exterior_damage || "",
        smoked_in: apiDataEdit.smoked_in || "",
        tyrecondition_fl: apiDataEdit.tyrecondition_fl || "",
        tyrecondition_fr: apiDataEdit.tyrecondition_fr || "",
        tyrecondition_bl: apiDataEdit.tyrecondition_bl || "",
        tyrecondition_br: apiDataEdit.tyrecondition_br || "",
        seller_description: apiDataEdit.seller_description || "",
        // Title info-----------------------------
        title_type: apiDataEdit.title_type || "",
        title_location: apiDataEdit.title_location || "",
        title_number: apiDataEdit.title_number || "",
        title_recieved: apiDataEdit.title_recieved || "",
        title_status: apiDataEdit.title_status || "",
        // finance ------------------------------------------
        total_cost: apiDataEdit.total_cost || "",
        total_repair_cost: apiDataEdit.total_repair_cost || "",
        total_repair_cost: apiDataEdit.total_repair_cost || "",
        //others: apiDataEdit.others || "",
        // others: apiDataEdit.others || "",
        profit_price: apiDataEdit.profit_price || "",
        auction_fees: apiDataEdit.auction_fees || "",
        // advertising manager----------------------------------------------
        addvertising: {
          dacmp_status: apiDataEdit.addvertising.dacmp_status || false,
          dacmp_interval: apiDataEdit.addvertising.dacmp_interval || "",
          unitedmotors_status:
            apiDataEdit.addvertising.unitedmotors_status || false,
          unitedmotors_interval:
            apiDataEdit.addvertising.unitedmotors_interval || "",
          google_status: apiDataEdit.addvertising.google_status || false,
          google_interval: apiDataEdit.addvertising.google_interval || "",
          insta_status: apiDataEdit.addvertising.insta_status || false,
          insta_interval: apiDataEdit.addvertising.insta_interval || "",
          facebook_status: apiDataEdit.addvertising.facebook_status || false,
          facebook_interval: apiDataEdit.addvertising.facebook_interval || "",
          craigslist_status:
            apiDataEdit.addvertising.craigslist_status || false,
          craigslist_interval:
            apiDataEdit.addvertising.craigslist_interval || "",
          twitter_status: apiDataEdit.addvertising.twitter_status || false,
          twitter_interval: apiDataEdit.addvertising.twitter_interval || "",
        },
        //repaire cost---------------------------------------------------
        repair_cost: apiDataEdit.repair_cost || [],
      }));

      // Find the boxColor for the exterior color from apiDataEdit
      const exteriorColor = radioOptionsh.find(
        (obj) => obj.label === apiDataEdit.exteriorColor
      )?.boxColor;

      if (exteriorColor) {
        setExteriorColorShow(exteriorColor);
      }
      // Find the boxColor for the exterior color from apiDataEdit
      const interiorColor = radioOptionsi.find(
        (obj) => obj.label === apiDataEdit.interiorColor
      )?.boxColor;

      if (interiorColor) {
        setInteriorColorShow(interiorColor);
      }
      // Update displayValues with formatted data from the API
      setDisplayValues((prevState) => ({
        ...prevState,
        mileage_in: formatValue(apiDataEdit.mileage_in?.toString() || "0.0"),
        purchase_price: formatValue(
          apiDataEdit.purchase_price?.toString() || "0.0"
        ),
        listing_price: formatValue(
          apiDataEdit.listing_price?.toString() || "0.0"
        ),
        others: formatValue(apiDataEdit.others?.toString() || "0.0"),
        market_value_price: formatValue(
          apiDataEdit.market_value_price?.toString() || "0.0"
        ),
      }));
      setIsCheckedfooter(apiDataEdit.addvertising.dacmp_status || false);
      setIsCheckedfooterb(
        apiDataEdit.addvertising.unitedmotors_status || false
      );
      setIsCheckedfooterc(apiDataEdit.addvertising.google_status || false);
      setIsCheckedfooterd(apiDataEdit.addvertising.insta_status || false);
      setIsCheckedfootere(apiDataEdit.addvertising.facebook_status || false);
      setIsCheckedfooterf(apiDataEdit.addvertising.craigslist_status || false);
      setIsCheckedfooterg(apiDataEdit.addvertising.twitter_status || false);
      // Select the first item in repair_cost array (assuming there is at least one)
      const firstItem =
        apiDataEdit.repair_cost && apiDataEdit.repair_cost.length > 0
          ? apiDataEdit.repair_cost[0]
          : {};

      // Set fields based on first item
      setInputValueDate(firstItem.date || ""); // Set date from the first item
      setSelectedOptionv(firstItem.category || ""); // Set category from the first item
      setSelectedOptionw(firstItem.payee_vendor || ""); // Set payee_vendor from the first item
      setSelectedOptionx(firstItem.payment_method || ""); // Set price_payment_method from the first item
      setInputValueAmount(firstItem.amount ? firstItem.amount.toString() : ""); // Set amount from the first item
      setInputValueDescription(firstItem.description || ""); // Set description from the first item

      // Set objects with repair_cost array
      // setObjects(apiDataEdit.repair_cost || []);
      setObjects(apiDataEdit.repair_cost || []);

      const newTotalAmount = (apiDataEdit.repair_cost || []).reduce(
        (acc, curr) => acc + curr.amount,
        0
      );
      setTotalAmount(newTotalAmount);
    }
  }, [apiDataEdit]);
  
  //
  // formdata end---------------------------------------------------------------------
  useEffect(() => {
    console.log("typeof setIsClicked:", typeof setIsClicked); // Check if it's a function
    if (TaxFeesPopData) {
      console.log("purchase_price value:", TaxFeesPopData?.purchase_price); // Log the value

      setFormData((prevData) => ({
        ...prevData,
        vin: TaxFeesPopData.vin || "",
        year_make_model: TaxFeesPopData.year_make_model || "",
        trim: TaxFeesPopData.trim || "",
        make: TaxFeesPopData.make || "",
        year: TaxFeesPopData.year || "",
        model: TaxFeesPopData.model || "",
        bodyClass: TaxFeesPopData.bodyClass || "",
        wheelbase: TaxFeesPopData.wheelbase || "",
        driveType: TaxFeesPopData.driveType || "",
        doorCount: TaxFeesPopData.doorCount || "",
        engine: TaxFeesPopData.engine || "",
        transmission: TaxFeesPopData.transmission || "",
        vehicleType: TaxFeesPopData.vehicleType || "",
        seat_count: TaxFeesPopData.seat_count || "",
        city_mpg: TaxFeesPopData.city_mpg || "",
        highway_mpg: TaxFeesPopData.highway_mpg || "",
        inspection: TaxFeesPopData.inspection || "",
        exteriorColor: TaxFeesPopData.exteriorColor || "",
        interiorColor: TaxFeesPopData.interiorColor || "",
        interiorType: TaxFeesPopData.interiorType || "",
      }));

      setDisplayValues((prevState) => ({
        ...prevState,
        mileage_in: formatValue(TaxFeesPopData?.mileage_in?.toString() ?? ""),
        purchase_price: formatValue(
          TaxFeesPopData?.purchase_price?.toString() ?? ""
        ),
        listing_price: formatValue(
          TaxFeesPopData?.listing_price?.toString() ?? ""
        ),
      }));

      // if (TaxFeesPopData.purchase_price) {
      //   setIsClicked(true);
      //   setIsSpanVisibleTradeIn(true);
      // } else if (TaxFeesPopData.purchase_price === "") {
      //   setIsClicked(false);
      //   setIsSpanVisibleTradeIn(false);
      // }
    }
  }, [TaxFeesPopData]);
  //   for sold update data-------------------------------------------------------------------------
  useEffect(() => {
    if (dealerRetailDtata && status) {
      const yeraMakeModel = dealerRetailDtata?.year_make_model
        ?.replace(/\/#/g, "")
        .replace(/\//g, " ")
        .replace(/#/g, " ");
      setFormData((prevData) => ({
        ...prevData,
        vin: dealerRetailDtata.vin || "",
        year_make_model: yeraMakeModel || "",
        trim: dealerRetailDtata.trim || "",
        make: dealerRetailDtata.make || "",
        year: dealerRetailDtata.year || "",
        model: dealerRetailDtata.model || "",
        bodyClass: dealerRetailDtata.bodyClass || "",
        wheelbase: dealerRetailDtata.wheelbase || "",
        driveType: dealerRetailDtata.driveType || "",
        doorCount: dealerRetailDtata.doorCount || "",
        engine: dealerRetailDtata.engine || "",
        transmission: dealerRetailDtata.transmission || "",
        vehicleType: dealerRetailDtata.vehicleType || "",
        seat_count: dealerRetailDtata.seat_count || "",
        city_mpg: dealerRetailDtata.city_mpg || "",
        highway_mpg: dealerRetailDtata.highway_mpg || "",
        inspection: dealerRetailDtata.inspection || "",
        exteriorColor: dealerRetailDtata.exteriorColor || "",
        interiorColor: dealerRetailDtata.interiorColor || "",
        interiorType: dealerRetailDtata.interiorType || "",
      }));

      setDisplayValues((prevState) => ({
        ...prevState,
        mileage_in: formatValue(
          dealerRetailDtata?.mileage_in?.toString() ?? ""
        ),
        purchase_price: formatValue(
          dealerRetailDtata?.purchase_price?.toString() ?? ""
        ),
        listing_price: formatValue(
          dealerRetailDtata?.listing_price?.toString() ?? ""
        ),
      }));
    }
  }, [dealerRetailDtata]);
  // new popo input ------------------------------------------------
  const handleArrowClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    // Access vin from state
    const { vin } = location.state || {};
    console.log("VIN:", vin);
    if (vin) {
      // setAlertVisible(true);
      setFormData((prevData) => ({
        ...prevData,
        vin: vin,
      }));
      setInputVin(vin); // Initialize input value
    }
    console.log("8888888888888888888", location.state);
  }, [location.state]);

  const handleOptionSelect = (option) => {
    setSelectedOptionn(option);
    setFormData((prevState) => ({
      ...prevState,
      trim: option,
    }));
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // ----

  // -------
  // Function to handle clicking the arrow, shows the modal
  const handleArrowClickc = () => {
    setShowModalc(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelectc = (option) => {
    setSelectedOptionc(option);
    setFormData((prevState) => ({
      ...prevState,
      transmission: option,
    }));
    setShowModalc(false);
  };
  // Effect to call transmissiondecode whenever the transmission in formData changes
  // useEffect(() => {
  //   if (formData.transmission) {
  //     transmissiondecode();
  //   }
  // }, [formData.transmission]);

  // Function to handle closing the modal
  const handleCloseModalc = () => {
    setShowModalc(false);
  };
  // ---

  const [newOptionseats, setNewOptionseats] = useState("");

  const handleArrowClickd = () => {
    setShowModald(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelectd = (option) => {
    setSelectedOptiond(option);
    setFormData((prevState) => ({
      ...prevState,
      seat_count: option,
    }));
    setShowModald(false);
  };

  const handleCloseModald = () => {
    setShowModald(false);
  };

  // Handler for adding the new option
  const handleAddOptionseats = () => {
    if (newOptionseats.trim() !== "") {
      const newOptionObj = { value: newOptionseats, label: newOptionseats };
      setRadioOptionsd([...radioOptionsd, newOptionObj]);
      setNewOptionseats("");
      handleOptionSelectd(newOptionseats);
    }
  };

  //----
  const handleArrowClicke = () => {
    setShowModale(true);
  };

  const handleOptionSelecte = (option) => {
    setSelectedOptione(option);
    setFormData((prevState) => ({
      ...prevState,
      interiorType: option,
    }));
    setShowModale(false);
  };

  const handleCloseModale = () => {
    setShowModale(false);
  };

  const [newOptionExteriorColor, setNewOptionExteriorColor] = useState("");

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelecth = (option, color) => {
    setSelectedOptionh(option);
    setFormData((prevState) => ({
      ...prevState,
      exteriorColor: option,
    }));
    setExteriorColorShow(color);
    setShowModalh(false);
  };

  const handleCloseModalh = () => {
    setShowModalh(false);
  };

  // Handler for adding the new option
  const handleAddOptionExteriorColor = () => {
    if (newOptionExteriorColor.trim() !== "") {
      const newColor = "#CCCCCC"; // Default color for new entries
      const newOptionObj = {
        value: newOptionExteriorColor,
        label: newOptionExteriorColor,
        boxColor: newColor,
      };
      setRadioOptionsh([...radioOptionsh, newOptionObj]);
      setNewOptionExteriorColor("");
      handleOptionSelecth(newOptionExteriorColor, newColor);
    }
  };
  //----

  const [newOptionInteriorColor, setNewOptionInteriorColor] = useState("");

  const handleArrowClicki = () => {
    setShowModali(true);
  };

  // Function to handle selecting an option, sets the selected option, updates the form data, and closes the modal
  const handleOptionSelecti = (option, color) => {
    setSelectedOptioni(option);
    setFormData((prevState) => ({
      ...prevState,
      interiorColor: option,
    }));
    setInteriorColorShow(color);
    setShowModali(false);
  };
  const handleCloseModali = () => {
    setShowModali(false);
  };
  // // Handler for adding the new option
  // const handleAddOptionInteriorColor = () => {
  //   if (newOptionInteriorColor.trim() !== "") {
  //     const newOptionObj = { value: newOptionInteriorColor, label: newOptionInteriorColor };
  //     setRadioOptionsi([...radioOptionsi, newOptionObj]);
  //     setNewOptionInteriorColor("");
  //     handleOptionSelecti(newOptionInteriorColor);
  //   }
  // };

  // Handler for adding the new option
  const handleAddOptionInteriorColor = () => {
    if (newOptionInteriorColor.trim() !== "") {
      const newColor = "#CCCCCC"; // Default color for new entries
      const newOptionObj = {
        value: newOptionInteriorColor,
        label: newOptionInteriorColor,
        boxColor: newColor,
      };
      setRadioOptionsi([...radioOptionsi, newOptionObj]);
      setNewOptionInteriorColor("");
      handleOptionSelecti(newOptionInteriorColor, newColor);
    }
  };

  //----
  const handleArrowClickzb = () => {
    setShowModalzb(true);
  };
  // for calculator on amount in maintanance section---------------------
  const [showModalCalculator, setShowModalCalculator] = useState(false);
  const [showModalCalculatorListing, setShowModalCalculatorListing] =
    useState(false);
  const [showModalCalculatorPurchase, setShowModalCalculatorPurchase] =
    useState(false);
  const [showModalCalculatorMileage, setShowModalCalculatorMileage] =
    useState(false);
  const handleArrowClickCalculator = () => {
    setShowModalCalculator(true);
  };
  const handleArrowClickCalculatorListing = () => {
    setShowModalCalculatorListing(true);
  };
  const handleArrowClickCalculatorPurchase = () => {
    setShowModalCalculatorPurchase(true);
  };
  const handleArrowClickCalculatorMileage = () => {
    setShowModalCalculatorMileage(true);
  };

  const handleCloseModalCalculatorListing = () => {
    setShowModalCalculatorListing(false);
  };
  const handleCloseModalCalculatorPurchase = () => {
    setShowModalCalculatorPurchase(false);
  };
  const handleCloseModalCalculatorMileage = () => {
    setShowModalCalculatorMileage(false);
  };
  // --------------------------------------------------------------------
  // const handleOptionSelectzb = (option) => {
  //   setSelectedOptionzb(option);
  // };
  const handleOptionSelectzb = (option) => {
    dispatch(setIsSomethingChanged(true));
    setSelectedOptionzb(option);
    setFormData((prevState) => ({
      ...prevState,
      vehicleType: option,
    }));
    setShowModalzb(false);
  };

  const handleCloseModalzb = () => {
    setShowModalzb(false);
  };

  // const handleContinuezb = () => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     vehicle_type: selectedOptionzb,
  //   }));
  //   setShowModalzb(false);
  // };
  // title info for select image and pdf file-------------------------------------------------------------------------------------

  // for location------------------------------------------and ip-----****************************-----------------------------
  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const res = await fetchGeoMapData();

        // Update formData only after geoData has been successfully fetched and set
        setFormData((prevFormData) => ({
          ...prevFormData,
          lattitude: res.coordinates.lat,
          longitude: res.coordinates.lng,
        }));
      } catch (error) {
        console.log("Server side error", error);
      }
    };

    fetchUserLocation();
  }, []);

  // for ip address--------------------------------
  // Function to fetch IP address
  const getUserIPaddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log("User IP Address", res.data.ip);
      // Update formData with the IP address
      setFormData((prevFormData) => ({
        ...prevFormData,
        ip: res.data.ip,
      }));
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };
  // Fetch IP address on component mount
  useEffect(() => {
    getUserIPaddress();
  }, []);

  // end-------------------------*********************************--------------------------------------------------

  useEffect(() => {
    // Set the default purchase date to the current date
    const today = new Date().toISOString().split("T")[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      purchasedDate: today,
      inspection: today,
      price_fee_date: today,
    }));
  }, [counter]);

  useEffect(() => {
    // Set the default date to today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];
    setInputValueDate(today);
  }, [counter]);

  const chunkSize = 6;
  const objectChunks = [];
  for (let i = 0; i < objects.length || i < chunkSize; i += chunkSize) {
    objectChunks.push(objects.slice(i, i + chunkSize));
  }

  // for scroll bar-----------------------
  const handleKeyDown = (event) => {
    if (scrollContainerRef.current) {
      if (event.key === "ArrowDown") {
        scrollContainerRef.current.scrollTo({
          top: scrollContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      } else if (event.key === "ArrowUp") {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  // for tabs-----------------------------

  // -----------------------------------------------------------------------**********************************-------------
  // inspection data--------------------------------------------------
  // Function to handle inspection date change
  const handleChangeins = (e) => {
    const selectedDate = e.target.value;
    setInspection(selectedDate);
    setFormData((prevFormData) => ({
      ...prevFormData,
      inspection: selectedDate,
    }));
  };

  // Function to calculate days difference between current date and selected inspection date
  const calculateDaysDifference = () => {
    if (!formData.inspection) return 0; // Ensure formData.inspection is used for calculation
    const currentDate = new Date();
    const selectedDate = new Date(formData.inspection);
    const timeDifference = selectedDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
    );
    return daysDifference;
  };

  // --------------------------------******************************************************---------------------------------------------------
  const [masked, setMasked] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    // Reset masking state if formData changes
    if (masked) {
      setMasked(false);
    }
  }, [formData.purchase_price]);

  // floor fees---------------------------------------------------
  // total repare cost--------------
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      total_repair_cost: totalAmount.toFixed(2),
    }));
  }, [totalAmount]);

  // Calculate total
  const calculateTotal = () => {
    const { purchase_price, total_repair_cost, auction_fees, others } =
      formData;
    return (
      parseFloat(purchase_price || 0) +
      parseFloat(total_repair_cost || 0) +
      parseFloat(auction_fees || 0) +
      parseFloat(others || 0)
    ).toFixed(2);
  };

  const calculateProfit = () => {
    const listingPrice = parseFloat(formData.listing_price) || 0;
    const totalCost = parseFloat(formData.total_cost) || 0;
    return listingPrice - totalCost;
  };

  useEffect(() => {
    const newTotalCost = calculateTotal();
    setFormData((prevFormData) => ({
      ...prevFormData,
      total_cost: newTotalCost,
    }));
  }, [
    formData.purchase_price,
    formData.total_repair_cost,
    formData.auction_fees,
    formData.others,
  ]);

  useEffect(() => {
    const newProfitPrice = calculateProfit().toFixed(2);
    setFormData((prevFormData) => ({
      ...prevFormData,
      profit_price: newProfitPrice,
    }));
  }, [formData.listing_price, formData.total_cost]);
  // ---------------------------

  // Update formData whenever objects changes
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      repair_cost: objects,
      floor_fee: objectsFloor,
    }));
  }, [objects, objectsFloor]);

  console.log("line230", objectsFloor);
  // for decode the api data-------------------------------------------------------------------------------------------------------
  const handleChangedecode = async (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Check if the input has exactly 17 characters
    if (name === "vin" && value.length === 17) {
      await handleDecode(value); // Pass value directly to handleDecode
    } else if (value.length < 17) {
      setAlertVisible(false); // Set alertVisible to false if VIN is less than 17 characters
    }
  };

  // New function to handle key press events
  const handleKeyPress = async (event) => {
    const { name, value } = event.target;
    if (event.key === "Enter") {
      // Check if Enter key is pressed
      event.preventDefault(); // Prevent default Enter key behavior
      if (name === "vin" && value.length === 17) {
        await handleDecode(value);
      }
    }
  };

  const handleDecode = async (vinValue) => {
    try {
      const fd = new FormData();
      fd.append("vin", vinValue); // Use the passed vinValue
      fd.append("userId", dealerId);

      const response = await axios.post(`${apiUrl}/listing/get_vin_data`, fd);
      if (response.status === 200) {
        console.log("API Data:", response.data);
        setApiData(response.data[0]);
        setApiDatab(response.data[1]);
        setApiDatac(response.data[2]);
        setAlertVisible(false);
      }
    } catch (error) {
      if (error.response.status === 409) {
        // Handle retry logic for 409 Conflict status
        console.log(
          "API request failed with status 409. Making a GET request..."
        );
        setAlertVisible(true); // Show the alert message
      } else if (error.response.status === 401) {
        console.log("API request failed with status 401");
        setErrorMessage(error.response.data);
      } else if (error.response) {
        console.log("Error:", error.response.data);
        setErrorMessage(error.response.data);
      } else if (error.request) {
        console.log("Error:", error.request);
      } else {
        console.log("Error:", error.message);
      }
    }
  };

  // NM0LS7F79G1269247
  const handleRetry = async () => {
    //alert("Hello", formData.vin);

    setAlertVisible(false); // Hide the alert after confirming

    const retryUrl = `${apiUrl}/listing/get_vin/${formData.vin}/${id}`;
    try {
      const retryResponse = await axios.get(retryUrl);
      if (retryResponse.status === 200) {
        //alert("Hello1");
        console.log("Retry API Data:", retryResponse.data);
        setApiDataEdit(retryResponse.data[0]);
        setBtnTxt("Update");
      } else {
        // alert("Hello3");
        console.log(`Retry request failed with status ${retryResponse.status}`);
        setErrorMessage(retryResponse.data);
      }
    } catch (retryError) {
      console.log("Retry request failed with error:", retryError);
      setErrorMessage(retryError.message);
    }
  };

  const handleCancel = () => {
    setAlertVisible(false); // Hide the alert
    setFormData({ vin: "" }); // Clear the input field
  };
  useEffect(() => {
    console.log("apiData:", apiData); // Debugging log to check apiData structure
    if (apiData) {
      const newEmptyFields = {
        year_make_model: !(
          apiData?.ModelYear &&
          apiData?.Make &&
          apiData?.Model
        ),
        year: apiData?.ModelYear,
        make: apiData?.Make,
        model: apiData?.Model,
        engine: !apiData?.WheelBaseShort,
        exteriorColor: !apiData?.ExteriorColor,
        interiorColor: !apiData?.InteriorColor,
        odo: !apiData?.ODO,
        owner: !apiData?.Owner,
        mileage_in: !apiData?.Miles,
        title_status: !apiData?.TitleProblem,
        // stock_no: !apiData?.stock_number,
        vehicleType: !apiData?.VehicleType,
        interiorType: !apiData?.interiorType,
        fuel_type: !apiData?.FuelTypePrimary,
        fuel_type_secondary: !apiData?.FuelTypeSecondary,
        engine_cylinders: !apiData?.EngineCylinders,
        transmission: !(
          apiData?.TransmissionStyle && apiData?.TransmissionSpeeds
        ),
        engine_size: !apiData?.DisplacementL,
        engine_type: !apiData?.EngineConfiguration,
        drive_train: !apiData?.DriveType,
        door_count: !apiData?.Doors,
        battery_type: !apiData?.BatteryType,
        battery_kwh: !apiData?.BatteryKWh,
        other_restraint: !(
          apiData?.OtherRestraintSystemInfo && apiData?.AirBagLocFront
        ),
        trim: !(apiData?.Trim && apiData?.Series),
        seat_count: !apiData?.Seats,
        horse_power: !apiData?.DisplacementCI,
        city_mpg: !apiData?.city_mpg,
        highway_mpg: !apiData?.highway_mpg,
      };
      // Set the isSeatsPresent state based on the seat_count field
      setIsSeatsPresent(!!apiData?.Seats);

      setEmptyFields(newEmptyFields);

      const combinedYearMakeModel = `${apiData?.ModelYear || ""} ${
        apiData?.Make || ""
      } ${apiData?.Model || ""} ${
        apiData?.BodyClass === "Cargo Van" || "Van" || "Bus"
          ? apiData?.WheelBaseShort
            ? `WB${apiData?.WheelBaseShort}`
            : ""
          : apiData?.BodyClass || ""
      } ${apiData?.DriveType || ""} ${
        apiData?.Doors ? `${apiData?.Doors}D` : ""
      }`;
      const combinedBodyClassSeriesWheelBase = `${
        apiData?.FuelTypePrimary === "Electric"
          ? `${apiData?.FuelTypePrimary} ${
              apiData?.BatteryKWh ? `${apiData?.BatteryKWh}Kwh` : ""
            } ${apiData?.BatteryType || ""}`
          : `${
              apiData?.EngineCylinders ? `${apiData?.EngineCylinders}CYL` : ""
            } ${apiData?.DisplacementL ? `${apiData?.DisplacementL}L` : ""} ${
              apiData?.EngineConfiguration || ""
            } ${apiData?.FuelTypePrimary || ""} ${
              apiData?.FuelTypeSecondary ? `/${apiData?.FuelTypeSecondary}` : ""
            }`
      }`;
      // const transTransSpeed = `${apiData?.TransmissionStyle || ""} ${

      //   apiData?.TransmissionSpeeds
      //     ? `${apiData?.TransmissionSpeeds} Speed`
      //     : ""
      // }`;
      // Here is the change in the code for transTransSpeed
      const transTransSpeed = `${
        apiData?.city_mpg && apiData?.highway_mpg
          ? `${apiDatac || ""} ${
              apiData?.TransmissionSpeeds
                ? `${apiData?.TransmissionSpeeds} Speed`
                : ""
            }`
          : `${apiData?.TransmissionStyle || ""}   ${
              apiData?.TransmissionSpeeds
                ? `${apiData?.TransmissionSpeeds} - Speed`
                : ""
            }`
      }`;

      const otherRestrain = `${apiData?.OtherRestraintSystemInfo || ""} ${
        apiData?.AirBagLocFront || ""
      }`;
      const trimSeries = `${apiData?.Trim || ""} ${apiData?.Series || ""}`;
      // Set the isTransTransSpeedPresent state based on the transTransSpeed field
      setIsTransmissionPresent(!!transTransSpeed);
      setFormData((prevFormData) => ({
        ...prevFormData,
        year_make_model: combinedYearMakeModel || "",
        engine: combinedBodyClassSeriesWheelBase || "",
        vehicleType: apiData?.VehicleType || "",
        interiorType: apiData?.interiorType || "",
        // stock_no: apiData?.stock_number || "",
        // only send for split purpose--
        make: apiData?.Make || "",
        year: apiData?.ModelYear || "",
        model: apiData?.Model || "",
        bodyClass: apiData?.BodyClass || "",
        wheelbase: apiData?.WheelBaseShort || "",
        driveType: apiData?.DriveType || "",
        doorCount: apiData?.Doors || "",
        // -------------------------
        exteriorColor: apiData?.ExteriorColor || "",
        interiorColor: apiData?.InteriorColor || "",
        odo: apiData?.ODO || "",
        owner: apiData?.Owner || "",
        mileage_in: apiData?.Miles || "",
        title_status: apiData?.TitleProblem || "",
        transmission: transTransSpeed || "",
        drive_train: apiData?.DriveType || "",
        door_count: apiData?.Doors || "",
        battery_type: apiData?.BatteryType || "",
        battery_kwh: apiData?.BatteryKWh || "",
        other_restraint: otherRestrain || "",
        trim: trimSeries || "",
        transmission: apiData?.TransmissionStyle || "",
        seat_count: apiData?.Seats || "",
        horse_power: apiData?.DisplacementCI || "",
        city_mpg: apiData?.city_mpg || "",
        highway_mpg: apiData?.highway_mpg || "",
        driveType: apiData?.DriveType || "",
        fuelType: apiData?.FuelTypePrimary || "",
        fuelType2: apiData?.FuelTypeSecondary || "",
        engineCylinder: apiData?.EngineCylinders || "",
        //
        engineType: apiData?.EngineModel || "",
        engineShape: apiData?.EngineConfiguration || "",

        engineSize: `${apiData?.DisplacementL}L` || "",
        doors: apiData?.Doors || "",
      }));

      // Set year, make, and model states
      setYear(apiData?.ModelYear);
      setMake(apiData?.Make);
      setModel(apiData?.Model);
    }
  }, [apiData]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    dispatch(setIsSomethingChanged(true));
  };
  const handleChangeTradeIn = (e, tradeInValueType) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [tradeInValueType]: value,
    }));
    // if (tradeInValueType === "tradeInAllowance") {
    //   const valueAfterTradeIn =
    //     selectedVehicle.listing && selectedVehicle.listing.price - value;
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [tradeInValueType]: valueAfterTradeIn,
    //   }));
    // } else {}
  };
console.log("formdata23432",formData);
  const handleChangeb = (e) => {
    const { name, value } = e.target;

    // Remove any non-numeric characters (except for the decimal point)
    const numericValue = value.replace(/[^\d.]/g, "");

    // Check if the value matches the numeric pattern
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(numericValue)) {
      // Convert the numeric value to a number
      const numericValueAsNumber =
        numericValue === "" ? 0.0 : parseFloat(numericValue);

      // Update the form data with the numeric value (without commas)
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValueAsNumber,
      }));

      // Update the display value with commas
      const formattedValue = formatValue(numericValue);
      setDisplayValues((prevDisplayValues) => ({
        ...prevDisplayValues,
        [name]: formattedValue,
      }));
    }
  };

  const formatValue = (value) => {
    // Convert the value to a string if it's not already
    value = value.toString();

    // Split the value into whole and decimal parts
    const [whole, decimal] = value.split(".");

    // Add commas after every 3 digits for display
    let formattedValue = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (decimal !== undefined) {
      formattedValue += "." + decimal;
    }

    return formattedValue;
  };
  const handleResetVehicleSpecfication = () => {
    setFormData({
      year_make_model: "",
      vin: "",
      trim: "",
      engine: "",
      transmission: "",
      vehicleType: "",
      seat_count: "",
      city_mpg: "",
      highway_mpg: "",
      exteriorColor: "",
      interiorColor: "",
      interiorType: "",
      other_restraint: "",
    });

    setDisplayValues({
      mileage_in: "",
      purchase_price: "",
      listing_price: "",
    });
    setShowModal(false);
    setShowModalc(false);
    setShowModalzb(false);
    setShowModald(false);
    setShowModalh(false);
    setShowModali(false);
    setShowModale(false);
  };
  // Call the parent's onReset function when needed
  React.useEffect(() => {
    if (onReset) onReset(handleResetVehicleSpecfication);
  }, [onReset]);
  // for new dropdown end------------------------***************-----------------------------------------------------------
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [modalOptionContent, setModalOptionContent] = useState("");
  const handleModalContentSelection = (boxContentToShow) => {
    setModalOptionContent(boxContentToShow);
    setShowOptionsModal(true);
  };
  // for calculation of trade-in-----------------------------------------------------------------------------------------------------
  console.log("formdataValue purchase_price tradeInPay netTradeIn",formData.purchase_price,formData.tradeInPay, formData.netTradeIn);
  console.log("sellingPriceVehicle",sellingPriceVehicle);
  const trade_in_pay = parseFloat(formData.tradeInPay || "0");
  const purchsePrice = parseFloat(formData.purchase_price || "0");
  const cashDifference = sellingPriceVehicle + trade_in_pay - purchsePrice;
  console.log("cashDifference",cashDifference);
  useEffect(()=>{
    if (purchsePrice > 0) {
      setFormData((prevFormData) => ({ 
        ...prevFormData, 
        netTradeIn: cashDifference 
      }));
      }else if(purchsePrice == 0){
        setFormData((prevFormData) => ({
          ...prevFormData,
          netTradeIn: 0
          }));
          }
  },[cashDifference]);
  //  data save to redux--------------------------------------------------------------------------------------------------------------------
  console.log("TradeInComponent Data 850", formData);
  const SavedataRedux = () => {
    dispatch(setIsSomethingChanged(true));
    dispatch(
      setRetailData({
        id: 0,
        dealerId: formData.id,
        vin: formData.vin,
        year_make_model: formData.year_make_model,
        trim: formData.trim,
        // maek year modal for check purpose only------
        make: formData.make,
        year: formData.year,
        model: formData.model,
        bodyClass: formData.bodyClass,
        wheelbase: formData.wheelbase,
        driveType: formData.driveType,
        doorCount: formData.doorCount,
        //  for trade in calculation----------------------------
        purchase_price: purchsePrice,
        ownedTrade:trade_in_pay ,
        cashDifference:cashDifference,
        // ----------------------------------------
        engine: formData.engine,
        transmission: formData.transmission,
        vehicleType: formData.vehicleType,
        seat_count: formData.seat_count,
        city_mpg: formData.city_mpg,
        highway_mpg: formData.highway_mpg,
        inspection: formData.inspection,
        exteriorColor: formData.exteriorColor,
        interiorColor: formData.interiorColor,
        interiorType: formData.interiorType,
        mileage_in: formData.mileage_in,
        //tradeInValue: formData.tradeInValue,
        listing_price: formData.listing_price,
        // new key for trade in---------------------
        doors: formData.doors,
        engineCylinder: formData.engineCylinder,
        engineType: formData.engineType,
        engineSize: formData.engineSize,
        engineShape: formData.engineShape,
        fuelType : formData.fuelType,
        fuelType2: formData.fuelType2,
      })
    );
    handleClose();
    onButtonClick();
    // handleResetVehicleSpecfication();
    setIsSpanVisibleTradeIn(true);
  };
  return (
    <>
      {/* --------------------------------------------------------------------------- */}
      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ticModalheader">Trade-In Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="ticModalBodyWrapper">
          <div
            className="border-0 ticMobiledesignWrapper"
            style={{ border: "none" }}
          >
            <div className="ticParentWrapper">
              <div className="ticLeftWrapper">
                {/* Vin Wrapper */}
                <div className="ticVinWrapper">
                  <div className=" ticVinInputContainer">
                    <button type="button">VIN</button>
                    <input
                      type="text"
                      placeholder="Please Enter 17 character.."
                      className=""
                      name="vin"
                      value={formData.vin}
                      maxLength={17}
                      onChange={handleChangedecode}
                      onKeyDown={handleKeyPress} // Add this line
                      style={{ textTransform: "uppercase" }}
                    />
                  </div>

                  <img
                    src={Image.reset_button}
                    alt="Reset Button"
                    onClick={handleResetVehicleSpecfication}
                  />
                </div>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                {/* Year make Model Wrapper */}
                <div className="ticYearMakeModelWrapper">
                  {/* Year */}
                  <div className="ticYearWrapper">
                    <p>
                      <span>Year</span>
                    </p>
                    <input
                      type="text"
                      name="year"
                      value={formData.year}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* Make */}
                  <div className="ticMakeWrapper">
                    <p>
                      <span>Make</span>
                    </p>
                    <input
                      type="text"
                      name="make"
                      value={formData.make}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* Model */}
                  <div className="ticModelWrapper">
                    <p>
                      <span>Model</span>
                    </p>
                    <input
                      type="text"
                      name="model"
                      value={formData.model}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>

                {alertVisible && (
                  <div className="col-md-12 popup-vin mt-2">
                    <p
                      style={{ color: "red", cursor: "pointer" }}
                      // onClick={handleCancel}
                    >
                      Already exists want to edit?
                      {/* <GiClick /> */}
                    </p>
                    <button
                      className="btn btn-secondary"
                      onClick={handleCancel}
                      style={{ marginLeft: "25px" }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-danger mr-2"
                      onClick={handleRetry}
                      style={{ marginLeft: "25px" }}
                    >
                      Yes
                    </button>
                  </div>
                )}

                {/* Trim Body Class Transmission */}
                <div className="ticTrimBodyTypeTransmissionWrapper">
                  {/* Trim  */}
                  <div className="ticTrimWrapper">
                    <p>
                      <span>Trim</span>
                    </p>
                    <select
                      name="trim"
                      value={formData.trim}
                      onChange={handleChange}
                    >
                      {apiDatab &&
                        apiDatab.map((opt, index) => (
                          <option key={index} value={opt}>
                            {opt}
                          </option>
                        ))}
                    </select>
                  </div>
                  {/* Body Class */}
                  <div className="ticVehicleTypeWrapper">
                    <p>
                      <span>Body Style</span>
                    </p>
                    <select
                      name="bodyClass"
                      value={formData.bodyClass}
                      onChange={handleChange}
                    >
                      <option value={formData.bodyClass}>
                        {formData.bodyClass}
                      </option>
                    </select>
                  </div>
                  {/* Transmission */}
                  <div className="ticTransmissionWrapper">
                    <p>
                      <span>Transmission</span>
                    </p>
                    <select
                      name="transmission"
                      value={formData.transmission}
                      onChange={handleChange}
                    >
                      {apiDatac &&
                        apiDatac.map((opt, index) => (
                          <option key={index} value={opt}>
                            {opt}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {/* Drive Type ,Fuel Type ,Engine Cylinder */}
                <div className="ticDriveFuelEngineWrapper">
                  {/* Drive Type */}
                  <div className="ticDriveTypeWrapper">
                    <p>
                      <span>Drive Type</span>
                    </p>
                    <input
                      type="text"
                      name="driveType"
                      value={formData.driveType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* Fuel Type */}
                  <div className="ticFuelTypeWrapper">
                    <p>
                      <span>Vehicle Type</span>
                    </p>
                    <input
                      type="text"
                      name="vehicleType"
                      value={formData.vehicleType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* Engine Cylinder */}
                  <div className="ticEngineCylWrapper">
                    <p>
                      <span>Engine Cyliner</span>
                    </p>
                    <input
                      type="text"
                      name="engineCylinder"
                      value={formData.engineCylinder}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* Engine Type ,Engine Size , Doors */}
                <div className="ticEngineTypeSizeDoors">
                  {/* Engine Type */}
                  <div className="ticDriveTypeWrapper">
                    <p>
                      <span>Engine Type</span>
                    </p>
                    <input
                      type="text"
                      name="engineType"
                      value={formData.engineType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* Engine Size */}
                  <div className="ticFuelTypeWrapper">
                    <p>
                      <span>Engine Size</span>
                    </p>
                    <input
                      type="text"
                      name="engineSize"
                      value={formData.engineSize}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/*  Doors */}
                  <div className="ticEngineCylWrapper">
                    <p>
                      <span>Engine Shape</span>
                    </p>
                    <input
                      type="text"
                      name="engineShape"
                      value={formData.engineShape}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* Seat , City MPG , Hwy MPG */}
                <div className="ticSeatCityHwyMPGWrapper">
                  {/* Seat */}
                  <div className="ticSeatWrapper">
                    <p>
                      <span>Fuel Type</span>
                    </p>
                    <input
                      type="text"
                      name="fuelType"
                      value={formData.fuelType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* City MPG */}
                  <div className="ticCityMPGWrapper">
                    <p>
                      <span>Fuel Type2</span>
                    </p>
                    <input
                      type="text"
                      name="fuelType2"
                      value={formData.fuelType2}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/*  Hwy MPG */}
                  <div className="ticHwyMPGWrapper">
                    <p>
                      <span>Doors</span>
                    </p>
                    <input
                      type="text"
                      name="doors"
                      value={formData.doors}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* Exterior Color , Interior Color , Interior Type */}
                <div className="ticExtIntColorType">
                  {/* Exterior Color */}
                  <div className="ticExtColorWrapper">
                    <p>
                      <span>Exterior Color</span>
                    </p>
                    <select
                      name="exteriorColor"
                      value={formData.exteriorColor}
                      onChange={handleChange}
                    >
                      {radioOptionsi.map((color) => (
                        <option key={color.boxColor} value={color.value}>
                          {color.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Interior Color */}
                  <div className="ticIntColorWrapper">
                    <p>
                      <span>Interior Color</span>
                    </p>
                    <select
                      name="interiorColor"
                      value={formData.interiorColor}
                      onChange={handleChange}
                    >
                      {radioOptionsi.map((color) => (
                        <option key={color.boxColor} value={color.value}>
                          {color.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/*  Interior Type */}
                  <div className="ticIntTypeWrapper">
                    <p>
                      <span>Interior Type</span>
                    </p>
                    <input
                      type="text"
                      name="interiorType"
                      value={formData.interiorType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* Mileage In , Inspection Date */}
                <div className="ticMileageInspectionWrapper">
                  {/* Mileage In */}
                  <div className="ticMileageContainer">
                    <p>
                      <span>
                        <PiSpeedometerThin />
                      </span>
                      <span>Mileage</span>
                    </p>
                    <input
                      type="text"
                      name="mileage_in"
                      value={formData.mileage_in}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* Inspection Date */}
                  <div className="ticInspectionDateWrapper">
                    <p>
                      <span>Inspection Date</span>
                    </p>
                    <input
                      type="date"
                      name="inspection"
                      value={formData.inspection}
                      onChange={handleChangeins}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* Trade In Value, Actual Value */}
                <div className="ticTradeInActualValueWrapper">
                  {/* Mileage In */}
                  <div className="ticTradeInValueWrapper">
                    <p>
                      <span>*Trade-In Allowance</span>
                    </p>
                    <input
                      type="text"
                      name="purchase_price"
                      value={formData.purchase_price}
                      placeholder="0"
                      onChange={(e) =>
                        handleChangeTradeIn(e, "purchase_price")
                      }
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* Actual Value */}
                  <div className="ticActualValueWrapper">
                    <p>
                      <span>Trade-In Payoff</span>
                    </p>
                    <input
                      type="text"
                      name="tradeInPay"
                      value={formData.tradeInPay}
                      onChange={(e) =>
                        handleChangeTradeIn(e, "tradeInPay")
                      }
                      placeholder="0"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="ticActualValueWrapper">
                    <p>
                      <span>Net after Trade-In</span>
                    </p>
                    <input
                      type="text"
                      name="netTradeIn"
                      value={formData.netTradeIn}
                      onChange={(e) =>
                        handleChangeTradeIn(e, "netTradeIn")
                      }
                      placeholder="0"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*------------------------------- new trade-In formdata------------------------------ */}
          <div className="ticDesktopDesignWrapper">
            <div className="ticDesktopDesignContainer">
              <div className="ticDesktopVinWrapper">
                <div className="ticDesktopVinContainer">
                  <span>VIN</span>
                  <input
                    type="text"
                    placeholder="Please Enter 17 character.."
                    className=""
                    name="vin"
                    value={formData.vin}
                    maxLength={17}
                    onChange={handleChangedecode}
                    onKeyDown={handleKeyPress} // Add this line
                    style={{ textTransform: "uppercase" }}
                  />
                </div>
                <img
                  src={Image.reset_button}
                  alt="Reset Button"
                  onClick={handleResetVehicleSpecfication}
                />
              </div>
              {/* Year, Make, Model, Trim , Body style wrapper */}
              <div className="ticDesktopRow1Wrapper">
                {/* Year */}
                <div className="SimpleInputContainer">
                  <span>Year</span>
                  <input
                    type="text"
                    name="year"
                    value={formData.year}
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {/* Make */}
                <div className="SimpleInputContainer">
                  <span>Make</span>
                  <input
                    type="text"
                    name="make"
                    value={formData.make}
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {/* Model */}
                <div className="SimpleInputContainer">
                  <span>Model</span>
                  <input
                    type="text"
                    name="model"
                    value={formData.model}
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {/* Trim */}
                <div className="ArrowInputContainer">
                  <span>Trim</span>
                  <div>
                    <input
                      type="text"
                      name="trim"
                      value={formData.trim}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span onClick={() => handleModalContentSelection("trim")}>
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Body Style */}
                <div className="ArrowInputContainer">
                  <span>Body Style</span>
                  <div>
                    <input
                      type="text"
                      name="bodyClass"
                      value={formData.bodyClass}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() => handleModalContentSelection("bodyClass")}
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
              </div>
              {/* Door Count, Transmission, Drive Type, Vehicle Type, Engine Cylinder */}
              <div className="ticDesktopRow1Wrapper">
                {/* Door Count */}
                <div className="ArrowInputContainer">
                  <span>Door Count</span>
                  <div>
                    <input
                      type="text"
                      name="doors"
                      value={formData.doors}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span onClick={() => handleModalContentSelection("doors")}>
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Transmission */}
                <div className="ArrowInputContainer">
                  <span>Transmission</span>
                  <div>
                    <input
                      type="text"
                      name="transmission"
                      value={formData.transmission}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() =>
                        handleModalContentSelection("transmission")
                      }
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Drive Type */}
                <div className="ArrowInputContainer">
                  <span>Drive Type</span>
                  <div>
                    <input
                      type="text"
                      name="driveType"
                      value={formData.driveType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() => handleModalContentSelection("driveType")}
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Vehicle Type */}
                <div className="ArrowInputContainer">
                  <span>Vehicle Type</span>
                  <div>
                    <input
                      type="text"
                      name="vehicleType"
                      value={formData.vehicleType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() => handleModalContentSelection("vehicleType")}
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Engine Cylinder */}
                <div className="SimpleInputContainer">
                  <span>Engine Cylinder</span>
                  <input
                    type="text"
                    name="engineCylinder"
                    value={formData.engineCylinder}
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              {/* Engine Type, Engine Size(L), Engine Shape, fuel Type 1, fuel Type 2 */}
              <div className="ticDesktopRow1Wrapper">
                {/* Engine Type */}
                <div className="SimpleInputContainer">
                  <span>Engine Type</span>
                  <input
                    type="text"
                    name="engineType"
                    value={formData.engineType}
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {/* Engine Size(L) */}
                <div className="ArrowInputContainer">
                  <span>Engine Size(L)</span>
                  <div>
                    <input
                      type="text"
                      name="engineSize"
                      value={formData.engineSize}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() => handleModalContentSelection("engineSize")}
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Engine Shape */}
                <div className="ArrowInputContainer">
                  <span>Engine Shape</span>
                  <div>
                    <input
                      type="text"
                      name="engineShape"
                      value={formData.engineShape}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() => handleModalContentSelection("engineShape")}
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Fuel Type 1 */}
                <div className="ArrowInputContainer">
                  <span>Fuel Type 1</span>
                  <div>
                    <input
                      type="text"
                      name="fuelType"
                      value={formData.fuelType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() => handleModalContentSelection("fuelType")}
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Fuel Type 2 */}
                <div className="ArrowInputContainer">
                  <span>Fuel Type 2</span>
                  <div>
                    <input
                      type="text"
                      name="fuelType2"
                      value={formData.fuelType2}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() => handleModalContentSelection("fuelType2")}
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
              </div>
              {/* Exterior Color, Interior Color, Interior Type, Mileage, Inspection Date */}
              <div className="ticDesktopRow1Wrapper">
                {/* Exterior Color */}
                <div className="ArrowInputContainer">
                  <span>Exterior Color</span>
                  <div>
                    <input
                      type="text"
                      name="exteriorColor"
                      value={formData.exteriorColor}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() =>
                        handleModalContentSelection("exteriorColor")
                      }
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Interior Color */}
                <div className="ArrowInputContainer">
                  <span>Interior Color</span>
                  <div>
                    <input
                      type="text"
                      name="interiorColor"
                      value={formData.interiorColor}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() =>
                        handleModalContentSelection("interiorColor")
                      }
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Interior Type */}
                <div className="ArrowInputContainer">
                  <span>Interior Type</span>
                  <div>
                    <input
                      type="text"
                      name="interiorType"
                      value={formData.interiorType}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span
                      onClick={() =>
                        handleModalContentSelection("interiorType")
                      }
                    >
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Mileage */}
                <div className="ArrowInputContainer">
                  <span>Mileage</span>
                  <div>
                    <input
                      type="text"
                      name="mileage_in"
                      value={formData.mileage_in}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span>
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Inspection Date */}
                <div className="SimpleInputContainer">
                  <span>Inspection Date</span>
                  <input
                    type="date"
                    name="inspection"
                    value={formData.inspection}
                    onChange={handleChangeins}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              {/* Trade In Allowance, Trade-In Pay, Net Trade-In */}
              <div className="ticDesktopRow1Wrapper">
                {/* Trade In Allowance */}
                <div className="ArrowInputContainer">
                  <span>*Trade In Allowance</span>
                  <div>
                    <input
                      type="text"
                      name="purchase_price"
                      value={formData.purchase_price}
                      onChange={(e) =>
                        handleChangeTradeIn(e, "purchase_price")
                      }
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span>
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/*Trade-In Pay */}
                <div className="ArrowInputContainer">
                  <span>Trade-In Payoff</span>
                  <div>
                    <input
                      type="text"
                      name="tradeInPay"
                      value={formData.tradeInPay}
                      onChange={(e) =>
                        handleChangeTradeIn(e, "tradeInPay")
                      }
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span>
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
                {/* Net Trade-In */}
                <div className="ArrowInputContainer">
                  <span>Net after Trade-In</span>
                  <div>
                    <input
                      type="text"
                      name="netTradeIn"
                      value={formData.netTradeIn}
                      onChange={(e) =>
                        handleChangeTradeIn(e, "netTradeIn")
                      }
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span>
                      <TbArrowRight />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "inline-block",
              float: "right",
              marginTop: "4%",
            }}
          >
            <div className="loading">
              {loading ? (
                <span>
                  {uploadedCount} of {totalCount}
                </span>
              ) : null}
            </div>
            <button
              type="button"
              id="btn-save"
              className="btn-change4"
              onClick={SavedataRedux}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showOptionsModal} onHide={() => setShowOptionsModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="ticModalOptionWrapper">
            {modalOptionContent &&
              modalOptionContent !== "trim" &&
              modalOptionContent !== "transmission" &&
              TradeInOptions[modalOptionContent].map((item, i) => (
                <div key={i} className="ticModalOptionContainer">
                  <input
                    type="radio"
                    id={item}
                    onChange={() => {
                      setFormData((prevData) => ({
                        ...prevData,
                        [modalOptionContent]: item,
                      }));
                      setShowOptionsModal(false);
                    }}
                  />
                  {(modalOptionContent === "exteriorColor" ||
                    modalOptionContent === "interiorColor") && (
                    <span style={{ backgroundColor: item }}></span>
                  )}
                  <label for={item}>{item}</label>
                </div>
              ))}
            {modalOptionContent === "trim" &&
              (apiDatab && apiDatab.length > 0
                ? apiDatab
                : TradeInOptions["trim"]
              ).map((item, i) => (
                <div key={i} className="ticModalOptionContainer">
                  <input
                    type="radio"
                    id={item}
                    onChange={() => {
                      setFormData((prevData) => ({
                        ...prevData,
                        [modalOptionContent]: item,
                      }));
                      setShowOptionsModal(false);
                    }}
                  />

                  <label for={item}>{item}</label>
                </div>
              ))}
            {modalOptionContent === "transmission" &&
              (apiDatac && apiDatac.length > 0
                ? apiDatac
                : TradeInOptions["transmission"]
              ).map((item, i) => (
                <div key={i} className="ticModalOptionContainer">
                  <input
                    type="radio"
                    id={item}
                    onChange={() => {
                      setFormData((prevData) => ({
                        ...prevData,
                        [modalOptionContent]: item,
                      }));
                      setShowOptionsModal(false);
                    }}
                  />

                  <label for={item}>{item}</label>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default TradeInComponent;
