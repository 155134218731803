import React, { useEffect, useState } from "react";
import styles from "./SoldPreviewPaymentHistory.module.css";
import soldTransactions from "./SoldPreviewPaymentHistoryData";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ReceiptPDFRender from "../PaymentDueData/components/ReceiptPDFRender";

const SoldPreviewPaymentHistory = ({ PaymentData }) => {
  console.log("PaymentData 123", PaymentData);
  const emptyRowsArray = new Array(1).fill(null);
  const emptyColsArray = new Array(10).fill(null);
  const [apiData, setApiData] = useState([]);
  console.log("apiData 123", apiData);
  const handleGetDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sell/get_paymenthistory/${PaymentData.userId}/${PaymentData.id}`
      );
      // console.log("Response from the data => ", response);
      if (response.status === 200) {
        console.log("Response from the data => ", response);
        setApiData(response.data);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  useEffect(() => {
    handleGetDetails();
  }, []);
  const [arrayCheckIDToSend, setArrayCheckIDToSend] = useState([]);
  console.log("arrayCheckIDToSend => ", arrayCheckIDToSend);
  const handleCheckBoxesToSend = (idToAdd) => {
    if (arrayCheckIDToSend.includes(idToAdd)) {
      const updatedArray = arrayCheckIDToSend.filter(
        (item) => item !== idToAdd
      );
      setArrayCheckIDToSend(updatedArray);
    } else {
      setArrayCheckIDToSend([...arrayCheckIDToSend, idToAdd]);
    }
  };
  const [pdfArray, setPdfArray] = useState([]);
  const [showPdfArray, setShowPdfArray] = useState(false);
  const handlePrintfetch = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sell/view_receipt/${PaymentData.userId}`,
        arrayCheckIDToSend
      );
      if (response.status === 200) {
        console.log("Response from PDF API => ", response);
        setPdfArray(response.data);
        setShowPdfArray(true);
      }
    } catch (error) {
      console.log("Error while response from API => ", error);
    }
  };
  return (
    <div className={styles.soldPaymentTableWrapper}>
      <p className={styles.soldPaymentPrintDeleteContainer}>
        <button onClick={handlePrintfetch}>Print</button>
      </p>
      <table className={styles.soldPaymentTable}>
        <thead className={styles.soldPaymentTableHeader}>
          <tr>
            <th></th>
            <th>No#</th>
            <th>Transaction Date</th>
            <th>Current Balance</th>
            <th>Late Payment Fees</th>
            <th>Payment</th>
            <th>Payment Method</th>
            <th>Next Payment Due</th>
            <th>Remaining Balance</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {apiData.map((item, index) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  checked={
                    arrayCheckIDToSend && arrayCheckIDToSend.includes(item.id)
                      ? true
                      : false
                  }
                  onChange={() => handleCheckBoxesToSend(item.id)}
                />
              </td>
              <td>{index}</td>
              <td>{item.paymentDate || "NA"}</td>
              <td>{item.balanceDue.toFixed(2)}</td>
              <td>{(index !== 0 && `$ ${item.latePaymentFees}`) || ""}</td>
              <td
                style={{
                  backgroundColor: index === apiData.length - 1 && "#b8d3b3",
                }}
              >
                {`$ ${item.payment}` || ""}
              </td>
              <td>{(index !== 0 && item.paymentMethod) || ""}</td>
              <td>{(index !== 0 && item.nextPaymentDate) || ""}</td>
              <td
                style={{
                  backgroundColor: index === apiData.length - 1 && "#fc9090",
                }}
              >
                {(item.dueBalance && `$ ${item.dueBalance?.toFixed(2)}`) ||
                  "Paid Off"}
              </td>
              <td>{(index !== 0 && `${item.notes}`) || ""}</td>
            </tr>
          ))}
          {/* Empty rows */}
          {emptyRowsArray.map((_, i) => (
            <tr key={i} className={styles.soldEmptyRow}>
              {emptyColsArray.map((_, i) => (
                <td key={i}>hello</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <p className={styles.paymentTableFooter}>
        <span>
          <span>Total Transactions : </span>
          <span>{apiData && apiData?.length}</span>
        </span>
        <span>
          <span>Total Payments : </span>
          <span>
            ${" "}
            {apiData &&
              apiData.reduce((acc, current) => {
                return acc + current.payment;
              }, 0)}
          </span>
        </span>
      </p>
      <p className={styles.saveBtnContainer}>
        <label htmlFor="paymentTracker">
          <input type="checkbox" name="" id="paymentTracker" />
          Payment Tracker
        </label>
        <button>Save</button>
      </p>
      <Modal
        show={showPdfArray}
        onHide={() => setShowPdfArray(false)}
        size="xl"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReceiptPDFRender pdfArray={pdfArray && pdfArray} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SoldPreviewPaymentHistory;
