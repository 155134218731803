import React from "react";
import { Modal } from "react-bootstrap";
import styles from "../styles/LoginComponent.module.css";

const UserQueryAuthModel = ({
  showQueryModel,
  setShowQueryModel,
  setUserType,
  setUserData,
  inputEmail,
  handleCreateResult,
}) => {
  const handleUserType = async (userSelected) => {
    setUserType(userSelected);
    setShowQueryModel(false);
    setUserData((prevData) => ({
      ...prevData,
      email: inputEmail,
      isUserExists: false,
    }));
    await handleCreateResult(userSelected);
  };
  return (
    <Modal show={showQueryModel} onHide={() => setShowQueryModel(false)}>
      <Modal.Header closeButton>
        <h4 className={styles.queryModelHeader}>Create Account as</h4>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.queryModelContainer}>
          <button onClick={() => handleUserType("User")}>
            Individual Account
          </button>
          <button onClick={() => handleUserType("Dealer")}>
            Dealer Account
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserQueryAuthModel;
