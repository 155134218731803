import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ContactMobile.module.css";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { FaCircleArrowRight } from "react-icons/fa6";

const ContactMobile = () => {
  const { dealerId } = useSelector((state) => state.user);
  // const [loanCardDataArray, setLoanCardDataArray] = useState([]);
  // console.log("loanCardDataArray => ", loanCardDataArray);
  //  dummy data  for mobile show----------------------------------------
  const loanCardDataArray = [
    {
      name: "Niraj Kumar",
      mobileNumber: "9752467788",
      email: "niraj@gmail.com",
      address: "Taxes , USA, Collin",
    },
    {
      name: "Sumit Kumar Rana",
      mobileNumber: "3456654575",
      email: "sumit@gmail.com",
      address: "Taxes , USA, Collin",
    },
    {
      name: "Afjal Khan",
      mobileNumber: "345646324575",
      email: "afjal@gmail.com",
      address: "Taxes , USA, Collin",
    },
  ];
  // const fetchDealerData = async () => {
  //   const fd = new FormData();
  //   fd.append("user_id", dealerId);

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/loan/get`,
  //       fd
  //     );
  //     setLoanCardDataArray(response.data.data);
  //   } catch (error) {
  //     console.log("Error fetching dealer data:", error);
  //     setLoanCardDataArray([]);
  //   }
  // };

  // useEffect(() => {
  //   if (dealerId) {
  //     fetchDealerData();
  //   }
  // }, [dealerId]);
  // for mobile popup-----------------------------------------------------------------------
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [showViewPopUp, setShowViewPopUp] = useState(false);
  const showMobileMessagePopUp = () => {
    setShowMessagePopUp(true);
  };
  const showMobileViewPopUp = () => {
    setShowViewPopUp(true);
  };
  // -----------------------------------------------------------------------------------------
  return (
    <>
      <div className={styles.crmMwrapper}>
        {loanCardDataArray &&
          loanCardDataArray?.map((item, index) => (
            <div className={styles.crmMcontainer}>
              <div className={styles.crmMHeader}>
                <span>Name</span>
                <span>{item.name}</span>
              </div>
              <div className={styles.crmMPersonalWrapper}>
                <div className={styles.crmMPersonalDetails}>
                  <p className={styles.crmMDetail}>
                    <span>Phone Number</span>
                    <span>{item.mobileNumber}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Email</span>
                    <span>{item.email}</span>
                  </p>
                  <p className={styles.crmMDetail}>
                    <span>Address</span>
                    <span>{item.address}</span>

                    {/* <span>{item.assetName}</span> */}
                  </p>
                </div>
                <div className={styles.crmMBtnContainer}>
                  <button onClick={showMobileMessagePopUp}>Message</button>
                  <button onClick={showMobileViewPopUp}>View</button>
                </div>
              </div>
              <span className={styles.crmMNumbering}>{index + 1}</span>
            </div>
          ))}
      </div>
      {/*--------- mobile messgae------------- */}
      <Modal
        show={showMessagePopUp}
        onHide={() => setShowMessagePopUp(false)}
        size="xl"
      >
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#d1823a" }}
          >
            Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div className={styles.mibileMessageCobntainer}>
            {/* Messanger part container */}
            <div className={styles.MessangerPartWrapper}>
              <div className={styles.MessangerPartContainer}>
                <div className={styles.MessangerHeader}>
                  <span>Message</span>
                  <span>1</span>
                </div>
                <div className={styles.MessangerBody}>
                  <div className={styles.LeftBody}>
                    <div className={styles.message}>
                      <span>Ghada</span>
                      <span>I am interested to drive this car.</span>
                    </div>
                  </div>
                  <div className={styles.RightBody}>
                    <div className={styles.message}>
                      <span>United</span>
                      <span>
                        Before we proceed, can you please provide your ID and
                        address? Also I need to know if you have driving license
                        with all other documents.
                      </span>
                    </div>
                  </div>
                  <div className={styles.LeftBody}>
                    <div className={styles.message}>
                      <span>Ghada</span>
                      <span>I am interested to drive this car.</span>
                    </div>
                  </div>
                  <div className={styles.RightBody}>
                    <div className={styles.message}>
                      <span>United</span>
                      <span>
                        Before we proceed, can you please provide your ID and
                        address? Also I need to know if you have driving license
                        with all other documents.
                      </span>
                    </div>
                  </div>
                  <div className={styles.LeftBody}>
                    <div className={styles.message}>
                      <span>Ghada</span>
                      <span>
                        {" "}
                        Hello Jhone, Are you available to meet today?
                      </span>
                    </div>
                  </div>
                  <div className={styles.RightBody}>
                    <div className={styles.message}>
                      <span>United</span>
                      <span>No , I am busy today. Can we meet tomorrow?</span>
                    </div>
                  </div>
                  <div className={styles.LeftBody}>
                    <div className={styles.message}>
                      <span>Ghada</span>
                      <span>
                        Now I am available to meet today. Can we meet at 2 pm?
                      </span>
                    </div>
                  </div>
                  <div className={styles.RightBody}>
                    <div className={styles.message}>
                      <span>United</span>
                      <span>No , I am busy today. Can we meet tomorrow?</span>
                    </div>
                  </div>
                </div>
                <div className={styles.MessangerInput}>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Your Message"
                  />
                  <span>
                    <FaCircleArrowRight />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*--------- mobile view------------- */}
      <Modal
        show={showViewPopUp}
        onHide={() => setShowViewPopUp(false)}
        size="xl"
      >
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#d1823a" }}
          >
            View
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          {/* contact left section */}
          <div className={styles.left_side_contact_container}>
            <div className={styles.leftSectionContact}>
              <div className={styles.contactNameHead}>
                <div>
                  <label>First Name</label>
                  <input type="text" className={styles.contactInput} />
                </div>
                <div>
                  <label>Middle Name</label>
                  <input type="text" className={styles.contactInput} />
                </div>
              </div>
              <div>
                <label>Last Name</label>
                <input type="text" className={styles.contactInput} />
              </div>
              <div>
                <label>Address</label>
                <input type="text" className={styles.contactInput} />
              </div>
              <div className={styles.contactNameHead}>
                <div>
                  <label>City State Zip</label>
                  <input type="text" className={styles.contactInput} />
                </div>
                <div>
                  <label>County</label>
                  <input type="text" className={styles.contactInput} />
                </div>
              </div>
              <div className={styles.contactNameHead}>
                <div>
                  <label>Phone Number 1</label>
                  <input type="text" className={styles.contactInput} />
                </div>
                <div>
                  <label>Phone Number 2</label>
                  <input type="text" className={styles.contactInput} />
                </div>
              </div>
              <div>
                <label>Email</label>
                <input type="text" className={styles.contactInput} />
              </div>
            </div>
            <hr style={{ marginTop: "20px" }} />
            <h5 style={{ textAlign: "center" }}>Associated Names</h5>
            <hr />
            <div className={styles.leftSectionContact}>
              <div className={styles.contactNameHead}>
                <div>
                  <label>First Name</label>
                  <input type="text" className={styles.contactInput} />
                </div>
                <div>
                  <label>Middle Name</label>
                  <input type="text" className={styles.contactInput} />
                </div>
              </div>
              <div>
                <label>Last Name</label>
                <input type="text" className={styles.contactInput} />
              </div>
              <div>
                <label>Address</label>
                <input type="text" className={styles.contactInput} />
              </div>
              <div className={styles.contactNameHead}>
                <div>
                  <label>City State Zip</label>
                  <input type="text" className={styles.contactInput} />
                </div>
                <div>
                  <label>County</label>
                  <input type="text" className={styles.contactInput} />
                </div>
              </div>
              <div className={styles.contactNameHead}>
                <div>
                  <label>Phone Number 1</label>
                  <input type="text" className={styles.contactInput} />
                </div>
                <div>
                  <label>Phone Number 2</label>
                  <input type="text" className={styles.contactInput} />
                </div>
              </div>
              <div>
                <label>Email</label>
                <input type="text" className={styles.contactInput} />
              </div>
            </div>
            <div className={styles.saveButton}>
              <button>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactMobile;
