import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function MyDoughnutChart() {
  const data = [
    { label: "Facebook", value: 40, color: "#f8cb58" },
    { label: "Website(United Motors)", value: 5, color: "#ffd4d5" },
    { label: "Google Market Palace", value: 5, color: "#f9d1ad" },
    { label: "Dealers Auto center Market Palace", value: 12, color: "#cfe6f8" },
    { label: "Craigslist", value: 16, color: "#efc7ff" },
    { label: "Instagram", value: 22, color: "#dbfed3" },
  ];

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false, // Disable the default legend
      },
    },
  };

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
     <div style={{ width: '200px', height: '200px' }}>
      <Doughnut data={finalData} options={options} style={{width:"200px",height:"200px"}}/>
      </div>
      <div
        style={{ marginLeft: "20px", display: "flex", flexDirection: "column" }}
      >
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: item.color,
                marginRight: "8px",
              }}
            ></div>
            {item.label} {" "}<span style={{color:"#d1823a"}}>{item.value}%</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyDoughnutChart;
