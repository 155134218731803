import React from "react";
import styles from "../Styles/DealerBuyerLoginQueryModel.module.css";
import Image from "../../imgData";
import { IoCloseSharp } from "react-icons/io5";

const DealerBuyerLoginQueryModal = ({
  setShowLoginDialogueBox,
  showLoginDialogueBox,
  showDealerBuyerQueryModal,
  setShowDealerBuyerQueryModal,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
          
            <span className={styles.QueryVisibilityTrackerContainer}>
              <IoCloseSharp />
            </span>
          
        <div className={styles.dataContainer}>
          <p className={styles.dataHeaderContainer}>
            <img src={Image.dacNewLogo} alt="Dac New Logo" />
            <span>Dealers Auto Center Inc</span>
          </p>
          <p className={styles.loginText}>
            <span>Log In</span>
          </p>
          <p className={styles.NavBtnContainer}>
            <button>Licensed Dealer</button>
            <button>Private/Buyer</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DealerBuyerLoginQueryModal;
