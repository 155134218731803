import React, { useEffect, useRef, useState } from "react";
import styles from "./History.module.css";
import { MdEmail } from "react-icons/md";

import { useSelector } from "react-redux";
import axios from "axios";
import { Button, Container, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { FaCircleArrowRight } from "react-icons/fa6";
const History = () => {
  const dispatch = useDispatch();
  const { dealerId } = useSelector((state) => state.user);
  const [showEmailPopOut, setShowEmailPopOut] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Initial state for the 6 OTP digits
  const [rowData, setRowData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  // contact dummy table--------------------------------
  // const tableData = [
  //   {
  //     date:"02/12/2023",
  //     name: "Niraj Kumar",
  //     phoneNumber:"6547546758",
  //     email:"nirajn@gmail.com",
  //     address:"Taxes, U.S.A",
  //     address2:"43654436",
  //     city:"Taxes 450076 Collin",
  //     county:"Collin"
  //   },
  //   {
  //     date:"04/08/2026",
  //     name: "Sumit Rana",
  //     phoneNumber:"65475467546",
  //     email:"sumit@gmail.com",
  //     address:"California, U.S.A",
  //     address2:"43654436",
  //     city:"Taxes 450076 Collin",
  //     county:"Collin"
  //   },

  // ]
  //  get data of crn table---------------------------------------------------
  const fetchDealerData = async () => {
    // const fd = new FormData();
    // fd.append('user_id', dealerId);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/taxcertificate/get/${dealerId}`
        // fd
      );
      console.log("HistoryData",response.data);
      setTableData(response.data);
      // setTableData([]);
    } catch (error) {
      console.log("Error fetching dealer data:", error);
      setTableData([]);
    }
  };

  // Using useEffect to fetch data when the page loads
  useEffect(() => {
    if (dealerId) {
      fetchDealerData(); // Fetch dealer data when 'id' is available
    }
  }, [dealerId]);
//   console.log("tableData34543", tableData);
  // -----------------------------------------------------------------------------------
  // fortable data-------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  // Ensure tableData is defined before performing operations
  const totalItems = tableData ? tableData.length : 0;
  const itemsPerPage = totalItems > 20 ? totalItems : 20;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData
    ? tableData.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`} className={`${styles.tableRowData}`}>
      {[...Array(10)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));
  //  for pdf download and view--------------------------------------------------------------
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfData, setShowPdfData] = useState();
  const [showPdfStatus, setShowPdfStatus] = useState();
  const [idFinance, setIdFinance] = useState();
  const [userIdFinance, setUserIdFinance] = useState();
  //  const [pdfData, setPdfData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPdf = async (QuotationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/taxcertificate/view_pdf/${dealerId}/${QuotationId}`
      );
      console.log("response3545", response.status);

      if (response.status === 200) {
        setPdfData([response.data]);
        toast.success("Successfully show the PDF");
        setShowPdf(true);
      } else if (response.status === 204) {
        toast.error("Please Enter Correct OTP.");
      }
    } catch (err) {
      toast.error(err);
    }
  };

  // -------------------------------------
  //  for contact modal--------------------------------------------------------------
const [showContactModal, setShowContactmodal] = useState(false);
const showContactModalPopUp = () => {
  setShowContactmodal(true);
}
//----------------------------------------------------------------------------------
  //  for country dialing code-------------------------
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("Error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, [countryCode]);
  console.log("countryCode", countryCode);
//   useEffect(() => {
//         dispatch(
//           setRetailData({
//             crmQuoteLenght:tableData?.length ?? 0,
//           })
//         );
//       }, [tableData]);
  // --------------------------------------------------------------------------------
  const renderTableRow = (item, index) => {
    console.log("item in the current list => ", item);
    // Calculate days difference for the inspection date
    return (
      <>
        <tr
          key={index}
          className={`${styles.tableRowData}`}
        >
          {item ? (
            <>
              <td>{index + 1}</td>
              <td className="border-0 mb-0">{item?.createdAt} </td>
              <td>
                {item?.sellerName}
              </td>
              <td>{item?.sellerPhone}</td>
              <td className={styles.email}>
                <span>{item?.sellerEmail}</span>
                <span
                  style={{ cursor: "pointer" }}
                //   onClick={() => {
                //     setShowEmailPopOut(true);
                //     setRowData(item);
                //   }}
                >
                  <MdEmail />
                </span>
              </td>
              {/* <td className="ac-col fw-bold">{item?.address} </td> */}
              <td>{item?.sellerAddressLine1}</td>
              <td>{item?.sellerAddressLine2}</td>
              <td>{item?.sellerCity} {item?.sellerState} {item?.sellerZip}</td>
              <td>{item?.sellerCounty}</td>
              <td className="text-center">
                <Button
                  type="button"
                  className="border-0 me-1 py-2"
                  style={{ color: "white", width: "100px",backgroundColor:"#934805",fontSize:"0.8rem" }}
                  // onClick={() => downloadPdf(item?.id)}
                  // onClick={()=>getOtpLoan(item?.id, item?.userId,2)}
                  // onClick={showContactModalPopUp}
                >
                 Send Envelope
                </Button>
                <Button
                  type="button"
                  className="border-0  py-2"
                  style={{ color: "white", width: "100px",backgroundColor:"#934805",fontSize:"0.8rem" }}
                  onClick={() => loadPdf(item?.id)}
                >
                  View
                </Button>
              </td>
            </>
          ) : (
            Array.from({ length: 10 }).map((_, i) => <td key={i}>&nbsp;</td>)
          )}
        </tr>
      </>
    );
  };
  //  table functionend-------------------------------------------------------------
  console.log("showPdfData", showPdfData);
  // -------------------------------------------------------------------------------
  
  console.log("tableData", tableData);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container} style={{maxHeight:"70vh",overflowY:"auto",scrollbarWidth:"none"}}>
          <Table
            striped
            bordered
            hover
            ref={tableRef}
            className="shadow mb-0"
            id="table"
          >
            <thead>
              <tr>
                <th>No#</th>
                <th className="ac-col">Date</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Address</th>
                <th>Address2</th>
                <th>City, State, ZipCode</th>
                <th>County</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(renderTableRow)}
              {emptyRows}
            </tbody>
            <tfoot>
              <tr className={`${styles.tableFooterRow}`}>
                <td colSpan={2}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
      
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title" style={{ color:"#d1823a"}}>
            History Pdf :
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {pdfData &&
              pdfData.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
      {/* for contact pop-up */}
      <Modal show={showContactModal} onHide={() => setShowContactmodal(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title id="example-custom-modal-styling-title" style={{ color:"#d1823a"}}>
           Contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div  className={styles.contact_container}>
            {/* contact left section */}
            <div className={styles.left_side_contact_container}>
            <div className={styles.leftSectionContact}>
              <div className={styles.contactNameHead}>
              <div>
                <label>First Name</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              <div>
                <label>Middle Name</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              <div>
                <label>Last Name</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              </div>
              <div>
              <label>Address</label>
              <input type="text" className={styles.contactInput}/>
              </div>
              <div className={styles.contactNameHead}>
              <div>
                <label>City State Zip</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              <div>
                <label>County</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              </div>
              <div className={styles.contactNameHead}>
              <div>
              <label>Phone Number 1</label>
              <input type="text" className={styles.contactInput}/>
              </div>
              <div>
              <label>Phone Number 2</label>
              <input type="text" className={styles.contactInput}/>
              </div>
              </div>
              <div>
              <label>Email</label>
              <input type="text" className={styles.contactInput}/>
              </div>
            </div>
            <hr style={{marginTop:"20px"}}/>
            <h5 style={{textAlign:"center"}}>Associated Names</h5>
            <hr/>
            <div className={styles.leftSectionContact}>
              <div className={styles.contactNameHead}>
              <div>
                <label>First Name</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              <div>
                <label>Middle Name</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              <div>
                <label>Last Name</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              </div>
              <div>
              <label>Address</label>
              <input type="text" className={styles.contactInput}/>
              </div>
              <div className={styles.contactNameHead}>
              <div>
                <label>City State Zip</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              <div>
                <label>County</label>
                <input type="text" className={styles.contactInput}/>
              </div>
              </div>
              <div className={styles.contactNameHead}>
              <div>
              <label>Phone Number 1</label>
              <input type="text" className={styles.contactInput}/>
              </div>
              <div>
              <label>Phone Number 2</label>
              <input type="text" className={styles.contactInput}/>
              </div>
              </div>
              <div>
              <label>Email</label>
              <input type="text" className={styles.contactInput}/>
              </div>
            </div>
            <div className={styles.saveButton}>
                <button>Save</button>
              </div>
            </div>
            {/* Messanger part container */}
            <div className={styles.MessangerPartWrapper}>
                    <div className={styles.MessangerPartContainer}>
                      <div className={styles.MessangerHeader}>
                        <span>Message</span>
                        <span>1</span>
                      </div>
                      <div className={styles.MessangerBody}>
                        <div className={styles.LeftBody}>
                          <div className={styles.message}>
                            <span>Ghada</span>
                            <span>I am interested to drive this car.</span>
                          </div>
                        </div>
                        <div className={styles.RightBody}>
                          <div className={styles.message}>
                            <span>United</span>
                            <span>
                              Before we proceed, can you please provide your ID
                              and address? Also I need to know if you have
                              driving license with all other documents.
                            </span>
                          </div>
                        </div>
                        <div className={styles.LeftBody}>
                          <div className={styles.message}>
                            <span>Ghada</span>
                            <span>I am interested to drive this car.</span>
                          </div>
                        </div>
                        <div className={styles.RightBody}>
                          <div className={styles.message}>
                            <span>United</span>
                            <span>
                              Before we proceed, can you please provide your ID
                              and address? Also I need to know if you have
                              driving license with all other documents.
                            </span>
                          </div>
                        </div>
                        <div className={styles.LeftBody}>
                          <div className={styles.message}>
                            <span>Ghada</span>
                            <span> Hello Jhone, Are you available to meet today?</span>
                          </div>
                        </div>
                        <div className={styles.RightBody}>
                          <div className={styles.message}>
                            <span>United</span>
                            <span>
                             No , I am busy today. Can we meet tomorrow?
                            </span>
                          </div>
                        </div>
                        <div className={styles.LeftBody}>
                          <div className={styles.message}>
                            <span>Ghada</span>
                            <span>Now I am available to meet today. Can we meet at 2 pm?</span>
                          </div>
                        </div>
                        <div className={styles.RightBody}>
                          <div className={styles.message}>
                            <span>United</span>
                            <span>
                             No , I am busy today. Can we meet tomorrow?
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.MessangerInput}>
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Enter Your Message"
                        />
                        <span>
                          <FaCircleArrowRight />
                        </span>
                      </div>
                    </div>
                  </div>
          </div>
        </Modal.Body>
        </Modal>
    </>
  );
};

export default History;
