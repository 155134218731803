import React from "react";
import Image from "../../imgData";

const DealerProductsCard = ({
  text,
  image = Image.dealer_inventory_tax_maker,
}) => {
  return (
    <div className="dealer_product_card_wrapper">
      <div className="dealer_product_card_container">
        <p>{text}</p>
        <img src={image} alt="dealer products and facilities" />
        <span>Learn More</span>
      </div>
    </div>
  );
};

export default DealerProductsCard;
