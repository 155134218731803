import React from "react";
import DealerProductsCard from "./DealerProductsCard";
import "../Styles/DealerFaciltiesProducts.css";

const DealerFacilitiesProducts = () => {
  return (
    <div className="dealer_facilities_wrapper">
      <div className="dealer_facilities_container">
        <p className="dealer_products_header">OUR PRODUCTS</p>
        <div className="dealer_card_container">
          <DealerProductsCard text="DMS" image="image/solutions-icon1.png" />
          <DealerProductsCard
            text="WEBSITE FOR DEALER"
            image="image/solutions-icon8.png"
          />
          <DealerProductsCard
            text="DIGITAL CONTRACTING"
            image="image/solutions-icon5.png"
          />
          <DealerProductsCard
            text="ADVERTISING MANAGER"
            image="image/solutions-icon5.png"
          />
          <DealerProductsCard
            text="FACEBOOK MARKET SERVICE"
            image="image/solutions-icon3.png"
          />
          <DealerProductsCard
            text="DAC GOOGLE POWER"
            image="image/solutions-icon5.png"
          />
          <DealerProductsCard
            text="SMART CRM"
            image="image/solutions-icon5.png"
          />
          <DealerProductsCard
            text="HR SYSTEM"
            image="image/solutions-icon7.png"
          />
          <DealerProductsCard
            text="QUALIFIED LEADS"
            image="image/solutions-icon4.png"
          />
          <DealerProductsCard
            text="FAX SERVICES"
            image="image/solutions-icon2.png"
          />
          <DealerProductsCard
            text="DOMAIN AND HOSTING"
            image="image/solutions-icon5.png"
          />
          <DealerProductsCard
            text="LOGISTICS SERVICE"
            image="image/solutions-icon9.png"
          />
          <DealerProductsCard
            text="DAC UNIVERSITY"
            image="image/solutions-icon5.png"
          />
          <DealerProductsCard
            text="DAC EMAIL SERVICES"
            image="image/solutions-icon5.png"
          />
          {/* <DealerProductsCard text="DAC AUCTION LINE"/> */}
        </div>
      </div>
    </div>
  );
};

export default DealerFacilitiesProducts;
