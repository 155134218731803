import React, { useEffect, useState } from "react";
import Styles from "../Styles/Box_D.module.css";
import InputFieldComp from "./InputFieldComp";
import { useDispatch, useSelector } from "react-redux";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import { setIsSomethingChanged } from "../../../../Redux/slice/sellSlice";
const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
};
const Box_D = ({ retailFromRedux, sum }) => {
  const userListingPrice = useSelector((state) =>
    parseFloat(state.taxesAndFees.dealerRetail.userListingPrice)
  );
  const purchase_price = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.purchase_price) || 0
  );
  const ownedTrade = useSelector(
    (state) => parseFloat(state.taxesAndFees.dealerRetail.ownedTrade) || 0
  );
  const dispatch = useDispatch();
  const allInputs = useSelector(
    (state) => state.taxesAndFees.dealerRetail.SellPriceInputs
  );
  const lenghtInputs = allInputs?.length;
  console.log("14purchase", purchase_price);
  console.log("14Owned", ownedTrade);
  console.log("14UserListing", userListingPrice);
  const cashDifference = userListingPrice + ownedTrade - purchase_price;
  console.log("14CashDiff", cashDifference);
  useEffect(() => {
    dispatch(setRetailData({ cashDifference: cashDifference }));
  }, [cashDifference, dispatch]);

  const initialValues = [
    {
      header: "Trade In Allowance",
      InputValue: purchase_price,
      name: "purchase_price",
    },
    { header: "Trade-In Payoff", InputValue: ownedTrade, name: "ownedTrade" },
  ];

  useEffect(() => {
    dispatch(setIsSomethingChanged(true));
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "purchase_price"
          ? { ...item, InputValue: purchase_price }
          : item
      )
    );
  }, [purchase_price]);

  useEffect(() => {
    dispatch(setIsSomethingChanged(true));
    setInputValues((prevInputValues) =>
      prevInputValues.map((item) =>
        item.name === "ownedTrade" ? { ...item, InputValue: ownedTrade } : item
      )
    );
  }, [ownedTrade]);

  const [inputValues, setInputValues] = useState(initialValues);

  // const handleInputChange = (name, value) => {
  //   const updatedFields = inputValues.map((field) =>
  //     field.name === name ? { ...field, InputValue: value } : field
  //   );
  //   setInputValues(updatedFields);

  //   // Dispatch the updated value to the Redux store
  //   dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  // };
  const handleInputChange = (name, value) => {
    dispatch(setIsSomethingChanged(true));
    const updatedFields = inputValues.map((field) =>
      field.name === name ? { ...field, InputValue: value } : field
    );
    setInputValues(updatedFields);

    // Dispatch the updated value to the Redux store
    dispatch(setRetailData({ ...retailFromRedux, [name]: value }));
  };

  // Only render if purchase_price is greater than 0
  if (purchase_price <= 0) return null;

  return (
    <div className={Styles.wrapper}>
      <div
        className={Styles.container}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        {inputValues.map((item) => (
          <InputFieldComp
            item={item}
            key={item.header}
            header={item.header}
            InputValue={item.InputValue}
            name={item.name}
            handleInputChange={handleInputChange}
          />
        ))}
        <div
          className={`${Styles.inputFieldContainer} ${Styles.netAfterDataContainer}`}
        >
          <span>{lenghtInputs + 7}. Net Trade-In:</span>
          <p className={Styles.dueDataCash} style={{ backgroundColor: "none" }}>
            <span>$</span>
            {formatNumberWithCommas(
              cashDifference ? cashDifference?.toFixed(2) : 0
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Box_D;
