import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "../../../Redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import getIPAddress from "../../../GlobalAuthSection/SmallAuthComponent/IpFile";

const GoogleOneTapLogin = () => {
  const { email, id } = useSelector((state) => state.user);
  const [showAskWhichUserModal, setShowAskWhichUserModal] = useState(false);
  const [responseFromGoogleOneTapLogin, setResponseFromGoogleOneTapLogin] =
    useState({});
  const [loading, setLoading] = useState(false);
  const [userSelected, setUserSelected] = useState({
    user: false,
    dealer: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email && !id) {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;

      script.onload = () => {
        function decodeJwtResponse(token) {
          const base64Url = token.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
              .join("")
          );
          return JSON.parse(jsonPayload);
        }

        const handleCredentialResponse = async (response) => {
          const responsePayload = decodeJwtResponse(response.credential);
          console.log("Response from Google:", responsePayload);

          if (responsePayload?.email) {
            setResponseFromGoogleOneTapLogin(responsePayload);
            await handleSocialCheckAccount(responsePayload.email, responsePayload);
          }
        };

        window.google.accounts.id.initialize({
          client_id: "882863583650-d9lpkgf8dch0aiipej2e1biugdh7vlhe.apps.googleusercontent.com",
          cancel_on_tap_outside: false,
          context: "signin",
          callback: handleCredentialResponse,
        });

        window.google.accounts.id.prompt();
      };

      document.body.appendChild(script);
    }
  }, [email, id]);

  const handleSocialCheckAccount = async (userEmail, userData) => {
    try {
      const formData = new FormData();
      formData.append("email", userEmail);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/check_account`,
        formData
      );

      if (response.status === 200) {
        if (!response.data) {
          setShowAskWhichUserModal(true);
        } else {
          await loginGoogleOneTap(null, userData);
        }
      }
    } catch (error) {
      console.error("Error checking account:", error);
      toast.error("Error checking account.");
    }
  };

  const loginGoogleOneTap = async (userType, userData) => {
    setLoading(true);
    const ip = await getIPAddress();

    const formData = new FormData();
    formData.append("email", userData.email || "");
    formData.append("firstName", userData.given_name || "");
    formData.append("lastName", userData.family_name || "");
    formData.append("ip", ip);
    formData.append("user_type", userType || "");

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/save_user`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (res.status === 200) {
        dispatch(
          saveUser({
            email: res.data.email,
            id: res.data.id,
            dealerId: res.data.dealerId,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            roles: res.data.roles,
            loginAccess: res.data.loginAccess,
            dealerApproval: res.data.dealerApproval,
            dealerData: res.data.dealerData,
          })
        );

        toast.success("Signed in successfully");

        if (res.data.roles === "Dealer") {
          navigate("/dealer/dashboard");
        } else {
          navigate("/user/dashboard");
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Server-side error during login.");
    } finally {
      setLoading(false);
      setShowAskWhichUserModal(false);
      setUserSelected({ user: false, dealer: false });
    }
  };

  return (
    <>
      <Modal
        show={showAskWhichUserModal}
        onHide={() => setShowAskWhichUserModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>How would you like to log in?</p>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button
              onClick={() => {
                setUserSelected({ user: true, dealer: false });
                loginGoogleOneTap("User", responseFromGoogleOneTapLogin);
              }}
            >
              {loading && userSelected.user ? "Signing in..." : "As a Customer"}
            </button>
            <button
              onClick={() => {
                setUserSelected({ user: false, dealer: true });
                loginGoogleOneTap("Dealer", responseFromGoogleOneTapLogin);
              }}
            >
              {loading && userSelected.dealer ? "Signing in..." : "As a Dealer"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GoogleOneTapLogin;
