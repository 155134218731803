import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Modal,
  Form,
  FormGroup,
  OverlayTrigger,
  Tooltip,
  ModalFooter,
} from "react-bootstrap";
import { BiReset, BiSolidPhoneCall } from "react-icons/bi";
import html2canvas from "html2canvas";
import { MdDelete, MdEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./tax.module.css";
import { BsStars } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice";
import axios from "axios";
import { MdOutlinePreview } from "react-icons/md";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { TbArrowRight } from "react-icons/tb";
import { FaPen } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import toast from "react-hot-toast";

const apiUrl = process.env.REACT_APP_API_URL;

const DealerInventoryTaxMaker = () => {
  const { id } = useSelector((state) => state.user);
  //  for new filter-----------------------------------
  const { dealerId } = useSelector((state) => state.user);
  const [counterb, setCounterb] = useState(0);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedBodyType, setSelectedBodyType] = useState(null);
  const [dropdownData, setDropdownData] = useState();
  // -----------------------------------------------------------------
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [showSettings, setShowSettings] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef();
  const Navigate = useNavigate();
  const location = useLocation();
  // Extract data and originalData from location state
  const originalData = location.state?.originalData || {};
  const updatedData = location.state?.updatedData || {};
  const [counter, setCounter] = useState(0);
  const [initialFilters, setInitialFilters] = useState([]);

  // for fetch data----------------------------
  // useEffect(() => {
  //   fetchData();
  // }, [counter]);

  const handleIncrement = () => {
    setOpenDropdown(null);
    setCounter((prevCounter) => prevCounter + 1);
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });
    setSelectedSort(null);
  };
  // --------------------------------------
  const [formDataSetting, setFormDataSetting] = useState({
    taxFactor: "",
    accountNumber: "",
    personName: "",
    title: "",
    phone: "",
    businessStartDate: "",
    // new fields---------------
    countyNameTaxOffice: "",
    countyAddressTaxOffice: "",
    countyPhoneTaxOffice: "",
    appriasalNameDistrict: "",
    appriasalAddressDistrict: "",
    appriasalPhoneDistrict: "",
    userId: dealerId,
  });
  // List of months
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const isFieldUpdated = (data, fieldName) => {
    if (data.vin === updatedData.vin) {
      return originalData[fieldName] !== updatedData[fieldName];
    }
  };
  // --------------------------------------for generate forms section ----------------------------------------
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    dealerId: id,
    taxMakerIds: [],
    taxFactor: "",
    accountNumber: "",
    personName: "",
    title: "",
    phone: "",
    businessStartDate: "",
    // new fields---------------
    countyNameTaxOffice: "",
    countyAddressTaxOffice: "",
    countyPhoneTaxOffice: "",
    appriasalNameDistrict: "",
    appriasalAddressDistrict: "",
    appriasalPhoneDistrict: "",
  });
  const [taxMakerForms, setTaxMakerForms] = useState([]);
  console.log("sellForm", taxMakerForms);
  useEffect(() => {
    dispatch(
      setRetailData({
        taxMakerForms: taxMakerForms,
      })
    );
  }, [taxMakerForms]);
  console.log("formData97", formData);
  const GenerateTaxMakerForms = async (e) => {
    e.preventDefault();
    const {
      taxFactor,
      accountNumber,
      personName,
      title,
      phone,
      businessStartDate,
    } = formData;
    if (
      !taxFactor ||
      !accountNumber ||
      !personName ||
      !title ||
      !phone ||
      !businessStartDate
    ) {
      // If any of the fields are empty, show the message
      return alert("Please fill the settings data");
    }
    const formattedBusinessStartDateb = new Date(formData.businessStartDate).toISOString().split('T')[0];
    // Update formDataSetting with the new date format
    const updatedFormDataSettingb = {
        ...formData,
        businessStartDate: formattedBusinessStartDateb
    };
    const fnd = new FormData();
    fnd.append("tax", JSON.stringify(updatedFormDataSettingb));

    try {
      const response = await axios.post(`${apiUrl}/tax/forms`, fnd, {
        responseType: "arraybuffer",
      });
      if (response.status === 200) {
        console.log("Data saved successfully:", response.data);
        const data = response.data;
        const base64Pdf = arrayBufferToBase64(data);
        setTaxMakerForms(base64Pdf);

        //close the model after generate the pdf
        setShowGenerate(false);
        setShowPdf(true);
      }
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  };
  // Helper function to convert ArrayBuffer to Base64
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  // ----------------------------------------------------------------------------------------------------------------------------
  // for form to save data to craete sell form---------------------------

  // ------------------------------------------------------------------------------------------------------------------
  const handlePublish = (id) => {
    // Handle publish event
    console.log(`Publishing item with id: ${id}`);
  };

  const handleLinkClick = (vin, data) => {
    // Navigate to AddList component programmatically with state
    Navigate("/dealer/inventory/add", { state: { vin, data, active: true } });
    // Navigate('/myprofile/addlist', { state: vin, });
  };

  const handleRowClick = (index, MakerIds) => {
    // Ensure MakerIds is an array
    const ids = Array.isArray(MakerIds) ? MakerIds : [MakerIds];

    if (selectedRows.includes(index)) {
      // Deselect the row
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedIndex) => selectedIndex !== index)
      );
      // Remove MakerIds from taxMakerIds
      setFormData((prevFormData) => ({
        ...prevFormData,
        taxMakerIds: prevFormData.taxMakerIds.filter((id) => !ids.includes(id)),
      }));
    } else {
      // Select the row
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
      // Add MakerIds to taxMakerIds
      setFormData((prevFormData) => ({
        ...prevFormData,
        taxMakerIds: [...new Set([...prevFormData.taxMakerIds, ...ids])],
      }));
    }

    console.log("line114 taxMakerIds", ids);
  };

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevDropdown) => {
      // If the clicked dropdown is already open, close it
      if (prevDropdown === dropdownName) {
        return null;
      } else {
        // Open the clicked dropdown and close any other open dropdown
        return dropdownName;
      }
    });
  };

  const handleSort = (order) => {
    const sorted = [...tableData].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return order === "oldest" ? dateA - dateB : dateB - dateA;
    });
    // setSortedData(sorted);
  };

  const itemsPerPage = 20; // Change this value according to your preference
  const totalItems = tableData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  console.log("tableData", tableData);
  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`}>
      {[...Array(11)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));

  const renderTableRow = (item, index) => {
    if (!item.addListing2.year_Make_Model) {
      return null;
    }
    const year_make_model = item.addListing2.year_Make_Model;
    const [year, make, model, series, bodyClass] = year_make_model.split(" ");

    return (
      <tr
        key={index}
        className={selectedRows.includes(index) ? "selected-rows" : ""}
        onClick={() => item && handleRowClick(index, item.id)}
      >
        {item ? (
          <>
            <td>{index + 1}</td>
            <td>{item?.sellPriceFees?.priceFeeDate}</td>
            <td>{item?.saleType}</td>
            <td>{item?.accountNumber}</td>
            <td>
              {item?.isBuyerEntity
                ? item?.businessName
                : `${item?.firstName} ${item?.lastName}`}
            </td>
            <td>{item?.addListing2?.year}</td>
            <td>{item?.addListing2?.make}</td>
            <td>{item?.addListing2?.model}</td>

            <td
              className="vin-column fw-bold"
              // onClick={() => handleLinkClick(item.addListing2.vin, item)}
            >
              {item?.addListing2?.vin}
            </td>

            {/* <OverlayTrigger
              placement="bottom-start"
              overlay={
                <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                  Purchased Date: <br />
                  {item.purchasedDate}
                </Tooltip>
              }
            >
              <td style={{ textShadow: isFieldUpdated(item, "inspection") ? '#ff8c21 1px 0 10px' : 'black' }}>5</td>
            </OverlayTrigger> */}

            <td
              style={{
                textShadow: isFieldUpdated(item, "listing_price")
                  ? "#ff8c21 1px 0 10px"
                  : "black",
              }}
            >
              ${" "}
              {new Intl.NumberFormat().format(
                item?.sellPriceFees?.listingPrice
              )}
            </td>
            <td>
              {new Intl.NumberFormat().format(
                item?.sellPriceFees?.mInventoryValue
              )}
            </td>
          </>
        ) : (
          Array.from({ length: 11 }).map((_, i) => <td key={i}>&nbsp;</td>)
        )}
      </tr>
    );
  };

  // Calculate total mileage
  const totalMileage = tableData.reduce(
    (total, item) => total + item.mileage_in,
    0
  );

  // Calculate total mileage
  const listingPrice = tableData.reduce(
    (total, price) => total + price.listing_price,
    0
  );

  // handlers for modals
  const generateDatesForMonth = (year, month) => {
    const dates = [];
    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get number of days in the month

    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(day);
    }

    return dates;
  };

  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  // for filter make model year-------------------------------------------------
  // for filter sold data---------------------------------------------------------------*********************-----------------------------

  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });
  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedStatus, type = "") => {
    setOpenDropdown(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${selectedStatus}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (type) {
        setSelectedFilter((prev) => ({
          ...prev,
          [type]: selectedStatus, // Use computed property name here
        }));
      }
      setTableData(data);
      setSelectedSort(selectedStatus);
      console.log("selectedFilter", selectedFilter, type, selectedStatus);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //handleSelect(inputValue); // Call the function with the input value
      setInputValue(inputValue); // Clear the input field if desired
      setCounterb((prev) => prev + 1);
    }
  };
  const handleButtonClick = () => {
    // handleSelect(inputValue); // Call the function with the input value
    setInputValue(inputValue); // Clear the input field if desired
    setCounterb((prev) => prev + 1);
  };

  const handleSortByColumn = async (sort, column) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_sold_listing/${id}?searchTerm=${sort}&extraParam=${column}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // -------------------------------------------------------------------------------------------
  console.log("initialFilters32455", initialFilters);
  // ----------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------New Filter Sold Inventory--------------------------

  const transformOptions = (data, label) => {
    // Check if the data is an array before proceeding
    if (!Array.isArray(data)) {
      console.error(`${label} data is not an array`, data);
      return [];
    }

    return [
      { value: "", label: label }, // Default empty value for all dropdowns
      ...data.map((item) => ({
        value: item.toString(),
        label: item.toString(),
      })),
    ];
  };

  // Generating options
  const optionsYear = transformOptions(dropdownData?.year, "Year");
  const optionsMake = transformOptions(dropdownData?.make, "Make");
  const optionsModal = transformOptions(dropdownData?.model, "Model");
  const optionsBodyType = transformOptions(
    dropdownData?.bodyStyle,
    "Body Type"
  );

  console.log("dropdownData", dropdownData);
  const handleSelectedYear = (event) => {
    const selectedOption = optionsYear.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedYear(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    //handleFilterDDClick("year",selectedOption.value);
  };
  const handleSelectedMake = (event) => {
    const selectedOption = optionsMake.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedMake(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    // handleFilterDDClick("make",selectedOption.value);
  };
  const handleSelectedModal = (event) => {
    const selectedOption = optionsModal.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedModal(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    // handleFilterDDClick("models",selectedOption.value);
  };
  const handleSelectedBodyType = (event) => {
    const selectedOption = optionsBodyType.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedBodyType(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    // handleFilterDDClick("Body Type",selectedOption.value);
  };
  console.log("selectedYear", selectedYear);
  const objFor = {
    year: selectedYear ? parseInt(selectedYear) : null,
    make: selectedMake && selectedMake !== "" ? selectedMake : null,
    model: selectedModal && selectedModal !== "" ? selectedModal : null,
    bodyStyle:
      selectedBodyType && selectedBodyType !== "" ? selectedBodyType : null,
    userId: dealerId,
  };
  console.log("selectedYear", selectedYear);
  // --------------------------------------------------------------
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sell/active_filters`,
          objFor,
          {}
        );
        console.log("Response:", response.data);
        setDropdownData(response.data);
        // setCounterc((prev)=>prev+1);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    // Trigger the POST request
    postData();
  }, [counterb]);
  //  for get the data---------------------------------
  const objForSold = {
    searchTerm: inputValue && inputValue !== "" ? inputValue : null,
    make: selectedMake && selectedMake !== "" ? selectedMake : null,
    model: selectedModal && selectedModal !== "" ? selectedModal : null,
    year: selectedYear ? parseInt(selectedYear) : null,
    bodyStyle:
      selectedBodyType && selectedBodyType !== "" ? selectedBodyType : null,
    userId: dealerId,
  };
  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/get_sold_filters`,
          objForSold,
          {}
        );
        console.log("Response:5656565", response.data);
        const data = await response.data;
        if (response.status === 200 && data.length > 0) {
          console.log("all the data in sold inventory", data, response);
          const sortedData = data.sort((a, b) => a.id - b.id);
          console.log("all the sortedData in sold inventory", sortedData);
          setTableData(data);
          setInitialFilters(data.map((item) => item.addListing2));
          console.log("______________tableData", sortedData);
          setInputValue("");
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    // Trigger the POST request
    postData();
  }, [counterb]);
  //  for filter reset---------------------------------------------------------------------
  const handleResetFilter = () => {
    setInputValue("");
    setSelectedMake("");
    setSelectedModal("");
    setSelectedYear("");
    setSelectedBodyType("");
    setCounterb((prev) => prev + 1);
  };
  // ---------------------------------------------------------------------------
  // -----------------------------------------------for Settings Data save to backend------------------------------------------
  const handleChangeSettings = (e) => {
    console.log("settingsEvent", e);
    const { name, value } = e.target;

    if (
      name === "phone" ||
      name === "appriasalPhoneDistrict" ||
      name === "countyPhoneTaxOffice"
    ) {
      // Store the unformatted phone number (digits only) in the state
      const unformattedPhone = value.replace(/\D/g, ""); // Remove non-digit characters
      setFormDataSetting((prev) => ({
        ...prev,
        [name]: unformattedPhone, // Store only unformatted phone number
      }));
    } else {
      setFormDataSetting((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Helper function to format phone number in US format
  const formatPhoneNumber = (phone) => {
    if (!phone) return ""; // Return empty string if phone is undefined or null

    const phoneDigits = phone.replace(/\D/g, ""); // Remove non-digits
    if (phoneDigits.length <= 3) return phoneDigits;
    if (phoneDigits.length <= 6) {
      return `(${phoneDigits.slice(0, 3)}) ${phoneDigits.slice(3)}`;
    }
    return `(${phoneDigits.slice(0, 3)}) ${phoneDigits.slice(
      3,
      6
    )}-${phoneDigits.slice(6, 10)}`;
  };
  console.log("settingsFormData", formDataSetting);

  const handleBack = () => {
    setShowPdf(false);
  };
  // ----------------------------------------------------------------------------------------------------------------------------
  // for signature--------------------------**********************************---------------------------------------------------
  const [showSelectSign, setShowSelectSign] = useState(false);
  const [showSelectSignDraw, setShowSelectSignDraw] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [selectedFontFamily, setSelectedFontFamily] = useState("");
  const signatureData = [
    { id: "922647j" },
    { id: "6783ui6" },
    { id: "94234we" },
    { id: "gs49747" },
    { id: "bn89847" },
    { id: "357fhbj" },
    { id: "7654io" }, // The 7th signature
  ];

  // Mapping signature IDs to their respective font families
  const fontMapping = {
    "922647j": "Roboto, sans-serif",
    "6783ui6": "Arial, sans-serif",
    "94234we": "Courier New, monospace",
    gs49747: "Georgia, serif",
    bn89847: "Times New Roman, serif",
    "357fhbj": "Verdana, sans-serif",
    "7654io": "Tahoma, sans-serif",
  };
  const handleSelectSign = () => {
    setShowSelectSign(true);
  };
  const handleSelectSignDraw = () => {
    setShowSelectSignDraw(true);
  };
  const handleRadioChange = (signature) => {
    setSelectedSignature(signature.id);
    setSelectedFontFamily(fontMapping[signature.id]);
  };
  // for short the name like niraj kumar for this N.K.
  const initials = formDataSetting?.personName
    ? formDataSetting?.personName
        ?.split(" ")
        .map((name) => name.charAt(0))
        .join(".")
    : "";
  const [nameFull, setNameFull] = useState("");
  const [loading, setLoading] = useState("Save");
  const fullName = `${formDataSetting?.personName} ${initials}`;
  const captureImage = () => {
    if (selectedSignature) {
      setLoading("Process...");
      const element = document.getElementById(selectedSignature);
      // Using html2canvas with transparent background
      html2canvas(element, {
        backgroundColor: null, // Set the background to null to make it transparent
      })
        .then((canvas) => {
          setSignatureImage(canvas.toDataURL());
          setLoading("Save");
        })
        .catch(() => {
          setLoading("Save"); // Handle any errors and end loading
        });
    }
    setShowSelectSign(false);
    setNameFull(fullName);
  };
  console.log("signatureImage", loading);
  // ------------------------------------------------------
  //  for draw the Signature and save like image in useState-----------------------------------------
  const [isDrawing, setIsDrawing] = useState(false);
  const [isSecondDrawing, setIsSecondDrawing] = useState(false);
  const [signatureDataDraw, setSignatureDataDraw] = useState("");
  const [initialsData, setInitialsData] = useState("");

  const signatureCanvasRef = useRef(null);
  const initialsCanvasRef = useRef(null);

  const startDrawing = (e, isSecond) => {
    const canvas = isSecond
      ? initialsCanvasRef.current
      : signatureCanvasRef.current;
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();

    context.beginPath();
    context.moveTo(e.clientX - rect.left, e.clientY - rect.top);

    const onMouseMove = (e) => {
      context.lineTo(e.clientX - rect.left, e.clientY - rect.top);
      context.stroke();
    };

    const stopDrawing = () => {
      canvas.removeEventListener("mousemove", onMouseMove);
      canvas.removeEventListener("mouseup", stopDrawing);
      canvas.removeEventListener("mouseleave", stopDrawing);
      // Capture the drawn content as an image in base64 format
      captureDrawing(isSecond);
    };

    canvas.addEventListener("mousemove", onMouseMove);
    canvas.addEventListener("mouseup", stopDrawing);
    canvas.addEventListener("mouseleave", stopDrawing);
  };

  const captureDrawing = (isSecond) => {
    const canvas = isSecond
      ? initialsCanvasRef.current
      : signatureCanvasRef.current;
    const imageData = canvas.toDataURL("image/png"); // Capture image as base64 PNG
    if (isSecond) {
      setInitialsData(imageData);
    } else {
      setSignatureDataDraw(imageData);
    }
  };

  const handleCanvasMouseDown = (e, isSecond) => {
    startDrawing(e, isSecond);
    setIsDrawing(true);
    setIsSecondDrawing(isSecond);
  };

  const handleEdit = () => {
    if (isSecondDrawing) {
      const canvas = initialsCanvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      setInitialsData("");
    } else {
      const canvas = signatureCanvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      setSignatureDataDraw("");
    }
  };

  const handleDelete = () => {
    const signatureCanvas = signatureCanvasRef.current;
    const initialsCanvas = initialsCanvasRef.current;
    const signatureContext = signatureCanvas.getContext("2d");
    const initialsContext = initialsCanvas.getContext("2d");
    signatureContext.clearRect(
      0,
      0,
      signatureCanvas.width,
      signatureCanvas.height
    );
    initialsContext.clearRect(
      0,
      0,
      initialsCanvas.width,
      initialsCanvas.height
    );
    setSignatureDataDraw("");
    setInitialsData("");
  };
  const handleSaveSignature = () => {
    setShowSelectSignDraw(false);
    toast.success("Signature Saved");
  };
  console.log("signatureDataDraw", signatureDataDraw);
  console.log("initialsData", initialsData);
  //  for upload the images sor signatue-------------------------------------------
  const [imageSig, setImageSig] = useState(null);
  const [fileName, setFileName] = useState(null);
  // Trigger the file input click when the arrow icon is clicked
  const handleIconClick = () => {
    document.getElementById("file-input").click();
  };

  // Handle image selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a local URL for the image
      setImageSig(imageUrl); // Store the image in state
      setFileName(file.name);
    }
  };
  // console.log("imgessignture",formDataSetting.personName);
  //  for draw the Signature and save like image in useState End-----------------------------------------
  //  handle save settings data--------------------------------------------------------------------------------
  const handleSaveSettingsData = () => {
    const signatureImageMain = signatureImage || signatureDataDraw || imageSig;
    if (!signatureImageMain) {
      // Show toast message if no signature image is found
      toast.error("Please Upload Signature."); // You can replace `toast.error` with your preferred toast method
      return;
    }
    const formattedBusinessStartDate = new Date(formDataSetting.businessStartDate).toISOString().split('T')[0];

    // Update formDataSetting with the new date format
    const updatedFormDataSetting = {
        ...formDataSetting,
        businessStartDate: formattedBusinessStartDate
    };
    // Prepare FormData for API request
    const fnd = new FormData();
    fnd.append("tax", JSON.stringify(updatedFormDataSetting));
    //  const signatureImageMain = signatureImage ||  signatureDataDraw || imageSig;
    // Convert the base64 signature to binary (Blob)
    const byteCharacters = atob(signatureImageMain.split(",")[1]); // Remove base64 prefix and decode
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Create a Blob from the byte arrays (assuming it's a PNG)
    const blob = new Blob(byteArrays, { type: "image/png" });

    // Append the Blob (binary data) to FormData
    fnd.append("signature", blob, "signature.png");

    // Send the FormData to the API using Axios
    axios
      .post(`${process.env.REACT_APP_API_URL}/tax/save-details`, fnd)
      .then((response) => {
        // Handle success
        toast.success("Data Saved Successfully....");
        setShowSettings(false); // Close settings after success
      })
      .catch((error) => {
        // Handle error
        toast.error("Failed to save data. Please try again.");
        console.error("Error saving data:", error);
      });
  };
  // for get the api data for settings forms input fields------------------------------------------------------------
  const [apiSettingsData, setApiSettingsData] = useState([]);
  const getSettingsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tax/get-details/${dealerId}`
      );
      console.log("Settings Data:", response.data);
      setApiSettingsData(response.data);
      setShowSettings(true);
    } catch (error) {
      console.error("Error fetching settings data:", error);
    }
  };
  const getSettingsDataGenerateBtn = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tax/get-details/${dealerId}`
      );
      console.log("Settings Data:", response.data);
      setApiSettingsData(response.data);
    } catch (error) {
      console.error("Error fetching settings data:", error);
    }
  };
  console.log("apiSettingsData", apiSettingsData);
  useEffect(() => {
    if (apiSettingsData) {
      const formatDate = (date) => {
        if (!date) return "";
        const [month, day, year] = date.split("/");
        return `${year}-${month}-${day}`;
      };
      setFormDataSetting((prev) => ({
        ...prev,
        taxFactor: apiSettingsData.taxFactor,
        accountNumber: apiSettingsData.accountNumber,
        personName: apiSettingsData.personName,
        title: apiSettingsData.title,
        phone: apiSettingsData.phone,
        businessStartDate: formatDate(apiSettingsData.businessStartDate),
        // new fields---------------
        countyNameTaxOffice: apiSettingsData.countyNameTaxOffice,
        countyAddressTaxOffice: apiSettingsData.countyAddressTaxOffice,
        countyPhoneTaxOffice: apiSettingsData.countyPhoneTaxOffice,
        appriasalNameDistrict: apiSettingsData.appriasalNameDistrict,
        appriasalAddressDistrict: apiSettingsData.appriasalAddressDistrict,
        appriasalPhoneDistrict: apiSettingsData.appriasalPhoneDistrict,
      }));
      setFormData((prev) => ({
        ...prev,
        taxFactor: apiSettingsData.taxFactor,
        accountNumber: apiSettingsData.accountNumber,
        personName: apiSettingsData.personName,
        title: apiSettingsData.title,
        phone: apiSettingsData.phone,
        businessStartDate: apiSettingsData.businessStartDate,
        // new fields---------------
        countyNameTaxOffice: apiSettingsData.countyNameTaxOffice,
        countyAddressTaxOffice: apiSettingsData.countyAddressTaxOffice,
        countyPhoneTaxOffice: apiSettingsData.countyPhoneTaxOffice,
        appriasalNameDistrict: apiSettingsData.appriasalNameDistrict,
        appriasalAddressDistrict: apiSettingsData.appriasalAddressDistrict,
        appriasalPhoneDistrict: apiSettingsData.appriasalPhoneDistrict,
      }));
    }
  }, [apiSettingsData]);
  //  for generate button---------------------------------------------
  const handleShowGenerate = () => {
    getSettingsDataGenerateBtn();
    setShowGenerate(true);
  };
  // ------------------------------------------------------
  const [showModalSelectSign, setshowModalSelectSign] = useState(false);
  const [showModalDrawSign, setshowModalDrawSign] = useState(false);
  const [showModalUploadSign, setshowModalUploadSign] = useState(false);
  // for select signature----------------------------
  const handlePreviewImgSelectSign = () => {
    setshowModalSelectSign(true);
  };
  const handleDeleteImage = () => {
    setSignatureImage(null);
  };
  // for draw-------------------------
  const handlePreviewImgDrawSign = () => {
    setshowModalDrawSign(true);
  };
  const handleDeleteDrawImage = () => {
    setSignatureDataDraw("");
  };
  //  for upload image signature-------------------------------
  const handlePreviewImgUploadSign = () => {
    setshowModalUploadSign(true);
  };
  const handleDeleteUploadImage = () => {
    setImageSig(null);
  };
  // for multiple pdf send to email.------------------------------------------
  const [emailData, setEmailData] = useState({
    email: [{ value: "" }],
  });
  const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const handleClickEmail = () => {
    console.log("click the email");
    setShowEmailPopUp(true);
  };
  const handleEmailChange = (index, event) => {
    const newEmailData = [...emailData.email];
    newEmailData[index].value = event.target.value;
    setEmailData({ email: newEmailData });
  };

  // Handle add new email input
  const handleAddEmail = () => {
    setEmailData({
      email: [...emailData.email, { value: "" }],
    });
  };

  // Handle delete email input
  const handleDeleteEmail = (index) => {
    const newEmailData = emailData.email.filter((_, i) => i !== index);
    setEmailData({ email: newEmailData });
  };
const emailList = emailData.email.map(item => item.value);
console.log("emailList343",emailList);
  // console.log("images",`draw img ${signatureDataDraw} selectImg ${signatureImage} uploadImg ${imageSig}`);
  console.log("emailData", emailData);
  //  -----------------------------------------for send the pdf  to the multiple email-----------------------------------
  const handleSendPdfEmail = () => {
    const formattedBusinessStartDatec = new Date(formData.businessStartDate).toISOString().split('T')[0];
    // Update formDataSetting with the new date format
    const updatedFormDataSettingc = {
        ...formData,
        businessStartDate: formattedBusinessStartDatec
    };
    const fnd = new FormData();
    fnd.append('tax', JSON.stringify(updatedFormDataSettingc));
    // fnd.append('signature', null);
    fnd.append('email', emailList);
  
    // Send the POST request with the form data
    fetch(`${process.env.REACT_APP_API_URL}/tax/send-document`, {
      method: 'POST',
      body: fnd,
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 200) {
        setShowEmailPopUp(false);
        toast.success("Envelope sent successfully");
      } else {
        toast.error('Failed to send document: ' + data.message);
      }
    })
    .catch((error) => {
      setShowEmailPopUp(true);
      console.error('Error:', error);
    }).finally(() => {
      setShowEmailPopUp(false);
      toast.success("Envelope sent successfully");
    });
  };
  // ---------------------------------------------------------------
  return (
    <>
      {showPdf ? (
        <Container
          fluid
          style={{ minWidth: "82vw", height: "100vh" }}
          className={`px-4 ${styles.pdfContainerMain}`}
        >
          {/* <Modal.Header style={{ gap: "10%" }} className={styles.headerTaxPdf}>
          
        </Modal.Header> */}
          <Modal.Body className={styles.pdfContainerFrame}>
            <Container style={{ height: "100%" }}>
              <Modal.Title
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "5%",
                  marginBottom: "1rem",
                }}
              >
                <Button
                  className="w-100 pe-0 py-2 btn-orange border-0"
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  className="w-100 pe-0 py-2 btn-orange border-0"
                  style={{ visibility: "hidden" }}
                >
                  E-Sign
                </Button>
                <Button
                  className="w-100 pe-0 py-2 btn-orange border-0"
                  onClick={handleClickEmail}
                >
                  E-Mail
                </Button>
              </Modal.Title>
              {/* {taxMakerForms &&
              taxMakerForms.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${id}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })} */}
              {taxMakerForms ? (
                <iframe
                  title="Tax Maker Application PDF"
                  src={`data:application/pdf;base64,${taxMakerForms}`}
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                />
              ) : (
                <p>No PDF loaded yet.</p>
              )}
            </Container>
          </Modal.Body>
        </Container>
      ) : (
        <Container fluid className="px-4" style={{ minWidth: "82vw" }}>
          <Row className="px-3">
            <Col sm={2} className="px-0" style={{ paddingTop: "2%" }}>
              <span type="button" style={{ color: "blue" }}>
                <BsStars style={{ outline: "none" }} /> AI Inventory Tax Maker
              </span>
            </Col>

            <Col className="d-flex justify-content-end align-items-center px-0">
              <Button
                className={` m-2 py-1 px-3 rounded-3 fw-bold ${styles.bgBtn}`}
                onClick={() => handleShowGenerate()}
              >
                Generate
              </Button>

              <Button
                className={` m-2 py-1 px-3 rounded-3 fw-bold ${styles.bgBtn}`}
                onClick={getSettingsData}
              >
                Settings
              </Button>

              {/* First dropdown */}
              {/* <div className="">
              <button
                className="btn dropdown-toggle me-2 drp-btn page-count"
                type="button"
                onClick={() => toggleDropdown("page-count")}
              >
                10
              </button>
              {openDropdown === "page-count" && (
                <ul
                  className="page-count-list p-0"
                  ref={dropdownRef}
                  style={{ display: openDropdown ? "block" : "none" }}
                >
                  <li>
                    <Button className="dropdown-item">1</Button>
                  </li>
                  <li>
                    <Button className="dropdown-item">2</Button>
                  </li>
                </ul>
              )}
            </div> */}
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="px-0" style={{ position: "relative" }}>
              <Container fluid className="px-3">
                <Row>
                  {/* input field start */}
                  <Col
                    sm={11}
                    className="input-group mt-3 mb-4"
                    id="act-search"
                  >
                    <Button
                      className="btn border-0 dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        backgroundColor: "#e8a262",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                      }}
                    >
                      All
                    </Button>
                    <ul className="dropdown-menu">
                      <li onClick={() => handleSelect("Price10k")}>
                        <Link className="dropdown-item" to="#">
                          Price &lt; 10k
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Mileage50k")}>
                        <Link className="dropdown-item" to="#">
                          Miles &lt; 50k
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Mileage100k")}>
                        <Link className="dropdown-item" to="#">
                          Miles &lt; 100k
                        </Link>
                      </li>
                    </ul>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Inventory"
                      style={{
                        boxShadow: "inset 0 0 9px 2px rgb(209, 130, 58)",
                      }}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                      onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                    />
                    <Button
                      className="btn border-0"
                      type="button"
                      id="button-addon1"
                      style={{ backgroundColor: "#e8a262" }}
                      onClick={handleButtonClick}
                    >
                      <i className="fas fa-search"></i>
                    </Button>
                    {/*--------------------------------- nerw filter---------------------------------- */}
                    <div className="new-filter-active">
                      <select
                        value={selectedYear}
                        onChange={handleSelectedYear}
                        style={{ width: "auto" }}
                        placeholder="Year"
                      >
                        {optionsYear.length === 0 ? (
                          <option disabled>No year found</option>
                        ) : (
                          optionsYear.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                    <div className="new-filter-active">
                      <select
                        value={selectedMake}
                        onChange={handleSelectedMake}
                        style={{ width: "auto" }}
                        placeholder="Make"
                      >
                        {optionsMake.length === 0 ? (
                          <option disabled>No make found</option>
                        ) : (
                          optionsMake.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="new-filter-active">
                      <select
                        value={selectedModal}
                        onChange={handleSelectedModal}
                        style={{ width: "auto" }}
                        placeholder="Make"
                      >
                        {optionsModal.length === 0 ? (
                          <option disabled>No modal found</option>
                        ) : (
                          optionsModal.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="new-filter-active">
                      <select
                        value={selectedBodyType}
                        onChange={handleSelectedBodyType}
                        style={{ width: "auto" }}
                        placeholder="Make"
                      >
                        {optionsBodyType.length === 0 ? (
                          <option disabled>No body-type found</option>
                        ) : (
                          optionsBodyType.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                    {/* ------------------------------------------------------------------------------- */}

                    <Button
                      className={`btn border-0 dropdown-toggle btn-eq-wd ${
                        selectedSort === "Desc" || selectedSort === "Asc"
                          ? "bg-secondary"
                          : ""
                      }`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="sortBy" // Added ID for accessibility
                      style={{
                        backgroundColor: "#e8a262",
                        margin: "0 0.3rem",
                      }}
                    >
                      Sort BY
                    </Button>
                    <ul className="dropdown-menu" aria-labelledby="sortBy">
                      <li onClick={() => handleSelect("Desc")}>
                        <Link
                          className={`dropdown-item ${
                            selectedSort === "Desc" ? "selected-item" : ""
                          }`}
                          to="#"
                        >
                          Newest
                        </Link>
                      </li>
                      <li onClick={() => handleSelect("Asc")}>
                        <Link
                          className={`dropdown-item ${
                            selectedSort === "Asc" ? "selected-item" : ""
                          }`}
                          to="#"
                        >
                          Oldest
                        </Link>
                      </li>
                    </ul>

                    <Button
                      className="btn border-0 btn-eq-wd"
                      type="button"
                      id="button-addon1"
                      style={{
                        backgroundColor: "#e8a262",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                      }}
                      onClick={handleResetFilter}
                    >
                      <BiReset className="icons-r-flt" />
                    </Button>
                  </Col>
                  {/* input field end */}
                </Row>
              </Container>
            </Col>
          </Row>

          <Row
            className="mx-0 rounded  bg-body border-2"
            style={{
              position: "relative",
              top: "",
              zIndex: "5 ",
              // minWidth: "1000px", overflowX: "scroll"
            }}
          >
            <div className="table-wrapper rounded px-0 table-scrollbar">
              <Table striped bordered hover className="shadow mb-0" id="table">
                <thead>
                  <tr>
                    <th>No#</th>
                    <th className="ac-col">Sale Date</th>
                    <th>Sale Type</th>
                    <th>Acc. no.</th>
                    <th>Buyer Name</th>
                    <th>
                      Year
                      <span className="filter-icons">
                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Descending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() =>
                              handleSortByColumn("Desc", "Vehicle")
                            }
                          >
                            <TiArrowSortedUp />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          key="bottom-start"
                          placement="bottom-start"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-start`}
                              className="table-tooltip"
                            >
                              Ascending
                            </Tooltip>
                          }
                        >
                          <span
                            onClick={() => handleSortByColumn("Asc", "Vehicle")}
                          >
                            <TiArrowSortedDown />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </th>
                    <th>Make</th>
                    <th>Model</th>
                    <th className="vin-column">VIN</th>
                    <th>Sold Price </th>
                    <th>Unit Property Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData
                    .sort(
                      (a, b) =>
                        new Date(b.sellPriceFees.priceFeeDate) -
                        new Date(a.sellPriceFees.priceFeeDate)
                    )
                    .map(renderTableRow)}
                  {emptyRows}
                </tbody>
                <tfoot>
                  <tr style={{ backgroundColor: "orange" }}>
                    <td colSpan={8}>All Inventory: {tableData.length}</td>
                    <td colSpan={3}>Total Sale: $587,465,483</td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Row>
        </Container>
      )}
      {/*---------------------------------------------------------------- settings pop-up ----------------------------------------------------------------------------------------*/}
      <Modal
        show={showSettings}
        onHide={() => setShowSettings(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{
              color: "#d1823a",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.settingsParentContainer}>
            <div className={styles.mainSettingContentLeft}>
              <div className={styles.settingContentUpper}>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Name Of County Tax Office</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="countyNameTaxOffice"
                      value={formDataSetting.countyNameTaxOffice}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Address Of County Tax Office</h4>
                  </div>
                  <div className={styles.rightInputTextArea}>
                    <textarea
                      type="text"
                      name="countyAddressTaxOffice"
                      value={formDataSetting.countyAddressTaxOffice}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>

                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Phone Number</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="countyPhoneTaxOffice"
                      value={formatPhoneNumber(
                        formDataSetting.countyPhoneTaxOffice
                      )}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.settingContentUpper}
                style={{
                  borderTop: "2px solid #d1823a",
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Name Of Appriasal District</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="appriasalNameDistrict"
                      value={formDataSetting.appriasalNameDistrict}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Address Of Appriasal District</h4>
                  </div>
                  <div className={styles.rightInputTextArea}>
                    <textarea
                      type="text"
                      name="appriasalAddressDistrict"
                      value={formDataSetting.appriasalAddressDistrict}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Phone Number</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="appriasalPhoneDistrict"
                      value={formatPhoneNumber(
                        formDataSetting.appriasalPhoneDistrict
                      )}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.mainSettingContent}>
              <div className={styles.settingContentUpper}>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Invetory Tax Factor</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="taxFactor"
                      value={formDataSetting.taxFactor}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Account Number</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="accountNumber"
                      value={formDataSetting.accountNumber}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Person Name</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="personName"
                      value={formDataSetting.personName}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Title</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="title"
                      value={formDataSetting.title}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Phone</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      name="phone"
                      value={formatPhoneNumber(formDataSetting.phone)}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h4>Business Start Date</h4>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="date"
                      name="businessStartDate"
                      value={formDataSetting.businessStartDate}
                      onChange={(e) => handleChangeSettings(e)}
                      style={{ width: "100%", paddingLeft: "4%" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.settingContentUpper}
                style={{
                  borderTop: "2px solid #d1823a",
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}>
                    <h2>Signature</h2>
                  </div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      placeholder="Select"
                      value={nameFull}
                      style={{
                        fontFamily: selectedFontFamily || "default, sans-serif",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        pointerEvents:
                          fileName || signatureDataDraw ? "none" : "auto",
                      }}
                    />
                    <span onClick={handleSelectSign}>
                      <TbArrowRight />
                    </span>
                    <span
                      style={{ marginLeft: "5px", borderRadius: "20%" }}
                      onClick={handlePreviewImgSelectSign}
                    >
                      <MdOutlinePreview />
                    </span>
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}></div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      placeholder="Draw"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        pointerEvents:
                          nameFull || fileName || signatureDataDraw
                            ? "none"
                            : "auto",
                      }}
                    />
                    <span onClick={handleSelectSignDraw}>
                      <FaPen />
                    </span>
                    <span
                      style={{ marginLeft: "5px", borderRadius: "20%" }}
                      onClick={handlePreviewImgDrawSign}
                    >
                      <MdOutlinePreview />
                    </span>
                  </div>
                </div>
                <div className={styles.settingContentTextInput}>
                  <div className={styles.leftText}></div>
                  <div className={styles.rightInput}>
                    <input
                      type="text"
                      // value={fileName}
                      placeholder="Upload"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        pointerEvents:
                          nameFull || signatureDataDraw ? "none" : "auto",
                      }}
                    />
                    <span onClick={handleIconClick}>
                      <FaArrowUp />
                    </span>
                    <span
                      style={{ marginLeft: "5px", borderRadius: "20%" }}
                      onClick={handlePreviewImgUploadSign}
                    >
                      <MdOutlinePreview />
                    </span>
                    {/* Hidden file input */}
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <ModalFooter className={styles.footerbtnSave}>
          <button onClick={handleSaveSettingsData}>Save</button>
        </ModalFooter>
      </Modal>
      {/*----------------- for select signature-------------------------- */}
      <Modal
        show={showSelectSign}
        onHide={() => setShowSelectSign(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{
              color: "#d1823a",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            Select Signature
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div className={styles.selectSignContainerMain}>
            {signatureData.map((signature, index) => (
              <div className={styles.selectSignContainer} key={signature.id}>
                <div className={styles.selectSignContainerLeft}>
                  <span className={styles.checkBoxLeft}>
                    <input
                      type="radio"
                      checked={selectedSignature === signature.id}
                      onChange={() => handleRadioChange(signature)}
                    />
                  </span>
                  <div className={styles.inputNameLeft} id={signature.id}>
                    <input
                      type="text"
                      value={formDataSetting.personName}
                      style={{
                        fontFamily:
                          fontMapping[signature.id] || "default, sans-serif",
                        backgroundColor: "transparent",
                      }}
                    />
                    <input
                      type="text"
                      value={initials}
                      style={{
                        fontFamily:
                          fontMapping[signature.id] || "default, sans-serif",
                        backgroundColor: "transparent",
                      }}
                    />
                  </div>
                </div>
                <div className={styles.selectSignContainerRight}>
                  <span className={styles.selectSigId}>Signature ID.</span>
                  <h6 className={styles.selectSigIdCode}>{signature.id}</h6>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <ModalFooter className={styles.footerbtnSave}>
          <button type="button" onClick={captureImage}>
            {loading}
          </button>
        </ModalFooter>
      </Modal>
      {/* ----------------For Draw Signature--------------------- */}
      <Modal
        show={showSelectSignDraw}
        onHide={() => setShowSelectSignDraw(false)}
        size="l"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{
              color: "#d1823a",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            Draw Signature
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div className={styles.selectSignContainerDrawMain}>
            <div>
              <p>Draw Your Signature</p>
              <canvas
                ref={signatureCanvasRef}
                className={styles.drawSignature}
                width="450"
                height="150"
                onMouseDown={(e) => handleCanvasMouseDown(e, false)}
              ></canvas>
            </div>
            <div>
              <p>Draw Your Initials</p>
              <canvas
                ref={initialsCanvasRef}
                className={styles.drawSecondSignature}
                width="450"
                height="150"
                onMouseDown={(e) => handleCanvasMouseDown(e, true)}
              ></canvas>
            </div>
          </div>
        </Modal.Body>
        <ModalFooter className={styles.footerbtnSave}>
          <button type="button" onClick={handleEdit}>
            Edit
          </button>
          <button type="button" onClick={handleDelete}>
            Delete
          </button>
          <button type="button" onClick={handleSaveSignature}>
            Save
          </button>
        </ModalFooter>
      </Modal>
      {/*--------------------------------------------------------------------- settings pop-up end*--------------------------------------------------------------------------------*/}
      {/* for generate the pdf pop-up */}
      <Modal
        show={showGenerate}
        onHide={() => setShowGenerate(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Generate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={12}>
                <Row className="mb-3">
                  <Col
                    sm={9}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <input
                      type="radio"
                      id="monthly-inventory"
                      name="reportType"
                      value="1"
                      checked={tabs === 1}
                      onChange={() => setTabs(1)}
                      className="me-3"
                    />
                    <label
                      htmlFor="monthly-inventory"
                      className={`w-50 ${styles.tabs} ${
                        tabs === 1 ? styles.active : ""
                      }`}
                    >
                      Monthely Inventory Tax Report 50-246
                    </label>
                  </Col>
                  <Col
                    sm={3}
                    className="d-flex justify-content-center align-items-center pe-0"
                  >
                    <Button
                      className="w-50 pe-0 py-2 btn-orange border-0"
                      onClick={GenerateTaxMakerForms}
                    >
                      Generate
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <Row className="mb-5">
                  <Col
                    sm={9}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <input
                      type="radio"
                      id="vehicle-declaration"
                      name="reportType"
                      value="2"
                      checked={tabs === 2}
                      onChange={() => setTabs(2)}
                      className="me-3"
                    />
                    <label
                      htmlFor="vehicle-declaration"
                      className={`w-50 ${styles.tabs} ${
                        tabs === 2 ? styles.active : ""
                      }`}
                    >
                      Motor Vehicle Inventory Declaration 50-242
                    </label>
                  </Col>
                  <Col
                    sm={3}
                    className="d-flex justify-content-center align-items-center"
                  ></Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* for generate the pdf pop-up */}
      {/*  for view the images uploaded */}
      {/* for select signature img */}
      <Modal
        show={showModalSelectSign}
        onHide={() => setshowModalSelectSign(false)}
        // size="l"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div>
            <img
              src={signatureImage}
              alt="Signature"
              style={{ width: "100%", height: "100px" }}
            />
          </div>
        </Modal.Body>
        <ModalFooter className={styles.footerbtnSave}>
          <button type="button" onClick={handleDeleteImage}>
            Delete
          </button>
        </ModalFooter>
      </Modal>
      {/* for draw img signature */}
      <Modal
        show={showModalDrawSign}
        onHide={() => setshowModalDrawSign(false)}
        // size="l"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div>
            <img
              src={signatureDataDraw}
              alt="Signature"
              style={{ width: "100%", height: "100px" }}
            />
          </div>
        </Modal.Body>
        <ModalFooter className={styles.footerbtnSave}>
          <button type="button" onClick={handleDeleteDrawImage}>
            Delete
          </button>
        </ModalFooter>
      </Modal>
      {/* for upload img signature */}
      <Modal
        show={showModalUploadSign}
        onHide={() => setshowModalUploadSign(false)}
        // size="l"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div>
            <img
              src={imageSig}
              alt="Signature"
              style={{ width: "100%", height: "100px" }}
            />
          </div>
        </Modal.Body>
        <ModalFooter className={styles.footerbtnSave}>
          <button type="button" onClick={handleDeleteUploadImage}>
            Delete
          </button>
        </ModalFooter>
      </Modal>
      {/* for multiplem email pop-up */}
      <Modal
        show={showEmailPopUp}
        onHide={() => setShowEmailPopUp(false)}
        // size="l"
      >
        <Modal.Header closeButton style={{color:"#d1823a"}}> Add CC</Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div>
            {emailData.email.map((emailItem, index) => (
              <div
                key={index}
                className={styles.containerEmail}
              >
                <input
                  type="text"
                  value={emailItem.value}
                  onChange={(event) => handleEmailChange(index, event)}
                  style={{ marginRight: "10px" }}
                />
                {/* Add and Delete buttons */}
                {emailData.email.length > 1 && (
                  <button
                    onClick={() => handleDeleteEmail(index)}
                    className={styles.containerEmaildltbtn}
                  >
                    <MdDelete />
                  </button>
                )}
                <button onClick={handleAddEmail} style={{ marginLeft: "10px" }}  className={styles.containerEmailAddbBtn}><IoIosAddCircle /></button>
              </div>
            ))}
          </div>
        </Modal.Body>
        <ModalFooter className={styles.footerbtnSave}>
          <button type="button" onClick={handleSendPdfEmail}>Send</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DealerInventoryTaxMaker;
