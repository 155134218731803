import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ToolTip1 } from "react-tooltip";
import {
  setSelectedVehicle,
  incrementTabIndex,
  setActiveTab,
  resetBuyerSellData,
  resetCoBuyerSellData,
  setVehicleData,
  setSellData,
  setCoBuyerSellData,
} from "../../../Redux/slice/sellSlice";
import "./vehicle-list.css";
import VehicleCard from "./VehicleCard";
import {
  resetRetailData,
  resetTexasDynamicPriceFees,
  setRetailData,
} from "../../../Redux/slice/taxes&FeesSlice";
import axios from "axios";
import { BiReset } from "react-icons/bi";
import { incrementCounter } from "../../../Redux/slice/activeSlice";
const apiUrl = process.env.REACT_APP_API_URL;

const VehicleList = ({
  vehicleData,
  isLoading,
  handleTabSelect,
  handleSelectedVehicle,
}) => {
  const previousListingId = useSelector(
    (state) => state.taxesAndFees.dealerRetail.listingId
  );
  const counter = useSelector((state) => state.active.counter);
  const { dealerId } = useSelector((state) => state.user);
  const [counterb, setCounterb] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/listing/get_user_listing/${id}`
        );
        const data = await response.json();

        if (response.ok) {
          dispatch(setVehicleData(data));
          setInitialFilters(vehicleData);
        }
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
    };

    fetchData();
  }, [counter]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [selectedCard, setSelectedCard] = useState(null);
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);
  const handleSelected = (index, vehicle) => {
    console.log("vehicle12321Selected", index + vehicle);
    setSelectedCard(index === selectedCard ? null : index);
    dispatch(setSelectedVehicle(vehicle));
    handleSelectedVehicle(vehicle);
    dispatch(incrementTabIndex());
    dispatch(setActiveTab("Buyer"));
    dispatch(setRetailData({ listingId: vehicle.id }));
    // Compare with the previous listingId

    // dispatch(resetTexasDynamicPriceFees());
    dispatch(resetCoBuyerSellData());
    dispatch(resetBuyerSellData());
    dispatch(
      setRetailData({
        salesTaxes: "6.25",
        mInventoryTax: "0.001488",
        titleApplicationFee: 13.0,
        terpTitlefee: 20,
        drivesOutTag: 5.0,
        windshieldTrackerSticker: 50.75,
        regFeeDps: 1,
        cntyRoadBridgeFee: 10,
        childSafetyFund: 1.5,
        InspectionFeeOBDNL: 25,
        StateInspectionFees: 8.25,
        processingHandlingFee: 4.75,
        documentationFee: 150.0,
        technologyFees: 0.0,
        buyerTagFees: 5,
        other1: 0.0,
        other2: 0.0,
        cardFees: 0,
        warranty: 0,
        gapInsurance: 0,
        mailDeliveryFees: 0,
        transportationFees: 0,
        totalFees: 0,
        // totalFeesAndothers: 0,
        txtState: "",
        listingPrice: "",
        userListingPrice: 0,
        txtTitleType: "Retail",
        ownedTrade: "",
        cashDifference: "",
        paymentPriceFees: "",
        date: getCurrentDate(),
        saleTaxValue: "",
        mInventoryValue: "",
        balanceDue: "",
        totalDue: 0,
        // -----------------------------------
        lienHolderName: "",
        leanHolderId: "",
        lienHolderID: "",
        lienHolderAddress: "",
        lienHolderAddress2: "",
        serviceContractId: "",
        serviceContractName: "",
        serviceContractLicense: "",
        serviceContractAddress: "",
        serviceProviderActivationStatus: "",
        serviceProviderType: "",
        providerPhoneNumber: "",
        providerCounty: "",
        providerListingExpiry: "",
        priceFeesPaymentMethod: "",
        //Gap Insurance Provider Data
        serviceProviderMonths: "",
        serviceContractMiles: "",
        gapMonths: "",
        gapMiles: "",
        // -----------
        gapInsuranceId: "",
        gapName: "",
        gapLicense: "",
        gapAddress: "",
        gapActivationStatus: "",
        gapType: "",
        gapPhoneNumber: "",
        gapCounty: "",
        gapListingExpiry: "",
        // Sell Form ---------------------------
        sellForm: "",
        // tax maker forms-----------------------
        taxMakerForms: "",
        // trade in key--------
        dealerId: 0,
        vin: "",
        year_make_model: "",
        // make year modal only check purpose for backend------
        make: "",
        year: "",
        model: "",
        bodyClass: "",
        wheelbase: "",
        driveType: "",
        doorCount: "",
        // -----------------------
        trim: "",
        engine: "",
        transmission: "",
        vehicleType: "",
        seat_count: "",
        city_mpg: "",
        highway_mpg: "",
        inspection: "",
        exteriorColor: "",
        interiorColor: "",
        interiorType: "",
        mileage_in: "",
        purchase_price: "",
        //tradeInValue: formData.tradeInValue,
        listing_price: "",
        mileageIn: "",
        // trade in key end--------
        // for images of vehicle card--------
        imageUrl: "",
      })
    );
  };
  //  for filter --------on basic of make modal--------------------------------
  const [initialFilters, setInitialFilters] = useState([]);
  useEffect(() => {
    // setInitialFilters(vehicleData);
  }, [vehicleData]);
  //Fetching the filters
  //   First fetch the filters
  const [filters, setFilters] = useState({
    make: [],
    models: [],
    year: [],
    bodyClass: [],
    engineType: [],
    fuelType: [],
    vehicleType: [],
    owner: [],
    state: [],
    interiorColor: [],
    exteriorColor: [],
    interiorType: [],
    doorCounts: [],
    driveTrain: [],
    odometer: [],
    price: [],
    exterior_damage: [],
    photos: [],
    seller: [id && id],
    zip_code: [],
  });
  // console.log("Filter selected ", filters);

  // Selected filter state
  const [selectedFilter, setSelectedFilter] = useState({
    make: "Make",
    models: "Model",
    year: "Year",
    bodyClass: "Body Type",
  });
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  // Handle Year Filter Dropdown Click
  const handleFilterDDClick = (PropToUpdate, ValueForUpdate) => {
    // Close the dropdown after selection
    // setOpenDropdown(null);
    // // If ValueForUpdate is null, reset the selected filter
    if (!ValueForUpdate) {
      setSelectedFilter((prevData) => ({
        ...prevData,
        [PropToUpdate]:
          PropToUpdate === "make"
            ? "Make"
            : PropToUpdate === "models"
            ? "Model"
            : PropToUpdate === "year"
            ? "Year"
            : "Body Type",
      }));
      setFilters((prevData) => ({
        ...prevData,
        [PropToUpdate]: [],
      }));
      return;
    }

    // Update selected filter
    setSelectedFilter((prevData) => ({
      ...prevData,
      [PropToUpdate]: ValueForUpdate,
    }));

    // Set filters: if year, send as array of strings
    const valueToUpdate =
      PropToUpdate === "year"
        ? [String(ValueForUpdate), String(ValueForUpdate)]
        : [ValueForUpdate];

    setFilters((prevData) => ({
      ...prevData,
      [PropToUpdate]: valueToUpdate,
    }));
  };

  const handleResetFilter = () => {
    setOpenDropdown(null);
    setFilters({
      make: [],
      models: [],
      year: [],
      bodyClass: [],
      engineType: [],
      fuelType: [],
      vehicleType: [],
      owner: [],
      state: [],
      interiorColor: [],
      exteriorColor: [],
      interiorType: [],
      doorCounts: [],
      driveTrain: [],
      odometer: [],
      price: [],
      exterior_damage: [],
      photos: [],
      seller: [id && id],
      zip_code: [],
    });

    // Reset selected filters to initial state
    setSelectedFilter({
      make: "Make",
      models: "Model",
      year: "Year",
      bodyClass: "Body Type",
    });
    
    dispatch(incrementCounter());
    setSelectedSort(null);
    setSelectedYear("");
    setSelectedMake("");
    setSelectedModal("");
    setSelectedBodyType("");
    setCounterb((prev)=>prev+1);
    // Use this function wherever you need to reset the filters
  };
  //Here after changing the filter fetch the products again
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/filter_listing`,
          filters
        );
        console.log("response filters from the API", response.data);
        dispatch(setVehicleData(response.data));
        // setSortedData(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.log("Server side while fetching data during getting filters");
      }
    };
    fetchProducts();
  }, [filters]);

  // ---------------------------------------------------------------------------------------
  // ----------for search bar filter -----------------------------------------------------------
  const [inputValue, setInputValue] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);

  const handleSelect = async (selectedValue, type = "") => {
    setOpenDropdown(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listing/get_user_listing/${id}?searchTerm=${selectedValue}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // Sort the data immediately after fetching
      // const sortedData = [...data].sort((a, b) => b.id - a.id); // Sort in descending order by id
      dispatch(setVehicleData(data)); // Dispatch the sorted data
      setInitialFilters(data);
      // setSelectedSort(selectedValue);
      console.log(data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSelect(inputValue); // Call the function with the input value
      setInputValue(""); // Clear the input field if desired
    }
  };
  const handleButtonClick = () => {
    handleSelect(inputValue); // Call the function with the input value
    setInputValue(""); // Clear the input field if desired
  };
  // -------------------------------------------------------------------------------------------
  console.log("vehicleDataSell888888", vehicleData);
  useEffect(() => {
    if (vehicleData.length === 0) {
      dispatch(
        setSellData({
          buyer: {
            firstName: "",
            middleName: "",
            lastName: "",
            idType: "",
            idNumber: "",
            idState: "",
            address: "",
            county: "",
            address2: "",
            phoneNumber: "",
            email: "",
          },
        })
      );

      dispatch(
        setCoBuyerSellData({
          sellCoBuyer: {
            firstName: "",
            middleName: "",
            lastName: "",
            idType: "",
            idNumber: "",
            idState: "",
            address: "",
            county: "",
            address2: "",
            phoneNumber: "",
            email: "",
          },
        })
      );
      dispatch(setSelectedVehicle(null));
      dispatch(
        setRetailData({
          txtTitleType: "",
          userListingPrice: 0,
          mileageIn: "",
          imageUrl: "",
        })
      );
    }
  }, [vehicleData]); // dependencies
  //  new filter----------------------------------------------------------------------------------
  // --------------------------------------------------------------New Year make Modal filter-----------------------------**********-------------
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedBodyType, setSelectedBodyType] = useState(null);
  const [dropdownData, setDropdownData] = useState();
  // Function to transform the data into the desired format for each dropdown
  const transformOptions = (data, label) => {
    // Check if the data is an array before proceeding
    if (!Array.isArray(data)) {
      console.error(`${label} data is not an array`, data);
      return [];
    }

    return [
      { value: "", label: label }, // Default empty value for all dropdowns
      ...data.map((item) => ({
        value: item.toString(),
        label: item.toString(),
      })),
    ];
  };

  // Generating options
  const optionsYear = transformOptions(dropdownData?.year, "Year");
  const optionsMake = transformOptions(dropdownData?.make, "Make");
  const optionsModal = transformOptions(dropdownData?.model, "Model");
  const optionsBodyType = transformOptions(
    dropdownData?.bodyStyle,
    "Body Type"
  );

  console.log("dropdownData", dropdownData);
  const handleSelectedYear = (event) => {
    const selectedOption = optionsYear.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedYear(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("year", selectedOption.value);
  };
  const handleSelectedMake = (event) => {
    const selectedOption = optionsMake.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedMake(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("make", selectedOption.value);
  };
  const handleSelectedModal = (event) => {
    const selectedOption = optionsModal.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedModal(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("models", selectedOption.value);
  };
  const handleSelectedBodyType = (event) => {
    const selectedOption = optionsBodyType.find(
      (option) => option.value === event.target.value
    );
    console.log("Selected year => ", selectedOption);
    setSelectedBodyType(selectedOption.value); // Set the selected option object
    setCounterb((prev) => prev + 1);
    handleFilterDDClick("Body Type", selectedOption.value);
  };
  console.log("selectedYear", selectedYear);
  const objFor = {
    year: selectedYear ? parseInt(selectedYear) : null,
    make: selectedMake && selectedMake !== "" ? selectedMake : null,
    model: selectedModal && selectedModal !== "" ? selectedModal : null,
    bodyStyle:
      selectedBodyType && selectedBodyType !== "" ? selectedBodyType : null,
    userId: dealerId,
  };
  console.log("selectedYear", selectedYear);
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing/active_filters`,
          objFor,
          {}
        );
        console.log("Response:", response.data);
        setDropdownData(response.data);
        // setCounterc((prev)=>prev+1);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    // Trigger the POST request
    postData();
  }, [counterb]);
  // ---------------------------------------------------------------------------------------------
  return (
    <div className="vehicleListWrapper">
      {vehicleData && vehicleData.length > 0 ? (
        <div style={{ padding: "0" }}>
          <Col className="mx-auto px-0 py-1">
            <div
              className="input-group mb-4 vehicleFilterGroup"
              id="act-search"
            >
              <div className="vehicleListFilterFirstRow">
                <button
                  className="btn border-0 dropdown-toggle search-all allItemsDropDownToggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul className="dropdown-menu">
                  <li onClick={() => handleSelect("Price10k")}>
                    <Link className="dropdown-item" to="#">
                      Price &lt; 10k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage50k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 50k
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Mileage100k")}>
                    <Link className="dropdown-item" to="#">
                      Miles &lt; 100k
                    </Link>
                  </li>
                </ul>
                <input
                  type="text"
                  className="form-control searchItemsInput"
                  placeholder="Search Inventory"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                  onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                />
                <button
                  className="btn border-0 search-btn"
                  type="button"
                  id="button-addon2"
                  style={{ backgroundColor: "#e8a262" }}
                  onClick={handleButtonClick}
                >
                  <i className="fas fa-search" style={{ color: "white" }}></i>
                </button>
                <button
                  className="btn border-0 btn-eq-wd allItemsDropDownToggle smallScreenReset"
                  type="button"
                  id="button-addon1"
                  style={{ backgroundColor: "#e8a262" }}
                  onClick={handleResetFilter}
                  data-tooltip-id="reset1"
                  data-tooltip-content="Reset"
                  data-event="click"
                >
                  <BiReset className="icons-r-flt" />
                </button>
                <ToolTip1 id="reset1" place="top" />
              </div>
              <div className="vehicleListFilterSecondRow">
                <div className="new-filter-active">
                  <select
                    value={selectedYear}
                    onChange={handleSelectedYear}
                    style={{ width: "auto" }}
                    placeholder="Year"
                  >
                    {optionsYear.length === 0 ? (
                      <option disabled>No year found</option>
                    ) : (
                      optionsYear.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="new-filter-active">
                  <select
                    value={selectedMake}
                    onChange={handleSelectedMake}
                    style={{ width: "auto" }}
                    placeholder="Make"
                  >
                    {optionsMake.length === 0 ? (
                      <option disabled>No make found</option>
                    ) : (
                      optionsMake.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <div className="new-filter-active">
                  <select
                    value={selectedModal}
                    onChange={handleSelectedModal}
                    style={{ width: "auto" }}
                    placeholder="Make"
                  >
                    {optionsModal.length === 0 ? (
                      <option disabled>No modal found</option>
                    ) : (
                      optionsModal.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>
              <div className="vehicleListFilterThirdRow">
                <div className="new-filter-active">
                  <select
                    value={selectedBodyType}
                    onChange={handleSelectedBodyType}
                    style={{ width: "auto" }}
                    placeholder="Make"
                  >
                    {optionsBodyType.length === 0 ? (
                      <option disabled>No body-type found</option>
                    ) : (
                      optionsBodyType.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <button
                  className={`btn border-0 dropdown-toggle btn-eq-wd ${
                    selectedSort ? "bg-secondary" : ""
                  }`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="sortBy" // Added ID for accessibility
                  style={{
                    backgroundColor: "#e8a262",
                  }}
                >
                  Sort By
                </button>
                <ul className="dropdown-menu" aria-labelledby="sortBy">
                  <li onClick={() => handleSelect("Desc")}>
                    <Link
                      className={`dropdown-item ${
                        selectedSort === "Desc" ? "selected-item" : ""
                      }`}
                      to="#"
                    >
                      Newest
                    </Link>
                  </li>
                  <li onClick={() => handleSelect("Asc")}>
                    <Link
                      className={`dropdown-item ${
                        selectedSort === "Asc" ? "selected-item" : ""
                      }`}
                      to="#"
                    >
                      Oldest
                    </Link>
                  </li>
                </ul>
                <button
                  className="btn border-0 btn-eq-wd allItemsDropDownToggle bigScreenReset"
                  type="button"
                  id="button-addon1"
                  style={{ backgroundColor: "#e8a262" }}
                  onClick={handleResetFilter}
                  data-tooltip-id="reset1"
                  data-tooltip-content="Reset"
                  data-event="click"
                >
                  <BiReset className="icons-r-flt" />
                </button>
              </div>
            </div>
          </Col>
        </div>
      ) : null}

      {isLoading ? (
        <h2>Loading...</h2>
      ) : vehicleData && vehicleData.length > 0 ? (
        <Row className="rowAutoWidth">
          <Col className="mx-auto px-0">
            <Container className="d-flex justify-content-center px-0">
              <Row className="card-parent ">
                {vehicleData.map((vehicle, index) => (
                  <div
                    style={{ position: "relative" }}
                    className="vehicleCardContainer"
                  >
                    <VehicleCard
                      key={index}
                      index={vehicle.id}
                      selectedCard={selectedCard}
                      handleSelected={() => handleSelected(index, vehicle)}
                      data={vehicle}
                    />
                    <span className="vehicleCardNum">{index + 1}</span>
                  </div>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12} className="outer-box px-0">
            <div className="no-list">
              <h2>No active Inventory</h2>
              <h2>
                <Link to="/dealer/inventory/add">
                  Click here to add inventory
                </Link>
              </h2>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default VehicleList;
