const TradeInOptions = {
  trim: [
    "Base",
    "S",
    "SE",
    "LE",
    "XLE",
    "LX",
    "EX",
    "Sport",
    "GT",
    "Limited",
    "Platinum",
    "Lariat",
    "SLT",
    "Denali",
    "Z71",
    "R/T",
    "SS",
    "TRD",
    "SV",
    "Titanium",
    "Premier",
  ],
  seat_count: [2, 4, 5, 6, 7, 8],
  bodyClass: [
    "SUV (Sport Utility Vehicle)",
    "Sedan",
    "Crossover",
    "Hatchback",
    "Coupe",
    "Convertible",
    "Wagon (Station Wagon)",
    "Pickup Truck",
    "Minivan",
    "Microcar",
    "Roadster",
    "Luxury SUV",
    "Compact SUV",
    "Subcompact SUV",
    "Full-Size SUV",
    "Off-Road Vehicle",
    "Van",
    "Sports Car",
    "Electric Vehicle (EV)",
    "Hybrid Vehicle",
  ],
  vehicleType: [
    "Two-Seater",
    "Compact Car",
    "Sedan",
    "MPV",
    "Minivan",
    "SUV",
    "Crossover",
    "Pickup Truck",
    "Van",
    "Bus",
    "Limousine",
    "Motorhome",
    "Taxi",
    "Ride-Sharing Vehicle",
  ],
  doors: [2, 3, 4, 5, 6],
  transmission: [
    "Manual Transmission (MT)",
    "Automatic Transmission (AT)",
    "Continuously Variable Transmission (CVT)",
    "Semi-Automatic Transmission",
    "Dual-Clutch Transmission (DCT)",
    "Tiptronic Transmission",
    "Electric Transmission",
    "Hydraulic Transmission",
    "Sequential Transmission",
    "Planetary Gear Transmission",
  ],
  driveType: [
    "Front-Wheel Drive (FWD)",
    "Rear-Wheel Drive (RWD)",
    "All-Wheel Drive (AWD)",
    "Four-Wheel Drive (4WD)",
    "Part-Time Four-Wheel Drive",
    "Full-Time Four-Wheel Drive",
    "Electric Drive",
    "Hybrid Drive",
  ],
  engineType: [
    "2CYL",
    "3CYL",
    "4CYL",
    "5CYL",
    "6CYL",
    "8CYL",
    "10CYL",
    "12CYL",
    "16CYL",
    "Rotary",
  ],
  engineSize: [
    "1.0L",
    "1.2L",
    "1.5L",
    "1.6L",
    "1.8L",
    "2.0L",
    "2.2L",
    "2.5L",
    "3.0L",
    "3.5L",
    "4.0L",
    "5.0L",
    "6.0L",
    "7.0L",
  ],
  engineShape: [
    "In-Line",
    "V-Shaped",
    "Flat (Boxer)",
    "W-Shaped",
    "Rotary (Wankel)",
    "H-Shaped",
    "Opposed",
  ],
  fuelType: [
    "Gasoline",
    "Diesel",
    "Electric",
    "Hybrid",
    "Ethanol (E85)",
    "Compressed Natural Gas (CNG)",
    "Liquefied Petroleum Gas (LPG)",
    "Biodiesel",
    "Hydrogen",
    "Propane",
  ],
  fuelType2: [
    "Gasoline",
    "Diesel",
    "Electric",
    "Hybrid",
    "Ethanol (E85)",
    "Compressed Natural Gas (CNG)",
    "Liquefied Petroleum Gas (LPG)",
    "Biodiesel",
    "Hydrogen",
    "Propane",
  ],
  exteriorColor: [
    "Black",
    "White",
    "Silver",
    "Gray",
    "Red",
    "Blue",
    "Green",
    "Yellow",
    "Brown",
    "Beige",
    "Orange",
    "Purple",
    "Gold",
    "Maroon",
    "Teal",
  ],
  interiorColor: ["Black", "Gray", "Red", "White", "Blue", "Brown", "Ivory"],
  interiorType: [
    "Cloth",
    "Leather",
    "Synthetic Leather (Vegan Leather)",
    "Vinyl",
    "Alcantara",
    "Suede",
    "Mesh",
    "Wood Trim",
    "Carbon Fiber",
    "Metal Trim",
  ],
};

export default TradeInOptions;
