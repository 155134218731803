import React, { useEffect, useRef, useState } from "react";
import Styles from "./TaxCertificate.module.css";
import styles from "../../DealerInventory/DealerInventoryTaxMaker/tax.module.css";
import { FaArrowUp, FaMapMarkerAlt, FaPen } from "react-icons/fa";
import toast from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Container, Modal, ModalFooter } from "react-bootstrap";
import { PDFDocument } from "pdf-lib";
import History from "./History";
import HistoryMobile from "./HistoryMobile";
import { MdArrowBack } from "react-icons/md";
import { TbArrowRight } from "react-icons/tb";
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;
const TaxCertificate = ({ activeTab }) => {
  const addressRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const { dealerId } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    dealerId: dealerId,
    permitNumber: "",
    phoneNumber: "",
    buyerName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    // Seller Data---------------
    sellerName: "",
    sellerAddressLine1: "",
    sellerAddressLine2: "",
    sellerCity: "",
    sellerState: "",
    sellerZip: "",
    sellerCounty: "",
    discription: "",
    sellerPhone:"",
    sellerEmail:"",
  });
  // ------------------------ handel change----------------------------------------
  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    // Remove all non-numeric characters
    const cleaned = phone.replace(/\D/g, "");
    // Format it as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // ------------------------------------------------------------------------------
  // for address or Seller Address google api------------------------------------------------------------
  useEffect(() => {
    // Load the Google Maps API script
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initAutocomplete();
      };
      document.body.appendChild(script);
    };

    loadGoogleScript();
  }, [formData.sellerName]);

  const initAutocomplete = () => {
    const input = addressRef.current;
    const options = {
      types: ["establishment"],
      componentRestrictions: { country: "us" }, // Restrict to US addresses, can be changed to any country code.
    };
    const autocomplete = new window.google.maps.places.Autocomplete(
      input,
      options
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (place.geometry) {
        const addressComponents = place.address_components;
        const streetName =
          addressComponents.find((component) =>
            component.types.includes("route")
          )?.long_name || "";

        const zip =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        const county =
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_2")
          )?.long_name || "";

        const state =
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "";

        const city =
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "";
        const businessName = place.name || "";
        // Set the state with the selected address and its components
        setFormData((prevState) => ({
          ...prevState,
          sellerName: businessName,
          sellerAddressLine1: streetName,
          sellerCity: city,
          sellerState: state,
          sellerZip: zip,
          sellerCounty: county,
        }));
      }
    });
  };
  const handleInputChangeSellerName = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Filter the suggestions based on the input value
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };
  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    // Find the full address for the selected suggestion
    const selectedPlace = suggestions.find(
      (place) => place.name === suggestion
    );
    setFormData({
      sellerName: suggestion,
      sellerAddress: selectedPlace?.address || "",
    });
    setSuggestions([]); // Clear suggestions after selection
  };
  // ----------------------------------------------------------------------------------------------------
  //  for location-----------------------------------------------------------------
  const gotoGoogleMap = () => {
    // Check if all required fields are available
    if (!formData?.sellerAddressLine1) {
      toast.error("Seller address is required");
    }
    if (!formData.sellerCity) {
      toast.error("City is required");
    }
    if (!formData.sellerState) {
      toast.error("State is required");
    }
    if (!formData.sellerZip) {
      toast.error("Zip Code is required");
    }
    if (!formData.sellerCounty) {
      toast.error("County is required");
    }
    if (
      formData.sellerAddressLine1 &&
      formData.sellerCity &&
      formData.sellerState &&
      formData.sellerZip &&
      formData.sellerCounty
    ) {
      // Construct the address string
      const address = `${formData.sellerAddressLine1}, ${formData.sellerCity}, ${formData.sellerState} ${formData.sellerZip}, ${formData.sellerCounty}`;

      // Encode the address for URL
      const encodedAddress = encodeURIComponent(address);
      console.log("address1234", address);
      // Open Google Maps with the encoded address in a new tab
      const googleMapUrl = `https://www.google.com/maps?q=${encodedAddress}`;
      window.open(googleMapUrl, "_blank");
    }
  };
  const [dealerDeatails, setDealerDeatails] = useState({});
  //  for get dealer deatils-------------------------------
  console.log("activeTab", activeTab);
  useEffect(() => {
    const fetchDealerdata = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/dealer/details/${dealerId}`
        );
        console.log("response => ", response);
        setDealerDeatails(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDealerdata();
  }, []);
  console.log("DealerDeatils", dealerDeatails);
  //  show the dealer deatils in input------------------------------
  useEffect(() => {
    if (dealerDeatails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        buyerName: dealerDeatails.businessName || "",
        addressLine1: dealerDeatails.addressOne || "",
        addressLine2: dealerDeatails.addressTwo || "",
        city: dealerDeatails.city || "",
        state: dealerDeatails.state || "",
        zip: dealerDeatails.zipCode || "",
        county: dealerDeatails.county || "",
        permitNumber: dealerDeatails.exemptions || "",
        phoneNumber: dealerDeatails.phoneNumber || "",
      }));
    }
  }, [dealerDeatails]);
  //  for show the dealer deatails in Seller-Name section in drop down---------------------------------------------
  // for purchase source----------------------------------------------------------------
  const [apiSellerSuggestion, setAPISellerSuggestion] = useState([]);
  const handleSellerChange = (e, changeValue) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    fetchSellerData(value);
  };
  const handleSellerClick = (suggestion) => {
    console.log("suggestion123", suggestion);
    const zipCodeDe = suggestion?.zip.split("-")[0];
    setAPISellerSuggestion([]);
    setFormData((prevData) => ({
      ...prevData,
      sellerName: suggestion?.businessName || "",
      sellerAddressLine1: suggestion?.addressOne || "",
      sellerAddressLine2: suggestion?.addressTwo || "",
      sellerCity: suggestion?.city || "",
      sellerState: suggestion?.state || "",
      sellerZip: zipCodeDe || "",
      sellerCounty: suggestion?.county || "",
    }));
  };

  const fetchSellerData = async (nameValue) => {
    if (!nameValue || nameValue.trim().length === 0) {
      setAPISellerSuggestion([]);
      return;
    }
    try {
      // // Using FormData if necessary, otherwise use plain JSON
      // const fd = new FormData();
      // // fd.append("name", providerData.name);
      // fd.append("name", nameValue);

      // API call to fetch lien holder data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/dealer/business_details/seller/${nameValue}`
        // fd
      );

      // Logging the response
      //console.log(response.data);
      setAPISellerSuggestion(response.data);
    } catch (error) {
      // More descriptive error handling
      console.log(error);
      setAPISellerSuggestion([]);
    }
  };

  // -----------------------------------------------------------------------------------
  // generate pdf------------------------------------------------
  const [quoteForm, setQuoteForm] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleGenerate = async (e) => {
    // console.log("handleGenerate");
    e.preventDefault();
    // if (
    //   (!formData.firstName || !formData.lastName) &&
    //   (!formData.phoneNumber || !formData.email)  && (formData.listingId <= 0)
    // ) {
    //   toast.error(
    //     "First Name and Last Name, or Phone Number and Email, Assets, are required."
    //   );
    //   return;
    // }
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/taxcertificate/generate`,
        formData
      );
      // setQuoteForm([response.data]);
      const mergedPDF = await mergePDFs([response.data]);
      setMergedPdfUrl(mergedPDF);
      toast.success("Tax-Data Successfully Save...");
      await delay(3000);
      // Show the modal
      setShowPdf(true);
      console.log("Response:23432", response.data);
    } catch (error) {
      console.error("There was an error!");
      toast.error(error);
      // setError("Detail already exist");
    } finally {
      console.log("There was an error!");
      setLoading(false); // Set loading to false when the request finishes
    }
  };
  // ------------------------------------------------------------
  const mergePDFs = async (pdfArray) => {
    console.log("pdfArray => ", pdfArray.length);
    try {
      const mergedPdf = await PDFDocument.create();
      for (const item of pdfArray) {
        const existingPdfBytes = await fetch(
          `${process.env.REACT_APP_DOCS_URL}/${dealerId}/${item}`
        ).then((res) => res.arrayBuffer());
        const pdf = await PDFDocument.load(existingPdfBytes);
        const form = pdf.getForm();
        form.flatten();
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();

      return URL.createObjectURL(
        new Blob([mergedPdfBytes], { type: "application/pdf" })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    if (quoteForm) {
      mergePDFs(quoteForm);
    }
  }, [quoteForm]);
  // for view History-----------------------------------------------------------------------
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const handleViewHistory = () => {
    setShowHistoryTable(true);
  };
  console.log("showSettings", showSettings);
  // -----------------------------------------------for Settings Data save to backend------------------------------------------
  const [formDataSetting, setFormDataSetting] = useState({
    taxFactor: "",
    accountNumber: "",
    personName: "",
    title: "",
    phone: "",
    businessStartDate: "",
  });
  const handleChangeSettings = (e) => {
    console.log("settingsEvent", e);
    const { name, value } = e.target;

    if (name === "phone") {
      // Store the unformatted phone number (digits only) in the state
      const unformattedPhone = value.replace(/\D/g, ""); // Remove non-digit characters
      setFormDataSetting((prev) => ({
        ...prev,
        [name]: unformattedPhone, // Store only unformatted phone number
      }));
    } else {
      setFormDataSetting((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Helper function to format phone number in US format
  const formatPhoneNumberSetting = (phone) => {
    const phoneDigits = phone.replace(/\D/g, ""); // Remove non-digits
    if (phoneDigits.length <= 3) return phoneDigits;
    if (phoneDigits.length <= 6) {
      return `(${phoneDigits.slice(0, 3)}) ${phoneDigits.slice(3)}`;
    }
    return `(${phoneDigits.slice(0, 3)}) ${phoneDigits.slice(
      3,
      6
    )}-${phoneDigits.slice(6, 10)}`;
  };
  console.log("settingsFormData", formDataSetting);
  const handleSaveSettingsData = () => {
    localStorage.setItem("formDataSetting", JSON.stringify(formDataSetting));
    toast.success("Data Save Successfully....");
    setShowSettings(false);
  };
  // for signature--------------------------**********************************---------------------------------------------------
  const [showSelectSign, setShowSelectSign] = useState(false);
  const [showSelectSignDraw, setShowSelectSignDraw] = useState(false);
  const signatureData = [
    { id: "922647j" },
    { id: "6783ui6" },
    { id: "94234we" },
    { id: "gs49747" },
    { id: "bn89847" },
    { id: "357fhbj" },
    { id: "7654io" }, // The 7th signature
  ];

  // Mapping signature IDs to their respective font families
  const fontMapping = {
    "922647j": "Roboto, sans-serif",
    "6783ui6": "Arial, sans-serif",
    "94234we": "Courier New, monospace",
    gs49747: "Georgia, serif",
    bn89847: "Times New Roman, serif",
    "357fhbj": "Verdana, sans-serif",
    "7654io": "Tahoma, sans-serif",
  };
  const handleSelectSign = () => {
    setShowSelectSign(true);
  };
  const handleSelectSignDraw = () => {
    setShowSelectSignDraw(true);
  };
  // ------------------------------------------------------
  //  for draw the Signature and save like image in useState-----------------------------------------
  const [isDrawing, setIsDrawing] = useState(false);
  const [isSecondDrawing, setIsSecondDrawing] = useState(false);
  const [signatureDataDraw, setSignatureDataDraw] = useState("");
  const [initialsData, setInitialsData] = useState("");

  const signatureCanvasRef = useRef(null);
  const initialsCanvasRef = useRef(null);

  const startDrawing = (e, isSecond) => {
    const canvas = isSecond
      ? initialsCanvasRef.current
      : signatureCanvasRef.current;
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();

    context.beginPath();
    context.moveTo(e.clientX - rect.left, e.clientY - rect.top);

    const onMouseMove = (e) => {
      context.lineTo(e.clientX - rect.left, e.clientY - rect.top);
      context.stroke();
    };

    const stopDrawing = () => {
      canvas.removeEventListener("mousemove", onMouseMove);
      canvas.removeEventListener("mouseup", stopDrawing);
      canvas.removeEventListener("mouseleave", stopDrawing);
      // Capture the drawn content as an image in base64 format
      captureDrawing(isSecond);
    };

    canvas.addEventListener("mousemove", onMouseMove);
    canvas.addEventListener("mouseup", stopDrawing);
    canvas.addEventListener("mouseleave", stopDrawing);
  };

  const captureDrawing = (isSecond) => {
    const canvas = isSecond
      ? initialsCanvasRef.current
      : signatureCanvasRef.current;
    const imageData = canvas.toDataURL("image/png"); // Capture image as base64 PNG
    if (isSecond) {
      setInitialsData(imageData);
    } else {
      setSignatureDataDraw(imageData);
    }
  };

  const handleCanvasMouseDown = (e, isSecond) => {
    startDrawing(e, isSecond);
    setIsDrawing(true);
    setIsSecondDrawing(isSecond);
  };

  const handleEdit = () => {
    if (isSecondDrawing) {
      const canvas = initialsCanvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      setInitialsData("");
    } else {
      const canvas = signatureCanvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      setSignatureDataDraw("");
    }
  };

  const handleDelete = () => {
    const signatureCanvas = signatureCanvasRef.current;
    const initialsCanvas = initialsCanvasRef.current;
    const signatureContext = signatureCanvas.getContext("2d");
    const initialsContext = initialsCanvas.getContext("2d");
    signatureContext.clearRect(
      0,
      0,
      signatureCanvas.width,
      signatureCanvas.height
    );
    initialsContext.clearRect(
      0,
      0,
      initialsCanvas.width,
      initialsCanvas.height
    );
    setSignatureDataDraw("");
    setInitialsData("");
  };
  const handleSaveSignature = () => {
    setShowSelectSignDraw(false);
    toast.success("Signature Saved");
  };
  console.log("signatureDataDraw", signatureDataDraw);
  console.log("initialsData", initialsData);
  //  for upload the images sor signatue-------------------------------------------
  const [imageSig, setImageSig] = useState(null);
  const [fileName,setFileName] = useState(null);
  // Trigger the file input click when the arrow icon is clicked
  const handleIconClick = () => {
    document.getElementById('file-input').click();
  };

  // Handle image selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a local URL for the image
      setImageSig(imageUrl); // Store the image in state
      setFileName(file.name);
    }
  };
  // console.log("imgessignture",formDataSetting.personName);
  const initials = formDataSetting?.personName ?  formDataSetting?.personName?.split(" ")
                          .map(name => name.charAt(0))
                          .join(".") : "";
  //  for draw the Signature and save like image in useState End-----------------------------------------
  console.log("formData----TexCertificate", formData);
  return (
    <>
      {showHistoryTable ? (
        <div className={Styles.historyTableMain}>
          <div className={Styles.taxHistoryTxt}>
            <h2>History</h2>
          </div>
          <div className={Styles.backButtonTaxCerti}>
            <button onClick={() => setShowHistoryTable(false)}>
              <MdArrowBack />
            </button>
          </div>
          <div className={Styles.history_mobile}>
            <HistoryMobile />
          </div>
          <div className={Styles.history}>
            <History />
          </div>
        </div>
      ) : (
        <div className={Styles.taxCertificateWrapper}>
          <div className={Styles.taxCertificateContainer}>
            <div className={Styles.viewHistoryButton}>
              <button onClick={handleViewHistory}>View History</button>{" "}
              <button onClick={() => setShowSettings(true)}>Settings</button>
            </div>
            {/* Buyer Name + Phone Number +  Texas Sales */}
            <div className={Styles.business_address_2}>
              <p className={Styles.addressComponent}>
                <label htmlFor="buyerName" className={Styles.input_label}>
                  <span>Buyer Name</span>
                </label>
                <input
                  type="text"
                  id="buyerName"
                  name="buyerName"
                  className={Styles.input_text_field}
                  value={formData.buyerName}
                  onChange={handleInputChange}
                />
              </p>
              <p className={Styles.addressComponent}>
                <label
                  htmlFor="dealer_business_fax"
                  className={Styles.input_label}
                >
                  <span
                    style={{
                      color: "orange",
                      fontSize: "large",
                      marginRight: "0.3rem",
                    }}
                  ></span>
                  <span>Phone Number</span>
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  maxLength={14}
                  className={Styles.input_text_field}
                  value={formatPhoneNumber(formData.phoneNumber)}
                  onChange={handleInputChange}
                />
              </p>
              <p className={Styles.addressComponent}>
                <label
                  htmlFor="dealer_business_phone_number"
                  className={Styles.input_label}
                >
                  <span
                    style={{
                      color: "orange",
                      fontSize: "large",
                      marginRight: "0.3rem",
                    }}
                  ></span>
                  <span>Texas Sales And Use Tax Permit Number(11 Digit)</span>
                </label>
                <input
                  type="text"
                  id="permitNumber"
                  name="permitNumber"
                  className={Styles.input_text_field}
                  maxLength={11}
                  value={formData.permitNumber}
                  onChange={handleInputChange}
                />
              </p>
            </div>
            {/* Address Line 1 +  Address Line 2 */}
            <div className={Styles.business_address_3}>
              <p className={Styles.addressComponent}>
                <label htmlFor="addressLine1" className={Styles.input_label}>
                  <span>Address Line 1</span>
                </label>
                <input
                  ref={addressRef}
                  type="text"
                  id="addressLine1"
                  name="addressLine1"
                  className={Styles.input_text_field}
                  value={formData.addressLine1}
                  onChange={handleInputChangeSellerName}
                />
                {/* Optionally, show suggestions below */}
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={(event) => {
                        event.stopPropagation(); // This stops the event from propagating up the DOM
                        handleSuggestionClick(suggestion);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              </p>
              <p className={Styles.addressComponent}>
                <label
                  htmlFor="dealer_business_zip_code"
                  className={Styles.input_label}
                >
                  <span>Address Line 2</span>
                </label>
                <span className={Styles.input_Field_Container}>
                  <input
                    type="text"
                    id="sellerAddress"
                    name="addressLine2"
                    className={Styles.input_text_field}
                    value={formData.addressLine2}
                    onChange={handleInputChange}
                  />
                  {/* <span onClick={gotoGoogleMap}>
              <FaMapMarkerAlt />
            </span> */}
                </span>
              </p>
              <div className={Styles.cityStateZipContainer}>
                <p className={Styles.subAddressComponent}>
                  <span>City</span>
                  <input
                    type="text"
                    value={formData.city}
                    name="city"
                    onChange={handleInputChange}
                  />
                </p>
                <p className={Styles.subAddressComponent}>
                  <span>State</span>
                  <input
                    type="text"
                    value={formData.state}
                    name="state"
                    onChange={handleInputChange}
                  />
                </p>
                <p className={Styles.subAddressComponent}>
                  <span>Zip-Code</span>
                  <input
                    type="text"
                    value={formData.zip}
                    name="zip"
                    onChange={handleInputChange}
                  />
                </p>
              </div>
              <p className={Styles.addressComponent}>
                <label htmlFor="buyerCounty" className={Styles.input_label}>
                  <span>County</span>
                </label>
                <span className={Styles.input_Field_Container}>
                  <input
                    type="text"
                    id="buyerCounty"
                    name="county"
                    className={Styles.input_text_field}
                    value={formData.county}
                    onChange={handleInputChange}
                  />
                </span>
              </p>
            </div>

            {/* Seller Name  */}
            <div className={Styles.business_address_2}>
              <p
                className={Styles.addressComponent}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="dealer_business_phone_number"
                  className={Styles.input_label}
                >
                  <span>Seller Name</span>
                </label>
                <input
                  ref={addressRef}
                  type="text"
                  id="sellerName"
                  name="sellerName"
                  className={`${Styles.input_text_field} ${Styles.input_seller_width}`}
                  value={formData.sellerName}
                  onChange={handleInputChangeSellerName}
                />
                {apiSellerSuggestion.length > 0 && formData.sellerName && (
                  <ul
                    className="dropdown-menu show"
                    style={{
                      position: "absolute", // Position the suggestions relative to the input field
                      top: "100%", // Position directly below the input
                      left: 0, // Align to the left of the input
                      right: 0, // Make sure it stretches to the input's width
                      width: "47%",
                      maxHeight: "300px",
                      overflowY: "auto",
                      zIndex: 10, // Ensure it appears above other elements
                      marginTop: "0.5rem", // Add some space between input and suggestions
                      padding: 0, // Optional, if you want to remove any default padding
                      backgroundColor: "white", // Optional, adjust the background color for visibility
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional, for a subtle shadow effect
                    }}
                  >
                    {apiSellerSuggestion &&
                      apiSellerSuggestion?.map((suggestion) => (
                        <li key={suggestion.id}>
                          <button
                            className="dropdown-item"
                            type="button"
                            style={{ color: "black" }}
                            onClick={() => handleSellerClick(suggestion)}
                          >
                            {suggestion.businessName}
                          </button>
                        </li>
                      ))}
                  </ul>
                )}
              </p>
              <p className={Styles.addressComponent}>
                <label htmlFor="buyerName" className={Styles.input_label}>
                  <span>Email</span>
                </label>
                <input
                  type="text"
                  id="sellerEmail"
                  name="sellerEmail"
                  className={Styles.input_text_field}
                  value={formData.sellerEmail}
                  onChange={handleInputChange}
                />
              </p>
              <p className={Styles.addressComponent}>
                <label
                  htmlFor="dealer_business_fax"
                  className={Styles.input_label}
                >
                  <span
                    style={{
                      color: "orange",
                      fontSize: "large",
                      marginRight: "0.3rem",
                    }}
                  ></span>
                  <span>Phone Number</span>
                </label>
                <input
                  type="text"
                  id="sellerPhone"
                  name="sellerPhone"
                  maxLength={14}
                  className={Styles.input_text_field}
                  value={formatPhoneNumber(formData.sellerPhone)}
                  onChange={handleInputChange}
                />
              </p>
            </div>
            <div
              className={Styles.business_address_3}
              style={{ borderBottom: "none" }}
            >
              <p className={Styles.addressComponent}>
                <label
                  htmlFor="sellerAddressLine1"
                  className={Styles.input_label}
                >
                  <span>Address Line 1</span>
                </label>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <input
                    // ref={addressRef}
                    type="text"
                    id="sellerAddressLine1"
                    name="sellerAddressLine1"
                    className={Styles.input_text_field}
                    value={formData.sellerAddressLine1}
                    onChange={handleInputChange}
                  />
                  <span
                    onClick={gotoGoogleMap}
                    style={{ position: "relative", right: "22px" }}
                  >
                    <FaMapMarkerAlt />
                  </span>
                </span>
                {/* Optionally, show suggestions below */}
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={(event) => {
                        event.stopPropagation(); // This stops the event from propagating up the DOM
                        handleSuggestionClick(suggestion);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              </p>
              <p className={Styles.addressComponent}>
                <label
                  htmlFor="sellerAddressLine2"
                  className={Styles.input_label}
                >
                  <span>Address Line 2</span>
                </label>
                <span className={Styles.input_Field_Container}>
                  <input
                    type="text"
                    id="sellerAddressLine2"
                    name="sellerAddressLine2"
                    className={Styles.input_text_field}
                    value={formData.sellerAddressLine2}
                    onChange={handleInputChange}
                  />
                  {/* <span onClick={gotoGoogleMap}>
              <FaMapMarkerAlt />
            </span> */}
                </span>
              </p>
              <div className={Styles.cityStateZipContainer}>
                <p className={Styles.subAddressComponent}>
                  <span>City</span>
                  <input
                    type="text"
                    value={formData.sellerCity}
                    name="sellerCity"
                    onChange={handleInputChange}
                  />
                </p>
                <p className={Styles.subAddressComponent}>
                  <span>State</span>
                  <input
                    type="text"
                    value={formData.sellerState}
                    name="sellerState"
                    onChange={handleInputChange}
                  />
                </p>
                <p className={Styles.subAddressComponent}>
                  <span>Zip-Code</span>
                  <input
                    type="text"
                    value={formData.sellerZip}
                    name="sellerZip"
                    onChange={handleInputChange}
                  />
                </p>
              </div>
              <p className={Styles.addressComponent}>
                <label htmlFor="sellerCounty" className={Styles.input_label}>
                  <span>County</span>
                </label>
                <span className={Styles.input_Field_Container}>
                  <input
                    type="text"
                    id="sellerCounty"
                    name="sellerCounty"
                    className={Styles.input_text_field}
                    value={formData.sellerCounty}
                    onChange={handleInputChange}
                  />
                </span>
              </p>
            </div>

            <div className={Styles.business_address_2}>
              <p>
                <label
                  htmlFor="dealer_business_zip_code"
                  className={Styles.input_label}
                >
                  <span
                    style={{
                      color: "orange",
                      fontSize: "large",
                      marginRight: "0.3rem",
                    }}
                  ></span>
                  <span>Description</span>
                </label>
                <textarea
                  type="text"
                  id="discription"
                  name="discription"
                  className={Styles.input_text_field_seller}
                  value={formData.discription}
                  onChange={handleInputChange}
                />
              </p>
            </div>
            <div className={Styles.generate_btn}>
              <button type="button" onClick={handleGenerate}>
                {loading ? <div className="spinner"></div> : "Generate"}
              </button>
            </div>
          </div>
          {/* show the pdf in the modal */}
          <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
            <Modal.Header closeButton style={{ gap: "10%" }}>
              <Modal.Title id="example-custom-modal-styling-title">
                {""}
              </Modal.Title>
              <Modal.Title
                style={{
                  display: "flex",
                  width: "45%",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Button className="w-100 pe-0 py-2 btn-orange border-0">
            Print
          </Button>
          <Button className="w-100 pe-0 py-2 btn-orange border-0">
            E-Mail
          </Button> */}
                <h4 style={{ color: "#d1823a" }}>Tax-Certificate Forms</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                {mergedPdfUrl && (
                  <div style={{ height: "80vh" }}>
                    <iframe
                      src={mergedPdfUrl + "#zoom=110"}
                      // src={`${mergedPdfUrl}#view=fit&pagemode=none&zoom=150`}
                      width="100%"
                      height="100%"
                      type="application/pdf"
                      title="Merged PDF File"
                    />
                  </div>
                )}
              </Container>
            </Modal.Body>
          </Modal>
          {/* end of the modal */}
          {/*---------------------------------------------------------------- settings pop-up ----------------------------------------------------------------------------------------*/}
          <Modal
            show={showSettings}
            onHide={() => setShowSettings(false)}
            size="lg"
            // dialogClassName="modal-90w"
            // size prop can be 'sm', 'lg', or 'xl'
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="example-custom-modal-styling-title"
                style={{
                  color: "#d1823a",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                Settings
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.mainSettingContent}>
                <div className={styles.settingContentUpper}>
                  {/* <div className={styles.settingContentTextInput}>
                <div className={styles.leftText}>
                  <h4>Invetory Tax Factor</h4>
                </div>
                <div className={styles.rightInput}>
                  <input
                    type="text"
                    name="taxFactor"
                    value={formDataSetting.taxFactor}
                    onChange={(e) => handleChangeSettings(e)}
                  />
                </div>
              </div>
              <div className={styles.settingContentTextInput}>
                <div className={styles.leftText}>
                  <h4>Account Number</h4>
                </div>
                <div className={styles.rightInput}>
                  <input
                    type="text"
                    name="accountNumber"
                    value={formDataSetting.accountNumber}
                    onChange={(e) => handleChangeSettings(e)}
                  />
                </div>
              </div>*/}
              <div className={styles.settingContentTextInput}>
                <div className={styles.leftText}>
                  <h4>Person Name</h4>
                </div>
                <div className={styles.rightInput}>
                  <input
                    type="text"
                    name="personName"
                    value={formDataSetting.personName}
                    onChange={(e) => handleChangeSettings(e)}
                  />
                </div>
              </div> 
                  <div className={styles.settingContentTextInput}>
                    <div className={styles.leftText}>
                      <h4>Title</h4>
                    </div>
                    <div className={styles.rightInput}>
                      <input
                        type="text"
                        name="title"
                        value={formDataSetting.title}
                        onChange={(e) => handleChangeSettings(e)}
                      />
                    </div>
                  </div>
                  {/* <div className={styles.settingContentTextInput}>
                <div className={styles.leftText}>
                  <h4>Phone</h4>
                </div>
                <div className={styles.rightInput}>
                  <input
                    type="text"
                    name="phone"
                    value={formatPhoneNumberSetting(formDataSetting.phone)}
                    onChange={(e) => handleChangeSettings(e)}
                  />
                </div>
              </div>
              <div className={styles.settingContentTextInput}>
                <div className={styles.leftText}>
                  <h4>Business Start Date</h4>
                </div>
                <div className={styles.rightInput}>
                  <input
                    type="date"
                    name="businessStartDate"
                    value={formDataSetting.businessStartDate}
                    onChange={(e) => handleChangeSettings(e)}
                    style={{ width: "100%", paddingLeft: "4%" }}
                  />
                </div>
              </div> */}
                </div>
                <div
                  className={styles.settingContentUpper}
                  style={{
                    borderTop: "2px solid #d1823a",
                    marginTop: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  <div className={styles.settingContentTextInput}>
                    <div className={styles.leftText}>
                      <h2>Signature</h2>
                    </div>
                    <div className={styles.rightInput}>
                      <input
                        type="text"
                        placeholder="Select"
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      />
                      <span onClick={handleSelectSign}>
                        <TbArrowRight />
                      </span>
                    </div>
                  </div>
                  <div className={styles.settingContentTextInput}>
                    <div className={styles.leftText}></div>
                    <div className={styles.rightInput}>
                      <input
                        type="text"
                        placeholder="Draw"
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      />
                      <span onClick={handleSelectSignDraw}>
                        <FaPen />
                      </span>
                    </div>
                  </div>
                  <div className={styles.settingContentTextInput}>
                    <div className={styles.leftText}></div>
                    <div className={styles.rightInput}>
                      <input
                        type="text"
                        placeholder="Upload"
                        value={fileName}
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      />
                      <span onClick={handleIconClick}>
                        <FaArrowUp />
                      </span>

                      {/* Hidden file input */}
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <ModalFooter className={styles.footerbtnSave}>
              <button onClick={handleSaveSettingsData}>Save</button>
            </ModalFooter>
          </Modal>
          {/*----------------- for select signature-------------------------- */}
          <Modal
            show={showSelectSign}
            onHide={() => setShowSelectSign(false)}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="example-custom-modal-styling-title"
                style={{
                  color: "#d1823a",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                Select Signature
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "100%" }}>
              <div className={styles.selectSignContainerMain}>
                {signatureData.map((signature, index) => (
                  <div
                    className={styles.selectSignContainer}
                    key={signature.id}
                  >
                    <div className={styles.selectSignContainerLeft}>
                      <span className={styles.checkBoxLeft}>
                        <input type="radio" />
                      </span>
                      <div className={styles.inputNameLeft}>
                        <input
                          type="text"
                          value={formDataSetting.personName}
                          style={{
                            fontFamily:
                              fontMapping[signature.id] ||
                              "default, sans-serif",
                          }}
                        />
                        <input
                          type="text"
                          value={initials}
                          style={{
                            fontFamily:
                              fontMapping[signature.id] ||
                              "default, sans-serif",
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.selectSignContainerRight}>
                      <span className={styles.selectSigId}>Signature ID.</span>
                      <h6 className={styles.selectSigIdCode}>{signature.id}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </Modal.Body>
            <ModalFooter className={styles.footerbtnSave}>
              <button type="button">Save</button>
            </ModalFooter>
          </Modal>
          {/* ----------------For Draw Signature--------------------- */}
          <Modal
            show={showSelectSignDraw}
            onHide={() => setShowSelectSignDraw(false)}
            size="l"
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="example-custom-modal-styling-title"
                style={{
                  color: "#d1823a",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                Draw Signature
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "100%" }}>
              <div className={styles.selectSignContainerDrawMain}>
                <div>
                  <p>Draw Your Signature</p>
                  <canvas
                    ref={signatureCanvasRef}
                    className={styles.drawSignature}
                    width="450"
                    height="150"
                    onMouseDown={(e) => handleCanvasMouseDown(e, false)}
                  ></canvas>
                </div>
                <div>
                  <p>Draw Your Initials</p>
                  <canvas
                    ref={initialsCanvasRef}
                    className={styles.drawSecondSignature}
                    width="450"
                    height="150"
                    onMouseDown={(e) => handleCanvasMouseDown(e, true)}
                  ></canvas>
                </div>
              </div>
            </Modal.Body>
            <ModalFooter className={styles.footerbtnSave}>
              <button type="button" onClick={handleEdit}>
                Edit
              </button>
              <button type="button" onClick={handleDelete}>
                Delete
              </button>
              <button type="button" onClick={handleSaveSignature}>
                Save
              </button>
            </ModalFooter>
          </Modal>
          {/* ------------------------------------------------------ */}
        </div>
      )}
    </>
  );
};

export default TaxCertificate;
