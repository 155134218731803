import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import ScrollToTopButton from "./ScrollToTopButton";
import Image from "../imgData";

const Footer = () => {
  // console.log(Image)
  return (
    <>
      <footer
        className="text-left text-lg-start text-light pt-4"
        style={{ backgroundColor: "rgb(207 206 206)" }}
      >
        <section>
          <div className="container-fluid text-left text-md-left flexParent">
            <div className="row mt-3">
              {/* Company Column */}
              {/* <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4"> */}
              {/* <h6 className="logo_home_footer"> */}
              {/* <Link to="/">
                    <span>DEALERS</span>
                    <span>AUTO</span>
                    <span>CENTER</span>
                  </Link> */}
              {/* <Link>
                  <img src={Image.darkLogo} alt="Logo" width="30%" className="L_g"/>
                </Link> */}
              {/* </h6> */}
              {/* <div>
                  <Link to="" className="ico-reset">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link to="" className=" text-reset">
                    <i className="fab fa-twitter"></i>s
                  </Link>
                  <Link to="" className=" text-reset">
                    <i className="fab fa-google"></i>
                  </Link>
                  <Link to="" className="text-reset">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link to="" className=" text-reset">
                    <i className="fab fa-linkedin"></i>
                  </Link>
                </div> */}
              {/* <br /> */}
              {/* <p>
                  <b>Download Our Mobile App</b>
                </p>
                <div className="app-btn ">
                  <img src={Image.ios} alt="iOS" />
                  <img src={Image.android} alt="Android" />
                </div> */}
              {/* </div> */}

              <div
                className="col-md-2 col-lg-2 col-xl-2   mobileLinksParent1"
                style={{ color: "black" }}
              >
                {/* <!-- Links --> */}
                <h6 className=" fw-bold  mobileLinksParent1Header">
                  Dealers Auto Center
                </h6>
                <p>
                  <Link to="/" className="text-reset">
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Inventory
                  </Link>
                </p>
                <p>
                  <Link to="/term-condition" className="text-reset">
                    Finance
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    About Us
                  </Link>
                </p>
                <p>
                  <Link to="/privacy-policy" className="text-reset">
                    Privacy policy
                  </Link>
                </p>
                <p>
                  <Link to="/term-condition" className="text-reset">
                    Terms and Conditions
                  </Link>
                </p>
              </div>

              {/* <!-- Grid column 1--> */}
              <div className="col-md-2 col-lg-2 col-xl-2 mb-4 mobileLinksParent2">
                <h6 className="text-uppercase fw-bold mb-4 mobileLinksParent1Header">
                  Shop
                </h6>
                <p>
                  <Link to="#" className="text-reset">
                    Browse by Category
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    View all Inventory
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Find Link Store
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column 1 End--> */}

              {/* <!-- Grid column 2--> */}
              <div className="col-md-2 col-lg-2 col-xl-2  mb-4 mobileLinksParent2">
                <h6 className="text-uppercase fw-bold mb-4 mobileLinksParent1Header">
                  Financing
                </h6>
                <p>
                  <Link to="#" className="text-reset">
                    Browse by Category
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    View all Inventory
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    Find Link Store
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column 2  End--> */}

              {/* <!-- Grid column 3 --> */}
              {/* <div className="col-md-2 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Sell / Trade</h6>

                <p>
                  <Link to="#" className="text-reset">
                    Get an Online offer
                  </Link>
                </p>
                <p>
                  <Link to="#" className="text-reset">
                    How it works
                  </Link>
                </p>
              </div> */}
              {/* <!-- Grid column 3  End--> */}

              {/* Other Columns (Company, Shop, Financing, Sell/Trade)
            ... (Remaining code for other columns) */}
            </div>
          </div>
        </section>

       
      </footer>
    </>
  );
};

export default Footer;
