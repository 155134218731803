import React, { useEffect, useRef, useState } from "react";
import styles from "./Users.module.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import tableBodyArray from "./CRMDummyData";
import { MdEmail } from "react-icons/md";
import { ImCross } from "react-icons/im";
import CrmEmailPopOut from "./CrmEmailPopOut.jsx";
import { useSelector } from "react-redux";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { Button, Container, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { setRetailData } from "../../../../Redux/slice/taxes&FeesSlice.js";
import { useDispatch } from "react-redux";
import MyDoughnutChart from "./MyDoughnutChart.jsx";
const Users = () => {
  const dispatch = useDispatch();
  const { dealerId } = useSelector((state) => state.user);
  const [showEmailPopOut, setShowEmailPopOut] = useState(false);
  const [showModalOtp, setShowModalOtp] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Initial state for the 6 OTP digits
  const [rowData, setRowData] = useState(null);
  // const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  const tableData = [
    {
      userId: "gadha Mussllem",
      sentMessage: "5",
      outGoingnCall: "6",
      inCominingCall: "8",
      loanAppAssets: "20",
      offers: "30",
      quotationSent: "40",
      appointment: "34",
      loginHours: "12",
    },
    {
      userId: "Niraj Kumar",
      sentMessage: "50",
      outGoingnCall: "60",
      inCominingCall: "80",
      loanAppAssets: "25",
      offers: "37",
      quotationSent: "48",
      appointment: "39",
      loginHours: "24",
    },
  ];
  //  get data of crn table---------------------------------------------------
  //   const fetchDealerData = async () => {
  //     // const fd = new FormData();
  //     // fd.append('user_id', dealerId);

  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/fastQuote/getQuote/${dealerId}`
  //         // fd
  //       );
  //     //   setTableData(response.data);
  //       setTableData([]);
  //     } catch (error) {
  //       console.log("Error fetching dealer data:", error);
  //       setTableData([]);
  //     }
  //   };

  //   // Using useEffect to fetch data when the page loads
  //   useEffect(() => {
  //     if (dealerId) {
  //       fetchDealerData(); // Fetch dealer data when 'id' is available
  //     }
  //   }, [dealerId]);
  //   console.log("tableData34543", tableData);
  // -----------------------------------------------------------------------------------
  // fortable data-------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  

  // Ensure tableData is defined before performing operations
  const totalItems = tableData ? tableData.length : 0;
  const itemsPerPage = totalItems > 20 ? totalItems : 20; // Change this value according to your preference
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData
    ? tableData.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Add empty rows if necessary
  const emptyRowsCount = Math.max(itemsPerPage - currentItems.length, 0);
  const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => (
    <tr key={`empty-${index}`} className={`${styles.tableRowData}`}>
      {[...Array(11)].map((_, i) => (
        <td key={i}>&nbsp;</td>
      ))}
    </tr>
  ));
  //  for pdf download and view--------------------------------------------------------------
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfData, setShowPdfData] = useState();
  const [showPdfStatus, setShowPdfStatus] = useState();
  const [idFinance, setIdFinance] = useState();
  const [userIdFinance, setUserIdFinance] = useState();
  //  const [pdfData, setPdfData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPdf = async (QuotationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fastQuote/view/${QuotationId}`
      );
      console.log("response3545", response.status);

      if (response.status === 200) {
        setPdfData([response.data]);
        toast.success("Successfully show the PDF");
        setShowPdf(true);
      } else if (response.status === 204) {
        toast.error("Please Enter Correct OTP.");
      }
    } catch (err) {
      toast.error(err);
    }
  };

  // -------------------------------------
  //  for country dialing code-------------------------
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json");
        console.log("User all info", res.data);
        setCountryCode(res.data.country_calling_code);
        // setIP(res.data.ip)
      } catch (error) {
        console.log("Error while getting ip ", error);
      }
    };
    getUserCountryCode();
  }, [countryCode]);
  console.log("countryCode", countryCode);
  //   useEffect(() => {
  //         dispatch(
  //           setRetailData({
  //             crmQuoteLenght:tableData?.length ?? 0,
  //           })
  //         );
  //       }, [tableData]);
  // --------------------------------------------------------------------------------\
  const [showUserPop, setShowUserPop] = useState(false);
  const showPopUpUsers = () => {
    setShowUserPop(true);
  };
  const renderTableRow = (item, index) => {
    console.log("item in the current list => ", item);
    // Calculate days difference for the inspection date

    return (
      <>
        <tr key={index} className={`${styles.tableRowData}`}>
          {item ? (
            <>
              <td>{index + 1}</td>
              <td>{item?.userId} </td>
              <td>{item?.sentMessage}</td>
              <td>{item?.outGoingnCall}</td>
              <td>{item?.inCominingCall}</td>
              {/* <td className={styles.email}>
                <span>{item?.email}</span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowEmailPopOut(true);
                    setRowData(item);
                  }}
                >
                  <MdEmail />
                </span>
              </td> */}
              {/* <td className="ac-col fw-bold">{item?.loanAppAssets} </td> */}
              <td>{item?.loanAppAssets} </td>
              <td>{item?.offers}</td>
              <td>{item?.quotationSent}</td>
              <td>{item?.appointment}</td>
              <td>{item?.loginHours}</td>
              <td className="text-center">
                {/* <Button
                  type="button"
                  className="publish-btn border-0 me-1 py-2"
                  style={{ color: "white", width: "70px" }}
                  // onClick={() => downloadPdf(item?.id)}
                  // onClick={()=>getOtpLoan(item?.id, item?.userId,2)}
                >
                  Download
                </Button> */}
                <Button
                  type="button"
                  className="border-0  py-2"
                  style={{
                    color: "white",
                    width: "70px",
                    backgroundColor: "#934805",
                    fontSize: "0.8rem",
                  }}
                  // onClick={() => loadPdf(item?.id, 1)}
                  // onClick={()=>getOtpLoan(item?.id, item?.userId,1)}
                  // onClick={() => loadPdf(item?.id)}
                  onClick={() => showPopUpUsers()}
                >
                  View
                </Button>
              </td>
            </>
          ) : (
            Array.from({ length: 11 }).map((_, i) => <td key={i}>&nbsp;</td>)
          )}
        </tr>
      </>
    );
  };
  //  table functionend-------------------------------------------------------------
  console.log("showPdfData", showPdfData);
  // -------------------------------------------------------------------------------
  console.log("tableData", tableData);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container} style={{maxHeight:"70vh",overflowY:"auto",scrollbarWidth:"none"}}>
          <Table
            striped
            bordered
            hover
            ref={tableRef}
            className="shadow mb-0"
            id="table"
          >
            <thead>
              <tr>
                <th>No#</th>
                <th>User Id</th>
                <th>Sent Messages</th>
                <th>Outgoing Calls</th>
                <th>Incomming calls</th>
                <th>Loan App Asset</th>
                <th>Offers</th>
                <th>Quatation Sent</th>
                <th>Appointment</th>
                <th>Login Hours</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(renderTableRow)}
              {emptyRows}
            </tbody>
            <tfoot>
              <tr className={`${styles.tableFooterRow}`}>
                <td colSpan={2}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
      <CrmEmailPopOut
        setShowEmailPopOut={setShowEmailPopOut}
        showEmailPopOut={showEmailPopOut}
        rowData={rowData}
      />
      <Modal show={showPdf} onHide={() => setShowPdf(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#d1823a" }}
          >
            Quotation Pdf :
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Container>
            {pdfData &&
              pdfData.map((pdf, i) => {
                return (
                  <div key={i} style={{ height: "80vh" }}>
                    <iframe
                      src={`${process.env.REACT_APP_DOCS_URL}/${dealerId}/${pdf}#page=1&zoom=125`}
                      type="application/pdf"
                      frameborder="0"
                      style={{
                        objectFit: "fill",
                        width: "100%",
                        height: "75vh",
                      }}
                    ></iframe>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>
      </Modal>
      {/* show the pop-up of User-View Button */}
      <Modal show={showUserPop} onHide={() => setShowUserPop(false)} size="xl">
        <Modal.Header closeButton style={{ gap: "10%" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#d1823a" }}
          >
            Users Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100%" }}>
          <div className={styles.main_container_user}>
            <div className={styles.container_user_top}>
              <div className={styles.container_user_top_left}>
                <div>
                  <span style={{ color: "#d1823a" }}>Name</span>
                  <h3>Mike Report Gra</h3>
                </div>
                <div>
                  <span style={{ color: "#d1823a" }}>Phone Number</span>
                  <h3>(XXX)XXX-XXXX</h3>
                </div>
                <div>
                  <span style={{ color: "#d1823a" }}>City</span>
                  <h3>Manhattan</h3>
                </div>
                <div>
                  <span style={{ color: "#d1823a" }}>Last Login: <span style={{color:"grey"}}>12/23/2024 09:37 pm</span></span>
                </div>
              </div>
              <div className={styles.container_user_top_right}>
                <div className={styles.container_user_top_right_upper}>
                  <div className={styles.user_top_right_box}>
                    <span>Sent Messages</span>
                    <span>20</span>
                  </div>
                  <div className={styles.user_top_right_box}>
                  <span>Outgoing Call</span>
                  <span>10</span>
                  </div>
                  <div className={styles.user_top_right_box}>
                  <span>Incoming Call</span>
                  <span>5</span>
                  </div>
                  <div className={styles.user_top_right_box}>
                  <span>Loan App Access</span>
                  <span>65</span>
                  </div>
                </div>
                <div className={styles.container_user_top_right_lower}>
                  <div className={styles.user_top_right_box}>
                  <span>Offers</span>
                  <span>20</span>
                  </div>
                  <div className={styles.user_top_right_box}>
                  <span>Quotation Sent</span>
                  <span>11</span>
                  </div>
                  <div className={styles.user_top_right_box}>
                  <span>Appointment</span>
                  <span>9</span>
                  </div>
                  <div className={styles.user_top_right_box}>
                  <span>Login Hours</span>
                  <span>8 Hours</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.container_user_bottom}>
            <div className={styles.container_user_bottom_left}>
                <h6 style={{textAlign:"center",color:"grey"}}>Media Manager</h6>
                <MyDoughnutChart/>
              </div>
              <div className={styles.container_user_bottom_right}>
                 <div className={styles.container_user_top_right_upper}>
                  <div className={styles.user_top_right_boxb}>
                    <span>Sold Inventory</span>
                    <span style={{color:"green"}}>8</span>
                  </div>
                  <div className={styles.user_top_right_boxb}>
                  <span>Added Vehicles</span>
                  <span style={{color:"green"}}>7</span>
                  </div>
                  <div className={styles.user_top_right_boxb}>
                  <span>Payment Posted</span>
                  <span>8</span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Users;
